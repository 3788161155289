import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { MiscUtil } from 'src/app/lib/core/util/misc.util';

import { AppService } from 'src/app/services/app.service';

import { DynformControl, DynformControlsMap } from 'src/app/lib/core/model/dymform-control';


@Component({
  selector: 'app-provider-misc-gift-card-page',
  templateUrl: './provider-misc-gift-card-page.component.html',
  styleUrls: ['./provider-misc-gift-card-page.component.scss']
})
export class ProviderMiscGiftCardPageComponent implements OnInit {

  route: ActivatedRoute;

  processing = false;

  df:any = {
    form: undefined,
    controls: [],
    obj: undefined,
    balance: undefined,
  };

  constructor(public injector:Injector, public apps:AppService) {
    this.route = injector.get(ActivatedRoute);
    this.apps.bas.us.checkActivatedRoute(this.route.snapshot);

    if (this.apps.bas.envtest) console.log("ProviderMiscGiftCardPageComponent");


    this.updateForm();
    
  }

  getBalance() {
    let gccc = this.df.form.controls.giftCardCode;
    let res = this.apps.bas.fs.validateFormControls({ "giftCardCode": gccc });

    let gcc = gccc.value;
    if (this.apps.bas.envtest) console.log("getBalance, res: ", res, ", gcc: " + gcc);

    this.df.balance = undefined;

    if (!res) return;
    
    this.apps.bas.ws.json({ 

      aType: "provider", 
      action: "getGiftCardBalance", 
      jsonData: JSON.stringify({ "giftCardCode": gcc })
    
    }).then((json) => {
  
      if (this.apps.bas.envtest) console.log("getBalance, json: ", json);
      if (json.success) {
        this.df.balance = json.balanceAsDouble;
      }
    });

  }
  
  updateForm() {

    let controls: DynformControl[] = [];

    controls.push(new DynformControl({ key: 'giftCardCode',   mk: 'Gavekortkode', required: true }));
    controls.push(new DynformControl({ key: 'amountAsDouble',   mk: 'Gavekort-beløp', required: true }));
    controls.push(new DynformControl({ key: 'comment',   mk: 'common.comment', controlType: 'textarea', required: true }));

    this.apps.bas.fs.updateFormObj(this.df, controls, { });


  }

  dfSubmit() {
    let rv = this.apps.bas.fs.getRawValue(this.df.form, this.df.controls);

    if (rv === false) return;



    if (this.apps.bas.envtest) console.log("formSubmit, rv: ", rv);

    this.processing = true;

    this.apps.bas.ws.json({ 

      aType: "provider", 
      action: "useGiftCardBalance", 
      jsonData: JSON.stringify(rv)
    
    }).then((json) => {
      this.processing = false;

      if (this.apps.bas.envtest) console.log("dfSubmit, json: ", json);
      if (json.success) {
        this.df.balance = undefined;
        this.df.form.reset();

        this.apps.bas.ui.success("Gavekortet ble innløst, gjennsående beløp: " + json.newBalanceAsDouble + ", referansenummer: " + json.bid);

      }
    }).catch(err => {
      this.processing = false;

    });

  }

  ngOnInit(): void {
    
  }

}

