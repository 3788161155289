
<nz-modal 
  [nzVisible]="!!order.id" 
  [nzTitle]="'common.booking._order' | actrans | str" 
  nzWidth="90vw" 
  (nzOnCancel)="closeOrderModal()" 
  (nzOnOk)="closeOrderModal()"
  >
  <ng-container *nzModalContent>
    
    <div *ngIf="order.id">

      <form nz-form nzLayout="vertical" [nzAutoTips]="apps.bas.fs.autoTips">

      
        <h1>
          <a *ngIf="apps.bas.aas.isAdmin()" [routerLink]="apps.bas.ui.getRouterPrefix() + '/lib/goto'" [queryParams]="{ 'goto-action': 'order', 'goto-id': order.bid }">{{ order.bid }}</a>
          <span *ngIf="!apps.bas.aas.isAdmin()">{{ order.bid }}</span>
        </h1>


        
        <btlib-content-row [cols]="orderRows['s1']" [grid]="apps.bas.ui.gridMd"></btlib-content-row>
      
        <btlib-content-row [cols]="orderRows['s2']" [grid]="apps.bas.ui.gridLg"></btlib-content-row>


        <nz-form-label class="text-bold">{{ 'common.notes' | actrans }}</nz-form-label>
        <nz-input-group [nzSuffix]="saveNotesTpl" class="ant-input-affix-wrapper-textarea-with-clear-btn">

          <textarea nz-input [(ngModel)]="order.notes" [placeholder]="'common.notes' | actrans"  [ngModelOptions]="{standalone: true}"></textarea>
        </nz-input-group>

        <ng-template #saveNotesTpl>
          <span
            nz-icon
            class="ant-input-clear-icon"
            nzTheme="twotone"
            [nzTwotoneColor]="'#52c41a'"
            nzType="save"
            
            (click)="saveNotes()"
            title="Lagre notater"
          ></span>
          <!--*ngIf="textValue"-->
        </ng-template>


        <h2>{{ 'common.customer' | actrans }}</h2>
        <btlib-content-row [cols]="orderRows['customer']" [grid]="apps.bas.ui.gridLg"></btlib-content-row>
      

        <div>
          <h2>{{ 'bus.booking.items' | actrans }}</h2>
          <nz-table 
            #nzTableOrderItems
            [nzData]="order.items || []" 
            nzTableLayout="fixed" 
            [nzShowPagination]="false" 
            [nzPageSize]="100"
            nzSize="small"
            class="nz-table-xs"
            >
            <thead>
              <tr>
                <th nzWidth="30px"></th>
           
     
                <th [nzWidth]="apps.bas.ui.r.gtemd ? '100px' : '40px'">
                  <span *ngIf="apps.bas.ui.r.gtemd">{{ 'common.status' | actrans }}</span>
                </th>
                <th>{{ 'common.product' | actrans }}</th>

                <th *ngIf="apps.bas.ui.r.gtelg">{{ 'common.updated' | actrans }}</th>
                <th *ngIf="apps.bas.ui.r.gtemd">{{ 'common.period' | actrans }}</th>

                <th *ngIf="apps.bas.ui.r.gtelg">{{ 'bus.booking.guests' | actrans }}</th>
                <th *ngIf="apps.bas.ui.r.gtelg">{{ 'common.count' | actrans }}</th>
    
                <th nzAlign="right">{{ 'common.amount' | actrans }}</th>

              </tr>
            </thead>
            <tbody>
              <ng-container  *ngFor="let data of nzTableOrderItems.data; let idx = index">
                <tr>
                  <td>
                    <i nz-icon class="cursor-pointer" [nzType]="data.expanded ? 'minus-square' : 'plus-square'" (click)="data.expanded = !data.expanded" ></i>
                  </td>
                  <td [class]="data.statusCssClass">
                    <span *ngIf="apps.bas.ui.r.gtemd">{{ data.mkStatus }}</span> 
                  </td>
                  <td>{{ data.product }}</td>
     
              
                  <td *ngIf="apps.bas.ui.r.gtelg">{{ data.updated }}</td>
                  
                  <td *ngIf="apps.bas.ui.r.gtemd">{{ data.periodString }}</td>
           
                  <td *ngIf="apps.bas.ui.r.gtelg">{{ data.guests }}</td>
                  <td *ngIf="apps.bas.ui.r.gtelg">{{ data.count }}</td>

                  <td nzAlign="right">{{ data.amountDouble | nf }}</td>

                  <!-- <td *ngIf="apps.bas.ui.r.ltesm"></td> -->

                </tr>
                <tr  *ngIf="data.expanded" class="tr-expanded"> 
                 
                  <td [attr.colspan]="(
                                             4 
                    + (apps.bas.ui.r.ltesm ? 0 : 0)  
                    + (apps.bas.ui.r.gtesm ? 0 : 0) 
                    + (apps.bas.ui.r.gtemd ? 1 : 0) 
                    + (apps.bas.ui.r.gtelg ? 3 : 0) 
                    + (apps.bas.ui.r.gtexl ? 0 : 0)
                    )">
                  <div class="tr-extended-div">
                 
                   
                    <btlib-content-row [cols]="orderRows['item' + data.id]" [grid]="apps.bas.ui.gridMd"></btlib-content-row>
      
                    <div *ngIf="orderRows['item' + data.id + '_inputValues']">
                      <h3>{{ 'bus.productInput' | actrans }}</h3>
                      <btlib-content-row [cols]="orderRows['item' + data.id + '_inputValues']" [grid]="apps.bas.ui.gridMd"></btlib-content-row>
      
                    </div>

                  </div>
                  </td>

                </tr>
              </ng-container>
            </tbody>
            <tfoot *ngIf="!(order.status?.isAvailability || order.status?.isProvider)">
              <tr>
                <th colspan="3">
                  {{ 'common.sum' | actrans }}
                </th>
                <th [attr.colspan]="(
                    1
                    + (apps.bas.ui.r.ltesm ? 0 : 0)  
                    + (apps.bas.ui.r.gtesm ? 0 : 0) 
                    + (apps.bas.ui.r.gtemd ? 1 : 0) 
                    + (apps.bas.ui.r.gtelg ? 3 : 0) 
                    + (apps.bas.ui.r.gtexl ? 0 : 0)
                  )"
                  
                  nzAlign="right"
                >
                {{ order.amountDouble | nf }}
                </th>
           
              </tr>
              <tr>
                <th colspan="3">
                  {{ 'bus.invoice.paidAmount' | actrans }}
                </th>
                <th [attr.colspan]="(
                    1
                    + (apps.bas.ui.r.ltesm ? 0 : 0)  
                    + (apps.bas.ui.r.gtesm ? 0 : 0) 
                    + (apps.bas.ui.r.gtemd ? 1 : 0) 
                    + (apps.bas.ui.r.gtelg ? 3 : 0) 
                    + (apps.bas.ui.r.gtexl ? 0 : 0)
                  )"
                  
                  nzAlign="right"
                >
                {{ order.paidAmountDouble | nf }}
                </th>
           
              </tr>
              <tr>
                <th colspan="3">
                  {{ 'pdf.invoice.remainingAmount' | actrans }}
                </th>
                <th [attr.colspan]="(
                    1
                    + (apps.bas.ui.r.ltesm ? 0 : 0)  
                    + (apps.bas.ui.r.gtesm ? 0 : 0) 
                    + (apps.bas.ui.r.gtemd ? 1 : 0) 
                    + (apps.bas.ui.r.gtelg ? 3 : 0) 
                    + (apps.bas.ui.r.gtexl ? 0 : 0)
                  )"
                  
                  nzAlign="right"
                >
                {{ order.remainingAmountDouble | nf }}
                </th>
           
              </tr>

            </tfoot>
          </nz-table>

        </div>

      </form>
    </div>

  </ng-container>
  <div *nzModalFooter>
    <!-- <span>Modal Footer:</span> -->
    
  <button 
    *ngIf="apps.bas.ds.config.company.upCmSms"
    nz-button 
    nzType="default" 
    (click)="sendSms()" 
    [disabled]="!order.userTelephone">{{ 'web.ajax.sms' | actrans }}
  </button>
  <button 
    nz-button 
    nzType="default" 
    (click)="sendEmail()" 
    [disabled]="!order.userEmailAdress">{{ 'web.ajax.email' | actrans }}
  </button>

    <button 
      nz-button 
      nzType="default" 
      nzDanger 
      (click)="deleteOrder()"
      *ngIf="order.id && order.status && order.status.isAvailability"
      >
      {{ 'common.delete' | actrans }}  {{ 'common.booking._order' | actrans | str | lowercase }}
    </button>
    <button nz-button nzType="primary" (click)="closeOrderModal()">{{ 'common.close' | actrans }}</button>
  </div>
</nz-modal>


<btlib-sms-modal [input]="smsInput"></btlib-sms-modal>
<btlib-email-modal [input]="emailInput"></btlib-email-modal>

