import { AfterViewInit, Component, HostListener, Injector, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DynformControl, DynformControlsMap } from 'src/app/lib/core/model/dymform-control';
import { DATAID } from 'src/app/lib/core/services/data.service';
import { MiscUtil } from 'src/app/lib/core/util/misc.util';
import { upperCase } from 'src/app/lib/core/util/const';

import { AppService } from 'src/app/services/app.service';

import { NzUploadChangeParam, NzUploadFile, NzUploadXHRArgs } from 'ng-zorro-antd/upload';
import { Observable, Subscription } from 'rxjs';
import { BtGoogleMapsComponent } from 'src/app/lib/core/component/ui/bt-google-maps/bt-google-maps.component';
import { ComponentCanDeactivate } from 'src/app/lib/core/guards/pending-changes.guard';


@Component({
  selector: 'app-admin-user-user-page',
  templateUrl: './admin-user-user-page.component.html',
  styleUrls: ['./admin-user-user-page.component.scss']
})
export class AdminUserUserPageComponent implements OnInit, AfterViewInit, ComponentCanDeactivate {

  router: Router;
  route: ActivatedRoute;

  fbo:any;



  edit:any = {
    form: undefined,
    obj: undefined,
    changes: { },

    contact:  { controls: {},  obj: null, form: null, id: -1, idx: -1, newItemField: "contactNew" },
    log:      { controls: {},  obj: null, form: null, id: -1, idx: -1, newItemField: "logNew" },
  }


  isSpinning = true;
  dirty = false;
  lang:string = "no";

  userChangePassword:any = undefined;
  userChangeUsername:any = undefined;
  userDeleteUser:any = undefined;

  Sections = {
    "basic"         : { id: "basic",        title: "app.lib.common.basic",        icon: "setting"}, // + tagsEtc
    "companyInfo"   : { id: "companyInfo",  title: "web.common.user.companyInfo", icon: "dollar"}, 
    // "company"       : { id: "company",      title: "common.company",              icon: "setting"}, // + coa, cbapi
    "contacts"      : { id: "contacts",     title: "bus.user.contacts",           icon: "user"}, 
    "provider"      : { id: "provider",     title: "common.provider",             icon: "appstore"}, 
    // "agent"         : { id: "agent",        title: "common.agent",                icon: "setting"}, 
    
    "ups"           : { id: "ups",          title: "bus.user.ups",                icon: "table"}, 
    "log"           : { id: "log",          title: "common.log",                  icon: "file" },
   
  }


  helpVisible = false;
  mapVisible = false;

  private mapMain: BtGoogleMapsComponent|undefined;

  @ViewChild('mapMain', { static: false }) set mapMainComponent(content: BtGoogleMapsComponent) {
      if (this.apps.bas.envtest) console.log("mapMainComponent, content: " + content);

      if(content) { // initially setter gets called with undefined
          this.mapMain = content;
      }
  }

  @Output() userChange = new EventEmitter<any>();


  private _inputId:any;
  @Input() 
  get inputId() { return this._inputId; }
  set inputId(inputId:any) {
    console.log("inputId.set: " + inputId);
    if (inputId) this.getUser(inputId);
    this._inputId = inputId;
  }

  constructor(public injector:Injector, public apps:AppService) {
    this.router = injector.get(Router);
    this.route = injector.get(ActivatedRoute);
    this.apps.bas.us.checkActivatedRoute(this.route.snapshot);

    if (this.apps.bas.envtest) console.log("CommonUserUserPageComponent, login.success: "+this.apps.bas.ds.login.success+", route: ", this.route.snapshot, ", inputId: " + this.inputId);

    if (this.apps.bas.ds.login.success) {
      
      // this.getUser(this.route.snapshot.queryParams.id);
      
      
      if (this.inputId) {
        this.getUser(this.inputId);

      } else {
        this.route.queryParams.subscribe(qps => {
          console.log("route.queryParams.subscribe: ", qps);
          if (qps.id) this.getUser(qps.id);
  
        });
      }
  
    } 
    else {

      //TODO: denne blir kalt flere ganger. 
      this.apps.bas.ds.get(DATAID.APP_LOGIN).subscribe((res) => {
        if (this.apps.bas.envtest) console.log("CommonUserUserPageComponent.LOGIN.updated, res: ", res)
        // window.location.reload();
        if (res.success) {
          this.getUser(this.inputId || this.route.snapshot.queryParams.id);
      
          
        }
     
      });
    }

   }

  ngOnInit(): void {
    if (this.apps.bas.envtest) console.log("CommonUserUserPageComponent.ngOnInit");
  }

  ngAfterViewInit(): void {
    if (this.apps.bas.envtest) console.log("CommonUserUserPageComponent.ngAfterViewInit");
  }

  //

  
  async newItem(event:Event, item:string) {

    let params = this.edit[item].params || { };
    
    if (this.apps.bas.envtest) console.log("newItem: " + item + ", params: ", params);
    
    
    event.preventDefault();
    
    if (this.apps.bas.envtest) console.log("newItem: " + item + ", params: ", params);

    let obj = MiscUtil.clone( this.edit.obj[this.edit[item].newItemField] );

    if (obj.id == "") obj.id = this.edit[item].id--;
    if (this.apps.bas.envtest) console.log("newItem.then: ", obj);


    this.edit.obj[item + "s"] = this.edit.obj[item + "s"] || [];

    if (item == "log") this.edit.obj[item + "s"] = [obj, ...this.edit.obj[item + "s"] ];
    else this.edit.obj[item + "s"] = [...this.edit.obj[item + "s"], obj ];


    this.editItem(obj, item);

  }

  editItem(objOrId:number|any, item:string) {


    let isNum =  typeof objOrId !== "object";
    let obj = isNum ? this.edit.obj[item + "s"][objOrId] : objOrId;
  
    if (isNum) obj.idx = objOrId;

    let controls = this.generateControls(item, obj);
 
    this.apps.bas.fs.updateFormObj(this.edit[item], controls, obj);
  }

  saveItem(item:string, event:any = undefined) {


    let formValue = this.apps.bas.fs.getRawValue(this.edit[item].form, this.edit[item].controls);
    if (formValue === false) return false;
    if (this.apps.bas.envtest) console.log("saveItem, item: "+item+": ", formValue);

    let arr:any[] = this.edit.changes[ item + "s"] = this.edit.changes[ item + "s"] || [];

    let currentIdx = arr.findIndex((elem:any) => elem.id === formValue.id);

    if (currentIdx < 0) arr.push(formValue);
    else arr[currentIdx] = formValue;

    this.dirty = true;

    let obj =  this.edit[item].obj;
    this.edit[item].obj = null;

    let objClone = MiscUtil.clone(obj);

    MiscUtil.extend(obj, formValue);

    if (item == "contact") {
     obj.mkType = MiscUtil.listToMap(this.fbo.contactTypes)[formValue.type].mkName;

    } else  if (item == "log") {
      obj.time = objClone.time;
      obj.textOutput = obj.text;

      
    }


    if ( event) { 
      
      event.preventDefault();
    } 

    return true;
  }

    
  deleteItem(idx:number, item:string) {
   

    let toDelete = this.edit.obj[item + "s"][idx];
    this.edit.obj[item + "s"] = this.edit.obj[item + "s"].filter((id:number, i:number) => i != idx);
    if (toDelete.id < 0) {
      
    } else {
      this.edit.changes[item + "Delete"] =  this.edit.changes[item + "Delete"] || [];
      this.edit.changes[item + "Delete"].push(toDelete.id);
    }
    
    this.dirty = true;

    if (this.apps.bas.envtest) console.log("deleteItem, changes: ", this.edit.changes)
  }


  //

  uploadHeaders = (file: NzUploadFile): Object => {
    let headers = this.apps.bas.ws.getCommonHeaders();
    MiscUtil.extend(headers, this.apps.bas.ws.getLoginHeaders());
    return headers;
  }

  handleUploadImage({ file, fileList }: NzUploadChangeParam): void {

    const status = file.status;
    const resp = file.response;
    if (status !== 'uploading') {
      if (this.apps.bas.envtest) console.log(file, fileList);

      if (status == "removed") {
        //TODO
      }
    }
    if (status === 'done') {
     
      if (this.apps.bas.envtest) console.log(file.name + 'file uploaded successfully, resp: ', resp);

      if (resp.itemId) {
        //TODO
      } else {
        this.apps.bas.ui.error(this.apps.bas.ui.actrans("common.error.upload.general", [], false, "En feil oppstod da vi skulle behandle filen du lastet opp"));
      }

    } else if (status === 'error') {
      if (this.apps.bas.envtest) console.log(`${file.name} file upload failed.`);
      // TODO
      // this.apps.bas.ui.error(this.apps.bas.ui.actrans("common.error.imageUpload", [], false, "En feil oppstod da vi skulle behandle filen du lastet opp. Vanligvis er dette fordi du har lastet opp en JPEG fil i CMYK-format. Kun JPEG bilder i RGB-format kan lastes opp")); //TODO:text
      
    }
  }



  //



  generateControls(type:string, obj:any) {
    if (type == "contact") return this.generateControlsContact(obj);
    else if (type == "log") return this.generateControlsLog(obj);

    return [];
  }

  generateControlsContact(obj:any) {
    let controls: DynformControl[] = [];

    controls.push(new DynformControl({ key: 'id' }));
    controls.push(new DynformControl({ key: 'time',     mk: 'common.time',     controlType: 'label', }));
    controls.push(new DynformControl({ key: 'name',     mk: 'common.name' }));
    controls.push(new DynformControl({ key: 'telephone',     mk: 'common.telephone' }));
    controls.push(new DynformControl({ key: 'email',     mk: 'common.email' }));
    controls.push(new DynformControl({ key: 'comment',     mk: 'common.comment', controlType: "textarea" }));

    controls.push(new DynformControl({ 
      key: 'type',     
      mk: 'bus.contact.type', 
      controlType: 'select', 
      required: true,
      // show: () => user.isUser,bus.user.role
      options: () => {
        return this.fbo.contactTypes;
      }, 
      optionsAllowEmpty: true,
      optionsFieldValue: "id", 
      optionsFieldLabel: "mkName"
    }));

    /*
		<div class="form-group">
										<label class="col-sm-4 control-label" for="contact.type"><nit:message key="bus.contact.type"/>:</label>
										<div class="col-sm-8">
											<nit:select cssClass="form-control " path="fbo.contact.type" items="${fbo.contactTypes}" enabled="${empty fbo.contact.id}" valueProperty="id" labelProperty="mk" />
											<%--
											<c:choose>
											<c:when test="${empty fbo.contact.id}">
												<nit:select cssClass="form-control " path="fbo.contact.type" items="${fbo.contactTypes}" valueProperty="id" labelProperty="mk" />
											</c:when>
											<c:otherwise>
												<input type="hidden" name="contact.type" value="${fbo.contact.type}" />
<
												<p class="form-control-static"><nit:message key="${fbo.contact.typeEnum.mk}"/></p>
											</c:otherwise>
											</c:choose>
											--%>
										</div>
									</div> 
    */


    return controls;
  }

  generateControlsLog(obj:any) {
    let controls: DynformControl[] = [];

    controls.push(new DynformControl({ key: 'id' }));
    controls.push(new DynformControl({ key: 'time',          mk: 'common.time',     controlType: 'label', }));
    controls.push(new DynformControl({ key: 'text',     mk: 'bus.log.text', controlType: "richtext" }));

    return controls;
  }




  generateControlsUser(user:any) {
    let controls: DynformControl[] = [];

    let langs = this.apps.bas.ds.config.appConfig?.enabledLanguages;
    // console.log("langs:")

    let isNew = !user.id;
    let isAdmin = this.apps.bas.aas.isAdmin();
    let isBn = this.apps.bas.aas.isBn();
  

    controls.push(new DynformControl({ key: 'id',     mk: 'common.id' }));

    controls.push(new DynformControl({ key: 'username',     mk: 'common.username', controlType: isNew ? "input" : "label", required: true }));
    controls.push(new DynformControl({ key: 'password',     mk: 'common.password', controlType: "label", value: "•••••••••••" }));

    controls.push(new DynformControl({ key: 'email',     mk: 'common.email', show: () => !user.isUsernameEmail }));
    
    if (isNew) {
      controls.push(new DynformControl({ 
        key: 'role',     
        mk: 'bus.user.role', 
        controlType: 'select', 
        required: true,
        // show: () => user.isUser,bus.user.role
        options: () => {
          return this.fbo.newUserRoles;
        }, 
        // optionsAllowEmpty: true,
        optionsFieldValue: "id", 
        optionsFieldLabel: "mkName",
        onChange: () => {
          this.edit.obj.userRole = this.apps.bas.us.listToMap(this.fbo.newUserRoles)[this.edit.form.controls.role.value];
        }
      }));
    } else {
      controls.push(new DynformControl({ key: 'role', valuePath: "mkRole",     mk: 'bus.user.role', controlType: "label" }));
   
    }
 
    controls.push(new DynformControl({ 
      key: 'customerType',     
      mk: user.userRole.isUser ? 'bus.user.customerType' : 'common.type', 
      controlType: 'select', 
      required: true,
      // show: () => user.isUser,
      options: () => {
        return this.fbo.customerTypes;
      }, 
      // optionsAllowEmpty: true,
      optionsFieldValue: "id", 
      optionsFieldLabel: "mkName",
      onChange: () => {
        this.edit.ct = this.apps.bas.us.listToMap(this.fbo.customerTypes)[this.edit.form.controls.customerType.value];
      }
    }));
    this.edit.ct = this.apps.bas.us.listToMap(this.fbo.customerTypes)[user.customerType];

    controls.push(new DynformControl({ 
      key: 'socialSecurityNumber',     
      mk: 'bus.user.socialSecurityNumber',
      show: () => {
        return this.edit.ct?.isPrivate;
      }
    }));
    controls.push(new DynformControl({ 
      key: 'orgNumber',     
      mk: 'bus.user.orgNumber',
      show: () => {
        return !this.edit.ct?.isPrivate;
      }
    }));

    controls.push(new DynformControl({ key: 'name',     mk: 'common.name' }));
    controls.push(new DynformControl({ key: 'address1',     mk: 'common.addressLine1' }));
    controls.push(new DynformControl({ key: 'address2',     mk: 'common.addressLine2' }));
    controls.push(new DynformControl({ key: 'address3',     mk: 'common.addressLine3' }));
    controls.push(new DynformControl({ key: 'zipCode',     mk: 'common.zipCode' }));
    controls.push(new DynformControl({ key: 'city',     mk: 'common.city' }));
    controls.push(new DynformControl({ key: 'telephone',     mk: 'common.telephone' }));
    
    controls.push(new DynformControl({ 
      key: 'country', 
      valuePath: "countryCode",
      mk: 'common.country', 
      controlType: 'select', 
      required: true,
      options: () => {
        return this.fbo.countries;
      }, 
      // optionsAllowEmpty: true,
      optionsFieldValue: "code", 
      optionsFieldLabel: "mkName",
  
    }));
    
    controls.push(new DynformControl({ 
      key: 'language', 
      valuePath: "languageCode",
      mk: 'common.language', 
      controlType: 'select', 
      required: true,
      options: () => {
        return this.apps.bas.ds.config.appConfig?.enabledLanguages || [];
      }, 
      // optionsAllowEmpty: true,
      optionsFieldValue: "code", 
      optionsFieldLabel: "mkName",
  
    }));
    
    controls.push(new DynformControl({ 
      key: 'timeZone', 
      // valuePath: "countryCode",
      mk: 'common.timeZone', 
      controlType: 'select', 
      required: true,
      options: () => {
        return this.fbo.timeZones;
      }, 
      // optionsAllowEmpty: true,
      optionsFieldValue: "id", 
      optionsFieldLabel: "label",
 
    }));

    controls.push(new DynformControl({ key: 'invoiceNote',     mk: 'bus.user.invoiceNote' }));
    
    controls.push(new DynformControl({ key: 'ehfEnabled',     mk: 'bus.user.ehfEnabled' }));
    controls.push(new DynformControl({ key: 'ehfOrderReference',     mk: 'bus.user.ehfOrderReference' }));
    controls.push(new DynformControl({ key: 'ehfContractDocumentReference',     mk: 'bus.user.ehfContractDocumentReference' }));
    controls.push(new DynformControl({ key: 'ehfContactId',     mk: 'bus.user.ehfContactId' }));
    
    
    controls.push(new DynformControl({ key: 'acceptInfoEmail',     mk: 'bus.user.acceptInfoEmail' }));
    controls.push(new DynformControl({ key: 'acceptInfoSms',     mk: 'bus.user.acceptInfoSms' }));
    
    controls.push(new DynformControl({ key: 'notes',     mk: 'common.notes', controlType: "textarea" }));
    
    // Provider
    controls.push(new DynformControl({ key: 'displayName',     mk: 'common.displayName', show: () => this.edit.obj.userRole.isProvider }));
    controls.push(new DynformControl({ key: 'webAddress',     mk: 'common.webAddress' }));
    controls.push(new DynformControl({ key: 'bankAccount',     mk: 'bus.user.bankAccount' }));

    // Provider Tab:

    let company = this.apps.bas.ds.config.company;
    let daysList = this.fbo.daysList.map((val:number) => { return { value: val, label: val } });;

    // if (user.isProvider) {
      let upControls:DynformControl[] = [];

      upControls.push(new DynformControl({ 
        key: 'settleDay', 
        mk: 'bus.user.settleDay', 
        controlType: 'select', 
        options: () => {
          return daysList;
        }, 
        optionsAllowEmpty: true,
      }));
      upControls.push(new DynformControl({ 
        key: 'settleWeekday', 
        mk: 'bus.user.settleWeekday', 
        controlType: 'select', 
        options: () => {
          return this.fbo.weekdays;
        }, 
        optionsAllowEmpty: true,
      }));

      
      upControls.push(new DynformControl({ 
        key: 'payoutDelay', 
        mk: 'bus.user.payoutDelay', 
        controlType: 'select', 
        options: () => {
          return daysList;
        }, 
        optionsAllowEmpty: true,
      }));

      upControls.push(new DynformControl({ key: 'providerPercent',     mk: 'bus.common.providerPercent' }));
      // 	<c:if test="${nit:getUpw(wpd.company, 'C_MOD_SKATT_UTLEIE_FRA_FORMIDLINGSSELSKAP').booleanValue}">
      upControls.push(new DynformControl({ key: 'yearlyFixedFeeAsDouble',     mk: 'bus.user.up.yearlyFixedFee', show: () => company.upCModSkattUtleieFraFormidlingsselskap }));

      for (let ctrl of upControls) {
        // ctrl.valuePath =  'upObj.' + ctrl.key;  // ctrl.valuePath ||
      }

      controls.push(new DynformControl({ 
        key: 'upObj',
        controlType: 'formGroup',
        children: upControls
      }));

      
    // }
    

    let upsMapControls:DynformControl[] = [];

    for (let upw of user.upsList) {
      let isBoolean = upw.upObj.isBoolean;

      if (isBoolean) {
        upsMapControls.push(new DynformControl({ 
          key: upw.upObj.id + "",    
          // valuePath: 'upsMap.' + upw.upObj.id,
          mk: '', //upw.upObj.mkName,
          value: upw.value || "",
          controlType: 'select', 
          options: () => {
            return this.fbo.yesNo;
          }, 
          optionsAllowEmpty: true,
          optionsFieldValue: "bool", 
          optionsFieldLabel: "mkName",
          // data: {
          //   up: upw.up
          // }
        }));
      } else {
        upsMapControls.push(new DynformControl({ 
          key: upw.upObj.id + "",    
          // valuePath: 'upsMap.' + upw.upObj.id,
          mk: '', //upw.upObj.mkName,
          controlType: "input",
          value: upw.value || "",
          // data: {
          //   up: upw.up
          // }
        }));
      }


    }

    controls.push(new DynformControl({ 
      key: 'upsMap',
      controlType: 'formGroup',
      children: upsMapControls
    }));


    //
    
    if (user.isCompany) {
        
      let ucControls:DynformControl[] = [];

      let parentCompanies =  this.fbo.companies.filter((c:any) => c.id != user.id);

            
      ucControls.push(new DynformControl({ 
        key: 'ga4IdProd',     
        mk: 'bus.user.uc.gaid', 
      }));

      ucControls.push(new DynformControl({ 
        key: 'parent', 
        valuePath: 'parentId',
        mk: 'common.parent', 
        controlType: 'select', 
        options: () => {
          return parentCompanies;
        }, 
        show: () => isBn,
        optionsAllowEmpty: true,
        optionsFieldValue: "id", 
        optionsFieldLabel: "label",
      }));

      ucControls.push(new DynformControl({ 
        key: 'productTypePrimary', 
        valuePath: 'productTypePrimaryId',
        mk: 'bus.user.productTypePrimary', 
        controlType: 'select', 
        options: () => {
          return this.fbo.productTypes;
        }, 
        optionsAllowEmpty: true,
        optionsFieldValue: "id", 
        optionsFieldLabel: "mkName",
      }));
      ucControls.push(new DynformControl({ 
        key: 'productTypesSecondaryIdsAsString', 
        mk: 'bus.user.productTypesSecondary', 
        controlType: 'select', 
        selectMode: "multiple",
        options: () => {
          return this.fbo.productTypes;
        }, 
        optionsAllowEmpty: true,
        optionsFieldValue: "id", 
        optionsFieldLabel: "mkName",
      }));
      ucControls.push(new DynformControl({ 
        key: 'languagesAsString', 
        mk: 'bus.user.languages', 
        controlType: 'select', 
        selectMode: "multiple",
        options: () => {
          return this.fbo.languagesAll;
        }, 
        optionsAllowEmpty: true,
        optionsFieldValue: "code", 
        optionsFieldLabel: "mkName",
      }));


    /*
					
								<div class="form-group">
									<label class="col-sm-4 control-label" for="languageCodes"><nit:message key="bus.user.languages"/>:</label>
									<div class="col-sm-8"><nit:select cssClass="form-control " path="fbo.languageCodes" items="${fbo.languagesAll}" multiple="true" size="11" valueProperty="code" labelProperty="messageKey" debug="false" /></div>
								</div>
							
								<div class="form-group">
									<label class="col-sm-4 control-label" for="user.uc.currency"><nit:message key="common.currency"/>:</label>
									<div class="col-sm-8"><nit:select cssClass="form-control " path="fbo.user.uc.currency" items="${fbo.currencies}" valueProperty="code" labelProperty="code" /></div>
								</div>
                
								<div class="form-group">
									<label class="col-sm-4 control-label" for="user.uc.bidLetters"><nit:message key="bus.user.uc.bidLetters"/> (<nit:message key="common.default"/>: ${fbo.user.uc.defaultBidLetters}):</label>
									<div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.uc.bidLetters" enabled="${isBn}" /></div>
								</div>
								<div class="form-group">
									<label class="col-sm-4 control-label" for="user.uc.bidDigits"><nit:message key="bus.user.uc.bidDigits"/> (<nit:message key="common.default"/>: ${fbo.user.uc.defaultBidDigits}):</label>
									<div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.uc.bidDigits" enabled="${isBn}" /></div>
								</div>
								<div class="form-group">
									<label class="col-sm-4 control-label" for="user.uc.bidOffset"><nit:message key="bus.user.uc.bidOffset"/> (<nit:message key="common.default"/>: ${fbo.user.uc.defaultBidOffset}):</label>
									<div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.uc.bidOffset" enabled="${isBn}" /></div>
								</div>
							 
									
									
								<div class="mt20"></div>
								
					
								<div class="form-group">
									<label class="col-sm-4 control-label" for="user.uc.latitude"><nit:message key="common.latitude"/>:</label>
									<div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.uc.latitude" style="" /></div>
								</div>
						
								<div class="form-group">
									<label class="col-sm-4 control-label" for="user.uc.longitude"><nit:message key="common.longitude"/>:</label>
									<div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.uc.longitude" style="" /></div>
								</div>
					
								<div class="form-group">	
									<div class="col-sm-offset-4 col-sm-8">
										<a href="#" id="openMap" class="btn btn-default" ><nit:message key="web.common.map.openMap" ct="false" /></a>
								
									</div>
								</div>
									
								
								<div class="form-group">
									<div class="col-sm-offset-4 col-sm-8"><h3>Google Analytics</h3></div>
								</div>
								<div class="form-group">
									<label class="col-sm-4 control-label" for="user.uc.gaid"><nit:message key="bus.user.uc.gaid"/>:</label>
									<div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.uc.gaid" style="" /></div>
								</div>
								<c:if test="${isTest or isBn}">
									<div class="form-group">
										<label class="col-sm-4 control-label" for="user.uc.gaidTest"><nit:message key="bus.user.uc.gaid"/> Test:</label>
										<div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.uc.gaidTest" style="" /></div>
									</div>
								</c:if>
								<div class="form-group">
                  <div class="col-sm-offset-4 col-sm-8"><h3>Google Analytics 4</h3></div>
                </div>
                <div class="form-group">
                  <label class="col-sm-4 control-label" for="user.uc.ga4IdProd"><nit:message key="bus.user.uc.gaid"/> 4:</label>
                  <div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.uc.ga4IdProd" style="" /></div>
                </div>
                <c:if test="${isTest or isBn}">
                  <div class="form-group">
                    <label class="col-sm-4 control-label" for="user.uc.ga4IdTest"><nit:message key="bus.user.uc.gaid"/> 4 Test:</label>
                    <div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.uc.ga4IdTest" style="" /></div>
                  </div>
                </c:if>
								
									
								<div class="mt20"></div>
								
								<div class="form-group">
									<label class="col-sm-4 control-label" for="user.uc.fromEmailAddress"><nit:message key="bus.user.uc.fromEmailAddress"/>:</label>
									<div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.uc.fromEmailAddress" /></div>
								</div>
								
								<div class="form-group">
									<label class="col-sm-4 control-label" for="user.uc.replyToEmailAddress"><nit:message key="common.replyToAddress"/>:</label>
									<div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.uc.replyToEmailAddress" /></div>
								</div>
						
								<div class="form-group">
									<label class="col-sm-4 control-label" for="user.uc.noreplyEmailAddress"><nit:message key="bus.user.uc.noreplyEmailAddress"/>:</label>
									<div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.uc.noreplyEmailAddress" /></div>
								</div>
							
								<div class="form-group">
									<label class="col-sm-4 control-label" for="user.uc.bccEmailAddress"><nit:message key="bus.user.uc.bccEmailAddress"/>:</label>
									<div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.uc.bccEmailAddress" /></div>
								</div>
						
								<div class="form-group">
									<label class="col-sm-4 control-label" for="user.uc.supportEmailAddress"><nit:message key="bus.user.uc.supportEmailAddress"/>:</label>
									<div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.uc.supportEmailAddress" /></div>
								</div>
								
								<div class="form-group">
									<label class="col-sm-4 control-label" for="user.uc.debugEmailAddress"><nit:message key="bus.user.uc.debugEmailAddress"/>:</label>
									<div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.uc.debugEmailAddress" /></div>
								</div>
								
								<div class="form-group">
                  <label class="col-sm-4 control-label" for="user.uc.alternativeCids"><nit:message key="bus.user.uc.alternativeCids"/>:</label>
                  <div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.uc.alternativeCids" /></div>
                </div>
							
								<div class="form-group">
									<div class="col-sm-offset-4 col-sm-8">
										<div class="checkbox">
											<label class="" for="user.uc.demo">
												<nit:checkbox path="fbo.user.uc.demo" />
												<nit:message key="bus.user.uc.demo"/>
											</label>
										</div>
									</div>
								</div>
								
								<div class="form-group">
									<div class="col-sm-offset-4 col-sm-8">
										<div class="checkbox">
											<label class="" for="user.uc.orderingEnabled">
												<nit:checkbox path="fbo.user.uc.orderingEnabled" />
												<nit:message key="bus.user.uc.orderingEnabled"/>
											</label>
										</div>
									</div>
								</div>
									
								<div class="form-group">
									<div class="col-sm-offset-4 col-sm-8">
										<div class="checkbox">
											<label class="" for="user.uc.showOnFrontpage">
												<nit:checkbox path="fbo.user.uc.showOnFrontpage" />
												<nit:message key="bus.user.uc.showOnFrontpage"/>
											</label>
										</div>
									</div>
								</div>
							
								<%--<div class="form-group">
									<div class="col-sm-offset-4 col-sm-8">
										<div class="checkbox">
											<label class="" for="user.uc.netsUseGlobal">
												<nit:checkbox path="fbo.user.uc.netsUseGlobal" />
												<nit:message key="bus.user.uc.netsUseGlobal"/>
											</label>
										</div>
									</div>
								</div>--%>
								
								<!-- <div class="form-group">
									<div class="col-sm-offset-4 col-sm-8"><h3><nit:message key="common.misc"/></h3></div>
								</div>-->
								
								
								<div class="form-group">
									<div class="col-sm-offset-4 col-sm-8"><h3><nit:message key="common.provider"/></h3></div>
								</div>
							
								<div class="form-group">
									<label class="col-sm-4 control-label" for="user.uc.providerPercentDefault"><nit:message key="bus.user.uc.providerPercentDefault"/>:</label>
									<div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.uc.providerPercentDefault" /></div>
								</div>
							
							
								<c:if test="${fbo.user.upCmCcPayment}">
									<div class="form-group">
										<div class="col-sm-offset-4 col-sm-8"><h3><nit:message key="web.common.user.company.ccPayment"/></h3></div>
									</div>
									<div class="form-group">
										<label class="col-sm-4 control-label" for="user.uc.ccType"><nit:message key="bus.user.uc.ccType"/>:</label>
										<div class="col-sm-8"><nit:select cssClass="form-control " path="fbo.user.uc.ccType" items="${fbo.ccTypes}" allowEmpty="true" valueProperty="id" labelProperty="mk" /></div>
									</div>
									<div id="cc_type_nets" class="hide cc_type_div">
										<div class="form-group">
											<label class="col-sm-4 control-label" for="user.uc.netsMerchantId"><nit:message key="bus.user.uc.netsMerchantId"/>:</label>
											<div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.uc.netsMerchantId" /></div>
										</div>
									
										<div class="form-group">
											<label class="col-sm-4 control-label" for="user.uc.netsToken"><nit:message key="bus.user.uc.netsToken"/>:</label>
											<div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.uc.netsToken" /></div>
										</div>
									</div>
									
									<div id="cc_type_dibs" class="hide cc_type_div">
										<div class="form-group">
											<label class="col-sm-4 control-label" for="user.uc.dibsMerchantId"><nit:message key="bus.user.uc.dibsMerchantId"/>:</label>
											<div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.uc.dibsMerchantId" /></div>
										</div>
									
										<div class="form-group">
											<label class="col-sm-4 control-label" for="user.uc.dibsHmacKey"><nit:message key="bus.user.uc.dibsHmacKey"/>:</label>
											<div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.uc.dibsHmacKey" /></div>
										</div>
										
										<div class="form-group">
											<label class="col-sm-4 control-label" for="user.uc.dibsMD5Key1"><nit:message key="bus.user.uc.dibsMD5Key"/> 1:</label>
											<div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.uc.dibsMD5Key1" /></div>
										</div>
										<div class="form-group">
											<label class="col-sm-4 control-label" for="user.uc.dibsMD5Key2"><nit:message key="bus.user.uc.dibsMD5Key"/> 2:</label>
											<div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.uc.dibsMD5Key2" /></div>
										</div>
										
										
										<!-- 
										<div class="form-group">
											<label class="col-sm-4 control-label" for="user.uc.dibsMerchantId3dsDisabled"><nit:message key="bus.user.uc.dibsMerchantId"/> (<nit:message key="bus.user.uc.3dsDisabled"/>):</label>
											<div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.uc.dibsMerchantId3dsDisabled" /></div>
										</div>
									
										<div class="form-group">
											<label class="col-sm-4 control-label" for="user.uc.dibsHmacKey3dsDisabled"><nit:message key="bus.user.uc.dibsHmacKey"/> (<nit:message key="bus.user.uc.3dsDisabled"/>):</label>
											<div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.uc.dibsHmacKey3dsDisabled" /></div>
										</div>
										-->
										
										<div class="form-group">
											<div class="col-sm-offset-4 col-sm-8">
												<div class="checkbox">
													<label class="" for="user.uc.dibsApiAccess">
														<nit:checkbox path="fbo.user.uc.dibsApiAccess" />
														<nit:message key="bus.user.uc.dibsApiAccess"/>
													</label>
												</div>
											</div>
										</div>
									</div>
									
									<div id="cc_type_easy" class="hide cc_type_div">
									
										<div class="form-group">
											<label class="col-sm-4 control-label" for="user.ui.dibsEasyMerchantId"><nit:message key="bus.user.uc.dibsMerchantId"/>:</label>
											<div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.ui.dibsEasyMerchantId" /></div>
										</div>
										
										<c:if test="${isTest or isBn}">
											<div class="form-group">
												<label class="col-sm-4 control-label" for="user.ui.dibsEasyKeySecretTest"><nit:message key="bus.user.ui.dibsEasyKeySecret"/> (Test):</label>
												<div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.ui.dibsEasyKeySecretTest" /></div>
											</div>
											<div class="form-group">
												<label class="col-sm-4 control-label" for="user.ui.dibsEasyKeyCheckoutTest"><nit:message key="bus.user.ui.dibsEasyKeyCheckout"/> (Test):</label>
												<div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.ui.dibsEasyKeyCheckoutTest" /></div>
											</div>
										</c:if>
										
										<c:if test="${isProduction}">
											<div class="form-group">
												<label class="col-sm-4 control-label" for="user.ui.dibsEasyKeySecretProd"><nit:message key="bus.user.ui.dibsEasyKeySecret"/> (Prod):</label>
												<div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.ui.dibsEasyKeySecretProd" /></div>
											</div>
											<div class="form-group">
												<label class="col-sm-4 control-label" for="user.ui.dibsEasyKeyCheckoutProd"><nit:message key="bus.user.ui.dibsEasyKeyCheckout"/> (Prod):</label>
												<div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.ui.dibsEasyKeyCheckoutProd" /></div>
											</div>
										</c:if>
										
									
									</div>

									
									
									<c:if test="${nit:getBooleanProperty('STRIPE_ENABLED', wpd.isTest)}">
										
										<div id="cc_type_stripe" class="hide-cc_type_div-off">
											<div class="form-group">
												<div class="col-sm-offset-4 col-sm-8"><h4>Stripe</h4></div>
											</div>
											<div class="form-group">
												<div class="col-sm-offset-4 col-sm-8">
													<div class="checkbox">
														<label class="" for="user.uc.stripeEnabled">
															<nit:checkbox path="fbo.user.uc.stripeEnabled" />
															<nit:message key="common.enabled"/>
														</label>
													</div>
												</div>
											</div>
											
											<c:if test="${isTest or isBn}">
											
												<div class="form-group">
													<label class="col-sm-4 control-label" for="">Stripe <nit:message key="common.status" lowerCase="true" /> <nit:message key="common.test" lowerCase="true" />:</label>
													<div class="col-sm-8">
														<div class="alert alert-${fbo.user.uc.stripeStatusTest ? 'success' : 'danger'}" role="alert">
															<nit:message key="${not empty fbo.user.uc.stripeAccessTokenPublicTest ? 'common.connected' : 'common.notConnected'}" />
														
														</div>
											
											

														<c:choose>
														<c:when test="${not empty fbo.user.uc.stripeAccessTokenPublicTest}">
															
															<c:if test="${wpd.isBn}">
																<a href="<nit:url value="/tools.htm" pn1="action" pv1="stripePlatformDeauthorize" pn2="env" pv2="false" />" class="btn btn-danger"><span>Disconnect (bn)</span></a> 
															</c:if>
															<p>stripeAccessDataTest: ${fbo.user.uc.stripeAccessDataTest}</p>
														</c:when>
														<c:otherwise>
															<a href="<nit:url value="/tools.htm" pn1="action" pv1="stripePlatformAuthorize" pn2="env" pv2="false" />" class="stripe-connect"><span>Connect with Stripe</span></a> 
															
														</c:otherwise>
														</c:choose>
														
													</div>
												</div>
												
												
											
											</c:if>
											
											<c:if test="${isProduction}">
												<%-- 
												
												<div class="form-group">
													<label class="col-sm-4 control-label" for="">Stripe <nit:message key="common.status" lowerCase="true" /> <nit:message key="common.production" lowerCase="true" />:</label>
													<div class="col-sm-8">
														<div class="alert alert-${fbo.user.uc.stripeStatusProd ? 'success' : 'danger'}" role="alert">
															<nit:message key="${not empty fbo.user.uc.stripeAccessTokenPublicProd ? 'common.connected' : 'common.notConnected'}" />
														
														</div>
											
											
																
														<c:choose>
														<c:when test="${not empty fbo.user.uc.stripeAccessTokenPublicProd}">
															<c:if test="${wpd.isBn}">
															
																<a href="<nit:url value="/tools.htm" pn1="action" pv1="stripePlatformDeauthorize" pn2="env" pv2="true" />" class="btn btn-danger"><span>Disconnect (bn)</span></a> 
															</c:if>
														</c:when>
														<c:otherwise>
															
															<a href="<nit:url value="/tools.htm" pn1="action" pv1="stripePlatformAuthorize" pn2="env" pv2="true" />" class="stripe-connect"><span>Connect with Stripe</span></a> 
														
														</c:otherwise>
														</c:choose>
													</div>
												</div>
												
												
												
												<div class="form-group">
													<label class="col-sm-4 control-label" for="">Stripe <nit:message key="common.status" lowerCase="true" /> <nit:message key="common.production" lowerCase="true" />:</label>
													<div class="col-sm-8"><p class="form-control-static">
														<div class="alert alert-${fbo.user.uc.stripeStatusProd ? 'success' : 'danger'}" role="alert"><nit:message key="${fbo.user.uc.stripeStatusProd ? 'common.connected' : 'common.notConnected'}" /></div>
													</p></div>
<!-- 													<p class="form-control-static"></p> -->
												</div>
												
												<c:choose>
												<c:when test="${fbo.user.uc.stripeStatusProd}">
													
												</c:when>
												<c:otherwise>
													<a href="<nit:url value="/tools.htm" pn1="action" pv1="stripePlatformAuthorize" pn2="env" pv2="true" />" class="stripe-connect"><span>Connect with Stripe</span></a> 
												</c:otherwise>
												</c:choose>
												--%>
												
											</c:if>
											
											
										</div>
									
									</c:if>
									
									
									<%-- 
									<c:if test="${isTest or isBn}">
										<div id="cc_type_vipps" class="">
											<div class="form-group">
												<div class="col-sm-offset-4 col-sm-8"><h4>Vipps</h4></div>
											</div>
											
											<div class="form-group">
												<label class="col-sm-4 control-label" for="user.uc.vippsNumber"><nit:message key="bus.user.uc.vippsNumber"/>:</label>
												<div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.uc.vippsNumber" /></div>
											</div>
											
										</div>
									</c:if>
									--%>
								
								</c:if>
							
								<c:if test="${fbo.user.upCmNetseNett}">
									<div class="form-group">
										<div class="col-sm-offset-4 col-sm-8"><h3><nit:message key="web.common.user.company.eNett"/></h3></div>
									</div>
									
									<%--
									<div class="form-group">
										<div class="col-sm-offset-4 col-sm-8">
											<div class="checkbox">
												<label class="" for="user.uc.netsAvtaleUseGlobal">
													<nit:checkbox path="fbo.user.uc.netsAvtaleUseGlobal" />
													<nit:message key="bus.user.uc.netsAvtaleUseGlobal"/>
												</label>
											</div>
										</div>
									</div>
									--%>
									
									<div class="form-group">
										<div class="col-sm-offset-4 col-sm-8">
											<div class="checkbox">
												<label class="" for="user.uc.netsDrManualTransfer">
													<nit:checkbox path="fbo.user.uc.netsDrManualTransfer" />
													<nit:message key="bus.user.uc.netsDrManualTransfer"/>
												</label>
											</div>
										</div>
									</div>
									
									
									<div class="form-group">
                    <div class="col-sm-offset-4 col-sm-8">
                      <div class="checkbox">
                        <label class="" for="user.uc.netsDrApprovePayments">
                          <nit:checkbox path="fbo.user.uc.netsDrApprovePayments" />
                          <nit:message key="bus.user.uc.netsDrApprovePayments"/>
                        </label>
                      </div>
                    </div>
                  </div>
                  
<!-- 									<div id="netsAvtaleUseGlobal_div"> -->
										<!-- <div class="form-group">
											<label class="col-sm-4 control-label" for="user.uc.netsAccount"><nit:message key="bus.user.uc.netsAccount"/>:</label>
											<div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.uc.netsAccount" /></div>
										</div>-->
									
										<div class="form-group">
											<label class="col-sm-4 control-label" for="user.uc.netsAvtaleIdOcr"><nit:message key="bus.user.uc.netsAvtaleIdOcr"/>:</label>
											<div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.uc.netsAvtaleIdOcr" /></div>
										</div>
									
										<div class="form-group">
											<label class="col-sm-4 control-label" for="user.uc.netsAvtaleIdDr"><nit:message key="bus.user.uc.netsAvtaleIdDr"/>:</label>
											<div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.uc.netsAvtaleIdDr" /></div>
										</div>
										
										<div class="form-group">
                      <label class="col-sm-4 control-label" for="user.uc.netsDrFormatId"><nit:message key="bus.drFile.format"/>:</label>
                      <div class="col-sm-8">
                        <nit:select path="fbo.user.uc.netsDrFormatId" cssClass="form-control" items="${fbo.drFormats}" allowEmpty="true" valueProperty="id" labelProperty="name"   />
                      </div>
                    </div>
                    
<!-- 									</div> -->

                    <div class="form-group">
                      <label class="col-sm-4 control-label" for="user.uc.payoutDay"><nit:message key="bus.user.uc.payoutDay"/>:</label>
                      <div class="col-sm-8">
                        <nit:select path="fbo.user.uc.payoutDay" cssClass="form-control" items="${fbo.daysList}" allowEmpty="true" />
                      </div>
                    </div>
                    
                    <div class="form-group">
                      <label class="col-sm-4 control-label" for="user.uc.payoutWeekday"><nit:message key="bus.user.uc.payoutWeekday"/>:</label>
                      <div class="col-sm-8">
                        <nit:select path="fbo.user.uc.payoutWeekday" cssClass="form-control" items="${fbo.weekdays}" allowEmpty="true" valueProperty="value" labelProperty="label" />
                      </div>
                    </div>
								</c:if>
								
								
								
								<c:if test="${fbo.user.upCmIntEaccounting or fbo.user.upCmIntFiken or fbo.user.upCmIntPogo or fbo.user.upCmIntTripletex}">
									<div class="form-group">
										<div class="col-sm-offset-4 col-sm-8"><h3><nit:message key="common.accounting" /></h3></div>
									</div>
									<div class="form-group">
										<label class="col-sm-4 control-label" for="user.ui.accountingFirstDate"><nit:message key="bus.user.ui.accountingFirstDate"/>:</label>
										<div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.ui.accountingFirstDate" /></div>
									</div>
									
								</c:if>
								
								
								
								<c:if test="${fbo.user.upCmIntEaccounting}">
									<div class="form-group">
										<div class="col-sm-offset-4 col-sm-8"><h3>Visma eAccounting</h3></div>
									</div>
									<div class="form-group">
										<div class="col-sm-offset-4 col-sm-8">
											<div class="checkbox">
												<label class="" for="user.ui.eaccountingEnabled">
													<nit:checkbox path="fbo.user.ui.eaccountingEnabled" />
													eAccounting <nit:message key="common.enabled" lowerCase="true" />
												</label>
											</div>
										</div>
									</div>
									
									
									<c:if test="${isTest or isBn}">
										<div class="form-group">
											<label class="col-sm-4 control-label" for="">eAccounting <nit:message key="common.status" lowerCase="true" /> <nit:message key="common.test" lowerCase="true" />:</label>
											<div class="col-sm-8">
												<div class="alert alert-${not empty fbo.user.ui.eaccountingRefreshTokenTest ? 'success' : 'danger'}" role="alert">
													<nit:message key="${not empty fbo.user.ui.eaccountingRefreshTokenTest ? 'common.connected' : 'common.notConnected'}" />
												</div>
										
												<c:choose>
												<c:when test="${not empty fbo.user.ui.eaccountingRefreshTokenTest}">
													<c:if test="${wpd.isBn}">
														<a href="<nit:url value="/misc/integration.htm" pn1="action" pv1="disconnect" pn2="integration" pv2="eAccounting" pn3="env" pv3="false" />" class="btn btn-danger"><span>Disconnect (bn)</span></a> 
													</c:if>
												</c:when>
												<c:otherwise>
													
													<a href="<nit:url value="/misc/integration.htm" pn1="action" pv1="auth" pn2="integration" pv2="eAccounting" pn3="env" pv3="false" />" class="btn btn-primary"><span>Connect with eAccounting</span></a> 
												
												</c:otherwise>
												</c:choose>
											</div>
										</div>
									</c:if>
									
									<c:if test="${isProduction}">
										<div class="form-group">
											<label class="col-sm-4 control-label" for="">eAccounting <nit:message key="common.status" lowerCase="true" /> <nit:message key="common.production" lowerCase="true" />:</label>
											<div class="col-sm-8">
												<div class="alert alert-${not empty fbo.user.ui.eaccountingRefreshTokenProd ? 'success' : 'danger'}" role="alert">
													<nit:message key="${not empty fbo.user.ui.eaccountingRefreshTokenProd ? 'common.connected' : 'common.notConnected'}" />
												</div>
										
												<c:choose>
												<c:when test="${not empty fbo.user.ui.eaccountingRefreshTokenProd}">
													<c:if test="${wpd.isBn}">
														<a href="<nit:url value="/misc/integration.htm" pn1="action" pv1="disconnect" pn2="integration" pv2="eAccounting" pn3="env" pv3="true" />" class="btn btn-danger"><span>Disconnect (bn)</span></a> 
													</c:if>
												</c:when>
												<c:otherwise>
													
													<a href="<nit:url value="/misc/integration.htm" pn1="action" pv1="auth" pn2="integration" pv2="eAccounting" pn3="env" pv3="true" />" class="btn btn-primary"><span>Connect with eAccounting</span></a> 
												
												</c:otherwise>
												</c:choose>
											</div>
										</div>
									</c:if>
									
								</c:if>
								
								
								<c:if test="${fbo.user.upCmIntFiken}">
                  <div class="form-group">
                    <div class="col-sm-offset-4 col-sm-8"><h3>Fiken</h3></div>
                  </div>
                  
                  <c:if test="${isTest or isBn}">
                    <div class="form-group">
                      <div class="col-sm-offset-4 col-sm-8"><h4>Test</h4></div>
                    </div>
                   
                    <div class="form-group">
                      <label class="col-sm-4 control-label" for="">Fiken <nit:message key="common.status" lowerCase="true" />:</label>
                      <div class="col-sm-8">
                        <div class="alert alert-${fbo.user.ui.statusFikenTest ? 'success' : 'danger'}" role="alert">
                          <nit:message key="${fbo.user.ui.statusFikenTest ? 'common.connected' : 'common.notConnected'}" />
                        </div>
                        
                          <c:choose>
                           <c:when test="${not empty fbo.user.ui.fikenRefreshTokenTest}">
                             <c:if test="${wpd.isBn}">
                               <a href="<nit:url value="/misc/integration.htm" pn1="action" pv1="disconnect" pn2="integration" pv2="Fiken" pn3="env" pv3="false" />" class="btn btn-danger"><span>Disconnect (bn)</span></a> 
                             </c:if>
                           </c:when>
                           <c:otherwise>
                             <a href="<nit:url value="/misc/integration.htm" pn1="action" pv1="auth" pn2="integration" pv2="Fiken" pn3="env" pv3="false" />" class="btn btn-primary"><span>Connect with Fiken</span></a>   
                           </c:otherwise>
                          </c:choose>
              
                      </div>
                    </div>
                    
                  
                        
                  </c:if>
                  
                  <c:if test="${isProduction}">
                    <div class="form-group">
                      <div class="col-sm-offset-4 col-sm-8"><h4><nit:message key="common.production" /></h4></div>
                    </div>
                  
                    <div class="form-group">
                      <label class="col-sm-4 control-label" for="">Fiken <nit:message key="common.status" lowerCase="true" />:</label>
                      <div class="col-sm-8">
                        <div class="alert alert-${fbo.user.ui.statusFikenProd ? 'success' : 'danger'}" role="alert">
                          <nit:message key="${fbo.user.ui.statusFikenProd ? 'common.connected' : 'common.notConnected'}" />
                        </div>
              
               
                        <c:choose>
                         <c:when test="${not empty fbo.user.ui.fikenRefreshTokenProd}">
                           <c:if test="${wpd.isBn}">
                             <a href="<nit:url value="/misc/integration.htm" pn1="action" pv1="disconnect" pn2="integration" pv2="Fiken" pn3="env" pv3="true" />" class="btn btn-danger"><span>Disconnect (bn)</span></a> 
                           </c:if>
                         </c:when>
                         <c:otherwise>
                           <a href="<nit:url value="/misc/integration.htm" pn1="action" pv1="auth" pn2="integration" pv2="Fiken" pn3="env" pv3="true" />" class="btn btn-primary"><span>Connect with Fiken</span></a>   
                         </c:otherwise>
                        </c:choose>
                        
                      </div>
                    </div>
                  
                    
                  </c:if>
                </c:if>
                
                
                <c:if test="${fbo.user.upCmIntPogo}">
                  <div class="form-group">
                    <div class="col-sm-offset-4 col-sm-8"><h3>PowerOffice GO</h3></div>
                  </div>
                  
                  <c:if test="${isTest or isBn}">
                    <div class="form-group">
                      <div class="col-sm-offset-4 col-sm-8"><h4>Test</h4></div>
                    </div>
                   
                    <div class="form-group">
                      <label class="col-sm-4 control-label" for="">PowerOffice GO <nit:message key="common.status" lowerCase="true" />:</label>
                      <div class="col-sm-8">
                        <div class="alert alert-${fbo.user.ui.pogoStatusTest ? 'success' : 'danger'}" role="alert">
                          <nit:message key="${fbo.user.ui.pogoStatusTest ? 'common.connected' : 'common.notConnected'}" />
                        </div>
                        
                          <c:choose>
                           <c:when test="${not empty fbo.user.ui.pogoClientKeyTest}">
                             <c:if test="${wpd.isBn}">
                               <a href="<nit:url value="/misc/integration.htm" pn1="action" pv1="disconnect" pn2="integration" pv2="PowerOfficeGo" pn3="env" pv3="false" />" class="btn btn-danger"><span>Disconnect (bn)</span></a> 
                             </c:if>
                           </c:when>
                           <c:otherwise>
                             <a href="<nit:url value="/misc/integration.htm" pn1="action" pv1="auth" pn2="integration" pv2="PowerOfficeGo" pn3="env" pv3="false" />" class="btn btn-primary"><span>Connect with PowerOffice GO</span></a>   
                           </c:otherwise>
                          </c:choose>
              
                      </div>
                    </div>
                    
                  
                        
                  </c:if>
                  
                  <c:if test="${isProduction}">
                    <div class="form-group">
                      <div class="col-sm-offset-4 col-sm-8"><h4><nit:message key="common.production" /></h4></div>
                    </div>
                  
                    <div class="form-group">
                      <label class="col-sm-4 control-label" for="">PowerOffice GO <nit:message key="common.status" lowerCase="true" />:</label>
                      <div class="col-sm-8">
                        <div class="alert alert-${fbo.user.ui.pogoStatusProd ? 'success' : 'danger'}" role="alert">
                          <nit:message key="${fbo.user.ui.pogoStatusProd ? 'common.connected' : 'common.notConnected'}" />
                        </div>
              
               
                        <c:choose>
                         <c:when test="${not empty fbo.user.ui.pogoClientKeyProd}">
                           <c:if test="${wpd.isBn}">
                             <a href="<nit:url value="/misc/integration.htm" pn1="action" pv1="disconnect" pn2="integration" pv2="PowerOfficeGo" pn3="env" pv3="true" />" class="btn btn-danger"><span>Disconnect (bn)</span></a> 
                           </c:if>
                         </c:when>
                         <c:otherwise>
                           <a href="<nit:url value="/misc/integration.htm" pn1="action" pv1="auth" pn2="integration" pv2="PowerOfficeGo" pn3="env" pv3="true" />" class="btn btn-primary"><span>Connect with PowerOffice GO</span></a>   
                         </c:otherwise>
                        </c:choose>
                        
                      </div>
                    </div>
                  
                    
                  </c:if>
                
                    <%--
                    <div class="form-group">
                      <label class="col-sm-4 control-label" for="">PowerOffice GO <nit:message key="common.status" lowerCase="true" />:</label>
                      <div class="col-sm-8">
                        <div class="alert alert-${fbo.user.ui.pogoStatus ? 'success' : 'danger'}" role="alert">
                          <nit:message key="${fbo.user.ui.pogoStatus ? 'common.connected' : 'common.notConnected'}" />
                        </div>
              
               
                        <c:choose>
                         <c:when test="${not empty fbo.user.ui.pogoClientKey}">
                           <c:if test="${wpd.isBn}">
                             <a href="<nit:url value="/misc/integration.htm" pn1="action" pv1="disconnect" pn2="integration" pv2="PowerOfficeGo" pn3="env" pv3="${isProduction}" />" class="btn btn-danger"><span>Disconnect (bn)</span></a> 
                           </c:if>
                         </c:when>
                         <c:otherwise>
                           <a href="<nit:url value="/misc/integration.htm" pn1="action" pv1="auth" pn2="integration" pv2="PowerOfficeGo" pn3="env" pv3="${isProduction}" />" class="btn btn-primary"><span>Connect with PowerOffice GO</span></a>   
                         </c:otherwise>
                        </c:choose>
                        
                      </div>
                    </div>
                    --%>
                  
                </c:if>
                
                <c:if test="${fbo.user.upCmIntTripletex}">
                  <div class="form-group">
                    <div class="col-sm-offset-4 col-sm-8"><h3>Tripletex</h3></div>
                  </div>
                  
                  <c:if test="${isTest or isBn}">
                    <div class="form-group">
                      <div class="col-sm-offset-4 col-sm-8"><h4>Test</h4></div>
                    </div>
                   
                    <div class="form-group">
                      <label class="col-sm-4 control-label" for="">Tripletex <nit:message key="common.status" lowerCase="true" />:</label>
                      <div class="col-sm-8">
                        <div class="alert alert-${fbo.user.ui.tripletexStatusTest ? 'success' : 'danger'}" role="alert">
                          <nit:message key="${fbo.user.ui.tripletexStatusTest ? 'common.connected' : 'common.notConnected'}" />
                        </div>
                     
                        <%--
                          <c:choose>
                           <c:when test="${not empty fbo.user.ui.tripletexEmployeeTokenTest}">
                             <c:if test="${wpd.isBn}">
                               <a href="<nit:url value="/misc/integration.htm" pn1="action" pv1="disconnect" pn2="integration" pv2="Tripletex" pn3="env" pv3="false" />" class="btn btn-danger"><span>Disconnect (bn)</span></a> 
                             </c:if>
                           </c:when>
                           <c:otherwise>
                             <a href="<nit:url value="/misc/integration.htm" pn1="action" pv1="auth" pn2="integration" pv2="Tripletex" pn3="env" pv3="false" />" class="btn btn-primary"><span>Connect with Tripletex</span></a>   
                           </c:otherwise>
                          </c:choose>
                          --%>
              
                      </div>
                    </div>
                   <div class="form-group">
                      <label class="col-sm-4 control-label" for="user.ui.tripletexEmployeeTokenTest"><nit:message key="bus.user.ui.tripletexEmployeeToken" defaultText="API-nøkkel"/> Test:</label>
                      <div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.ui.tripletexEmployeeTokenTest" /></div>
                    </div>
                    <div class="form-group">
                      <label class="col-sm-4 control-label" for="user.ui.tripletexSessionTokenTest"><nit:message key="bus.user.ui.tripletexSessionToken" defaultText="SessionToken"/> Test:</label>
                      <div class="col-sm-8"><p class="form-control-static"><c:out value="${fbo.user.ui.tripletexSessionTokenTest}" /></p></div>
                    </div>
                        
                  </c:if>
                  
                  <c:if test="${isProduction}">
                    <div class="form-group">
                      <div class="col-sm-offset-4 col-sm-8"><h4><nit:message key="common.production" /></h4></div>
                    </div>
                  
                    <div class="form-group">
                      <label class="col-sm-4 control-label" for="">Tripletex <nit:message key="common.status" lowerCase="true" />:</label>
                      <div class="col-sm-8">
                        <div class="alert alert-${fbo.user.ui.tripletexStatusProd ? 'success' : 'danger'}" role="alert">
                          <nit:message key="${fbo.user.ui.tripletexStatusProd ? 'common.connected' : 'common.notConnected'}" />
                        </div>
                       
                        
               <%--
                        <c:choose>
                         <c:when test="${not empty fbo.user.ui.tripletexEmployeeTokenProd}">
                           <c:if test="${wpd.isBn}">
                             <a href="<nit:url value="/misc/integration.htm" pn1="action" pv1="disconnect" pn2="integration" pv2="Tripletex" pn3="env" pv3="true" />" class="btn btn-danger"><span>Disconnect (bn)</span></a> 
                           </c:if>
                         </c:when>
                         <c:otherwise>
                           <a href="<nit:url value="/misc/integration.htm" pn1="action" pv1="auth" pn2="integration" pv2="Tripletex" pn3="env" pv3="true" />" class="btn btn-primary"><span>Connect with Tripletex</span></a>   
                         </c:otherwise>
                        </c:choose>
                         --%>
                      </div>
                    </div>
                   <div class="form-group">
                      <label class="col-sm-4 control-label" for="user.ui.tripletexEmployeeTokenProd"><nit:message key="bus.user.ui.tripletexEmployeeToken" defaultText="API-nøkkel"/>:</label>
                      <div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.ui.tripletexEmployeeTokenProd" /></div>
                    </div>
                    
                  </c:if>
                
        
                  
                </c:if>
                
                <c:if test="${fbo.user.upCmIntZettle}">
                  <div class="form-group">
                      <div class="col-sm-offset-4 col-sm-8"><h3>Zettle</h3></div>
                  </div>
                  
                  <c:if test="${isTest or isBn}">
                    <div class="form-group">
                      <div class="col-sm-offset-4 col-sm-8"><h4>Test</h4></div>
                    </div>
                   
                    <div class="form-group">
                      <label class="col-sm-4 control-label" for="">Zettle <nit:message key="common.status" lowerCase="true" />:</label>
                      <div class="col-sm-8">
                        <div class="alert alert-${fbo.user.ui.zettleStatusTest ? 'success' : 'danger'}" role="alert">
                          <nit:message key="${fbo.user.ui.zettleStatusTest ? 'common.connected' : 'common.notConnected'}" />
                        </div>
                        
                          <c:choose>
                           <c:when test="${not empty fbo.user.ui.zettleRefreshTokenTest}">
                             <c:if test="${wpd.isBn}">
                               <a href="<nit:url value="/misc/integration.htm" pn1="action" pv1="disconnect" pn2="integration" pv2="Zettle" pn3="env" pv3="false" />" class="btn btn-danger"><span>Disconnect (bn)</span></a> 
                             </c:if>
                           </c:when>
                           <c:otherwise>
                             <a href="<nit:url value="/misc/integration.htm" pn1="action" pv1="auth" pn2="integration" pv2="Zettle" pn3="env" pv3="false" />" class="btn btn-primary"><span>Connect with Zettle</span></a>   
                           </c:otherwise>
                          </c:choose>
              
                      </div>
                    </div>
                    
                  
                        
                  </c:if>
                  
                  <c:if test="${isProduction}">
                    <div class="form-group">
                      <div class="col-sm-offset-4 col-sm-8"><h4><nit:message key="common.production" /></h4></div>
                    </div>
                  
                    <div class="form-group">
                      <label class="col-sm-4 control-label" for="">Zettle <nit:message key="common.status" lowerCase="true" />:</label>
                      <div class="col-sm-8">
                        <div class="alert alert-${fbo.user.ui.zettleStatusProd ? 'success' : 'danger'}" role="alert">
                          <nit:message key="${fbo.user.ui.zettleStatusProd ? 'common.connected' : 'common.notConnected'}" />
                        </div>
              
               
                        <c:choose>
                         <c:when test="${not empty fbo.user.ui.zettleRefreshTokenProd}">
                           <c:if test="${wpd.isBn}">
                             <a href="<nit:url value="/misc/integration.htm" pn1="action" pv1="disconnect" pn2="integration" pv2="Zettle" pn3="env" pv3="true" />" class="btn btn-danger"><span>Disconnect (bn)</span></a> 
                           </c:if>
                         </c:when>
                         <c:otherwise>
                           <a href="<nit:url value="/misc/integration.htm" pn1="action" pv1="auth" pn2="integration" pv2="Zettle" pn3="env" pv3="true" />" class="btn btn-primary"><span>Connect with Zettle</span></a>   
                         </c:otherwise>
                        </c:choose>
                        
                      </div>
                    </div>
                  
                    
                  </c:if>

                  <%--
                  <div class="form-group">
                    <label class="col-sm-4 control-label" for="">Zettle <nit:message key="common.status" lowerCase="true" />:</label>
                    <div class="col-sm-8">
                      <div class="alert alert-${fbo.user.ui.zettleStatus ? 'success' : 'danger'}" role="alert">
                        <nit:message key="${fbo.user.ui.zettleStatus ? 'common.connected' : 'common.notConnected'}" />
                      </div>
            
             
                      <c:choose>
                       <c:when test="${not empty fbo.user.ui.zettleRefreshToken}">
                         <c:if test="${wpd.isBn}">
                           <a href="<nit:url value="/misc/integration.htm" pn1="action" pv1="disconnect" pn2="integration" pv2="Zettle" pn3="env" pv3="${isProduction}" />" class="btn btn-danger"><span>Disconnect (bn)</span></a> 
                         </c:if>
                       </c:when>
                       <c:otherwise>
                         <a href="<nit:url value="/misc/integration.htm" pn1="action" pv1="auth" pn2="integration" pv2="Zettle" pn3="env" pv3="${isProduction}" />" class="btn btn-primary"><span>Connect with Zettle</span></a>   
                       </c:otherwise>
                      </c:choose>
                      
                    </div>
                  </div>
                  --%> 
                  
                </c:if>
                
                
                                <c:if test="${fbo.user.upCmIntVisBook}">
                                    <div class="form-group">
                                        <div class="col-sm-offset-4 col-sm-8"><h3>VisBook</h3></div>
                                    </div>
                                    
                                    <c:if test="${isTest or isBn}">
                                        <div class="form-group">
                                            <div class="col-sm-offset-4 col-sm-8"><h4>Test</h4></div>
                                        </div>
                                        <div class="form-group">
                                            <label class="col-sm-4 control-label" for="user.ui.visbookUsernameTest">VisBook <nit:message key="common.username" lowerCase="true"/>:</label>
                                            <div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.ui.visbookUsernameTest" /></div>
                                        </div>
                                        <div class="form-group">
                                            <label class="col-sm-4 control-label" for="user.ui.visbookPasswordTest">VisBook <nit:message key="common.password" lowerCase="true"/>:</label>
                                            <div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.ui.visbookPasswordTest" password="true" /></div>
                                        </div>
                                            
                                        
                                    </c:if>
                                    
                                    <c:if test="${isProduction}">
                                        <div class="form-group">
                                            <div class="col-sm-offset-4 col-sm-8"><h4><nit:message key="common.production" /></h4></div>
                                        </div>
                                        <div class="form-group">
                                            <label class="col-sm-4 control-label" for="user.ui.visbookUsernameProd">VisBook <nit:message key="common.username" lowerCase="true"/>:</label>
                                            <div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.ui.visbookUsernameProd" /></div>
                                        </div>
                                        <div class="form-group">
                                            <label class="col-sm-4 control-label" for="user.ui.visbookPasswordProd">VisBook <nit:message key="common.password" lowerCase="true"/>:</label>
                                            <div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.ui.visbookPasswordProd" password="true" /></div>
                                        </div>
                 
                                    </c:if>
                                </c:if>
									
								<c:if test="${fbo.user.upCmIntTrekkSoft}">
                                    <div class="form-group">
                                        <div class="col-sm-offset-4 col-sm-8"><h3>TrekkSoft</h3></div>
                                    </div>
                                    
                                    <c:if test="${isTest or isBn}">
                                        <div class="form-group">
                                            <div class="col-sm-offset-4 col-sm-8"><h4>Test</h4></div>
                                        </div>
                                        <div class="form-group">
                                            <label class="col-sm-4 control-label" for="user.ui.trekksoftUsernameTest">TrekkSoft <nit:message key="common.username" lowerCase="true"/>:</label>
                                            <div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.ui.trekksoftUsernameTest" /></div>
                                        </div>
                                        <div class="form-group">
                                            <label class="col-sm-4 control-label" for="user.ui.trekksoftPasswordTest">TrekkSoft <nit:message key="common.password" lowerCase="true"/>:</label>
                                            <div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.ui.trekksoftPasswordTest" password="true" /></div>
                                        </div>
                                            
                                        
                                    </c:if>
                                    
                                    <c:if test="${isProduction}">
                                        <div class="form-group">
                                            <div class="col-sm-offset-4 col-sm-8"><h4><nit:message key="common.production" /></h4></div>
                                        </div>
                                        <div class="form-group">
                                            <label class="col-sm-4 control-label" for="user.ui.trekksoftUsernameProd">TrekkSoft <nit:message key="common.username" lowerCase="true"/>:</label>
                                            <div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.ui.trekksoftUsernameProd" /></div>
                                        </div>
                                        <div class="form-group">
                                            <label class="col-sm-4 control-label" for="user.ui.trekksoftPasswordProd">TrekkSoft <nit:message key="common.password" lowerCase="true"/>:</label>
                                            <div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.ui.trekksoftPasswordProd" password="true" /></div>
                                        </div>
                 
                                    </c:if>
                                </c:if>
                                    
                                
								
								<c:if test="${fbo.user.upCmExternalOrderSystem}">
									<div class="form-group">
										<div class="col-sm-offset-4 col-sm-8"><h3><nit:message key="web.common.user.company.externalOrderSystem"/></h3></div>
									</div>
									
									<div class="form-group">
										<label class="col-sm-4 control-label" for="user.uc.eosSkiStarIdentity"><nit:message key="bus.user.uc.eosSkiStarIdentity"/>:</label>
										<div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.uc.eosSkiStarIdentity" /></div>
									</div>
									<div class="form-group">
										<label class="col-sm-4 control-label" for="user.uc.eosSkiStarApiKey"><nit:message key="bus.user.uc.eosSkiStarApiKey"/>:</label>
										<div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.uc.eosSkiStarApiKey" /></div>
									</div>
									<div class="form-group">
										<div class="col-sm-offset-4 col-sm-8">
											<div class="checkbox">
												<label class="" for="user.uc.eosSkiStarPublic">
													<nit:checkbox path="fbo.user.uc.eosSkiStarPublic" />
													<nit:message key="bus.user.uc.eosSkiStarPublic"/>
												</label>
											</div>
										</div>
									</div>
								</c:if>
								
								
								<div class="form-group">
									<div class="col-sm-offset-4 col-sm-8"><h3><nit:message key="common.misc"/></h3></div>
								</div>
								
								<div class="form-group">
									<label class="col-sm-4 control-label" for="user.uc.propertyTypeConfig"><nit:message key="bus.user.uc.propertyTypeConfig"/>:</label>
									<div class="col-sm-8"><nit:select cssClass="form-control " path="fbo.user.uc.propertyTypeConfig" items="${fbo.propertyTypeConfigs}" allowEmpty="false" valueProperty="id" labelProperty="name" /></div>
								</div>
								<div class="form-group">
									<label class="col-sm-4 control-label" for="user.uc.vatConfig"><nit:message key="bus.user.uc.vatConfig"/>:</label>
									<div class="col-sm-8"><nit:select cssClass="form-control " path="fbo.user.uc.vatConfig" items="${fbo.vatConfigs}" allowEmpty="true" valueProperty="id" labelProperty="mkName" /></div>
								</div>
								<div class="form-group">
									<label class="col-sm-4 control-label" for="user.uc.invoiceStyle"><nit:message key="bus.user.uc.invoiceStyle"/>:</label>
									<div class="col-sm-8"><nit:select cssClass="form-control " path="fbo.user.uc.invoiceStyle" items="${fbo.invoiceStyles}" allowEmpty="true" valueProperty="id" labelProperty="enumName" /></div>
								</div>
								
								<div class="form-group">
									<label class="col-sm-4 control-label" for=""><nit:message key="common.logo"/>:</label>
									<div class="col-sm-8">
										<div class="" >
											<c:if test="${not empty fbo.user.id}">

												<c:set var="logoUrl" value="" />
												<c:if test="${fbo.user.uc.hasLogo}"><nit:url var="logoUrl" value="${fbo.user.uc.logoUrlPath350x50}" /></c:if>
												<img id="companyLogo" src="${logoUrl}" style="" alt="<nit:message key="web.common.user.noLogo" ct="false" />" />
											</c:if>
										</div>
									</div>
								</div>
							
								<div class="form-group">
									<div class="col-sm-offset-4 col-sm-8">
										<div class="" id="companyLogoUpload" >
											<noscript><p><nit:message key="web.common.image.enableJavaScript"/></p></noscript> 
										</div>
									</div>
								</div>
								
								<div class="form-group">
									<label class="col-sm-4 control-label" for=""><nit:message key="web.common.user.ogImage"/>:</label>
									<div class="col-sm-8">
										<div class="" >
											<c:if test="${not empty fbo.user.id}">

												<c:set var="ogImageUrl" value="" />
												<c:if test="${fbo.user.uc.hasOgImage}"><nit:url var="ogImageUrl" value="${fbo.user.uc.ogImageUrl}" /></c:if>
												<img id="companyOgImage" src="${ogImageUrl}" class="img-responsive" style="" alt="<nit:message key="web.common.user.noOgImage" ct="false" />" />
											</c:if>
										</div>
									</div>
								</div>
							
								<div class="form-group">
									<div class="col-sm-offset-4 col-sm-8">
										<div class="" id="companyOgImageUpload" >
											<noscript><p><nit:message key="web.common.image.enableJavaScript"/></p></noscript> 
										</div>
									</div>
								</div>
								
								<%-- 
								<div class="form-group">
									<div class="col-sm-offset-4 col-sm-8"><h3><nit:message key="web.common.user.company.iframe"/></h3></div>
								</div>
								
								<div class="form-group">
									<div class="col-sm-offset-4 col-sm-8">
										<div class="checkbox">
											<label class="" for="user.uc.iframeShowMenu">
												<nit:checkbox path="fbo.user.uc.iframeShowMenu" />
												<nit:message key="bus.user.uc.iframeShowMenu"/>
											</label>
										</div>
									</div>
								</div>
								
							
								<div class="form-group">
									<label class="col-sm-4 control-label" for="user.uc.iframeUrlMain"><nit:message key="bus.user.uc.iframeUrlMain"/>:</label>
									<div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.uc.iframeUrlMain" /></div>
								</div>
								--%>
								
								
								<c:if test="${fbo.user.upCBooking}">
									<div class="form-group">
										<div class="col-sm-offset-4 col-sm-8">
											<h3><nit:message key="bus.product.gcs"/></h3>
											<p><nit:message key="web.common.user.guestCategoriesDesc"/></p>
										</div>
									</div>
									
									
									<c:forEach var="gc" begin="0" end="4" varStatus="status">
										<div class="form-group gcControlGroup">
											<label class="col-sm-4 control-label"><nit:message key="common.category"/> ${gc}:</label>
											<div class="col-sm-8"><p class="form-control-static"><a href="#" class="translateLink" data-message-key="bus.bookingItem.gc${gc}"><nit:message key="bus.bookingItem.gc${gc}" ct="false" /></a></p></div>
										</div>
									</c:forEach>
								</c:if>
                
                <c:if test="${not empty fbo.user.uc.customUserProperties }">
                  <div id="customUserProperties">
                  
                    <div class="form-group">
                      <div class="col-sm-offset-4 col-sm-8">
                        <h3><nit:message key="web.common.user.customUserProperties"/></h3>
                      </div>
                    </div>
                    
                    
                    <c:forEach var="cup" items="${fbo.user.uc.customUserProperties}" varStatus="status">
                      <div class="form-group">
                        <label class="col-sm-4 control-label">${cup.idx}:</label>
                        <div class="col-sm-8">
                          <p class="form-control-static">
                            <nit:message key="common.name"/>: ${cup.name}<br/>
                            <nit:message key="common.type"/>: ${cup.type}<br/>
                            <nit:message key="common.required"/>: <nit:message key="common.${cup.required}"/><br/>
                            <nit:message key="common.translate"/>: <a href="#" class="translateLink" data-message-key="${cup.mk}"><nit:message key="${cup.mk}" ct="false" /></a>
                          </p>
                        </div>
                      </div>
                    </c:forEach>
                  </div>
                </c:if>
								
								
							
								<div class="form-group">
									<div class="col-sm-offset-4 col-sm-8"><h3><nit:message key="web.common.user.company.customHeaderAndFooter" defaultText="Header / Footer"/></h3></div>
								</div>
								
								<div class="form-group">
									<label class="col-sm-4 control-label" for="user.uc.seoTitle"><nit:message key="bus.user.uc.seoTitle"/>:</label>
									<div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.uc.seoTitle" /></div>
								</div>
								<div class="form-group">
									<label class="col-sm-4 control-label" for="user.uc.seoDescription"><nit:message key="bus.user.uc.seoDescription"/>:</label>
									<div class="col-sm-8"><nit:textarea cssClass="form-control " style="height: 200px;" path="fbo.user.uc.seoDescription" /></div>
								</div>
								
								<div class="form-group">
									<label class="col-sm-4 control-label" for="user.uc.customHeaderContent"><nit:message key="bus.user.uc.customHeaderContent"/>:</label>
									<div class="col-sm-8"><nit:textarea cssClass="form-control " style="height: 200px;" path="fbo.user.uc.customHeaderContent" /></div>
								</div>
								<div class="form-group">
									<label class="col-sm-4 control-label" for="user.uc.customFooterContent"><nit:message key="bus.user.uc.customFooterContent"/>:</label>
									<div class="col-sm-8"><nit:textarea cssClass="form-control " style="height: 100px;" path="fbo.user.uc.customFooterContent" /></div>
								</div>
			
						
							</div>
							<div class="tab-pane" id="coaTab">
								
						
			
								<!-- <div class="">
									<h3><nit:message key="bus.user.uc.coa" /></h3>
								</div>-->
									
								<div class="form-group">
									<label class="col-sm-4 control-label" for="user.uc.customerNumberStart"><nit:message key="bus.user.uc.customerNumberStart"/>:</label>
									<div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.uc.customerNumberStart" /></div>
								</div>
										
							
							
								<div class="form-group">
									<label class="col-sm-4 control-label" for="user.uc.providerNumberStart"><nit:message key="bus.user.uc.providerNumberStart"/>:</label>
									<div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.uc.providerNumberStart" /></div>
								</div>
							
										
								<div class="form-group">
									<label class="col-sm-4 control-label" for="user.uc.invoiceNumberStart"><nit:message key="bus.user.uc.invoiceNumberStart"/>:</label>
									<div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.uc.invoiceNumberStart" /></div>
								</div>
	
								<!-- 
								<div class="form-group">
									<label class="col-sm-4 control-label" for="user.uc.coaBankBooking"><nit:message key="bus.user.uc.coaBankBooking"/>:</label>
									<div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.uc.coaBankBooking" /></div>
								</div>

								<div class="form-group">
									<label class="col-sm-4 control-label" for="user.uc.coaBankCommission"><nit:message key="bus.user.uc.coaBankCommission"/>:</label>
									<div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.uc.coaBankCommission" /></div>
								</div>
							
							
								<div class="form-group">
									<label class="col-sm-4 control-label" for="user.uc.coaAccruedCommission"><nit:message key="bus.user.uc.coaAccruedCommission"/>:</label>
									<div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.uc.coaAccruedCommission" /></div>
								</div>
								
						
								<div class="form-group">
									<label class="col-sm-4 control-label" for="user.uc.coaAdvanceFromCustomers"><nit:message key="bus.user.uc.coaAdvanceFromCustomers"/>:</label>
									<div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.uc.coaAdvanceFromCustomers" /></div>
								</div>
								
								-->

								
								
								<c:if test="${fbo.user.upCmIntEaccounting}">
									<h2>eAccounting</h2>
									<div class="form-group">
										<div class="col-sm-4 text-right"><nit:message key="common.account"/></div>
										<div class="col-sm-4">eAccounting <nit:message key="common.account" lowerCase="true" /></div>
										<div class="col-sm-4"><nit:message key="common.default"/></div>
									</div>
									
									<c:forEach var="account" items="${fbo.accountingIntegrationAccounts}" varStatus="status">
										<c:if test="${not empty account.eaccountingDefaultAccount}">
											<div class="form-group">
												<label class="col-sm-4 control-label" for="user.ui.eaccountingAccounts[${account.id}]"><nit:message key="${account.mk}" />:</label>
												<div class="col-sm-4"><nit:text path="fbo.user.ui.eaccountingAccounts[${account.id}]" cssClass="form-control" style="" /></div>
												<div class="col-sm-4">${account.eaccountingDefaultAccount}</div>
											</div>
										</c:if>
									
									</c:forEach>
								</c:if>
								
								<c:if test="${fbo.user.upCmIntFiken}">
                  <h2>Fiken</h2>
                  <div class="form-group">
                    <div class="col-sm-4 text-right"><nit:message key="common.account"/></div>
                    <div class="col-sm-4">Fiken <nit:message key="common.account" lowerCase="true" /></div>
                    <div class="col-sm-4"><nit:message key="common.default"/></div>
                  </div>
                  
                  <c:forEach var="account" items="${fbo.accountingIntegrationAccounts}" varStatus="status">
                    <c:if test="${not empty account.fikenDefaultAccount}">
                      <div class="form-group">
                        <label class="col-sm-4 control-label" for="user.ui.fikenAccounts[${account.id}]"><nit:message key="${account.mk}" />:</label>
                        <div class="col-sm-4"><nit:text path="fbo.user.ui.fikenAccounts[${account.id}]" cssClass="form-control" style="" /></div>
                        <div class="col-sm-4">${account.fikenDefaultAccount}</div>
                      </div>
                    </c:if>
                  
                  </c:forEach>
                </c:if>
                
                <c:if test="${fbo.user.upCmIntPogo}">
                  <h2>PowerOfficeGo</h2>
                  <div class="form-group">
                    <div class="col-sm-4 text-right"><nit:message key="common.account"/></div>
                    <div class="col-sm-4">POGO <nit:message key="common.account" lowerCase="true" /></div>
                    <div class="col-sm-4"><nit:message key="common.default"/></div>
                  </div>
                  
                  <c:forEach var="account" items="${fbo.accountingIntegrationAccounts}" varStatus="status">
                    <c:if test="${not empty account.pogoDefaultAccount}">
                      <div class="form-group">
                        <label class="col-sm-4 control-label" for="user.ui.pogoAccounts[${account.id}]"><nit:message key="${account.mk}" />:</label>
                        <div class="col-sm-4"><nit:text path="fbo.user.ui.pogoAccounts[${account.id}]" cssClass="form-control" style="" /></div>
                        <div class="col-sm-4">${account.pogoDefaultAccount}</div>
                      </div>
                    </c:if>
                  
                  </c:forEach>
                </c:if>
					      
					      <c:if test="${fbo.user.upCmIntTripletex}">
                  <h2>Tripletex</h2>
                  <div class="form-group">
                    <div class="col-sm-4 text-right"><nit:message key="common.account"/></div>
                    <div class="col-sm-4">Tripletex <nit:message key="common.account" lowerCase="true" /></div>
                    <div class="col-sm-4"><nit:message key="common.default"/></div>
                  </div>
                  
                  <c:forEach var="account" items="${fbo.accountingIntegrationAccounts}" varStatus="status">
                    <c:if test="${not empty account.pogoDefaultAccount}"> <!--TODO -->
                      <div class="form-group">
                        <label class="col-sm-4 control-label" for="user.ui.tripletexAccounts[${account.id}]"><nit:message key="${account.mk}" />:</label>
                        <div class="col-sm-4"><nit:text path="fbo.user.ui.tripletexAccounts[${account.id}]" cssClass="form-control" style="" /></div>
                        <div class="col-sm-4">${account.pogoDefaultAccount}</div>
                      </div>
                    </c:if>
                  
                  </c:forEach>
                </c:if>
          
						
							</div>
							<div class="tab-pane" id="cbapiTab">
                
                <div class="form-group">
                  <label class="col-sm-4 control-label" for="user.uc.cbapiHostnameProd"><nit:message key="bus.user.uc.cbapiHostname"/>:</label>
                  <div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.uc.cbapiHostnameProd" /></div>
                </div>
              
                <c:if test="${wpd.isBn}">
                  <div class="form-group">
                    <label class="col-sm-4 control-label" for="user.uc.cbapiHostnameTest"><nit:message key="bus.user.uc.cbapiHostname"/> (TEST):</label>
                    <div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.uc.cbapiHostnameTest" /></div>
                  </div>
                </c:if>
                    
                 <div class="form-group">
                  <label class="col-sm-4 control-label" for="user.uc.customShortLinkDomain"><nit:message key="bus.user.uc.customShortLinkDomain" defaultText="Kort URL-domene" />:</label>
                  <div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.uc.customShortLinkDomain" /></div>
                </div>
                    
							</div>
						</c:if>

    */

      
    for (let ctrl of ucControls) {
      // ctrl.valuePath =  'ucObj.' + ctrl.key; 
    }
    
    controls.push(new DynformControl({ 
      key: 'ucObj',
      controlType: 'formGroup',
      children: ucControls
    }));
  }
        
        


    // Tags osv:
    /*
<c:if test="${not isUserProfile or user.isAdminSu}">
							<div class="tab-pane" id="tagsEtcTab">
	
								<div id="tagsCtx">
									<div class="form-group">
										<label class="col-sm-4 control-label" for="newTag"><nit:message key="common.tags"/>:</label>
										<div class="col-sm-5">
											<input type="text" id="newTag" class="newTag form-control" style="" value=""  />
										</div>
										<div class="col-sm-3">
											<a href="#" id="addTag" class="addTag btn btn-default"><i class="glyphicon glyphicon-tag"></i> <nit:message key="common.addTag" ct="false" /></a>
										</div>
									</div>
									<div class="form-group">
										<div class="col-sm-offset-4 col-sm-8">
											<div id="" class="tagsDiv"></div>
										</div>
									</div>
								</div>
								<div class="form-group mt20">
									<label class="col-sm-4 control-label" for="user.notes"><nit:message key="common.notes"/>:</label>
									<div class="col-sm-8"><nit:textarea cssClass="form-control " path="fbo.user.notes" style="height: 100px;" /></div>
								</div>
								
								
								<c:if test="${not empty wpdm.customUserRoles}">
									<div id="customUserRolesCtx">
										<input type="hidden" name="customUserRolesAsString" id="customUserRolesAsString" value="${fbo.user.customUserRolesAsString}" />
										<div class="form-group">
											<label class="col-sm-4 control-label" for="newCustomUserRole"><nit:message key="bus.user.customUserRoles"/>:</label>
											<div class="col-sm-5">
												<input type="text" id="newCustomUserRole" class="newTag form-control" style="" value=""  />
											</div>
											<div class="col-sm-3">
												<a href="#" id="addCustomUserRole" class="addTag btn btn-default"><i class="glyphicon glyphicon-tag"></i> <nit:message key="common.addTag" ct="false" /></a>
											</div>
										</div>
										<div class="form-group">
											<div class="col-sm-offset-4 col-sm-8">
												<div id="customUserRolesDiv" class="tagsDiv"></div>
											</div>
										</div>
									</div>
								</c:if>
									
						
							</div>
						</c:if>

            */


            /*
Company:
		<div class="form-group">
									<label class="col-sm-4 control-label" for="user.shortName"><nit:message key="common.shortName"/> (<nit:message key="common.max" lowerCase="true" /> 11 <nit:message key="common.characters" lowerCase="true" />):</label>
									<div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.shortName" /></div> <%--  extraAttr="data-jp-max-length='11'" --%>
								</div>
							
    	<c:if test="${fbo.user.isCompany}">
									<div class="form-group">
										<label class="col-sm-4 control-label" for="user.uc.commissionAccount"><nit:message key="bus.user.uc.commissionAccount"/>:</label>
										<div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.uc.commissionAccount" /></div>
									</div>
								</c:if>
							
								<div class="form-group">
									<label class="col-sm-4 control-label" for="user.iban"><nit:message key="bus.user.iban"/>:</label>
									<div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.iban" /></div>
								</div>
							
								<div class="form-group">
									<label class="col-sm-4 control-label" for="user.bic"><nit:message key="bus.user.bic"/>:</label>
									<div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.bic" /></div>
								</div>
								<div class="tab-pane" id="cbapiTab">
                
                <div class="form-group">
                  <label class="col-sm-4 control-label" for="user.uc.cbapiHostnameProd"><nit:message key="bus.user.uc.cbapiHostname"/>:</label>
                  <div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.uc.cbapiHostnameProd" /></div>
                </div>
              
                <c:if test="${wpd.isBn}">
                  <div class="form-group">
                    <label class="col-sm-4 control-label" for="user.uc.cbapiHostnameTest"><nit:message key="bus.user.uc.cbapiHostname"/> (TEST):</label>
                    <div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.uc.cbapiHostnameTest" /></div>
                  </div>
                </c:if>
                    
                 <div class="form-group">
                  <label class="col-sm-4 control-label" for="user.uc.customShortLinkDomain"><nit:message key="bus.user.uc.customShortLinkDomain" defaultText="Kort URL-domene" />:</label>
                  <div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.user.uc.customShortLinkDomain" /></div>
                </div>
                    
							</div>
						</c:if>
            
            




            CONTAKCTS:

<c:if test="${not empty fbo.user.id and (isCompany or fbo.user.isProvider or not isUserProfile)}"> <%--  --%>
							<div class="tab-pane" id="contactsTab">
								
						
							
								<div class="" style="">
									<h2><nit:message key="web.common.user.addChangeContact" /></h2>
								</div>
								
								<div class="bs-callout bs-callout-info mt5"> 
									<nit:message key="web.common.user.info.contactDesc"/>
								</div>
								
								<div>
									
									<div class="form-group">
										<label class="col-sm-4 control-label" for="contact.type"><nit:message key="bus.contact.type"/>:</label>
										<div class="col-sm-8">
											<nit:select cssClass="form-control " path="fbo.contact.type" items="${fbo.contactTypes}" enabled="${empty fbo.contact.id}" valueProperty="id" labelProperty="mk" />
											<%--
											<c:choose>
											<c:when test="${empty fbo.contact.id}">
												<nit:select cssClass="form-control " path="fbo.contact.type" items="${fbo.contactTypes}" valueProperty="id" labelProperty="mk" />
											</c:when>
											<c:otherwise>
												<input type="hidden" name="contact.type" value="${fbo.contact.type}" />
<
												<p class="form-control-static"><nit:message key="${fbo.contact.typeEnum.mk}"/></p>
											</c:otherwise>
											</c:choose>
											--%>
										</div>
									</div> 
								
									<div class="form-group">
										<label class="col-sm-4 control-label" for="contact.name"><nit:message key="common.name"/>:</label>
										<div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.contact.name" /></div>
									</div>
									
									<div class="form-group">
										<label class="col-sm-4 control-label" for="contact.email"><nit:message key="common.email"/>:</label>
										<div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.contact.email" /></div>
									</div>
									
									<div class="form-group">
										<label class="col-sm-4 control-label" for="contact.telephone"><nit:message key="common.telephone"/>:</label>
										<div class="col-sm-8"><nit:text cssClass="form-control " path="fbo.contact.telephone" /></div>
									</div>
									
							
									<div class="form-group">
										<label class="col-sm-4 control-label" for="contact.comment"><nit:message key="common.comment"/>:</label>
										<div class="col-sm-8"><nit:textarea cssClass="form-control " path="fbo.contact.comment" style="height: 50px;" /></div>
									</div>
									
									<div class="form-group">
										<div class="col-sm-offset-4 col-sm-8">
											<input type="button" data-name="saveContact" class="submitButton btn btn-default" value="<nit:message key="web.common.user.saveContact" ct="false" />" />
											<input type="button" data-name="newContact" class="submitButton btn btn-default" value="<nit:message key="common.new" ct="false" />" />
										</div>
									</div>
								</div>
						
			
								<div class="mt20 table-responsive" id="contactTableDiv">
									<table class="table">
										<thead>
										<tr>
											<th class="text-left"><nit:message key="bus.contact.type" /></th>
											<th class="text-left"><nit:message key="common.email" /></th>
											<th class="text-left"><nit:message key="common.name" /></th>
											<th class="text-left"><nit:message key="common.telephone" /></th>
											<th class="text-left"><nit:message key="common.comment" /></th>
											<th class="text-left"><nit:message key="common.updated" /></th>
											<th class="text-left"><nit:message key="common.change" /></th>
											<th class="text-left"><nit:message key="common.delete" /></th>
										</tr>
										</thead>
										<tbody>
										<c:forEach var="contact" items="${fbo.user.contacts}" varStatus="status">
											<tr> 
												<td class="text-left nowrap"><nit:message key="${contact.typeEnum.mk}" /></td>
												<td class="text-left nowrap"><c:out value="${contact.email}" /></td>
												<td class="text-left nowrap"><c:out value="${contact.name}" /></td>
												<td class="text-left nowrap"><c:out value="${contact.telephone}" /></td>
												<td class="text-left"><c:out value="${contact.comment}" /></td>
												<td class="text-left nowrap"><fmt:formatDate value="${contact.time}" type="both" dateStyle="short" timeStyle="short" timeZone="${user.timeZone.ID}" /></td>
												
												<nit:url var="changeContactUrl" value="/common/user.htm">
													<nit:param name="id" value="${fbo.user.id}" />
													<nit:param name="tab" value="contactsTab"/>
													<nit:param name="contactId" value="${contact.id}" />
												</nit:url>
												<td class="text-left nowrap"><a href="${changeContactUrl}" class="btn btn-default btn-xs"><nit:message key="common.change" ct="true" /></a></td>
												
												<nit:url var="deleteContactUrl" value="/common/user.htm">
													<nit:param name="id" value="${fbo.user.id}" />
													<nit:param name="tab" value="contactsTab"/>
													<nit:param name="deleteContactId" value="${contact.id}" />
												</nit:url>
												<td class="text-left nowrap"><a href="${deleteContactUrl}" class="btn btn-default btn-xs"><nit:message key="common.delete" ct="true" /></a></td>
												
											</tr>
										</c:forEach>
										</tbody>
									</table>
								</div>
								
								
							</div>
						</c:if>

    */



    // let messagesChildren = [
    //   new DynformControl({ 
    //   key: 'g_name',
    //   valuePath: 'messages.g_name', 
    //   mk: 'common.name', 
    //   controlType: 'message',
    //   data: {
    //     langs: langs,
    //     controlType: "input"
    //   } }),
    //   new DynformControl({ 
    //   key: 'g_ingress',
    //   valuePath: 'messages.g_ingress', 
    //   mk: 'bus.product.ingress', 
    //   controlType: 'message',
    //   data: {
    //     langs: langs,
    //     controlType: "textarea"
    //   } })
    //   ,
    //   new DynformControl({ 
    //   key: 'g_description',
    //   valuePath: 'messages.g_description', 
    //   mk: 'common.description', 
    //   controlType: 'message',
    //   data: {
    //     langs: langs,
    //     controlType: "richtext"
    //   } })
      
    // ];

    // controls.push(new DynformControl({ 
    //   key: 'messages',
    //   controlType: 'formGroup',
    //   children: messagesChildren
    // }));
 



 

    return controls;

   
  }

  onChange(event:any) {
    if (!event.control) return;
    this.dirty = true;

  }

  @HostListener('document:keydown.control.s',['$event']) 
  ctrlS(event: KeyboardEvent) {
    // console.log("ctrlS: ", event);

    this.submitForm();
    if (event) event.preventDefault();
    return false;
  }

  getVisibleSections() {
    let list:any = [];
    for (let s of Object.values(this.Sections)) {
      if (this.isSectionVisible(s.id)) list.push(s);
    }
    return list;
  }

  isSectionVisible(section:string, button:boolean = false) {

    let u = this.edit.obj;
    let ctrls = this.edit.form.controls;

    let isAdmin = this.apps.bas.aas.isAdmin();
  
    // let roleId = this.edit.obj.role;
    // let role = this.apps.bas.us.listToMap(this.fbo.userRoles)[roleId];
    let role = u.userRole;

    // console.log("roleId: " + roleId + ", role: ", role);

    switch (section) {
      case "provider": 
        if (!role.isProvider) return false; break;
      case "company": 
        if (!role.isCompany) return false; break;
      case "agent": 
        if (!role.isAgent) return false; break;
      case "companyInfo": 
        if (!(role.isCompany || role.isProvider || role.isAgent)) return false; break;
     
      case "ups": 
        if (role.isUser) return false; break

      default: break;
    }

 /*

 <c:if test="${fbo.user.isCompany or fbo.user.isProvider or fbo.user.isAgent}">

  Sections = {
    "basic"         : { id: "basic",        title: "app.lib.common.basic",        icon: "setting"}, // + tagsEtc
    "companyInfo"   : { id: "companyInfo",  title: "web.common.user.companyInfo", icon: "setting"}, 
    "company"       : { id: "company",      title: "common.company",              icon: "setting"}, // + coa, cbapi
    "contacts"      : { id: "contacts",     title: "bus.user.contacts",           icon: "setting"}, 
    "provider"      : { id: "provider",     title: "common.provider",             icon: "setting"}, 
    "#agent"        : { id: "#agent",       title: "common.#agent",               icon: "setting"}, 
    "log"           : { id: "log",          title: "common.log",                  icon: "file" },
    "ups"           : { id: "ups",          title: "bus.user.ups",                icon: "setting"}, 
   
  }
 */


    return true;
  }

  getUser(id:any) {

    let isAdmin = this.apps.bas.aas.isAdmin();
    if (!isAdmin) {
      
      // vise feilmelding og sende til forsiden
      return;
    }


    if (this.apps.bas.envtest) console.log("getUser, id: " + id + ", queryParams: ", this.route.snapshot.queryParams);

    if (!id) return;

    let options:any = { 
      jil: "all",
      jil2: "all",
      params: { }
    };

    if (this.apps.bas.aas.isCbSu()) options.params["superuser"] = true;


    // if (id == "new" && this.route.snapshot.queryParams.copy) options.copy = this.route.snapshot.queryParams.copy;

    
    this.apps.bas.aas.getUser(id, options).then((json) => {
      if (this.apps.bas.envtest) console.log("getUser.then: ", json);

  
      let fbo = json.fbo;

      this.fbo = fbo;

      let user = json.user;

      this.setUser(user);
      this.isSpinning = false;



    });
  } 

  setUser(user:any) {

    user.logText = "";

    let controls = this.generateControlsUser(user);


    this.apps.bas.fs.updateFormObj(this.edit, controls, user);

    this.updateUserLogs(user);
    
    this.dirty = false;
  }

  updateUserLogs(user:any) {
    if (!user.id) return; 

    this.apps.bas.ws.json( { 
      // actionType: "user", 
      action: "getUserLogs",
      user: user.id
    }).then(json => {
      if (this.apps.bas.envtest) console.log("updateUserLogs, logs: ", json);
      user.logs = json.logs || [];
    });


  }


  onMapSelectPosition(latLng:google.maps.LatLng) {
    // console.log("onMapSelectPosition, latLng: ", latLng);
    // console.log("controls: ", latLng);
    
    this.edit.form.controls.latitude.setValue(latLng.lat());
    this.edit.form.controls.longitude.setValue(latLng.lng());
    
  }
  onMapSelectPositionCancel() {
    // console.log("onMapSelectPositionCancel, restoring value"
    //   + ", lat: " + this.productForm.controls.latitude.value + " -> " + this.p.latitude
    //   + ", lng: " + this.productForm.controls.longitude.value + " -> " + this.p.longitude
    // );
    this.edit.form.controls.latitude.setValue(this.edit.obj.latitude);
    this.edit.form.controls.longitude.setValue(this.edit.obj.longitude);
    this.mapVisible = false;
  }



  submitForm(): void {

  
    let rv = this.apps.bas.fs.getRawValue(this.edit.form, this.edit.controls);
    if (rv === false) return;

    rv.changes = this.edit.changes;

    this.isSpinning = true;

    let isNew = "new" == this.route.snapshot.queryParams.id;
    if (!isNew) delete rv.role;
    
    if (this.apps.bas.envtest) console.log("CommonUserUserPageComponent.submitForm, isNew: " + isNew+ ", rv: ", rv)

    this.apps.bas.aas.saveUser(this.edit.obj.id || "new", rv).then(res => {
      this.isSpinning = false;
      this.edit.changes = { };

      if (this.apps.bas.envtest) console.log("CommonUserUserPageComponent.submitForm.then: ", res);

      if (!res.success) {

        return;
      }

      this.apps.bas.ui.success("Brukeren er lagret"); //TODO:text

      this.userChange.next({ type: "save", user: res.user, isNew: isNew })

      this.setUser(res.user);

      if (!this.inputId && isNew) {

        this.router.navigate([
            this.apps.bas.ui.getRouterPrefix() + '/admin/user/user'
          ], { queryParams: { id: res.user.id }}
        );

      }
     

    }).catch(err =>  {
      this.isSpinning = false;

      if (this.apps.bas.envtest) console.log("CommonUserUserPageComponent.submitForm.err: ", err);
    })


  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    // insert logic to check if there are pending changes here;
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away

    return !this.dirty;
  }


}


