import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MiscUtil } from 'src/app/lib/core/util/misc.util';

import { AppService } from 'src/app/services/app.service';

import { DynformControl } from 'src/app/lib/core/model/dymform-control';

@Component({
  selector: 'app-admin-tools-page',
  templateUrl: './admin-tools-page.component.html',
  styleUrls: ['./admin-tools-page.component.scss']
})
export class AdminToolsPageComponent {

  fbo:any = { };

  run:any = {

    testNo: "",
    commonTask: "",

    args: []
  }

  errors = {  runCommonTask: false, runTest: false }

  messageKey:string = ""
  messageInput = {
    key: ""
  }
  
  // https://192.168.1.82:8200/api/kragerokystlag/admin/misc/session-status
  constructor(public injector:Injector, public apps:AppService) {
    let route = injector.get(ActivatedRoute);
    this.apps.bas.us.checkActivatedRoute(route.snapshot);

    if(this.apps.bas.envtest) console.log("AdminToolsPageComponent");

    this.getFbo();

    for (let i = 0; i < 10; i++) {
      this.run.args.push({ "idx": i, "name": "arg" + i, "value": "" })
    }
  }

  ngOnInit(): void {

  }

 
  getFbo() {
    this.apps.bas.ws.json({ aType: "appAdmin", action: "getToolsFbo" }).then((json) => {
      if (json.success) {
        if(this.apps.bas.envtest) console.log("getToolsFbo: ", json);

        this.fbo = json.fbo || { };

        this.fbo.updateTimeDate = this.apps.bas.ui.dateTimeParse(this.fbo.updateTime);

      }
    });
  }


  translateText() {
    console.log("messageKey: " + this.messageKey);
    this.messageInput = { key: this.messageKey };
  }

  saveUpdateTime() {
    let updateTime = this.apps.bas.ui.dateTimeFormat(this.fbo.updateTimeDate);
    if(this.apps.bas.envtest) console.log("saveUpdateTime"
      // +", time: "+this.fbo.updateTimeDate
      +", updateTime: "+updateTime
      +", period: " + this.fbo.updatePeriod
    );

    this.apps.bas.ws.json({
      actionType: "su", 
      action: "setUpdateWarning",
      updateTime: updateTime,
      period: this.fbo.updatePeriod
    }).then((json) => {
      if (json.success) {
        this.apps.bas.ui.success("Vellykket!");
      }
    });


  }


  runTask(action:string) {
    if(this.apps.bas.envtest) console.log("runTask, action: "+action+", run: ", this.run);

    this.errors = {  runCommonTask: false, runTest: false }

    if (action == "runCommonTask" && this.run.commonTask == "") {
      this.errors.runCommonTask = true;
      return;
    }
    if (action == "runTest" && this.run.testNo == "") {
      this.errors.runTest = true;
      return;
    }

   

    // var params = { aType: "admin", action: id };
		// 	$(".arg").each(function() {
		// 		var name = $(this).attr("id");
		// 		var value = $(this).val(); //$(this).is("select") ? $(this).find("option:selected").val() : $(this).val();
				
		// 		params[name] = value;
		// 	});
			

    let params:any = {
      aType: "admin", 
      action: action,
      testNo: this.run.testNo,
      ct:  this.run.commonTask
    }

    for (let arg of this.run.args) {
      params["arg" + arg.idx] = arg.value;
    }

    if(this.apps.bas.envtest) console.log("runTask,params: ", params);

    this.apps.bas.ws.json(params).then((json) => {
    
      console.log("runTask,then: ", json);

   
      if (json.success) {
        this.apps.bas.ui.success("Oppgaven er fullført!");
      } else {
        this.apps.bas.ui.error("Oppgaven feilet, se loggen for detaljer.");
      }
    }).catch((err)=> {
      console.log("runTask.catch: ", err);
      this.apps.bas.ui.error("Oppgaven feilet, se loggen for detaljer.");
    });
    
  }



}
