<btlib-admin-page-wrapper>

  <div class="m-d">
    <h1>{{ 'web.common.productTypes' | actrans }}</h1>
    <div class="text-right">
      <button nz-button nzType="primary"  (click)="newObject()">
        <i nz-icon nzType="plus-circle"></i>
        {{ 'common.add' | actrans }}
      </button>
  
    </div>


    <!-- TODO: filter -->
   
  </div>

  <nz-table 
    #objectsTable 
    nzTableLayout="fixed" 
    [nzData]="objects || []"
    [nzPageSize]="20"
    nzSize="small"
    class="nz-table-xs"
    >
    <thead>
      <tr>
        <th>{{ 'common.id' | actrans }}</th>
        <th>{{ 'common.nameInternal' | actrans }}</th>
        <th>{{ 'bus.productType.parent' | actrans }}</th>
        <th nzWidth="50px">{{ 'bus.productType.guestCountIsQuantity.abbr' | actrans }}</th>
        <th nzWidth="50px"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of objectsTable.data">

        <td>{{ data.ptid }}</td>
        <td nzEllipsis>{{ data.name }}</td>
        <td>{{ data.parent }}</td>
        <td>{{ 'common.' + data.guestCountIsQuantity | actrans }}</td>

        <td nzAlign="center">
          <button nz-button nzType="default" nzSize="small" [title]="'common.edit' | actrans"  (click)="editObject(data)">
            <i nz-icon [nzType]="'edit'"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </nz-table>

  <nz-modal [(nzVisible)]="edit.obj" [nzTitle]="'common.edit' | actrans | str" nzWidth="90vw"  (nzOnCancel)="onEditCancel()" (nzOnOk)="onEditOk();">
    <ng-container *nzModalContent>
      

      <form nz-form [formGroup]="edit.form" class="item-form mt-d" nzLayout="vertical" [nzAutoTips]="apps.bas.fs.autoTips" (ngSubmit)="onEditOk()"  >
  
        <!-- <btlib-form-row [controls]="[  ]" [form]="edit.form"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row> -->
        <btlib-form-row [controls]="[ edit.controls.name, edit.controls.ptid   ]" [form]="edit.form"  [grid]="apps.bas.ui.gridMd" ></btlib-form-row> 
        <btlib-form-row [controls]="[ edit.controls.messages.childMap.name ]" [form]="edit.form"  [grid]="apps.bas.ui.gridXl" ></btlib-form-row> 
        <btlib-form-row [controls]="[ edit.controls.parent ]" [form]="edit.form"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row> 
        <btlib-form-row [controls]="[ edit.controls.guestCountIsQuantity ]" [form]="edit.form"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row>
        
      </form>
      

    </ng-container>
  </nz-modal>





</btlib-admin-page-wrapper>
