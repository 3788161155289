
<nz-modal 
[nzVisible]="manual || !!recipient.length" 
[nzTitle]="'web.admin.app.sendPushMessage' | actrans | str" 
(nzOnCancel)="closeModal()" 
(nzOnOk)="closeModal()"
>
<!--   nzWidth="90vw"  -->
<ng-container *nzModalContent>
  
  <div *ngIf="fbo.an">
    <form nz-form [formGroup]="df.form" class="item-form mt-d" nzLayout="vertical" [nzAutoTips]="apps.bas.fs.autoTips" (ngSubmit)="dfSubmit()"  >
       
      <btlib-form-row [controls]="[   df.controls.appId ]" [form]="df.form"  [grid]="apps.bas.ui.gridFull"  ></btlib-form-row>
    
      <btlib-form-row [controls]="[ 
          
      df.controls.schedule,
      df.controls.sendTimeAsString,
      df.controls.expireAsString
    ]" [form]="df.form"  [grid]="apps.bas.ui.gridFull"  ></btlib-form-row>
  
      <btlib-form-row [controls]="[ 
          
        df.controls.title,
        df.controls.message,
        df.controls.url,
        df.controls.recipient,
        df.controls.sendEmail
      ]" [form]="df.form"  [grid]="apps.bas.ui.gridFull"  ></btlib-form-row>
    

      
    </form>

    <!--

			Boolean sendToProd = WebUtil.getBooleanParam(request, "sendToProd", false);
			Boolean dryRun = WebUtil.getBooleanParam(request, "dryRun", false);

			String topic = WebUtil.getParam(request, "topic");


            
             <div class="form-group">
               <div class="col-sm-offset-4-col-sm-8">
                 <div class="checkbox">
                   <label class="" for="pm_sendEmail">
                     <input type="checkbox" id="pm_sendEmail" name="sendEmail" class="jpField" />
                     <nit:message key="web.admin.appConfig.sendEmail" defaultText="Send også meldingen på epost til angitt mottaker" />
                   </label>
                 </div>
               </div>
            </div>
              
            <c:if test="${wpd.isBn}">
            
              <div class="form-group">
                <label class="control-label" for="topic"><nit:message key="common.topic"/> (bn):</label>
                <div class="controls"><input type="text" id="pm_topic" name="topic" class="form-control jpField" value="" /></div>
              </div>
              <div class="form-group">
                  <div class="col-sm-offset-4-col-sm-8">
                      <div class="checkbox">
                          <label class="" for="pm_dryRun">
                              <input type="checkbox" id="pm_dryRun" name="dryRun" class="jpField" />
                              <nit:message key="web.admin.appConfig.dryRun"/> (bn)
                          </label>
                      </div>
                  </div>
              </div>
            </c:if>

          
    -->
  </div>
</ng-container>
<div *nzModalFooter>
  <button nz-button nzType="primary" (click)="dfSubmit()"   [nzLoading]="df.processing" >{{ 'common.submit' | actrans }}</button> <!--[disabled]="!recipients.length"-->
</div>
</nz-modal>

