import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MiscUtil } from 'src/app/lib/core/util/misc.util';

import { AppService, APP_DATAID } from '../../../../../services/app.service';
import { BtContent } from '../../../model/bt-content';
import { DATAID } from '../../../services/data.service';

@Component({
  selector: 'btlib-sms-modal',
  templateUrl: './sms-modal.component.html',
  styleUrls: ['./sms-modal.component.scss']
})
export class SmsModalComponent implements OnInit {

  // order:any = { }

  recipients:string[] = [ ];
  manual = false;

  text:string = "";
  df:any = {
    status: undefined
  };


  @Input() 
  set input(input: any) {
    if (!input) {
      this.recipients = [];
      return;
    }
    

    let c = this.apps.bas.ds.config.company;
    if (!c.upCmSms) {
      console.log("!c.upCmSms: ", c)
      this.recipients = [];
      this.manual = false;
      return;
    }

    if (input.manual) {
      this.manual = input.manual;
      return;
    }

    if (input.recipients) {
      this.recipients = input.recipients.filter((tel:string) => tel);
      // console.log("this.recipients: ", this.recipients);
      return;
    }

    // console.log("input: ", input)

    if (input.orders) {
      let orders:any[] = input.orders;
      this.recipients = orders.map((order:any) => order.userTelephone).filter(tel => tel); 
      return;
    }
    if (input.bis) {
      let bis:any[] = input.bis;
      this.recipients = bis
        .map((order:any) => order.bookingObj?.userTelephone)
        .filter(tel => tel); 
      return;
    }
    if (input.bids) {
      
      this.df.processing = true;
      this.apps.bas.ws.json( {
        aType: "appAdmin",
        action: "getSmsRecipients",
        bids: input.bids.join(","),
    
       } ).then((json:any) => {
        if(this.apps.bas.envtest) console.log("getSmsRecipients.then, json: ", json);
     
        
        this.df.processing = false;
        if (json.success && json.recipients) {
          this.recipients = json.recipients;
        }
  
       }).catch((err) => {
        
        this.df.processing = false;
       });
  
    }
  }

  @Output() change = new EventEmitter<any>();

  
  constructor(public apps:AppService) {
  
    
  }

  ngOnInit(): void {

  }

  closeModal() {
    this.recipients = [ ];
    this.manual = false;
    this.text = "";
  }


  sendSms() {
    let r = this.recipients;

    console.log("text: " + this.text);
    // console.log("r.length: " + r.length);
    console.log("r: ", r);


    if (this.text == "") {
      this.df.status = "error";
      return;
    }

    this.df.status = undefined;
    this.df.processing = true;

    this.apps.bas.ws.json( {
      aType: "misc",
      action: "sendSms",
      recipients: r.join(","),
      message: this.text,
     } ).then((json:any) => {
      if(this.apps.bas.envtest) console.log("sendSms.then, json: ", json);
      this.df.processing = false;
      this.closeModal();
   
      this.change.next({ type: "sendSms", recipients: r });

     }).catch((err) => {
      
      this.df.processing = false;
     });

  }


}
