import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { MiscUtil } from 'src/app/lib/core/util/misc.util';

import { AppService } from 'src/app/services/app.service';

import { DynformControl, DynformControlsMap } from 'src/app/lib/core/model/dymform-control';

import { NzUploadChangeParam, NzUploadFile } from 'ng-zorro-antd/upload';
import { DATAID } from 'src/app/lib/core/services/data.service';

@Component({
  selector: 'app-provider-misc-electricity-billing-page',
  templateUrl: './provider-misc-electricity-billing-page.component.html',
  styleUrls: ['./provider-misc-electricity-billing-page.component.scss']
})
export class ProviderMiscElectricityBillingPageComponent implements OnInit {

  route: ActivatedRoute;

  processing = false;

  df:any = {
    form: undefined,
    controls: [],
    obj: undefined,

    orders: [],
    fileList: [],
    bi: undefined
  };

  constructor(public injector:Injector, public apps:AppService) {
    this.route = injector.get(ActivatedRoute);
    this.apps.bas.us.checkActivatedRoute(this.route.snapshot);

    if (this.apps.bas.envtest) console.log("ProviderMiscElectricityBillingPageComponent");


    // if (this.apps.bas.ds.login.success) {
    //   this.getPendingOrders();
    // } 
    // else {

      this.apps.bas.ds.get(DATAID.APP_LOGIN).subscribe((res) => {
        if (this.apps.bas.envtest) console.log("ProviderMiscElectricityBillingPageComponent.APP_LOGIN.subscribe: ", res);

        if (res.success) this.getPendingOrders();
     
      });
    // }

    
  }

  getPendingOrders() {

    this.apps.bas.ws.json({ 

      aType: "provider", 
      action: "electricityBillingGetPending", 
    
    }).then((json) => {
  
      if (this.apps.bas.envtest) console.log("getPendingOrders, json: ", json);
      if (json.success) {
        this.df.orders = json.orders || [];
      }
    });

  }

  selectOrder(bi:any) {
    this.updateForm(bi);
  }
  
  onChange(event:any) {
    let fcs = this.df.form.controls;
    let sum =  fcs.kwh.value * fcs.unitPrice.value; 
    
    if (!sum) return;
    

    // console.log("sum: ", sum);
    this.df.controls.calcTotal.value = this.apps.bas.ui.nf(sum);
  }

  updateForm(obj:any = { }) {

    let controls: DynformControl[] = [];

    // controls.push(new DynformControl({ key: 'kwh',   mk: 'Antall kwt', controlType: "input-number", required: true, data: { fractionSize: 2 } }));
    // controls.push(new DynformControl({ key: 'unitPrice',   mk: 'Pris per kwt i kr', controlType: "input-number", required: true, data: { fractionSize: 2 } }));
    // controls.push(new DynformControl({ key: 'calcTotal',   mk: 'Sum', controlType: "label" }));
    controls.push(new DynformControl({ key: 'totalAmount',   mk: 'Total strømpris', controlType: "input-number", required: true, data: { fractionSize: 2 }  }));
    controls.push(new DynformControl({ 
      key: 'comment',   
      // mk: 'common.comment', 
      mk: 'Kommentar (vises på kvitteringen til gjesten)',
      controlType: 'textarea', 
      required: false 
    }));

    this.apps.bas.fs.updateFormObj(this.df, controls, obj);


  }

  dfSubmit() {
    let rv = this.apps.bas.fs.getRawValue(this.df.form, this.df.controls);

    if (rv === false) return;

    rv.biid = this.df.obj.id;

    if (this.apps.bas.envtest) console.log("formSubmit, rv: ", rv);

    this.processing = true;

    this.apps.bas.ws.json({ 

      aType: "provider", 
      action: "electricityBillingSettle", 
      jsonData: JSON.stringify(rv)
    
    }).then((json) => {
      this.processing = false;

      if (this.apps.bas.envtest) console.log("dfSubmit, json: ", json);
      if (json.success) {
        this.apps.bas.ui.success("Strømmen for bestilling "+this.df.obj.bid+" er nå avregnet.");
        
        this.df.form.reset();
        this.df.obj = undefined;
        this.df.fileList = [];
        this.getPendingOrders();
      }
    }).catch(err => {
      this.processing = false;

    });

  }

  uploadHeaders = (file: NzUploadFile): Object => {
    let headers = this.apps.bas.ws.getCommonHeaders();
    MiscUtil.extend(headers, this.apps.bas.ws.getLoginHeaders());
    return headers;
  }
  handleUploadFile({ file, fileList }: NzUploadChangeParam): void {

    const status = file.status;
    const resp = file.response;
    if (status !== 'uploading') {
      if (this.apps.bas.envtest) console.log(file, fileList);

      if (status == "removed") {
        let itemId = resp.itemId;

        // this.apps.bas.ws.json({  //TODO
      
        //   aType: "customAction", 
        //   action: "removeLeadFile", 
        //   itemId: itemId
        
        // }).then((json) => {
        //   if (this.apps.bas.envtest) console.log("removeLeadImage, json: ", json);
        //   if (json.success) {
          
        //   }
        // });

      }
    }
    if (status === 'done') {
     
      

      if (this.apps.bas.envtest) console.log(file.name + 'file uploaded successfully, resp: ', resp);

      if (resp.itemId) {
        //TODO
      } else {
        this.apps.bas.ui.error(this.apps.bas.ui.actrans("common.error.upload.general", [], false, "En feil oppstod da vi skulle behandle filen du lastet opp"));
      }

    } else if (status === 'error') {
      if (this.apps.bas.envtest) console.log(`${file.name} file upload failed.`);
      // TODO
      // this.apps.bas.ui.error(this.apps.bas.ui.actrans("common.error.imageUpload", [], false, "En feil oppstod da vi skulle behandle filen du lastet opp. Vanligvis er dette fordi du har lastet opp en JPEG fil i CMYK-format. Kun JPEG bilder i RGB-format kan lastes opp")); //TODO:text
      
    }
  }

  ngOnInit(): void {
    
  }

}

