import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { MiscUtil } from '../../../../lib/core/util/misc.util';

import { AppService } from '../../../../services/app.service';

import { FormGroup } from '@angular/forms';
import { DynformControl, DynformControlsMap } from '../../../../lib/core/model/dymform-control';


@Component({
  selector: 'app-admin-order-root-page',
  templateUrl: './admin-order-root-page.component.html',
  styleUrls: ['./admin-order-root-page.component.scss']
})
export class AdminOrderRootPageComponent implements OnInit {

  route: ActivatedRoute;

  // PAGE_SIZE = 20;
  fbo:any = {
    loading: true,
    statsObj: {
      navigatorObj: {
        size: 0,
        pageSize: 20, //this.PAGE_SIZE,
        page: 0
      }
    }

  };

  df:any = {
    form: undefined,
    controls: [],
    obj: undefined,
    params: { },
    statsForm: { 
      statsType: "BOOKING",
      currentJil: "min",
      //pageSize: this.PAGE_SIZE
    }
  };

  orderInfo:any = { };

  selected:any[] = []
  smsInput:any = { };


  constructor(public injector:Injector, public apps:AppService) {
    this.route = injector.get(ActivatedRoute);
    this.apps.bas.us.checkActivatedRoute(this.route.snapshot);

    if (this.apps.bas.envtest) console.log("AdminOrderRootPageComponent");


    // this.updateFbo();
    
  }

  onSelected(event:any, bi:any) {
    console.log("onSelected, bi: ", bi,  ", event: ", event);
    bi.selected = event;
    this.selected = this.fbo.statsObj.current.filter((order:any) => order.selected);
  }
  sendSms() {
    let recipients = this.selected.map((bi:any) => bi.bookingUser?.telephone);
    console.log("sendSms, selected: " + this.selected.length + ", recipients: ", recipients);
    this.smsInput = {
      recipients: recipients
    };
  }


  onQueryParamsChange(queryParams: NzTableQueryParams): void {
    if (this.apps.bas.envtest) console.log("onQueryParamsChange: ", queryParams);
    const { pageSize, pageIndex, sort, filter } = queryParams;
    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || null;
    const sortOrder = (currentSort && currentSort.value) || null;

    let params:any = { };
    
    let page = pageIndex !== undefined && pageIndex > 0 ? pageIndex -1 : undefined;
    // let nav = this.fbo.statsObj.navigatorObj;
    // if ((page !== undefined && nav.page != page )) { 
    //   params.navAction = "page";
    //   params.navParam = page;
    // } else if ((pageSize !== undefined && nav.pageSize != pageSize )) { 
    //   params.navAction = "pageSize";
    //   params.navParam = pageSize;
    // }

    // this.updateFbo(params);

    let sfi:any = { }
        
    if (page !== undefined) sfi.navPage = page;
    if (pageSize !== undefined)  sfi.navPageSize = pageSize;


    this.updateFbo(params, sfi);
  }

  
  dfSubmit() {
    this.updateFbo({ },  { 
      navPageSize: this.fbo.statsObj.navigatorObj.pageSize,
      navPage: 0, //this.fbo.statsObj.navigatorObj.page
    });
  }

  updateFbo(input:any = { }, statsFormInput:any = { }) {
    let params:any = {
      aType: "appAdmin",
      action: "getOrdersFbo"
    };

    let rv = { };
    if (this.df.form) {
      rv = this.apps.bas.fs.getRawValue(this.df.form, this.df.controls);
      if (rv === false) return;
  
    }
 
    MiscUtil.extend(rv,  statsFormInput);


    let statsForm = MiscUtil.extend({ }, this.df.statsForm);
    MiscUtil.extend(statsForm, rv);
    // MiscUtil.extend(statsForm, { pageSize});
    

    MiscUtil.extend(params, input);

    MiscUtil.extend(params, this.df.params);
    MiscUtil.extend(params, { 
      statsForm: JSON.stringify(statsForm),
      // navForm:  JSON.stringify({ pageSize: this.fbo.statsObj.navigatorObj.pageSize || this.PAGE_SIZE })
    } );

    if (this.apps.bas.envtest) console.log("updateFbo.params: ", params);

    this.fbo.loading = true;
    this.apps.bas.ws.json(params ).then((json:any) => {
      if (this.apps.bas.envtest) console.log("updateFbo.then, json: ", json);

      //TODO: må lagre dssse veriene lokalt slik at man ikke må stille inn hver gang siden lastes.

      if (json.success) {
        this.updateForm(json.statsObj);

        this.fbo = json;
        
      } else {

      }
      

     });
  }

  
  updateForm(obj:any) {

    let controls: DynformControl[] = [];

    
    controls.push(new DynformControl({ key: 'search',               mk: 'common.search' }));
    controls.push(new DynformControl({ key: 'currentJil', value: "max" }));
    
    
    
    controls.push(new DynformControl({ 
      key: 'product',  
      // valuePath: "ruleId",
      mk: 'common.product',  
      controlType: 'select', 
      // required: true,
      options: () => {
        return obj.products || []; 
      },
      optionsAllowEmpty: true,
      optionsFieldValue: "id",
      optionsFieldLabel: "mkName"
    }));

    controls.push(new DynformControl({ 
      key: 'productType',  
      // valuePath: "ruleId",
      mk: 'bus.product.productType',  
      controlType: 'select', 
      // required: true,
      options: () => {
        return obj.productTypes; 
      },
      optionsAllowEmpty: true,
      optionsFieldValue: "id",
      optionsFieldLabel: "mkName"
    }));

    controls.push(new DynformControl({ 
      key: 'productSubType',  
      mk: 'bus.product.subType',  
      controlType: 'select', 
      // required: true,
      options: () => {
        return obj.productSubTypes;  //TODO: bare vise etter valgt productType
      },
      optionsAllowEmpty: true,
      optionsFieldValue: "id",
      optionsFieldLabel: "mkName"
    }));

    controls.push(new DynformControl({ 
      key: 'provider',  
      mk: 'common.provider',  
      controlType: 'select', 
      // required: true,
      options: () => {
        return obj.providers || [];
      },
      show: () => {
        return this.apps.bas.aas.isAdmin();
      },
      optionsAllowEmpty: true,
      optionsFieldValue: "id",
      optionsFieldLabel: "label"
    }));


    controls.push(new DynformControl({ 
      key: 'datePeriodAsRange',  
      mk: 'common.period', 
      required: true , 
      controlType: "date-range-picker", data: { 
        readOnly: this.apps.bas.ui.r.xs
       } })); 
    //TODO: period-nedtreksboks: sist uke, osv

    controls.push(new DynformControl({ key: 'statusMap',     mk: 'common.status', controlType: "order-status", data: { statusList:  obj.statusList } }));


    // TODO: username 
    // TODO: sort

    /*

					
		
				<div class="form-group">
					<label class="col-sm-4 control-label" for="stats.username"><nit:message key="common.username"/>:</label>
					<div class="col-sm-8"><nit:text path="fbo.statsObj.username" cssClass="form-control typeahead" extraAttr='autocomplete="off" data-autocomplete-type="user"' /></div>
				</div>
		
				<div class="form-group">
					<label class="col-sm-4 control-label" for="stats.sort"><nit:message key="common.sort"/>:</label>
					<div class="col-sm-8"><nit:select path="fbo.statsObj.sort" items="${stats.sorts}" cssClass="form-control " labelProperty="mk" valueProperty="name" /></div>
				</div>
			
				<div class="form-group">
					<label class="col-sm-4 control-label" for="stats.sortMode"><nit:message key="common.sortMode"/>:</label>
					<div class="col-sm-8"><nit:select path="fbo.statsObj.sortMode" items="${stats.sortModes}" cssClass="form-control " labelProperty="mk" valueProperty="name" /></div>
				</div>
    */


    // this.apps.bas.fs.updateControls(controls, obj);
    // this.df.form = this.apps.bas.fs.toFormGroup(controls);
    // this.df.controls = this.apps.bas.fs.toMap(controls);
    // this.df.obj = obj;

    this.apps.bas.fs.updateFormObj(this.df, controls, obj);


  }


  ngOnInit(): void {
    
  }

}
