import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { MiscUtil } from 'src/app/lib/core/util/misc.util';

import { AppService } from 'src/app/services/app.service';

import { DynformControl, DynformControlsMap } from 'src/app/lib/core/model/dymform-control';


@Component({
  selector: 'app-admin-user-users-page',
  templateUrl: './admin-user-users-page.component.html',
  styleUrls: ['./admin-user-users-page.component.scss']
})
export class AdminUserUsersPageComponent implements OnInit {

  route: ActivatedRoute;

  fbo:any = {
    loading: true,
    stats: {
      current: [],
      navigatorObj: {
        size: 0,
        pageSize: 20,
        page: 0
      }
    }

  };

  customFilters:any = { }

  df:any = {
    form: undefined,
    controls: [],
    obj: undefined,
    params: { 
      // 
      navPageSize: 20,
    },
    statsForm: { 
      statsType: "USER",
      currentJil: "min",
      sort: "USERNAME",
      sortMode: "ASC",
    }
  };

  constructor(public injector:Injector, public apps:AppService) {
    this.route = injector.get(ActivatedRoute);
    this.apps.bas.us.checkActivatedRoute(this.route.snapshot);

    if (this.apps.bas.envtest) console.log("AdminUserUsersPageComponent");


    // this.updateFbo();
    
  }

  editUser(user:any) {
    let qps:any = { id: user.id };
    if (this.apps.bas.aas.isCbSu()) qps["superuser"] = true;

    this.apps.bas.ui.router.navigate([ 
        this.apps.bas.ui.getRouterPrefix() 
        + '/admin/user/user'
      ], { queryParams: qps }
    );
    // [routerLink]="[apps.bas.ui.getRouterPrefix(), '/common/user/user', '?id=' + data.id ]
  }

  onQueryParamsChange(queryParams: NzTableQueryParams): void {
    if (this.apps.bas.envtest) console.log("onQueryParamsChange: ", queryParams);
    const { pageSize, pageIndex, sort, filter } = queryParams;
    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || null;
    const sortOrder = (currentSort && currentSort.value) || null;

    let params:any = { };
    
    let page = pageIndex !== undefined && pageIndex > 0 ? pageIndex -1 : undefined;
    let nav = this.fbo.stats.navigatorObj;
    if ((page !== undefined && nav.page != page )) { 
      params.navAction = "page";
      params.navParam = page;
    } else if ((pageSize !== undefined && nav.pageSize != pageSize )) { 
      params.navAction = "pageSize";
      params.navParam = pageSize;
    }

    
    let sfi:any = { }
    if (sortField) {
      sfi.customSortsList = [
     
        { field: sortField, asc: sortOrder === "ascend" }
      ];

    }

    if (filter) {
      sfi.customFiltersMap = { };
      for (let item of filter) {
        if (item.value == null || item.value.length == 0) continue;
   
        sfi.customFiltersMap[item.key] = { value: item.value };

      }
    }


    this.updateFbo(params, sfi);
  }

  updateFbo(input:any = { }, statsFormInput:any = { }) {
    let params:any = {
      aType: "appAdmin",
      action: "getStatsFbo"
    };

    if (this.apps.bas.aas.isCbSu()) params["superuser"] = true;

    let rv = { };
    if (this.df.form) {
      rv = this.apps.bas.fs.getRawValue(this.df.form, this.df.controls);
      if (rv === false) return;
  
    }

    statsFormInput.customFiltersMap = statsFormInput.customFiltersMap || { };
    for (let key in this.customFilters) {
      let val = this.customFilters[key];
      if (val == undefined || val == "") continue; 
      statsFormInput.customFiltersMap[key] = { value: val, type: "search" };
    }

    MiscUtil.extend(rv,  statsFormInput);


    MiscUtil.extend(params, input);

    let statsForm = MiscUtil.extend({ }, this.df.statsForm);
    MiscUtil.extend(statsForm, rv);

    MiscUtil.extend(params, this.df.params);
    MiscUtil.extend(params, { statsForm: JSON.stringify(statsForm) } );

    if (this.apps.bas.envtest) console.log("updateFbo.params: ", params);

    this.fbo.loading = true;
    this.apps.bas.ws.json(params ).then((json:any) => {
      if (this.apps.bas.envtest) console.log("updateFbo.then, json: ", json);

      //TODO: må lagre dssse veriene lokalt slik at man ikke må stille inn hver gang siden lastes.

      if (json.success) {
        this.updateForm(json.stats);

        this.fbo = json;
        
      } else {

      }
      

     });
  }

  
  updateForm(stats:any) {

    let controls: DynformControl[] = [];

    // controls.push(new DynformControl({ key: 'statsType', value: "USER" }));
    
    
    controls.push(new DynformControl({ key: 'search',               mk: 'common.search' }));
    // controls.push(new DynformControl({ key: 'currentJil', value: "max" }));
    
    
    
    // controls.push(new DynformControl({ 
    //   key: 'product',  
    //   // valuePath: "ruleId",
    //   mk: 'common.product',  
    //   controlType: 'select', 
    //   // required: true,
    //   options: () => {
    //     return stats.products || []; 
    //   },
    //   optionsAllowEmpty: true,
    //   optionsFieldValue: "id",
    //   optionsFieldLabel: "mkName"
    // }));

    let roles = stats.roles;
    // if (this.apps.bas.envtest) console.log("roles: ", roles);

    controls.push(new DynformControl({ 
      key: 'role',  
      // valuePath: "ruleId",
      mk: 'bus.user.role',  
      controlType: 'select', 
      // required: true,
      options: () => {
        return stats.roles; 
      },
      optionsAllowEmpty: true,
    }));



    //TODO: period-nedtreksboks: sist uke, osv

    /*
predef: 
statsType: USER
period: ONE_MONTH
fromAsString: 2022-01-21 00:00
toAsString: 2022-02-21 00:00
dateMode: TIME
groupBy: NONE
sort: TIME
sortMode: DESC
sort_2: NONE
sortMode_2: ASC
sort_3: NONE
sortMode_3: ASC
statuss: 0
statuss: 2
sm1: SHOW
provider: 
product: 
productTagsInclude: 
productTagsExclude: 
productSubType: 
viName: 
showSettled: ALL
showDoSettle: ALL
showSaved: YES
bookingTag: 
biTag: 
search: 
username: 
    */


    this.apps.bas.fs.updateFormObj(this.df, controls, stats);


  }

  dfSubmit() {
    this.updateFbo();
  }

  ngOnInit(): void {
    
  }

}

