<btlib-admin-page-wrapper>
  <!--  -->

  <div class="mx-d">

    <h2>{{ 'web.common.bookings' | actrans }}</h2>
    
    <div *ngIf="df.obj">
      <nz-collapse>
        <nz-collapse-panel  [nzHeader]="'Filter'" [nzActive]="apps.bas.ui.r.gtemd"  >
          
          <form nz-form [formGroup]="df.form" class="item-form mt-d" nzLayout="vertical" [nzAutoTips]="apps.bas.fs.autoTips" (ngSubmit)="dfSubmit()"  >
  
            <btlib-form-row [controls]="[ df.controls.datePeriodAsRange,  df.controls.search ]" [form]="df.form"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row>
            <btlib-form-row [controls]="[ df.controls.statusMap ]" [form]="df.form"  [grid]="apps.bas.ui.gridXl"  ></btlib-form-row>
            <btlib-form-row [controls]="[ df.controls.productType,  df.controls.productSubType ]" [form]="df.form"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row> 
            <btlib-form-row [controls]="[ df.controls.product, df.controls.provider ]" [form]="df.form"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row> 
            <!-- <btlib-form-row [controls]="[ df.controls.productQuery ]" [form]="df.form"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row>  -->
            <!-- <btlib-form-row [controls]="[ df.controls.showPoolUnits, df.controls.showEmptyResources ]" [form]="df.form"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row>  -->
      
            <button nz-button nzType="primary"> {{ 'common.show' | actrans }}  </button>
      
          </form>

        </nz-collapse-panel>
      </nz-collapse>


     
    </div>
  

    <div class="mt-d">

      <nz-table 
        #nzTableOrders
        [nzData]="fbo.statsObj.current || []" 
        nzTableLayout="fixed" 

        nzSize="small"
        class="nz-table-xs"

        [nzFrontPagination]="false"
        [nzLoading]="fbo.loading"

        [nzTotal]="fbo.statsObj.navigatorObj.size"
        [nzPageSize]="fbo.statsObj.navigatorObj.pageSize"
        [nzPageIndex]="fbo.statsObj.navigatorObj.page+1"
        (nzQueryParams)="onQueryParamsChange($event)"

        >
        <thead>
          <tr>
            <!-- <th nzWidth="30px"></th> -->
      

            <th  *ngIf="apps.bas.ui.r.gtelg" [nzWidth]="'80px'">
              <span *ngIf="apps.bas.ui.r.gtemd">{{ 'common.status' | actrans }}</span>
            </th>
            <th [nzWidth]="'70px'">{{ (apps.bas.ui.r.gtemd ? 'common.id' : 'common.id') | actrans }}</th>
            <th>{{ 'common.product' | actrans }}</th>
            <th *ngIf="apps.bas.ui.r.gtemd">{{ 'common.customer' | actrans }}</th>

            <th *ngIf="apps.bas.ui.r.gtelg">{{ 'common.updated' | actrans }}</th>
            <th *ngIf="apps.bas.ui.r.gtemd">{{ 'common.period' | actrans }}</th>

            <th *ngIf="apps.bas.ui.r.gtesm" [nzWidth]="'60px'">{{ 'bus.booking.guests' | actrans }}</th>
            <th *ngIf="apps.bas.ui.r.gtesm" [nzWidth]="'60px'">{{ 'common.count' | actrans }}</th>

            <th nzAlign="right" [nzWidth]="'70px'">{{ 'common.amount' | actrans }}</th>

            <th nzWidth="30px"></th>
            <th  nzWidth="30px" nzAlign="center"></th>

          </tr>
        </thead>
        <tbody>
          <ng-container  *ngFor="let data of nzTableOrders.data; let idx = index">
            <tr class="cursor-pointer-off" > <!-- , biid: data.id -->
              <!-- <td>
                <i nz-icon class="cursor-pointer" [nzType]="data.expanded ? 'minus-square' : 'plus-square'" (click)="data.expanded = !data.expanded" ></i>
              </td> -->

              <td [class]="data.statusCssClass"  *ngIf="apps.bas.ui.r.gtelg">
                <span>{{ data.mkStatus }}</span> 
              </td>
              <td  [class]="apps.bas.ui.r.gtelg ? '' : data.statusCssClass">{{ data.bid }}</td>
              <td [class.text-xs]="apps.bas.ui.r.ltesm">{{ data.product }}</td>
              <td *ngIf="apps.bas.ui.r.gtemd">
                <div *ngIf="data.bookingUser">
                  {{ data.bookingUser.name }} <br/>
                  {{ data.bookingUser.emailAddress }} <br/>
                  {{ data.bookingUser.telephone }}
                </div>
                

              </td>

          
              <td *ngIf="apps.bas.ui.r.gtelg">{{ data.updated }}</td>
              
              <td *ngIf="apps.bas.ui.r.gtemd">{{ data.periodString }}</td>
      
              <td *ngIf="apps.bas.ui.r.gtesm">{{ data.guests }}</td>
              <td *ngIf="apps.bas.ui.r.gtesm">{{ data.count }}</td>

              <td nzAlign="right" class="text-norwap">{{ data.amountDouble | nf:0 }}</td>

              <!-- <td *ngIf="apps.bas.ui.r.ltesm"></td> -->
              <td>
             
                <button nz-button nzSize="small" nzType="default"   (click)="orderInfo = { id: data.bookingId }" >
                  <i nz-icon  [nzType]="'arrow-right'" ></i>
                </button>
              </td>
              <td nzAlign="center">
                <label 
                  nz-checkbox 
                  [nzValue]="true" 
                  [ngModelOptions]="{standalone: true}"
                  [ngModel]="selected[data.id]"
                  (ngModelChange)="onSelected($event, data)"
                  ></label>
     
              </td>
            </tr>
           
          </ng-container>
        </tbody>
        <tfoot>
          <!-- <tr>
            <th colspan="3">
              {{ 'common.sum' | actrans }}
            </th>
            <th [attr.colspan]="(
                1
                + (apps.bas.ui.r.ltesm ? 0 : 0)  
                + (apps.bas.ui.r.gtesm ? 0 : 0) 
                + (apps.bas.ui.r.gtemd ? 1 : 0) 
                + (apps.bas.ui.r.gtelg ? 3 : 0) 
                + (apps.bas.ui.r.gtexl ? 0 : 0)
              )"
              
              nzAlign="right"
            >
            {{ order.amountDouble | nf }}
            </th>
      
          </tr>
      -->
        </tfoot>
      </nz-table>

      <div class="text-right">
          
        <button 
          *ngIf="apps.bas.ds.config.company.upCmSms"
          nz-button 
          nzType="default" 
          (click)="sendSms()" 
          [disabled]="!selected.length">{{ 'web.ajax.sms' | actrans }}
        </button>

      </div>

      <btlib-order-info-modal [order]="orderInfo"></btlib-order-info-modal>

      <div style="height: 50px;"></div>

    </div>
  </div>


  <btlib-sms-modal [input]="smsInput"></btlib-sms-modal>

  

</btlib-admin-page-wrapper>
