import { Component, Input, OnInit } from '@angular/core';
import { MiscUtil } from 'src/app/lib/core/util/misc.util';

import { AppService, APP_DATAID } from '../../../../../services/app.service';
import { DATAID } from '../../../services/data.service';
import { BehaviorSubject, debounceTime } from 'rxjs';

@Component({
  selector: 'btlib-admin-menu',
  templateUrl: './admin-menu.component.html',
  styleUrls: ['./admin-menu.component.scss']
})
export class AdminMenuComponent implements OnInit {

  menuDrawerVisible:boolean = false;

  @Input()
  showAutocomplete:boolean = false;

  autocomplete:any = {
    user: {
      subject: new BehaviorSubject<string>(''),
      value: "",
      options: []
    },
    product: {
      subject: new BehaviorSubject<string>(''),
      value: "",
      options: []
    },
    booking: {
      subject: new BehaviorSubject<string>(''),
      value: "",
      options: []
    }
  }

  smsInput:any = { };
  emailInput:any = { };
  sendPushInput:any = { };

  company:any = { };
  user:any = { }


  constructor(public apps:AppService) {
    this.showAutocomplete = true; //apps.bas.envtest;

    if(this.apps.bas.envtest) console.log("AdminMenuComponent, config: ", apps.bas.ds.config);
    if(this.apps.bas.envtest) console.log("AdminMenuComponent, appLogin: ", apps.bas.appLogin);
    this.company = apps.bas.ds.config.company;
    this.user = apps.bas.appLogin?.user;

    let isCbapi5 = this.apps.bas.settings.appId == 'cbapi5';

    if (this.company.root || isCbapi5) this.showAutocomplete = false;

  }

  ngOnInit(): void {

    if (this.showAutocomplete) {
      for (let type of [ "user", "product", "booking" ]) {

        let ac = this.autocomplete[type];
        ac.subject.pipe(
          debounceTime(500)
        ).subscribe(async (term:string) => {
          ac.term = term;
          // if(this.apps.bas.envtest) console.log("autocomplete.pipe.1000, ac: ", ac);
          if (term == "") {
            ac.options = [];
            return; 
          }

          let json = await this.apps.bas.ws.json({
            aType: "autocomplete",
            action: "autocomplete",
            type: type,
            term: term
          })
          // if(this.apps.bas.envtest) console.log("autocomplete.pipe.1000, json: ", json);
          ac.options = json ? [...json] : [];

        });
      }
    }
  }

  drawerOpen(): void {
    this.menuDrawerVisible = true;
  }

  drawerClose(): void {
    this.menuDrawerVisible = false;
  }

  onAutocompleteChange(event:any, type:string) {
    if(this.apps.bas.envtest) console.log("onAutocompleteChange, type: " + type + ", ev: ", event);

    this.autocomplete[type].subject.next(event);


  }
  onAutocompleteClick(type:string, option:any) {
    if(this.apps.bas.envtest) console.log("onAutocompleteClick: " + type + ", opt: ", option);

    let isCbadmin = this.apps.bas.settings.appId == 'cbadmin';

    if (isCbadmin) {

      if (type == "user") {

        this.apps.bas.ui.router.navigate([ 
            this.apps.bas.ui.getRouterPrefix() 
            + '/user/user'
          ], { queryParams: { id: option.value }}
        );
  
      } else if (type == "product") {
   
        this.apps.bas.ui.router.navigate([ 
          this.apps.bas.ui.getRouterPrefix() 
          + '/product/product/' +  option.value
          ], { queryParams: {  } }
        );
  
      } else if (type == "booking") {
  
        this.apps.bas.ui.router.navigate([ 
          this.apps.bas.ui.getRouterPrefix() 
          + '/lib/goto' 
        ], { queryParams: { 'goto-action': 'order' , 'goto-id': option.bid }});
  
      } else {
        // error
      }

    } else { // cbapi5
      if (type == "user") {

        this.apps.bas.ui.router.navigate([ 
            this.apps.bas.ui.getRouterPrefix() 
            + '/admin/user/user'
          ], { queryParams: { id: option.value }}
        );
  
      } else if (type == "product") {
        // TODO: hvis vi allerde er på produkt-siden så må vi laste inn produktet. 
        
        this.apps.bas.ui.router.navigate([ 
          this.apps.bas.ui.getRouterPrefix() 
          + '/admin/product/' + option.value
          ]
        );
  
      } else if (type == "booking") {
  
        this.apps.bas.ui.router.navigate([ 
          this.apps.bas.ui.getRouterPrefix() 
          + '/admin/order/' + option.value
        ]);
  
      } else {
        // error
      }
  
    }


  }


  orderSendSms() {
    this.smsInput = {
      manual: true
    };
  }

  orderSendEmail() {
    this.emailInput = {
      manual: true
    };
  }

  orderSendPushMessage() {
    if (this.apps.bas.envtest) console.log("AdminMenuComponent.orderSendPushMessage");
    this.sendPushInput = {
      manual: true
    };
  }

}
