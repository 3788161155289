import { Component, Input, OnInit } from '@angular/core';
import { MiscUtil } from 'src/app/lib/core/util/misc.util';

import { AppService, APP_DATAID } from '../../../../../services/app.service';
import { DATAID } from '../../../services/data.service';

@Component({
  selector: 'btlib-menu-main',
  templateUrl: './menu-main.component.html',
  styleUrls: ['./menu-main.component.scss']
})
export class MenuMainComponent implements OnInit {

  menuDrawerVisible:boolean = false;

  constructor(public apps:AppService) {
    // console.log("MenuMainComponent, pw: ", this.pw);
    
  }

  ngOnInit(): void {
  }

  drawerOpen(): void {
    this.menuDrawerVisible = true;
  }

  drawerClose(): void {
    this.menuDrawerVisible = false;
  }

}
