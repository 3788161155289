<div>
  <form nz-form (ngSubmit)="onSubmitted()" [formGroup]="form">


    <bt-dynamic-form-control 
      *ngFor="let control of getControlsList()" 
      [control]="control" 
      [form]="form"></bt-dynamic-form-control>
 

    <div class="submit-div">
      <button type="submit" [disabled]="!form.valid">{{ 'common.save' | actrans }}</button>
    </div>
  </form>

</div>
