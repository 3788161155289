
<nz-modal 
[nzVisible]="manual || !!recipients.length" 
[nzTitle]="'web.ajax.email' | actrans | str" 
 
(nzOnCancel)="closeModal()" 
(nzOnOk)="closeModal()"
>
<!--   nzWidth="90vw"  -->
<ng-container *nzModalContent>
  
  <div *ngIf="manual || recipients.length">
    <form nz-form [formGroup]="df.form" class="item-form mt-d" nzLayout="vertical" [nzAutoTips]="apps.bas.fs.autoTips" (ngSubmit)="dfSubmit()"  >
      <btlib-form-row [controls]="[ 
          
        df.controls.recipients,
        df.controls.subject,
        df.controls.message,
      ]" [form]="df.form"  [grid]="apps.bas.ui.gridFull"  ></btlib-form-row>
    
    </form>
    <div *ngIf="apps.data.messageTemplates">
      <div class="mt-d">
        <nz-tag 
          *ngFor="let tpl of apps.data.messageTemplates || []" 
          [nzColor]="'cyan'" 
          (click)="df.form.controls.message.setValue((df.form.controls.message.value || '') + tpl.text)"
          class="cursor-pointer"
          >
          {{ tpl.name || tpl.text }}
        </nz-tag>
      </div>
   
    </div>

  </div>
</ng-container>
<div *nzModalFooter>
  <button 
    nz-button 
    nzType="primary" 
    (click)="dfSubmit()" 
    [disabled]="!df.form?.controls?.recipients?.value?.length"
    [nzLoading]="df.processing"
    >{{ 'common.submit' | actrans }}</button>
</div>
</nz-modal>
