// import { Pipe, PipeTransform, Injector } from '@angular/core';
// import { Inject } from '@angular/core';
// import { SafeHtml } from '@angular/platform-browser';
// // import { InitConfigService } from '../booktech-app-lib.module'
// import { BooktechAppService } from "../services/booktech-app.service";


import { Pipe, PipeTransform, Injector } from "@angular/core";
import { Inject } from '@angular/core';
import { SafeHtml } from "@angular/platform-browser";
import { AppService } from "src/app/services/app.service";
import { BooktechAppService } from "../services/booktech-app.service";


/*
*/
@Pipe({name: 'str'})
export class StrPipe implements PipeTransform {

  constructor(injector:Injector, public apps:AppService) {

    
  }


  transform(input: any): string {

    return (input ? input : "") + "";
  }
}
