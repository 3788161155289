<btlib-admin-page-wrapper [showMenu]="false">

  <div class="m-d">

    <h1>Plassbooking | ADMIN</h1>

    <div *ngIf="ad.products">

      <div>
        Velg område: 
        <nz-select 
          [(ngModel)]="ad.product"
          (ngModelChange)="selectProduct($event)"
          nzPlaceHolder="Velg område"
          style="min-width: 300px;"
          >
          <nz-option *ngFor="let p of ad.products" [nzValue]="p" [nzLabel]="p.name"></nz-option>
        </nz-select>
      </div>




      <div *ngIf="ad.product">

        <div class="m-8"></div>
        <!-- <hr/>
        <div class="m-8"></div>

        <div class="m-8"></div> -->
        <hr/>
        <div class="m-8"></div>

        <h2>Perioder som ikke kan bestilles</h2> 
        <p>For å sperre en konkret dag så settes fra og til-dato til den samme datoen.</p>

        <nz-form-item>
          <nz-form-label>Maks dato for bestilling: </nz-form-label>
          <nz-form-control>
            <nz-date-picker [(ngModel)]="ad.maxOrderDate"  [nzFormat]="'yyyy-MM-dd'" [nzAllowClear]="false" ></nz-date-picker>
          </nz-form-control>
        </nz-form-item>
          

        <div nz-row>
          <div  nz-col [nzXs]="apps.bas.ui.gridLg.xs" [nzSm]="apps.bas.ui.gridLg.sm" [nzMd]="apps.bas.ui.gridLg.md" [nzLg]="apps.bas.ui.gridLg.lg" [nzXl]="apps.bas.ui.gridLg.xl" [nzXXl]="apps.bas.ui.gridLg.xxl">
                
            <nz-table 
              #periodsTable 
              nzTableLayout="fixed" 
              [nzData]="ad.periods || []"
              [nzPageSize]="100"
              [nzShowPagination]="false"
              nzSize="small"
              class="nz-table-xs"
            >
            <thead>
              <tr>
        
                <th>{{ 'common.from' | actrans }}</th>
                <th>{{ 'common.to' | actrans }}</th>
                <th nzWidth="50px"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of periodsTable.data">
        
                <td> <nz-date-picker   [(ngModel)]="data.from"  [nzFormat]="'yyyy-MM-dd'" [nzAllowClear]="false" ></nz-date-picker>  </td>
                <td><nz-date-picker   [(ngModel)]="data.to"  [nzFormat]="'yyyy-MM-dd'" [nzAllowClear]="false" ></nz-date-picker> </td>

                <td nzAlign="center">
                  <button nz-button nzType="default" nzSize="small" [title]="'common.delete' | actrans"  (click)="removePeriod(data)">
                    <i nz-icon [nzType]="'delete'"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </nz-table>
          <div class="text-right">
            <button nz-button nzType="default"  (click)="addPeriod()">
              <i nz-icon nzType="plus-circle"></i>
              {{ 'common.add' | actrans }} periode
            </button>
        
            <button nz-button nzType="primary"  (click)="savePeriods()">
              <i nz-icon nzType="save"></i>
              {{ 'common.save' | actrans }} perioder
            </button>
        
          </div>

        </div>
      </div>


      <div class="m-8"></div>
      <hr/>

      <div class="m-8"></div>

      <div class="pull-right">
        <button nz-button nzType="primary"  (click)="newObject()">
          <i nz-icon nzType="plus-circle"></i>
          {{ 'common.add' | actrans }} plass
        </button>
    
      </div>

        <h2>Plasser</h2> 

        <div class="m-d"></div>

        <nz-table 
          #objectsTable 
          nzTableLayout="fixed" 
          [nzData]="ad.product.spaces || []"
          [nzPageSize]="100"
          [nzShowPagination]="false"
          nzSize="small"
          class="nz-table-xs"
        >
        <thead>
          <tr>
    
            <th>{{ 'common.name' | actrans }}</th>
            <th nzAlign="right">{{ 'Venstre' | actrans }}</th>
            <th nzAlign="right">{{ 'Topp' | actrans }}</th>
            <th nzAlign="right">{{ 'Bredde' | actrans }}</th>
            <th nzAlign="right">{{ 'Høyde' | actrans }}</th>
            <th nzAlign="right">{{ 'Rot.' | actrans }}</th>
            <th nzWidth="50px"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of objectsTable.data">
    
            <td nzEllipsis>{{ data.name }}</td>
            <td nzAlign="right">{{ data.jsonDataObj?.spaceBooking?.left | nf }}</td>
            <td nzAlign="right">{{ data.jsonDataObj?.spaceBooking?.top | nf }}</td>
            <td nzAlign="right">{{ data.jsonDataObj?.spaceBooking?.width | nf }}</td>
            <td nzAlign="right">{{ data.jsonDataObj?.spaceBooking?.height | nf }}</td>
            <td nzAlign="right">{{ data.jsonDataObj?.spaceBooking?.rotation | nf }}</td>
      
    
            <td nzAlign="center">
              <button nz-button nzType="default" nzSize="small" [title]="'common.edit' | actrans"  (click)="editObject(data)">
                <i nz-icon [nzType]="'edit'"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </nz-table>
    
      <nz-modal [(nzVisible)]="edit.obj" [nzTitle]="'common.edit' | actrans | str" nzWidth="90vw"  (nzOnCancel)="onEditCancel()" (nzOnOk)="onEditOk();">
        <ng-container *nzModalContent>
          
    
          <form nz-form [formGroup]="edit.form" class="item-form mt-d" nzLayout="vertical" [nzAutoTips]="apps.bas.fs.autoTips" (ngSubmit)="onEditOk()"  >
      
            <btlib-form-row [controls]="[ edit.controls.name ]" [form]="edit.form"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row>
            <btlib-form-row [controls]="[ 
              edit.controls.spaceBooking.childMap.left,
              edit.controls.spaceBooking.childMap.top,
              edit.controls.spaceBooking.childMap.width,
              edit.controls.spaceBooking.childMap.height,
              edit.controls.spaceBooking.childMap.rotation 
              ]" [form]="edit.form.controls.spaceBooking"  [grid]="apps.bas.ui.gridXs" ></btlib-form-row> 

          </form>
          
    
        </ng-container>
      </nz-modal>


      </div>

    </div>
    
  </div>

</btlib-admin-page-wrapper>
