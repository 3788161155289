<btlib-admin-page-wrapper>

  <div class="m-d">
    <h1>{{ 'web.su.properties' | actrans }}</h1>
    <div class="text-right">
      <button nz-button nzType="primary"  (click)="newObject()">
        <i nz-icon nzType="plus-circle"></i>
        {{ 'common.add' | actrans }}
      </button>
  
    </div>


    <!-- TODO: filter -->
   
  </div>

  <nz-table 
    #objectsTable 
    nzTableLayout="fixed" 
    [nzData]="objects || []"
    [nzPageSize]="100"
    nzSize="small"
    class="nz-table-xs"
    >
    <thead>
      <tr>
        <th>{{ 'common.name' | actrans }}</th>
        <th>{{ 'common.value' | actrans }}</th>
        <th nzWidth="50px"></th>
        <th nzWidth="50px"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of objectsTable.data">

        <td class="text-sm">{{ data.name }}</td>
        <td>{{ data.mkValue }}</td>
    
        <td nzAlign="center">
          <i nz-icon nzType="edit"  
          [title]="'common.edit' | actrans"
          (click)="editObject(data)"></i>
        </td>
        <td nzAlign="center">


          <i nz-icon nzType="delete" 
          nz-popconfirm
          [nzPopconfirmTitle]="'app.lib.common.popconfirm.confirm' | actrans | str"  
          [title]="'common.delete' | actrans"
          (nzOnConfirm)="deleteObject(data)" [nzTheme]="'twotone'" [nzTwotoneColor]="'#eb2f96'"></i>
        </td>
      </tr>
    </tbody>
  </nz-table>

  <nz-modal [(nzVisible)]="edit.obj" [nzTitle]="'common.edit' | actrans | str" nzWidth="90vw"  (nzOnCancel)="onEditCancel()" (nzOnOk)="onEditOk();">
    <ng-container *nzModalContent>
      

      <form nz-form [formGroup]="edit.form" class="item-form mt-d" nzLayout="vertical" [nzAutoTips]="apps.bas.fs.autoTips" (ngSubmit)="onEditOk()"  >
  
        <btlib-form-row [controls]="[ edit.controls.name ]" [form]="edit.form"  [grid]="apps.bas.ui.gridFull"  ></btlib-form-row>
        <btlib-form-row [controls]="[ edit.controls.value ]" [form]="edit.form"  [grid]="apps.bas.ui.gridFull"  ></btlib-form-row>
 
      </form>
      

    </ng-container>
  </nz-modal>





</btlib-admin-page-wrapper>
