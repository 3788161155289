
import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { MiscUtil } from 'src/app/lib/core/util/misc.util';

import { AppService } from 'src/app/services/app.service';

import { FormGroup } from '@angular/forms';
import { DynformControl, DynformControlsMap } from 'src/app/lib/core/model/dymform-control';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-admin-discount-codes-page',
  templateUrl: './admin-discount-codes-page.component.html',
  styleUrls: ['./admin-discount-codes-page.component.scss']
})
export class AdminDiscountCodesPageComponent implements OnInit {

  
    dirty = false;
  
    fbo:any = {
      loading: true,
      stats: {
        current: [],
        navigatorObj: {
          size: 0,
          pageSize: 20,
          page: 0
        }
      }
  
    };
  
    edit:any = {
      obj: undefined,
      loading: false,
  
      item: {
        id: -1,
        obj: undefined,
      },
      user: {
        id: -1,
        obj: undefined,
      }
    }
  
  
    customFilters:any = { }
    df:any = {
      form: undefined,
      controls: [],
      obj: undefined,
      params: { 
        navPageSize: 20,
        // 
      },
      statsForm: { 
        statsType: "DISCOUNT_CODE",
        dateMode: "START",
        currentJil: "min"
      },
  
      loading: false
    };
    
    // https://192.168.1.82:8200/api/nesbyen/admin/misc/discount-codes
    constructor(public injector:Injector, public apps:AppService) {
      let route = injector.get(ActivatedRoute);
      this.apps.bas.us.checkActivatedRoute(route.snapshot);
  
      if(this.apps.bas.envtest) console.log("AdminDiscountCodesPageComponent");
  
    }
  
    ngOnInit(): void {
  
    }

    onQueryParamsChange(queryParams: NzTableQueryParams): void {
      if (this.apps.bas.envtest) console.log("onQueryParamsChange: ", queryParams);
      const { pageSize, pageIndex, sort, filter } = queryParams;
      const currentSort = sort.find(item => item.value !== null);
      const sortField = (currentSort && currentSort.key) || null;
      const sortOrder = (currentSort && currentSort.value) || null;
  
      let params:any = { };
      
      let page = pageIndex !== undefined && pageIndex > 0 ? pageIndex -1 : undefined;
      let nav = this.fbo.stats.navigatorObj;
      if ((page !== undefined && nav.page != page )) { 
        params.navAction = "page";
        params.navParam = page;
      } else if ((pageSize !== undefined && nav.pageSize != pageSize )) { 
        params.navAction = "pageSize";
        params.navParam = pageSize;
      }
  
      if (params.navAction == undefined) {

      }
      
      let sfi:any = { }
      if (sortField) {
        sfi.customSortsList = [
       
          { field: sortField, asc: sortOrder === "ascend" }
        ];
  
      }
  
      if (filter) {
        sfi.customFiltersMap = { };
        for (let item of filter) {
          if (item.value == null || item.value.length == 0) continue;
     
          sfi.customFiltersMap[item.key] = { value: item.value };
  
        }
      }
  
  
      this.updateFbo(params, sfi);
    }
  
    updateFbo(input:any = { }, statsFormInput:any = { }) {
      let params:any = {
        aType: "appAdmin",
        action: "getStatsFbo",
        page: "discountCodes",
      };
  
      let rv = { };
      if (this.df.form) {
        rv = this.apps.bas.fs.getRawValue(this.df.form, this.df.controls);
        if (rv === false) return;
    
      }
  
      statsFormInput.customFiltersMap = statsFormInput.customFiltersMap || { };
      for (let key in this.customFilters) {
        let val = this.customFilters[key];
        if (val == undefined || val == "") continue; 
        statsFormInput.customFiltersMap[key] = { value: val, type: "search" };
      }
  
      MiscUtil.extend(rv,  statsFormInput);
  
  
      MiscUtil.extend(params, input);
  
      let statsForm = MiscUtil.extend({ }, this.df.statsForm);
      MiscUtil.extend(statsForm, rv);
  
      MiscUtil.extend(params, this.df.params);
      MiscUtil.extend(params, { statsForm: JSON.stringify(statsForm) } );
  
      if (this.apps.bas.envtest) console.log("updateFbo.params: ", params);
  
      this.fbo.loading = true;
      this.apps.bas.ws.json(params ).then((json:any) => {
        if (this.apps.bas.envtest) console.log("updateFbo.then, json: ", json);
  
        //TODO: må lagre dssse verdiene lokalt slik at man ikke må stille inn hver gang siden lastes.
  
        if (json.success) {
          this.updateForm(json.stats);
  
          this.fbo = json;
          
        } else {
  
        }
        
  
       });
    }
  
    
    updateForm(stats:any) {
  
      let controls: DynformControl[] = [];

      controls.push(new DynformControl({ key: 'search',               mk: 'common.search' }));

      
      // controls.push(new DynformControl({ 
      //   key: 'product',  
      //   // valuePath: "ruleId",
      //   mk: 'common.product',  
      //   controlType: 'select', 
      //   // required: true,
      //   options: () => {
      //     return stats.products || []; 
      //   },
      //   optionsAllowEmpty: true,
      //   optionsFieldValue: "id",
      //   optionsFieldLabel: "mkName"
      // }));


  
      this.apps.bas.fs.updateFormObj(this.df, controls, stats);
  
  
    }
  
    dfSubmit() {
      this.updateFbo();
    }
  
  
    newObject() {
  
  
      this.editObject(this.fbo.newDiscountCode); 
    }
  
    copyObject(src:any) {
      let obj = MiscUtil.clone(src);
      obj.id = ""; //this.edit[type].id--;
      obj.items.map((item:any) => item.id = this.edit.item.id--)
      obj.users.map((user:any) => user.id = this.edit.user.id--)
  
      this.editObject(obj); 
    }
  
  
    editObject(obj:any) {
  
      if(this.apps.bas.envtest) console.log("editObject, obj: ", obj);
  
      let controls: DynformControl[] = [];

      // controls.push(new DynformControl({ 
      //   key: 'type',  
      //   valuePath: "typeLabel",
      //   mk: 'common.type',  
      //   controlType: 'select', 
      //   required: true,
      //   options: () => {
      //     return this.fbo.stats.taskTypes || []; 
      //   },
      //   optionsAllowEmpty: true,
      //   optionsFieldValue: "enumName",
      //   optionsFieldLabel: "enumName"
      // }));
  

      controls.push(new DynformControl({ 
        key: 'product',  
        // valuePath: "statusId",
        mk: 'common.product',  
        controlType: 'select', 
        // required: true,
        options: () => {
          return this.fbo.stats.products || [];
        },
        optionsAllowEmpty: true,
        optionsFieldValue: "id",
        optionsFieldLabel: "mkName"
      }));
  
  
      controls.push(new DynformControl({ 
        key: 'orderPeriodAsRange',     
        mk: 'Ordreperiode', 
        controlType: "datetime-range-picker",
        mkInfo: "common.orderBookingPeriodHelpText"
      }));
      controls.push(new DynformControl({ 
        key: 'bookingPeriodAsRange',     
        mk: 'Bookingperiode', 
        controlType: "date-range-picker" ,
        mkInfo: "common.orderBookingPeriodHelpText"
      }));

      controls.push(new DynformControl({ 
        key: 'amountAsDouble',     
        mk: 'common.amount', 
        controlType: "input-number" 
      }));
      controls.push(new DynformControl({ 
        key: 'percentAsDouble',     
        mk: 'common.percent', 
        controlType: "input-number" 
      }));

      controls.push(new DynformControl({ 
        key: 'code',     
        mk: 'common.code', 
        disabled: obj.id != ""
      }));
      controls.push(new DynformControl({ 
        key: 'productTag',     
        mk: 'web.common.productTag', 
      }));

      controls.push(new DynformControl({ 
        key: 'limitCount',     
        mk: 'bus.discountCode.limitCount', 
        controlType: "input-number" 
      }));
      controls.push(new DynformControl({ 
        key: 'limitAmountAsDouble',     
        mk: 'bus.discountCode.limitAmount', 
        controlType: "input-number" 
      }));

  
      this.apps.bas.fs.updateFormObj(this.edit, controls, obj);
    } 
  
    onEditCancel() {
      this.edit.obj = undefined;
    }
    async onEditOk() {

  
      let rv = this.apps.bas.fs.getRawValue(this.edit.form, this.edit.controls);
      if (rv === false) return;
      
      let obj = this.edit.obj;
      let isNew = !obj.id;
  
      if(this.apps.bas.envtest) console.log("onEditOk, obj: ", obj);
      if(this.apps.bas.envtest) console.log("onEditOk, rv: ", rv);
  
      this.edit.loading = true;
  
      let params:any = {
        aType: "appAdmin", 
        action: "saveObject", 
        type: "discountCode",
        id: isNew ? "new" : obj.id,
        isNew: isNew,
        object: JSON.stringify(rv)
      }
  
      await this.apps.bas.ws.json(params,  { showLoading: true }).then((json) => {
        if (json.success) {
          this.updateFbo();
  
        }
      });
      
      this.edit.loading = false;
      
      this.edit.obj = undefined;
    }
  
  
    onChange(event:any) {
      if (!event.control) return;
      this.dirty = true;
  
    }
  

  
}
  