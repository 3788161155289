<btlib-admin-page-wrapper [showMenu]="!inputId">
 
 
  <nz-spin [nzSpinning]="isSpinning">

    <div class="m-d" *ngIf="edit.obj">

  
    <div nz-row  [nzGutter]="8" style="flex-flow: row;">
      <div *ngIf="!inputId" nz-col [nzFlex]="inputId ? '0px': '35px'">


        <nz-affix [nzOffsetTop]="10">
          

          <div id="linkTop"></div>
          <nz-anchor [nzAffix]="false">

            <button nz-button nzType="primary" nzShape="circle" class="section-button-back" [nzSize]="apps.bas.ui.r.gtesm ? 'default' : 'small'" [routerLink]="apps.bas.ui.getRouterPrefix() + '/user/users'" [title]="'web.common.users' | actrans">
              <i nz-icon nzType="left"></i>
            </button>

            <nz-link [nzHref]="'#linkTop'" [nzTitle]="saveLinkTemplate" class="section-link" > 
              <ng-template #saveLinkTemplate>
                <button nz-button nzType="primary" nzShape="circle" class="section-button-save" [nzSize]="apps.bas.ui.r.gtesm ? 'default' : 'small'" (click)="submitForm()" [title]="'common.save' | actrans">
                  <i nz-icon nzType="save"></i>
                </button>
              </ng-template>

            </nz-link>
            
            <nz-link [nzHref]="'#linkTop'" [nzTitle]="helpLinkTemplate" class="section-link" > 
              <ng-template #helpLinkTemplate>
                <button nz-button nzType="primary" nzShape="circle" class="section-button" [nzSize]="apps.bas.ui.r.gtesm ? 'default' : 'small'" (click)="helpVisible = true;"  [title]="'common.help' | actrans" ><i nz-icon nzType="question-circle"></i></button>
              </ng-template>

            </nz-link>

            <nz-link *ngFor="let s of apps.bas.us.values(Sections)" [nzHref]="'#section-' + s.id" [nzTitle]="linkTemplate" class="section-link" > 
              <ng-template #linkTemplate>
                <button  *ngIf="isSectionVisible(s.id, true)" nz-button nzType="primary" nzShape="circle" class="section-button" [nzSize]="apps.bas.ui.r.gtesm ? 'default' : 'small'" [title]="s.title | actrans" >
                  <i nz-icon [nzType]="s.icon"></i> 
                </button>

              </ng-template>

            </nz-link>

 
          </nz-anchor>



        </nz-affix>

        <nz-modal [(nzVisible)]="helpVisible" nzTitle="Hjelp" (nzOnCancel)="helpVisible = false;" (nzOnOk)="helpVisible = false;">
          <ng-container *nzModalContent>
            <h2>{{ 'app.cbapi5.page.admin.product.sections' | actrans }}</h2>
            <div nz-row *ngFor="let s of getVisibleSections()">
              <div nz-col  [nzFlex]="'35px'" >
                <i nz-icon [nzType]="s.icon"></i> 
              </div>
              <div nz-col >
                {{ s.title | actrans }}

              </div>
            </div>
  
          </ng-container>
        </nz-modal>

       
      </div>

      <div nz-col  nzFlex="auto" [style]="inputId ? '' : 'width: calc(100% - 40px);'">


        <form nz-form [formGroup]="edit.form" class="user-form" (ngSubmit)="submitForm()" nzLayout="vertical" [nzAutoTips]="apps.bas.fs.autoTips">
  
          <h1>{{ edit.form.controls.username.value }} </h1>

          <hr/>



        
          <div id="section-basic" *ngIf="isSectionVisible('basic')">
            <h2>{{ 'app.lib.common.basic' | actrans }}</h2>
            
            
            <div>
        
              <btlib-form-row  [controls]="[ edit.controls.username, edit.controls.role, edit.controls.password ]" [form]="edit.form"  [grid]="apps.bas.ui.gridSm" (change)="onChange($event)" ></btlib-form-row> 
              
              <btlib-form-row  [controls]="[ edit.controls.name, edit.controls.displayName, edit.controls.email ]" [form]="edit.form"  [grid]="apps.bas.ui.gridSm" (change)="onChange($event)" ></btlib-form-row> 
              <btlib-form-row  [controls]="[ edit.controls.address1, edit.controls.address2, edit.controls.address3 ]" [form]="edit.form"  [grid]="apps.bas.ui.gridSm" (change)="onChange($event)" ></btlib-form-row> 
              <btlib-form-row  [controls]="[ edit.controls.zipCode, edit.controls.city, edit.controls.country ]" [form]="edit.form"  [grid]="apps.bas.ui.gridSm" (change)="onChange($event)" ></btlib-form-row> 
              <btlib-form-row  [controls]="[ edit.controls.language, edit.controls.timeZone ]" [form]="edit.form"  [grid]="apps.bas.ui.gridMd" (change)="onChange($event)" ></btlib-form-row> 
              <btlib-form-row  [controls]="[ edit.controls.telephone, edit.controls.invoiceNote ]" [form]="edit.form"  [grid]="apps.bas.ui.gridMd" (change)="onChange($event)" ></btlib-form-row> 
          
            </div>
            
            <div *ngIf="edit.obj.userRole.isUser || edit.obj.userRole.isProvider || edit.obj.userRole.isCompany">
              <btlib-form-row  [controls]="[ 
                edit.controls.customerType, 
                edit.controls.socialSecurityNumber,  
                edit.controls.orgNumber
                ]" [form]="edit.form"  [grid]="apps.bas.ui.gridMd" (change)="onChange($event)" ></btlib-form-row> 
          
            </div>

            <btlib-form-row  [controls]="[ edit.controls.notes ]" [form]="edit.form"  [grid]="apps.bas.ui.gridXl" (change)="onChange($event)" ></btlib-form-row> 
            <btlib-form-row  [controls]="[ edit.controls.tagsAsString ]" [form]="edit.form"  [grid]="apps.bas.ui.gridXl" (change)="onChange($event)" ></btlib-form-row> 
          

    
            
            <div>
              <btlib-form-row  [controls]="[ edit.controls.acceptInfoEmail, edit.controls.acceptInfoSms ]" [form]="edit.form"  [grid]="apps.bas.ui.gridMd" (change)="onChange($event)" ></btlib-form-row> 
          
            </div>
  
    

          </div>



          <div id="section-companyInfo" *ngIf="isSectionVisible('companyInfo')">
            <hr/>


            <h2>{{ 'web.common.user.companyInfo' | actrans }}</h2>

            <div *ngIf="edit.obj.userRole.isUser && !edit.ct.isPrivate">
              <btlib-form-row  [controls]="[ edit.controls.ehfEnabled ]" [form]="edit.form"  [grid]="apps.bas.ui.gridMd" (change)="onChange($event)" ></btlib-form-row> 
              <btlib-form-row *ngIf="edit.form.controls.ehfEnabled.value"  [controls]="[ 
              edit.controls.ehfOrderReference, 
              edit.controls.ehfContractDocumentReference,  
              edit.controls.ehfContactId
              ]" [form]="edit.form"  [grid]="apps.bas.ui.gridMd" (change)="onChange($event)" ></btlib-form-row> 
        
            </div>
    
    
            <div *ngIf="edit.obj.userRole.isProvider || edit.obj.userRole.isCompany ">
              <btlib-form-row  [controls]="[ 
              edit.controls.webAddress,  
              edit.controls.bankAccount
              ]" [form]="edit.form"  [grid]="apps.bas.ui.gridMd" (change)="onChange($event)" ></btlib-form-row> 
        
            </div>
    

          </div>

          <div id="section-provider" *ngIf="isSectionVisible('provider')">
            <hr/>


            <h2>{{ 'common.provider' | actrans }}</h2>

            <btlib-form-row  [controls]="[ 
              edit.controls.upObj.childMap.settleDay,  
              edit.controls.upObj.childMap.settleWeekday,  
              edit.controls.upObj.childMap.payoutDelay,  
              edit.controls.upObj.childMap.providerPercent,  
              edit.controls.upObj.childMap.yearlyFixedFeeAsDouble
              ]" [form]="edit.form.controls.upObj"  [grid]="apps.bas.ui.gridMd" (change)="onChange($event)" ></btlib-form-row> 
      

          </div>
      
          <!-- <div id="section-company" *ngIf="isSectionVisible('company')">
            <hr/>


            <h2>{{ 'common.company' | actrans }}</h2>

            
            <btlib-form-row  [controls]="[ 
              edit.controls.ucObj.childMap.parent,  
              edit.controls.ucObj.childMap.productTypePrimary,  
            
     
              
              ]" [form]="edit.form.controls.ucObj"  [grid]="apps.bas.ui.gridMd" (change)="onChange($event)" ></btlib-form-row> 
      
     
              <btlib-form-row  [controls]="[ 
              edit.controls.ucObj.childMap.productTypesSecondaryIdsAsString,  
   
     
              ]" [form]="edit.form.controls.ucObj"  [grid]="apps.bas.ui.gridMd" (change)="onChange($event)" ></btlib-form-row> 
      
              <btlib-form-row  [controls]="[ 
              edit.controls.ucObj.childMap.ga4IdProd,  
   
     
              ]" [form]="edit.form.controls.ucObj"  [grid]="apps.bas.ui.gridMd" (change)="onChange($event)" ></btlib-form-row> 
              
              <btlib-form-row  [controls]="[ 
              edit.controls.ucObj.childMap.languagesAsString,  
   
     
              ]" [form]="edit.form.controls.ucObj"  [grid]="apps.bas.ui.gridMd" (change)="onChange($event)" ></btlib-form-row> 
      
              

          </div> -->
          
          <div id="section-contacts" *ngIf="isSectionVisible('contacts')">
            <hr/>



            
            <div class="pull-right">
              <button nz-button [disabled]="!edit.obj.id" nzType="default" class="item-new" (click)="newItem($event, 'contact')">
                {{ 'common.add' | actrans }}
              </button>
            </div>
            <h2>{{ 'bus.user.contacts' | actrans }}</h2>
    
            <div *ngIf="edit.obj.contacts?.length">
              <nz-table 
                #nzTableContacts
                [nzData]="edit.obj.contacts || []" 
                nzTableLayout="fixed" 
                [nzShowPagination]="true" 
                nzSize="small"
                class="nz-table-xs"
                >
                <thead>
                  <tr>
                
                    <th *ngIf="apps.bas.ui.r.gtexl" [nzWidth]="'120px'">{{ 'common.time' | actrans }}</th>
  
                    <th [nzWidth]="'200px'" >{{ 'common.type' | actrans }}</th>
                    <th *ngIf="apps.bas.ui.r.gtelg">{{ 'common.name' | actrans }}</th>
                    <th *ngIf="apps.bas.ui.r.gtesm">{{ 'common.telephone' | actrans }}</th>
                    <th>{{ 'common.email' | actrans }}</th>
                    <th *ngIf="apps.bas.ui.r.gtexl">{{ 'common.comment' | actrans }}</th>
                    <th nzWidth="30px"></th>
                    <th nzWidth="30px"></th>
              
            
                  </tr>
                </thead>
                <tbody>
                  <ng-container  *ngFor="let data of nzTableContacts.data; let idx = index">
                    <tr >
                      <td *ngIf="apps.bas.ui.r.gtexl">{{ data.time }}</td>
                      <td>{{ data.mkType }}</td>
                      <td *ngIf="apps.bas.ui.r.gtelg">{{ data.name }}</td>
                      <td *ngIf="apps.bas.ui.r.gtesm">{{ data.telephone }}</td>
                      <td>{{ data.email }}</td>
                      <td *ngIf="apps.bas.ui.r.gtexl">{{ data.comment }}</td>
                      

                      <td>
                        <i nz-icon class="cursor-pointer" nzType="delete" (click)="deleteItem(idx, 'contact')" [nzTheme]="'twotone'" [nzTwotoneColor]="'#eb2f96'"></i>
                      </td>
                      <td>
                        <i nz-icon class="cursor-pointer"nzType="edit" (click)="editItem(idx, 'contact')" ></i>
                      </td>
                    </tr>
              
                  </ng-container>
                </tbody>
              </nz-table>

            </div> 
            
            <!-- [nzComponentParams]="{ item: edit.contact.obj }" -->
            <nz-modal [nzVisible]="!!edit.contact.obj" nzTitle="{{ 'bus.contact' | actrans | str }}" nzWidth="95vw" [nzContent]="modalContentItem"  (nzOnCancel)="edit.contact.obj = null" (nzOnOk)="saveItem('contact')">
              <ng-template #modalContentItem let-params>


                <form nz-form [formGroup]="edit.contact.form" class="item-form mt-d" nzLayout="vertical" [nzAutoTips]="apps.bas.fs.autoTips">

                  <btlib-form-row [controls]="[ edit.contact.controls.time ]" [form]="edit.contact.form"  [grid]="apps.bas.ui.gridMd" (change)="onChange($event)"  ></btlib-form-row>  
                  <btlib-form-row [controls]="[ 
                    edit.contact.controls.type,
                    edit.contact.controls.name,
                    edit.contact.controls.telephone,
                    edit.contact.controls.email,
                    
                  ]" [form]="edit.contact.form"  [grid]="apps.bas.ui.gridMd"  (change)="onChange($event)" ></btlib-form-row>  
                  <btlib-form-row [controls]="[ edit.contact.controls.comment ]" [form]="edit.contact.form"  [grid]="apps.bas.ui.gridFull"  (change)="onChange($event)" ></btlib-form-row>  
      

                  
                </form>
    

              </ng-template>
    
            </nz-modal> 

          

          </div>
          
          <div id="section-agent" *ngIf="isSectionVisible('agent')">
            <hr/>


            <h2>{{ 'common.agent' | actrans }}</h2>
            <!--  -->
          </div>

          <div id="section-ups" *ngIf="isSectionVisible('ups')">
            <hr/>


            <h2>{{ 'bus.user.ups' | actrans }}</h2>
            <!--  -->
            <div *ngIf="edit.obj.upsList?.length">
              <nz-table 
                  #nzTableUps
                  [nzData]="edit.obj.upsList || []" 
                  nzTableLayout="fixed" 
                  [nzShowPagination]="true"
                  [nzPageSize]="20" 
                  nzSize="small"
                  class="nz-table-xs"
                  >
                  
                  <thead>
                    <tr>
                  
                      <th>{{ 'common.type' | actrans }}</th>
                      <th>{{ 'common.value' | actrans }}</th>
                      <th *ngIf="apps.bas.ui.r.gtelg" nzWidth="110px">{{ 'common.defaultValue' | actrans }}</th>
                    
                
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container  *ngFor="let data of nzTableUps.data; let idx = index">
                      <tr *ngIf="edit.controls.upsMap.childMap[data.upObj.id + '']">
      
                        <td>{{ data.upObj.mkName }} </td>
                        <td>
                          <bt-dynamic-form-control 
                            [control]="edit.controls.upsMap.childMap[data.upObj.id + '']" 
                            [form]="edit.form.controls.upsMap" 
                            (change)="onChange($event)" >
                          </bt-dynamic-form-control>  
                        </td>
                        <td *ngIf="apps.bas.ui.r.gtelg">{{ data.upObj.mkDefaultValue }}</td>
                        
                
                      </tr>
                
                    </ng-container>
                  </tbody>
                </nz-table>

      

            </div> 
          </div>


          <div id="section-log" *ngIf="isSectionVisible('log')">
            <hr/>


            <h2>{{ 'common.log' | actrans }}</h2>
            <!--  -->
            <div>
              <nz-table 
                  #nzTableLog
                  [nzData]="edit.obj.logs || []" 
                  nzTableLayout="fixed" 
                  [nzShowPagination]="true"
                  [nzPageSize]="5" 
                  nzSize="small"
                  class="nz-table-xs"
                  >
                  <thead>
                    <tr>
                  
                      <th *ngIf="apps.bas.ui.r.gtesm"  [nzWidth]="apps.bas.ui.r.gtesm ? '100px' : '80px'">{{ 'common.time' | actrans }}</th>
                      <th *ngIf="apps.bas.ui.r.gtemd" nzWidth="120px">{{ 'common.user' | actrans }}</th>
                      <th *ngIf="apps.bas.ui.r.gtelg" nzWidth="150px">{{ 'common.type' | actrans }}</th>
                      <th *ngIf="apps.bas.ui.r.gtelg" nzWidth="110px">{{ 'common.ipAddress' | actrans }}</th>
                      <th>{{ 'bus.log.text' | actrans }}</th>
                      <th nzWidth="30px"></th>
                      <th nzWidth="30px"></th>
                
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container  *ngFor="let data of nzTableLog.data; let idx = index">
                      <tr >
                        <td *ngIf="apps.bas.ui.r.gtesm">{{ data.time }}</td>
                        <td *ngIf="apps.bas.ui.r.gtemd">{{ data.creator }}</td>
                        <td *ngIf="apps.bas.ui.r.gtelg">{{ data.mkType }}</td>
                        <td *ngIf="apps.bas.ui.r.gtelg">{{ data.ipAddress }}</td>
                        
                        <td> <div [innerHtml]="data.textOutput | trusthtml"></div> </td>
                        <td>
                          <i nz-icon class="cursor-pointer" nzType="delete" (click)="deleteItem(idx, 'log')" [nzTheme]="'twotone'" [nzTwotoneColor]="'#eb2f96'"></i>
                        </td>
                        <td>
                          <i nz-icon class="cursor-pointer"nzType="edit" (click)="editItem(idx, 'log')" ></i>
                        </td>
                      </tr>
                
                    </ng-container>
                  </tbody>
                </nz-table>

                <!-- [nzComponentParams]="{ item: edit.log.obj }"  -->
                <nz-modal [nzVisible]="!!edit.log.obj" nzTitle="{{ 'common.log' | actrans | str }}" nzWidth="95vw" [nzContent]="modalContentItem" (nzOnCancel)="edit.log.obj = null" (nzOnOk)="saveItem('log')">
                  <ng-template #modalContentItem let-params>
    
      
                    <form nz-form [formGroup]="edit.log.form" class="item-form mt-d" nzLayout="vertical" [nzAutoTips]="apps.bas.fs.autoTips">
    
                      <btlib-form-row [controls]="[ edit.log.controls.time ]" [form]="edit.log.form"  [grid]="apps.bas.ui.gridMd" (change)="onChange($event)"  ></btlib-form-row>  
                      <btlib-form-row [controls]="[ edit.log.controls.text ]" [form]="edit.log.form"  [grid]="apps.bas.ui.gridXl"  (change)="onChange($event)" ></btlib-form-row>  
                      
                    </form>
        
    
                  </ng-template>
        
                </nz-modal>

            </div> 

          </div>

  


          <hr/>
          <nz-form-item>
            <nz-form-control class="form-buttons">
              <button nz-button nzType="primary">
                {{ 'app.lib.common.save' | actrans }} 
              </button>

              <span *ngIf="edit.obj.id">
                  
                <button nz-button nzType="default" (click)="$event.preventDefault(); userChangePassword = edit.obj;">{{ 'web.ajax.password' | actrans }}</button>
                <button nz-button nzType="default" (click)="$event.preventDefault(); userChangeUsername = edit.obj;">{{ 'web.common.user.changeUsername' | actrans }}</button>
                
                <button nz-button nzType="default" nzDanger class="pull-right"  (click)="$event.preventDefault(); userDeleteUser = edit.obj;">{{ 'web.common.user.deleteUser' | actrans }}</button>

              </span>
            </nz-form-control>
          </nz-form-item>

        </form>

        <bt-user-change-password [(user)]="userChangePassword"></bt-user-change-password>
        <bt-user-change-username [(user)]="userChangeUsername" (userChange)="onUserChange($event, 'username')" ></bt-user-change-username>
        <bt-user-delete-user  [(user)]="userDeleteUser" (userChange)="onUserChange($event, 'delete')"></bt-user-delete-user>

    

      </div>

    </div>




  </div>
</nz-spin>




        <!-- 

          <div>
          <hr/>
          <h2> {{ 'common.map' | actrans | str | lowercase}}</h2>

          <nz-modal [(nzVisible)]="mapVisible" [nzTitle]="'common.map' | actrans | str" nzWidth="90vw"  (nzOnCancel)="onMapSelectPositionCancel()" (nzOnOk)="mapVisible = false;">
            <ng-container *nzModalContent>
              <bt-google-maps #mapMain
              
                [params]="{ 
                selectPosition: true, 
                coords: { 
                  latitude: edit.form.controls.latitude.value || apps.bas.ds.config.company.ucObj.latitude, 
                  longitude: edit.form.controls.longitude.value || apps.bas.ds.config.company.ucObj.longitude 
                }
              }" (mapSelectPosition)="onMapSelectPosition($event)" ></bt-google-maps>
          
    
            </ng-container>
          </nz-modal>

          <div nz-row [nzGutter]="apps.bas.ui.gridMd.gutter" nzAlign="middle">
            <div nz-col [nzXs]="apps.bas.ui.gridMd.xs" [nzSm]="apps.bas.ui.gridMd.sm" [nzMd]="apps.bas.ui.gridMd.md" [nzLg]="apps.bas.ui.gridMd.lg" [nzXl]="apps.bas.ui.gridMd.xl" [nzXXl]="apps.bas.ui.gridMd.xxl">
             
            </div>
            <div nz-col [nzXs]="apps.bas.ui.gridMd.xs" [nzSm]="apps.bas.ui.gridMd.sm" [nzMd]="apps.bas.ui.gridMd.md" [nzLg]="apps.bas.ui.gridMd.lg" [nzXl]="apps.bas.ui.gridMd.xl" [nzXXl]="apps.bas.ui.gridMd.xxl">
              
          
              
              <nz-form-item>
                <nz-form-label>{{ 'common.map' | actrans }} 
                  <span *ngIf="edit.form.controls.latitude.value">
                    &nbsp; | {{ edit.form.controls.latitude.value | str | slice:0:10 }}, {{ edit.form.controls.longitude.value | str | slice:0:10 }}
                  </span>
                </nz-form-label>
                <nz-form-control>
                  <button nz-button (click)="mapVisible = true; $event.preventDefault();" >
                    
                    {{ 'app.cbapi5.page.admin.product.placeOnMap' | actrans }}
                  </button>
       
                </nz-form-control>
              </nz-form-item>

            </div>
          </div>


          <btlib-form-row [controls]="[ edit.controls.address1, edit.controls.zipCode, edit.controls.city ]" [form]="edit.form"  [grid]="apps.bas.ui.gridMd" (change)="onChange($event)" ></btlib-form-row> 

    
          <div *ngIf="apps.bas.ds.config.appConfig.company.upCModSkattUtleieFraFormidlingsselskap">
            <btlib-form-row [controls]="[ edit.controls.kommuneNr, edit.controls.gardsNr, edit.controls.bruksNr ]" [form]="edit.form"  [grid]="apps.bas.ui.gridMd" (change)="onChange($event)" ></btlib-form-row> 

          </div>


        </div> -->


       
              <!-- <nz-upload
                nzType="drag"
                [nzMultiple]="true"
                [nzDisabled]="!p.id"
                [nzAction]="this.apps.bas.ws.getBaseUrlTools() + '?action=uploadDropzone&type=productImage&product=' + p.id"
                [nzHeaders]="uploadHeaders"
                [(nzFileList)]="edit.image.fileList"
                nzFileType="image/png,image/jpeg"
                nzListType="text"
                [nzShowUploadList]="{ showRemoveIcon: true }"
                (nzChange)="handleUploadItem($event, 'image')"
              >
                <p class="ant-upload-drag-icon">
                  <i nz-icon nzType="inbox"></i>
                </p>
                <p class="ant-upload-text">
                  {{ 'app.cbapi5.page.admin.product.clickOrDragImagesHere' | actrans }}
                  
                </p>
                <p class="ant-upload-hint">
                  {{ 'app.cbapi5.page.admin.product.uploadImagesHint' | actrans }}
                  
                </p>
              </nz-upload> -->

      
        



        
        <!-- 

          <div>
            <nz-table 
              #nzTableLog
              [nzData]="p.logs || []" 
              nzTableLayout="fixed" 
              [nzShowPagination]="true" 
              nzSize="small"
              class="nz-table-xs"
              >
              <thead>
                <tr>
              
                  <th *ngIf="apps.bas.ui.r.gtesm"  [nzWidth]="apps.bas.ui.r.gtesm ? '100px' : '80px'">{{ 'common.time' | actrans }}</th>
                  <th *ngIf="apps.bas.ui.r.gtelg" nzWidth="120px">{{ 'common.user' | actrans }}</th>
                  <th *ngIf="apps.bas.ui.r.gtelg" nzWidth="150px">{{ 'common.type' | actrans }}</th>
                  <th *ngIf="apps.bas.ui.r.gtelg" nzWidth="110px">{{ 'common.ipAddress' | actrans }}</th>
                  <th *ngIf="apps.bas.ui.r.gtesm">{{ 'bus.log.text' | actrans }}</th>
                  <th *ngIf="apps.bas.ui.r.ltexs">{{ 'common.log' | actrans }}</th>
                  <th nzWidth="30px"></th>
                  <th nzWidth="30px"></th>
            
                </tr>
              </thead>
              <tbody>
                <ng-container  *ngFor="let data of nzTableLog.data; let idx = index">
                  <tr >
                    <td *ngIf="apps.bas.ui.r.gtesm">{{ data.time }}</td>
                    <td *ngIf="apps.bas.ui.r.gtelg">{{ data.creator }}</td>
                    <td *ngIf="apps.bas.ui.r.gtelg">{{ data.mkType }}</td>
                    <td *ngIf="apps.bas.ui.r.gtelg">{{ data.ipAddress }}</td>
                    

                    <td *ngIf="apps.bas.ui.r.gtesm"> 
                      <div *ngIf="apps.bas.ui.r.ltemd">
                        <strong>{{ 'common.user' | actrans }}</strong> {{ data.creator }}<br/>
                        <strong>{{ 'common.type' | actrans }}</strong>: {{ data.mkType }}<br/>
                        <strong>{{ 'common.ipAddress' | actrans }}</strong>: {{ data.ipAddress }}
                        <hr/>
                      </div>
                      <div [innerHtml]="data.textOutput | trusthtml"></div>
                    </td>
                    
                   
                    <td *ngIf="apps.bas.ui.r.ltexs">
                      <div><strong>{{ 'common.time' | actrans }}</strong>: <span>{{ data.time }}</span></div>
                      <div *ngIf="apps.bas.ui.r.ltemd">
                        <strong>{{ 'common.user' | actrans }}</strong> {{ data.creator }}<br/>
                        <strong>{{ 'common.type' | actrans }}</strong>: {{ data.mkType }}<br/>
                        <strong>{{ 'common.ipAddress' | actrans }}</strong>: {{ data.ipAddress }}
          
                      </div>
                      <hr/>
                      <div [innerHtml]="data.textOutput | trusthtml"></div>
                    </td>
                    <td>
                      <i nz-icon class="cursor-pointer" nzType="delete" (click)="deleteItem(idx, 'log')" [nzTheme]="'twotone'" [nzTwotoneColor]="'#eb2f96'"></i>
                    </td>
                    <td>
                      <i nz-icon class="cursor-pointer"nzType="edit" (click)="editItem(idx, 'log')" ></i>
                    </td>
                  </tr>
            
                </ng-container>
              </tbody>
            </nz-table>

          </div> 
          
          <nz-modal [nzVisible]="!!edit.log.obj" nzTitle="{{ 'common.log' | actrans | str }}" nzWidth="95vw" [nzContent]="modalContentItem" [nzComponentParams]="{ item: edit.log.obj }" (nzOnCancel)="edit.log.obj = null" (nzOnOk)="saveItem('log')">
            <ng-template #modalContentItem let-params>


              <form nz-form [formGroup]="edit.log.form" class="item-form mt-d" nzLayout="vertical" [nzAutoTips]="apps.bas.fs.autoTips">

                <btlib-form-row [controls]="[ edit.log.edit.controls.time ]" [form]="edit.log.form"  [grid]="apps.bas.ui.gridMd" (change)="onChange($event)"  ></btlib-form-row>  
                <btlib-form-row [controls]="[ edit.log.edit.controls.text ]" [form]="edit.log.form"  [grid]="gridXl"  (change)="onChange($event)" ></btlib-form-row>  
                
              </form>
  

            </ng-template>
   
          </nz-modal> 
          -->


  <!-- 

            <div nz-row [nzGutter]="[8, 16]" nzAlign="top" cdkDropList  (cdkDropListDropped)="cdkDropListDroppedItem($event, 'time')" >
              <div cdkDrag *ngFor="let itemId of p.times; let idx = index" nz-col [nzXs]="12" [nzSm]="8" [nzMd]="8" [nzLg]="6" [nzXl]="6" [nzXXl]="4" >
            
                <nz-card [nzActions]=" [itemActionDelete, itemActionMove, itemActionEdit]" >
                  
                  <div>{{ p.timeMap[itemId].name }}</div>
                  <div>{{ p.timeMap[itemId].datePeriodDisplay }}</div>
                </nz-card>
    
                <ng-template #itemActionDelete>
                  <i nz-icon nzType="delete" (click)="deleteItem(idx, 'time')" [nzTheme]="'twotone'" [nzTwotoneColor]="'#eb2f96'"></i>
                </ng-template>
                <ng-template #itemActionMove>
                  <i nz-icon nzType="up" (click)="moveItem(idx, true, 'time')" ></i>
                  <i nz-icon nzType="drag" cdkDragHandle></i>
                  <i nz-icon nzType="down" (click)="moveItem(idx, false, 'time')"></i>
                </ng-template>
                <ng-template #itemActionEdit>
                  <i nz-icon nzType="edit" (click)="editItem(itemId, 'time')" ></i>
                </ng-template>
              </div>

              <div nz-col [nzXs]="12" [nzSm]="8" [nzMd]="8" [nzLg]="6" [nzXl]="6" [nzXXl]="4" style="margin:auto;">
        
                <div class="item-new-wrapper">
                  <div class="item-new-icon"><i nz-icon nzType="plus-circle"></i></div>
                  <button nz-button [disabled]="!p.id" nzType="dashed" nzSize="large" class="item-new" (click)="newItem($event, 'time')">
                    {{ 'common.add' | actrans }}
                  </button>
                </div>
  
              </div>
            </div>
            <nz-modal [nzVisible]="!!edit.time.obj" nzTitle="{{ 'bus.productTime' | actrans | str }}" nzWidth="95vw" [nzContent]="modalContentItem" [nzComponentParams]="{ item: edit.time.obj }" (nzOnCancel)="edit.time.obj = null" (nzOnOk)="saveItem('time')">
              <ng-template #modalContentItem let-params>

  
                <form nz-form [formGroup]="edit.time.form" class="item-form mt-d" nzLayout="vertical" [nzAutoTips]="apps.bas.fs.autoTips">

                  <btlib-form-row [controls]="[ 
                    edit.time.controls.time,
                    edit.time.controls.updated
                  ]" [form]="edit.time.form"  [grid]="gridMd" (change)="onChange($event)"  ></btlib-form-row>  
                 <btlib-form-row [controls]="[ edit.time.controls.enabled, edit.time.controls.timeEnabled ]" [form]="edit.time.form"  [grid]="gridMd" (change)="onChange($event)"  ></btlib-form-row> 
                 <btlib-form-row [controls]="[ edit.time.controls.name, edit.time.controls.datePeriodAsRange  ]" [form]="edit.time.form"  [grid]="gridMd"  (change)="onChange($event)" ></btlib-form-row>  
                 <btlib-form-row [controls]="[ edit.time.controls.startTimeAsString, edit.time.controls.endTimeAsString  ]" [form]="edit.time.form"  [grid]="gridMd" (change)="onChange($event)"  ></btlib-form-row>  
                 <btlib-form-row [controls]="[ edit.time.controls.weekdaysMap ]" [form]="edit.time.form"  [grid]="gridXl" (change)="onChange($event)"  ></btlib-form-row>  
                 <btlib-form-row [controls]="[ edit.time.controls.cutoff, edit.time.controls.enableWhenFull ]" [form]="edit.time.form"  [grid]="gridMd"  (change)="onChange($event)" ></btlib-form-row>  
                

                </form>
    

              </ng-template>
     
            </nz-modal>
           -->

</btlib-admin-page-wrapper>

