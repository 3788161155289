import { Component, HostListener, Input, OnInit } from '@angular/core';
import { BtEvent } from 'src/app/lib/core/services/event.service';
import { MiscUtil } from 'src/app/lib/core/util/misc.util';

import { AppService, APP_DATAID } from '../../../../../services/app.service';
import { DATAID } from '../../../services/data.service';

@Component({
  selector: 'btlib-order-cart',
  templateUrl: './order-cart.component.html',
  styleUrls: ['./order-cart.component.scss']
})
export class OrderCartComponent implements OnInit {

  @Input() cart:any;

  paymentUrl:string = "";
  // paymentSuccess = false;

  user:any = {
    u_username: "",
    u_name: "",
    u_telephone: ""
  }

  step = 0;
  
  constructor(public apps:AppService) {

    this.cart = apps.bas.ds.getValue('ORDER_CART');

    this.apps.bas.es.on(BtEvent.MESSAGE, (ev:any) => this.onMessage(ev));
  }

  ngOnInit(): void {

  }

  goBack() {
    this.apps.bas.es.trigger(BtEvent.GO_BACK);
  }
  
  // @HostListener('window:message', ['$event'])
  onMessage(btenv:any) {
    // let ev = this.apps.bas.es.onMessage(event);
    if(this.apps.bas.envtest) console.log("onMessage: ", btenv);
    if (btenv.action == "cbPaymentComplete") {
      this.paymentUrl = "";
      //TODO: vise bekreftelse
      if(this.apps.bas.envtest) console.log("ID: " + btenv.params.id);
      // this.paymentSuccess = true;
      this.step = 3;
    }
  }

  placeOrder() {
    if(this.apps.bas.envtest) console.log("placeOrder, cart: ", this.cart);

    // this.step = 2;
    // this.paymentUrl = "https://test.cloud-booking.net/langedrag/vp-cbapi5/test.htm?type=cbapi5";
    // return; 

    let params = MiscUtil.extend({ }, this.user);

    this.apps.bas.os.placeOrder(this.cart, params).then((res) => {
      if(this.apps.bas.envtest) console.log("placeOrder success, res: ", res);

      if (res.success) {
        this.step = 2;
        this.cart = { };
        this.apps.bas.ds.save(APP_DATAID.ORDER_CART, this.cart, true);
        this.paymentUrl = this.apps.bas.ws.getBaseUrl() +  "/vp-cbapi5/tools.htm?action=payment&id=" + res.order.id; //TODO: &lang=
      }

      // this.checkout = false;

    }).catch(err => {
      if(this.apps.bas.envtest) console.log("placeOrder failed, err: ", err);
    })

  }


}
