
<nz-modal 
  [(nzVisible)]="!!edit.obj" 
  [nzTitle]="'web.common.user.changeUsername' | actrans | str" 

  (nzOnCancel)="onCancel()" 
  (nzOnOk)="onOk()"
  >
  <ng-container *nzModalContent>
    
    <div *ngIf="edit.obj">

      <form nz-form nzLayout="vertical" [nzAutoTips]="apps.bas.fs.autoTips">
        
        <btlib-form-row  [controls]="[ edit.controls.username ]" [form]="edit.form"  [grid]="apps.bas.ui.gridFull" (change)="onChange($event)" ></btlib-form-row> 
        <btlib-form-row  [controls]="[ edit.controls.usernameConfirm ]" [form]="edit.form"  [grid]="apps.bas.ui.gridFull" (change)="onChange($event)" ></btlib-form-row> 
    
      </form>
    </div>

  </ng-container>

</nz-modal>

