<bt-page-wrapper>
<nz-spin [nzSpinning]="processing">
  <div class="p-d">
    <div class="form-wrapper" *ngIf="edit.form ">
  
      <div *ngIf="!response.success">

        <form 
          nz-form 
          [formGroup]="edit.form" 
          (ngSubmit)="formSubmit()"  
          class="item-form mt-d" 
          nzLayout="vertical" 
          [nzAutoTips]="apps.bas.fs.autoTips" >
          
          <h1>Rom til leie</h1>
          <p>Fyll ut skjemaet for å registere at du har rom til leie til flyktninger.</p>
          
          
          <h2>Informasjon om boligen</h2>
          <btlib-form-row [controls]="[ 
          edit.controls.product.childMap.address1,

          ]" [form]="edit.form.controls.product"  [grid]="apps.bas.ui.gridMd"  [options]="edit.controlOptions" ></btlib-form-row> 
        
          <btlib-form-row [controls]="[ 
    
          edit.controls.product.childMap.zipCode,
          edit.controls.product.childMap.city,
          
          ]" [form]="edit.form.controls.product"  [grid]="apps.bas.ui.gridMd"  [options]="edit.controlOptions" ></btlib-form-row> 
        

          <h2>Kontaktinformasjon</h2>
          <btlib-form-row [controls]="[ 
            edit.controls.user.childMap.name ,
            edit.controls.user.childMap.telephone,
            edit.controls.user.childMap.username,
          
            ]" [form]="edit.form.controls.user"  [grid]="apps.bas.ui.gridMd"  [options]="edit.controlOptions" ></btlib-form-row> 
        
          <btlib-form-row [controls]="[ 
            edit.controls.user.childMap.acceptEula
          
            ]" [form]="edit.form.controls.user"  [grid]="apps.bas.ui.gridMd"  [options]="edit.controlOptions" ></btlib-form-row> 
            
            
          <btlib-form-row [controls]="[ 
          edit.controls.product.childMap.desc
          
          ]" [form]="edit.form.controls.product"  [grid]="apps.bas.ui.gridLg"  [options]="edit.controlOptions" ></btlib-form-row> 
        
          


            <div nz-row>
              <div nz-col [nzXs]="12">
                <button nz-button nzType="default" (click)="$event.preventDefault(); edit.showEula = true; apps.bas.es.sendParentMessageScroll();">Vis avtalevilkår</button> 
        
              </div>
              <div nz-col [nzXs]="12" class="text-right">
                <button nz-button nzType="primary" [nzLoading]="processing">Send inn skjema</button>
    
              </div>
            </div>

        
        </form>
        
      </div>
      <div *ngIf="response.success">
        
        <h1>Takk for at du bruker denne tjenesten!</h1>
        <p>Vi vil ta kontakt med deg så snart vi kan.</p>
      </div>
    </div>
  </div>
  
</nz-spin>
</bt-page-wrapper>

<nz-modal 
  [(nzVisible)]="edit.showEula" 
  [nzTitle]="'Avtalevilkår' | actrans | str" 

  (nzOnOk)="edit.showEula = false; "
  (nzOnCancel)="edit.showEula = false; "
  (nzAfterClose)="apps.bas.es.sendParentMessageScroll();"
  nzWidth="80vw" 
  >
  <ng-container *nzModalContent>
    
    <p [innerHtml]="'web.eula.eula' | actrans">

    </p>

  </ng-container>
</nz-modal>
