import { Component, Input, OnInit, Injector, EventEmitter, Output } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { MiscUtil } from 'src/app/lib/core/util/misc.util';
import { DynformControl } from 'src/app/lib/core/model/dymform-control';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'btlib-form-row',
  templateUrl: './bt-form-row.component.html',
  styleUrls: ['./bt-form-row.component.scss']
})
export class BtFormRowComponent implements OnInit {

  @Input() form!: UntypedFormGroup;
  // @Input() controls: DynformControl[] = [];
  @Input() options:any = { };

  allControls: DynformControl[] = [];
  // visibleControls: DynformControl[] = [];
  @Input()
  get controls(): DynformControl[] {return this.allControls;}
  set controls(value: DynformControl[]) {
    this.allControls = value;
    // console.log(value);
    // this.visibleControls = value.filter(dc => {
    //   if (dc == undefined) {
    //     console.log("dc == undefined");
    //   }
    //   return dc != undefined && dc.show(dc) 
    // });
  }
  
  @Input() grid: any = { };
  g = {
    xs: null,
    sm: null,
    md: null,
    lg: null,
    xl: null,
    xxl: null,
    gutter: 16
  };

  @Output() change = new EventEmitter<any>();
  
  constructor(public injector:Injector, public apps:AppService) {

  }

  ngOnInit(): void {
    MiscUtil.extend(this.g, this.grid);

  }

  onChange(event:any) {
    this.change.emit(event);
    
  }

}
