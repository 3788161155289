import { Component, Input, OnInit } from '@angular/core';

import { AppService } from '../../../../../services/app.service';
import { DATAID } from '../../../services/data.service';

@Component({
  selector: 'btlib-login-check',
  templateUrl: './login-check.component.html',
  styleUrls: ['./login-check.component.scss']
})
export class LoginCheckComponent implements OnInit {


  @Input() rolesRequired:string[] = [];

  showLogin = true;
  constructor(public apps:AppService) {

  }

  ngOnInit(): void {
  }

}
