<btlib-admin-page-wrapper>

  <div class="m-d">
    <h1>{{ 'common.tasks' | actrans }}</h1>
    <div class="text-right">
      <button nz-button nzType="primary"  (click)="newObject()">
        <i nz-icon nzType="plus-circle"></i>
        {{ 'common.add' | actrans }}
      </button>
  
    </div>


    <div *ngIf="df.obj && false">
      <nz-collapse>
        <nz-collapse-panel  [nzHeader]="'Filter'" [nzActive]="apps.bas.ui.r.gtemd"  >
          
          <form nz-form [formGroup]="df.form" class="item-form mt-d" nzLayout="vertical" [nzAutoTips]="apps.bas.fs.autoTips" (ngSubmit)="dfSubmit()"  >
  
            <btlib-form-row [controls]="[ 
            df.controls.taskType ,
            df.controls.taskStatus,
            df.controls.search
            
            ]" [form]="df.form"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row>
           
            <button nz-button nzType="default"> {{ 'common.show' | actrans }}  </button>
      
          </form>

        </nz-collapse-panel>
      </nz-collapse>


     
    </div>
   
  </div>

  <nz-table 

    #nzTableTasks
    [nzData]="fbo.stats.current || []" 
    nzTableLayout="fixed" 

    nzSize="small"
    class="nz-table-xs"

    [nzFrontPagination]="false"
    [nzLoading]="fbo.loading"

    [nzTotal]="fbo.stats.navigatorObj.size"
    [nzPageSize]="fbo.stats.navigatorObj.pageSize"
    [nzPageIndex]="fbo.stats.navigatorObj.page+1"
    (nzQueryParams)="onQueryParamsChange($event)"
    >
    <thead>
      <tr>
        <th nzWidth="40px"></th>
        <th nzWidth="40px"></th>
        <th>{{ 'common.time' | actrans }}</th>
        <th>{{ 'common.start' | actrans }}</th>
        <th>{{ 'common.type' | actrans }}</th>
        <th>{{ 'common.status' | actrans }}</th>
        <th>{{ 'common.title' | actrans }}</th>
        <th>{{ 'common.deadline' | actrans }}</th>
        <th>{{ 'common.product' | actrans }}</th>
        <th nzWidth="40px"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of nzTableTasks.data">


        <td nzAlign="center">
          <button  nzSize="small" class="" [title]="'common.copy' | actrans" 
            (click)="$event.preventDefault()" 
            nz-popconfirm
            [nzPopconfirmTitle]="'app.lib.common.popconfirm.confirm' | actrans | str"
            nz-button nzType="default"
            (nzOnConfirm)="copyObject(data)"
          >
            <i nz-icon [nzType]="'copy'"></i>
          </button>
        </td>
        <td nzAlign="center"> 
          <i *ngIf="!data.enabled" nz-icon 
            [nzType]="data.enabled ? 'check-circle' : 'close-circle'"
            [nzTheme]="'twotone'" 
            [nzTwotoneColor]="data.enabled ? '#52c41a' : '#eb2f2f'"   
          ></i>
          <i nz-icon 
          [nzType]="data.isFinished ? 'check-circle' : ( data.isFailed ? 'close-circle' : 'question-circle' )"
          [nzTheme]="'twotone'" 
          [nzTwotoneColor]="data.isFinished ? '#52c41a' : ( data.isFailed ? '#eb2f2f' : '#f29e3e' )" 
          
          ></i>

        </td>
        <td>{{ data.time }}</td>
        <td>{{ data.start }}</td>
        <td>{{ data.typeLabel }}</td>
        <td>{{ data.statusLabel }}</td>
        <td nzEllipsis>{{ data.title }}</td>
        <td>{{ data.deadline }}</td>
        <td>{{ data.productObj?.mkName }}</td>

        <td nzAlign="center">
          <button nz-button nzType="default" nzSize="small" [title]="'common.edit' | actrans"  (click)="editObject(data)">
            <i nz-icon [nzType]="'edit'"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </nz-table>

  <nz-modal 
    [(nzVisible)]="edit.obj" 
    [nzTitle]="'common.edit' | actrans | str" 
    nzWidth="90vw" 
    (nzOnCancel)="onEditCancel()" 
    (nzOnOk)="onEditOk();" 
    [nzOkText]="'common.save' | actrans | str"
    [nzOkLoading]="edit.loading"
    >
    <ng-container *nzModalContent>
      

      <form nz-form [formGroup]="edit.form" class="item-form mt-d" nzLayout="vertical" [nzAutoTips]="apps.bas.fs.autoTips" (ngSubmit)="onEditOk()"  >
  
        <btlib-form-row [controls]="[ edit.controls.enabled  ]" [form]="edit.form"  [grid]="apps.bas.ui.gridSm"  ></btlib-form-row> 
        <btlib-form-row [controls]="[ edit.controls.type, edit.controls.status, edit.controls.title ]" [form]="edit.form"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row>
        <btlib-form-row [controls]="[ edit.controls.body ]" [form]="edit.form"  [grid]="apps.bas.ui.gridXl"  ></btlib-form-row> 
        
        <btlib-form-row [controls]="[ edit.controls.product, edit.controls.booking, edit.controls.bi ]" [form]="edit.form"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row> 
        
      </form>
      

      <div>
        <nz-table 
          #nzTableManual
          [nzData]="edit.obj?.items || []" 
          nzTableLayout="fixed" 
          [nzShowPagination]="false" 
          [nzPageSize]="100"
          nzSize="small"
          class="nz-table-xs"
          >
          <thead>
            <tr>
              <th nzWidth="30px"></th>
              <th nzWidth="30px"></th>
              <th nzWidth="30px"></th>
              <th nzWidth="30px" class="text-center">#</th>
              <th>{{ 'common.title' | actrans }}</th>
              <th>{{ 'bus.productInput.type' | actrans }}</th>
           
              <th>{{ 'common.value' | actrans }}</th>
              <th *ngIf="apps.bas.ui.r.gtexl">{{ 'common.comment' | actrans }}</th>

            </tr>
          </thead>
          <tbody cdkDropList (cdkDropListDropped)="cdkDropListDroppedItem($event)">
            <ng-container  *ngFor="let data of nzTableManual.data; let idx = index">
              <tr cdkDrag >
                <td>
                  <i nz-icon class="cursor-pointer" nzType="drag" cdkDragHandle  ></i>
                </td>
                <td>
                  <i nz-icon class="cursor-pointer" [nzType]="edit.item.obj?.id == data.id ? 'minus-square' : 'plus-square'" (click)="editItem(data)" ></i>
                </td>
                <td nzAlign="center"> 
                  <i nz-icon 
                    [nzType]="data.isFinished ? 'check-circle' : ( data.isFailed ? 'close-circle' : 'question-circle' )"
                    [nzTheme]="'twotone'" 
                    [nzTwotoneColor]="data.isFinished ? '#52c41a' : ( data.isFailed ? '#eb2f2f' : '#f29e3e' )" 
                    
                    ></i>
                </td>
                <td class="text-center">{{ data.sortOrder }}</td>
                <td>{{ data.title }}</td>
                <td>{{ data.inputTypeObj.mkName }}</td>
                
             
                <td>{{ data.completedValue }}</td>
                <td *ngIf="apps.bas.ui.r.gtexl">{{ data.completedComment }}</td>

              </tr>
              <tr  *ngIf="edit.item.obj?.id == data.id" class="tr-extended"> 
               
                <td [attr.colspan]="(
                                           7 
                  + (apps.bas.ui.r.ltesm ? 0 : 0)  
                  + (apps.bas.ui.r.gtesm ? 0 : 0) 
                  + (apps.bas.ui.r.gtemd ? 0 : 0) 
                  + (apps.bas.ui.r.gtelg ? 0 : 0) 
                  + (apps.bas.ui.r.gtexl ? 1 : 0)
                  + (apps.bas.ui.r.gtexxl ? 0 : 0)
                  )">
                <div *ngIf="edit.item.obj" class="tr-extended-div">
               
                  <form nz-form [formGroup]="edit.item.form" class="item-form mt-d" nzLayout="vertical" [nzAutoTips]="apps.bas.fs.autoTips">

                    <btlib-form-row [controls]="[ edit.item.controls.required ]" [form]="edit.item.form"  [grid]="apps.bas.ui.gridMd" (change)="onChange($event)"  ></btlib-form-row>  
                    <btlib-form-row [controls]="[ edit.item.controls.title, edit.item.controls.inputType, edit.item.controls.status ]" [form]="edit.item.form"  [grid]="apps.bas.ui.gridMd" (change)="onChange($event)"  ></btlib-form-row>  
                    <btlib-form-row [controls]="[ edit.item.controls.optionsListAsString  ]" [form]="edit.item.form"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row>
        

                    <div nz-row [nzGutter]="0" nzAlign="middle">
                      <div nz-col [nzXs]="12">
                        <button 
                          nz-popconfirm
                          [nzPopconfirmTitle]="'app.lib.common.popconfirm.delete' | actrans | str"
                          nz-button nzType="default" nzDanger nzSize="small" class="" (click)="$event.preventDefault()" (nzOnConfirm)="deleteItem(edit.item.obj, idx)">
                          {{ 'common.delete' | actrans }}
                        </button>
                      </div>
                      <div nz-col [nzXs]="12">
                        <div class="text-right">
                          <button nz-button nzType="default" nzSize="small" (click)="saveItem(edit.item.obj)">
                            {{ 'app.lib.common.save' | actrans }}
                          </button>
                        </div>
                  
                      </div>
                    </div>

                    

                  </form>
                </div>
                </td>

              </tr>
            </ng-container>
          </tbody>
        </nz-table>

        <div *ngIf="!edit.item.obj" class="text-right">
          <button nz-button nzType="default" nzSize="small" (click)="newItem()">
            {{ 'common.add' | actrans }}
          </button>
        </div>

      </div>

    </ng-container>
  </nz-modal>





</btlib-admin-page-wrapper>
