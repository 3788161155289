import {AfterViewInit, Directive, ElementRef, OnDestroy} from '@angular/core';
import {IFrameComponent, IFrameMessageData, IFrameResizedData, iframeResizer} from 'iframe-resizer';
import { AppService } from 'src/app/services/app.service';

@Directive({
  selector: '[appIFrameResizer]'
})
export class IFrameResizerDirective implements AfterViewInit, OnDestroy {
  component: IFrameComponent | null = null;

  constructor(public element: ElementRef, public apps:AppService) {
  }

  ngAfterViewInit() {
    // if(this.apps.bas.envtest) console.log("IFrameResizerDirective.ngAfterViewInit");
      const components = iframeResizer({
          checkOrigin: false,
          //heightCalculationMethod: 'documentElementOffset',
          log: false, //this.apps.bas.envtest,
          // resizeFrom:'child',
          // heightCalculationMethod:'max',
          // widthCalculationMethod:'documentElementScroll',
          // sizeWidth:true,
          // sizeHeight:true,
          messageCallback: (data:IFrameMessageData) => {

          },
          resizedCallback: (data: IFrameResizedData) => {
            //console.log("resizedCallback.data: ", data);
            this.apps.bas.es.sendParentMessage({ action: "resize" });

            // let win:any = window;
            // console.log("resizedCallback.parent: ", win.parentIFrame);
            // win.parentIFrame.sendMessage({ action: "resize" });
            
          }

      }, this.element.nativeElement);

      /* save component reference so we can close it later */
      this.component = components && components.length > 0 ? components[0] : null;
  }

  ngOnDestroy(): void {
      if (this.component && this.component.iFrameResizer) {
          this.component.iFrameResizer.close();
      }
  }
}

