import { AfterViewInit, Component, HostListener, Injector, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DynformControl, DynformControlsMap } from 'src/app/lib/core/model/dymform-control';
import { DATAID } from 'src/app/lib/core/services/data.service';
import { MiscUtil } from 'src/app/lib/core/util/misc.util';
import { upperCase } from 'src/app/lib/core/util/const';

import { AppService } from 'src/app/services/app.service';

import { NzUploadChangeParam, NzUploadFile, NzUploadXHRArgs } from 'ng-zorro-antd/upload';
import { Observable, Subscription } from 'rxjs';
import { BtGoogleMapsComponent } from 'src/app/lib/core/component/ui/bt-google-maps/bt-google-maps.component';
import { ComponentCanDeactivate } from 'src/app/lib/core/guards/pending-changes.guard';


@Component({
  selector: 'app-admin-order-order-page',
  templateUrl: './admin-order-order-page.component.html',
  styleUrls: ['./admin-order-order-page.component.scss']
})
export class AdminOrderOrderPageComponent implements OnInit, AfterViewInit, ComponentCanDeactivate {

  router: Router;
  route: ActivatedRoute;

  fbo:any;
  c:any;

  edit:any = {
    form: undefined,
    obj: undefined,
    changes: { },

    item:  { controls: {},  obj: null, form: null, id: -1, idx: -1, newItemField: "newItem" },
    payment:  { controls: {},  obj: null, form: null, id: -1, idx: -1, newItemField: "newPayment" },
    log:   { controls: {},  obj: null, form: null, id: -1, idx: -1, newItemField: "newLog" },

    giftCard:   { controls: {},  obj: null, form: null, id: -1, idx: -1, newItemField: "newPayment" },
    transferPayment:   { controls: {},  obj: null, form: null, id: -1, idx: -1, newItemField: "newPayment" },
    userSearch:   { controls: {},  obj: null, form: null, id: -1, idx: -1, newItemField: "" },
    

    user: { obj: null }


  }

  smsInput:any = { };
  emailInput:any = { };
  sendPushInput:any = { };


  isSpinning = true;
  dirty = false;
  lang:string = "no";

  Sections = {
    "items"     : { id: "items",    title: "bus.booking.items", icon: "table"},
    "general"   : { id: "general",  title: "common.general",    icon: "setting"},
    "customer"   : { id: "customer",  title: "common.customer",    icon: "user"},
    "invoice"   : { id: "invoice",  title: "bus.invoice",       icon: "dollar"}, 
    "economy"   : { id: "economy",  title: "common.economy",    icon: "bank"},
    "log"       : { id: "log",      title: "common.log",        icon: "file" },
    
  }

  helpVisible = false;

  
  selected:any[] = []

  constructor(public injector:Injector, public apps:AppService) {
    this.router = injector.get(Router);
    this.route = injector.get(ActivatedRoute);
    this.apps.bas.us.checkActivatedRoute(this.route.snapshot);

    if (this.apps.bas.envtest) console.log("AdminOrderOrderPageComponent, login.success: "+this.apps.bas.ds.login.success+", route: ", this.route.snapshot);

    if (this.apps.bas.ds.login.success) {
      this.c = this.apps.bas.ds.config.company;
      // this.getOrder(this.route.snapshot.queryParams.id);
      this.route.params.subscribe(routeParams => {
        // console.log("route.params.subscribe: ", routeParams);
        this.getOrder(routeParams.id);

      });
    } 
    else {

      //TODO: denne blir kalt flere ganger. 
      this.apps.bas.ds.get(DATAID.APP_LOGIN).subscribe((res) => {
        if (this.apps.bas.envtest) console.log("AdminOrderOrderPageComponent.LOGIN.updated, res: ", res)
        // window.location.reload();
        if (res.success) {
          this.getOrder(this.route.snapshot.queryParams.id);
      
        }
     
      });
    }

   }

  ngOnInit(): void {
    if (this.apps.bas.envtest) console.log("AdminOrderOrderPageComponent.ngOnInit");
  }

  ngAfterViewInit(): void {
    if (this.apps.bas.envtest) console.log("AdminOrderOrderPageComponent.ngAfterViewInit");
  }

  //

  
  onSelected(event:any, item:any) {
    if (this.apps.bas.envtest) console.log("onSelected, item: ", item,  ", event: ", event);
    item.selected = event;
    this.selected = this.edit.obj.items?.filter((order:any) => order.selected);
  }

  //


  async json(action:string, params:any = undefined, args:any = { }):Promise<any> {
    if (this.apps.bas.envtest) console.log("AdminOrderOrderPageComponent.json, action: " + action + ", params: ", params);
    params = params || { id: this.edit.obj.id  };
    args = args || { };
    return this.apps.bas.ws.json(MiscUtil.extend({ 
      aType: "booking", 
      action: action
    }, params)).then((json) => {

      if (this.apps.bas.envtest) console.log(action + ", json: ", json);
      if (json.success) {
        if (!args.skipSucessMessage) {
          this.apps.bas.ui.success(this.apps.bas.ui.actrans("common.success"));
      
        }
      }

      return json;
    });
  }

  addRow() {
    if (this.apps.bas.envtest) console.log("AdminOrderOrderPageComponent.addRow");

    //TODO: legg til ny rad og åpne
  }

  async setDefinitive() {
    let json = await this.json("setDefinitive");
    if (json.success) {
      // TODO
    }
  }

  async cancelAll() {
    let json = await this.json("cancelBooking");
    if (json.success) {
      // TODO

    }
    
  }

  async cloneOrder() {
    let json = await this.json("cloneOrder");
    if (json.success) {
      // TODO
      // $cb.obj.b = data.booking;
			// $cb.updateBooking();
    }
  }

  async creditSelectedRows(toThis:boolean) {
    if (this.apps.bas.envtest) console.log("AdminOrderOrderPageComponent.creditSelectedRows, toThis: " + toThis);

    if (this.selected.length == 0) {
      // TODO
      return;
    }
    let biids = this.selected.map((item) => item.id).join(",");
  
    let json = await this.json("creditBookingRows", { 
      id: this.edit.obj.id,
      biids,
      toThis
    });
    if (json.success) {

    }
  }

  async cloneSelectedRows(toThis:boolean) {
    if (this.apps.bas.envtest) console.log("AdminOrderOrderPageComponent.creditSelectedRows, toThis: " + toThis);
    // { actionType: "booking", action: "copyBookingRows", id: $cb.obj.b.id, biids: biids, toThis: toThis}
    
    if (this.selected.length == 0) {
      // TODO
      return;
    }
    let biids = this.selected.map((item) => item.id).join(",");
  
    let json = await this.json("copyBookingRows", { 
      id: this.edit.obj.id,
      biids,
      toThis
    });
    if (json.success) {

    }
  }

  showAvailableProducts() {
    if (this.apps.bas.envtest) console.log("AdminOrderOrderPageComponent.showAvailableProducts");
    /*
TODO: 
$cb.json({ 
				actionType: "booking", 
				action: "getAvailableProducts", 
				biid: $cb.obj.bi.id, 
				start: $("#bi_startDateAsString").val() + " " + $("#bi_startTimeAsString").val(), 
				end: $("#bi_endDateAsString").val() + " " + $("#bi_endTimeAsString").val(), 
				product: $("#bi_productId").val(), 
				quantity: $cb.parseNumber($("#bi_quantity").val()), 
				guests: guests
		}, function(data, params) {
			var html = '';
			if (data.products) {
				html += '<div style="max-height: 400px; overflow: auto;">';
				$.each(data.products, function(idx, p) {
					html += '<a href="#" class="selectProduct" data-pid="'+p.id+'">'+p.mkName+' ('+p.name+')</a><br/>';
				});
				html += '</div>';
			} else {
				html = '<nit:message key="web.common.booking.noProductsAvailable" eqm="true" />';
			}
			
			$cb.obj.availableProductsDialog = $cb.showModal({ id: "availableProductsDialog", content: html, title: "<nit:message key="common.info" ct="false" />" });

		});

    */

  }


  async sendProviderMail() {
     this.json("sendProviderMail");
  }

  //TODO: bør sjekke om produktene på denne bookingene har "info før ankomst" å sende før vi viser knappen
  sendArrivalInfo() {
    this.json("sendArrivalInfo");
  }

  async hotelCheckIn(checkIn:boolean) {
    let json = await this.json(checkIn ? "checkIn" : "checkOut");
    if (json.success) {
      this.edit.controls.mkIsCheckedIn.value = json.booking.mkIsCheckedIn;
      this.edit.obj.isCheckedIn =  json.booking.isCheckedIn;
    }
  }

  userSearch() {
    if (this.apps.bas.envtest) console.log("AdminOrderOrderPageComponent.userSearch");
    
    // TODO: Åpne søk-bruker dialog, burde åpne felles edit-user-kode
    //this.edit.user.obj = { id: "new" };

    this.showUserSearch();
  }

  newUser() {
    if (this.apps.bas.envtest) console.log("AdminOrderOrderPageComponent.newUser");
    
    this.edit.user.obj = { id: "new" };
  }

  editUser(userId?:any) {
    
    let uobj =  { id: userId || this.edit.obj.userId };
    
    if (this.apps.bas.envtest) console.log("AdminOrderOrderPageComponent.editUser, uobj: ", uobj);
    // TODO: Åpne bruker dialog

    this.edit.user.obj = uobj;
  }

  selectUser(user:any) {
    if (this.apps.bas.envtest) console.log("AdminOrderOrderPageComponent.selectUser, user: ", user);

    // TODO

    this.edit.userSearch.obj = null;
  }

  onUserChange(event:any) {
    if (this.apps.bas.envtest) console.log("AdminOrderOrderPageComponent.onUserChange, event: ", event);
    
    this.edit.user.obj = null;
  }

  orderSendSms() {
    this.smsInput = {
      orders: [ this.edit.obj ]
    };
  }

  orderSendEmail() {
    this.emailInput = {
      orders: [ this.edit.obj ]
    };
  }

  orderSendPushMessage() {
    if (this.apps.bas.envtest) console.log("AdminOrderOrderPageComponent.orderSendPushMessage");
    this.sendPushInput = {
      orders: [ this.edit.obj ]
    };
  }

  async doNotGenerateInvoice() {

    let json = await this.json("doNotGenerateInvoice");
    if (json.success) {
      this.edit.controls.mkGenerateInvoice.value = json.booking.mkGenerateInvoice;
      this.edit.obj.generateInvoiceBool = json.booking.generateInvoiceBool;
    }
  }

  async generateInvoice() {

    // action: "generateInvoice", id: $cb.obj.b.id, generateInitialInvoice: generateInitialInvoice, generateInvoiceForce: generateInvoiceForce
    this.json("generateInvoice");
  }

  async sendInvoice() {
   this.json("sendInvoice");
    
  }

  async sendEhfInvoice() {
   this.json("sendEhfInvoice");
  }

  async sendPaymentConfirmation() {
    this.json("sendPaymentConfirmation");
  }

  async sendOrderConfirmation() {
    this.json("sendOrderConfirmation");
  }

  
  async economyRefresh() {
    
    let json = await this.json("getEconomyData", undefined, { skipSucessMessage: true });
    if (json.success) {
      // TODO
      
    }
  }

  addPayment() {
    if (this.apps.bas.envtest) console.log("AdminOrderOrderPageComponent.addPayment");
   
    // Åpne payment-dialog
  }

  addPaymentManual() {
    if (this.apps.bas.envtest) console.log("AdminOrderOrderPageComponent.addPaymentManual");
    
    // Åpne payment-dialog
  }

  refundUser() {
    if (this.apps.bas.envtest) console.log("AdminOrderOrderPageComponent.refundUser");
    
    // Åpne payment-dialog
  }

  useGiftCard() {
    if (this.apps.bas.envtest) console.log("AdminOrderOrderPageComponent.useGiftCard");
    
    // Åpne gift-card-dialog
  }

  transferPayment() {
    if (this.apps.bas.envtest) console.log("AdminOrderOrderPageComponent.transferPayment");
    
    // Åpne transfer-payment-dialog

  }



  // TODO: saveOrderItem, skal ikke legges i changes? 

  //
  
  async newItem(event:Event, item:string) {

    let params = this.edit[item].params || { };
    
    if (this.apps.bas.envtest) console.log("newItem: " + item + ", params: ", params);
    
    
    event.preventDefault();
    
    if (this.apps.bas.envtest) console.log("newItem: " + item + ", params: ", params);

    let obj = MiscUtil.clone( this.edit.obj[this.edit[item].newItemField] );

    if (obj.id == "") obj.id = this.edit[item].id--;
    if (this.apps.bas.envtest) console.log("newItem.then: ", obj);


    this.edit.obj[item + "s"] = this.edit.obj[item + "s"] || [];

    if (item == "log") this.edit.obj[item + "s"] = [obj, ...this.edit.obj[item + "s"] ];
    else this.edit.obj[item + "s"] = [...this.edit.obj[item + "s"], obj ];


    this.editItem(obj, item);

  }

  editItem(objOrId:number|any, item:string) {


    let isNum =  typeof objOrId !== "object";
    let obj = isNum ? this.edit.obj[item + "s"][objOrId] : objOrId;
  
    if (isNum) obj.idx = objOrId;

    let controls = this.generateControls(item, obj);
 
    this.apps.bas.fs.updateFormObj(this.edit[item], controls, obj);
  }

  saveItem(item:string, event:any = undefined) {


    let formValue = this.apps.bas.fs.getRawValue(this.edit[item].form, this.edit[item].controls);
    if (formValue === false) return false;
    if (this.apps.bas.envtest) console.log("saveItem, item: "+item+": ", formValue);

    let arr:any[] = this.edit.changes[ item + "s"] = this.edit.changes[ item + "s"] || [];

    let currentIdx = arr.findIndex((elem:any) => elem.id === formValue.id);

    if (currentIdx < 0) arr.push(formValue);
    else arr[currentIdx] = formValue;

    this.dirty = true;

    let obj =  this.edit[item].obj;
    this.edit[item].obj = null;

    let objClone = MiscUtil.clone(obj);

    MiscUtil.extend(obj, formValue);

    if (item == "item") {
    //  obj.mkType = this.fbo.contactTypes[formValue.type].mkName;

    } else  if (item == "log") {
      obj.time = objClone.time;
      obj.textOutput = obj.text;

      
    }


    if ( event) { 
      
      event.preventDefault();
    } 

    return true;
  }

    
  deleteItem(idx:number, item:string) {
   

    let toDelete = this.edit.obj[item + "s"][idx];
    this.edit.obj[item + "s"] = this.edit.obj[item + "s"].filter((id:number, i:number) => i != idx);
    if (toDelete.id < 0) {
      
    } else {
      this.edit.changes[item + "Delete"] =  this.edit.changes[item + "Delete"] || [];
      this.edit.changes[item + "Delete"].push(toDelete.id);
    }
    
    this.dirty = true;

    if (this.apps.bas.envtest) console.log("deleteItem, changes: ", this.edit.changes)
  }

  //

  
  showGiftCard(obj:any) {
    let controls: DynformControl[] = [];

    controls.push(new DynformControl({ key: 'id' }));
    controls.push(new DynformControl({ key: 'time',          mk: 'common.time',     controlType: 'label', }));
    controls.push(new DynformControl({ key: 'text',     mk: 'bus.log.text', controlType: "richtext" }));

    
    this.apps.bas.fs.updateFormObj(this.edit.giftCard, controls, obj);
  }

  saveGiftCard() {

    
  }

  showPayment(obj:any) {
    let controls: DynformControl[] = [];

    controls.push(new DynformControl({ key: 'id' }));
    controls.push(new DynformControl({ key: 'time',          mk: 'common.time',     controlType: 'label', }));
    controls.push(new DynformControl({ key: 'text',     mk: 'bus.log.text', controlType: "richtext" }));

    this.apps.bas.fs.updateFormObj(this.edit.payment, controls, obj);
  }
  
  savePayment() {

  }

  showTransferPayment(obj:any) {
    let controls: DynformControl[] = [];

    controls.push(new DynformControl({ key: 'id' }));
    controls.push(new DynformControl({ key: 'time',          mk: 'common.time',     controlType: 'label', }));
    controls.push(new DynformControl({ key: 'text',     mk: 'bus.log.text', controlType: "richtext" }));

    this.apps.bas.fs.updateFormObj(this.edit.transferPayment, controls, obj);
  }
  
  saveTransferPayment() {

  }

  // 
  showUserSearch() {
    this.edit.userSearch.obj = { show: true };
  }

  async onUserSearch() {
    
    if (this.apps.bas.envtest) console.log("onUserSearch, changes: ", this.edit.userSearch.obj);

    let json = await this.json("getUsers", {  id: this.edit.obj.id, search: this.edit.userSearch.search }, { skipSucessMessage: true });
    this.edit.userSearch.users = json.users || [];

  }


  //

  generateControls(type:string, obj:any) {
    if (type == "item") return this.generateControlsItem(obj);
    else if (type == "log") return this.generateControlsLog(obj);

    return [];
  }

  generateControlsItem(obj:any) {
    let controls: DynformControl[] = [];

    controls.push(new DynformControl({ key: 'id',     mk: 'common.id',  controlType: 'label' }));
    controls.push(new DynformControl({ key: 'updated',     mk: 'common.updated',     controlType: 'label', }));
   
    
    controls.push(new DynformControl({  
      key: 'product', 
      valuePath: 'productId',
      mk: 'common.product', 
      controlType: 'select', 
      required: true,
      options: () => {
        return this.fbo.products;
      }, 
      optionsAllowEmpty: true,
      optionsFieldValue: "id", 
      optionsFieldLabel: "mkName",
      // onChange: () => {
      //   this.edit.obj.userRole = this.apps.bas.us.listToMap(this.fbo.newUserRoles)[this.edit.form.controls.role.value];
      // }
    }));

    
    controls.push(new DynformControl({  
      key: 'parent', 
      valuePath: 'parentId',
      mk: 'bus.bookingItem.parent', 
      controlType: 'select', 
      // required: true,
      options: () => { return this.edit.obj.items.filter((bi:any) => bi.id != obj.id); }, 
      optionsAllowEmpty: true,
      optionsFieldValue: "id", 
      optionsFieldLabel: "label"
    }));
    
    controls.push(new DynformControl({  
      key: 'status', 
      mk: 'common.status', 
      controlType: 'select', 
      required: true,
       options: () => { return this.fbo.statuss;}, 
      optionsAllowEmpty: true,
      optionsFieldValue: "id", 
      optionsFieldLabel: "mkName",
    }));

    controls.push(new DynformControl({ key: 'periodAsRange',     mk: 'common.period',     controlType: 'datetime-range-picker', }));
    
    controls.push(new DynformControl({ key: 'quantity',     mk: 'common.quantity',     controlType: 'input-number', }));
    
    let gcChildren:DynformControl[] = []

    // 	<c:if test="${company.upCBooking}">
    for (let gc in obj.guestCategories) {

      gcChildren.push(new DynformControl({  
        key: '' + gc, 
        valuePath: 'gcs.' + gc, 
        mk:  obj.guestCategories[gc], //'bus.product.gc' + gc,
     
      }));
    } 
    controls.push(new DynformControl({ 
      key: 'gcs',
      controlType: 'formGroup',
      children: gcChildren
    }));
    // </c:if>
   
    controls.push(new DynformControl({  
      key: 'price', 
      valuePath: 'priceId',
      mk: 'common.price', 
      controlType: 'select', 
      // required: true,
      options: () => { return this.edit.item.prices || []; },  //TODO
      optionsAllowEmpty: true,
      optionsFieldValue: "id", 
      optionsFieldLabel: "name",
    }));

    controls.push(new DynformControl({ key: 'manAmountDouble',     mk: 'bus.bookingItem.manAmount',     controlType: 'input-number', }));
    controls.push(new DynformControl({ key: 'retailAmountDouble',     mk: 'bus.bookingItem.retailAmount',     controlType: 'input-number', })); // <c:if test="${isBn}">
    controls.push(new DynformControl({ key: 'providerAmountDouble',     mk: 'bus.bookingItem.providerAmount',     controlType: 'input-number', }));
    
    controls.push(new DynformControl({ key: 'invoiceText',     mk: 'common.invoiceText',     controlType: 'textarea', })); 
    controls.push(new DynformControl({ key: 'additionalInfo',     mk: 'common.additionalInfo',     controlType: 'input', }));  // TODO: deprecated
  
    // TODO: bi_input_values

    // TODO: Tags

    controls.push(new DynformControl({ key: 'completed',     mk: 'bus.bookingItem.completed',     controlType: 'label', })); 
    controls.push(new DynformControl({ key: 'campaignCode',     mk: 'bus.campaign.code',     controlType: 'label', }));  // only if specified
    controls.push(new DynformControl({ key: 'giftCardCode',     mk: 'bus.giftCard.code',     controlType: 'label', }));  // only if specified
    controls.push(new DynformControl({ key: 'settled',     mk: 'bus.bookingItem.settled',     controlType: 'label', })); 
    controls.push(new DynformControl({ key: 'payoutConfirmedCompany',     mk: 'common.payout',     controlType: 'label', })); 
    controls.push(new DynformControl({ key: 'payoutConfirmedProvider',     mk: 'common.payout',     controlType: 'label', })); 
  
    controls.push(new DynformControl({ key: 'credits',     mk: 'common.credits',     controlType: 'label', }));  // 	<c:if test="${isBn}">
    controls.push(new DynformControl({ key: 'eosId',     mk: 'bus.product.eosId',     controlType: 'label', }));  // 	<c:if test="${isBn}">
  
    controls.push(new DynformControl({ key: 'forceAdd',     mk: 'web.common.booking.forceAddBi',     controlType: 'checkbox', }));  // 	<c:if test="${isBn}">
  

    let inputValues:DynformControl[] = []

    for (let iv of obj.inputValuesList) {

      inputValues.push(new DynformControl({  
        key: '' + iv.key, 
        // valuePath: 'inputValues.' + iv.key, 
        mk: iv.label, //'bus.product.gc' + gc,
     
      }));
    } 
    controls.push(new DynformControl({ 
      key: 'inputValues',
      controlType: 'formGroup',
      children: inputValues
    }));
    
    /*
if (prod.inputs) {
        	
        	var gcIndexList = [];
        	
        	 for (var i = 0; i < 5; i++) {
                 var gcStr = $("#bi_gc" + i).val();
                 if (gcStr == "") continue;
                 var gc = parseInt(gcStr);
                 
                 for (var j = 0; j < gc ; j++) gcIndexList.push(i);
             }
        	 
        	 for (var idx  = -1; idx < gcIndexList.length; idx++) {
        		 $cb.debug("idx: " + idx);
        		 var gc = idx < 0 ? -1 : gcIndexList[idx];
        		 
        		 
        		 $.each(prod.inputs, function(piIdx, pi) {
      	            var key = pi.id + "_" + idx;
      	            var dkey = (idx >= 0 ? '#' + (idx+1) + ' ' : '') + pi.mkName;
      	            
      	            var iv = $cb.obj.bi.inputValues[key];
                    if (iv === undefined) iv = "";
                    
                    if (iv === "") {
                      
                      iv = $cb.obj.bi.inputValuesDisplay[dkey] || "";
                      
                    }
                  
      	            $cb.debug("key: " + key + ", dkey: " + dkey + ", iv: ", iv);
        			
      	            if (pi.guestInput && idx < 0) {
      	            	$cb.debug("key: " + key + ", pi.guestInput && idx < 0");
      	            	return true;
      	            }
                    if (!pi.guestInput && idx >= 0) {
                    	$cb.debug("key: " + key + ", !pi.guestInput && idx >= 0");
                    	return true;
                    }
                  
      	           
      	            
      	            var divFormGroup = $('<div class="form-group">');
      	            divFormGroup.append('<label class="col-sm-4 control-label" for="input-'+key+'">'
      	            	 + (pi.guestInput ? '#' + (idx+1) + ' ('+prod.mkGcs[gc]+'): ' : '') 
      	            	 + (pi.typeEnum.isCheckbox ? '' : pi.mkName + ':')
      	             + '</label>');
      	            
      	            var divFormControl = $('<div class="col-sm-8">');
      	            
      	            
      	            if (pi.typeEnum.isCheckbox) {
      	                
      	                divFormControl.append(''
      	                    + '<div class="checkbox">'  
      	                    + '<label class="" for="input-value-'+key+'">' 
      	                      + '<input id="input-value-'+key+'" name="input-value-'+key+'" type="checkbox" class="jpField '+(pi.required ? 'jpRequire' : '')+' bi-input-value" data-input-key="'+key+'" value="true" '+(iv === "true" ? 'checked="checked"' : '')+' />'  
        	                      + (pi.guestInput ? '#' + (idx+1) + ' ('+prod.mkGcs[gc]+'): ' : '') 
        	                      + ' ' +  pi.mkName
      	                      + '</label>'  
      	                    + '</div>' 
      	                + '');
      	                
//       	            } else if (pi.typeEnum.isTextInput) {
      	                
      	               
      	                
      	            } else if (pi.typeEnum.isTextArea) {
      	                
      	                divFormControl.append(''
      	                      + '<textarea id="input-value-'+key+'" name="input-value-'+key+'" type="text" class="form-control jpField '+(pi.required ? 'jpRequire' : '')+' bi-input-value" data-input-key="'+key+'">'+iv+'</textarea>' 
      	                  + '');
      	                
      	            } else if (pi.typeEnum.isSelect) {
      	                
      	                var select = $('<select id="input-value-'+key+'" name="input-value-'+key+'" class="form-control jpField '+(pi.required ? 'jpRequire' : '')+' bi-input-value" data-input-key="'+key+'">');
      	                select.append($cb.emptyOption);
      	                
      	                $.each(pi.optionsAsList, function(idx, value) {
      	                    select.append('<option value="'+value+'" '+(value == (iv || pi.mkDefaultValue) ? 'selected="selected"' : '')+'>'+value+'</option>');
      	                });
      
      	                
      	                divFormControl.append(select);
      	            } else { // isTextInput || isName || isTelephone
      	            	 divFormControl.append(''
         	                      + '<input id="input-value-'+key+'" name="input-value-'+key+'" type="text" class="form-control jpField '+(pi.required ? 'jpRequire' : '')+' bi-input-value" data-input-key="'+key+'" value="'+iv+'" />' 
         	                  + '');
          	        }
      	            
      	          divFormGroup.append(divFormControl);
                  ivs.append(divFormGroup);
        	            
      	         });
        	
        	}
        };
    */


    return controls;
  }


  generateControlsLog(obj:any) {
    let controls: DynformControl[] = [];

    controls.push(new DynformControl({ key: 'id' }));
    controls.push(new DynformControl({ key: 'time',          mk: 'common.time',     controlType: 'label', }));
    controls.push(new DynformControl({ key: 'text',     mk: 'bus.log.text', controlType: "richtext" }));

    return controls;
  }




  generateControlsOrder(order:any) {
    let controls: DynformControl[] = [];

    let langs = this.apps.bas.ds.config.appConfig?.enabledLanguages;
    let c = this.apps.bas.ds.config.company;
    // console.log("langs:")

    let isNew = !order.id;
    let isAdmin = this.apps.bas.aas.isAdmin();
  

    controls.push(new DynformControl({ key: 'id',     mk: 'common.id' }));
    controls.push(new DynformControl({ key: 'bid',     mk: 'common.bid',     controlType: 'label', }));
    controls.push(new DynformControl({ key: 'time',     mk: 'common.created',     controlType: 'label', }));
    controls.push(new DynformControl({ key: 'updated',     mk: 'common.updated',     controlType: 'label', }));
    controls.push(new DynformControl({ key: 'createdBy',     mk: 'bus.booking.createdBy',     controlType: 'label', }));
    controls.push(new DynformControl({ key: 'parent',     mk: 'bus.booking.parent',     controlType: 'label', }));
    controls.push(new DynformControl({ key: 'poEmail',     mk: 'bus.booking.poEmail',     controlType: 'label', }));
   
    controls.push(new DynformControl({ key: 'user',     mk: 'bus.booking.user',     controlType: 'label', }));
    
    controls.push(new DynformControl({ key: 'source',     mk: 'bus.booking.source',     controlType: 'label', }));
    controls.push(new DynformControl({ key: 'referer',     mk: 'bus.booking.referer',     controlType: 'label', }));
    controls.push(new DynformControl({ key: 'lastSentProviderMail',     mk: 'bus.booking.lastSentProviderMail',     controlType: 'label', }));
    
    controls.push(new DynformControl({ key: 'doSendProviderMail',     mk: 'bus.booking.doSendProviderMail' }));
   
    controls.push(new DynformControl({ key: 'mkIsCheckedIn',     mk: 'bus.booking.tag.checkedIn',     controlType: 'label', show:() => c.upCbHotel }));  // 	<c:if test="${company.upCbHotel}">
    
    controls.push(new DynformControl({  
      key: 'purpose',     
      mk: 'bus.booking.purpose', 
      controlType: 'select', 
      required: true,
      // show: () => user.isUser,bus.user.role  // 	<c:if test="${company.upCBooking}">
      options: () => {
        return this.fbo.purposes;
      }, 
      // optionsAllowEmpty: true,
      optionsFieldValue: "id", 
      optionsFieldLabel: "mk",
      // onChange: () => {
      //   this.edit.obj.userRole = this.apps.bas.us.listToMap(this.fbo.newUserRoles)[this.edit.form.controls.role.value];
      // }
    }));

    controls.push(new DynformControl({ key: 'comment',     mk: 'common.comment', controlType: "textarea" }));
    controls.push(new DynformControl({ key: 'notes',     mk: 'common.notes', controlType: "textarea" }));
    controls.push(new DynformControl({ key: 'orderReference',     mk: 'common.orderReference' }));
    controls.push(new DynformControl({ key: 'externalId',     mk: 'common.externalId' }));
    controls.push(new DynformControl({ key: 'jsonDataString',     mk: 'common.data', controlType: "textarea" }));  // 	<c:if test="${isBn}">
  
    // Invoice
    controls.push(new DynformControl({ key: 'mkGenerateInvoice',     mk: 'bus.booking.generateInvoice',     controlType: 'label' }));
    controls.push(new DynformControl({ key: 'invoiceDateAsString',     mk: 'bus.invoice.date', controlType: 'date-picker' }));  // 	<c:if test="${user.isBn}">

    controls.push(new DynformControl({  
      key: 'paymentMethod',     
      mk: 'bus.booking.pm', 
      controlType: 'select', 
      required: true,
      // show: () => user.isUser,bus.user.role  // 	<c:if test="${company.upCBooking}">
      options: () => {
        return this.fbo.paymentMethodsAdmin; 
      }, 
      // optionsAllowEmpty: true,
      optionsFieldValue: "id", 
      optionsFieldLabel: "mk",
      // onChange: () => {
      //   this.edit.obj.userRole = this.apps.bas.us.listToMap(this.fbo.newUserRoles)[this.edit.form.controls.role.value];
      // }
    }));

    // 	<c:set var="depositEnabled" value="${nit:getUpw(company, 'C_INVOICE_DEPOSIT').booleanValue}" />
    // <c:if test="${depositEnabled}">

    controls.push(new DynformControl({ key: 'advancePaymentAmountAsDouble',     mk: 'bus.booking.advancePaymentAmount' })); 
    controls.push(new DynformControl({ key: 'dueDateAdvancePaymentAsString',     mk: 'bus.booking.dueDateAdvancePayment', controlType: 'date-picker' })); 
    //	</c:if>

    controls.push(new DynformControl({ 
      key: 'dueDateFinalPaymentAsString',   
      mk: 'bus.invoice.dueDate',  // ${depositEnabled ? 'bus.booking.dueDateFinalPayment' : 'bus.invoice.dueDate'}
      controlType: 'date-picker' 
    })); 
    
    controls.push(new DynformControl({   key: 'settleDateAsString',        mk: 'bus.booking.settleDate',        controlType: 'date-picker'     })); // 	<c:if test="${user.isBn}">

    controls.push(new DynformControl({ key: 'userInvoiceNote',     mk: 'bus.user.invoiceNote', mkInfo: "bus.booking.invoiceNote.helpText", controlType: "textarea" }));
    controls.push(new DynformControl({ key: 'invoiceNote',     mk: 'bus.booking.invoiceNote', controlType: "textarea" }));
    
    controls.push(new DynformControl({ key: 'doSendInvoice',     mk: 'bus.booking.doSendInvoice' }));
    controls.push(new DynformControl({ key: 'doGenerateInvoice',     mk: 'bus.booking.doGenerateInvoice' }));
    controls.push(new DynformControl({ key: 'doGenerateInvoiceNotice',     mk: 'bus.booking.doGenerateInvoiceNotice' }));
    controls.push(new DynformControl({ key: 'doSettle',     mk: 'bus.booking.doSettle' }));
    controls.push(new DynformControl({ key: 'doSendInvoice',     mk: 'bus.booking.doSendInvoice' }));
    
    // 	<div class="savedBookingDiv notSettledDiv mt10">
    controls.push(new DynformControl({ key: 'doCancelUnpaid',     mk: 'bus.booking.doCancelUnpaid' }));
    controls.push(new DynformControl({ key: 'generateInitialInvoice',     mk: 'web.common.booking.generateInitialInvoice',  controlType: 'checkbox' }));
    controls.push(new DynformControl({ key: 'generateInvoiceForce',     mk: 'web.common.booking.generateInvoiceForce',  controlType: 'checkbox' })); // 	<c:if test="${wpd.isBn}">
    controls.push(new DynformControl({ key: 'includeEula',     mk: 'bus.booking.includeEula' }));
    //  end savedBookingDiv
        


    /*


			
			<div class="hide">
				<div id="selectUserDialog">
					
					<div class="form-horizontal">
						
						<div class="form-group">
							<label class="col-sm-4 control-label" for="userSearchField"><nit:message key="common.search"/>:</label>
							<div class="col-sm-8"><input type="text" class="form-control " id="userSearchField" value="" style="" /></div>
						</div>
						<div class="form-group">
							<div class="col-sm-offset-4 col-sm-8">
								<a href="#" class="btn btn-primary" id="userSearch"><nit:message key="common.search" ct="false" /></a>
								<a href="#" class="btn btn-default" id="newUser"><nit:message key="web.common.booking.newUser" ct="false" /></a>
							</div>
						</div>

					</div>
					
		
					<div style="max-height: 400px; overflow: auto;">
						<div class="table-responsive mt10">
							<table id="selectUserTable" class="table table-condensed table-hover">
								<thead>
									<tr id="selectUserTableHeader" class="">
										<th class="text-left"><nit:message key="common.username"/></th>
										<th class="text-left"><nit:message key="common.name"/></th>
										<!--<th class="text-left"><nit:message key="common.address"/></th>
										<th class="text-left"><nit:message key="common.tags"/></th>-->
										<th class="text-left"><nit:message key="common.select"/> / <nit:message key="common.edit"/></th>
										
									</tr>
								</thead>
								<tbody style=""></tbody>
							</table>
						</div>
					</div>
	
				</div>
			</div>
			
		
			
			<div class="hide">
				<div id="paymentDialog">
					<div id="fcPayment" class="formContainer">
						<input type="hidden" id="p_id" name="id" class="jpField" />
<!-- 						<input type="hidden" id="p_invoiceId" name="p_invoice" class="jpField" /> -->
						<input type="hidden" id="p_bookingId" name="p_booking" class="jpField" />
						
						<div class="form-horizontal">
							<!-- <div class="form-group newPaymentTr">
								<label class="col-sm-4 control-label" for=""><nit:message key="bus.invoice"/>:</label>
								<div class="col-sm-8"><p class="form-control-static" id="p_invoice"></p></div>
							</div>-->
							<div class="form-group newPaymentTr">
								<label class="col-sm-4 control-label" for="p_dateAsString"><nit:message key="bus.payment.date"/>:</label>
								<div class="col-sm-8">
									<div class="input-group date p_dateAsStringDatepicker dateDatepicker">
										<input type="text" id="p_dateAsString" name="p_dateAsString" class="form-control jpField datePickerInput " value="" autocomplete="off" />
										<span class="input-group-addon"><i class="glyphicon glyphicon-calendar" data-time-icon="glyphicon glyphicon-time" data-date-icon="glyphicon glyphicon-calendar"></i></span>
									</div>
								</div>
							</div>
							<div class="form-group newPaymentTr">
								<label class="col-sm-4 control-label" for="p_type"><nit:message key="common.type"/>:</label>
								<div class="col-sm-8"><nit:select id="p_type" name="p_type" cssClass="form-control jpField " items="${fbo.paymentTypes}" allowEmpty="true" valueProperty="id" labelProperty="mk" /></div>
							</div>
							<div class="form-group newPaymentTr">
								<label class="col-sm-4 control-label" for="p_amountDouble"><nit:message key="common.amount"/>:</label>
								<div class="col-sm-8"><input type="text" id="p_amountDouble" name="p_amountDouble" class="form-control jpField jpDouble " value="" /></div>
							</div> 
							<div class="form-group newPaymentTr">
								<label class="col-sm-4 control-label" for="p_feePaymentPartnerDouble"><nit:message key="common.fee"/>:</label>
								<div class="col-sm-8"><input type="text" id="p_feePaymentPartnerDouble" name="p_feePaymentPartnerDouble" class="form-control jpField jpDouble " value="" /></div>
							</div> 
							<div class="form-group">
								<label class="col-sm-4 control-label" for="p_comment"><nit:message key="common.comment"/>:</label>
								<div class="col-sm-8"><textarea id="p_comment" name="p_comment" class="form-control jpField "></textarea></div>
							</div>
							
							<div class="form-group" style="">
								<div class="col-sm-offset-4 col-sm-8">
									<a href="#" class="btn btn-primary jpSubmit" id="savePayment"><nit:message key="common.save" ct="false" /></a>
								</div>
							</div>
						</div>
			
					</div>
					
				</div>
			</div>
			
			<div class="hide">
				<div id="addPaymentDialog">
					<div id="fcAddPayment" class="formContainer">
						<input type="hidden" id="ap_id" name="id" class="jpField" />
<!-- 						<input type="hidden" id="ap_invoiceId" name="p_invoice" class="jpField" /> -->
						<input type="hidden" id="ap_bookingId" name="p_booking" class="jpField" />
<!-- 						<input type="hidden" id="ap_type" name="p_type" class="jpField" value="1" /> -->
						
						<div class="form-horizontal">
							<div class="form-group newPaymentTr">
								<label class="col-sm-4 control-label" for="p_type"><nit:message key="common.type"/>:</label>
								<div class="col-sm-8"><nit:select id="ap_type" name="p_type" cssClass="form-control jpField jpRequire" items="${fbo.paymentTypesAdmin}" allowEmpty="true" valueProperty="id" labelProperty="mk" /></div>
							</div>
							<div class="form-group">
								<label class="col-sm-4 control-label" for="ap_dateAsString"><nit:message key="bus.payment.date"/>:</label>
								<div class="col-sm-8">
									<div class="input-group date ap_dateAsStringDatepicker dateDatepicker">
										<input type="text" id="ap_dateAsString" name="p_dateAsString" class="form-control jpField jpRequire datePickerInput " value="" autocomplete="off" />
										<span class="input-group-addon"><i class="glyphicon glyphicon-calendar" data-time-icon="glyphicon glyphicon-time" data-date-icon="glyphicon glyphicon-calendar"></i></span>
									</div>
								</div>
							</div>
							<div class="form-group">
								<label class="col-sm-4 control-label" for="ap_amountDouble"><nit:message key="common.amount"/>:</label>
								<div class="col-sm-8"><input type="text" id="ap_amountDouble" name="p_amountDouble" class="form-control jpField jpRequire jpPositive jpDouble " value="" /></div>
							</div> 
							<!-- TODO: legge til flere beløpsfelt for gebyr og agio? -->
							<div class="form-group">
								<label class="col-sm-4 control-label" for="ap_comment"><nit:message key="common.comment"/>:</label>
								<div class="col-sm-8"><textarea id="ap_comment" name="p_comment" class="form-control jpField jpRequire "></textarea></div>
							</div>
							
							<div class="form-group" style="">
								<div class="col-sm-offset-4 col-sm-8">
									<a href="#" class="btn btn-primary jpSubmit" id=""><nit:message key="common.save" ct="false" /></a>
								</div>
							</div>
						</div>
			
					</div>
					
				</div>
			</div>
			<div class="hidden">
				<div id="useGiftCardDialogContent">
					<div id="fcUseGiftCard">
						<!-- <p><nit:message key="web.order.order.useGiftCard.desc" /></p> -->
						<div class="form-horizontal">
							<div class="form-group">
								<label class="col-sm-3 control-label" for="giftCardCode" style=""><nit:message key="bus.giftCard.code"/>:</label>
								<div class="col-sm-9"><input type="text" id="giftCardCode" name="giftCardCode" class="form-control jpField jpRequire" value="" placeholder="<nit:message key="bus.giftCard.code" ct="false"/>" /></div>
							
							</div>
							<div class="form-group">
								<label class="col-sm-3 control-label" for="giftCardAmount"><nit:message key="common.amount"/>:</label>
								<div class="col-sm-9"><input type="text" id="giftCardAmount" name="giftCardAmount" class="form-control jpField jpDouble jpPositive" value="" placeholder="<nit:message key="common.amount" ct="false"/>" /></div>
							
							</div>
							<div class="form-group">
								<div class="col-sm-offset-3 col-sm-9">
									<a href="#" id="useGiftCardButton" class="btn btn-primary jpSubmit"><nit:message key="web.order.order.useGiftCard" ct="false" /></a>
								</div>
							</div>
						</div>
						
					</div>
				</div>
			</div>
			
			<div class="hide">
				<div id="refundDialog">
					<div id="fcRefund" class="formContainer">
						<input type="hidden" id="refund_id" name="id" class="jpField" />
						<input type="hidden" id="refund_bookingId" name="p_booking" class="jpField" />
<!-- 						<input type="hidden" id="refund_invoiceId" name="p_invoice" class="jpField" /> -->
<!-- 						<input type="hidden" id="refund_type" name="p_type" class="jpField" value="1" /> -->

						
						<div class="form-horizontal">
						
							<div class="form-group">
								<label class="col-sm-4 control-label" for="refund_type"><nit:message key="common.type"/>:</label>
								<div class="col-sm-8"><select id="refund_type" name="p_type" class="form-control jpField "></select></div>
							</div>
							
							<div id="refund_bankAccountDiv" class="form-group">
								<label class="col-sm-4 control-label" for="refund_bankAccount"><nit:message key="bus.user.bankAccount"/>:</label>
								<div class="col-sm-8"><input type="text" id="refund_bankAccount" name="paymentBankAccount" class="form-control jpField " data-jp-min-length="11"  data-jp-max-length="11" value="" /></div>
							</div>
					
							<div class="form-group">
								<label class="col-sm-4 control-label" for="refund_amountDouble"><nit:message key="common.amount"/>:</label>
								<div class="col-sm-8"><input type="text" id="refund_amountDouble" name="p_amountDouble" class="form-control jpField jpRequire jpDouble jpPositive " value="" /></div>
							</div>
							<div class="form-group">
								<label class="col-sm-4 control-label" for="refund_comment"><nit:message key="common.comment"/>:</label>
								<div class="col-sm-8"><textarea id="refund_comment" name="p_comment" class="form-control jpField "></textarea></div>
							</div>
							
							
							
							<div class="form-group" style="">
								<div class="col-sm-offset-4 col-sm-8">
									<a href="#" class="btn btn-primary jpSubmit" id="saveRefund"><nit:message key="common.save" ct="false" /></a>
								</div>
							</div>
						</div>
			
					</div>
					
				</div>
			</div>
			
			<div class="hide">
				<div id="transferPaymentDialog">
					<div id="fcTransferPayment" class="formContainer">
						<input type="hidden" id="tp_id" name="id" class="jpField" />
						
						<p><nit:message key="web.common.booking.transferPaymentDesc"/></p>
						<div class="form-horizontal">
							
							<div class="form-group">
								<label class="col-sm-6 control-label" for="tp_target"><nit:message key="web.common.booking.transferPaymentTarget"/>:</label>
								<div class="col-sm-6"><input type="text" id="tp_target" name="target" class="form-control jpField jpRequire jpTrim "/></div>
							</div>
							<div class="form-group">
								<label class="col-sm-6 control-label" for="tp_amount"><nit:message key="common.amount"/>:</label>
								<div class="col-sm-6"><input type="text" id="tp_amount" name="amount" class="form-control jpField jpRequire jpDouble "/></div>
							</div>
							
							<div class="form-group" style="">
								<div class="col-sm-offset-6 col-sm-6">
									<a href="#" class="btn btn-primary jpSubmit" id="doTransferPaymentButton"><nit:message key="common.transfer" ct="false" /></a>
								</div>
							</div>
						</div>
			
					</div>
					
				</div>
			</div>
		


    */

 



 

    return controls;

   
  }

  onChange(event:any) {
    if (!event.control) return;
    this.dirty = true;

  }

  @HostListener('document:keydown.control.s',['$event']) 
  ctrlS(event: KeyboardEvent) {
    // console.log("ctrlS: ", event);

    this.submitForm();
    if (event) event.preventDefault();
    return false;
  }

  getVisibleSections() {
    let list:any = [];
    for (let s of Object.values(this.Sections)) {
      if (this.isSectionVisible(s.id)) list.push(s);
    }
    return list;
  }

  isSectionVisible(section:string, button:boolean = false) {

    let u = this.edit.obj;
    let ctrls = this.edit.form.controls;

    let isAdmin = this.apps.bas.aas.isAdmin();
  
    let role = u.userRole;

    // console.log("roleId: " + roleId + ", role: ", role);

    switch (section) {
      case "general": 
        return true;
      // case "ups": 
      //   if (role.isUser) return false; break

      default: break;
    }



    return true;
  }

  getOrder(id:any) {

    let isAdmin = this.apps.bas.aas.isAdmin();
    if (!isAdmin) {
      
      // vise feilmelding og sende til forsiden
      return;
    }

    if (this.apps.bas.envtest) console.log("getOrder, id: " + id + ", queryParams: ", this.route.snapshot.queryParams);

    let options:any = { jil: "all" };

    // if (id == "new" && this.route.snapshot.queryParams.copy) options.copy = this.route.snapshot.queryParams.copy;

    
    this.apps.bas.aas.getOrder(id, options).then((json) => {
      if (this.apps.bas.envtest) console.log("getOrder.then: ", json);

  
      let fbo = json.fbo;

      this.fbo = fbo;

      let order = json.order;

      this.setOrder(order);
      this.isSpinning = false;



    });
  } 

  setOrder(order:any) {

    order.logText = "";

    let controls = this.generateControlsOrder(order);


    this.apps.bas.fs.updateFormObj(this.edit, controls, order);

    // this.updateLogs(order);
    
    this.dirty = false;
  }

  // updateLogs(order:any) {
  //   if (!order.id) return; 

  //   this.apps.bas.ws.json( { 
  //     // actionType: "user", 
  //     action: "getOrderLogs",
  //     user: order.id
  //   }).then(json => {
  //     if (this.apps.bas.envtest) console.log("updateUserLogs, logs: ", json);
  //     order.logs = json.logs || [];
  //   });

  // }





  submitForm(): void {

  
    let rv = this.apps.bas.fs.getRawValue(this.edit.form, this.edit.controls);
    if (rv === false) return;

    rv.changes = this.edit.changes;

    this.isSpinning = true;

    let isNew = "new" == this.route.snapshot.queryParams.id;
    if (!isNew) delete rv.role;
    
    if (this.apps.bas.envtest) console.log("AdminOrderOrderPageComponent.submitForm, isNew: " + isNew+ ", rv: ", rv)

    this.apps.bas.aas.saveOrder(this.edit.obj.id || "new", rv).then(res => {
      this.isSpinning = false;
      this.edit.changes = { };

      if (this.apps.bas.envtest) console.log("AdminOrderOrderPageComponent.submitForm.then: ", res);

      if (!res.success) {

        return;
      }

      this.apps.bas.ui.success("Bookingen er lagret"); //TODO:text

      this.setOrder(res.order);
      if (isNew) {

        this.router.navigate([
            this.apps.bas.ui.getRouterPrefix() + '/admin/order/order'
          ], { queryParams: { id: res.order.id }}
        );

      }
     

    }).catch(err =>  {
      this.isSpinning = false;

      if (this.apps.bas.envtest) console.log("AdminOrderOrderPageComponent.submitForm.err: ", err);
    })


  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    // insert logic to check if there are pending changes here;
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away

    return !this.dirty;
  }


}


