import { Component, Input, OnInit, Injector } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { MiscUtil } from 'src/app/lib/core/util/misc.util';

@Component({
  selector: 'bt-weekdays',
  templateUrl: './bt-weekdays.component.html',
  styleUrls: ['./bt-weekdays.component.scss']
})
export class BtWeekdaysComponent implements OnInit {

  constructor(public injector:Injector, public apps:AppService) {

  }

  ngOnInit(): void {
  }

}
