<btlib-admin-page-wrapper>

  <div class="mx-d">

    
   
    <div class="pull-right">
      <button nz-button nzType="primary"  [routerLink]="apps.bas.ui.getRouterPrefix() + '/admin/user/user'" [queryParams]="{id: 'new' }">
        <i nz-icon nzType="plus-circle"></i>
        {{ 'common.add' | actrans }}
      </button>
  
    </div>
    <h2>{{ 'web.common.users' | actrans }}</h2>

    <div *ngIf="df.obj">
      <nz-collapse>
        <nz-collapse-panel  [nzHeader]="'Filter'" [nzActive]="apps.bas.ui.r.gtemd"  >
          
          <form nz-form [formGroup]="df.form" class="item-form mt-d" nzLayout="vertical" [nzAutoTips]="apps.bas.fs.autoTips" (ngSubmit)="dfSubmit()"  >
  
            <btlib-form-row [controls]="[ df.controls.role,  df.controls.search ]" [form]="df.form"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row>
           
            <button nz-button nzType="default"> {{ 'common.show' | actrans }}  </button>
      
          </form>

        </nz-collapse-panel>
      </nz-collapse>


     
    </div>
  

    <div class="mt-d">

      <nz-table 
        #nzTableOrders
        [nzData]="fbo.stats.current || []" 
        nzTableLayout="fixed" 

        nzSize="small"
        class="nz-table-xs"

        [nzFrontPagination]="false"
        [nzLoading]="fbo.loading"

        [nzTotal]="fbo.stats.navigatorObj.size"
        [nzPageSize]="fbo.stats.navigatorObj.pageSize"
        [nzPageIndex]="fbo.stats.navigatorObj.page+1"
        (nzQueryParams)="onQueryParamsChange($event)"

        >
        <thead>
          <tr>
            <!-- <th nzWidth="30px"></th> -->
      

            <th>{{ 'common.username' | actrans }}</th>
            <th [nzWidth]="'100px'">{{ 'bus.user.role' | actrans }}</th>
            <th *ngIf="apps.bas.ui.r.gtemd">{{ 'common.name' | actrans }}</th>
            <th [nzWidth]="'100px'" *ngIf="apps.bas.ui.r.gtemd">{{ 'common.updated' | actrans }}</th>

            <th nzWidth="30px"></th>

          </tr>
        </thead>
        <tbody>
          <ng-container  *ngFor="let data of nzTableOrders.data; let idx = index">
            <tr 
              class="cursor-pointer" 
              (click)="editUser(data)" 
              >

 
              <td>{{ data.username }}</td>
              <td>{{ data.mkRole }}</td>
              <td *ngIf="apps.bas.ui.r.gtemd">
                {{ data.name }}
              </td>

          
              <td *ngIf="apps.bas.ui.r.gtemd">{{ data.updated }}</td>
              
              <td>
                <i nz-icon  [nzType]="'arrow-right'" ></i>
              </td>
            </tr>
        
          </ng-container>
        </tbody>
 
      </nz-table>


    </div>
  </div>



  

</btlib-admin-page-wrapper>
