import { Pipe, PipeTransform, Injector } from "@angular/core";
import { Inject } from '@angular/core';
import { AppService } from "src/app/services/app.service";
import { BooktechAppService } from "../services/booktech-app.service";


@Pipe({ name: "nf" })
export class NfPipe implements PipeTransform {

  
  constructor(injector:Injector, public apps:AppService) {

    
  }


  transform(value: number | string, fractionSize: number = 2): string {
    return this.apps.bas.ui.nf(value, fractionSize);

  }

  parse(value: string, fractionSize: number = 2): number {
    return this.apps.bas.ui.nfparse(value, fractionSize);

  }

}

