import { Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { Location, isPlatformServer } from '@angular/common';
import { DOCUMENT } from '@angular/common';

import { ApiService } from './api.service';
import { DataService, DATAID } from './data.service';
import { EventService, BtEvent } from './event.service';
import { UtilService } from './util.service';
import { WebService } from './web.service';


// import { environment } from '../../../environments/environment';
// import { AppService } from '../..//services/app.service';
import { UIService } from './ui.service';
import { OrderService } from './order.service';
import { StyleService } from './style.service';
import { ScriptService } from './script.service';
import { AppAdminService } from './app-admin.service';
import { AppCommonService } from './app-common.service';
import { FormService } from './form.service';
import { HookService } from './hook.service'

import { MiscUtil } from '../util/misc.util';
import { TrackingService } from './tracking.service';

export class BooktechAppService {

  apps:any;
  api:ApiService;
  ds:DataService;
  es:EventService;
  os:OrderService;
  ui:UIService;
  us:UtilService;
  ws:WebService;
  style:StyleService;
  script:ScriptService;
  aas:AppAdminService;
  acs:AppCommonService;
  fs:FormService;
  hs:HookService;
  ts:TrackingService;

  env;
  settings:any;
  envprod:boolean = false;
  envtest:boolean = true;
  envdev:boolean = true;
  standalone:boolean = false;
  // ssr:boolean = false;

  ssr :boolean = false;
  nossr :boolean = true;

  appLogin:any = { success: false }

  public DOC:Document;
  public LOC:Location;

  constructor(
    public injector:Injector,
    public initConfig: any

  ) {
    
    this.apps = initConfig.apps;
    this.env = initConfig.environment;
    this.settings = this.env.settings;
    this.standalone = !!this.settings.standalone;

    this.envprod = this.settings.env;
    this.envtest = !this.envprod;

    let platformId = injector.get(PLATFORM_ID);
    if(this.envtest)  console.log(MiscUtil.getLogText("platformId: "), platformId);
    this.ssr = isPlatformServer(platformId);
    this.nossr = !this.ssr;

    if(this.envtest)  console.log(MiscUtil.getLogText("BooktechAppService.constructor, settings: ", this.settings));


    // let win:any = window;
    this.envdev = this.nossr && window.location.hostname === "192.168.1.81";
    

    this.DOC = injector.get(DOCUMENT);
    this.LOC = injector.get(Location);

    if(this.envtest)  console.log(MiscUtil.getLogText("LOC, path " + this.LOC.path() 
      // + ", state: " +this.LOC.getState()
      // + ", normalize: " +this.LOC.normalize()
      ));


    
    this.es = new EventService(injector, this);
    this.hs = new HookService(injector, this);

    this.api = new ApiService(injector, this);
    this.ds = new DataService(injector, this);

    this.aas = new AppAdminService(injector, this);
    this.acs = new AppCommonService(injector, this);
    this.os = new OrderService(injector, this);
    this.ui = new UIService(injector, this);
    this.us = new UtilService(injector, this);
    this.ws = new WebService(injector, this);
    this.style = new StyleService(injector, this);
    this.script = new ScriptService(injector, this);
    this.fs = new FormService(injector, this);
    this.ts = new TrackingService(injector, this);


    this.es.on(BtEvent.APP_INIT, this.initializeApp); // denne blir aldri kallt


    this.ds.get(DATAID.APP_LOGIN).subscribe((appLogin) => {
      if(this.envtest) console.log("BooktechAppService, subscribe:APP_LOGIN: " + appLogin?.user?.label);
      this.appLogin = appLogin;
    });

    if(this.envtest) console.log("BooktechAppService.constructor DONE, standalone: " + this.standalone);

  }

  initializeApp() {

  }
}
