<btlib-admin-page-wrapper>

  <div class="mx-d">


    <h2>{{ 'web.common.stats' | actrans }}</h2>

    <nz-alert nzType="warning" nzMessage="Denne funksjonen er under utvikling." nzShowIcon></nz-alert>
    

    <div class="mt-d" *ngIf="df.obj">
      <nz-collapse>
        <nz-collapse-panel  [nzHeader]="'Filter'" [nzActive]="apps.bas.ui.r.gtemd"  >
          
          <form nz-form [formGroup]="df.form" class="item-form mt-d" nzLayout="vertical" [nzAutoTips]="apps.bas.fs.autoTips" (ngSubmit)="dfSubmit()"  >
  
            <btlib-form-row [controls]="[ 
              df.controls.statsType,
            ]" [form]="df.form"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row>


            <btlib-form-row [controls]="[ 
            
              df.controls.period,
              df.controls.datePeriodAsRange,
            ]" [form]="df.form"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row>

            <btlib-form-row [controls]="[ 
            
            df.controls.groupBy,
          ]" [form]="df.form"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row>


            <div  *ngIf="fbo.stats.statsType == 'BOOKING'">
              <btlib-form-row [controls]="[ 
                df.controls.product
              ]" [form]="df.form"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row>



            </div>
           
            <button nz-button nzType="default"> {{ 'common.show' | actrans }}  </button>
      
          </form>

        </nz-collapse-panel>
      </nz-collapse>


     
    </div>
  

    <div class="mt-d">

      <nz-table 
        #nzTableOrders
        [nzData]="fbo.stats.current || []" 
        nzTableLayout="fixed" 

        nzSize="small"
        class="nz-table-xs"

        [nzFrontPagination]="false"
        [nzLoading]="fbo.loading"

        [nzTotal]="fbo.stats.navigatorObj.size"
        [nzPageSize]="fbo.stats.navigatorObj.pageSize"
        [nzPageIndex]="fbo.stats.navigatorObj.page+1"
        (nzQueryParams)="onQueryParamsChange($event)"

        >
        <thead  *ngIf="fbo.stats.groupByNone">
          <tr *ngIf="fbo.stats.statsType == 'BOOKING'" >

            <th [nzWidth]="'70px'">{{ 'common.id' | actrans }}</th>
            <th [nzWidth]="apps.bas.ui.r.gtemd ? '100px' : '40px'">
              <span *ngIf="apps.bas.ui.r.gtemd">{{ 'common.status' | actrans }}</span>
            </th>
            <th>{{ 'common.product' | actrans }}</th>

            <th [nzWidth]="'100px'" *ngIf="apps.bas.ui.r.gtelg">{{ 'common.updated' | actrans }}</th>
            <th [nzWidth]="'140px'" *ngIf="apps.bas.ui.r.gtemd">{{ 'common.period' | actrans }}</th>

            <th [nzWidth]="'70px'" *ngIf="apps.bas.ui.r.gtelg">{{ 'bus.booking.guests' | actrans }}</th>
            <th [nzWidth]="'70px'" *ngIf="apps.bas.ui.r.gtelg">{{ 'common.count' | actrans }}</th>

            <th  [nzWidth]="'90px'" nzAlign="right">{{ 'common.amount' | actrans }}</th>


          </tr>
          <tr *ngIf="fbo.stats.statsType == 'REPORT'">

            <th>{{ 'common.id' | actrans }}</th>
            <th [nzWidth]="'100px'">{{ 'common.period' | actrans }}</th>
            <th *ngIf="apps.bas.ui.r.gtemd">{{ 'common.updated' | actrans }}</th>
            <!-- <th [nzWidth]="'100px'" *ngIf="apps.bas.ui.r.gtemd">{{ 'common.updated' | actrans }}</th> -->
            <!-- <th nzWidth="30px"> </th>
            <th nzWidth="30px"></th> -->

            <!--


            -->
          </tr>
          <tr *ngIf="fbo.stats.statsType == 'LOG'">

            <th>{{ 'common.id' | actrans }}</th>
            <th *ngIf="apps.bas.ui.r.gtemd">{{ 'common.time' | actrans }}</th>
   
          </tr>
        </thead>
        <thead  *ngIf="!fbo.stats.groupByNone">

          <tr *ngIf="!fbo.stats.groupBy2None">

							<td></td>
							<th class="text-center" [colspan]="fbo.stats.header.size - 2">
                {{ fbo.stats.groupBy2Obj.mk | actrans }}
              </th>
							<td></td>
				
          </tr>
          <tr class="">
            <!-- <th>NOT GBN</th> -->
						<!-- <th class="text-left">{{ fbo.stats.header.values[0] | actrans }}</th> -->

						<th 
              *ngFor="let hw of ((fbo.stats.header.values || []) | filterBy:{ tagExcel: false }); let idx = index;" 
              [class]="hw.hObj ? hw.hObj.cssClass : ''"
              [nzWidth]="idx == 0 ? '25%' : ''"
              >
              {{ hw.findMk | actrans }}
            </th>
            
              <!-- TODO: ngIf="response.size" -->

					</tr>

        </thead>
        <tbody  *ngIf="fbo.stats.groupByNone">

          <ng-container  *ngFor="let data of nzTableOrders.data; let idx = index">
            <tr  *ngIf="fbo.stats.statsType == 'BOOKING'" class="cursor-pointer"  (click)="orderInfo = { id: data.bookingId }" >

 
              <td>{{ data.bid }}</td>
              <td [class]="data.statusCssClass">
                <span *ngIf="apps.bas.ui.r.gtemd">{{ data.mkStatus }}</span> 
              </td>
              <td>{{ data.product }}</td>
     
              
              <td *ngIf="apps.bas.ui.r.gtelg">{{ data.updated }}</td>
              
              <td *ngIf="apps.bas.ui.r.gtemd">{{ data.periodString }}</td>
       
              <td *ngIf="apps.bas.ui.r.gtelg">{{ data.guests }}</td>
              <td *ngIf="apps.bas.ui.r.gtelg">{{ data.count }}</td>

              <td nzAlign="right">{{ data.amountDouble | nf }}</td>

                          <!-- 

							<td class="text-left ${o.statusCssClass}" data-title="<nit:message key="common.status" ct="false" />"><nit:message key="${o.mkStatus}"/></td>
							<td class="text-left nowrap" data-title="<nit:message key="common.updated" ct="false" />">
								<fmt:formatDate value="${o.b.time}" type="both" dateStyle="short" timeStyle="short" timeZone="${user.timeZone.ID}"/><br/>
								<fmt:formatDate value="${o.b.updated}" type="both" dateStyle="short" timeStyle="short" timeZone="${user.timeZone.ID}"/>
							</td>
							<td class="text-left" data-title="<nit:message key="common.user" ct="false" />">
								<c:out value="${o.b.user.name}"/><br/>
								<c:if test="${wpd.pageName == 'orders'}">
									<a href="#" class="bookingUserLink"><c:out value="${o.b.user.username}"/></a>
									<c:if test="${not empty o.b.user.telephone}">
										<br/><strong><nit:message key="common.telephone"/></strong>: ${o.b.user.telephone}
									</c:if>
								</c:if>
								
							</td>
							<td class="text-left" data-title="<nit:message key="common.product" ct="false" />">
								<c:out value="${o.product.name}" />
								<c:if test="${o.hasParent}">
									(<c:out value="${o.parent.product.name}" />)
								</c:if>
							</td>
							<td class="text-left" data-title="<nit:message key="common.quantity" ct="false" />">
								<fmt:formatNumber value="${o.quantity}" minFractionDigits="${nit:isIntegerNumber(o.quantity) ? 0 : 2}" maxFractionDigits="${nit:isIntegerNumber(o.quantity) ? 0 : 2}"/>
							</td>
							<td class="text-left" data-title="<nit:message key="bus.booking.guests" ct="false" />">
								<c:if test="${o.product.guestProduct}">
									${o.guests}
									<c:if test="${o.product.findTypeLodging}"><br/>${o.days} / ${o.guestDays}</c:if>
								</c:if>
							</td>
							<c:if test="${wpd.isNit or company.upCBooking}">
								<td class="text-left" data-title="<nit:message key="common.arrival" ct="false" />">
									<fmt:formatDate var="orderStart" value="${o.start}" type="${o.product.findShowTime ? 'both' : 'date'}" dateStyle="short" timeStyle="short" timeZone="${user.timeZone.ID}" />
									<c:choose>
									<c:when test="${wpd.pageName != 'orders'}">${orderStart}</c:when>
									<c:otherwise><a href="#" class="orderStartLink" data-start-as-string="${o.startAsString}" data-end-as-string="${o.endAsString}">${orderStart}</a></c:otherwise>
									</c:choose>
								</td>
								<td class="text-left" data-title="<nit:message key="common.departure" ct="false" />">
									<fmt:formatDate var="orderEnd" value="${o.end}" type="${o.product.findShowTime ? 'both' : 'date'}" dateStyle="short" timeStyle="short" timeZone="${user.timeZone.ID}" />
									<c:choose>
									<c:when test="${wpd.pageName != 'orders'}">${orderEnd}</c:when>
									<c:otherwise><a href="#" class="orderEndLink" data-start-as-string="${o.startAsString}" data-end-as-string="${o.endAsString}">${orderEnd}</a></c:otherwise>
									</c:choose>
								</td>
							</c:if>
							<td class="text-right nowrap" data-title="<nit:message key="common.amount" ct="false" />">
								<fmt:formatNumber value="${o.amountDouble}" minFractionDigits="2" maxFractionDigits="2"/>
								<c:if test="${isTest}">
									<br/><span style="${o.booking.paidAmount != 0 ? 'color: red; font-weight: bold;' : ''}"><fmt:formatNumber value="${o.booking.paidAmountDouble}" minFractionDigits="2" maxFractionDigits="2"/></span>
									<br/><fmt:formatNumber value="${o.booking.remainingAmountDouble}" minFractionDigits="2" maxFractionDigits="2"/>
								</c:if>
							</td>
							<td class="text-right nowrap" data-title="<nit:message key="bus.bookingItem.providerAmount" ct="false" />"><fmt:formatNumber value="${o.calcProviderAmountDouble}" minFractionDigits="2" maxFractionDigits="2"/></td>
							<td class="text-right nowrap" data-title="<nit:message key="common.commission" ct="false" />"><fmt:formatNumber value="${o.commissionAmountDouble}" minFractionDigits="2" maxFractionDigits="2"/></td>
              
                            <c:set var="orderSumGuestCount" value="${orderSumGuestCount + o.guestCount}" />
                            <c:if test="${o.product.findTypeLodging}">
                                <c:set var="orderSumDays" value="${orderSumDays + o.days}" />
                            
                            </c:if>
                            
                            <c:set var="orderSumAmount" value="${orderSumAmount + o.amountDouble}" />
                            <c:set var="orderSumProvider" value="${orderSumProvider + o.calcProviderAmountDouble}" />
                            <c:set var="orderSumCommission" value="${orderSumCommission + o.commissionAmountDouble}" />
							
							
							<c:if test="${wpd.isNit}">
								<td class="text-left" data-title="<nit:message key="common.company" ct="false" />"><c:if test="${not empty o.b.company}"><c:out value="${o.b.company.label}"/></c:if></td>
							</c:if>
							
							<c:if test="${stats.hasHeaderDelete and not isStats}"><td class="text-center"><input type="checkbox" name="toDelete" value="${o.id}" /></td></c:if>
							<td class="text-right" data-title="">
								<c:if test="${wpd.pageName == 'orders'}">
									<c:if test="${o.product.confirmationRequired and empty o.completed}">
										<a href="#" class="btn btn-default btn-xs confirmBookingItem" data-biid="${o.id}"><nit:message key="common.confirm" ct="false" /></a>
									</c:if>
								</c:if>
							</td>
							
						</tr>
						<c:if test="${wpd.pageName == 'orders' and not empty o.b.comment}">
						<tr>
							<td></td>
							<td class="text-left ${o.statusCssClass}"></td>
							<td class="text-left" colspan="12">
								<c:if test="${not empty o.b.comment}">
									<strong><nit:message key="common.comment" ct="false" /></strong>: ${o.b.comment}
								</c:if>
							</td>
							
						</tr>
						</c:if>
					</c:when>

            -->
            </tr>
            <tr  *ngIf="fbo.stats.statsType == 'REPORT'" >

 
              <td>R: {{ data.id }}</td>
              <td>{{ data.periodLabel }}</td>
              <td *ngIf="apps.bas.ui.r.gtemd">
                {{ data.updated }}
              </td>

            </tr>
            <tr  *ngIf="fbo.stats.statsType == 'LOG'" >

 
              <td>L: {{ data.id }}</td>
              <td *ngIf="apps.bas.ui.r.gtemd">
                {{ data.time }}
              </td>

            </tr>
        
          </ng-container>
        </tbody>
        <tbody  *ngIf="!fbo.stats.groupByNone">
          <!-- <tr class="">
            <th>NOT GBN</th>
          </tr> -->

					<ng-container  *ngFor="let data of nzTableOrders.data; let idx = index">
            <tr class="">
							<!-- <th class="text-left"> {{ data.v0 | actrans }} </th> -->
							
              <td 
              *ngFor="let col of (data.vals || []); let idx2 = index" 
              [class]="col.hw?.hObj ? col.hw.hObj.cssClass : ''"
              >
         
              {{ idx2 <= 1 ? (col.value | actrans) : (col.value | nf:0) }}
            </td>

						</tr>
					</ng-container>

          <!--

          <c:set var="fractionDigits" value="${stats.isDouble ? 2 : 0}" />
				
					<c:forEach var="o" items="${stats.current}" varStatus="status">
						<c:set var="cssClass" value="${(status.index % 2 == 0) ? '' : 'bgAlt'}"/>
						<tr>
							<th class="text-left"><nit:message key="${o.v0}"/></th>
							<c:forEach var="v" begin="1" items="${o.values}" varStatus="status">
								<c:set var="hw" value="${stats.header.values[status.index]}" />
								<c:if test="${not hw.tagExcel}">
									<td class="text-right">
										<c:choose>
										<c:when test="${not stats.groupBy2None}">
											<fmt:formatNumber value="${v}" maxFractionDigits="${fractionDigits}" minFractionDigits="${fractionDigits}"/>
										</c:when>
										<c:otherwise>
											<c:set var="fd" value="${nit:isDouble(v) ? 2 : 0}" />
											<fmt:formatNumber value="${not empty v ? v : 0}" maxFractionDigits="${fd}" minFractionDigits="${fd}"/>
										</c:otherwise>
										</c:choose>
									</td>
								</c:if>
							</c:forEach>
						</tr>
					</c:forEach>


          -->
        </tbody>

				<tfoot *ngIf="!fbo.stats.groupByNone">
          <tr class="">
            <!-- <th class="text-left"> {{ data.v0 | actrans }} </th> -->
            
            <th
            *ngFor="let col of (fbo.stats.footer.vals || []); let idx2 = index" 
            [class]="col.hw?.hObj ? col.hw.hObj.cssClass : ''"
            >
       
            {{ idx2 <= 1 ? (col.value | actrans) : (col.value | nf:0) }}
          </th>

          </tr>

									
					
					<!--TODO:
             <tr class="">
						<th class="text-left"><nit:message key="${stats.footer.v0}"/></th>
						<c:forEach var="v" begin="1" items="${stats.footer.values}" varStatus="status">
							<c:set var="hw" value="${stats.header.values[status.index]}" />
							<c:if test="${not hw.tagExcel}">
							<th class="text-right">
								<c:choose>
								<c:when test="${not stats.groupBy2None}">
									<fmt:formatNumber value="${v}" maxFractionDigits="${fractionDigits}" minFractionDigits="${fractionDigits}"/>
								</c:when>
								<c:otherwise>
									<c:set var="fd" value="${nit:isDouble(v) ? 2 : 0}" />
									<fmt:formatNumber value="${not empty v ? v : 0}" maxFractionDigits="${fd}" minFractionDigits="${fd}"/>
								</c:otherwise>
								</c:choose>
							</th>
							</c:if>
						</c:forEach>
					</tr> -->

				</tfoot>
 
      </nz-table>


    </div>
  </div>


  <btlib-order-info-modal [order]="orderInfo"></btlib-order-info-modal>



  

</btlib-admin-page-wrapper>


