
<!-- <h2 class="text-center">{{ fbo.parent?.name }}</h2> -->

<!-- <button nz-button nzType="primary" nzDanger (click)="$event.preventDefault();  apps.bas.es.sendParentMessageResize();">Resize</button> -->

<bt-page-wrapper>
<nz-spin [nzSpinning]="processing">
<div class="p-d">
  <div *ngIf="edit.form">

    <form nz-form [formGroup]="edit.form" (ngSubmit)="formSubmit()"  class="item-form mt-d" nzLayout="vertical" [nzAutoTips]="apps.bas.fs.autoTips" >
      

      <nz-steps 
        [nzCurrent]="step" 
        [nzSize]="apps.bas.ui.r.gtelg ? 'default' : 'small'" 
        (nzIndexChange)="onIndexChange($event)"
        >
        <nz-step [nzTitle]="apps.bas.ui.r.gtemd ? 'Periode' : ''"></nz-step>
        <nz-step [nzTitle]="apps.bas.ui.r.gtemd ? 'Plass' : ''"></nz-step>
        <nz-step [nzTitle]="apps.bas.ui.r.gtemd ? 'Kontaktinfo' : ''"></nz-step>
        <nz-step [nzTitle]="apps.bas.ui.r.gtemd ? 'Betaling' : ''"></nz-step>
        <nz-step [nzTitle]="apps.bas.ui.r.gtemd ? 'Ferdig' : ''"></nz-step>
      </nz-steps>
      <!-- [nzDirection]="apps.bas.ui.r.gtemd ? 'horizontal' : 'vertical'" -->

      <div class="m-12"></div>

      <div *ngIf="step == 0">
        <h2 *ngIf="apps.bas.ui.r.ltesm">Kundetype og periode</h2>

         
        <div nz-row>
          <div nz-col [nzXs]="24" [nzMd]="12"  >
            <h3>Velg kundetype</h3>
            <!-- <btlib-form-row [controls]="[ edit.controls.priceId ]" [form]="edit.form"  [grid]="apps.bas.ui.gridMd" [options]="edit.controlOptions" ></btlib-form-row>  -->
            <btlib-form-row [controls]="[ edit.controls.customerType ]" [form]="edit.form"  [grid]="apps.bas.ui.gridXl" [options]="edit.controlOptions" ></btlib-form-row> 
    
          </div>
          <div nz-col [nzXs]="24"  [nzMd]="12">


            <h3 class="">{{ fbo.parent?.mkName }}</h3>
            <p>{{ fbo.parent?.mkIngress }}</p>

            <div style="" *ngIf="!fbo.fixedPeriod">



              <h3>Velg periode(r)</h3>
              <btlib-form-row [controls]="[ edit.controls.datePeriodAsRange ]" [form]="edit.form"  [grid]="apps.bas.ui.r.gtemd ? apps.bas.ui.gridXl : apps.bas.ui.gridMd" [options]="edit.controlOptions"  ></btlib-form-row> 
              <button nz-button nzType="default" (click)="$event.preventDefault(); addPeriod()">Legg til periode</button>
                
              <h3 class="mt-d">Valgte perioder ({{edit.periods.length}})</h3>
      
                <div *ngFor="let per of edit.periods">
                  
                  <button nz-button nzType="link" (click)="removePeriod(per)" title="Slett"><i nz-icon nzType="delete"></i> </button>
                  {{ per.label }}
                </div>
             
              <nz-alert *ngIf="edit.periodsError" nzType="error" nzMessage="Du må velge minst én periode"></nz-alert>
            
            </div>
            <div *ngIf="fbo.fixedPeriod">
            </div>

          </div>
        </div>

       
      
      

       

        <!-- Perioder: {{ edit.form.controls.periods.value }} -->
        
        <div class="m-d"></div>
        <!-- <button nz-button nzType="default" (click)="$event.preventDefault(); prevStep();">Tilbake</button>
        <button nz-button nzType="default" (click)="$event.preventDefault(); nextStep()">Gå videre</button> -->
        <div nz-row>
          <div nz-col [nzXs]="12" >
            <!-- <button nz-button nzType="default" (click)="$event.preventDefault(); prevStep();">Tilbake</button> -->
          </div>
          <div nz-col [nzXs]="12" class="text-right"> <button nz-button nzType="primary" (click)="$event.preventDefault(); nextStep()">Gå videre</button></div>
        </div>

        <div *ngIf="apps.bas.ui.r.gtemd" style="height: 200px;"></div>

        <div *ngIf="apps.bas.ui.r.ltesm" style="height: 80vh;"></div>

      </div>
      <div *ngIf="step == 1">
        <h2 *ngIf="apps.bas.ui.r.ltesm">Velg plass</h2>


        <p class="text-bold">Velg ønsket plass nedenfor.</p>

        <div *ngIf="fbo.parent?.mkDesc" [innerHtml]="fbo.parent?.mkDesc"></div>   <!--DONE: custom tekst -->
        <div *ngIf="!fbo.parent?.mkDesc">

          <p>Hvis du ikke hører fra oss innen kort tid så vil du få plassen du har valgt.</p>

          <p>Merk! Tilgang til strøm er markert på kartet. Kun plasser i nærheten av disse punktene kan forvente å få tilgang til strøm.</p>
          <p>Plassene i midten av torget har normalt ikke tilgang til størm, ta kontakt hvis du har behov for det.</p>
        
        </div>

        
        <nz-alert class="m-d" *ngIf="!fbo.isSomeAvailable" nzType="error" nzShowIcon nzMessage="Ingen plasser er tilgjengelig på datoen og/eller periodene du har valgt. Ta kontakt med oss eller velg andre perioder."></nz-alert>
        
        <nz-alert class="m-d" *ngIf="fbo.hasDateExceptions" nzType="error" nzShowIcon nzMessage="Perioden(e) du bestiller inneholder perioder og datoer som ikke er inkludert i denne leien: {{ fbo.dateExceptionsString }}"></nz-alert>
        
        

        <div nz-row [nzGutter]="apps.bas.ui.gridMd.gutter" nzAlign="top">
          <div nz-col [nzXs]="apps.bas.ui.gridMd.xs" [nzSm]="24" [nzMd]="12" [nzLg]="16" [nzXl]="16" [nzXXl]="16">
          

            <bt-image-map 
              [bg]="imageMap.bg" 
              [items]="imageMap.items" 
              (onClick)="imageMapClick($event)" 
              [canEdit]="true"></bt-image-map>

          </div>
          <div nz-col [nzXs]="apps.bas.ui.gridMd.xs" [nzSm]="24" [nzMd]="12" [nzLg]="8" [nzXl]="8" [nzXXl]="8">
                
            <h3 class="text-bold">Valgte plasser</h3>

            <div class="text-lg" *ngFor="let item of edit.items">
              <i nz-icon nzType="shop"></i> 
              {{ item.name }}
            </div>
            <p *ngIf="edit.items.length == 0"  class="text-italic">Ingen plasser er valgt</p>
            <nz-alert *ngIf="edit.itemsError" nzType="error" nzMessage="Du må velge minst én plass"></nz-alert>
        
          

            <div *ngIf="fbo.periodAmounts">
              <!-- <h3 class="mt-d">Pris</h3> -->
              <nz-descriptions class="mt-d"
                nzTitle="Pris"
                nzBordered
                [nzColumn]="1"
                nzSize="small"
              >
                <nz-descriptions-item  
                  *ngFor="let pa of fbo.periodAmounts" 
                  nzTitle="{{ pa.period }}">
           
                  <div class="text-right text-norwap">{{ pa.amountAsDouble | nf }}</div>
                </nz-descriptions-item>

                <nz-descriptions-item  
                  *ngFor="let acc of fbo.accessories" 
                  nzTitle="{{ acc.name }}">
                  <div class="text-right text-norwap">{{ acc.amountAsDouble | nf }}</div>
                </nz-descriptions-item>

                <nz-descriptions-item nzTitle="Sum"><div class="text-right text-norwap text-bold">{{ fbo.sumAmountAsDouble | nf }}</div></nz-descriptions-item>
              
              </nz-descriptions>

      
            </div>


          </div>
        </div>

        <div class="m-d"></div>
       
        <div nz-row>
          <div nz-col [nzXs]="12"><button nz-button nzType="default" (click)="$event.preventDefault(); prevStep();">Tilbake</button></div>
          <div nz-col [nzXs]="12" class="text-right"> <button nz-button nzType="primary" (click)="$event.preventDefault(); nextStep()">Gå videre</button></div>
        </div>
      </div>
      <div *ngIf="step == 2">
        <h2 *ngIf="apps.bas.ui.r.ltesm">Kontaktinfo</h2>

        <div>
          <btlib-form-row [controls]="edit.controls.inputs.children" [form]="edit.form.controls.inputs"  [grid]="apps.bas.ui.gridMd"  [options]="edit.controlOptions" ></btlib-form-row> 
      
        </div>

        <btlib-form-row [controls]="[ 
          edit.controls.user.childMap.name ,
          edit.controls.user.childMap.orgNumber ,
          edit.controls.user.childMap.contactPerson ,
          edit.controls.user.childMap.telephone,
          edit.controls.user.childMap.username,
        
          ]" [form]="edit.form.controls.user"  [grid]="apps.bas.ui.gridMd"  [options]="edit.controlOptions" ></btlib-form-row> 
      
      
          <btlib-form-row [controls]="[ 
          edit.controls.paymentMethod
          ]" [form]="edit.form"  [grid]="apps.bas.ui.gridMd"  [options]="edit.controlOptions" ></btlib-form-row> 
        
          <p *ngIf="edit.pm">
            {{ edit.pm.mkDesc }}
          </p>
  
          <btlib-form-row [controls]="[ 
          edit.controls.user.childMap.ehfEnabled ,
          edit.controls.user.childMap.ehfOrderReference
        
          ]" [form]="edit.form.controls.user"  [grid]="apps.bas.ui.gridMd"  [options]="edit.controlOptions" ></btlib-form-row> 
      
          
    
        <btlib-form-row [controls]="[ 
          edit.controls.comment
          ]" [form]="edit.form"  [grid]="apps.bas.ui.gridXl"  [options]="edit.controlOptions" ></btlib-form-row> 
    

        <btlib-form-row [controls]="[ 
        edit.controls.user.childMap.acceptEula
        
        ]" [form]="edit.form.controls.user"  [grid]="apps.bas.ui.gridMd"  [options]="edit.controlOptions" ></btlib-form-row> 
    

        <p *ngIf="apps.bas.envtest">
          <button nz-button nzType="default" (click)="$event.preventDefault(); testForm();">TEST</button>
        </p>
 

        <div class="m-d"></div>

        <div nz-row>
          <div nz-col [nzXs]="12">
            <button nz-button nzType="default" (click)="$event.preventDefault(); prevStep();">Tilbake</button>
            <button nz-button nzType="default" (click)="$event.preventDefault(); edit.showEula = true; scrollToTop();">Vis avtalevilkår</button> 
          </div>
          <div nz-col [nzXs]="12" class="text-right"> <button nz-button nzType="primary" (click)="$event.preventDefault(); nextStep()">Bekreft bestilling</button></div>
        </div>
        
      </div>
      <div *ngIf="step == 3">
        <h2 *ngIf="apps.bas.ui.r.ltesm">Betaling</h2>

        <div *ngIf="booking">

          
          <div *ngIf="booking.isPmInvoice || booking.isPmFree">

            <div *ngIf="booking.isPmFree">
              <p>
                Som frivilling organisasjon er det ingenting å betale. <br/><br/>
                Fullfør ved å trykke på knappen nedenfor. 
              </p>
            </div>

            <div *ngIf="!booking.isPmFree && booking.invoice">
              
              <p *ngIf="!booking.invoice.ehfStatus">
                Benytt nettbank eller fyll ut bankgiro med opplysningene nedenfor.
                Vi har også sendte deg disse opplysningene på epost.
              </p>
              <p *ngIf="booking.invoice.ehfStatus">
                Vi har sendt deg faktrua som EHF. <br/>
                Betalingsopplysningene ser du nedenfor.
          
              </p>

              <div nz-row>
                <div nz-col [nzXs]="24" [nzMd]="16" [nzLg]="12" [nzXl]="8">
      
                  <nz-descriptions
                      nzTitle="Fakturadetaljer"
                      nzBordered
                      [nzColumn]="1"
                    >
                    <nz-descriptions-item nzTitle="Forfallsdato"><div class="text-right">{{ booking.invoice.dueDateFpAsDate}}</div></nz-descriptions-item>
                    <nz-descriptions-item nzTitle="KID nr"><div class="text-right">{{ booking.invoice.kid}}</div></nz-descriptions-item>
                    <nz-descriptions-item nzTitle="Beløp"><div class="text-right">{{ booking.invoice.amountDouble | nf }}</div></nz-descriptions-item>
                    <nz-descriptions-item nzTitle="Bankkontonr"> <div class="text-right">{{ booking.invoice.bankAccountDisplay }}</div></nz-descriptions-item>
                  
                  </nz-descriptions>
              
                </div>
              </div>
              
            </div>

           

            <div class="m-d"></div>
            <div class="text-right">
              <button nz-button nzType="primary" (click)="$event.preventDefault(); nextStep()">Fullfør</button>
            </div>
          </div>

          <div *ngIf="booking.isPmCreditCard">
            <p>
              Fullfør betalingen med Vipps eller bank/kredittkort.
            </p>

            <btlib-iframe [src]="booking.paymentUrlApi" [autoResize]="true"></btlib-iframe>

          </div>


        </div>
        <!-- <btlib-form-row [controls]="[ 
        
        
        ]" [form]="edit.form"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row>  -->
    

        <!-- <button nz-button nzType="default" (click)="$event.preventDefault(); prevStep();">Tilbake</button>
        <button nz-button nzType="default" (click)="$event.preventDefault(); nextStep()">Gå videre</button> -->
        
      </div>
      <div *ngIf="step == 4">
        <h2 *ngIf="apps.bas.ui.r.ltesm">Ferdig</h2>

        <h1>Takk for bestillingen</h1>
        <h3></h3>

        <p>Vi har sendt deg en bekreftelse på epost.</p>
        <!-- <button nz-button nzType="default" (click)="$event.preventDefault(); prevStep();">Tilbake</button>
        <button nz-button nzType="default" (click)="$event.preventDefault(); nextStep()">Gå videre</button> -->
        
      </div>

    </form>
  </div>

     



  <nz-modal 
    [(nzVisible)]="edit.showEula" 
    [nzTitle]="'Avtalevilkår' | actrans | str" 
  
    (nzOnOk)="edit.showEula = false; "
    (nzOnCancel)="edit.showEula = false; "
    (nzAfterClose)="scrollToTop();"
    nzWidth="80vw" 
    >
    <ng-container *nzModalContent>
      
      <p [innerHtml]="'web.eula.eula' | actrans">

      </p>
    </ng-container>
  </nz-modal>
  <!--  nzWidth="90vw"  -->


</div>
</nz-spin>
</bt-page-wrapper>
