import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MiscUtil } from '../../../../lib/core/util/misc.util';

import { AppService } from '../../../../services/app.service';

@Component({
  selector: 'app-widget-order-products-page',
  templateUrl: './widget-order-products-page.component.html',
  styleUrls: ['./widget-order-products-page.component.scss']
})
export class WidgetOrderProductsPageComponent implements OnInit {

  route:ActivatedRoute;
  ctx:any = {
    queryParams: { },
    products: []
  };
  		

  // style:any = {
  //   primaryColor: undefined
  // };
  
  constructor(public injector:Injector, public apps:AppService) {
    this.route = injector.get(ActivatedRoute);
    this.apps.bas.us.checkActivatedRoute(this.route.snapshot);

    if (this.apps.bas.envtest) console.log("WidgetOrderProductsPageComponent");

    this.ctx.queryParams = this.route.snapshot.queryParams;

    this.ctx.missingImgUrl = this.apps.bas.ws.getBaseUrlStatic() + '/img/misc/missing_image_400x300.png';
	
    // this.apps.bas.style.getQueryParamStyle(this.style, this.route);
    // this.apps.bas.style.setCustomStyle(this.style);
    //this.apps.bas.style.updateCustomStyle(this.route);
    // http://192.168.1.82:8200/api/faerdertonsberg365/widget/order/products?style-custom-color-primary=%23F1BE48
    
  }

  ngOnInit(): void {
    this.apps.bas.acs.productsGet(this.ctx);
  } 

}
