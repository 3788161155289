import { Component, HostListener, Injector, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MiscUtil } from 'src/app/lib/core/util/misc.util';
import { MbscEventcalendarOptions, MbscEventcalendarView, Notifications, MbscCalendarEvent , localeNo } from '@mobiscroll/angular-ivy';

import { AppService, APP_DATAID } from '../../../services/app.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-widget-event-calendar-page',
  templateUrl: './widget-event-calendar-page.component.html',
  styleUrls: ['./widget-event-calendar-page.component.scss']
})
export class WidgetEventCalendarPageComponent implements OnInit {

 
  route: ActivatedRoute;
  resizeSubject = new Subject<any>();

    // myName:string = "";
  // myBirthday:Date = new Date();
  myEvents: MbscCalendarEvent[] = [
    {
      // base properties
      title: 'Inngangsbillett',
      color: '#3131e2',
      start: new Date(2021, 8, 19, 10),
      end: new Date(2021, 8, 19, 18),
      // add any property you'd like
      busy: true,
      description: 'Weekly meeting with team',
      location: 'Office',
      recurring: {
        repeat: 'daily',
        until: '2021-11-26',
        interval: 1
    },
  },
  {
    // base properties
    title: 'Gaupegårdsbesøk',
    color: '#56ca70',
    start: new Date(2021, 8, 19, 13),
    end: new Date(2021, 8, 19, 14),
    recurring: {
      repeat: 'weekly',
      until: '2021-11-26',
      weekDays: 'MO,WE,SA',
      interval: 1
  },
    // add any property you'd like
    busy: true,
    description: 'Weekly meeting with team',
    location: 'Office'
  },
 
  ];

  view : MbscEventcalendarView = {
    calendar: {
      //popover: true,  // TOOD: på mpbil?
      //labels: false
    },
    agenda: { 
      // type: 'week' 
      type: 'day'
    }
  }
  eventSettings: MbscEventcalendarOptions = {
        locale: localeNo,
        // theme: 'ios',
        themeVariant: 'light',
        clickToCreate: false,
        dragToCreate: false,
        dragToMove: false,
        dragToResize: false,
        // height: '70vh',
        view: this.view,
        marked: this.myEvents,
        // responsive: {
        //   xsmall: {
        //       view: {
        //           calendar: {
        //               type: 'week',
        //               labels: true,
        //               popover: true,
        //           },
        //           // agenda: {
        //           //     type: 'week'
        //           // }
        //       }
        //   },
        //   custom: { // Custom breakpoint
        //     breakpoint: 768,
        //     view: {
        //         calendar: { 
        //             type: 'month',
        //             labels: true,
        //             popover: true,
        //         },
        //         // agenda: {
        //         //     type: 'week'
        //         // }
        //     }
        //   }
        // },
        onEventClick: (event, inst) => {
            // this.notify.toast({
            //     message: event.event.title
            // });
            let title:string = event.event.title || "";
            this.message.info(
              title
          );
        }
    };


  
    constructor(public injector:Injector, public apps:AppService, private notify: Notifications, private message: NzMessageService) {
      this.route = injector.get(ActivatedRoute);
      this.apps.bas.us.checkActivatedRoute(this.route.snapshot);
  
      if(this.apps.bas.envtest) console.log(MiscUtil.getLogText("OrderProductWidgetPageComponent, route.snapshot: ")); // , this.route.snapshot
  
  
      // this.apps.bas.ws.json({ 
      //   aType: "appService",
      //   action: "getPwCart",
      //   product: this.route.snapshot.queryParams.product
      // }, { }).toPromise().then((res) => {
  
      //   if(this.apps.bas.envtest) console.log("DemoWidgetPageComponent.getPwCart: ", res);
      //   if (res.success) {
      //     this.cart = res.cart;
      //     this.pw0 = this.cart.items[0];
      //   }
  
      // }).catch((err) => {
      //   if(this.apps.bas.envtest) console.log("DemoWidgetPageComponent.getPwCart, err ", err);
      // });
  
      this.resizeSubject.pipe(
        debounceTime(50)
      ).subscribe((resizeEvent) => {
        this.doResize(resizeEvent);
      });

  
    }
  
  ngOnInit(): void {
    this.onResize();
  }
  

  @HostListener('window:resize', ['$event'])
  onResize(event?:any) {
    // console.log(MiscUtil.getLogText("onResize", true)); 
    this.resizeSubject.next(event);
  }

  doResize(event?:any) {  

    if(this.apps.bas.envtest) console.log(MiscUtil.getLogText("doResize", true) + ", w: " + window.innerWidth); 
    let height =  window.innerHeight;
    let width = window.innerWidth;


    let labelsValue = width >= 768;
    if (this.view.calendar && this.view.calendar.labels != labelsValue) {
      if(this.apps.bas.envtest) console.log("Setting view.calendar.labels: " + labelsValue); 
      this.view.calendar.labels = width > 768;
      this.view = MiscUtil.extend({ }, this.view);
    }


  }

}
