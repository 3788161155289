import { Component, HostListener, Injector, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MiscUtil } from 'src/app/lib/core/util/misc.util';
import { MbscEventcalendarOptions, Notifications, MbscCalendarEvent , localeNo } from '@mobiscroll/angular-ivy';

import { AppService, APP_DATAID } from '../../../services/app.service';
import { BtEvent } from 'src/app/lib/core/services/event.service';
import { DATAID } from 'src/app/lib/core/services/data.service';


@Component({
  selector: 'app-widget-order-product-page',
  templateUrl: './widget-order-product-page.component.html',
  styleUrls: ['./widget-order-product-page.component.scss']
})
export class WidgetOrderProductPageComponent implements OnInit {


  route: ActivatedRoute;

  //cart:any = { };
  pw0:any;

  checkout:boolean = false;
  // step = 0;


  constructor(public injector:Injector, public apps:AppService, private notify: Notifications) {
    this.route = injector.get(ActivatedRoute);
    this.apps.bas.us.checkActivatedRoute(this.route.snapshot);

    if(this.apps.bas.envtest) console.log(MiscUtil.getLogText("OrderProductWidgetPageComponent, route.snapshot: ")); // , this.route.snapshot

    // this.updatePw();
    this.pw0 = { productId: this.route.snapshot.queryParams.product };

    this.apps.bas.es.on(BtEvent.GOTO_CHECKOUT, () => {

      this.checkout = true;
    });

    this.apps.bas.es.on(BtEvent.GO_BACK, () => {

      this.checkout = false;
    });

  }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    //console.log("ngOnChanges, changes: ", changes);
  }





  goHome() {
    this.checkout = false;
  }


  test1() {
    this.apps.bas.es.sendParentMessage({ action: "test1" });

  }


}
