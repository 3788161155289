import { Component, HostListener, Injector, OnInit } from '@angular/core';
// import { ActivatedRoute } from '@angular/router';
import { AppService } from './services/app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'cbapi5';

  constructor(public injector:Injector, public apps:AppService) {
   
  }

  ngOnInit(): void {
    // if(this.apps.bas.envtest) console.log("AppComponent.ngOnInit");
    // let route = this.injector.get(ActivatedRoute);
    // console.log("AppComponent.route: ", route.snapshot.queryParams);
    // this.apps.bas.style.updateCustomStyle(route);
  }

  @HostListener('window:message', ['$event'])
  onMessage(event:any) {
    let ev = this.apps.bas.es.onMessage(event);
    
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?:any) {
    this.apps.bas.ui.onResize(event);
  }

}
