<div class="text-center">



  <button nz-button nzType="primary" (click)="login()">login</button>
  <button nz-button nzType="default" (click)="checkLogin()">checkLogin</button>
  <button nz-button nzType="default" (click)="changeTheme()">change theme</button>

  <!-- <button class="py-2 px-4 font-semibold rounded-lg shadow-md text-white bg-primary hover:bg-primary">
    Click me
  </button> -->


    <!-- <p>

        <button (click)="moreRows = !moreRows">Add Rows</button>
    </p>
    <p>APP test works!</p>
    <p>APP test works!</p>
    <p>APP test works!</p>
    <p>APP test works!</p>
    <p>APP test works!</p>
    <p>APP test works!</p>
    <p>APP test works!</p>
    <p>APP test works!</p>
    <p>APP test works!</p>
    <p>APP test works!</p>
    <p>APP test works!</p>
    <p>APP test works!</p>
    <p>APP test works!</p>
    <p>APP test works!</p>
    <p>APP test works!</p>
    <p>APP test works!</p>
    
   

    <div *ngIf="moreRows">
      <p>APP test works!</p>
    <p>APP test works!</p>
    <p>APP test works!</p>
    <p>APP test works!</p>
   
      <p>APP test works!</p>
      <p>APP test works!</p>
      <p>APP test works!</p>
      <p>APP test works!</p>
      <p>APP test works!</p>
      <p>APP test works!</p>
      <p>APP test works!</p>
      <p>APP test works!</p>
      <p>APP test works!</p>
      <p>APP test works!</p>
      <p>APP test works!</p>
      <p>APP test works!</p>
      <p>APP test works!</p>
      <p>APP test works!</p>
      <p>APP test works!</p>
      <p>APP test works!</p>
      <p>APP test works!</p>
      <p>APP test works!</p>
      <p>APP test works!</p>
      <p>APP test works!</p>
      <p>APP test works!</p>
      <p>APP test works!</p>
      <p>APP test works!</p>
      <p>APP test works!</p>
      <p>APP test works!</p>
      <p>APP test works!</p>
      <p>APP test works!</p>
      <p>APP test works!</p>
    </div> -->
    
</div>

