import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MiscUtil } from 'src/app/lib/core/util/misc.util';

import { AppService } from 'src/app/services/app.service';
import { DynformControl } from '../../../../../lib/core/model/dymform-control';

@Component({
  selector: 'app-widget-order-view-root-page',
  templateUrl: './widget-order-view-root-page.component.html',
  styleUrls: ['./widget-order-view-root-page.component.scss']
})
export class WidgetOrderViewRootPageComponent implements OnInit {

  route:ActivatedRoute;
  // order:any = {
  //   form: undefined,
  //   controls: [],
  //   obj: undefined,
  // };

  style:any = {
    view: "default",
    // bgcolor: undefined,
    mkGuests: undefined,
    mkDate: undefined,
    mkButton: undefined,
  }

  views:any = {
    default: {
      grid: {
        date:  { xs: 24, sm: 24, md: 24, lg: 12, xl: 12,  xxl: 12,  gutter: 16 },
        guests:   { xs: 24, sm: 24, md: 24, lg: 12, xl: 12,  xxl: 12,  gutter: 16 },
        button:   { xs: 24, sm: 24, md: 24, lg: 12, xl: 12,  xxl: 12,  gutter: 16 },
      },
      size: "default"
    },
    fullWidth: {
      grid: {
        date:  { xs: 14, sm: 8, md: 8, lg: 6, xl: 4,  xxl: 4,  gutter: 16 },
        guests:  { xs: 10, sm: 8, md: 6, lg: 5, xl: 4,  xxl: 3,  gutter: 16 },
        button:  { xs: 24, sm: 8, md: 6, lg: 5, xl: 4,  xxl: 3,  gutter: 16 },
      },
      size: "large"
    }
  };
  view = this.views.default;
// [nzXs]="14" [nzSm]="8" [nzMd]="8" [nzLg]="6" [nzXl]="4" [nzXXl]="4" 
//  [nzXs]="10" [nzSm]="8" [nzMd]="6" [nzLg]="5" [nzXl]="4" [nzXXl]="3"  
//  [nzXs]="24" [nzSm]="8" [nzMd]="6" [nzLg]="5" [nzXl]="4" [nzXXl]="3" 
  
  pw:any;
  dateInvalid:Date[] = [];
  guestsPopupVisible = false;

  constructor(public injector:Injector, public apps:AppService) {
    this.route = injector.get(ActivatedRoute);
    this.apps.bas.us.checkActivatedRoute(this.route.snapshot);

    if(this.apps.bas.envtest) console.log("WidgetOrderViewRootPageComponent");

    let t = this.style;
    for (let key of Object.keys(t)) {
      t[key] = this.route.snapshot.queryParams[key]  || t[key];
    }

    if (t.mkGuests === undefined) t.mkGuests = this.apps.bas.ui.actrans("bus.booking.guests");
    if (t.mkDate === undefined) t.mkDate = this.apps.bas.ui.actrans("app.cbapi5.common.selectDate", [], false, "Velg dato");
    if (t.mkButton === undefined) t.mkButton = this.apps.bas.ui.actrans("common._order", [], false, "Bestilling");


    
    this.view = this.views[t.view];
    // this.apps.bas.style.updateCssVars({
    //   "widget-bgcolor": t.bgcolor
    // })
    
    this.pw = { 
      productId: this.route.snapshot.queryParams.product 
    };

    //this.apps.bas.style.updateCustomStyle(this.route);

  }

  ngOnInit(): void {
    if(this.apps.bas.envtest) console.log("WidgetOrderViewRootPageComponent, pw: ", this.pw);
    this.updatePw();

  }

  openOrderPage() {
    let url = this.apps.bas.ws.getBaseUrl() 
      + this.pw.productObj.url
      + "?startDateAsString=" + this.pw.startDateAsString
      + "&guestCountsAsString=" + this.pw.guestCounts.guestCountsAsString
    ;

    if(this.apps.bas.envtest) console.log("openOrderPage, url: " + url);

    if (this.route.snapshot.queryParams.newWindow) {
      // this.apps.bas.us.
      window.open(url, '_blank');
      return;
    }

    this.apps.bas.es.sendParentMessage({ action: "redirect", target: url });
  }

  dateOnPageLoading(event:any) {
    // console.log("dateOnPageLoading, ", event, ", invalid: ", this.dateInvalid);
    this.dateUpdateInvalid(event);
  }

  dateOnChange(event:any) {
    if(this.apps.bas.envtest) console.log("dateOnChange, startDateAsString: " + this.pw.startDateAsString
      + ", startDateAsJsDate: " + this.pw.startDateAsJsDate + ", event: ", event);
    this.pw.startDateAsString = this.apps.bas.ui.dateToString(this.pw.startDateAsJsDate);
    
    // this.change.emit({ field: "startDateAsString"});
  }

  dateUpdateInvalid(event:any) {
    let inst = event.inst;
    let fd = event.firstDay;
    let ld = event.lastDay;


    let invalid:any[] = [];
    
    let pw = this.pw;

    let dates:Date[] = this.apps.bas.ui.getAllDatesBwtweenDates(fd, ld);

    for (let current of dates) {
      let dateInt = MiscUtil.getDateAsInt(current);
      
      if (pw.selectableDatesNext && pw.selectableDatesNext.length) {
        if (MiscUtil.binarySearch(pw.selectableDatesNext, dateInt) < 0) {
          //console.log("current: " + current + " -> dateInt: " + dateInt + ", binarySearch: " + MiscUtil.binarySearch(pw.selectableDatesNext, dateInt) + ", pw: ", pw);

          invalid.push(current);
        }
      } else if (((pw.selectableDates && pw.selectableDates.length) || pw.productObj.timeProduct)) {
        if (MiscUtil.binarySearch(pw.selectableDates, dateInt) < 0)  invalid.push(current);
      } else if (pw.productObj.periodProduct && pw.occupiedDates) {
        if (MiscUtil.binarySearch(pw.occupiedDates, dateInt) >= 0)  invalid.push(current);
      }
    }

    //console.log("Setting dateInvalid: " + this.dateInvalid.length + " -> " + invalid.length);
    this.dateInvalid = invalid;


  }

  //
  incrementGcValue(gcIdx:number) { this.setGcValue(gcIdx, this.pw.guestCounts.map[gcIdx] + 1); }
  decrementGcValue(gcIdx:number) { this.setGcValue(gcIdx, this.pw.guestCounts.map[gcIdx] - 1); }
  setGcValue(gcIdx:number, value:number) {
    if (value < 0) return;

    let maxGc = this.pw.productObj.maxGcMap[gcIdx];
    if (maxGc === "") maxGc = 100;
    maxGc = Math.min(maxGc, this.pw.maxGuests);


    if (maxGc < value) return;

    this.pw.guestCounts.map[gcIdx] = value;
    MiscUtil.updateGuestCountsFromMap(this.pw.guestCounts);

    //TODO: sjekke total antall mot maxGuests?


  }

  guestsPopupClose() {
    this.guestsPopupVisible = false;
    this.guestsPopupVisibleChange(false);
  }
  guestsPopupVisibleChange(event:any) {
    if(this.apps.bas.envtest) console.log("guestsPopupVisibleChange, ev: ", event);
    if (event === false) {
      // this.change.emit({ field: "guestCounts", value: this.pw.guestCounts });
      // this.updatePw();

      this.apps.bas.es.sendParentMessageResize();
    }
  }

  getGcs() {
    if (!this.pw) return "";
    let gcs = this.pw.guestCounts.guestCountsAsString.split(",").join(" + ");
    return gcs;
  }


  updatePw() {
    let pw = this.pw ;
    if(this.apps.bas.envtest) console.log("updatePw, pw: ", pw);

    var params:any = {
      aType: "appService",
      action: "getPwCart",
      product: pw.productId
    }

    if (pw.startDateAsString) params.startDateAsString = pw.startDateAsString;
    if (pw.guestCounts) params.guestCountsAsString = pw.guestCounts.guestCountsAsString;
    if (pw.timePeriodAsString) params.timePeriodAsString = pw.timePeriodAsString;

    this.apps.bas.ws.json(params, { }).then((res) => {

      if(this.apps.bas.envtest) console.log("OrderProductWidgetPageComponent.getPwCart: ", res);
      if (res.success) {
        // this.cart = res.cart;
        let pw = res.cart.items[0];
        pw.startDateAsJsDate = this.apps.bas.ui.stringToDate(pw.startDateAsString);
        for (let gcIdx in pw.guestCategories) {
          if (pw.guestCounts.map[gcIdx] === undefined) pw.guestCounts.map[gcIdx] = 0; 
        }
        this.pw = pw;
      }

    }).catch((err) => {
      if(this.apps.bas.envtest) console.log("OrderProductWidgetPageComponent.getPwCart, err ", err);
    });
  }





  // updateOrderForm(order:any) { // { start, end, resource }

  //   let controls: DynformControl[] = [];

  //   controls.push(new DynformControl({ key: 'product' }));
  //   // controls.push(new DynformControl({ key: 'comment', mk: 'common.comment', controlType: 'textarea' }));

  //   controls.push(new DynformControl({ 
  //     key: 'startDate',         
  //     mk: 'common.period', 
  //     controlType: "date-picker",
  //     onChange: (event:any) => {
  //       if(this.apps.bas.envtest) console.log("period.onChange: ", event);
  //       this.orderRefresh();
  //     }, data: { 
  //       readOnly: this.apps.bas.ui.r.xs
  //      } 
  //    }));

  //    controls.push(new DynformControl({ 
  //      key: 'availability',
  //      mk: 'web.common.overview.availabilityOrder',
  //      controlType: 'input',
  //      type: 'number'
  //    }));

  //   // controls.push(new DynformControl({ key: 'showEmptyResources',               mk: 'web.common.overview.showEmptyResources' }));
   

  //   let obj = {
  //     product: event.resource,
  //     period: [ event.start, event.end ],
  //     availability: 0,
  //     comment: ""
  //   };
  //   this.apps.bas.fs.updateFormObj(this.newEvent, controls, obj);
  //   this.adminOrderRefresh(true);
  // }

  // orderRefresh(updatePeriod:boolean = false) {
  //   let rv = this.apps.bas.fs.getRawValue(this.newEvent.form, this.newEvent.controls, true);

  //   this.apps.bas.ws.json({
  //     actionType: "appAdmin",
	// 		action: "adminOrderRefresh",
  //     product: rv.product,
  //     period: rv.period

  //   }).then((json:any) => {
  //     if(this.apps.bas.envtest) console.log("adminOrderRefresh.then, json: ", json);
  //     if (json.success) {
  //       let pw = json.pw;
  //       let form = this.newEvent.form;
  //       let ctrls = this.newEvent.controls;

  //       if (updatePeriod) {
  //         let fcvPeriod = this.apps.bas.fs.toFormControlValue(ctrls.period, pw.periodAsRange);

  //         if(this.apps.bas.envtest) console.log("Updating period: ", fcvPeriod);
  //         form.controls.period.setValue(fcvPeriod)
  //       }

  //       this.newEvent.pw = pw;


  //     }

      

  //    });
  // }

  // orderFormSubmit() {
  //   let rv = this.apps.bas.fs.getRawValue(this.newEvent.form, this.newEvent.controls);
  //   if (rv === false) return;
    
  //   if(this.apps.bas.envtest) console.log("newEventSubmit, rv: ", rv);

  //   let params:any = {
  //     actionType: "appAdmin",
	// 		action: "adminOrderSaveAvailability",
  //   };
  //   MiscUtil.extend(params, rv);

  //   this.apps.bas.ws.json(params).then((json:any) => {
  //     if(this.apps.bas.envtest) console.log("adminOrderSaveAvailability.then, json: ", json);
  //     if (json.success) {
        
  //       this.apps.bas.ui.success("Lagret!"); 

  //       this.myEvents = [ ...this.myEvents, json.event ];

  //       this.newEvent.obj = undefined;

  //     }

      

  //    });

  // }

}

