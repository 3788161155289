import { Component, Input, OnInit } from '@angular/core';
import { MiscUtil } from 'src/app/lib/core/util/misc.util';

import { AppService, APP_DATAID } from '../../../../../services/app.service';
import { DATAID } from '../../../services/data.service';

@Component({
  selector: 'btlib-order-product',
  templateUrl: './order-product.component.html',
  styleUrls: ['./order-product.component.scss']
})
export class OrderProductComponent implements OnInit {

  

  @Input() pw:any;


  constructor(public apps:AppService) {
    if(this.apps.bas.envtest) console.log("OrderProductComponent, pw: ", this.pw);
    
  }
  

  ngOnInit(): void {
    if(this.apps.bas.envtest) console.log("OrderProductComponent.ngOnInit, pw: ", this.pw);
    this.updatePw();

  }

  onChange(event:any) {
    if(this.apps.bas.envtest) console.log("onChange, event: ", event, ", pw: ", this.pw);
    this.updatePw();
  }

  updatePw() {
    let pw = this.pw ;
    if(this.apps.bas.envtest) console.log("updatePw, pw: ", pw);

    var params:any = {
      aType: "appService",
      action: "getPwCart",
      product: pw.productId
    }

    if (pw.startDateAsString) params.startDateAsString = pw.startDateAsString;
    if (pw.guestCounts) params.guestCountsAsString = pw.guestCounts.guestCountsAsString;
    if (pw.timePeriodAsString) params.timePeriodAsString = pw.timePeriodAsString;

    this.apps.bas.ws.json(params, { }).then((res) => {

      if(this.apps.bas.envtest) console.log("OrderProductComponent.getPwCart: ", res);
      if (res.success) {
        // this.cart = res.cart;
        let pw = res.cart.items[0];
        pw.startDateAsJsDate = this.apps.bas.ui.stringToDate(pw.startDateAsString);
        for (let gcIdx in pw.guestCategories) {
          if (pw.guestCounts.map[gcIdx] === undefined) pw.guestCounts.map[gcIdx] = 0; 
        }
        this.pw = pw;
      }

    }).catch((err) => {
      if(this.apps.bas.envtest) console.log("OrderProductComponent.getPwCart, err ", err);
    });
  }



  addToCart() {
    if(this.apps.bas.envtest) console.log("addToCart, pw: ", this.pw);
    var cart = this.apps.bas.ds.getValue(APP_DATAID.ORDER_CART);
    if (!cart.items?.length) {
      cart = { id: MiscUtil.generatePassord(32), items: [] };
    }

    cart.items.push(this.pw);
    this.apps.bas.ds.save(APP_DATAID.ORDER_CART, cart, true);
  }


}
