import { Component, Injector, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MiscUtil } from 'src/app/lib/core/util/misc.util';

import { AppService } from 'src/app/services/app.service';

import { DynformControl } from 'src/app/lib/core/model/dymform-control';

@Component({
  selector: 'bt-user-delete-user',
  templateUrl: './bt-user-delete-user.component.html',
  styleUrls: ['./bt-user-delete-user.component.scss']
})
export class BtUserDeleteUserComponent implements OnInit {

  @Input() 
  get user(): any { return this.edit.obj; }
  set user(user: any) {
    // console.log("user.set: ", user);
    if (user == undefined) return;
    this.editObject(user);
  }

  @Output() userChange = new EventEmitter<any>();


  edit:any = {
    visible: false,
    obj: undefined,
   
  }
  
  constructor(public injector:Injector, public apps:AppService) {

    if(this.apps.bas.envtest) console.log("BtUserDeleteUserComponent");

  }

  ngOnInit(): void {

  }

  editObject(obj:any) {

    if(this.apps.bas.envtest) console.log("editObject, obj: ", obj);

    let isAdmin = this.apps.bas.aas.isAdmin();

    let controls: DynformControl[] = [];

    controls.push(new DynformControl({ 
      key: 'userId',
      valuePath: 'id' 
    }));

    controls.push(new DynformControl({ 
      key: 'confirmDelete', 
      mk: 'web.common.user.confirmDeleteDesc', 
      controlType: "checkbox",
      required: true
    }));

    if (isAdmin) {
      controls.push(new DynformControl({ 
        key: 'force', 
        mk: 'web.common.user.forceDeleteDesc', 
        controlType: "checkbox"
      }));
    }





    this.apps.bas.fs.updateFormObj(this.edit, controls, obj);
    // this.edit.visible = true;
  } 

  onChange(event:any) {

  }

  onCancel() {
    // this.edit.visible = false;
    this.edit.obj = undefined;
    this.userChange.next(this.edit.obj);
  }
  onOk() {

    let rv = this.apps.bas.fs.getRawValue(this.edit.form, this.edit.controls);
    if (rv === false) return;
    

    let obj = this.edit.obj;

    let params:any = {
      aType: "appAdmin", 
      action: "deleteUser"
    };
    MiscUtil.extend(params, rv);


    if(this.apps.bas.envtest) console.log("onOk, params: ", params);


    this.apps.bas.ws.json(params).then((json) => {
      if (json.success) {
        this.apps.bas.ui.success(json.statusDesc); 

        this.userChange.next(this.edit.obj);
        
        this.edit.obj = undefined;
      }
    });
    

    // this.edit.visible = false;
  }



}
