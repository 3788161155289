import { AfterViewInit, Component, HostListener, Injector, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DynformControl, DynformControlsMap } from 'src/app/lib/core/model/dymform-control';
import { DATAID } from 'src/app/lib/core/services/data.service';
import { MiscUtil } from 'src/app/lib/core/util/misc.util';
import { upperCase } from 'src/app/lib/core/util/const';

import { AppService } from 'src/app/services/app.service';

import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { NzUploadChangeParam, NzUploadFile, NzUploadXHRArgs } from 'ng-zorro-antd/upload';
import { Observable, Subscription } from 'rxjs';
import { BtGoogleMapsComponent } from 'src/app/lib/core/component/ui/bt-google-maps/bt-google-maps.component';
import { ComponentCanDeactivate } from 'src/app/lib/core/guards/pending-changes.guard';


@Component({
  selector: 'app-admin-product-page',
  templateUrl: './admin-product-page.component.html',
  styleUrls: ['./admin-product-page.component.scss']
})
export class AdminProductPageComponent implements OnInit, AfterViewInit, ComponentCanDeactivate {

  router: Router;
  route: ActivatedRoute;
  fb: UntypedFormBuilder;
  
  productForm!: UntypedFormGroup;
  controls: DynformControlsMap = { };

  imgForm!: UntypedFormGroup;
  imgControls: DynformControlsMap = { };

  p:any = undefined;
  fbo:any;

  // xs: '(max-width: 575px)',
  // sm: '(min-width: 576px)',
  // md: '(min-width: 768px)',
  // lg: '(min-width: 992px)',
  // xl: '(min-width: 1200px)',
  // xxl: '(min-width: 1600px)'

  gridFull = { xs: 24, gutter: 16 };
  gridXl = { xs: 24, sm: 24, md: 24, lg: 24, xl: 16, xxl: 16, gutter: 16 };
  gridLg = { xs: 24, sm: 24, md: 12, lg: 12, xl: 8, xxl: 8, gutter: 16 };
  gridMd = { xs: 24, sm: 12, md: 12, lg: 12, xl: 8, xxl: 6, gutter: 16 };
  gridSm = { xs: 24, sm: 12, md: 8, lg: 6, xl: 4, xxl: 4, gutter: 16 };
  gridXs = { xs: 12, sm: 8, md: 8, lg: 6, xl: 4, xxl: 4, gutter: 16 };


  isSpinning = true;
  dirty = false;
  changes:any = { };
  lang:string = "no";
  // logText:string = "";

  Sections = {
    // "all"           : { id: "all",            title: "common.all",                        icon: "expand"},
    "basic"         : { id: "basic",          title: "app.lib.common.basic",              icon: "setting"}, // setting
    "accommodation" : { id: "accommodation",  title: "common.accommodation",              icon: "home"},
    "nameLeadBody"  : { id: "nameLeadBody",   title: "app.cbapi5.page.admin.product.nameLeadBody",  icon: "translation"},
    "guests"        : { id: "guests",         title: "app.lib.common.guests",             icon: "user"},
   
    "actEvent"      : { id: "actEvent",       title: "app.cbapi5.common.actEvent",        icon: "thunderbolt" },
    "advanced"      : { id: "advanced",       title: "common.advanced",                   icon: "rocket" },
   
    "prices"        : { id: "prices",         title: "bus.product.prices",                icon: "dollar" },
    "images"        : { id: "images",         title: "bus.product.images",                icon: "picture" },
   
    "times"         : { id: "times",          title: "bus.product.times",                 icon: "clock-circle" },
    "periods"       : { id: "periods",        title: "bus.product.periods",               icon: "calendar" },
    "routeItems"    : { id: "routeItems",     title: "bus.product.routeItems",            icon: "swap" },
    
    "units"         : { id: "units",          title: "web.common.product.units",          icon: "aim" },
    "accessories"   : { id: "accessories",    title: "bus.product.accessories",           icon: "appstore" },
    "related"       : { id: "related",        title: "bus.productRelation.type.relation", icon: "build" },
    "packageItems"  : { id: "packageItems",   title: "bus.productRelation.type.package",  icon: "borderless-table" },
    "groupItems"    : { id: "groupItems",     title: "bus.productRelation.type.group",    icon: "aim" },
    
    "properties"    : { id: "properties",     title: "common.properties",                 icon: "table" }, // 
    "files"         : { id: "files",          title: "common.files",                      icon: "download" },
    "input"         : { id: "input",          title: "bus.product.inputs",                icon: "sliders" },
    // "task"          : { id: "task",           title: "common.tasks",                      icon: "carry-out" },
    "log"           : { id: "log",            title: "common.log",                        icon: "file" },
  }

  sectionRowExpanded = false;
  activeSection = this.Sections.basic.id;
  
  helpVisible = false;
  mapVisible = false;
  mapType:string = "";


  private mapMain: BtGoogleMapsComponent|undefined;

 @ViewChild('mapMain', { static: false }) set mapMainComponent(content: BtGoogleMapsComponent) {
    if (this.apps.bas.envtest) console.log("mapMainComponent, content: " + content);

    if(content) { // initially setter gets called with undefined
        this.mapMain = content;
    }
 }
  constructor(public injector:Injector, public apps:AppService) {
    this.router = injector.get(Router);
    this.route = injector.get(ActivatedRoute);
    this.apps.bas.us.checkActivatedRoute(this.route.snapshot);
    this.fb = injector.get(UntypedFormBuilder);

    if (this.apps.bas.envtest) console.log("AdminProductPageComponent, login.success: "+this.apps.bas.ds.login.success+", route: ", this.route.snapshot);

    if (this.apps.bas.ds.login.success) {
      
    
      this.route.params.subscribe(routeParams => {
        console.log("route.params.subscribe: ", routeParams);
        this.getProduct(routeParams.id);

      });
      
    } 
    else {

      //TODO: denne blir kalt flere ganger. 
      this.apps.bas.ds.get(DATAID.APP_LOGIN).subscribe((res) => {
        if (this.apps.bas.envtest) console.log("AdminProductPageComponent.LOGIN.updated, res: ", res)
        // window.location.reload();
        if (res.success) {
          this.getProduct(this.route.snapshot.params.id);
      
        }
     
      });
    }

   }

  ngOnInit(): void {
    if (this.apps.bas.envtest) console.log("AdminProductPageComponent.ngOnInit");
  }

  ngAfterViewInit(): void {
    if (this.apps.bas.envtest) console.log("AdminProductPageComponent.ngAfterViewInit");
  }



  edit:any = {
    image:    { controls: {},  obj: null, form: null, fileList: [] },
    input:    { controls: {},  obj: null, form: null },
    file:     { controls: {},  obj: null, form: null, fileList: [] },
    
    price:    { controls: {},  obj: null, form: null, ags: [] },
    //priceAmountGroup:    { controls: {},  obj: null, form: null },
    
    priceItem:      { controls: {},  obj: null, form: null, id: -1, idx: -1, objArray: true, mapName: "items", arrayName: "items", newItemField: "newItem" },
    manPeriodItem:  { controls: {},  obj: null, form: null, id: -1, idx: -1, objArray: true, mapName: "manPeriodItems", newItemField: "newManPeriodItem" },
    dailyRatesItem: { controls: {},  obj: null, form: null, id: -1, idx: -1, objArray: true, mapName: "dailyRatesItems", newItemField: "newDailyRatesItem", parentRowInfo: [] },
    priceRouteItem: { controls: {},  obj: null, form: null, id: -1, idx: -1, objArray: true, mapName: "routeItems", arrayName: "routeItems",  newItemField: "newRouteItem" },

    relation: { controls: {},  obj: null, form: null, mapName: "childMap", id: -1, subTypes: {
        "Accessory": { params: { typeAsString: "Accessory" }},
        "Relation": { params: { typeAsString: "Relation" }},
        "Package": { params: { typeAsString: "Package" }},
        "Group": { params: { typeAsString: "Group" }},
        "Pool": { params: { typeAsString: "Pool" }},
      }   
    },
    time:     { controls: {},  obj: null, form: null },
    routeItem:{ controls: {},  obj: null, form: null },
    period:   { controls: {},  obj: null, form: null },
    property: { controls: {},  obj: null, form: null, arrayName: "properties" },
    mapItem:  { controls: {},  obj: null, form: null },
    log:      { controls: {},  obj: null, form: null, id: -1, idx: -1, objArray: true,  mapName: "logs", newItemField: "newLog" },
    task:     { controls: {},  obj: null, form: null, id: -1, idx: -1, objArray: true,  mapName: "tasks", newItemField: "newTask" }
  }


  // common

  // onItemExpandChange(type:string, id: number, checked: boolean): void {
  //   let edit = this.edit[type];
  //   if (checked) {
  //     edit.expand.add(id);
  //   } else {
  //     edit.expand.delete(id);
  //   }
  // }

  cdkDropListDroppedItem(event: CdkDragDrop<string[]>, type:string, subType:string = "", parentObj:any = undefined) {
    if (this.apps.bas.envtest) console.log("cdkDropListDropped, event: ", event);
    const item = type + subType;
    let arrayName = this.edit[type].arrayName || item + "s";

    let hasMap = !this.edit[type].objArray; // parentObj !== undefined;
    if (!parentObj) parentObj = this.p;

    let array = parentObj[arrayName];

    moveItemInArray(array, event.previousIndex, event.currentIndex);
    this.changes[item + "SortOrder"] = array;
    this.changes[item + "SortOrder"] = hasMap 
      ?  array.map((e:any) => e.id)
      :  array;
    this.dirty = true;
  }
  
  moveItem(idx:number, up:boolean, type:string, subType:string = "", parentObj:any = undefined) {
    const item = type + subType;
    let arrayName = this.edit[type].arrayName || item + "s";
    
    let hasMap = parentObj !== undefined;
    if (!parentObj) parentObj = this.p;
    
    let array = parentObj[arrayName];

    moveItemInArray(array, idx, idx + (up ? -1 : 1));
    // this.changes[item + "SortOrder"] = array;
    this.changes[item + "SortOrder"] = hasMap 
      ?  array.map((e:any) => e.id)
      :  array;
    this.dirty = true;
  }
  async deleteItem(idx:number, type:string, subType:string = "", parentObj:any = undefined) {
    const item = type + subType;
    const arrayName = this.edit[type].arrayName || item + "s";

    // let hasMap = parentObj !== undefined;
    if (!parentObj) parentObj = this.p;

    let obj = parentObj[arrayName][idx];
    parentObj[arrayName] = parentObj[arrayName].filter((id:number, i:number) => i != idx);

    if (type == "log") {
      let jsonParams:any = { 
        actionType: "product", 
        action: "deleteLog",
        type: "product",
        id: obj.id
      };
  

      let json = await this.apps.bas.ws.json(jsonParams);
      if (!json) {
        //TODO
        if (this.apps.bas.envtest) console.log("!json, jsonParams: ", jsonParams);
  
        return;
      }


      return;
    }


   
    this.changes[item + "SortOrder"] = this.edit[type].objArray 
      ?  parentObj[arrayName].map((e:any) => e.id)
      :  parentObj[arrayName];

    this.dirty = true;

    if (this.apps.bas.envtest) console.log("deleteItem, changes: ", this.changes)
  }



  editItem(itemId:number|any, type:string, subType:string = "", parentObj:any = undefined) {

    const item = type + subType;
    // const arrayName = this.edit[type].arrayName || item + "s";
    const mapName = this.edit[type].mapName || item + "Map";

    
    if (!parentObj) parentObj = this.p;

    let isNum =  typeof itemId !== "object";
    let obj = isNum ? parentObj[mapName][itemId] : itemId;
  
    if ( this.edit[type].objArray && isNum) obj.idx = itemId;

    if (item === "price") {
      this.edit.price.ags = []; 
    }

    if (subType) this.edit[type].subType = subType;
    if (parentObj) this.edit[type].parentObj = parentObj;
    

    let controls = this.generateControls(item, obj);
    this.apps.bas.fs.updateControls(controls, obj);
    

    if (this.apps.bas.envtest) console.log("editItem, item: "+item+", type: " + type + ", obj: " , obj);


    this.edit[type].form = this.apps.bas.fs.toFormGroup(controls);
    this.edit[type].controls = this.apps.bas.fs.toMap(controls);
    this.edit[type].obj = obj;
  }


  saveItem(type:string, subType:string = "", parentObj:any = undefined, event:any = undefined) {
    const item = type + subType;
    // const arrName = this.edit[type].arrayName || item + "s";
    let mapName = this.edit[type].mapName || item + "Map";

    if (!parentObj) parentObj = this.p;

    
    let formValue = this.apps.bas.fs.getRawValue(this.edit[type].form, this.edit[type].controls);
    if (formValue === false) return false;
    
    let arr:any[] = this.changes[ item + "s"] = this.changes[ item + "s"] || [];

    let currentIdx = arr.findIndex((elem:any) => elem.id === formValue.id);

    if (currentIdx < 0) arr.push(formValue);
    else arr[currentIdx] = formValue;

    this.dirty = true;

    let obj =  this.edit[type].obj;
    this.edit[type].obj = null;
    if (this.edit[type].subType) this.edit[type].subType = undefined;

    let objClone = MiscUtil.clone(obj);

    MiscUtil.extend(obj, formValue);

    if (this.apps.bas.envtest) console.log("saveItem, item: "+item+": ", formValue, ", obj: ", obj);

    if (item == "input") {
      obj.mkName = formValue.messages.name[this.lang];
      let type = this.apps.bas.us.listToMap(this.fbo.inputTypes)[formValue.type];
      obj.mkType = type.mkName;
    } else  if (item == "property") {
      
      obj.type = objClone.type;
      obj.label = formValue.messages.messageValue.no 
        || formValue.doubleValue
        || formValue.integerValue
        || formValue.stringValue
        || formValue.value
        || formValue.booleanValue
      ; 

      if (typeof formValue.booleanValue == "boolean") obj.label = this.apps.bas.ui.actrans("common." + formValue.booleanValue);




    } else  if (item == "image") {
      // obj.name = formValue.name;

    } else  if (item == "file") {
      // obj.name = formValue.name;

    } else  if (item == "price") {
      obj.name = formValue.name;
      obj.mkType = this.apps.bas.us.listToMap(this.fbo.priceTypes)[formValue.type].mkName;
      obj.mkGroup = this.fbo.priceGroups ? this.fbo.priceGroups[formValue.group].label : "";

      // <div>{{ p.priceMap[itemId].startDateAsDate }} - {{ p.priceMap[itemId].endDateAsDate }}</div>
    } else  if (item == "priceItem") {
      // obj.name = formValue.name;
      // obj.order = formValue.order;
      obj.datePeriodDisplay = formValue.datePeriodAsRange.split("_").join(" - ");
      obj.daysRangeDisplay = formValue.daysAsRange.split("_").join(" - ");

      obj.timeRangeDisplay = formValue.startTimeAsString ? formValue.startTimeAsString + " - " + formValue.endTimeAsString : "";
      obj.timeDurationRangeDisplay = formValue.timeDurationAsRange.split("_").join(" - ");
      obj.mkGuestCategory = this.p.guestCategories[formValue.guestCategory] ;
      obj.totaltGuestsRangeDisplay = formValue.totalGuestsAsRange.split("_").join(" - ");
      obj.ruleId = formValue.rule;
      obj.rule = this.fbo.priceRules[formValue.rule].mkName;
      // obj.amountDouble = formValue.amountDouble;
      
      let wlm = this.apps.bas.us.listToMap(obj.weekdaysList, "weekday");
      for (let wd in formValue.weekdaysMap) {
        wlm[wd].enabled = formValue.weekdaysMap[wd];
        if (!wlm[wd].enabled) obj.isWeekdaysAll = false;
      }
      

    } else  if (item == "priceRouteItem") {
      // obj.name = formValue.name;
      // obj.order = formValue.order;
      obj.datePeriodDisplay = formValue.datePeriodAsRange.split("_").join(" - ");
      // obj.daysRangeDisplay = formValue.daysAsRange.split("_").join(" - ");

      // obj.timeRangeDisplay = formValue.startTimeAsString ? formValue.startTimeAsString + " - " + formValue.endTimeAsString : "";
      // obj.timeDurationRangeDisplay = formValue.timeDurationAsRange.split("_").join(" - ");
      obj.mkGuestCategory = this.p.guestCategories[formValue.guestCategory] ;
      obj.totaltGuestsRangeDisplay = formValue.totalGuestsAsRange.split("_").join(" - ");
     
      // obj.priceRule =  formValue.rule; 
      obj.priceRuleLabel  =this.fbo.priceRules[formValue.rule].mkName;
      // obj.amountDouble = formValue.amountDouble;
      
      let wlm = this.apps.bas.us.listToMap(obj.weekdaysList, "weekday");
      for (let wd in formValue.weekdaysMap) {
        wlm[wd].enabled = formValue.weekdaysMap[wd];
        if (!wlm[wd].enabled) obj.isWeekdaysAll = false;
      }
      
      obj.routeFromLabel = this.p.routeItemMap[formValue.routeFrom].name;
      obj.routeToLabel = this.p.routeItemMap[formValue.routeTo].name;

           // this.apps.bas.us.mapToList(this.p.routeItemMap, this.p.routes)
          //  obj.routeFromLabel = 

    } else  if (item == "manPeriodItem") {
      // obj.name = formValue.name;
      // obj.order = formValue.order;
      obj.datePeriodDisplay = formValue.datePeriodAsRange.split("_").join(" - ");
      obj.daysRangeDisplay = formValue.daysAsRange.split("_").join(" - ");

      
      let wlm = this.apps.bas.us.listToMap(obj.weekdaysList, "weekday");
      for (let wd in formValue.weekdaysMap) {
        wlm[wd].enabled = formValue.weekdaysMap[wd];
        if (!wlm[wd].enabled) obj.isWeekdaysAll = false;
      }

    } else  if (item == "dailyRatesItem") {

     
      // obj.name = formValue.name;
      // obj.sortOrder = formValue.sortOrder;
      // obj.datePeriodAsRange = formValue.datePeriodAsRange;
      obj.datePeriodDisplay = formValue.datePeriodAsRange.split("_").join(" - ");
      obj.daysRangeDisplay = formValue.daysAsRange.split("_").join(" - ");

      
      let wlm = this.apps.bas.us.listToMap(obj.weekdaysList, "weekday");
      for (let wd in formValue.weekdaysMap) {
        wlm[wd].enabled = formValue.weekdaysMap[wd];
        if (!wlm[wd].enabled) obj.isWeekdaysAll = false;
      }

      obj.mkGuestCategory = this.p.guestCategories[formValue.guestCategory] ;
      obj.totaltGuestsRangeDisplay = formValue.totalGuestsAsRange.split("_").join(" - ");
      obj.priceRule = formValue.rule;

      obj.priceRuleLabel = this.fbo.priceRules[formValue.rule].mkName;

      if (formValue.amountGroup !== "") {
        obj.mkAmountGroup = upperCase[formValue.amountGroup];
        obj.findAmountAsDouble = this.edit.price.form.controls.ags.controls[formValue.amountGroup]?.value
      }
      if (formValue.amountAsDouble) {
        obj.amountAsDouble = formValue.amountAsDouble;
        obj.findAmountAsDouble = formValue.amountAsDouble; //TODO
      }


 
    } else  if (item == "relation") {
      let child = this.apps.bas.us.listToMap(this.fbo.products)[formValue.child];
      obj.child = child?.name;
      obj.childId = child?.id;

    } else  if (item == "time") {
      obj.name = formValue.name;
      obj.datePeriodDisplay = formValue.datePeriodAsRange.split("_").join(" - ");
    } else  if (item == "routeItem") {
      obj.name = formValue.name;
   
    } else  if (item == "period") {
      obj.name = formValue.name;
      obj.datePeriodDisplay = formValue.datePeriodAsRange.split("_").join(" - ");
    } else  if (item == "mapItem") {

    } else  if (item == "task") {
      obj.time = objClone.time;

    } else  if (item == "log") {
      obj.time = objClone.time;
    }


    if ( event) { 
      
      event.preventDefault();
    } 

    return true;
  }



  async newItem(event:Event|undefined, type:string, subType:string = "", parentObj:any = undefined, copyId:any = undefined) {
    const item = type + subType;
    let hasParentObj = parentObj !== undefined;
    if (!parentObj) parentObj = this.p;

    const arrName = this.edit[type].arrayName || item + "s";
    const mapName = this.edit[type].mapName || item + "Map";

    let params = this.edit[type].params;
    if (subType && this.edit[type].subTypes && this.edit[type].subTypes[subType]) params = this.edit[type].subTypes[subType].params;
    if (params == undefined) params = { };


    if (this.apps.bas.envtest) console.log("newItem: " + item + ", params: ", params);
    
    
    if (event) event.preventDefault();
    
    let save = true;
    
    if (type == "relation" || type == "log" || type == "task") {
      save = false;
      // params.typeAsString = subType;
    }


    let form:UntypedFormGroup = this.add[type]?.form;
    if (form) {
      let formValue = this.apps.bas.fs.getRawValue(form, this.add[type].controls);
      if (formValue === false) {
        // event.preventDefault();
        return; 
      }
      if (this.apps.bas.envtest) console.log("newItem: " + item + ", params: ", params, ", formValue: ", formValue);
    
      MiscUtil.extend(params, formValue);
    }

   
    if (this.apps.bas.envtest) console.log("newItem: " + item + ", params: ", params);

  
    let obj = undefined;
    if (this.edit[type].newItemField) {

      obj = MiscUtil.clone( parentObj[this.edit[type].newItemField] );

    } else {

      let jsonParams:any = { 
        actionType: "product", 
        action: "newItem",
        product: this.p.id,
        copyId: copyId,
        type: type,
        save: save,
        params: JSON.stringify(params)
      };
  
      if (hasParentObj) jsonParams.parent = parentObj.id;

      let json = await this.apps.bas.ws.json(jsonParams);
      if (!json) {
        //TODO
        if (this.apps.bas.envtest) console.log("!json, jsonParams: ", jsonParams);
  
        return;
      }

      obj = json.item;

    }

 
    if (obj.id == "") obj.id = this.edit[type].id--;
    if (this.apps.bas.envtest) {
      console.log("newItem.then: ", obj);
      
      console.log("newItem.objArray: " + this.edit[type].objArray + ", arrName: " + arrName);
    }


    parentObj[arrName] = parentObj[arrName] || [];
    if ( this.edit[type].objArray ) {

      if (type == "log") parentObj[arrName] = [obj, ...parentObj[arrName] ];
      else parentObj[arrName] = [...parentObj[arrName], obj ];

    } else {
      

      parentObj[mapName] = parentObj[mapName] || { };
      parentObj[mapName][obj.id] = obj;
      parentObj[arrName].push(obj.id);
    }

    if (form) form.reset();


    this.editItem(obj, type, subType, parentObj);

  }


  
  copyPriceItem(itemId:number, type:string, parentObj:any = undefined, src:any) {
    const item = type 
    const arrName = this.edit[type].arrayName || item + "s";

    let obj = MiscUtil.clone(src);
    obj.id = this.edit[type].id--;
    if (this.apps.bas.envtest) console.log("copyPriceItem: ", obj);

    parentObj[arrName] = [...parentObj[arrName], obj ];

    this.editPriceItem(obj, type, parentObj, obj.id);

  }


  editPriceItem(itemId:number, type:string, parentObj:any = undefined, id:number) {
    let isExpanded = this.edit[type].obj?.id == id;

    if (this.edit[type].obj) {
      //TOOD: lukke andre
      let saveRes = this.saveItem(type, "", parentObj);
      if (!saveRes) return;
    }

    if (!isExpanded) {
      this.editItem(itemId, type, "", parentObj);
    } else {

      this.edit[type].obj = undefined;
    }
  }
  deletePriceItem(idx:number, type:string, parentObj:any = undefined, obj:any) {
    // let obj = this.edit[type].obj;
    let item = type;
    const arrayName = this.edit[type].arrayName || item + "s";

    let changeArr:any[] = this.changes[ item + "s"] || [];

    let currentIdx = changeArr.findIndex((elem:any) => elem.id === obj.id);

    if (currentIdx >= 0) changeArr.slice(currentIdx, 1);

    if (obj.id > 0) {
      let arr:any[] = this.changes[ item + "Delete"] = this.changes[ item + "Delete"] || [];

      arr.push({ priceId: parentObj.id, id: obj.id });
  
    } 

    obj.deleted = true;
   
    if ( this.edit[type].obj) this.edit[type].obj = undefined;

    let parr = parentObj[arrayName];
    parr.slice(idx, 1);
    parentObj[arrayName] = parr;
    // this.edit.price.obj = parentObj;

  }

  savePrice() {
    let price = this.edit.price.obj;
    for (let type of ["priceItem", "manPeriodItem", "dailyRatesItem", "priceRouteItem"]) {
      if (this.edit[type].obj) {
        //TODO: vise feilmelding eller bare lagre?
        if (this.apps.bas.envtest) console.log("Saving price item: " + type);
        let saveRes = this.saveItem(type, "", price);
        if (!saveRes) return;
      }
    }

    this.saveItem("price");
  } 

  //

  uploadHeaders = (file: NzUploadFile): Object => {
    let headers = this.apps.bas.ws.getCommonHeaders();
    MiscUtil.extend(headers, this.apps.bas.ws.getLoginHeaders());
    return headers;
  }

  // uploadFileList:NzUploadFile[] = [];
  handleUploadItem({ file, fileList }: NzUploadChangeParam, item:string): void {
    const arrName = this.edit[item].arrayName || item + "s";
    const mapName = this.edit[item].mapName || item + "Map";
    
    const status = file.status;
    if (status !== 'uploading') {
      if (this.apps.bas.envtest) console.log(file, fileList);
    }
    if (status === 'done') {
     
      this.p[arrName] = this.p[arrName] || [];
      this.p[mapName] = this.p[mapName] || { };
      
      let resp = file.response;


      if (this.apps.bas.envtest) console.log(file.name + 'file uploaded successfully, resp: ', resp);

      if (resp.itemId) {
        this.p[mapName][resp.itemId] = resp.itemObj;
        this.p[arrName].push(resp.itemId);

        
        // setTimeout(() => {
        //   this.edit[item].fileList = this.edit[item].fileList.filter((fileItem:any) => fileItem.uid != fileItem.uid)
        // }, 100);

      } else {

        if (resp.error == "accessDenied") {

          this.apps.bas.ui.error(this.apps.bas.ui.actrans("common.error.upload.accessDenied", [], false, "En feil oppstod da vi skulle behandle filen du lastet opp. Tillgangsnøkkelen din var utløpt, prøv igjen.")); //TODO:text

          this.apps.bas.ws.updateAccessToken();

        } else {
          this.apps.bas.ui.error(this.apps.bas.ui.actrans("common.error.upload.general", [], false, "En feil oppstod da vi skulle behandle filen du lastet opp")); //TODO:text

        }
        

      }

  

     
      

    } else if (status === 'error') {
      if (this.apps.bas.envtest) console.log(`${file.name} file upload failed.`);
      // this.uploadFileList = [];

      
      // this.edit[item].fileList = this.edit[item].fileList.filter((fileItem:any) => fileItem.uid != fileItem.uid)


      if (item == "image") {
        this.apps.bas.ui.error(this.apps.bas.ui.actrans("common.error.imageUpload", [], false, "En feil oppstod da vi skulle behandle filen du lastet opp. Vanligvis er dette fordi du har lastet opp en JPEG fil i CMYK-format. Kun JPEG bilder i RGB-format kan lastes opp")); //TODO:text

      } else {
        //TODO
      }
      
    }
  }


  // common.end



  setParentExpanded(obj:any) {

    if (obj.id === this.edit.price.parentExpand ) {
      this.edit.price.parentExpandData = this.edit.price.parentExpand = undefined;
      return;
    }

    this.edit.price.parentExpand = obj.id;
    this.edit.price.parentExpandData =  this.apps.bas.ui.toContentList(obj, {
      "sortOrder": "common.sortOrder",
      "name": "common.name", 
      "datePeriodDisplay": "common.period",
      "daysRangeDisplay": "bus.priceItem.minMaxDays",

      "weekdaysShortAsString": "common.weekdays",
      "mkGuestCategory": "bus.bookingItem.guestCategory",
      "totalGuestsRangeDisplay": "bus.priceItem.minMaxGuests",

      "priceRuleLabel": "bus.priceRule",
      "amountAsDouble": { label: "common.amount", type: "double" },
      "mkAmountGroupAndAmount": "bus.price.amountGroup"
    });
    
    /*
     <btlib-form-row [controls]="[ edit.dailyRatesItem.controls.sortOrder, edit.dailyRatesItem.controls.name ]" [form]="edit.dailyRatesItem.form"  [grid]="gridMd"  (change)="onChange($event)" ></btlib-form-row>  
<btlib-form-row [controls]="[ edit.dailyRatesItem.controls.datePeriodAsRange, edit.dailyRatesItem.controls.daysAsRange ]" [form]="edit.dailyRatesItem.form"  [grid]="gridMd"  (change)="onChange($event)" ></btlib-form-row>  
<btlib-form-row [controls]="[ edit.dailyRatesItem.controls.weekdaysMap ]" [form]="edit.dailyRatesItem.form"  [grid]="gridXl" (change)="onChange($event)"  ></btlib-form-row>  


<btlib-form-row [controls]="[ 
edit.dailyRatesItem.controls.guestCategory, 
edit.dailyRatesItem.controls.totalGuestsAsRange
]" [form]="edit.dailyRatesItem.form"  [grid]="gridSm" (change)="onChange($event)"  ></btlib-form-row>  


<btlib-form-row [controls]="[ 
edit.dailyRatesItem.controls.rule, 
edit.dailyRatesItem.controls.amountAsDouble,
edit.dailyRatesItem.controls.amountGroup
]" [form]="edit.dailyRatesItem.form"  [grid]="gridSm" (change)="onChange($event)"  ></btlib-form-row>  
                                


    */

  }


  //



  generateControls(item:string, obj:any): DynformControl[] {
    let controls: DynformControl[] = [];

    if (item == "image") return this.generateControlsImage(obj);
    if (item == "file") return this.generateControlsFile(obj);
    if (item == "input") return this.generateControlsInput(obj);
    if (item == "time") return this.generateControlsTime(obj);
    if (item == "period") return this.generateControlsPeriod(obj);
    if (item == "routeItem") return this.generateControlsRouteItem(obj);
    if (item == "price") return this.generateControlsPrice(obj);
    if (item.startsWith("relation")) return this.generateControlsRelation(obj);
    if (item == "mapItem") return this.generateControlsMapItem(obj);
    if (item == "property") return this.generateControlsProperty(obj);
    if (item == "task") return this.generateControlsTask(obj);
    if (item == "log") return this.generateControlsLog(obj);
  
    if (item == "priceItem") return this.generateControlsPriceItem(obj);
    if (item == "manPeriodItem") return this.generateControlsPriceManPeriodItem(obj);
    if (item == "dailyRatesItem") return this.generateControlsPriceDailyRatesItem(obj);
    if (item == "priceRouteItem") return this.generateControlsPriceRouteItem(obj);


    return controls;
  }



  generateControlsPriceItem(obj:any) {
    let controls: DynformControl[] = [];

    controls.push(new DynformControl({ key: 'id' }));
    controls.push(new DynformControl({ key: 'priceId' }));
    controls.push(new DynformControl({ key: 'idx' }));
    controls.push(new DynformControl({ key: 'name',     mk: 'common.nameInternal', required: true }));
    controls.push(new DynformControl({ key: 'order',     mk: 'common.sortOrder', mkInfo: 'web.common.product.help.priceSortOrder', controlType: "input", type: "number" }));

    controls.push(new DynformControl({ key: 'datePeriodAsRange',     mk: 'common.period', controlType: "date-range-picker" }));
    controls.push(new DynformControl({ key: 'daysAsRange',     mk: 'bus.priceItem.minMaxDays',  controlType: "number-range" }));
    controls.push(new DynformControl({ key: 'weekdaysMap',     mk: 'common.weekdays', controlType: "weekdays" })); // TODO:text

    controls.push(new DynformControl({ key: 'startTimeAsString',  mk: 'bus.productTime.startTime', controlType: "time-picker", data: {  } })); 
    controls.push(new DynformControl({ key: 'endTimeAsString',  mk: 'bus.productTime.endTime', controlType: "time-picker", data: {  } })); 

    controls.push(new DynformControl({ key: 'totalGuestsAsRange',     mk: 'bus.priceItem.minMaxGuests',  controlType: "number-range" }));
    controls.push(new DynformControl({ key: 'timeDurationAsRange',     mk: 'bus.priceItem.minMaxTime',  controlType: "number-range" }));
    
    for (let gc = 0; gc < 5; gc++) {
      controls.push(new DynformControl({ 
        key: 'guestsGcAsRange' + gc,     
        mk: this.apps.bas.ui.actrans('bus.booking.guests.gc' + gc) + ": " + this.apps.bas.ui.actrans("bus.bookingItem.gc" + gc),   //TODO:text
        // mk: "bus.booking.guests.gc" + gc,
        controlType: "number-range" 
      })); // TODO: bare vise de "aktive"
    

    }

    
    
    let gcs = this.apps.bas.us.mapToList(this.p.guestCategories).map((val) => { return { key: parseInt(val.key), value: val.value } });;
    controls.push(new DynformControl({ 
      key: 'guestCategory',  
      // valuePath: "ruleId",
      mk: 'bus.bookingItem.guestCategory',  
      controlType: 'select', 
      // required: true,
      options: () => {
         // TODO: ${p.findTypeActOrEvent or !p.guestProduct ? fbo.priceRulesActivity : fbo.priceRules}
        return gcs;
      },
      optionsAllowEmpty: true,
      optionsFieldValue: "key",
      optionsFieldLabel: "value"
    })); 
    

    controls.push(new DynformControl({ 
      key: 'rule',  
      valuePath: "ruleId",
      mk: 'bus.priceRule',  
      controlType: 'select', 
      required: true,
      options: () => {

        return this.fbo.priceRules; // TODO: ${p.findTypeActOrEvent or !p.guestProduct ? fbo.priceRulesActivity : fbo.priceRules}
      },
      optionsAllowEmpty: true,
      optionsFieldValue: "id",
      optionsFieldLabel: "mkName"
    })); 

    controls.push(new DynformControl({ key: 'amountDouble',     mk: 'common.amount', required: true, controlType: "input", type: "number" }));


    return controls;
  }

  generateControlsPriceRouteItem(obj:any) {
    let controls: DynformControl[] = [];

    controls.push(new DynformControl({ key: 'id' }));
    controls.push(new DynformControl({ key: 'priceId' }));
    controls.push(new DynformControl({ key: 'idx' }));
    controls.push(new DynformControl({ key: 'name',     mk: 'common.nameInternal', required: true }));
    controls.push(new DynformControl({ key: 'sortOrder',     mk: 'common.sortOrder', mkInfo: 'web.common.product.help.priceSortOrder', controlType: "input", type: "number" }));

    controls.push(new DynformControl({ key: 'datePeriodAsRange',     mk: 'common.period', controlType: "date-range-picker" }));
    // controls.push(new DynformControl({ key: 'daysAsRange',     mk: 'bus.priceItem.minMaxDays',  controlType: "number-range" }));
    controls.push(new DynformControl({ key: 'weekdaysMap',     mk: 'common.weekdays', controlType: "weekdays" })); 

    // controls.push(new DynformControl({ key: 'startTimeAsString',  mk: 'bus.productTime.startTime', controlType: "time-picker", data: {  } })); 
    // controls.push(new DynformControl({ key: 'endTimeAsString',  mk: 'bus.productTime.endTime', controlType: "time-picker", data: {  } })); 

    controls.push(new DynformControl({ key: 'totalGuestsAsRange',     mk: 'bus.priceItem.minMaxGuests',  controlType: "number-range" }));
    // controls.push(new DynformControl({ key: 'timeDurationAsRange',     mk: 'bus.priceItem.minMaxTime',  controlType: "number-range" }));
    
    controls.push(new DynformControl({ key: 'bothDirections',     mk: 'bus.priceRouteItem.bothDirections', controlType: "checkbox" }));

    // for (let gc = 0; gc < 5; gc++) {
    //   controls.push(new DynformControl({ 
    //     key: 'guestsGcAsRange' + gc,     
    //     mk: this.apps.bas.ui.actrans('bus.booking.guests.gc' + gc) + ": " + this.apps.bas.ui.actrans("bus.bookingItem.gc" + gc),  
    //     // mk: "bus.booking.guests.gc" + gc,
    //     controlType: "number-range" 
    //   })); // TODO: bare vise de "aktive"
    

    // }

    
    
    let gcs = this.apps.bas.us.mapToList(this.p.guestCategories).map((val) => { return { key: parseInt(val.key), value: val.value } });;
    controls.push(new DynformControl({ 
      key: 'guestCategory',  
      // valuePath: "ruleId",
      mk: 'bus.bookingItem.guestCategory',  
      controlType: 'select', 
      // required: true,
      options: () => {
         // TODO: ${p.findTypeActOrEvent or !p.guestProduct ? fbo.priceRulesActivity : fbo.priceRules}
        return gcs;
      },
      optionsAllowEmpty: true,
      optionsFieldValue: "key",
      optionsFieldLabel: "value"
    })); 
    

    controls.push(new DynformControl({ 
      key: 'rule',  
      // valuePath: "ruleId",
      mk: 'bus.priceRule',  
      controlType: 'select', 
      required: true,
      options: () => {

        return this.fbo.priceRules; // TODO: ${p.findTypeActOrEvent or !p.guestProduct ? fbo.priceRulesActivity : fbo.priceRules}
      },
      optionsAllowEmpty: true,
      optionsFieldValue: "id",
      optionsFieldLabel: "mkName"
    })); 

    controls.push(new DynformControl({ key: 'amountAsDouble',     mk: 'common.amount', required: true, controlType: "input", type: "number" }));

    controls.push(new DynformControl({ 
      key: 'routeFrom',  
      // valuePath: "ruleId",
      mk: 'bus.priceRouteItem.routeFrom',  
      controlType: 'select', 
      required: true,
      options: () => {

        return this.apps.bas.us.mapToList(this.p.routeItemMap, this.p.routes); 
      },
      optionsAllowEmpty: true,
      optionsFieldValue: "id",
      optionsFieldLabel: "name"
    })); 
    controls.push(new DynformControl({ 
      key: 'routeTo',  
      // valuePath: "ruleId",
      mk: 'bus.priceRouteItem.routeTo',  
      controlType: 'select', 
      required: true,
      options: () => {

        return this.apps.bas.us.mapToList(this.p.routeItemMap, this.p.routes); 
      },
      optionsAllowEmpty: true,
      optionsFieldValue: "id",
      optionsFieldLabel: "name"
    })); 

    return controls;
  }

  generateControlsPriceManPeriodItem(obj:any) {
    let controls: DynformControl[] = [];
    
    controls.push(new DynformControl({ key: 'id' }));
    controls.push(new DynformControl({ key: 'priceId' }));
    controls.push(new DynformControl({ key: 'idx' }));
    controls.push(new DynformControl({ key: 'name',     mk: 'common.nameInternal', required: true }));
    controls.push(new DynformControl({ key: 'order',     mk: 'common.sortOrder', mkInfo: 'web.common.product.help.priceSortOrder', controlType: "input", type: "number", required: true }));

    controls.push(new DynformControl({ key: 'datePeriodAsRange',     mk: 'common.period', controlType: "date-range-picker", required: true }));
    controls.push(new DynformControl({ key: 'daysAsRange',     mk: 'common.days',  controlType: "number-range" }));
    controls.push(new DynformControl({ key: 'weekdaysMap',     mk: 'common.weekdays', controlType: "weekdays", mkInfo: "web.iframe.search.priceList.header.day" })); // TODO:text

    controls.push(new DynformControl({ key: 'periodDouble',     mk: 'bus.priceManPeriodItem.period', mkInfo: "web.common.product.price.totalPriceNote" }));
    controls.push(new DynformControl({ key: 'dayDouble',     mk: 'web.iframe.search.priceList.header.day' }));
    controls.push(new DynformControl({ key: 'weekDouble',     mk: 'web.iframe.search.priceList.header.week' }));
    controls.push(new DynformControl({ key: 'friToSunDouble',     mk: 'web.iframe.search.priceList.header.friToSun' }));
    controls.push(new DynformControl({ key: 'thuToSunDouble',     mk: 'web.iframe.search.priceList.header.thuToSun' }));
    controls.push(new DynformControl({ key: 'monToFriDouble',     mk: 'web.iframe.search.priceList.header.monToFri' }));

  
    return controls;
  }

    
  generateControlsPriceDailyRatesItem(obj:any) {
    let controls: DynformControl[] = [];

    controls.push(new DynformControl({ key: 'id' }));
    controls.push(new DynformControl({ key: 'priceId' }));
    controls.push(new DynformControl({ key: 'idx' }));
    controls.push(new DynformControl({ key: 'name',               mk: 'common.nameInternal', required: true }));
    controls.push(new DynformControl({ key: 'sortOrder',          mk: 'common.sortOrder', mkInfo: 'web.common.product.help.priceSortOrder', controlType: "input", type: "number" }));

    controls.push(new DynformControl({ key: 'datePeriodAsRange',  mk: 'common.period', controlType: "date-range-picker" }));
    controls.push(new DynformControl({ key: 'daysAsRange',        mk: 'bus.priceItem.minMaxDays',  controlType: "number-range" }));
    controls.push(new DynformControl({ key: 'weekdaysMap',        mk: 'common.weekdays', controlType: "weekdays" }));

    controls.push(new DynformControl({ key: 'startTimeAsString',  mk: 'bus.productTime.startTime', controlType: "time-picker", data: {  } })); 
    controls.push(new DynformControl({ key: 'endTimeAsString',    mk: 'bus.productTime.endTime', controlType: "time-picker", data: {  } })); 

    controls.push(new DynformControl({ key: 'totalGuestsAsRange', mk: 'bus.priceItem.minMaxGuests',  controlType: "number-range" }));

    controls.push(new DynformControl({ key: 'closedToArrival',    mk: 'bus.priceDailyRatesItem.closedToArrival' })); 
    controls.push(new DynformControl({ key: 'closedToDeparture',    mk: 'bus.priceDailyRatesItem.closedToDeparture' })); 
    controls.push(new DynformControl({ key: 'exactPeriod',    mk: 'bus.priceDailyRatesItem.exactPeriod' })); 



    controls.push(new DynformControl({ 
      key: 'rule',  
      // valuePath: "ruleId",
      mk: 'bus.priceRule',  
      controlType: 'select', 
      required: true,
      options: () => {
        // console.log("rule.options");
        return this.fbo.priceRules; // TODO: ${p.findTypeActOrEvent or !p.guestProduct ? fbo.priceRulesActivity : fbo.priceRules}
      },
      optionsAllowEmpty: true,
      optionsFieldValue: "id",
      optionsFieldLabel: "mkName"
    })); 

    const guestCategories = this.apps.bas.us.mapToList(this.p.guestCategories).map((val) => { return { key: parseInt(val.key), value: val.value } });

    controls.push(new DynformControl({ 
      key: 'guestCategory',  
      // valuePath: "ruleId",
      mk: 'bus.bookingItem.guestCategory',  
      controlType: 'select', 
      // required: true,
      options: () => {
        // console.log("guestCategory.options");
        return guestCategories; // TODO: ${p.findTypeActOrEvent or !p.guestProduct ? fbo.priceRulesActivity : fbo.priceRules}
      },
      optionsAllowEmpty: true,
      optionsFieldValue: "key",
      optionsFieldLabel: "value"
    })); 

    // let ags:any[] = [];
    // let p = this.edit.price.obj;
    // if (p.ags) {
    //   for (var g = 0; g < p.ags.length; g++) {
    //     ags.push({ value: g, label: +p.ags[g].mk+(p.ags[g].amount ? ': ' + p.ags[g].amountAsDouble : '') });
    //   }
    // }


    controls.push(new DynformControl({ 
      key: 'amountGroup',  
      // valuePath: "ruleId",
      mk: 'bus.price.amountGroup',  
      controlType: 'select', 
      options: () => {
        // console.log("amountGroup.options");
        return this.edit.price.ags; // this.apps.bas.us.mapToList(this.p.guestCategories); // TODO: ${p.findTypeActOrEvent or !p.guestProduct ? fbo.priceRulesActivity : fbo.priceRules}
      },
      show: () => {
        // console.log("amountGroup.show");
        let uag = this.edit.price.form.controls.useAmountGroups.value;
        return uag;
      }, 
      validators:  [
        (control: AbstractControl): { [s: string]: boolean } => {
          if (control.value !== "") return { };
          if (this.edit.price.form.controls.useAmountGroups.value)  return  { error: true, required: true };
          return { };
        } 
      ],
      optionsAllowEmpty: true,
      optionsFieldValue: "group",
      optionsFieldLabel: "valueDisplay"
    })); 
    
    controls.push(new DynformControl({ 
      key: 'amountAsDouble',     
      mk: 'common.amount', 
      controlType: "input", 
      type: "number",
      show: () => {
        // console.log("amountAsDouble.show");
        let uag = this.edit.price.form.controls.useAmountGroups.value;
        return !uag;
      }, 
      validators:  [
        (control: AbstractControl): { [s: string]: boolean } => {
          if (control.value) return { };
          if (!this.edit.price.form.controls.useAmountGroups.value)  return  { error: true, required: true };
          return { };
        } 
      ]
    }));

    return controls;
  }


  addAmountGroup() {
    let p = this.edit.price.obj;
  
    
    p.amountGroupsList = p.amountGroupsList || [];
    let g = Math.max(p.amountGroupsList.length, p.parentObj && p.parentObj.amountGroupsList ?  p.parentObj.amountGroupsList.length : 0);
    
    let ag = {
      amount: 0, 
      amountAsDouble: 0, 
      mk: upperCase[g], 
      group: g 
    };

    p.amountGroupsList.push(ag);
    p.amountGroups[g] = "";
    
    if (this.apps.bas.envtest) console.log("addAmountGroup: ", ag);
    this.updatePriceAmountGroups(p);
  }

  updatePriceAmountGroups (price:any, dcm?:DynformControlsMap) {
    let ags:any[] = [];

    let agoControls: DynformControl[] = [];
    
    let parentLength = 0;


    let currentValues:any = { };
    for (let ag of this.edit.price.ags) {
      let gv = this.edit.price.form.controls.ags.controls[ag.group]?.value;
      currentValues[ag.group] = gv ? gv * 100 : undefined;
    }

    if (this.apps.bas.envtest) console.log("updatePriceAmountGroups, currentValues: ", currentValues);

    let parent = price.parentObj;
    if (parent && parent.amountGroupsList) {
      parentLength = parent.amountGroupsList.length;

      for (var ag of parent.amountGroupsList) {
        ags.push({
          ...ag, 
          parent: parent, 
          
        });

      }

    }

    if (price.amountGroupsList) {
      for (var ag of price.amountGroupsList) {
        ags[ag.group] = {
          ...ag, 
          price: price, 
        };
      }
    }

    
    // console.log("updatePriceAmountGroups, price: ", price, ", dcm: ", dcm, ", ags: ", ags);
    
    let controls: DynformControl[] = [];

    

    for (let ag of ags) {
      let agv = currentValues[ag.group]  || price.amountGroups[ag.group + ""];
      
      if (typeof agv === "number")  agv = agv / 100.0;
      else agv = "";

      ag.color = MiscUtil.getColor(ag.group) ;
      ag.value = agv;
      ag.valueDisplay = this.apps.bas.ui.nf(agv);
      ag.label = ag.mk + (agv ? ": " + agv : "");
  
      controls.push(new DynformControl({ 
        key: ag.group + "",  
        //valuePath: "amountGroups." + ag.group,
        value: agv,
        mk: ag.mk, 
        controlType: "input", 
        type: "number", 
        required: ag.group < parentLength,
        updateSourceOnChange: true,
        onChange: (event:any) => {
          if (this.apps.bas.envtest) console.log("ags.update, event: ", event);
          this.updatePriceAmountGroups(price);
        },
        data: {
          css: "fgc " + MiscUtil.getColor(ag.group, "fg")
        }
      }));
    }


    if (parent && parent.dailyRatesItems) {
      for (let dri of parent.dailyRatesItems) {
        let pAgos = price.agos ? price.agos : price.amountGroupOverride;

        dri.agColor = MiscUtil.getColor(dri.amountGroup) 
        agoControls.push(new DynformControl({ 
          key: dri.id + "",  
          // valuePath: "typeId",
          mk: "", //'common.override',  
          controlType: 'select', 
          // required: true,
          value: pAgos[dri.id] !== undefined ? pAgos[dri.id] : "",
          options: () => {
            return ags;
          },
          onChange: (event:any) => {
            if (this.apps.bas.envtest) console.log("agos.onChange: ", event);
            
          },
          optionsAllowEmpty: true,
          optionsEmptyLabel: "----",
          optionsFieldValue: "group",
          optionsFieldLabel: "valueDisplay",
          data: {
            nomargin: true
          }
        }));


      }
    }

    if (dcm) { // ved oppstart
      if (dcm.ags) dcm.ags.children = controls;
      if (dcm.agos) dcm.agos.children = agoControls;

    } else {  // etter oppstart
      //
      dcm = this.edit.price.controls as DynformControlsMap;
      // dcAgs = this.edit.price.controls.ags;
      if (dcm.ags) {
        this.edit.price.form.controls.ags = this.apps.bas.fs.toFormGroup(controls);
        dcm.ags.children = controls;
        dcm.ags.childMap = this.apps.bas.fs.toMap(controls);
       
      }
      if (dcm.agos) {
        this.edit.price.form.controls.agos = this.apps.bas.fs.toFormGroup(agoControls);
        dcm.agos.children = agoControls;
        dcm.agos.childMap = this.apps.bas.fs.toMap(agoControls);
      }

    }

    // price.ags = ags;
    this.edit.price.ags = ags;
    price.parentObj = parent;
  }

  generateControlsPrice(obj:any) {
    let controls: DynformControl[] = [];

    
    let isAdmin = this.apps.bas.aas.isAdmin();
    controls.push(new DynformControl({ key: 'id' }));
    controls.push(new DynformControl({ key: 'time',     mk: 'common.time', controlType: 'label', }));
    controls.push(new DynformControl({ key: 'updated',     mk: 'common.updated', controlType: 'label', }));
    controls.push(new DynformControl({ key: 'enabled',     mk: 'common.enabled' }));
    controls.push(new DynformControl({ key: 'name',     mk: 'common.nameInternal', required: true }));
    controls.push(new DynformControl({ key: 'order',     mk: 'common.sortOrder', mkInfo: 'web.common.product.help.priceSortOrder', required: true }));

    // adv
    controls.push(new DynformControl({ key: 'campaignCode',     mk: 'bus.campaign.code' }));
    controls.push(new DynformControl({ 
      key: 'orderPeriodAsString',     
      mk: 'bus.price.orderPeriod', 
      controlType: "datetime-range-picker",
      mkInfo: "bus.price.orderPeriod.helpText"
    }));
    
    controls.push(new DynformControl({ 
      key: 'providerPercent',     
      mk: 'bus.common.providerPercent', 
      controlType:"input", 
      type: "number", 
      disabled: !isAdmin,
      show:  (control:DynformControl) => {
        if (!isAdmin && control.value == "") return false;
        return !!this.productForm.controls.provider.value;
      } 
    }));
    controls.push(new DynformControl({ 
      key: 'providerAmountDouble',     
      mk: 'bus.common.providerAmount', 
      controlType:"input", 
      type: "number", 
      disabled: !isAdmin,
      show:  (control:DynformControl) => {
        if (!isAdmin && control.value == "") return false;
        return !!this.productForm.controls.provider.value && control.value !== "";
      } 
    })); // TODO: bus.common.providerAmount.warning
    controls.push(new DynformControl({ 
      key: 'commissionAmountDouble',     
      mk: 'common.commissionAmount', 
      controlType:"input", 
      type: "number", 
      disabled: !isAdmin,
      show:  (control:DynformControl) => { 
        if (!isAdmin && control.value == "") return false;
        return false; //return !!this.productForm.controls.provider.value && control.value !== "";
      } 
    })); // TODO: bus.common.commissionAmount.warning

    
    // adv end

    controls.push(new DynformControl({ 
      key: 'type',  
      // valuePath: "typeId",
      mk: 'common.type',  
      controlType: 'select', 
      required: true,
      options: () => {
        return this.fbo.priceTypes;
      },
      onChange: (event:any) => {
        if (this.apps.bas.envtest) console.log("type.onChange: ", event);
        this.edit.price.obj.typeEnum = this.apps.bas.us.listToMap(this.fbo.priceTypes)[event.value];
      },
      optionsAllowEmpty: true,
      optionsFieldValue: "id",
      optionsFieldLabel: "mkName"
    }));
    obj.typeEnum = this.apps.bas.us.listToMap(this.fbo.priceTypes)[obj.type];


    controls.push(new DynformControl({ 
      key: 'group',  
      // valuePath: "typeId",
      mk: 'bus.price.group',  
      controlType: 'select', 
      required: true,
      options: () => {
        return this.fbo.priceGroups;
      },
      optionsAllowEmpty: true,
      // optionsFieldValue: "id",
      // optionsFieldLabel: "mkName"
    }));

    

    controls.push(new DynformControl({ 
      key: 'channexPriceConfigId',  
      // valuePath: "typeId",
      mk: 'bus.price.channexPriceConfig',  
      controlType: 'select', 
      // required: true,
      options: () => {
        return this.fbo.channexPriceConfigs;
      },
      show:  (control:DynformControl) => {
        return this.apps.bas.ds.config.company.upCmIntChannex;
      },
      optionsAllowEmpty: true,
      optionsFieldValue: "id",
      optionsFieldLabel: "mkName"
    }));

    

    // Simple

    controls.push(new DynformControl({ 
      key: 'baseAmountDouble',     
      mk: 'common.amount', 
      controlType: "input", 
      type: "number",
      // TODO: required hvis SIMPLE

    }));

    controls.push(new DynformControl({ 
      key: 'rule',  
      valuePath: "ruleId",
      mk: 'bus.priceRule',  
      controlType: 'select', 
      // required: true, //TODO: required if isSimple
      options: () => {

        return this.fbo.priceRules; // TODO: ${p.findTypeActOrEvent or !p.guestProduct ? fbo.priceRulesActivity : fbo.priceRules}
      },
      optionsAllowEmpty: true,
      optionsFieldValue: "id",
      optionsFieldLabel: "mkName"
    })); 



    // Manual     


    // dailyRates
   
    let upCProductFeatureAmountGroups = this.apps.bas.ds.config.company.upCProductFeatureAmountGroups;

    
    controls.push(new DynformControl({ 
      key: 'useAmountGroups',     
      mk: 'bus.price.useAmountGroups' , 
      show: () =>  upCProductFeatureAmountGroups
    }));

    controls.push(new DynformControl({ 
      key: 'parent',  
      valuePath: "parentId",
      mk: 'common.template',  
      controlType: 'select', 
      options: () => {
        return this.fbo.amountGroupParents;
      },
      show: () => {
        return !this.productForm.controls.templateProduct.value;
        // let uag = this.edit.price.form.controls.useAmountGroups.value && !this.productForm.controls.templateProduct.value;
        // return uag;
      },
      onChange: (event:any) => {
        let pid = event.value;
        
        let po = this.apps.bas.us.listToMap(this.fbo.amountGroupParents)[pid];
        // console.log("parent.onChange: ", event, ", po: ", po);
        
        obj.parentObj = po;
        this.updatePriceAmountGroups(obj);
        // console.log("parent.onChange: ", event, ", po: ", po, ", obj: ", obj);
      },
      optionsAllowEmpty: true,
      optionsFieldValue: "idAsString",
      optionsFieldLabel: "name"
    })); 
    if (obj.parentId) obj.parentObj = this.apps.bas.us.listToMap(this.fbo.amountGroupParents)[obj.parentId];

    let dcAgs = new DynformControl({ 
      key: 'ags',
      controlType: 'formGroup',
      children: [ ]
    })

    controls.push(dcAgs);

    let dcAgos = new DynformControl({ 
      key: 'agos',
      controlType: 'formGroup',
      children: [ ]
    })

    controls.push(dcAgos);

    this.updatePriceAmountGroups(obj, { 
      "ags": dcAgs, 
      "agos": dcAgos 
    });

    let aos = new DynformControl({ 
      key: 'aos',
      controlType: 'formGroup',
      children: [ ]
    })

    // let pAos = price.agos ? price.agos : price.amountGroupOverride;

    let parent = obj.parentObj;

    if (parent && parent.dailyRatesItems) {
      for (let dri of parent.dailyRatesItems) {
        console.log("adding aos: ")
        aos.children.push(new DynformControl({ 
          key: dri.id + "",  
          // valuePath: "typeId",
          mk: "", //'common.override',  
          controlType: 'input-number', 
          // required: true,
          value: obj.amountOverride[dri.id] !== undefined ? obj.amountOverride[dri.id] / 100.0 : "",
 
          onChange: (event:any) => {
            if (this.apps.bas.envtest) console.log("aos.onChange: ", event);
            
          },
          // optionsAllowEmpty: true,
          // optionsEmptyLabel: "----",
          // optionsFieldValue: "group",
          // optionsFieldLabel: "valueDisplay",
          data: {
            nomargin: true
          }
        }));
      }
    }


    controls.push(aos);

    // ManPeriodItem

    controls.push(new DynformControl({ 
      key: 'startDay',  
      // valuePath: "typeId",
      mk: 'bus.price.startDay',  
      controlType: 'select', 
      options: () => {
        return this.fbo.weekdays;
      },
      optionsAllowEmpty: true,
      // optionsFieldValue: "id",
      // optionsFieldLabel: "mkName"
    })); 

    // Discount

    let discountValidators = [
      (control: AbstractControl): { [s: string]: boolean } => {
        if (control.value) return { };
        if (this.edit.price.obj.typeEnum.isDiscount)  return  { error: true, required: true };
        return { };
      }
    ];


    controls.push(new DynformControl({ 
      key: 'price',  
      valuePath: "priceId",
      mk: 'common.price',  
      controlType: 'select', 
      options: () => {
        return this.apps.bas.us.mapToList(this.p.priceMap, this.p.prices).filter((val) => { return val.id != this.edit.price.obj?.id; }); // TODO: exclude this
      },
      optionsAllowEmpty: true,
      optionsFieldValue: "id",
      optionsFieldLabel: "name",
      validators: discountValidators
    })); 

    controls.push(new DynformControl({ 
      key: 'discountPercent',      
      mk: 'bus.price.discountPercent', 
      controlType: "input", 
      type: "number", 
      validators:  [
        (control: AbstractControl): { [s: string]: boolean } => {
          if (control.value) return { };
          if (this.edit.price.obj.typeEnum.isDiscount && this.edit.price.form.controls.discountAmountDouble.value == "")  return  { error: true, required: true };
          return { };
        } 
      ] 
    }));

    controls.push(new DynformControl({ 
      key: 'discountAmountDouble', 
      mk: 'bus.price.discountAmount', 
      controlType: "input", 
      type: "number", 
      show: (control:DynformControl) => {
        return control.value !== "";
      } 
    }));

    controls.push(new DynformControl({ key: 'discountDatePeriodAsRange',  mk: 'common.period', mkInfo: "bus.price.discountPeriod.mkHelp", controlType: "date-range-picker" }));
    controls.push(new DynformControl({ key: 'discountDaysAsRange',   mk: 'bus.priceItem.minMaxDays',  controlType: "number-range" }));


    // Custom
    let customValidators = [
      (control: AbstractControl): { [s: string]: boolean } => {
        if (control.value) return { };
        if (this.edit.price.obj.typeEnum.isCustom)  return  { error: true, required: true };
        return { };
      }
    ];

    controls.push(new DynformControl({ key: 'customDefaultDouble', mk: 'bus.price.customDefault', controlType: "input", type: "number", data: { min: 0 } }));  // , validators: customValidators
    controls.push(new DynformControl({ key: 'customMinDouble',     mk: 'bus.price.customMin', controlType: "input", type: "number", data: { min: 0 } }));
    controls.push(new DynformControl({ key: 'customMaxDouble',     mk: 'bus.price.customMax', controlType: "input", type: "number", data: { min: 1 } }));


    return controls;
  }

  generateControlsProperty(obj:any) {
    let controls: DynformControl[] = [];

    controls.push(new DynformControl({ key: 'id' }));
    controls.push(new DynformControl({ key: 'type',     mk: '', controlType: "label" }));

    let type = obj.typeObj;

    controls.push(new DynformControl({ key: 'value',        mk: '',         show: () => { return type.isValString; } }));
    controls.push(new DynformControl({ key: 'booleanValue', mk: obj.type ,  show: () => { return type.isValBoolean; }  }));
    controls.push(new DynformControl({ key: 'integerValue', mk: '',         show: () => { return type.isValInteger; }  }));
    controls.push(new DynformControl({ key: 'doubleValue',  mk: '',         show: () => { return type.isValDouble; }  }));

    
    controls.push(new DynformControl({ 
      key: 'messages',
      controlType: 'formGroup',
      children: [

        new DynformControl({ 
          key: 'messageValue',
          // valuePath: 'messages.messageValue', 
          mk: 'common.value', 
          controlType: 'message',
          data: { controlType: type.isValRichText ? "richtext" : "textarea" },
          show: () => { return type.isValMessage || type.isValRichText; }
        }),

      ]
    }));

    return controls;
  }


  generateControlsTime(obj:any) {
    let controls: DynformControl[] = [];


    let isBn = this.apps.bas.aas.isBn();

    controls.push(new DynformControl({ key: 'id' }));
    controls.push(new DynformControl({ key: 'time',     mk: 'common.time', controlType: 'label', }));
    controls.push(new DynformControl({ key: 'updated',     mk: 'common.updated', controlType: 'label', }));
    // controls.push(new DynformControl({ key: 'enabled',     mk: 'common.available', mkInfo: "web.common.product.help.time.available" }));
    controls.push(new DynformControl({ key: 'name',     mk: 'common.nameInternal', required: true }));

    controls.push(new DynformControl({ key: 'datePeriodAsRange',  mk: 'common.period', controlType: "date-range-picker", required: true, data: {  } })); 
    controls.push(new DynformControl({ key: 'timeEnabled',     mk: 'common.enabled' }));
   
    if (this.apps.bas.envtest) {
      controls.push(new DynformControl({ key: 'startTimeAsString',  mk: 'bus.productTime.startTime', controlType: "time-picker", required: true, data: {  } })); 
      controls.push(new DynformControl({ key: 'endTimeAsString',  mk: 'bus.productTime.endTime', controlType: "time-picker", required: true, data: {  } })); 

      controls.push(new DynformControl({  // <c:if test="${company.username == 'langedrag' }">
        key: 'enableWhenFull',  
        valuePath: "enableWhenFullId",
        mk: 'bus.productTime.enableWhenFull',  
        controlType: 'select', 
        // required: true,
        options: () => {
           // TODO: må ta med nye og oppdatere navn osv. Exclude : this.id
          return this.apps.bas.us.values(this.p.timeMap, obj.id);
        },
        optionsAllowEmpty: true,
        optionsFieldValue: "id",
        optionsFieldLabel: "name"
      }));
    }

    controls.push(new DynformControl({ key: 'cutoff',     mk: 'bus.product.orderTimeThreshold', controlType: 'input', type: "number", data: { min: 1 } }));

    // controls.push(new DynformControl({ key: 'weekdaysMap',     mk: 'common.weekdays', controlType: "weekdays" }));

    controls.push(new DynformControl({ 
      key: 'overridePeriod',     
      mk: 'bus.productTime.overridePeriod', 
      // mkInfo: "web.common.product.help.time.overridePeriod", 
      show: () => isBn 
    }));
    


    
    let pcid = this.productForm.controls.productConfigId.value || this.p.productConfigId;
    let pc = this.apps.bas.us.listToMap(this.fbo.productConfigs)[pcid];

    
    controls.push(new DynformControl({ 
      key: 'routeNumber',     
      mk: 'bus.productTime.routeNumber', 
      controlType: "input", 
      type: "number", 
      data: { min: 1 },
      show: () => pc.isRoute
    }));

    let routes = this.apps.bas.us.mapToList(this.p.routeItemMap, this.p.routes); 
    if (routes.length == 0) {
      routes = this.fbo.globalRouteItems || [];
    }

    controls.push(new DynformControl({ 
      key: 'routeItemFrom',  
      valuePath: "routeItemFromId",
      mk: 'bus.priceRouteItem.routeFrom',  
      controlType: 'select', 
      // required: true,
      show: () => pc.isRoute,
      options: () => {

        return routes; 
      },
      optionsAllowEmpty: true,
      optionsFieldValue: "id",
      optionsFieldLabel: "name"
    })); 

    controls.push(new DynformControl({ 
      key: 'routeItemTo',  
      valuePath: "routeItemToId",
      mk: 'bus.priceRouteItem.routeTo',  
      controlType: 'select', 
      // required: true,
      show: () => pc.isRoute,
      options: () => {

        return routes; 
      },
      optionsAllowEmpty: true,
      optionsFieldValue: "id",
      optionsFieldLabel: "name"
    })); 

    
    if (this.apps.bas.envtest) {


      let timeChildren:DynformControl[] = []

      let children:any[] = [
        MiscUtil.clone( obj )
      ];

      if (obj.children) children = [...children, ...obj.children];

      obj.childrenAll = children;

      obj.timeChildren = { };

      children.forEach((tc, index) => {
        let fgTc = new DynformControl({ 
          key: 'tc_' + tc.id,
          controlType: 'formGroup',
          children: []
        });
        timeChildren.push(fgTc);

        let isMain = index === 0;

        if (!isMain) {
          fgTc.children.push(new DynformControl({ key: 'order',     mk: 'common.sortOrder', type: "number" }));
          fgTc.children.push(new DynformControl({ key: 'timeEnabled',     mk: 'common.enabled' }));
    
        }
    
        fgTc.children.push(new DynformControl({ key: 'startTimeAsString',  mk: 'bus.productTime.startTime', controlType: "time-picker", required: true, data: {  } })); 
        fgTc.children.push(new DynformControl({ key: 'endTimeAsString',  mk: 'bus.productTime.endTime', controlType: "time-picker", required: true, data: {  } })); 
    
        fgTc.children.push(new DynformControl({ key: 'weekdaysMap',     mk: 'common.weekdays', controlType: "weekdays", data: { textLength: "min"} }));

        let ewfList = obj.childrenAll.filter((item:any) => item.id != tc.id);

        let mainList =  this.apps.bas.us.values(this.p.timeMap, obj.id).filter((pt:any) => !pt.hasParent);

        fgTc.children.push(new DynformControl({  // <c:if test="${company.username == 'langedrag' }">
          key: 'enableWhenFull',  
          valuePath: "enableWhenFullId",
          mk: 'bus.productTime.enableWhenFull',  
          controlType: 'select', 
          // required: true,
          optionsList: isMain ? mainList : ewfList,
          // options: () => {
          //    // TODO: må ta med nye og oppdatere navn osv. Exclude : this.id
          //   return this.apps.bas.us.values(this.p.timeMap, obj.id);
          // },
          optionsAllowEmpty: true,
          optionsFieldValue: "idAsString",
          optionsFieldLabel: isMain ? "name" : "timeRange"
        }));
    

        obj.timeChildren['tc_' + tc.id] = tc;

      });

      controls.push(new DynformControl({ 
        key: 'timeChildren',
        controlType: 'formGroup',
        children: timeChildren
      }));

    }


    /*
							<c:if test="${user.isBn}">
									<label class="col-sm-4 control-label" for="time_duration"><span class="label label-info label-bn">BN</span> <nit:message key="common.duration"/>:</label>
									<div class="col-sm-8"><input type="text" id="time_duration" name="time_duration" class="form-control jpField jpInteger jpPositive" value="" /></div>
							</c:if>
    */

    return controls;
  }

  tcOpts:any = {
    hideLabel:true,
    nomargin: true
  }
  copyTimeChild(tc:any) {

  }
  deleteTimeChild(tc:any) {
    
  }


  generateControlsPeriod(obj:any) {
    let controls: DynformControl[] = [];

    controls.push(new DynformControl({ key: 'id' }));
    controls.push(new DynformControl({ key: 'time',     mk: 'common.time', controlType: 'label', }));
    controls.push(new DynformControl({ key: 'updated',     mk: 'common.updated', controlType: 'label', }));
    controls.push(new DynformControl({ key: 'name',     mk: 'common.nameInternal', required: true }));

    controls.push(new DynformControl({ key: 'datePeriodAsRange',  mk: 'common.period', required: true , controlType: "date-range-picker", data: {  } })); 
    


    return controls;
  }


  generateControlsRouteItem(obj:any) {
    let controls: DynformControl[] = [];

    controls.push(new DynformControl({ key: 'id' }));
    controls.push(new DynformControl({ key: 'time',     mk: 'common.time', controlType: 'label', }));
    controls.push(new DynformControl({ key: 'updated',     mk: 'common.updated', controlType: 'label', }));
    controls.push(new DynformControl({ key: 'enabled',     mk: 'common.enabled' }));
    controls.push(new DynformControl({ key: 'name',     mk: 'common.name', required: true }));

 
    controls.push(new DynformControl({ key: 'latitude',  mk: 'common.latitude' })); 
    controls.push(new DynformControl({ key: 'longitude',  mk: 'common.longitude' })); 

    controls.push(new DynformControl({ key: 'routeStart',     mk: 'bus.product.routeItem.routeStart' }));
    controls.push(new DynformControl({ key: 'routeEnd',     mk: 'bus.product.routeItem.routeEnd' }));

    controls.push(new DynformControl({ key: 'terminal',     mk: 'bus.product.routeItem.terminal' }));

    return controls;
  }

  generateControlsRelation(obj:any) {
    let controls: DynformControl[] = [];


    let isAccessory = obj.isAccessory;
    let isPool = obj.isPool;
    let isPackage = obj.isPackage;
    let isGroup = obj.isGroup;
    let isRelation = obj.isRelation;
    let isAccessoryOrPackage = isAccessory || isPackage;

    controls.push(new DynformControl({ key: 'id' }));
    controls.push(new DynformControl({ key: 'type'}));

    controls.push(new DynformControl({ key: 'mkType',   mk: 'common.type', controlType: "label" }));
    controls.push(new DynformControl({ key: 'time',     mk: 'common.time', controlType: 'label', }));
    controls.push(new DynformControl({ key: 'updated',  mk: 'common.updated', controlType: 'label', }));

    let children = this.fbo.products.filter((c:any) => c.id != this.p.id);
    //TODO: units

    let childOnChnage = (event:any) => {
      let cid = event.value;
      let child = this.fbo.productsMap[cid];
      if (!child) {
        this.edit.relation.prices = [];
        return;
      }

      this.apps.bas.ws.json( { 
        actionType: "appAdmin", 
        action: "getPrices",
        id: child.id
      }).then(json => {
        if (this.apps.bas.envtest) console.log("getPrices: ", json);
        this.edit.relation.prices = json.prices || [];
      });

    }

    childOnChnage({ value: obj.childId });

    controls.push(new DynformControl({ 
      key: 'child',  
      valuePath: "childId",
      mk: obj.mkType,  
      controlType: 'select', 
      required: true,
      options: () => {
        return children;
      },
      onChange: childOnChnage,
      optionsAllowEmpty: true,
      optionsFieldValue: "id",
      optionsFieldLabel: "name"
    }));
    
  
    controls.push(new DynformControl({ 
      key: 'orderStartDate',  
      mk: 'common.startDate',  
      controlType: 'select', 
      required: true,
      options: () => {
        return this.fbo.prOrderDates;
      },
      show: () => { return isAccessoryOrPackage; },
      // optionsAllowEmpty: true,
      // optionsFieldValue: "id",
      // optionsFieldLabel: "mkName"
    }));
    controls.push(new DynformControl({ 
      key: 'orderEndDate',  
      mk: 'common.endDate',  
      controlType: 'select', 
      required: true,
      options: () => {
        return this.fbo.prOrderDates;
      },
      show: () => { return isAccessoryOrPackage; }
      // optionsAllowEmpty: true,
      // optionsFieldValue: "id",
      // optionsFieldLabel: "mkName"
    }));

    let showTimeFields = () => {
      let cid = this.edit.relation.form.controls.child.value;
      let child = this.fbo.productsMap[cid];
      return child && child.timeProduct;
    }
    controls.push(new DynformControl({ key: 'orderStartTimeAsString',     mk: 'bus.productTime.startTime', controlType: "time-picker", show: showTimeFields }));
    controls.push(new DynformControl({ key: 'orderEndTimeAsString',     mk: 'bus.productTime.endTime', controlType: "time-picker", show: showTimeFields }));
   
    controls.push(new DynformControl({ key: 'bookAlways',     mk: 'common.mandatory', show: () => { return isAccessory; } }));
    controls.push(new DynformControl({ key: 'unlimited',     mk: 'bus.productRelation.unlimited', show: () => { return isAccessory; } }));
    controls.push(new DynformControl({ key: 'web',     mk: 'bus.product.web', show: () => { return isAccessory; }  }));
    
    controls.push(new DynformControl({ key: 'copyGuestCounts',     mk: 'bus.productRelation.copyGuestCounts', show: () => { return isAccessoryOrPackage; } }));
    
    controls.push(new DynformControl({ 
      key: 'price', 
      valuePath: "priceId", 
      mk: 'common.price', 
      controlType: 'select', 
      // required: true,
      optionsAllowEmpty: true,
      options: () => {
        return this.edit.relation.prices || [];
        // let cid = this.edit.relation.form.controls.child.value;
        // let child = this.fbo.productsMap[cid];

        // //console.log("child: ", child);
        // if (!child || !child.prices) return [];
        // let prices = this.apps.bas.us.mapToList(child.priceMap, child.prices);
        // return prices || [];
      },
      show: () => { return isAccessoryOrPackage || isGroup; } ,
      optionsFieldValue: "id",
      optionsFieldLabel: "name"
    
    }));
    
    /*
    TODO: 
    <div id="relationCircularDependencyWarning" class="hide bs-callout bs-callout-warning mt5 ">
						  <nit:message key="web.common.product.info.relationCircularDependencyWarning" defaultText="Denne relasjonen har en sirkulær avhengighet, noe som ikke er anbefalt og kan føre til uventene resultater."/>
						</div>
							
							<div class="prTrPool">
								<div class="form-group">
									<label class="col-sm-4 control-label" for="pr_childName"><nit:message key="common.name" />:</label>
									<div class="col-sm-8"><input id="pr_childName" type="text" name="pr_childName" class="form-control jpField "/></div>
								</div>
								<div class="form-group">
									<label class="col-sm-4 control-label" for="pr_childCap"><nit:message key="bus.product.cap" />:</label>
									<div class="col-sm-8"><input id="pr_childCap" type="text" name="pr_childCap" class="form-control jpField jpInteger jpPositive " /></div>
								</div>
							</div>
    */
    return controls;
  }

  generateControlsFile(obj:any) {
    let controls: DynformControl[] = [];

    controls.push(new DynformControl({ key: 'id' }));
    controls.push(new DynformControl({ key: 'name',             mk: 'common.name' }));
    controls.push(new DynformControl({ key: 'sendOnOrder',      mk: 'bus.productFile.sendOnOrder' }));
    controls.push(new DynformControl({ key: 'showOnWeb',        mk: 'bus.productFile.showOnWeb' }));
    controls.push(new DynformControl({ key: 'updated',          mk: 'common.updated',     controlType: 'label', }));
    controls.push(new DynformControl({ key: 'fileSizeDisplay',  mk: 'common.fileSize',    controlType: 'label', }));
    controls.push(new DynformControl({ key: 'filename',         mk: 'bus.image.filename', controlType: 'label', }));
    controls.push(new DynformControl({ key: 'user',             mk: 'common.user',        controlType: 'label', }));
    
    controls.push(new DynformControl({ 
      key: 'language',  
      valuePath: "languageCode",
      mk: 'common.language',  
      controlType: 'select', 
      options: () => {
        //TODO: vise flagg, kanskje egen controlType?
        return this.apps.bas.ds.config.appConfig?.enabledLanguages;
      },
      optionsAllowEmpty: true,
      optionsEmptyLabel: "common.international",
      optionsFieldValue:  "code",
      optionsFieldLabel: "mkName"
    }));

       
    if (obj.url && this.apps.bas.ds.login.success) {
      obj.urlAccess = obj.url + "&bttoken=" + this.apps.bas.ds.login.accessToken
    }

    return controls;
  }
  generateControlsInput(obj:any) {
    let controls: DynformControl[] = [];

    controls.push(new DynformControl({ key: 'id' }));
    controls.push(new DynformControl({ key: 'updated',              mk: 'common.updated', controlType: 'label', }));
    controls.push(new DynformControl({ key: 'enabled',              mk: 'common.enabled' }));
    controls.push(new DynformControl({ key: 'required',             mk: 'common.required' }));
    controls.push(new DynformControl({ key: 'guestInput',           mk: 'bus.productInput.guestInput' }));
    controls.push(new DynformControl({ key: 'includeInProductName', mk: 'bus.productInput.includeInProductName' }));
    controls.push(new DynformControl({ key: 'name',              mk: 'common.nameInternal' }));
    controls.push(new DynformControl({ key: 'configAsString',              mk: 'common.config', controlType: 'textarea', }));

    controls.push(new DynformControl({ 
      key: 'messages',
      controlType: 'formGroup',
      children: [
        new DynformControl({ 
          key: 'name',
          // valuePath: 'messages.name', 
          mk: 'common.name', 
          controlType: 'message',
          data: { controlType: "input" } }),

        new DynformControl({ 
          key: 'desc',
          // valuePath: 'messages.desc', 
          mk: 'bus.productInput.desc', 
          controlType: 'message',
          data: { controlType: "textarea" },
          show: (ctrl:DynformControl) => {
            let type = this.apps.bas.us.listToMap(this.fbo.inputTypes)[this.edit.input.controls.type.value];
            return type && (type.isContent);
          }
        }),

        new DynformControl({ 
          key: 'options',
          // valuePath: 'messages.options', 
          mk: 'bus.productInput.options', 
          controlType: 'message',
          data: { controlType: "textarea" },
          show: (ctrl:DynformControl) => {
            let type = this.apps.bas.us.listToMap(this.fbo.inputTypes)[this.edit.input.controls.type.value];
            return type && (type.isSelect || type.isCheckboxList || type.isRadioList);
          }
        }),
        new DynformControl({ 
          key: 'defaultValue',
          // valuePath: 'messages.defaultValue', 
          mk: 'common.defaultValue', 
          controlType: 'message',
          data: { controlType: "input" },
          show: (ctrl:DynformControl) => {
            let type = this.apps.bas.us.listToMap(this.fbo.inputTypes)[this.edit.input.controls.type.value];
            return type && (type.isSelect || type.isCheckboxList || type.isRadioList || type.isTextInput || type.isTextArea);
          }
         })
      ]
    }));

    controls.push(new DynformControl({ 
      key: 'type',  
      // valuePath: "typeId",
      mk: 'common.type',  
      controlType: 'select', 
      required: true,
      options: () => {
        return this.fbo.inputTypes;
      },
      optionsAllowEmpty: true,
      optionsFieldValue: "id",
      optionsFieldLabel: "mkName"
    }));
    


    return controls;
  }

  generateControlsMapItem(obj:any) {
    let controls: DynformControl[] = [];
    /*
      TODO: 
    	<div class="form-group">
								<label class="col-sm-4 control-label" for=""><nit:message key="common.updated"/>:</label>
								<div class="col-sm-8"><p class="form-control-static"><span id="mapItem_time"></span> / <span id="mapItem_updated"></span></p></div>
							</div>
							
							<div class="form-group">
								<label class="col-sm-4 control-label" for=""><nit:message key="common.order"/>:</label>
								<div class="col-sm-8"><p class="form-control-static" id="mapItem_order"></p></div>
							</div>
							
							<div class="form-group">
								<label class="col-sm-4 control-label" for="mapItem_type"><nit:message key="common.type"/>:</label>
								<div class="col-sm-8"><nit:select id="mapItem_type" name="mapItem_type" cssClass="form-control jpField jpRequire " items="${fbo.mapItemTypes}" allowEmpty="true" valueProperty="id" labelProperty="mk" /></div>
							</div>
							
							<div class="form-group mapItemDiv mapItemDiv-area mapItemDiv-outline">
								<label class="col-sm-4 control-label" for="mapItem_type"><nit:message key="common.color"/>:</label>
								<div class="col-sm-8"><nit:select id="mapItem_color" name="mapItem_color" cssClass="form-control jpField jpRequire " items="${fbo.colors}" allowEmpty="true" valueProperty="hex" labelProperty="label" /></div>
							</div>
							
							<div class="form-group">
								<label class="col-sm-4 control-label" for="mapItem_name"><nit:message key="common.name"/>:</label>
								<div class="col-sm-8"><input type="text" id="mapItem_name" name="mapItem_name" class="form-control jpField jpRequire " value="" /></div>
							</div>
							
							<div class="form-group mapItemDiv mapItemDiv-point">
								<label class="col-sm-4 control-label" for="mapItem_customIcon"><nit:message key="bus.appConfigItem.customIcon"/>:</label>
								<div class="col-sm-8"><nit:select id="mapItem_customIcon" name="mapItem_customIcon" cssClass="form-control jpField" items="${fbo.customMapItemIcons}" allowEmpty="true" /></div>
							</div>
							
							
							<div class="mapItemDiv mapItemDiv-area mapItemDiv-outline">
							<div id="mapItem_mkDesc_wrapper" class="hidden">
								
								<div class="form-group">
									<div class="col-sm-offset-4 col-sm-8">
										<nit:message key="common.description"/>
									</div>
								</div>
								
								<c:forEach items="${languages}" var="lang" varStatus="status">
									<div class="form-group">
										<label class="col-sm-4 control-label" for="mapItem_mkDesc_${lang.code}"><nit:message key="${lang.messageKey}"/>:</label>
										<div class="col-sm-8">
											
											<p id="mapItem_mkDesc_${lang.code}" class="ux-edit uxe-richtext mapItem_mkDesc form-control-static  translate-lang translate-lang-${lang.code}" data-uxe-message-key="" data-uxe-message-lang="${lang.code}"></p>
										</div>
									</div>
								</c:forEach>
							</div>
							</div>
    */
    return controls;
  }


  generateControlsImage(obj:any) {
    let controls: DynformControl[] = [];

    controls.push(new DynformControl({ key: 'id' }));
    controls.push(new DynformControl({ key: 'name',     mk: 'common.name' }));
    controls.push(new DynformControl({ 
      key: 'messages',
      controlType: 'formGroup',
      children: [
        new DynformControl({ 
          key: 'desc',
          // valuePath: 'messages.desc', 
          mk: 'common.description', 
          controlType: 'message',
          data: { controlType: "textarea" } })
        ]
    }));

    controls.push(new DynformControl({ 
      key: 'type',  
      // valuePath: "typeId",
      mk: 'common.type',  
      controlType: 'select', 
      options: () => {
        return this.fbo.imageTypes;
      }
    }));


    controls.push(new DynformControl({ key: 'updated',          mk: 'common.updated',     controlType: 'label', }));
    controls.push(new DynformControl({ key: 'fileSizeDisplay',  mk: 'common.fileSize',    controlType: 'label', }));
    controls.push(new DynformControl({ key: 'filename',         mk: 'bus.image.filename', controlType: 'label', }));
    controls.push(new DynformControl({ key: 'user',             mk: 'common.user',        controlType: 'label', }));
    
    controls.push(new DynformControl({ key: 'tags',             mk: 'common.tags' }));
    controls.push(new DynformControl({ key: 'copyright',        mk: 'common.copyright' }));

 

    return controls;
  }

  

  generateControlsTask(obj:any) {
    let controls: DynformControl[] = [];

    controls.push(new DynformControl({ key: 'id' }));
    controls.push(new DynformControl({ key: 'time',          mk: 'common.time',     controlType: 'label', }));
    // controls.push(new DynformControl({ 
    //   key: 'type',  
    //   valuePath: "typeId",
    //   mk: 'common.type',  
    //   controlType: 'select', 
    //   required: true,
    //   options: () => {
    //     return this.fbo.taskTypes;
    //   }, 
    //   optionsAllowEmpty: true,
    //   optionsFieldValue: "id", 
    //   optionsFieldLabel: "mkName"
    // }));



    return controls;
  }

  generateControlsLog(obj:any) {
    let controls: DynformControl[] = [];

    controls.push(new DynformControl({ key: 'id' }));
    controls.push(new DynformControl({ key: 'time',          mk: 'common.time',     controlType: 'label', }));
    controls.push(new DynformControl({ key: 'text',     mk: 'bus.log.text', controlType: "richtext" }));

    return controls;
  }




  updateControls(p:any) {
    let controls: DynformControl[] = [];

    let langs = this.apps.bas.ds.config.appConfig?.enabledLanguages;
    // console.log("langs:")

    let isAdmin = this.apps.bas.aas.isAdmin();
    let isBn = this.apps.bas.appLogin.user.username == "bn";

    controls.push(new DynformControl({ key: 'name',     mk: 'common.nameInternal',    required: true }));
    controls.push(new DynformControl({ key: 'desc',     mk: 'common.descriptionInternal',  controlType: 'textarea', data: { height: 140 } }));
    controls.push(new DynformControl({ key: 'enabled',  mk: 'common.enabled' }));
    controls.push(new DynformControl({ key: 'archived',  mk: 'common.archived', mkInfo: 'bus.product.archived.helpText' }));

    controls.push(new DynformControl({ key: 'unlimited',  mk: 'bus.product.unlimited' })); // , mkInfo: 'bus.product.archived.helpText'
    controls.push(new DynformControl({ key: 'count',      mk: 'bus.product.count',  controlType: 'input', type: "number", data: { min: 0 }, show: (control:DynformControl) => {
      return this.productForm.controls.unlimited.value === false;
    } })); // , mkInfo: 'bus.product.archived.helpText'
    
    controls.push(new DynformControl({ key: 'guestProduct',  mk: 'bus.product.guestProduct' })); 
    
    controls.push(new DynformControl({ key: 'minGuests',  mk: 'bus.product.minGuests' }));
    controls.push(new DynformControl({ key: 'maxGuests',  mk: 'bus.product.maxGuests' }));
    controls.push(new DynformControl({ key: 'defaultGuests',  mk: 'bus.product.defaultGuests' }));


    let gcChildren:DynformControl[] = []

    for (let i = 0; i < 5; i++) {

      gcChildren.push(new DynformControl({  
        key: 'gc_' + i + "_enabled", 
        // valuePath: 'gcs.gc_' + i + '_enabled', 
        mk: 'bus.product.gc' + i,
        disabled: i == 0,
        onChange: (event:any) => {  //  { control: '', event: event, prevValue: "", value: value }
          let parts = event.control.key.split("_");
          let gc = parseInt(parts[1]);
          
          let gcsfg = this.productForm.controls.gcs as UntypedFormGroup;

          let gcmfg = gcsfg.controls[ 'gc_' + gc + "_message"] as UntypedFormGroup;
          let mNo = gcmfg.controls.no;

          if (event.value == true) {
            
            if (gcsfg.controls[ 'gc_' + gc + "_maxGc"].value === 0) {
              if (this.apps.bas.envtest) console.log("onChange, removing macGc == 0: " + gc);
              gcsfg.controls[ 'gc_' + gc + "_maxGc"].setValue( "" );
            }


          } else {
            
            
            if (gcsfg.controls[ 'gc_' + gc + "_maxGc"].value !== 0) {
              if (this.apps.bas.envtest) console.log("onChange, setting macGc == 0: " + gc);
              gcsfg.controls[ 'gc_' + gc + "_maxGc"].setValue( 0 );
            }

            for (; gc < 4; gc++) {
              if (gcsfg.controls[ 'gc_' + gc + "_enabled"].value) {
                gcsfg.controls[ 'gc_' + gc + "_enabled"].setValue( false );
              }
            }

          }
       
          
        }
      })); 

      gcChildren.push(
        new DynformControl({ 
          key: 'gc_' + i + "_message",
          // valuePath: 'gcs.gc_' + i + '_message', 
          // mk: 'common.override', //TODO: overstyr navn/beskrivelse 
          controlType: 'message',
          data: {
            langs: langs,
            controlType: "input"
        } })
      );
      gcChildren.push(new DynformControl({  
        key: 'gc_' + i + "_maxGc", 
        // valuePath: 'gcs.gc_' + i + '_maxGc', 
        mk: 'common.maxCount',
        controlType: 'input',
        type: "number",
      })); 

    }

    
    controls.push(new DynformControl({ 
      key: 'gcs',
      controlType: 'formGroup',
      children: gcChildren
    }));

  

    let messagesChildren = [
      new DynformControl({ 
      key: 'g_name',
      // valuePath: 'messages.g_name', 
      mk: 'common.name', 
      controlType: 'message',
      data: {
        langs: langs,
        controlType: "input"
      } }),
      new DynformControl({ 
      key: 'g_ingress',
      // valuePath: 'messages.g_ingress', 
      mk: 'bus.product.ingress', 
      controlType: 'message',
      data: {
        langs: langs,
        controlType: "textarea"
      } })
      ,
      new DynformControl({ 
      key: 'g_description',
      // valuePath: 'messages.g_description', 
      mk: 'common.description', 
      controlType: 'message',
      data: {
        langs: langs,
        controlType: "richtext"
      } })
      
    ];

    controls.push(new DynformControl({ 
      key: 'messages',
      controlType: 'formGroup',
      children: messagesChildren
    }));
 


    
 

    controls.push(new DynformControl({ 
      key: 'type',  
      valuePath: "typeId",
      mk: 'bus.product.productType',  
      controlType: 'select', 
      required: true,
      options: () => {
        return this.fbo.types;
      }, 
      optionsAllowEmpty: true,
      optionsFieldValue: "id", 
      optionsFieldLabel: "mkName",
      onChange: (event:any) => {  //  { event: event, value: value }
        if (this.apps.bas.envtest) console.log("typeId.onChange");
        this.productForm.controls.subType.setValue("");
      }
    }));

    controls.push(new DynformControl({ 
      key: 'subType',  
      valuePath: "subTypeId",
      mk: 'bus.product.subType',  
      controlType: 'select', 
      options: () => {
        // console.log("subTypeId.options, typeId: " + this.productForm.controls.typeId.value);
        let ptid = this.productForm.controls.type.value || this.p.typeId;

        return this.fbo.subTypes[ptid];
      }, 
      validators: [
        (control: AbstractControl): { [s: string]: boolean } => {
          let fc = control as UntypedFormControl;
          
          if (fc.value !== "") return { };

          let ptid = this.productForm.controls.type.value || this.p.typeId;
          let pt = this.apps.bas.us.listToMap(this.fbo.types)[ptid];

          // console.log("cap, fc: " + fc.value + ", pt: ", pt);
          if (pt && pt.isLodging) return  { error: true, required: true };
          return { };
        }
      ],
      optionsAllowEmpty: true,
      optionsFieldValue: "id", 
      optionsFieldLabel: "mkName"
    }));
    // TODO: required hvis isLodging

    let pcHelpText = ''
    + '<span>'
      + '<strong>' + this.apps.bas.ui.actrans('bus.product.productConfig.name.Normal')  + '</strong>: ' + this.apps.bas.ui.actrans('bus.product.productConfig.desc.Normal') + '<br/>'
      + '<strong>' + this.apps.bas.ui.actrans('bus.product.productConfig.name.Package')  + '</strong>: ' + this.apps.bas.ui.actrans('bus.product.productConfig.desc.Package') + '<br/>'
      + '<strong>' + this.apps.bas.ui.actrans('bus.product.productConfig.name.Group')  + '</strong>: ' + this.apps.bas.ui.actrans('bus.product.productConfig.desc.Group') + '<br/>'
      + '<strong>' + this.apps.bas.ui.actrans('bus.product.productConfig.name.Collection')  + '</strong>: ' + this.apps.bas.ui.actrans('bus.product.productConfig.desc.Collection') + '<br/>'
      + '<strong>' + this.apps.bas.ui.actrans('bus.product.productConfig.name.Capacity')  + '</strong>: ' + this.apps.bas.ui.actrans('bus.product.productConfig.desc.Capacity') + '<br/>'
      + (p.pcUnit ?  '<strong>' + this.apps.bas.ui.actrans('bus.product.productConfig.name.Unit')  + '</strong>: ' + this.apps.bas.ui.actrans('bus.product.productConfig.desc.Unit') : '') 
    
      +  '</span>'
    ;


    controls.push(new DynformControl({ 
      key: 'productConfigId',   
      mk: 'bus.product.productConfig',  
      mkInfo: pcHelpText, //'bus.product.productConfig.helpText',
      controlType: 'select', 
      required: true,
      options:  () => {
        return this.fbo.productConfigs;
      }, 
      optionsFieldValue: "id", 
      optionsFieldLabel: "mkName"
    }));

    let ptHelpText = ''
    + '<span>'
      + '<strong>' + this.apps.bas.ui.actrans('bus.product.timeConfig.name.None')  + '</strong>: ' + this.apps.bas.ui.actrans('bus.product.timeConfig.desc.None') + '<br/>'
      + '<strong>' + this.apps.bas.ui.actrans('bus.product.timeConfig.name.Time')  + '</strong>: ' + this.apps.bas.ui.actrans('bus.product.timeConfig.desc.Time') + '<br/>'
      + '<strong>' + this.apps.bas.ui.actrans('bus.product.timeConfig.name.Date')  + '</strong>: ' + this.apps.bas.ui.actrans('bus.product.timeConfig.desc.Date') + '<br/>'
      + '<strong>' + this.apps.bas.ui.actrans('bus.product.timeConfig.name.Period')  + '</strong>: ' + this.apps.bas.ui.actrans('bus.product.timeConfig.desc.Period') + '<br/>'
    
      +  '</span>'
    ;
    controls.push(new DynformControl({ 
      key: 'timeConfigId',   
      mk: 'bus.product.timeConfig',  
      mkInfo: ptHelpText, 
      controlType: 'radio-button', 
      radioButtonStyle: "solid", 
      required: true,
      options:  () => {
        let ptid = this.productForm.controls.type.value || this.p.typeId;
        return ptid ? this.apps.bas.us.listToMap(this.fbo.types)[ptid]?.timeConfigs : this.fbo.timeConfigs;
      }, 
      optionsFieldValue: "id", 
      optionsFieldLabel: "mkName"
    }));

    controls.push(new DynformControl({ 
      key: 'vatInfo',  
      valuePath: "vatInfoId", 
      mk: 'bus.vatInfo',  
      mkInfo: 'bus.product.vatInfo.helpText',  //TODO: inneholder HTML
      controlType: 'select', // 'radio-button', 
      // radioButtonStyle: "solid", 
      options:  () => {
        return this.fbo.vatInfos;
      },
      validators: [
        (control: AbstractControl): { [s: string]: boolean } => {
          let fc = control as UntypedFormControl;

          let pcid = this.productForm.controls.productConfigId.value;
          let pc =  this.apps.bas.us.listToMap(this.fbo.productConfigs)[pcid];


          if (!control.value && ( !pc || !(pc.isPackage || pc.isUnit || pc.isCollection)))  return  { error: true, required: true };

          return { };
        }
      ], 
      optionsAllowEmpty: true,
      optionsFieldValue: "id", 
      optionsFieldLabel: "label"
    }));
    

    controls.push(new DynformControl({ key: 'findProvider',  mk: 'common.provider', controlType: "label" }));  // controls.findProvider,
    controls.push(new DynformControl({ 
      key: 'provider',   
      valuePath: "providerId",
      mk: 'common.provider',  
      controlType: 'select', 
      disabled: !isAdmin, 
      options:  () => {
        return this.fbo.providers || [];
      }, 
      optionsAllowEmpty: true,
      optionsFieldValue: "id", 
      optionsFieldLabel: "label"
    }));

    controls.push(new DynformControl({ 
      key: 'providerPercent',     
      mk: 'bus.common.providerPercent',  
      controlType:'input',
      disabled: !isAdmin, 
      type: "number",
      show:  (control:DynformControl) => {
        return !!this.productForm.controls.provider.value;
      },
      validators: [
        (control: AbstractControl): { [s: string]: boolean } => {
          let fc = control as UntypedFormControl;
          let pid = this.productForm.controls.provider.value;
          let pa = this.productForm.controls.providerAmountDouble.value;
          if (pa !== "") return { };

          if (pid && !control.value) return  { error: true, required: true };
          return { };
        }
      ]
    }));
    
    controls.push(new DynformControl({ 
      key: 'providerAmountDouble',  
      mk: 'bus.common.providerAmount', 
      controlType: "input", 
      disabled: !isAdmin, 
      type: "number",  
      show:  (control:DynformControl) => {
        return !!this.productForm.controls.provider.value && control.value !== "";
      } 
    })); 
    // TODO: bus.common.providerAmount.warning

    // , mkInfo: 'bus.product.archived.helpText'

    controls.push(new DynformControl({ key: 'web',  mk: 'bus.product.web' })); 
    controls.push(new DynformControl({ key: 'overview',  mk: 'bus.product.overview' })); 
    controls.push(new DynformControl({ key: 'frontpage',  mk: 'bus.product.frontpage' })); 
    controls.push(new DynformControl({ key: 'featured',  mk: 'common.featured', disabled: !isAdmin }));  
    controls.push(new DynformControl({ key: 'orderable',  mk: 'bus.product.orderable' })); 
    
    controls.push(new DynformControl({ key: 'minDays',  mk: 'bus.product.minDays',  controlType: 'input', type: "number", data: { min: 0 } })); 
    controls.push(new DynformControl({ key: 'defaultDays',  mk: 'bus.product.defaultDays',  controlType: 'input', type: "number", data: { min: 0 } })); 
    controls.push(new DynformControl({ key: 'minDaysToArrival',  mk: 'bus.product.minDaysToArrival',  controlType: 'input', type: "number", data: { min: 0 } })); 
    controls.push(new DynformControl({ key: 'maxDaysToArrival',  mk: 'bus.product.maxDaysToArrival',  controlType: 'input', type: "number", data: { min: 0 } })); 
   
    controls.push(new DynformControl({ key: 'orderTimeThreshold',  mk: 'bus.product.orderTimeThreshold', controlType: "time-picker", data: {  } })); 
    controls.push(new DynformControl({ key: 'orderTimeStart',  mk: 'bus.product.orderTimeStart', controlType: "time-picker", data: {  } })); 
    controls.push(new DynformControl({ key: 'orderTimeEnd',  mk: 'bus.product.orderTimeEnd', controlType: "time-picker", data: {  } })); 
   
    // eventDiv

    let cmEventTicket = this.apps.bas.ds.config.company.upCmEventTicket;


    controls.push(new DynformControl({ key: 'eventTicket',  mk: 'bus.product.eventTicket', show: () =>  cmEventTicket }));
    controls.push(new DynformControl({ key: 'venue',  mk: 'common.venue' })); 

    // activityDiv (og event )
    controls.push(new DynformControl({ key: 'reportActivityInclude',  mk: 'bus.product.reportActivityInclude' })); 
    controls.push(new DynformControl({ key: 'activityReportDays',  mk: 'bus.product.activityReportDays',  controlType: 'input', type: "number", data: { min: 0 } })); 
    controls.push(new DynformControl({ key: 'activityReportTime',  mk: 'bus.product.activityReportTime', controlType: "time-picker", data: {  } })); 
    
    // lodgingSettingsDiv
    controls.push(new DynformControl({ key: 'cap',  mk: 'bus.product.cap',  controlType: 'input', type: "number", data: { min: 1 },
      validators: [
        (control: AbstractControl): { [s: string]: boolean } => {
          let fc = control as UntypedFormControl;
          
          if (fc.value !== "") return { };

          let ptid = this.productForm.controls.type.value || this.p.typeId;
          let pt = this.apps.bas.us.listToMap(this.fbo.types)[ptid];

          // console.log("cap, fc: " + fc.value + ", pt: ", pt);
          if (pt && pt.isLodging) return  { error: true, required: true };
          return { };
      }
    ] })); 
    controls.push(new DynformControl({ key: 'xcap',  mk: 'bus.product.xcap',  controlType: 'input', type: "number", data: { min: 0 } }));
    // - keyOutletProviderId   --- - er denne i bruk?  items="${fbo.keyOutletProviders}" allowEmpty="true" valueProperty="id" labelProperty="up.findKeyOutletProvider"

    // all
    controls.push(new DynformControl({ key: 'reportHytteformidlingInclude',  mk: 'bus.product.reportHytteformidlingInclude' })); 
    controls.push(new DynformControl({ key: 'reportSsbRa0366Include',  mk: 'bus.product.reportSsbRa0366Include' })); 
    

    controls.push(new DynformControl({ key: 'address1',  mk: 'common.address' })); 
    controls.push(new DynformControl({ key: 'zipCode',  mk: 'common.zipCode' })); 
    controls.push(new DynformControl({ key: 'city',  mk: 'common.city' })); 
    

    controls.push(new DynformControl({ 
      key: 'area',   
      valuePath: "areaId",
      mk: 'bus.area',  
      controlType: 'select', 
      options:  () => {
        return this.fbo.areas || [];
      }, 
      optionsAllowEmpty: true,
      optionsFieldValue: "id", 
      optionsFieldLabel: "mkName"
    }));
    

    let upCModSkattUtleieFraFormidlingsselskap = this.apps.bas.ds.config.company.upCModSkattUtleieFraFormidlingsselskap;
    controls.push(new DynformControl({ key: 'kommuneNr',  mk: 'common.kommuneNr', show: () => upCModSkattUtleieFraFormidlingsselskap })); 
    controls.push(new DynformControl({ key: 'gardsNr',  mk: 'common.gardsNr', show: () => upCModSkattUtleieFraFormidlingsselskap })); 
    controls.push(new DynformControl({ key: 'bruksNr',  mk: 'common.bruksNr', show: () => upCModSkattUtleieFraFormidlingsselskap })); 
    

  
    controls.push(new DynformControl({ key: 'templateProduct',  mk: 'bus.product.templateProduct', show: () => this.apps.bas.ds.config.company.upCProductFeatureTemplate })); 
    controls.push(new DynformControl({ key: 'parentCompany',  mk: 'bus.product.parentCompany', show: () => this.apps.bas.ds.config.company.ucObj.hasParent  }));  
    controls.push(new DynformControl({ key: 'showInProductLists',  mk: 'bus.product.showInProductLists' }));  
    
    
    // Disse gjelder vel bare tidsprodukter, eller nei? 
    controls.push(new DynformControl({ key: 'showTime',  mk: 'bus.product.showTime' })); 
    controls.push(new DynformControl({ key: 'showEndTime',  mk: 'bus.product.showEndTime' })); 
    controls.push(new DynformControl({ key: 'mapProduct',  mk: 'bus.product.mapProduct', show: () => isBn })); 
    
    
    controls.push(new DynformControl({ key: 'providerConfirmationRequired',  mk: 'bus.product.providerConfirmationRequired', show: () => this.apps.bas.ds.config.company.upCOrderProviderConfirmationRequiredEnabled }));  //TODO: C_ORDER_PROVIDER_CONFIRMATION_REQUIRED_ENABLED
    controls.push(new DynformControl({ key: 'availabilityInfo',  mk: 'bus.product.availabilityInfo' })); 
    controls.push(new DynformControl({ key: 'ppgAvailabilityInfoProviderMail',  mk: this.fbo.ppMap['ppgAvailabilityInfoProviderMail'].mkName }));  // TODO: er egentlig en egenskap 
    controls.push(new DynformControl({ key: 'userAddressRequired',  mk: 'bus.product.userAddressRequired' })); 
    controls.push(new DynformControl({ key: 'showCampaignCodeField',  mk: 'bus.product.showCampaignCodeField' })); 
    controls.push(new DynformControl({ key: 'showInRelatedProductsList',  mk: 'bus.product.showInRelatedProductsList' })); 
    
    // 
    controls.push(new DynformControl({ key: 'singleDateProduct',  mk: 'bus.product.singleDateProduct' })); 
    controls.push(new DynformControl({ key: 'daysDropdownEnabled',  mk: 'bus.product.daysDropdownEnabled' })); 
    controls.push(new DynformControl({ key: 'daysDropdownMin',  mk: 'bus.product.daysDropdownMin',  controlType: 'input', type: "number", data: { min: 0 } })); 
    controls.push(new DynformControl({ key: 'daysDropdownMax',  mk: 'bus.product.daysDropdownMax',  controlType: 'input', type: "number", data: { min: 0 } })); 
    
    controls.push(new DynformControl({ key: 'tripAdvisorWidget',  mk: 'bus.product.tripAdvisorWidget',  controlType: 'textarea' })); 
    controls.push(new DynformControl({ key: 'embeddedVideo',  mk: 'bus.product.embeddedVideo',  controlType: 'textarea' })); 
    controls.push(new DynformControl({ key: 'jsonDataString',  mk: 'common.data',  controlType: 'textarea' })); 
    controls.push(new DynformControl({ key: 'comment',  mk: 'common.comment',  controlType: 'textarea' })); 
    
    controls.push(new DynformControl({ key: 'externalOrderSystemUrl',  mk: 'bus.product.externalOrderSystemUrl', show: () => this.apps.bas.ds.config.company.upCBookingExternalOrderSystem })); 
    controls.push(new DynformControl({ key: 'pid',  mk: 'bus.product.pid' })); 

    controls.push(new DynformControl({ key: 'ppgIncludeGeneratedProductPdf',  mk: 'bus.product.ppgIncludeGeneratedProductPdf' })); 

    controls.push(new DynformControl({ 
      key: 'channexEnabled',  mk: 'bus.product.channexEnabled',
      show:  (control:DynformControl) => {
        return this.apps.bas.ds.config.company.upCmIntChannex;
      },
     })); 

    

    controls.push(new DynformControl({ 
      key: 'guestCountIsQuantity',   
      mk: 'bus.productType.guestCountIsQuantity',  
      controlType: 'select', 
      options:  () => {
        return this.fbo.yesNos || [];
      }, 
      optionsAllowEmpty: true,
      optionsEmptyLabel: "---- Standard ----",  //TODO:text
      optionsFieldValue: "bool", 
      optionsFieldLabel: "mkName"
    }));

    this.fbo.availableTagsAsOptions = this.fbo.availableTags == "" ? [] : this.fbo.availableTags.split(",").map((x:string) => { return { value: x } }); 
    controls.push(new DynformControl({ 
      key: 'tagsAsString',  
      mk: 'common.tags',  
      // mkInfo: 'bus.product.vatInfo.helpText',
      controlType: 'select', 
      selectMode: "tags", 
      options:  () => {
        return this.fbo.availableTagsAsOptions;
      }, 
      optionsFieldValue: "value", 
      optionsFieldLabel: "value"
    }));

    this.fbo.availableGroupTagsAsOptions = this.fbo.availableGroupTags == "" ? [] : this.fbo.availableGroupTags.split(",").map((x:string) => { return { value: x } }); 
    controls.push(new DynformControl({ 
      key: 'groupTagsAsString',  
      mk: 'bus.product.groupTags',  
      // mkInfo: 'bus.product.vatInfo.helpText',
      controlType: 'select', 
      selectMode: "tags", 
      options:  () => {
        return this.fbo.availableGroupTagsAsOptions;
      }, 
      optionsFieldValue: "value", 
      optionsFieldLabel: "value"
    }));
    

    controls.push(new DynformControl({ key: 'latitude',  mk: 'common.latitude' })); 
    controls.push(new DynformControl({ key: 'longitude',  mk: 'common.longitude' })); 

    controls.push(new DynformControl({ key: 'jsonDataString',  mk: 'common.data', controlType: "textarea" })); 

    

    /*
    TOOD:

    Avventer:
    - providerAmount  ?? -- må vel ha den med kanskje, men legge inn advarsel. 
    - chain (er denne i bruk?)
    - requireAdditionalInfo (ikke i bruk lenger)
    - orderView  // HVA er dette?

  FANER: 
    - Relasjoner
      - units
    
    - Egenskaper
      - copyPropertiesFromProduct

    - mapItems



     (Skal denne bruke lenger: )
    - apInclude 
    - isAdditionalProduct
    - apMandatory
    - apForType
    - apFromDate
    - apToDate
    - apUnlimited
    - apBookArrival
    - apBookStaying
    - apBookDeparture

    BN:
    - minCount (bn)
    - vatInfoCompanyId
    - confirmationRequired
    - p_fastCheckout
    - p_pmVippsEnabled
    - p_stock
    - p_purchasePriceAsDouble
    - p_barcode

    */

    
    controls.push(new DynformControl({ 
      key: 'logText',  
      mk: 'bus.log.text',
      controlType: "textarea"
    })); 

    return controls;

   
  }

  onChange(event:any) {
    if (!event.control) return;
    this.dirty = true;
    // console.log("onChange: ", event);

    // if (event.control.onChange) {
    //   event.control.onChange(event);
    // }
  }

  @HostListener('document:keydown.control.s',['$event']) 
  ctrlS(event: KeyboardEvent) {
    // console.log("ctrlS: ", event);

    this.submitForm();
    if (event) event.preventDefault();
    return false;
  }

  
  getVisibleSections() {
    let list:any = [];
    for (let s of Object.values(this.Sections)) {
      if (this.isSectionVisible(s.id)) list.push(s);
    }
    return list;
  }

  isSectionVisible(section:string, button:boolean = false) {
    // let visible = this.activeSection == "all" || this.activeSection == section;
    // if (!visible && !button) return false; 

    let isBn = this.apps.bas.appLogin.user.username == "bn";


    let p = this.p;
    let ctrls = this.productForm.controls;
    let ptid = ctrls.type.value || this.p.typeId;
    let pt = this.apps.bas.us.listToMap(this.fbo.types)[ptid];

    let tcid = ctrls.timeConfigId.value || this.p.timeConfigId;
    let tc = this.apps.bas.us.listToMap(this.fbo.timeConfigs)[tcid];

    let pcid = ctrls.productConfigId.value || this.p.productConfigId;
    let pc = this.apps.bas.us.listToMap(this.fbo.productConfigs)[pcid];

    // let isGroupOrPackage = ctrls.packageProduct.value === true || ctrls.groupProduct.value === true;

    switch (section) {
      case "accommodation": 
        if (!pt || !pt.isLodging) return false; break;
      case "actEvent": 
        if (!pt || !pt.isActOrEvent) return false; break;
      case "times": 
        if (!tc || !tc.isTime) return false; break;
      case "periods": 
        if (!tc || !tc.isPeriod) return false; break;
      case "prices":
        //console.log("pc: ", pc)
        // if (pc.isRoute) return true; 
        if (!pc.isRoute && !pc.isNormal) return false; 
        break;
      case "packageItems": 
        return pc.isPackage; break;
      case "groupItems": 
        return pc.isGroup; break;

      case "units": 
        // TODO:     $cb.show($(".relation-unit"), ( $("#relTable_1 tbody tr").length > 0 || $cb.data.isBn) && isNormal );
          return isBn && pc.isNormal; break;
      case "routeItems": 
        return pc.isRoute; break;
      default: break;
    }


    return true;
  }

  getProduct(id:any) {

    if (this.apps.bas.envtest) console.log("getProduct, id: " + id + ", queryParams: ", this.route.snapshot.queryParams);

    let options:any = { jil: "all" };

    // if (id == "new" && this.route.snapshot.queryParams.copy) options.copy = this.route.snapshot.queryParams.copy;

    this.apps.bas.aas.getProduct(id, options).then((json) => {
      if (this.apps.bas.envtest) console.log("getProduct.then: ", json);

  
      let fbo = json.fbo;

      fbo.productsMap = this.apps.bas.us.listToMap(fbo.products, "id");

      this.fbo = fbo;

      let p = json.product;

      this.setProduct(p);
      this.isSpinning = false;



    });
  } 

  setProduct(p:any) {
    p.properties = !p.properties ? [] : p.properties.filter((ppId:number) => {
      let pp = p.propertyMap[ppId];
      return !pp.typeObj.ptHasTagEssential;
    });

    p.logText = "";

    let controls = this.updateControls(p);

    this.apps.bas.fs.updateControls(controls, p);
    this.productForm = this.apps.bas.fs.toFormGroup(controls);
    // console.log("after: fs.toFormGroup: ");
    this.controls = this.apps.bas.fs.toMap(controls);
    
    this.updateAddForms(p);
    this.updateProductLogs(p);
    // this.updateProductTasks(p);


    let timesParents = [];
    for (let tid of p.times || []) {
      let time = p.timeMap[tid];
      if (time.hasParent) continue;
      timesParents.push(tid);
    }
    p.timesParents = timesParents;

  
    
    this.p = p;
    this.dirty = false;
  }

  updateProductLogs(p:any) {
    if (!p.id) return; 

    this.apps.bas.ws.json( { 
      actionType: "product", 
      action: "getProductLogs",
      id: p.id
    }).then(json => {
      if (this.apps.bas.envtest) console.log("updateLogs, logs: ", json);
      p.logs = json.logs || [];
    });


  }

  updateProductTasks(p:any) {
    if (!p.id) return; 

    this.apps.bas.ws.json( { 
      actionType: "product", 
      action: "getProductTasks",
      id: p.id
    }).then(json => {
      if (this.apps.bas.envtest) console.log("updateProductTasks, logs: ", json);
      
      p.tasks = json.tasks || [];
    });


  }

  addNewTaskItem() {
    let title = this.add.task.taskItemTitle;

    this.edit.obj.itemTitles.push(title);

    this.add.task.taskItemTitle = "";

  }

  add:any = {
    property: {
      controls: { },
      form: undefined
    },
    task: {
      taskItemTitle: ""
    }
  }

  updateAddForms(p:any) {
    let propertyControls: DynformControl[] = [];


 

   

   
    propertyControls.push(new DynformControl({ 
      key: 'type',  
      valuePath: "typeId",
      mk: 'common.type',  
      controlType: 'select', 
      required: true,
      options: () => {
         //TODO, ta bort de som allerede er i bruk
         
        let ptsInUse:any = { };
    
         (this.p.properties || []).map((ppId:number) => {
          let pp = p.propertyMap[ppId];
          ptsInUse[pp.typeId] = true;
        });

        let exclude:any = {
          "gRequireAdditionalInfoText": true,
          "gRequireAdditionalInfoValues": true,

        };

         let propertyTypesFiltered = this.fbo.propertyTypes.filter((pt:any) => {
          if ( pt.ptHasTagEssential) return false;
          if ( exclude[pt.tid] ) return false;
          
          if ( ptsInUse[pt.id] ) return false; 
          return true;
        });

        return propertyTypesFiltered; // Object.values(this.fbo.propertyTypes);
      },
      optionsAllowEmpty: true,
      optionsFieldValue: "id",
      optionsFieldLabel: "mkName"
    }));

    this.apps.bas.fs.updateControls(propertyControls, { typeId: "" });
    this.add.property.form = this.apps.bas.fs.toFormGroup(propertyControls);
    this.add.property.controls = this.apps.bas.fs.toMap(propertyControls);


  }

  onMapSelectPosition(latLng:google.maps.LatLng) {
    // console.log("onMapSelectPosition, latLng: ", latLng);
    // console.log("controls: ", latLng);
    if (this.mapType == "product") {

      this.productForm.controls.latitude.setValue(latLng.lat());
      this.productForm.controls.longitude.setValue(latLng.lng());
    } else {
      this.edit[this.mapType].form.controls.latitude.setValue(latLng.lat());
      this.edit[this.mapType].form.controls.longitude.setValue(latLng.lng());
    }
    
  }
  onMapSelectPositionCancel() {
    // console.log("onMapSelectPositionCancel, restoring value"
    //   + ", lat: " + this.productForm.controls.latitude.value + " -> " + this.p.latitude
    //   + ", lng: " + this.productForm.controls.longitude.value + " -> " + this.p.longitude
    // );
    
    if (this.mapType == "product") {
      this.productForm.controls.latitude.setValue(this.p.latitude);
      this.productForm.controls.longitude.setValue(this.p.longitude);
  
    } else {
      this.edit[this.mapType].form.controls.latitude.setValue(this.edit[this.mapType].obj.latitude);
      this.edit[this.mapType].form.controls.longitude.setValue(this.edit[this.mapType].obj.longitude);
    }

    this.mapVisible = false;
  }

  openMap($event:Event, mapType:string) {
    $event.preventDefault();
    this.mapType = mapType;

    // mapType = 'product'; 
    let pCoords =  { 
      latitude: this.productForm.controls.latitude.value || this.apps.bas.ds.config.company.ucObj.latitude, 
      longitude: this.productForm.controls.longitude.value || this.apps.bas.ds.config.company.ucObj.longitude 
    };
    if (this.mapType == "product") { 
      // return pCoords
    } else {
      pCoords =  {
        latitude: this.edit[this.mapType].form.controls.latitude.value || pCoords.latitude,
        longitude: this.edit[this.mapType].form.controls.longitude.value || pCoords.longitude
      }
    }

    this.p.mapCoords = pCoords;
    this.mapVisible = true; 
  }
  // let pCoords =  { 
  //   latitude: this.productForm.controls.latitude.value || this.apps.bas.ds.config.company.ucObj.latitude, 
  //   longitude: this.productForm.controls.longitude.value || this.apps.bas.ds.config.company.ucObj.longitude 
  // };
  // if (this.mapType == "product") { 
  //   // return pCoords
  // } else {
  //   pCoords = {
  //     latitude: this.edit[this.mapType].form.controls.latitude.value || pCoords.latitude,
  //     longitude: this.edit[this.mapType].form.controls.longitude.value || pCoords.longitude
  //   }
  // }
  // p.mapCoords = pCoords;


  submitForm(): void {


    for (let type in this.edit) {
      
      if (this.edit[type].obj) {
        
        if (this.apps.bas.envtest) console.log("submitForm: Saving unsaved edit item: " + type);
        let saveRes = this.saveItem(type, this.edit[type].subType, this.edit[type].parentObj);
        if (!saveRes) return;
      }
    }


  
    let rv = this.apps.bas.fs.getRawValue(this.productForm, this.controls);
    if (rv === false) return;

    rv.changes = this.changes;
    // rv.logText = this.logText;
    if (this.apps.bas.envtest) console.log("AdminProductPageComponent.submitForm, rv: ", rv)

    this.isSpinning = true;

    let isNew = "new" == this.route.snapshot.params.id;
    
    this.apps.bas.aas.saveProduct(this.p.id || "new", rv).then(res => {
      this.isSpinning = false;
      this.changes = { };

      if (this.apps.bas.envtest) console.log("AdminProductPageComponent.submitForm.then: ", res);

      if (!res.success) {

        return;
      }

      this.apps.bas.ui.success("Produktet er lagret"); //TODO:text

      this.setProduct(res.product);
      if (isNew) {

        this.router.navigate([
          this.apps.bas.ui.getRouterPrefix() + '/admin/product/' + res.product.id
        ]);
        //  
        // return;
      }
     

    }).catch(err =>  {
      this.isSpinning = false;

      if (this.apps.bas.envtest) console.log("AdminProductPageComponent.submitForm.err: ", err);
    })


  }


  // @HostListener('window:beforeunload', ['$event'])
  // onBeforeunload(e:any) {
  //   if(this.dirty) {
  //     e.preventDefault();
  //     e.returnValue = this.apps.bas.ui.actrans("web.common.product.leaveWarning"); 
  //   }

  // }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    // insert logic to check if there are pending changes here;
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away

    return !this.dirty;
  }


}
