
<btlib-admin-page-wrapper>
  
  <div class="m-d">
    <h1>{{ 'common.admin' | actrans }}</h1>

    <div nz-row [nzGutter]="[8, 8]">
      <div nz-col [nzXs]="12" [nzSm]="8" [nzMd]="8" [nzLg]="6" [nzXl]="4" [nzXXl]="3">
        <button nz-button nzBlock [routerLink]="apps.bas.ui.getRouterPrefix() + '/admin/orders'" >Bookinger</button>
      </div>
      <div nz-col [nzXs]="12" [nzSm]="8" [nzMd]="8" [nzLg]="6" [nzXl]="4" [nzXXl]="3">
        <button nz-button nzBlock  [routerLink]="apps.bas.ui.getRouterPrefix() + '/admin/order-overview'">Bookingoversikt</button>
      </div>
      <div nz-col [nzXs]="12" [nzSm]="8" [nzMd]="8" [nzLg]="6" [nzXl]="4" [nzXXl]="3">
        <button nz-button nzBlock [routerLink]="apps.bas.ui.getRouterPrefix() + '/admin/products'">Produkter</button>
      </div>
    </div>


    <h2 class="mt-d">{{ 'web.common.stats' | actrans }}</h2>

       <!--
           TODO:
           Statistikk: 
             - Gjennomsnittspris per natt
             - beløp: 2000 / rom i systemet: 10 = 200  ( REVPAR )
             - Gjestedøgn
             - Belegg 
  


        -->

    <div nz-row>
      <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="16" [nzLg]="16" [nzXl]="12" [nzXXl]="12">
      
        <nz-table 
          #nzTableStats
          [nzData]="apps.bas.us.objectValues((apps.bas.ds.get('APP_ADMIN_ORDER_STATS') | async).orderStats?.sums || { })" 
          nzTableLayout="fixed" 
          [nzShowPagination]="false" 
          [nzPageSize]="100"
          nzSize="small"
          class="nz-table-xs nz-table-striped"
          >
          <thead>
            <tr>
              <!-- <th *ngIf="apps.bas.ui.r.gtesm" nzWidth="30px"></th> -->
              <th nzAlign="left" >{{ 'common.type' | actrans }}</th>
              <th nzAlign="left" >{{ 'web.common.stats' | actrans }}</th>
              <th nzAlign="right" >{{ 'common.amount' | actrans }}</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let row of nzTableStats.data; let idx = index">
              <tr>
                <!-- <td *ngIf="apps.bas.ui.r.gtesm">{{ data.sortOrder }}</td> -->
                <td nzAlign="left">{{ row.pt.label }}</td>
                <td nzAlign="left">{{ row.mkLabel }}</td>
                <td nzAlign="right">{{ row.amountDouble | nf }}</td>
              </tr>
            </ng-container>
          </tbody>
        </nz-table>

        
      </div>
    </div>


  </div>

</btlib-admin-page-wrapper>



