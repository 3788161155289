import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { DynformControl } from 'src/app/lib/core/model/dymform-control';
import { MiscUtil } from 'src/app/lib/core/util/misc.util';
import { DATAID } from '../../../lib/core/services/data.service';

import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-admin-products-page',
  templateUrl: './admin-products-page.component.html',
  styleUrls: ['./admin-products-page.component.scss']
})
export class AdminProductsPageComponent implements OnInit {

  route: ActivatedRoute;
  // router: Router;

  fbo:any = {
    loading: true,
    statsObj: {
      navigatorObj: {
        size: 0,
        pageSize: 50,
        page: 0
      }
    }

  };

  df:any = {
    form: undefined,
    controls: [],
    obj: undefined,
    params: {  },
    statsForm: { 
      statsType: "PRODUCT",
      currentJil: "min",
    }
  };

  constructor(public injector:Injector, public apps:AppService) {
    // this.router = injector.get(Router);
    this.route = injector.get(ActivatedRoute);
    this.apps.bas.us.checkActivatedRoute(this.route.snapshot);

    if(this.apps.bas.envtest) console.log("AdminProductsPageComponent");
    
    if (this.apps.bas.ds.login.success) {
    
    }

    this.apps.bas.ds.get(DATAID.APP_LOGIN).subscribe(res => {
      if(this.apps.bas.envtest) console.log("AdminProductsPageComponent.APP_LOGIN.subscribe: ", res);

      // this.updateProducts();
    })



  }

  
  onQueryParamsChange(queryParams: NzTableQueryParams): void {
    if (this.apps.bas.envtest) console.log("onQueryParamsChange: ", queryParams);
    const { pageSize, pageIndex, sort, filter } = queryParams;
    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || null;
    const sortOrder = (currentSort && currentSort.value) || null;

    let params:any = { };
    
    let page = pageIndex !== undefined && pageIndex > 0 ? pageIndex -1 : undefined;
    // let nav = this.fbo.stats.navigator;
    // if ((page !== undefined && nav.page != page )) { 
    //   params.navAction = "page";
    //   params.navParam = page;
    // } else if ((pageSize !== undefined && nav.pageSize != pageSize )) { 
    //   params.navAction = "pageSize";
    //   params.navParam = pageSize;
    // }

    
    let sfi:any = { }
        
    if (page !== undefined) sfi.navPage = page;
    if (pageSize !== undefined)  sfi.navPageSize = pageSize;


    this.updateFbo(params, sfi);
  }

  
  dfSubmit() {
    this.updateFbo({},  { 
      navPageSize: this.fbo.statsObj.navigatorObj.pageSize,
      navPage: 0, //this.fbo.statsObj.navigatorObj.page
    });
  }

  updateFbo(input:any = { }, statsFormInput:any = { }) {
    let params:any = {
      aType: "appAdmin",
      action: "getProductsFbo"
    };

    let rv = { };
    if (this.df.form) {
      rv = this.apps.bas.fs.getRawValue(this.df.form, this.df.controls);
      if (rv === false) return;
  
    }

    
    // statsFormInput.customFiltersMap = statsFormInput.customFiltersMap || { };
    // for (let key in this.customFilters) {
    //   let val = this.customFilters[key];
    //   if (val == undefined || val == "") continue; 
    //   statsFormInput.customFiltersMap[key] = { value: val, type: "search" };
    // }

    MiscUtil.extend(rv,  statsFormInput);

 
    let statsForm = MiscUtil.extend({ }, this.df.statsForm);
    MiscUtil.extend(statsForm, rv);

    MiscUtil.extend(params, input);

    MiscUtil.extend(params, this.df.params);
    MiscUtil.extend(params, { 
      statsForm: JSON.stringify(statsForm),
      // navForm:  JSON.stringify({ pageSize: this.fbo.stats.navigatorObj.pageSize || this.PAGE_SIZE })
    } );

    if (this.apps.bas.envtest) console.log("updateFbo.params: ", params);

    this.fbo.loading = true;
    this.apps.bas.ws.json(params ).then((json:any) => {
      if (this.apps.bas.envtest) console.log("updateFbo.then, json: ", json);

      //TODO: må lagre dssse veriene lokalt slik at man ikke må stille inn hver gang siden lastes.

      if (json.success) {
        this.updateForm(json.statsObj);

        this.fbo = json;
        
      } else {

      }
      

     });
  }

  
  updateForm(stats:any) {

    let controls: DynformControl[] = [];

    
    controls.push(new DynformControl({ key: 'search',               mk: 'common.search' }));
    controls.push(new DynformControl({ key: 'currentJil', value: "max" }));
    
    
    
    // controls.push(new DynformControl({ 
    //   key: 'product',  
    //   // valuePath: "ruleId",
    //   mk: 'common.product',  
    //   controlType: 'select', 
    //   // required: true,
    //   options: () => {
    //     return obj.products || []; 
    //   },
    //   optionsAllowEmpty: true,
    //   optionsFieldValue: "id",
    //   optionsFieldLabel: "mkName"
    // }));

    controls.push(new DynformControl({ 
      key: 'productType',  
      // valuePath: "ruleId",
      mk: 'bus.product.productType',  
      controlType: 'select', 
      // required: true,
      options: () => {
        return stats.productTypes; 
      },
      optionsAllowEmpty: true,
      optionsFieldValue: "id",
      optionsFieldLabel: "mkName"
    }));

    controls.push(new DynformControl({ 
      key: 'productSubType',  
      mk: 'bus.product.subType',  
      controlType: 'select', 
      // required: true,
      options: () => {
        return stats.productSubTypes;  //TODO: bare vise etter valgt productType
      },
      optionsAllowEmpty: true,
      optionsFieldValue: "id",
      optionsFieldLabel: "mkName"
    }));

    controls.push(new DynformControl({ 
      key: 'provider',  
      mk: 'common.provider',  
      controlType: 'select', 
      // required: true,
      options: () => {
        return stats.providers || [];
      },
      show: () => {
        return this.apps.bas.aas.isAdmin();
      },
      optionsAllowEmpty: true,
      optionsFieldValue: "id",
      optionsFieldLabel: "label"
    }));

    controls.push(new DynformControl({ 
      key: 'showEnabledProducts',  
      mk: 'web.common.stats.showEnabled',  
      controlType: 'select', 
      // required: true,
      options: () => {
        return stats.allYesNo; 
      },
      optionsAllowEmpty: false,
      optionsFieldValue: "enumName",
      optionsFieldLabel: "mkName"
    }));
    controls.push(new DynformControl({ 
      key: 'showArchived',  
      mk: 'web.common.stats.showArchived',  
      controlType: 'select', 
      // required: true,
      options: () => {
        return stats.allYesNo; 
      },
      optionsAllowEmpty: false,
      optionsFieldValue: "enumName",
      optionsFieldLabel: "mkName"
    }));

    controls.push(new DynformControl({ key: 'showUnits', mk: "web.common.stats.showUnits" }));
    
    controls.push(new DynformControl({ 
      key: 'sort',  
      mk: 'common.sort',  
      controlType: 'select', 
      // required: true,
      options: () => {
        return stats.sorts; 
      },
      optionsAllowEmpty: false,
      optionsFieldValue: "enumName",
      optionsFieldLabel: "mkName"
    }));

        
    controls.push(new DynformControl({ 
      key: 'sortMode',  
      mk: 'common.sortMode',  
      controlType: 'select', 
      // required: true,
      options: () => {
        return stats.sortModes; 
      },
      optionsAllowEmpty: false,
      optionsFieldValue: "enumName",
      optionsFieldLabel: "mkName"
    }));
    /*
       <btlib-form-row [controls]="[ df.controls.productType,  df.controls.productSubType ]" [form]="df.form"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row> 
            <btlib-form-row [controls]="[ df.controls.provider, df.controls.showEnabledProducts ]" [form]="df.form"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row> 
            <btlib-form-row [controls]="[ df.controls.search, df.controls.showArchived ]" [form]="df.form"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row>
            <btlib-form-row [controls]="[ df.controls.sort, df.controls.sortMode ]" [form]="df.form"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row> 
            <btlib-form-row [controls]="[ df.controls.showUnits ]" [form]="df.form"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row> 
    
            
          <!--

		
				<div class="form-group">
					<label class="col-sm-4 control-label" for="stats.sort"><nit:message key="common.sort"/>:</label>
					<div class="col-sm-8"><nit:select path="fbo.stats.sort" items="${stats.sorts}" cssClass="form-control " labelProperty="mk" valueProperty="name" /></div>
				</div>
		
				<div class="form-group">
					<label class="col-sm-4 control-label" for="stats.sortMode"><nit:message key="common.sortMode"/>:</label>
					<div class="col-sm-8"><nit:select path="fbo.stats.sortMode" items="${stats.sortModes}" cssClass="form-control " labelProperty="mk" valueProperty="name" /></div>
				</div>
				<div class="form-group">
					<label class="col-sm-4 control-label" for="stats.search"><nit:message key="common.search"/>:</label>
					<div class="col-sm-8"><nit:text path="fbo.stats.search" cssClass="form-control " style="" /></div>
				</div>

          -->

    */

    this.apps.bas.fs.updateFormObj(this.df, controls, stats);


  }


  // updateProducts() {
  //   this.apps.bas.aas.getProducts().then((products) => {
        
  //     if(this.apps.bas.envtest) console.log("AdminProductsPageComponent.getProducts, products: ", products);

  //   });
  // }

  copyProduct(id:number) {

    
    this.apps.bas.ws.json({ aType: "product", action: "copyProduct", id: id }).then((json) => {
      if (json.success) {
        this.apps.bas.ui.router.navigate([
          this.apps.bas.ui.getRouterPrefix() + '/admin/product/' + json.product.id
        ]);

      }
    });

  }


  ngOnInit(): void {

  }

}
