
import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MiscUtil } from 'src/app/lib/core/util/misc.util';

import { AppService } from 'src/app/services/app.service';
import { DynformControl } from '../../../../lib/core/model/dymform-control';

@Component({
  selector: 'app-app-spacebooking-admin-page',
  templateUrl: './app-spacebooking-admin-page.component.html',
  styleUrls: ['./app-spacebooking-admin-page.component.scss']
})
export class AppSpacebookingAdminPageComponent implements OnInit {

  route:ActivatedRoute;
  ad:any = {
    product: null,
    maxOrderDate: undefined,
    periods: [],
    periodIdx: 0,
  };

  edit:any = {
    obj: undefined,
   
  }

  // compareFn = (o1: any, o2: any): boolean => (o1 && o2 ? o1.id === o2.id : o1 === o2);

  
  constructor(public injector:Injector, public apps:AppService) {
    this.route = injector.get(ActivatedRoute);
    this.apps.bas.us.checkActivatedRoute(this.route.snapshot);

    if (this.apps.bas.envtest) console.log("AppSpacebookingAdminPageComponent");

    // this.ctx.queryParams = this.route.snapshot.queryParams;
    // this.ctx.type = this.ctx.queryParams.tabType || undefined;
    
    apps.bas.es.ready().then(() => {
      this.getAdminData();
    });



  }

  ngOnInit(): void {
    
  } 
  

  selectProduct(product:any) {

    if (this.apps.bas.envtest) console.log("selectProduct, product: ", product);

    let periods = [];

    let sb = product.jsonDataObj?.spaceBooking;

    this.ad.maxOrderDate = MiscUtil.setDateAsInt(sb.maxOrderDate);

    let dateExceptions = sb?.dateExceptions || [ ];
    for (let idx in dateExceptions) {
      let p = dateExceptions[idx];
      // console.log("p: " + p);
      let parts = p.split("_");
      let from = parts[0];
      let to = parts[1];

      periods.push({
        idx,
        from,
        to
      });

    }

    this.ad.periodIdx = dateExceptions.length;

    // periods.push({
    //   idx: dateExceptions.length,
    //   from: "",
    //   to: ""
    // });

    if (this.apps.bas.envtest) console.log("selectProduct, periods: ", periods);

    this.ad.periods = periods;
  }

  addPeriod() {

    this.ad.periods = [...this.ad.periods , {
      idx: this.ad.periodIdx++,
      from: undefined,
      to: undefined
    }];
  }

  removePeriod(toDelete:any) {
    this.ad.periods = this.ad.periods.filter((period:any, idx:number) => period.idx != toDelete.idx);
  }
  savePeriods() {
    if (this.apps.bas.envtest) console.log("savePeriods, periods: ", this.ad.periods, ", maxOrderDate: ", this.ad.maxOrderDate);

    let dateExceptions = [];

    for (let p of this.ad.periods) {
      let f = p.from;
      let t = p.to;

      if (f == undefined || t == undefined) continue;
      if (typeof f !== "string") f = this.apps.bas.ui.dateFormat(f);
      if (typeof t !== "string") t = this.apps.bas.ui.dateFormat(t);

      dateExceptions.push(f + "_" + t);

    }

    dateExceptions.sort();

    if (this.apps.bas.envtest) console.log("savePeriods, dateExceptions: ", dateExceptions);

    this.apps.bas.ws.json({ 
      // checkChildApp: true,
      aType: "spaceBooking", 
      action: "saveDateExceptions", 
      id: this.ad.product.id,
      jsonData: JSON.stringify({ 
        maxOrderDate: MiscUtil.getDateAsInt(this.ad.maxOrderDate),
        dateExceptions: dateExceptions 
      })
    
    }).then((json) => {

      if (this.apps.bas.envtest) console.log("saveDateExceptions, json: ", json);
      if (json.success) {
        this.apps.bas.ui.success("Periodene ble lagret");
        this.getAdminData();
      }
     
    });

  }

  getAdminData() {
    if (this.apps.bas.envtest) console.log("getAdminData.calling json");
    this.apps.bas.ws.json({ 
      // checkChildApp: true,
      aType: "spaceBooking", 
      action: "getAdminData", 
      // jsonData: JSON.stringify({ lead: lead })
    
    }).then((json) => {

      if (this.apps.bas.envtest) console.log("getAdminData, json: ", json);
      if (json.success) {
        this.ad = json;
      }
    });

  }

  newObject() {


    this.editObject(this.ad.emptyObject || { 
      name: ""
    });
  }

  editObject(obj:any) {

    if(this.apps.bas.envtest) console.log("editObject, obj: ", obj);

    if (obj.jsonDataObj?.spaceBooking) {
      obj.spaceBooking = obj.jsonDataObj.spaceBooking;
    }


    let controls: DynformControl[] = [];

    controls.push(new DynformControl({    key: 'name',   mk: 'common.name' }));


    controls.push(new DynformControl({ 
      key: 'spaceBooking',
      controlType: 'formGroup',
      children: [
        new DynformControl({ key: 'top',    mk: 'Fra toppen (%)', type: "number", required: true }),
        new DynformControl({ key: 'left',   mk: 'Fra venstre (%)', type: "number", required: true }),
        new DynformControl({ key: 'height',  mk: 'Høyde (%)', type: "number", required: true }),
        new DynformControl({ key: 'width',   mk: 'Bredde (%)', type: "number", required: true }),
        new DynformControl({ key: 'rotation',   mk: 'Rotasjon (°)', type: "number", required: false }),

       ]
    }));


    /*
  { name: 'Plass 1',  top: 17.5, left: 80,   height: 8,  width: 4.5,             bg: this.itemBg},
    */

    this.apps.bas.fs.updateFormObj(this.edit, controls, obj, true);
  } 

  onEditCancel() {
    this.edit.obj = undefined;
  }
  onEditOk() {

    let rv = this.apps.bas.fs.getRawValue(this.edit.form, this.edit.controls);
    if (rv === false) return;
    

    if(this.apps.bas.envtest) console.log("onEditOk, rv: ", rv);

    let obj = this.edit.obj;
    let isNew = !obj.id;

    let params:any = {
      aType: "spaceBooking", 
      action: "saveSpace", 
      parent: this.ad.product.id,
      id: isNew ? "new" : obj.id,
      isNew: isNew,
      space: JSON.stringify(rv)
    }

    this.apps.bas.ws.json(params).then((json) => {
      if(this.apps.bas.envtest) console.log("onEditOk, then: ", json);
      if (json.success) {
        

        let space = json.space;
        this.ad.product.spaces = this.ad.product.spaces || [];

        if (isNew) this.ad.product.spaces = [...this.ad.product.spaces, space];
        else {
          let itemIndex = this.ad.product.spaces.findIndex((obj:any) => obj.id == space.id);
          this.ad.product.spaces[itemIndex] = space;
          this.ad.product.spaces = [...this.ad.product.spaces];
        }
      }
    });
    
    this.edit.obj = undefined;
  }



}

