// import { Pipe, PipeTransform, Injector } from '@angular/core';
// import { Inject } from '@angular/core';
// import { SafeHtml } from '@angular/platform-browser';
// // import { InitConfigService } from '../booktech-app-lib.module'
// import { BooktechAppService } from "../services/booktech-app.service";


import { Pipe, PipeTransform, Injector } from "@angular/core";
import { Inject } from '@angular/core';
import { SafeHtml } from "@angular/platform-browser";
import { AppService } from "src/app/services/app.service";
import { BooktechAppService } from "../services/booktech-app.service";


/*
*/
@Pipe({name: 'actrans'})
export class ACTransPipe implements PipeTransform {

  constructor(injector:Injector, public apps:AppService) {

    
  }


  // 'key.test' | actrams:[]:true:'default text'
  transform(key: any, params:any|any[] = [ ], safe: boolean = false, fallback?:string):  (string | SafeHtml) {
    //Log.l("value.type: " + typeof value + ", value: ", value);
    
    return this.apps.bas.ui.actrans(key, params, safe, fallback);
  }
}
