import { Component, OnInit } from '@angular/core';
import { AntdTheme } from 'src/app/lib/core/services/style.service';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit {

  moreRows = false;

  constructor(public apps:AppService) {
    // console.log("config: ", this.apps.bas.ds.config);
  }


  ngOnInit(): void {
  }


  login() {
    if(this.apps.bas.envtest) console.log("al: ", this.apps.bas.ds.login);
    this.apps.bas.api.login({ username: "demo@booktech.no", password: "abc123"}).then(res => {
      if(this.apps.bas.envtest) console.log("login.res: ", res);
    });
  }

  checkLogin() {
    if(this.apps.bas.envtest) console.log("al: ", this.apps.bas.ds.login);
    this.apps.bas.ws.json({ aType: "auth", action: "checkLogin"}).then(res => {
      if(this.apps.bas.envtest) console.log("checkLogin.res: ", res);
    });
  }

  changeTheme() {
    var theme = this.apps.bas.style.antdTheme;
    this.apps.bas.style.loadTheme(theme == AntdTheme.default ? AntdTheme.dark : AntdTheme.default);
  }

}
