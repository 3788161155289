
import { AfterViewInit, Component, HostListener, Injector, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DynformControl, DynformControlsMap } from 'src/app/lib/core/model/dymform-control';
import { DATAID } from 'src/app/lib/core/services/data.service';
import { MiscUtil } from 'src/app/lib/core/util/misc.util';
import { upperCase } from 'src/app/lib/core/util/const';

import { AppService } from 'src/app/services/app.service';

import { Observable, Subscription } from 'rxjs';
import { ComponentCanDeactivate } from 'src/app/lib/core/guards/pending-changes.guard';



@Component({
  selector: 'app-app-bileasy-admin-profile-page',
  templateUrl: './app-bileasy-admin-profile-page.component.html',
  styleUrls: ['./app-bileasy-admin-profile-page.component.scss']
})
export class AppBileasyAdminProfilePageComponent implements OnInit, AfterViewInit, ComponentCanDeactivate {

  router: Router;
  route: ActivatedRoute;

  fbo:any;

  edit:any = {
    form: undefined,
    obj: undefined
  }

  isSpinning = true;
  dirty = false;

  userChangePassword:any = undefined;
  userChangeUsername:any = undefined;
  userDeleteUser:any = undefined;
  

  constructor(public injector:Injector, public apps:AppService) {
    this.router = injector.get(Router);
    this.route = injector.get(ActivatedRoute);
    this.apps.bas.us.checkActivatedRoute(this.route.snapshot);

    if (this.apps.bas.envtest) console.log("AppBileasyAdminProfilePageComponent, login.success: "+this.apps.bas.ds.login.success+", route: ", this.route.snapshot);

    if (this.apps.bas.ds.login.success) {
      
      this.getUser(this.route.snapshot.queryParams.id);
      
    } 
    else {

      //TODO: denne blir kalt flere ganger. 
      this.apps.bas.ds.get(DATAID.APP_LOGIN).subscribe((res) => {
        if (this.apps.bas.envtest) console.log("AppBileasyAdminProfilePageComponent.LOGIN.updated, res: ", res)
        // window.location.reload();
        if (res.success) {
          this.getUser(this.route.snapshot.queryParams.id);
      
        }
     
      });
    }

   }

  ngOnInit(): void {
    if (this.apps.bas.envtest) console.log("AppBileasyAdminProfilePageComponent.ngOnInit");
  }

  ngAfterViewInit(): void {
    if (this.apps.bas.envtest) console.log("AppBileasyAdminProfilePageComponent.ngAfterViewInit");
  }

  //


  updateControls(user:any) {
    let controls: DynformControl[] = [];

    let langs = this.apps.bas.ds.config.appConfig?.enabledLanguages;
    // console.log("langs:")

    // let isAdmin = this.apps.bas.aas.isAdmin();
  
    controls.push(new DynformControl({ key: 'id',     mk: 'common.id' }));

    controls.push(new DynformControl({ key: 'username',     mk: 'common.username', controlType: "label" }));
    controls.push(new DynformControl({ key: 'password',     mk: 'common.password', controlType: "label", value: "•••••••••••" }));
    controls.push(new DynformControl({ 
      key: 'subscriptionTypeLabel',     
      mk: 'Abonnementstype', 
      controlType: "label"
    }));
    
    
    controls.push(new DynformControl({ key: 'email',     mk: 'common.email', show: () => !user.isUsernameEmail }));
    
    controls.push(new DynformControl({ 
      key: 'customerType',     
      mk: user.isUesr ? 'bus.user.customerType' : 'common.type', 
      controlType: 'select', 
      required: true,
      // show: () => user.isUser,
      options: () => {
        return this.fbo.customerTypes;
      }, 
      // optionsAllowEmpty: true,
      optionsFieldValue: "id", 
      optionsFieldLabel: "mkName",
      onChange: () => {
        this.edit.ct = this.apps.bas.us.listToMap(this.fbo.customerTypes)[this.edit.form.controls.customerType.value];
      }
    }));
    this.edit.ct = this.apps.bas.us.listToMap(this.fbo.customerTypes)[user.customerType];

    controls.push(new DynformControl({ 
      key: 'socialSecurityNumber',     
      mk: 'bus.user.socialSecurityNumber',
      show: () => {
        return this.edit.ct?.isPrivate;
      }
    }));
    controls.push(new DynformControl({ 
      key: 'orgNumber',     
      mk: 'bus.user.orgNumber',
      show: () => {
        return !this.edit.ct?.isPrivate;
      }
    }));

    controls.push(new DynformControl({ key: 'name',     mk: 'Firmanavn' }));
    controls.push(new DynformControl({ key: 'address1',     mk: 'Besøksadresse' }));
    controls.push(new DynformControl({ key: 'address2',     mk: 'Postadresse' }));
    // controls.push(new DynformControl({ key: 'address3',     mk: 'common.addressLine3' }));
    controls.push(new DynformControl({ key: 'zipCode',     mk: 'common.zipCode' }));
    controls.push(new DynformControl({ key: 'city',     mk: 'common.city' }));
    controls.push(new DynformControl({ key: 'telephone',     mk: 'common.telephone' }));
    
    // controls.push(new DynformControl({ 
    //   key: 'country', 
    //   valuePath: "countryCode",
    //   mk: 'common.country', 
    //   controlType: 'select', 
    //   required: true,
    //   options: () => {
    //     return this.fbo.countries;
    //   }, 
    //   // optionsAllowEmpty: true,
    //   optionsFieldValue: "code", 
    //   optionsFieldLabel: "mkName",
  
    // }));
    
    controls.push(new DynformControl({ 
      key: 'language', 
      valuePath: "languageCode",
      mk: 'common.language', 
      controlType: 'select', 
      required: true,
      options: () => {
        return this.apps.bas.ds.config.appConfig?.enabledLanguages || [];
      }, 
      // optionsAllowEmpty: true,
      optionsFieldValue: "code", 
      optionsFieldLabel: "mkName",
  
    }));
    
    controls.push(new DynformControl({ 
      key: 'timeZone', 
      // valuePath: "countryCode",
      mk: 'common.timeZone', 
      controlType: 'select', 
      required: true,
      options: () => {
        return this.fbo.timeZones;
      }, 
      // optionsAllowEmpty: true,
      optionsFieldValue: "id", 
      optionsFieldLabel: "label",
 
    }));

    // controls.push(new DynformControl({ key: 'invoiceNote',     mk: 'bus.user.invoiceNote' }));
    
    controls.push(new DynformControl({ key: 'ehfEnabled',     mk: 'bus.user.ehfEnabled' }));
    controls.push(new DynformControl({ key: 'ehfOrderReference',     mk: 'bus.user.ehfOrderReference' }));
    controls.push(new DynformControl({ key: 'ehfContractDocumentReference',     mk: 'bus.user.ehfContractDocumentReference' }));
    controls.push(new DynformControl({ key: 'ehfContactId',     mk: 'bus.user.ehfContactId' }));
    
    
    controls.push(new DynformControl({ key: 'acceptInfoEmail',     mk: 'bus.user.acceptInfoEmail' }));
    controls.push(new DynformControl({ key: 'acceptInfoSms',     mk: 'bus.user.acceptInfoSms' }));
    
    // Provider
    controls.push(new DynformControl({ key: 'displayName',     mk: 'common.displayName', show: () => user.isProvider }));
    controls.push(new DynformControl({ key: 'webAddress',     mk: 'common.webAddress' }));
    controls.push(new DynformControl({ key: 'bankAccount',     mk: 'bus.user.bankAccount' }));



    return controls;

   
  }

  onChange(event:any) {
    if (!event.control) return;
    this.dirty = true;

  }

  @HostListener('document:keydown.control.s',['$event']) 
  ctrlS(event: KeyboardEvent) {
    // console.log("ctrlS: ", event);

    this.submitForm();
    if (event) event.preventDefault();
    return false;
  }


  getUser(id:any = undefined) {

    if (this.apps.bas.envtest) console.log("getUser, login: ", this.apps.bas.ds.login);
    else id = undefined;

    // let options:any = { jil: "all" };
    let params:any = {
      actionType: "appAdmin", 
      action: "getUserProfile",
      // id: id,
      jil: "all"
     };

     if (id) params.id = id;
    this.apps.bas.ws.json(params).then((json) => {
      if (this.apps.bas.envtest) console.log("getUser.then: ", json);

  
      let fbo = json.fbo;

      this.fbo = fbo;

      let user = json.user;

      this.setUser(user);
      this.isSpinning = false;

    });
  } 

  setUser(user:any) {

    let controls = this.updateControls(user);

    this.apps.bas.fs.updateFormObj(this.edit, controls, user);

    this.dirty = false;
  }

  submitForm(): void {

  
    let rv = this.apps.bas.fs.getRawValue(this.edit.form, this.edit.controls);
    if (rv === false) return;

    if (this.apps.bas.envtest) console.log("AppBileasyAdminProfilePageComponent.submitForm, rv: ", rv)

    this.isSpinning = true;

    
    this.apps.bas.ws.json(
      { 
        actionType: "appAdmin", 
        action: "saveUserProfile",
        id: this.edit.obj.id,
        user: JSON.stringify(rv)
      }
    ).then(res => {
      this.isSpinning = false;

      if (this.apps.bas.envtest) console.log("AppBileasyAdminProfilePageComponent.submitForm.then: ", res);

      if (!res.success) {

        return;
      }

      this.apps.bas.ui.success("Brukerprofilen er lagret"); //TODO:text

      this.setUser(res.user);
     

    }).catch(err =>  {
      this.isSpinning = false;

      if (this.apps.bas.envtest) console.log("AppBileasyAdminProfilePageComponent.submitForm.err: ", err);
    })


  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    // insert logic to check if there are pending changes here;
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away

    return !this.dirty;
  }


}



