
<bt-page-wrapper>
  <nz-spin [nzSpinning]="!fbo.providers">

    <div *ngIf="!fbo.providers">

    </div>

    <div *ngIf="fbo.providers">
      <nz-table 
        #objectsTable 
        nzTableLayout="fixed" 
        [nzData]="fbo.providers || []"
        [nzPageSize]="20"
        nzSize="small"
        class="nz-table-xs"
        >
        <thead>
          <tr>
            <th>{{ 'Forhandler' | actrans }}</th>
            <th>{{ 'Nettside' | actrans }}</th>
    
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of objectsTable.data">

            <td>{{ data.name }}</td>
            <td><a [href]="data.webAddressWithProtocol" (click)="websiteClick(data)" target="_blank">{{ data.webAddressDisplay }}</a></td>


          </tr>
        </tbody>
      </nz-table>


    </div>
    

  </nz-spin>
</bt-page-wrapper>