import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-widget-test-page',
  templateUrl: './widget-test-page.component.html',
  styleUrls: ['./widget-test-page.component.scss']
})
export class WidgetTestPageComponent implements OnInit {


  route: ActivatedRoute;

  constructor(public injector:Injector, public apps:AppService) {
    this.route = injector.get(ActivatedRoute);
    this.apps.bas.us.checkActivatedRoute(this.route.snapshot);

    if(this.apps.bas.envtest) console.log("TestWidgetPageComponent, route.snapshot: ", this.route.snapshot);
  }

  ngOnInit(): void {
  }

}
