
<nz-modal 
[nzVisible]="!!orders.length" 
[nzTitle]="'web.common.bookings._order' | actrans | str" 
nzWidth="90vw" 
(nzOnCancel)="closeOrdersModal()" 
(nzOnOk)="closeOrdersModal()"
>
<ng-container *nzModalContent>
  
  <div *ngIf="orders.length">

    <form nz-form nzLayout="vertical" [nzAutoTips]="apps.bas.fs.autoTips">
      
      <!-- <btlib-content-row [cols]="orderRows.s1" [grid]="apps.bas.ui.gridMd"></btlib-content-row>
    
      <btlib-content-row [cols]="orderRows.s2" [grid]="apps.bas.ui.gridLg"></btlib-content-row>
      <h2>{{ 'common.customer' | actrans }}</h2>
      <btlib-content-row [cols]="orderRows.customer" [grid]="apps.bas.ui.gridLg"></btlib-content-row> -->
    

      <div>
        <h2>{{ 'bus.booking.items' | actrans }}</h2>
        <nz-table 
          #nzTableOrderItems
          [nzData]="orders || []" 
          nzTableLayout="fixed" 
          [nzShowPagination]="false" 
          [nzPageSize]="100"
          nzSize="small"
          class="nz-table-xs"
          >
          <thead>
            <tr>
              <th nzWidth="30px"></th>
         
   
              <th *ngIf="apps.bas.ui.r.gtemd" [nzWidth]="'100px'">
                <span >{{ 'common.status' | actrans }}</span>
              </th>

              <th nzWidth="70px" >{{ 'common.id' | actrans }}</th>
              <th *ngIf="apps.bas.ui.r.gtesm">{{ 'common.product' | actrans }}</th>

              <th>{{ 'common.user' | actrans }}</th>

              <th *ngIf="apps.bas.ui.r.gtelg">{{ 'common.comment' | actrans }}</th>
              <th *ngIf="apps.bas.ui.r.gtemd">{{ 'common.period' | actrans }}</th>

              <th *ngIf="apps.bas.ui.r.gtelg">{{ 'bus.booking.guests' | actrans }}</th>
              <th  nzWidth="50px" nzAlign="right">{{ 'common.count' | actrans }}</th>
  
              <th *ngIf="apps.bas.ui.r.gtesm" nzAlign="right">{{ 'common.amount' | actrans }}</th>
              <th  nzWidth="30px" nzAlign="center">
                <label 
                nz-checkbox 
                [ngModelOptions]="{standalone: true}"
                [ngModel]="selectedAll"
                (ngModelChange)="onSelected($event, 'all')"
                ></label>
              </th>
              <th  nzWidth="30px" nzAlign="center"></th>

            </tr>
          </thead>
          <tbody>
            <ng-container  *ngFor="let data of nzTableOrderItems.data; let idx = index">
              <tr>
                <td nzAlign="center" [class]="data.statusCssClass">
                  <i nz-icon class="cursor-pointer" [nzType]="data.expanded ? 'minus-square' : 'plus-square'" (click)="data.expanded = !data.expanded" ></i>
                </td>
                <td  *ngIf="apps.bas.ui.r.gtemd" [class]="data.statusCssClass">
                  <span>{{ data.mkStatus }}</span> 
                </td>
                <td>
                  <a *ngIf="apps.bas.aas.isAdmin()" [routerLink]="apps.bas.ui.getRouterPrefix() + '/lib/goto'" [queryParams]="{ 'goto-action': 'order', 'goto-id': data.bookingObj.bid }">{{ data.bookingObj.bid }}</a>
                  <span *ngIf="!apps.bas.aas.isAdmin()">{{ data.bookingObj.bid }}</span>
                </td>
                
                <td *ngIf="apps.bas.ui.r.gtesm">{{ data.product }}</td>
   
            
                <td>{{ data.bookingObj.user }}</td>
                
                <td *ngIf="apps.bas.ui.r.gtelg">
                  {{ data.bookingObj.comment }} 
                  {{ data.bookingObj.notes }}
                </td>
                <td *ngIf="apps.bas.ui.r.gtemd">{{ data.periodString }}</td>
         
                <td *ngIf="apps.bas.ui.r.gtelg">{{ data.guests }}</td>
                <td  nzAlign="right">{{ data.count }}</td>

                <td *ngIf="apps.bas.ui.r.gtesm" nzAlign="right">{{ data.amountDouble | nf }}</td>

                <td nzAlign="center">
                  <label 
                    nz-checkbox 
                    [nzValue]="true" 
                    [ngModelOptions]="{standalone: true}"
                    [ngModel]="data.selected"
                    (ngModelChange)="onSelected($event, data)"
                    ></label>
       
                </td>
                <td>
                  <!-- <button 
                    nz-button 
                    nzType="default" 
                    nzDanger 
                   
                    > -->
                    <span 
                    class="cursor-pointer"
                    nz-icon nzType="delete"
                    (click)="deleteOrder(data)"
                    *ngIf="data.bookingId && data.statusEnum?.isAvailability"
                    [title]="('common.delete' | actrans) + ' ' + ('common.booking._order' | actrans | str | lowercase)"
                    [nzTheme]="'twotone'" [nzTwotoneColor]="'#eb2f96'"
                    ></span>
                  <!-- </button> -->
                </td>

              </tr>
              <tr  *ngIf="data.expanded" class="tr-expanded"> 
               
                <td [attr.colspan]="(
                                           5
                  + (apps.bas.ui.r.gtesm ? 2 : 0) 
                  + (apps.bas.ui.r.gtemd ? 2 : 0) 
                  + (apps.bas.ui.r.gtelg ? 2 : 0) 
                  + (apps.bas.ui.r.gtexl ? 0 : 0)
                  )">
                <div class="tr-extended-div">
               
                 
                  <btlib-content-row [cols]="contentData['item' + data.id]['basic']" [grid]="apps.bas.ui.gridMd"></btlib-content-row>
                  <btlib-content-row [cols]="contentData['item' + data.id]['order']" [grid]="apps.bas.ui.gridMd"></btlib-content-row>
    
                  <div *ngIf="contentData['item' + data.id]['inputValues'].length">
                    <h3>{{ 'bus.productInput' | actrans }}</h3>
                    <btlib-content-row [cols]="contentData['item' + data.id]['inputValues']" [grid]="apps.bas.ui.gridMd"></btlib-content-row>
    
                  </div>

                </div>
                </td>

              </tr>
            </ng-container>
          </tbody>
          <tfoot *ngIf="sums.show">
            <tr>
              <th [attr.colspan]="(3 
              + (apps.bas.ui.r.gtesm ? 1 : 0) 
              + (apps.bas.ui.r.gtemd ? 2 : 0) 
              + (apps.bas.ui.r.gtelg ? 2 : 0) 
              )">
                {{ 'common.sum' | actrans }}
              </th>
              <th nzAlign="right">
                <span *ngIf="sums.count">{{ sums.count | nf:0 }}</span>
              </th>
              <th *ngIf="apps.bas.ui.r.gtesm" nzAlign="right">
                <span *ngIf="sums.amount">
                  {{ sums.amount | nf }}
                </span>
              </th>
              <th></th>
              <!-- <th [attr.colspan]="(
                  1
                  + (apps.bas.ui.r.ltesm ? 0 : 0)  
                  + (apps.bas.ui.r.gtesm ? 1 : 0) 
                  + (apps.bas.ui.r.gtemd ? 3 : 0) 
                  + (apps.bas.ui.r.gtelg ? 1 : 0) 
                  + (apps.bas.ui.r.gtexl ? 0 : 0)
                )"
                
                nzAlign="right"
              >
              {{ sums.amount | nf }}
              </th> -->
         
            </tr>
     

          </tfoot>
        </nz-table>

      </div>

    </form>
  </div>

</ng-container>
<div *nzModalFooter>

  <button 
    *ngIf="apps.bas.aas.isAdmin() || apps.bas.ds.login.user.upApProviderOrdreCancelAndRefund"
    nz-button 
    nzType="primary" 
    nzDanger
    (click)="cancelAndRefund()" 
    [disabled]="!selected.length">{{ 'web.common.overview.cancelAndRefundOrders' | actrans }}
  </button>

  <button 
    *ngIf="apps.bas.ds.config.company.upCmSms"
    nz-button 
    nzType="default" 
    (click)="sendSms()" 
    [disabled]="!selected.length">{{ 'web.ajax.sms' | actrans }}
  </button>
  <button 
    nz-button 
    nzType="default" 
    (click)="sendEmail()" 
    [disabled]="!selected.length">{{ 'web.ajax.email' | actrans }}
  </button>
  <button nz-button nzType="primary" (click)="closeOrdersModal()">{{ 'common.close' | actrans }}</button>
</div>
</nz-modal>


<btlib-sms-modal [input]="smsInput"></btlib-sms-modal>
<btlib-email-modal [input]="emailInput"></btlib-email-modal>
<btlib-order-cancel-modal [input]="cancelAndRefundInput" (change)="onModalClose()"></btlib-order-cancel-modal>


