import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { MiscUtil } from 'src/app/lib/core/util/misc.util';

import { AppService } from 'src/app/services/app.service';

import { FormGroup } from '@angular/forms';
import { DynformControl, DynformControlsMap } from 'src/app/lib/core/model/dymform-control';

@Component({
  selector: 'app-admin-product-property-types-page',
  templateUrl: './admin-product-property-types-page.component.html',
  styleUrls: ['./admin-product-property-types-page.component.scss']
})
export class AdminProductPropertyTypesPageComponent implements OnInit {

  objects:any[] = [];
  fbo:any = { };

  edit:any = {
    obj: undefined,
   
  }
  
  constructor(public injector:Injector, public apps:AppService) {
    let route = injector.get(ActivatedRoute);
    this.apps.bas.us.checkActivatedRoute(route.snapshot);

    if(this.apps.bas.envtest) console.log("AdminProductPropertyTypesPageComponent");

    this.getObjects();
  }

  ngOnInit(): void {

  }

  getObjects() {
    this.apps.bas.ws.json({ aType: "appAdmin", action: "getObjects", type: "propertyType" }).then((json) => {
      if (json.success) {
        if(this.apps.bas.envtest) console.log("getObjects: ", json);

        this.objects = json.objects || [];
        this.fbo = json.fbo || { };
      }
    });
  }

  newObject() {


    this.editObject(this.fbo.emptyObject || { 
      parentId: ""
    }); //TODO
  }

  editObject(obj:any) {

    if(this.apps.bas.envtest) console.log("editObject, obj: ", obj);

    let controls: DynformControl[] = [];


    controls.push(new DynformControl({ 
      key: 'name',     
      mk: 'common.nameInternal'
    }));

    controls.push(new DynformControl({ 
      key: 'tid',     
      mk: 'common.id',
      disabled: !!obj.id
    }));


    controls.push(new DynformControl({ 
      key: 'messages',
      controlType: 'formGroup',
      children: [
        new DynformControl({ 
          key: 'name',
          // valuePath: 'messages.name', 
          mk: 'common.name', 
          controlType: 'message',
          data: { controlType: "input" }, 
          onChange: (event:any) => {
            // console.log("event: ", event);
            if (!obj.id && event.control.key == "no") {
              var cid = this.apps.bas.ds.findCid() != "_" ? this.apps.bas.ds.findCid() + "_" : "";
              this.edit.form.controls.tid.setValue(cid + MiscUtil.toId(event.value));
            }
          }
        }),
          
        ]
    }));

    controls.push(new DynformControl({ 
      key: 'type',  
      // valuePath: "typeId",
      mk: 'common.type',  
      controlType: 'select', 
      required: true,
      options: () => {
        return this.fbo.ptTypes || []; 
      },
      optionsAllowEmpty: true,
      // optionsFieldValue: "id",
      // optionsFieldLabel: "name"
    }));

    controls.push(new DynformControl({ 
      key: 'valType',  
      // valuePath: "valTypeId",
      mk: 'bus.propertyType.valType',  
      controlType: 'select', 
      required: true,
      options: () => {
        return this.fbo.valTypes || []; 
      },
      optionsAllowEmpty: true,
      // optionsFieldValue: "id",
      // optionsFieldLabel: "name"
    }));



    this.apps.bas.fs.updateFormObj(this.edit, controls, obj);
  } 

  onEditCancel() {
    this.edit.obj = undefined;
  }
  onEditOk() {

    let rv = this.apps.bas.fs.getRawValue(this.edit.form, this.edit.controls);
    if (rv === false) return;
    

    if(this.apps.bas.envtest) console.log("onEditOk, rv: ", rv);

    let obj = this.edit.obj;
    let isNew = !obj.id;

    let params:any = {
      aType: "appAdmin", 
      action: "saveObject", 
      type: "propertyType",
      id: isNew ? "new" : obj.id,
      isNew: isNew,
      object: JSON.stringify(rv)
    }

    this.apps.bas.ws.json(params).then((json) => {
      if (json.success) {
        
        if (isNew) this.objects = [...this.objects, json.object];
        else {
          let itemIndex = this.objects.findIndex(obj => obj.id == json.object.id);
          this.objects[itemIndex] =  json.object;
          this.objects = [...this.objects];
        }
      }
    });
    
    this.edit.obj = undefined;
  }



}
