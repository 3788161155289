<btlib-admin-page-wrapper [showMenu]="false">
  <!--  -->

  <div class="mx-d">

    
  <div *ngIf="apps.bas.ds.login.success" class="text-right">
    Du er innlogget som {{ apps.bas.ds.login.user.username }}
    <!-- ({{  apps.bas.ds.login.user.subscriptionType }})  -->
    <button nz-button nzSize="small" class="ml-d" [routerLink]="apps.bas.ui.getRouterPrefix() + '/app/bileasy/admin/profile'">{{ 'web.common.user' | actrans  }} </button>
    <button nz-button nzSize="small" class="ml-d" (click)="apps.bas.api.logout()">{{ 'app.lib.common.logout' | actrans  }} </button>
  
  </div>

  
    <a class="pull-right fgc-orange text-bold" nz-button nzType="link" href="https://www.bruktbilklubben.no/wp-content/uploads/2022/07/Brukermanual-forhandlerlosning-v2.pdf" target="_blank">Brukermanual</a>
    <h2>{{ 'Salgsmuligheter' | actrans }}</h2>



    
    <!-- <div *ngIf="df.obj">
      <nz-collapse>
        <nz-collapse-panel  [nzHeader]="'Filter'" [nzActive]="apps.bas.ui.r.gtemd"  >
          
          <form nz-form [formGroup]="df.form" class="item-form mt-d" nzLayout="vertical" [nzAutoTips]="apps.bas.fs.autoTips" (ngSubmit)="dfSubmit()"  >
  
       
            <button nz-button nzType="primary"> {{ 'common.show' | actrans }}  </button>
      
          </form>

        </nz-collapse-panel>
      </nz-collapse>


     
    </div> -->

    <div>
      <nz-radio-group [(ngModel)]="configSelect" (ngModelChange)="configChange()" nzButtonStyle="solid">
        <label nz-radio-button *ngFor="let conf of configs" [nzValue]="conf">{{ conf.name }}</label>
       
      </nz-radio-group>
    </div>
  
    <nz-spin [nzSpinning]="processing">

      <div *ngIf="config" class="mt-d">
        <!-- <p> page: {{ fbo.stats.navigatorObj.page }}</p> -->
        <nz-table 
          id="table-leads"
          #nzTableLeads
          [nzData]="fbo.stats.current || []" 
          nzTableLayout="fixed" 

          nzSize="small"
          class="nz-table-xs"

          [nzFrontPagination]="false"
          [nzLoading]="fbo.loading"

          [nzTotal]="fbo.stats.navigatorObj.size"
          [nzPageSize]="fbo.stats.navigatorObj.pageSize"
          [nzPageIndex]="fbo.stats.navigatorObj.page+1"
          (nzQueryParams)="onQueryParamsChange($event)"

          >
          <thead>
            <tr>
              
    
              <th
                *ngFor="let column of config.columnsFiltered || [];"
                [(nzSortOrder)]="column.sortOrder"
                [nzSortFn]="column.sortFn"
                [nzFilters]="column.listOfFilter"
                [nzFilterFn]="column.filterFn"
                [nzFilterMultiple]="column.filterMultiple"
                [nzColumnKey]="column.key"
                [nzWidth]="column.width"

                [nzCustomFilter]="column.filterCustom ? true : null"
              >
                {{ column.name }}

                <nz-filter-trigger *ngIf="column.filterSearch" [(nzVisible)]="column.filterSearchVisible" [nzActive]="customFilters[column.key]" [nzDropdownMenu]="customFilterSerachMenu">
                  <i nz-icon nzType="search"></i>
                </nz-filter-trigger>

                <nz-dropdown-menu #customFilterSerachMenu="nzDropdownMenu">
                  <div class="ant-table-filter-dropdown">
                    <div class="search-box">
                      <input type="text" nz-input [placeholder]="'Søk etter ' + column.name" [(ngModel)]="customFilters[column.key]" />
                      <button nz-button nzSize="small" nzType="primary" (click)="customFilterSearch(column)" class="search-button">Søk</button>
                      <button nz-button nzSize="small" (click)="customFilterReset(column)">Nullstill</button>
                    </div>
                  </div>
                </nz-dropdown-menu>

              </th>
              <th nzWidth="20px" nzAlign="center"></th>
              <th nzWidth="20px" nzAlign="left"></th>


          
            </tr>
          </thead>
          <tbody>
            <ng-container  *ngFor="let data of nzTableLeads.data; let idx = index">
              <tr  class="cursor-pointer" [class.lead-contact]="fbo.eventMap[data.id] && fbo.eventMap[data.id]['ConfirmContact']"  (click)="openLead(data)"> 
    
  
                <td
                *ngFor="let column of config.columnsFiltered || [];"
                [nzAlign]="column.align"
              >
                {{ getColumnValue(column, data) }}
              </td>

              <td nzAlign="center">
                <!-- <i nz-icon *ngIf="
                  fbo.eventMap[data.id] 
                  && fbo.eventMap[data.id]['ConfirmContact'] 
                  && !fbo.eventMap[data.id]['ProviderRemove']
                  " [nzType]="'check-circle'" [nzTheme]="'twotone'" [nzTwotoneColor]="'#3fa5b2'" ></i> -->

                  <i nz-icon 
                    *ngIf="data.icon" 
                    [nzType]="data.icon || 'check-circle'" 
                    [nzTheme]="'twotone'" 
                    [nzTwotoneColor]="data.iconColor || '#52c41a'" >
                  </i>
              </td>

                <td>
                  <i nz-icon  [nzType]="'arrow-right'" ></i>
                </td>

              </tr>
      

            </ng-container>
          </tbody>
          <tfoot>
        
          </tfoot>
        </nz-table>
      

      </div>
    </nz-spin>
  </div>


  
  
  <nz-modal 
    [(nzVisible)]="!!lead.obj" 
    [nzTitle]="'Salgsmulighet' | actrans | str" 
    nzWidth="90vw" 
    (nzOnCancel)="closeLeadModal()" 
    (nzOnOk)="closeLeadModal()"
    >
    <ng-container *nzModalContent>
      
      <div *ngIf="lead.obj">

        
        <nz-alert 
          *ngIf="!lead.obj.eventTypes?.ConfirmSale && lead.obj.eventTypesAll?.ConfirmSale" 
          class="my-d"
          nzType="error" 
          nzMessage="En annen forhandler har bekreftet at kunden har kjøpt bil, du kan derfor ikke gjøre det samme." 
          nzShowIcon></nz-alert>

        <form nz-form nzLayout="vertical" [nzAutoTips]="apps.bas.fs.autoTips">
          

          <div *ngIf="lead.obj.carType?.enumName == 'Electric'">
            <h2>Elektrisk</h2>
            <btlib-content-row [cols]="leadRows.electric" [grid]="apps.bas.ui.gridMd"></btlib-content-row>
          </div>

          <div *ngIf="lead.obj.carType?.enumName == 'Fossil'">
            <h2>Fossil</h2>
            <btlib-content-row [cols]="leadRows.fossil" [grid]="apps.bas.ui.gridMd"></btlib-content-row>
          </div>

          <div *ngIf="lead.obj.carType?.enumName == 'Commercial'">
            <h2>Varebil</h2>
            <btlib-content-row [cols]="leadRows.commercial" [grid]="apps.bas.ui.gridMd"></btlib-content-row>
          </div>

          <div *ngIf="lead.obj.leadTypeObj.isAdvanced">
            <h2>Bestemt modell</h2>
            <btlib-content-row [cols]="leadRows.advanced" [grid]="apps.bas.ui.gridMd"></btlib-content-row>
          </div>

          <!-- <h2>Informasjon </h2> -->
          
          <btlib-content-row [cols]="leadRows.s1" [grid]="apps.bas.ui.gridMd"></btlib-content-row>
          <btlib-content-row [cols]="leadRows.s2" [grid]="apps.bas.ui.gridXl"></btlib-content-row>
        

          <div class="m-d"></div>
          <h2>Innbytte</h2>
          <btlib-content-row [cols]="leadRows.tradein" [grid]="apps.bas.ui.gridMd"></btlib-content-row>
          <div *ngIf="lead.obj.tradein">
            <h3>Bilder</h3>
            <p *ngIf="!(lead.obj.files | filterBy: { isImg: true })">Ingen bilder lastet opp</p>
            <div *ngIf="lead.obj.files">
              <nz-image-group>
                <img
                *ngFor="let file of lead.obj.files | filterBy: { isImg: true }"
                  nz-image
                  height="150px"
                  [nzSrc]="file.url"
                  alt=""
                  class="cursor-pointer"
                />
              </nz-image-group>

              <h3 *ngIf="( lead.obj.files | filterBy: { isImg: false }).length" >Filer</h3>
              <span *ngFor="let file of lead.obj.files | filterBy: { isImg: false }">


                <a [href]="file.url" target="_blank">{{ file.filename || file.ext }}</a>

             
              </span>
          
            </div>
          </div>
          <div class="m-d"></div>
          <h2>Finansiering</h2>
          <btlib-content-row [cols]="leadRows.financing" [grid]="apps.bas.ui.gridMd"></btlib-content-row>
        
          <div *ngIf="lead.obj.eventTypes">

            <button 
              nz-button 
              nzType="default" 
              nzDanger 
              (click)="showLeadContact()"
              *ngIf="!(lead.obj.eventTypes.ShowContact || apps.bas.aas.isAdmin())"
              
              >
              <!-- [disabled]="!lead.obj.hasAccess" -->
              Vis kontaktinfo
              <span *ngIf="!lead.obj.hasAccess || lead.obj.threshold < 20" class="ml-d">
                ({{ lead.obj.count }} / {{ lead.obj.threshold }})
              </span>
            </button>

            <div *ngIf="lead.obj.eventTypes.ShowContact || apps.bas.aas.isAdmin()">
              <h3>Kontaktinfo</h3>
              <btlib-content-row [cols]="leadRows.contactInfo" [grid]="apps.bas.ui.gridMd"></btlib-content-row>
          

              <!-- <div nz-row>
                <div nz-col nzMd="16">
                  
                </div>
                <div nz-col>
          
                </div>
              </div> -->

              <h3>Mine kommentarer</h3>
              <textarea rows="4" nz-input placeholder="Her kan du skrive egene kommentater, f.eks. når du har hatt kontakt med kunden og eventuelt internt tilbudsnummer. " [(ngModel)]="lead.obj.commentEvent.value"  [ngModelOptions]="{standalone: true}"></textarea>
              <div class="text-right">
                <button  nz-button nzSize="small" nzType="default"  (click)="saveComment()" >
                  Lagre kommentar
                </button>
              </div>


              <hr/>

              <button    nz-button    nzType="default"   (click)="confirmContact()" [disabled]="lead.obj.eventTypes.ConfirmContact"  >
                Jeg bekrefter at jeg har kontaktet kunde
              </button>

              <button *ngIf="lead.obj.eventTypes.ConfirmContact" class="ml-d"  nz-button  nzType="default"   (click)="confirmSale()" [disabled]="lead.obj.eventTypes.ConfirmSale || lead.obj.eventTypesAll.ConfirmSale"  >
                Jeg bekrefter at kunden har kjøpt bil
              </button>
              <i *ngIf="lead.obj.eventTypes.ConfirmContact" class="ml-d" nz-icon [nzType]="'info-circle'" nz-popover nzType="primary" nzPopoverTitle="Bekrefte salg" nzPopoverContent="Når du bekrefter at kunden har kjøpt bil og kunden igjen bekrefter dette slettes denne salgsmuligheten for alle andre forhandlere."></i>

              
              <nz-alert 
                *ngIf="!lead.obj.eventTypes?.ConfirmSale && lead.obj.eventTypesAll?.ConfirmSale" 
                class="my-d"
                nzType="error" 
                nzMessage="En annen forhandler har bekreftet at kunden har kjøpt bil, du kan derfor ikke gjøre det samme." 
                nzShowIcon></nz-alert>
    
              
            </div>
          </div>

          <div *ngIf="apps.bas.aas.isAdmin()">
            
          <h2>Hendelser</h2>

          <nz-table 
            #eventsTable 
            nzTableLayout="fixed" 
            [nzData]="lead.obj.events || []"
            [nzPageSize]="20"
            nzSize="small"
            class="nz-table-xs"
            [nzShowPagination]="true"
            >
            <thead>
              <tr>
                <th>{{ 'common.time' | actrans }}</th>
                <th>{{ 'common.type' | actrans }}</th>
                <th>{{ 'common.user' | actrans }}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of eventsTable.data">
        
                <td>{{ data.time }}</td>
                <td>{{ data.mkLeadEvent }}</td>
                <td>{{ data.userLabel }}</td>
                <!-- <td>{{ 'common.' + data.guestCountIsQuantity | actrans }}</td> -->
        <!-- nzEllipsis -->
        
              </tr>
            </tbody>
          </nz-table>

        </div>
         
          
        </form>
      </div>

    </ng-container>
    <div *nzModalFooter>
      <!-- <span>Modal Footer:</span>
       && config.params.userLeads
       *ngIf="lead.obj?.eventTypes?.ConfirmContact"
      -->

      <button 
        nz-button 
        nzType="default" 
        nzDanger 
        (click)="removeLead()"
        *ngIf="lead.obj && !lead.obj.eventTypes?.ConfirmSale"
        >
        Fjern fra min visning
      </button>

      <button nz-button nzType="primary" (click)="closeLeadModal()">{{ 'common.close' | actrans }}</button>
    </div>
  </nz-modal>


  <!-- <div  class="m-d">
    <div class="svg-dash" style="width: 300px; height: 200px;">
      Test
    </div>
    <div class="m-d"></div>
    <svg xmlns="http://www.w3.org/2000/svg" width="100px" height="100px">
      <rect x="0" y="0" width="100" height="100" fill="transparent" stroke="black" stroke-width="4px" stroke-dasharray="10 10 10 10" />
    </svg>
    
  </div> -->


</btlib-admin-page-wrapper>

