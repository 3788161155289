import { Component, Injector, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MiscUtil } from 'src/app/lib/core/util/misc.util';

import { AppService, APP_DATAID } from 'src/app/services/app.service';
import { DATAID } from 'src/app/lib/core/services/data.service';

@Component({
  selector: 'btlib-admin-page-wrapper',
  templateUrl: './admin-page-wrapper.component.html',
  styleUrls: ['./admin-page-wrapper.component.scss']
})
export class AdminPageWrapperComponent implements OnInit {

  route: ActivatedRoute;

  @Input()
  showMenu:boolean = true;

  data:any = {

  }
  
  constructor(public injector:Injector, public apps:AppService) {
    this.route = injector.get(ActivatedRoute);
    this.data.cbclassic = !!this.route.snapshot.queryParams["cbclassic"];

    this.showMenu = !this.data.cbclassic;
    
  }

  ngOnInit(): void {
  }


}

