import { Component, Injector, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { MiscUtil } from 'src/app/lib/core/util/misc.util';
// import { BooktechAppService } from 'src/app/lib/services/booktech-app.service';
// import { AppService } from '../../../../../services/app.service';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'btlib-iframe',
  templateUrl: './iframe.component.html',
  styleUrls: ['./iframe.component.scss']
})
export class IframeComponent implements OnInit, OnChanges {

  @Input() src:string = "";
  @Input() autoResize:boolean = true;

  safeSource:SafeResourceUrl|undefined;

  // bas:BooktechAppService

  constructor(public apps:AppService) {

  }

  ngOnInit(): void {
    
  }

  ngOnChanges(changes: SimpleChanges): void {
    //console.log("IframeComponent.ngOnChanges, changes: ", changes);
    this.safeSource = this.apps.bas.ui.trustUrl(this.src);
  }
  

}
