<btlib-admin-page-wrapper [showMenu]="false">
  <!-- <div>
    <div class="m-d">
      <button nz-button nzType="default" nz-dropdown  nzTrigger="click" [nzDropdownMenu]="menu" >
        <i nz-icon [nzType]="'menu'" ></i> <span>{{ 'web.main.menu' | actrans  }}</span>
      </button>
    </div>


    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul nz-menu>
        <li nz-menu-item [routerLink]="apps.bas.ui.getRouterPrefix() + '/app/bileasy/admin/'" >{{ 'Salgsmuligheter' | actrans  }}</li>
      </ul>
    </nz-dropdown-menu>
  </div> -->

  <div class="m-d">
    <button nz-button [routerLink]="apps.bas.ui.getRouterPrefix() + '/app/bileasy/admin/'">{{ 'Salgsmuligheter' | actrans  }} </button>
  
  </div>
  

 
  <nz-spin [nzSpinning]="isSpinning">

    <div class="m-d" *ngIf="edit.obj">
      <form nz-form [formGroup]="edit.form" class="user-form" (ngSubmit)="submitForm()" nzLayout="vertical" [nzAutoTips]="apps.bas.fs.autoTips">

        <h1>{{ 'web.common.user' | actrans }}</h1>
        <!-- <p>{{ edit.form.controls.username.value }} </p> -->
        <hr/>



      
        <div>
      
          <btlib-form-row  [controls]="[ 
            edit.controls.username, 
            edit.controls.password,
            edit.controls.subscriptionTypeLabel ]" 
            [form]="edit.form"  [grid]="apps.bas.ui.gridSm" (change)="onChange($event)" ></btlib-form-row> 
          
          <btlib-form-row  [controls]="[ edit.controls.name,  edit.controls.email ]" [form]="edit.form"  [grid]="apps.bas.ui.gridMd" (change)="onChange($event)" ></btlib-form-row> 
          <btlib-form-row  [controls]="[ edit.controls.address1, edit.controls.address2 ]" [form]="edit.form"  [grid]="apps.bas.ui.gridMd" (change)="onChange($event)" ></btlib-form-row> 
          <btlib-form-row  [controls]="[ edit.controls.zipCode, edit.controls.city ]" [form]="edit.form"  [grid]="apps.bas.ui.gridMd" (change)="onChange($event)" ></btlib-form-row> 
          <!-- <btlib-form-row  [controls]="[ edit.controls.language, edit.controls.timeZone ]" [form]="edit.form"  [grid]="apps.bas.ui.gridMd" (change)="onChange($event)" ></btlib-form-row>  -->
          <!-- <btlib-form-row  [controls]="[ edit.controls.telephone ]" [form]="edit.form"  [grid]="apps.bas.ui.gridMd" (change)="onChange($event)" ></btlib-form-row>  -->
      
        </div>
        
        <div *ngIf="edit.obj.isUser || edit.obj.isProvider">
          <btlib-form-row  [controls]="[ 
            edit.controls.telephone,
            edit.controls.socialSecurityNumber,  
            edit.controls.orgNumber
            ]" [form]="edit.form"  [grid]="apps.bas.ui.gridMd" (change)="onChange($event)" ></btlib-form-row> 
          <!-- edit.controls.customerType,  -->
        </div>

        <div *ngIf="edit.obj.isUser && !edit.ct.isPrivate">
          <btlib-form-row  [controls]="[ edit.controls.ehfEnabled ]" [form]="edit.form"  [grid]="apps.bas.ui.gridMd" (change)="onChange($event)" ></btlib-form-row> 
          <btlib-form-row *ngIf="edit.form.controls.ehfEnabled.value"  [controls]="[ 
          edit.controls.ehfOrderReference, 
          edit.controls.ehfContractDocumentReference,  
          edit.controls.ehfContactId
          ]" [form]="edit.form"  [grid]="apps.bas.ui.gridMd" (change)="onChange($event)" ></btlib-form-row> 
    
        </div>


        <div *ngIf="edit.obj.isProvider">
          <btlib-form-row  [controls]="[ 
          edit.controls.webAddress,  
         
          ]" [form]="edit.form"  [grid]="apps.bas.ui.gridMd" (change)="onChange($event)" ></btlib-form-row> 
    
        </div>
        <!--  edit.controls.bankAccount -->

        <!-- <div>
          <btlib-form-row  [controls]="[ edit.controls.acceptInfoEmail, edit.controls.acceptInfoSms ]" [form]="edit.form"  [grid]="apps.bas.ui.gridMd" (change)="onChange($event)" ></btlib-form-row> 
      
        </div> -->
        <!-- TODO: kontaktperson -->


        <nz-form-item>
          <nz-form-control class="form-buttons">
            <button nz-button nzType="primary">
              {{ 'app.lib.common.save' | actrans }} 
            </button>

            <button nz-button nzType="default" (click)="$event.preventDefault(); userChangePassword = edit.obj;">{{ 'web.ajax.password' | actrans }}</button>

            <button *ngIf="edit.obj.moveEnabled" nz-button nzType="default" (click)="$event.preventDefault(); userChangeUsername = edit.obj;">{{ 'web.common.user.changeUsername' | actrans }}</button>
            
            <button *ngIf="edit.obj.moveEnabled" nz-button nzType="default" nzDanger class="pull-right"  (click)="$event.preventDefault(); userDeleteUser = edit.obj;">{{ 'web.common.user.deleteUser' | actrans }}</button>

          </nz-form-control>
        </nz-form-item>

      </form>


      <bt-user-change-password [(user)]="userChangePassword"></bt-user-change-password>

      <bt-user-change-username [(user)]="userChangeUsername"></bt-user-change-username>
      <bt-user-delete-user [(user)]="userDeleteUser"></bt-user-delete-user>


  


  </div>
  </nz-spin>

</btlib-admin-page-wrapper>