import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { MiscUtil } from 'src/app/lib/core/util/misc.util';
import { AppService, APP_DATAID } from 'src/app/services/app.service';

import { 
  MbscEventcalendarOptions, 
  MbscEventcalendarView, 
  MbscCalendarEvent, 
  MbscPageLoadingEvent, 
  MbscEventcalendar} from '@mobiscroll/angular-ivy';
import { BtEvent } from 'src/app/lib/core/services/event.service';
import { FormGroup } from '@angular/forms';
import { DynformControl, DynformControlsMap } from 'src/app/lib/core/model/dymform-control';
import { DATAID } from 'src/app/lib/core/services/data.service';


@Component({
  selector: 'app-admin-order-overview-page',
  templateUrl: './admin-order-overview-page.component.html',
  styleUrls: ['./admin-order-overview-page.component.scss']
})
export class AdminOrderOverviewPageComponent implements OnInit {

  route: ActivatedRoute;

  @ViewChild('myEventcalendar', { static: false })
  myEventcalendar: MbscEventcalendar | undefined;
  
 
  myResources = [];


  myEvents: MbscCalendarEvent[] = [];

  // calSize: string = "auto";
  calSizeCalc: string = "auto";
 
  view : MbscEventcalendarView = {
    // calendar: {
    //   //popover: true,  // TOOD: på mobil?
    //   //labels: false
    // },
    // agenda: { 
    //   // type: 'week' 
    //   type: 'day'
    // },
    timeline: { 
      type: 'month', // 'month', 'week'
      weekNumbers: true,
      // eventList: true

      // startDay: 0,
      // endDay: 6,
      startTime: '07:00',  // TODO: beregne hva disse verdiene skal være utfra hendelsene 
      endTime: '23:00',
      // timeCellStep: 360,    // Minuter per celle
      // timeLabelStep: 360,  // 24 timer :  timeLabelStep: Number (default 60) - Set the step of the time labels in minutes. Supported values: 1, 5, 10, 15, 20, 30, 60, 120, 180, 240, 360, 480, 720, 1440. 
      
    }
  }

  eventSettings: MbscEventcalendarOptions = {
    // locale: localeNo,
    // theme: 'ios',
    themeVariant: 'light',

    // clickToCreate: false,
    // dragToCreate: false,
    dragToMove: false,
    dragToResize: false,

    // TODO
    clickToCreate: true,
    dragToCreate: true,
    // dragToMove: true,
    // dragToResize: true,

    height: '80vh',
    view: this.view,
    marked: this.myEvents,
    // responsive: {
    //   xsmall: {
    //       view: {
    //         timeline: { 
    //           type: 'week', // 'month',
    //           eventList: true
    //         }
    //           // agenda: {
    //           //     type: 'week'
    //           // }
    //       }
    //   },
    //   medium: { // 

    //     view: {
    //       timeline: { 
    //         type: 'month', // 'month',
    //         // eventList: true
    
    //         startTime: '10:00',  // TODO: beregne hva disse verdiene skal være utfra hendelsene 
    //         endTime: '17:00',
    //         // timeCellStep: 1440,    // Minuter per celle
    //         // timeLabelStep: 1440,  // 24 timer :  timeLabelStep: Number (default 60) - Set the step of the time labels in minutes. Supported values: 1, 5, 10, 15, 20, 30, 60, 120, 180, 240, 360, 480, 720, 1440. 
            
    //       }
    //     }
    //   }
    // },
    onEventClick: (event, inst) => {
      if(this.apps.bas.envtest) console.log("onEventClick, event: ", event);
      //let title:string = event.event.title || "";
      //this.apps.bas.ui.info(title);

      let ev = event.event;

      // TODO: vise alle bookinger på dette tidspunktet, inkludert funksjons for å sende SMS og epost.
      if (ev.psi) {
        console.log("psi: ", ev.psi);
        let biDatas = ev.psi.biDatas || [];
        if (biDatas.length == 0) {
          //TODO: 
          this.updateNewEventForm(event.event);
          return;
        }

        let biids = biDatas.map(((bid:any) => bid.id));
        console.log("biids: ", biids);
        this.orderListInput = { biids: biids };
        return;
      }

      if (ev.bid) {
        this.orderListInput = { biids: [ event.event.id ] };
        // this.orderInfo = { id: event.event.bid };
        return;
      }


    },

    extendDefaultEvent: (event) => {

      return {
        title: "Legg til"
      };
    },
    onEventCreate: (event, inst) => {
      if(this.apps.bas.envtest) console.log("onEventCreate, event: ", event);

      this.updateNewEventForm(event.event);
      return false;
    },
    onEventCreated: (event, inst) => {
      if(this.apps.bas.envtest) console.log("onEventCreated, event: ", event);

    },
    onEventDelete: (event, inst) => {
      if(this.apps.bas.envtest) console.log("onEventDelete, event: ", event);

    },
    onEventDeleted: (event, inst) => {
      if(this.apps.bas.envtest) console.log("onEventDeleted, event: ", event);

    },
    onEventUpdate: (event, inst) => {
      if(this.apps.bas.envtest) console.log("onEventUpdate, event: ", event);

      return false;
    },
    onEventUpdated: (event, inst) => {
      if(this.apps.bas.envtest) console.log("onEventUpdated, event: ", event);

    }
    
  };

  calSizes = [
    { "value": "auto", "label" :"Auto" },
    { "value": "sm", "label" :"Liten" },
    { "value": "md", "label" :"Medium" },
    { "value": "lg", "label" :"Stor" },
    { "value": "xl", "label" :"Ekstra stor" },
    
  ];

  df:any = {
    form: undefined,
    controls: [],
    obj: undefined,
    params: { }
  };

  newEvent:any = {
    form: undefined,
    controls: [],
    obj: undefined,
  };

  overviewData:any = { };
  orderInfo:any = { };
  orderListInput:any = { };

  constructor(public injector:Injector, public apps:AppService) {
    this.route = injector.get(ActivatedRoute);
    this.apps.bas.us.checkActivatedRoute(this.route.snapshot);

    if(this.apps.bas.envtest) console.log("AdminOrderRootPageComponent");
    
    this.apps.bas.es.on(BtEvent.RESIZE, (ev:any) => {
      this.doResize(ev.responsive);
    });

    this.apps.bas.ds.find(APP_DATAID.ORDER_OVERVIEW_DATA, { }).subscribe(od => {
      console.log("ORDER_OVERVIEW_DATA.subscribe: ", od);
      if (this.overviewData.init) return;

      od = od || { };
      od.init = true;
      this.overviewData = od;
      
      this.apps.bas.ws.json({
        actionType: "appAdmin",
        action: "getOverviewFbo",
        productType: "",

      }).then((json:any) => {
        if(this.apps.bas.envtest) console.log("getOverviewFbo.then, json: ", json);
        if (json.success) {
        
          this.updateForm(json);

          this.updateEvents({ updateType: "init" })

        }

        

      });

    });




  }



  ngOnInit(): void {
    
  }


  onOrderInfoChange(event:any) {
    this.updateEvents({ updateType: "onOrderInfoChange" })

  }

  onOrderListChange(event:any) {
    this.updateEvents({ updateType: "onOrderListChange" })
  }



  updateNewEventForm(event:any) { // { start, end, resource }
    if(this.apps.bas.envtest) console.log("updateNewEventForm, event: ", event, ", typeof start: " + (typeof event.start));

    if (typeof event.start === "string") {
      event.start = this.apps.bas.ui.dateParseIso(event.start);
      event.end = this.apps.bas.ui.dateParseIso(event.end);

      if(this.apps.bas.envtest) console.log("updateNewEventForm, event.parse: ", event);

    }

    let controls: DynformControl[] = [];

    controls.push(new DynformControl({ key: 'product' }));
    controls.push(new DynformControl({ key: 'comment', mk: 'common.comment', controlType: 'textarea' }));

    controls.push(new DynformControl({ 
      key: 'period',         
      mk: 'common.period', 
      controlType: "datetime-range-picker",
      onChange: (event:any) => {
        if(this.apps.bas.envtest) console.log("period.onChange: ", event);
        this.adminOrderRefresh();
      }, data: { 
        readOnly: this.apps.bas.ui.r.xs
       } 
     }));

     controls.push(new DynformControl({ 
       key: 'availability',
       mk: 'web.common.overview.availabilityOrder',
       controlType: 'input',
       required: true,
       type: 'number'
     }));

    // controls.push(new DynformControl({ key: 'showEmptyResources',               mk: 'web.common.overview.showEmptyResources' }));
   

    let obj = {
      product: event.resource,
      period: [ event.start, event.end ],
      availability: 0,
      comment: ""
    };
    this.apps.bas.fs.updateFormObj(this.newEvent, controls, obj);
    this.adminOrderRefresh(true);
  }

  adminOrderRefresh(updatePeriod:boolean = false) {
    let rv = this.apps.bas.fs.getRawValue(this.newEvent.form, this.newEvent.controls, true);

    let period = this.newEvent.form.controls.period.value;
    if(this.apps.bas.envtest) console.log("period: " + period);

    let pTo = period[1];
    //pTo = this.apps.bas.ui.dateSet(pTo, { hours: 23, minutes: 59 });

    let periodString = this.apps.bas.ui.dateTimeRangeToString([ period[0], pTo]) ;


    this.apps.bas.ws.json({
      actionType: "appAdmin",
			action: "adminOrderRefresh",
      product: rv.product,
      period: periodString

    }).then((json:any) => {
      if(this.apps.bas.envtest) console.log("adminOrderRefresh.then, json: ", json);
      if (json.success) {
        let pw = json.pw;
        let form = this.newEvent.form;
        let ctrls = this.newEvent.controls;

        if (updatePeriod) {
          let fcvPeriod = this.apps.bas.fs.toFormControlValue(ctrls.period, pw.periodAsRange);

          if(this.apps.bas.envtest) console.log("Updating period: ", fcvPeriod);
          form.controls.period.setValue(fcvPeriod)
        }

        this.newEvent.pw = pw;


      }

      

     });
  }

  newEventSubmit() {
    let rv = this.apps.bas.fs.getRawValue(this.newEvent.form, this.newEvent.controls);
    if (rv === false) return;

    if (rv.availability == 0) {
      this.apps.bas.ui.error("Du må velge endring i tilgjengelighet"); 
      return;
    }
    
    if(this.apps.bas.envtest) console.log("newEventSubmit, rv: ", rv);

    let params:any = {
      actionType: "appAdmin",
			action: "adminOrderSaveAvailability",
    };
    MiscUtil.extend(params, rv);

    this.apps.bas.ws.json(params).then((json:any) => {
      if(this.apps.bas.envtest) console.log("adminOrderSaveAvailability.then, json: ", json);
      if (json.success) {
        
        this.apps.bas.ui.success("Lagret!"); 

        this.myEvents = [ ...this.myEvents, json.event ];

        this.newEvent.obj = undefined;

      }

      

     });


    
  }

  //

  updateForm(obj:any) {

    let od = this.overviewData;

    
    obj.showEmptyResources = od.showEmptyResources !== undefined ? od.showEmptyResources : false;
    obj.showAccumulated =  od.showAccumulated !== undefined ? od.showAccumulated : true;
    obj.calEventList =  od.calEventList !== undefined ? od.calEventList : false;
    obj.includeCancelled =  od.includeCancelled !== undefined ? od.includeCancelled : false;
    
    obj.calSize = od.calSize || "auto";
    console.log("updateForm, od.calSize: " + od.calSize + ", obj.calSize: " + obj.calSize);

    let controls: DynformControl[] = [];

    controls.push(new DynformControl({ key: 'showPoolUnits',               mk: 'web.common.overview.showPoolUnits' }));
    controls.push(new DynformControl({ key: 'showEmptyResources',               mk: 'web.common.overview.showEmptyResources' }));
    controls.push(new DynformControl({ key: 'showAccumulated',               mk: 'web.common.overview.showAccumulated', controlType: "checkbox" }));
    controls.push(new DynformControl({ key: 'calEventList',               mk: 'web.common.overview.calEventList', controlType: "checkbox" }));
    controls.push(new DynformControl({ key: 'includeCancelled',               mk: 'web.common.overview.includeCancelled', controlType: "checkbox" }));
    
    
    controls.push(new DynformControl({ key: 'productQuery',               mk: 'web.common.overview.productQuery' }));
    
    
    controls.push(new DynformControl({ 
      key: 'productType',  
      // valuePath: "ruleId",
      mk: 'bus.product.productType',  
      controlType: 'select', 
      // required: true,
      options: () => {
        return obj.productTypes; 
      },
      optionsAllowEmpty: true,
      optionsFieldValue: "id",
      optionsFieldLabel: "mkName"
    }));

    controls.push(new DynformControl({ 
      key: 'productSubType',  
      mk: 'bus.product.subType',  
      controlType: 'select', 
      // required: true,
      options: () => {
        return obj.productSubTypes;  //TODO: bare vise etter valgt productType
      },
      optionsAllowEmpty: true,
      optionsFieldValue: "id",
      optionsFieldLabel: "mkName"
    }));

    controls.push(new DynformControl({ 
      key: 'provider',  
      mk: 'common.provider',  
      controlType: 'select', 
      // required: true,
      options: () => {
        return obj.providers || [];
      },
      show: () => {
        return this.apps.bas.aas.isAdmin();
      },
      optionsAllowEmpty: true,
      optionsFieldValue: "id",
      optionsFieldLabel: "label"
    }));

    controls.push(new DynformControl({ 
      key: 'area',  
      mk: 'bus.area',  
      controlType: 'select', 
      // required: true,
      options: () => {
        return obj.areas || [];
      },
      optionsAllowEmpty: true,
      optionsFieldValue: "id",
      optionsFieldLabel: "mkName"
    }));


    controls.push(new DynformControl({ 
      key: 'calSize',  
      mk: 'common.size',  
      controlType: 'select', 
   
      options: () => {
        return this.calSizes;
      },
      onChange: (ev:any) => {
        // this.calSize = ev.value;
        this.updateCalSize();
        
        this.apps.bas.ds.save(APP_DATAID.ORDER_OVERVIEW_DATA, this.overviewData);

      }
      // optionsAllowEmpty: true,
      // optionsFieldValue: "valku",
      // optionsFieldLabel: "label"
    }));

    /*


prevBooking: ""
prevBookingId: ""
prevBookingLabel: ""
prevBookingObj: ""

products: (8) [{…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}]
status: 0

tags: "
    */


    this.apps.bas.fs.updateFormObj(this.df, controls, obj);


  }

  dfSubmit() {
    this.updateEvents({ updateType: "formSubmit" });
  }

  onPageLoading(event: MbscPageLoadingEvent) {

    MiscUtil.extend(this.df.params, { 
      startAsIso8601: this.apps.bas.ui.dateToIsoString(event.firstDay),
      endAsIso8601: this.apps.bas.ui.dateToIsoString(event.lastDay),
    });
    this.updateEvents({ updateType: "onPageLoading" });
  }

  updateEvents(options:any = { }) {

    if (!this.df.obj) {
      if(this.apps.bas.envtest) console.log("!this.df.obj");
      return;
    }
    if (!this.df.params.startAsIso8601) {
      if(this.apps.bas.envtest) console.log("!this.df.params.startAsIso8601");
      return;
    }

    let rv = this.apps.bas.fs.getRawValue(this.df.form, this.df.controls);
    if (rv === false) return;

    let od = this.overviewData;
    od.showEmptyResources = rv.showEmptyResources;
    od.showAccumulated = rv.showAccumulated;
    od.calEventList = rv.calEventList;
    od.calSize = rv.calSize;
   
    this.apps.bas.ds.save(APP_DATAID.ORDER_OVERVIEW_DATA, od);

    if(this.apps.bas.envtest) console.log("updateEvents, options: ", options, ", rv: ", rv);

    let params = {
      actionType: "mobiscroll",
			action: "getTimelineEvents",

     };

     MiscUtil.extend(params, this.df.params);
     MiscUtil.extend(params, rv);

    this.apps.bas.ws.json(params ).then((json:any) => {
      if(this.apps.bas.envtest) console.log("updateEvents.then, json: ", json);

      let events = json.events || [];

      this.myEvents = events;
      this.myResources = json.resources || [ ];

      if (options.updateType == "init") {
        this.myEventcalendar?.navigate(new Date());
      }

      this.updateCalSize();

     });

  }

  doResize(r?:any) {  

    if(this.apps.bas.envtest) console.log(MiscUtil.getLogText("doResize", true) + ", r: ", r); 
    let height = r.h;
    let width = r.w;

    let labelsValue = width >= 768;
    // if (this.view.calendar && this.view.calendar.labels != labelsValue) {
    //   if(this.apps.bas.envtest) console.log("Setting view.calendar.labels: " + labelsValue); 
    //   this.view.calendar.labels = width > 768;
    //   this.view = MiscUtil.extend({ }, this.view);
    // }


  }


  updateCalSize() {
    let calSize = this.df.form.controls.calSize.value;
    let cs = calSize || "auto";
    if (cs == "auto") {
      let r = this.apps.bas.ui.r;
      cs = "sm";
      if (r.w >= 768) cs = "md";
      if (r.w >= 1000) cs = "lg";
      if (r.w >= 1200) cs = "xl";
    }

    let isSm = cs === "sm";
    let isMd = cs === "md";
    let isLg = cs === "lg";
    let isXl = cs === "xl";
    
    let tl = this.view.timeline!;

    tl.eventList = this.df.form.controls.calEventList.value;

    let cellStep = 1440;
    let startTime = "24:00";
    let endTime = "00:00";
    

    if (tl.eventList) {
      startTime = "00:00";
      endTime = "24:00";

    } else {

      
      if (isMd) cellStep = 720;
      if (isLg) cellStep = 360;
      if (isXl) cellStep = 120;
      //   timeLabelStep: Number (default 60) - Set the step of the time labels in minutes. Supported values: 1, 5, 10, 15, 20, 30, 60, 120, 180, 240, 360, 480, 720, 1440. 
      
      
      

      if (this.myEvents && this.myEvents.length) {
        for (let ev of this.myEvents) {
          
          let evStart = ev.start instanceof Date ? ev.start : ( typeof ev.start === "string" ? this.apps.bas.ui.isoStringToDate(ev.start) : new Date());
          let evEnd = ev.end instanceof Date ? ev.end : ( typeof ev.end === "string" ? this.apps.bas.ui.isoStringToDate(ev.end) : new Date());
          let evStartTime = this.apps.bas.ui.timeFormat(evStart) || "00:00";
          let evEndTime = this.apps.bas.ui.timeFormat(evEnd) || "24:00";;
          //  console.log("updateCalSize, evStart: ", evStart, " -> " + evStartTime + ", evEnd: ", evEnd, " -> " + evEndTime);

          
          if (evStartTime > evEndTime) {
            if (startTime > evEndTime) {

              startTime = this.apps.bas.ui.timeFormat(this.apps.bas.ui.dateAdd(evEnd, { minutes: -60 }))!;
            }
            if (endTime < evStartTime) {
              // endTime = evStartTime;
              endTime = this.apps.bas.ui.timeFormat(this.apps.bas.ui.dateAdd(evStart, { minutes: +60 }))!;
            }

          } else {
            if (startTime > evStartTime) startTime = evStartTime;
            if (endTime < evEndTime) endTime = evEndTime;
  
          }
        }
      } else {
        startTime = "00:00";
        endTime = "24:00";
      
      }


    }


    console.log("updateCalSize, timePeriod: " + startTime + " -> " + endTime + "; cs: " + cs + ", cellStep: " + cellStep);

    tl.timeCellStep = cellStep;
    tl.timeLabelStep = cellStep;
      
    tl.startTime = startTime;
    tl.endTime = endTime;
    

    this.calSizeCalc = cs;
    this.view = MiscUtil.extend({ }, this.view);

    this.overviewData.calSize = calSize;

  }


  closeNewEventModal() {


    this.newEvent.obj = undefined;
  }


}
