
<div *ngIf="pw.id">

  <form nz-form [nzLayout]="'vertical'" class="m-d" >
    <div nz-row  [nzGutter]="apps.bas.ui.gridMd.gutter" nzAlign="top" >
      <div nz-col  [nzXs]="view.grid.date.xs" [nzSm]="view.grid.date.xs" [nzMd]="view.grid.date.md" [nzLg]="view.grid.date.lg" [nzXl]="view.grid.date.xl" [nzXXl]="view.grid.date.xxl" class="" style="">
          
        <nz-form-item>
          <nz-form-label  class="" nzFor="">{{ style.mkDate | actrans }}</nz-form-label>
          <nz-form-control [nzErrorTip]="style.mkDate | actrans | str">
          <div>
            <nz-input-group nzPrefixIcon="calendar"  [nzSize]="view.size">
              <input  
            
                mbsc-datepicker
                [controls]="['calendar']"
     
                select="date"
                display="anchored"

                [(ngModel)]="pw.startDateAsJsDate"
                name="startDateAsJsDate"
                [ngModelOptions]="{standalone: true}"
            
                calendarType="week"
                [calendarSize]="2"
                [invalid]="dateInvalid"
                (onPageLoading)="dateOnPageLoading($event)"
                (onChange)="dateOnChange($event)"

                class="ant-input"
              />
            </nz-input-group>
<!--     nz-input  -->

          </div>
          </nz-form-control>
      

          
        </nz-form-item>



      </div>


      <div nz-col  [nzXs]="view.grid.guests.xs" [nzSm]="view.grid.guests.sm" [nzMd]="view.grid.guests.md" [nzLg]="view.grid.guests.lg" [nzXl]="view.grid.guests.xl" [nzXXl]="view.grid.guests.xxl"  class="">
        <nz-form-item>
          <nz-form-label class="" nzFor="">{{ style.mkGuests | actrans }}</nz-form-label>
          <nz-form-control>
            <button 
              nz-button 
              nzBlock 
              [nzSize]="view.size"
              nz-dropdown 
              nzTrigger="click"  
              [nzDropdownMenu]="guestsPopup" 
              [(nzVisible)]="guestsPopupVisible"
              (nzVisibleChange)="guestsPopupVisibleChange($event)" 
              class="text-left"
              >
              <!-- <span>Gjester:</span>  -->
              <span class="text-lg-off"> {{ getGcs() }}</span>
              <!-- <i nz-icon nzType="down"></i> -->
            </button>
            <nz-dropdown-menu #guestsPopup="nzDropdownMenu">

              <div nz-menu>
                <i nz-icon nzType="close" class="pull-right p-3" (click)="guestsPopupClose()" ></i> 

                <div class="p-3">

                  <div *ngIf="pw.productObj.guestProduct" >
                    <div *ngFor="let gcMk of pw.guestCategories; index as gcIdx">
                      <div class="grid grid-cols-2 gap-4" *ngIf="pw.productObj.maxGcMap[gcIdx] !== 0">
                        <div> <nz-form-label  [nzFor]="'gc' + gcIdx">{{ gcMk }}</nz-form-label></div>
                        <div>
                          <nz-space [nzSize]="'small'">
                            <button *nzSpaceItem nz-button nzType="primary" nzShape="circle" (click)="decrementGcValue(gcIdx)" ><i nz-icon nzType="minus-circle"></i></button>
                            <input *nzSpaceItem nz-input [(ngModel)]="pw.guestCounts.map[gcIdx]" [name]="'gc' + gcIdx" class="text-center" style="width: 50px;" />
                            <button *nzSpaceItem nz-button nzType="primary" nzShape="circle" (click)="incrementGcValue(gcIdx)" ><i nz-icon nzType="plus-circle"></i></button>
                          </nz-space>

                        </div>
                      </div>
        
                    </div>
                  </div>

                </div>
              </div>

            </nz-dropdown-menu>
          </nz-form-control>
        </nz-form-item>
    </div>
    <div nz-col  [nzXs]="view.grid.button.xs" [nzSm]="view.grid.button.sm" [nzMd]="view.grid.button.md" [nzLg]="view.grid.button.lg" [nzXl]="view.grid.button.xl" [nzXXl]="view.grid.button.xxl"  class="">
      <nz-form-item>
        <nz-form-label class="" *ngIf="apps.bas.ui.r.gtesm"></nz-form-label>
        <nz-form-control class="custom-style" >
          <button nz-button nzBlock nzType="primary" [nzSize]="view.size" (click)="openOrderPage()">
            {{ style.mkButton | actrans }}
          </button>
        </nz-form-control>
      </nz-form-item>
    </div>

  </div>
  </form>

  <!-- <div *ngIf="apps.bas.envdev">
    <div style="width: 200px; height: 200px; background-color: var(--app-color-primary);"> app-color-primary </div>
    <div style="width: 200px; height: 200px; background-color: var(--app-custom-color-primary);"> app-custom-color-primary </div>
    <div style="width: 200px; height: 200px; background-color: var(--app-theme-color-primary);"> app-theme-color-primary </div>
    <div style="width: 200px; height: 200px; background-color: var(--app-fallback-color-primary);"> app-fallback-color-primary </div>
  
  
  </div> -->

</div>
