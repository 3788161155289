import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { MiscUtil } from 'src/app/lib/core/util/misc.util';

import { AppService } from 'src/app/services/app.service';

import { FormGroup } from '@angular/forms';
import { DynformControl, DynformControlsMap } from 'src/app/lib/core/model/dymform-control';

@Component({
  selector: 'app-app-tasks-tasks-page',
  templateUrl: './app-tasks-tasks-page.component.html',
  styleUrls: ['./app-tasks-tasks-page.component.scss']
})
export class AppTasksTasksPageComponent implements OnInit {


  fbo:any = {
    loading: true,
    stats: {
      current: [],
      navigatorObj: {
        size: 0,
        pageSize: 100,
        page: 0
      }
    }

  };

  edit:any = {
    obj: undefined,
   
  }

  df:any = {
    form: undefined,
    controls: [],
    obj: undefined,
    params: { 
      // 
    },
    statsForm: { 
      statsType: "TASK",
      currentJil: "min"
    },

    loading: false
  };
  
  constructor(public injector:Injector, public apps:AppService) {
    let route = injector.get(ActivatedRoute);
    this.apps.bas.us.checkActivatedRoute(route.snapshot);

    if(this.apps.bas.envtest) console.log("AdminTasksPageComponent");

    // this.apps.bas.aas.getProducts();
    // this.getObjects();
    this.updateFbo();
  }

  ngOnInit(): void {

  }

  dfSubmit() {
    this.updateFbo();
  }


  updateFbo(input:any = { }) {
    let params:any = {
      aType: "appAdmin",
      action: "getStatsFbo",
    };

    let rv = { };
    if (this.df.form) {
      rv = this.apps.bas.fs.getRawValue(this.df.form, this.df.controls);
      if (rv === false) return;
    }

    MiscUtil.extend(params, input);

    let statsForm = MiscUtil.extend({ }, this.df.statsForm);
    MiscUtil.extend(statsForm, rv);

    MiscUtil.extend(params, this.df.params);
    MiscUtil.extend(params, { statsForm: JSON.stringify(statsForm) } );

    if (this.apps.bas.envtest) console.log("updateFbo.params: ", params);

    this.fbo.loading = true;
    this.apps.bas.ws.json(params ).then((json:any) => {
      if (this.apps.bas.envtest) console.log("updateFbo.then, json: ", json);

      //TODO: må lagre dssse verdiene lokalt slik at man ikke må stille inn hver gang siden lastes.

      if (json.success) {
        this.updateForm(json.stats);

        this.fbo = json;
        
      } else {

      }
      

     });
  }

  
  updateForm(stats:any) {

    let controls: DynformControl[] = [];

    controls.push(new DynformControl({ key: 'search',               mk: 'common.search' }));
    
    controls.push(new DynformControl({ 
      key: 'product',  
      // valuePath: "ruleId",
      mk: 'common.product',  
      controlType: 'select', 
      // required: true,
      options: () => {
        return stats.products || []; 
      },
      optionsAllowEmpty: true,
      optionsFieldValue: "id",
      optionsFieldLabel: "mkName"
    }));

    controls.push(new DynformControl({ 
      key: 'taskType',  
      valuePath: "taskTypeLabel",
      mk: 'common.type',  
      controlType: 'select', 
      // required: true,
      options: () => {
        return stats.taskTypes; 
      },
      optionsAllowEmpty: true,
      optionsFieldValue: "enumName",
      optionsFieldLabel: "enumName"
    }));

    
    controls.push(new DynformControl({ 
      key: 'taskStatus',  
      valuePath: "taskStatusLabel",
      mk: 'common.status',  
      controlType: 'select', 
      // required: true,
      options: () => {
        return stats.taskStatuss || []; 
      },
      optionsAllowEmpty: true,
      optionsFieldValue: "enumName",
      optionsFieldLabel: "enumName"
    }));

    this.apps.bas.fs.updateFormObj(this.df, controls, stats);
  }


  newObject() {
    this.editObject({   }); 
  }

  editObject(obj:any) {

    if(this.apps.bas.envtest) console.log("editObject, obj: ", obj);

    let controls: DynformControl[] = [];

    controls.push(new DynformControl({ 
      key: 'title',     
      mk: 'common.title'
    }));



    controls.push(new DynformControl({ 
      key: 'type',  
      valuePath: "typeLabel",
      mk: 'common.type',  
      controlType: 'select', 
      required: true,
      options: () => {
        return this.fbo.stats.taskTypes || []; 
      },
      optionsAllowEmpty: true,
      optionsFieldValue: "enumName",
      optionsFieldLabel: "enumName"
    }));

    controls.push(new DynformControl({ 
      key: 'status',  
      valuePath: "statusLabel",
      mk: 'common.status',  
      controlType: 'select', 
      required: true,
      options: () => {
        return this.fbo.stats.taskStatuss || []; 
      },
      optionsAllowEmpty: true,
      optionsFieldValue: "enumName",
      optionsFieldLabel: "enumName"
    }));



    controls.push(new DynformControl({ 
      key: 'product',  
      // valuePath: "statusId",
      mk: 'common.product',  
      controlType: 'select', 
      // required: true,
      options: () => {
        return this.fbo.stats.products || []; // this.apps.bas.ds.getValue('APP_ADMIN_PRODUCTS').products || []; 
      },
      optionsAllowEmpty: true,
      optionsFieldValue: "id",
      optionsFieldLabel: "mkName"
    }));

    this.apps.bas.fs.updateFormObj(this.edit, controls, obj);
  } 

  onEditCancel() {
    this.edit.obj = undefined;
  }
  onEditOk() {

    let rv = this.apps.bas.fs.getRawValue(this.edit.form, this.edit.controls);
    if (rv === false) return;
    

    if(this.apps.bas.envtest) console.log("onEditOk, rv: ", rv);

    let obj = this.edit.obj;
    let isNew = !obj.id;

    let params:any = {
      aType: "appAdmin", 
      action: "saveObject", 
      type: "task",
      id: isNew ? "new" : obj.id,
      isNew: isNew,
      object: JSON.stringify(rv)
    }

    this.apps.bas.ws.json(params).then((json) => {
      if (json.success) {
        this.updateFbo();

      }
    });
    
    this.edit.obj = undefined;
  }



}
