import { Component, Input, OnInit } from '@angular/core';
import { BtEvent } from 'src/app/lib/core/services/event.service';

import { AppService, APP_DATAID } from '../../../../../services/app.service';
import { DATAID } from '../../../services/data.service';


@Component({
  selector: 'btlib-order-cart-preview',
  templateUrl: './order-cart-preview.component.html',
  styleUrls: ['./order-cart-preview.component.scss']
})
export class OrderCartPreviewComponent implements OnInit {

  @Input() cart:any;

  popoverVisible:boolean = false;
  
  constructor(public apps:AppService) {

  }

  ngOnInit(): void {

  }

  emptyCart() {
    this.apps.bas.ds.save(APP_DATAID.ORDER_CART, { }, true);
    this.popoverVisible = false;
  }

  gotoCheckout() {
    this.apps.bas.es.trigger(BtEvent.GOTO_CHECKOUT);
    this.popoverVisible = false;
  }

}
