<btlib-admin-page-wrapper>
 
 
    <nz-spin [nzSpinning]="isSpinning">

      <div class="m-d" *ngIf="p">

    
      <div nz-row  [nzGutter]="8" style="flex-flow: row;">
        <div nz-col [nzFlex]="'35px'">


          <nz-affix [nzOffsetTop]="10">
            

            <div id="linkTop"></div>
            <nz-anchor [nzAffix]="false">

             <!-- <nz-link [nzHref]="'#linkTop'" [nzTitle]="backLinkTemplate" class="section-link" > 
                <ng-template #backLinkTemplate>
                  -->
                  <button nz-button nzType="primary" nzShape="circle" class="section-button-back" [nzSize]="apps.bas.ui.r.gtesm ? 'default' : 'small'" [routerLink]="apps.bas.ui.getRouterPrefix() + '/admin/products'" [title]="'common.products' | actrans">
                    <i nz-icon nzType="left"></i>
                  </button>
                <!--  </ng-template>

              </nz-link>-->

              <nz-link [nzHref]="'#linkTop'" [nzTitle]="saveLinkTemplate" class="section-link" > 
                <ng-template #saveLinkTemplate>
                  <button nz-button nzType="primary" nzShape="circle" class="section-button-save" [nzSize]="apps.bas.ui.r.gtesm ? 'default' : 'small'" (click)="submitForm()" [title]="'common.save' | actrans">
                    <i nz-icon nzType="save"></i>
                  </button>
                </ng-template>

              </nz-link>
              
              <nz-link [nzHref]="'#linkTop'" [nzTitle]="helpLinkTemplate" class="section-link" > 
                <ng-template #helpLinkTemplate>
                  <button nz-button nzType="primary" nzShape="circle" class="section-button" [nzSize]="apps.bas.ui.r.gtesm ? 'default' : 'small'" (click)="helpVisible = true;"  [title]="'common.help' | actrans" ><i nz-icon nzType="question-circle"></i></button>
                </ng-template>

              </nz-link>

              <nz-link   *ngFor="let s of apps.bas.us.values(Sections)" [nzHref]="'#section-' + s.id" [nzTitle]="linkTemplate" class="section-link" > 
                <ng-template #linkTemplate>
                  <button  *ngIf="isSectionVisible(s.id, true)" nz-button nzType="primary" nzShape="circle" class="section-button" [nzSize]="apps.bas.ui.r.gtesm ? 'default' : 'small'" (click)="activeSection = s.id" [title]="s.title | actrans" >
                    <i nz-icon [nzType]="s.icon"></i> 
                  </button>
 
                </ng-template>

              </nz-link>

   
            </nz-anchor>


  
          </nz-affix>

          <nz-modal [(nzVisible)]="helpVisible" nzTitle="Hjelp" (nzOnCancel)="helpVisible = false;" (nzOnOk)="helpVisible = false;">
            <ng-container *nzModalContent>
              <h2>{{ 'app.cbapi5.page.admin.product.sections' | actrans }}</h2>
              <div nz-row *ngFor="let s of getVisibleSections()">
                <div nz-col  [nzFlex]="'35px'" >
                  <i nz-icon [nzType]="s.icon"></i> 
                </div>
                <div nz-col >
                  {{ s.title | actrans }}

                </div>
              </div>
    
            </ng-container>
          </nz-modal>

         
        </div>

        <div nz-col  nzFlex="auto" style="width: calc(100% - 40px);">


          <form nz-form [formGroup]="productForm" class="product-form" (ngSubmit)="submitForm()" nzLayout="vertical" [nzAutoTips]="apps.bas.fs.autoTips">
   
          <h1>{{ productForm.controls.name.value }}  <span *ngIf="!productForm.controls.enabled.value" class="fgc-r">({{ 'common.disabled' | actrans }})</span> </h1>
          <div *ngIf="p.id" >
            <p>
              <span>{{ 'common.shortUrl' | actrans }}: </span>
              <span *ngFor="let lang of apps.bas.ds.config.appConfig?.enabledLanguages; let idx = index">
                <span *ngIf="idx" class="mx-1">|</span>
                <img [src]="'assets/img/svg/flags/' + lang.code + '.svg'" style="height: 15px;" class="mx-1" />
                <a [href]="'https://bturl.no/p' + p.id + (lang.code != 'no' ? '?lang=' + lang.code : '')" target="_blank">{{ 'bturl.no/p' + p.id + (lang.code != 'no' ? '?lang=' + lang.code : '')  }}</a>
              </span>
             
            </p>
          </div>
          <hr/>



         
          <div id="section-basic" *ngIf="isSectionVisible('basic')">
            <h2>{{ 'app.lib.common.basic' | actrans }}</h2>
            <btlib-form-row  [controls]="[ controls.name, controls.productConfigId ]" [form]="productForm"  [grid]="gridMd" (change)="onChange($event)" ></btlib-form-row> 


            <btlib-form-row [controls]="[ controls.type, controls.subType ]" [form]="productForm"  [grid]="gridMd" (change)="onChange($event)" ></btlib-form-row> 

            <btlib-form-row [controls]="[ controls.vatInfo ]" [form]="productForm"  [grid]="gridMd" (change)="onChange($event)" ></btlib-form-row> 

            <div nz-row [nzGutter]="gridMd.gutter" nzAlign="top">
              <div nz-col [nzXs]="gridMd.xs" [nzSm]="gridMd.sm" [nzMd]="gridMd.md" [nzLg]="gridMd.lg" [nzXl]="gridMd.xl" [nzXXl]="gridMd.xxl">
                <bt-dynamic-form-control [control]="controls.timeConfigId" [form]="productForm" (change)="onChange($event)" ></bt-dynamic-form-control>
                <nz-alert nzType="info" *ngIf="controls.timeConfigId.fc?.value" [nzMessage]="apps.bas.us.listToMap(fbo.timeConfigs)[controls.timeConfigId.fc?.value]?.desc" nzShowIcon></nz-alert>
              </div>
              <div nz-col [nzXs]="gridMd.xs" [nzSm]="gridMd.sm" [nzMd]="gridMd.md" [nzLg]="gridMd.lg" [nzXl]="gridMd.xl" [nzXXl]="gridMd.xxl">
                <bt-dynamic-form-control [control]="controls.desc" [form]="productForm" (change)="onChange($event)" ></bt-dynamic-form-control>
              </div>

            </div>

    
            <!-- <hr/> -->


            <btlib-form-row [controls]="[ 
              controls.enabled, 
              controls.web, 
              controls.overview, 
              controls.frontpage, 
              controls.showInProductLists,
              controls.featured,
              ]" [form]="productForm"  [grid]="gridSm" (change)="onChange($event)" ></btlib-form-row> 


            <btlib-form-row [controls]="[ controls.tagsAsString, controls.groupTagsAsString ]" [form]="productForm"  [grid]="gridMd" (change)="onChange($event)" ></btlib-form-row> 

            <btlib-form-row [controls]="[  controls.archived,]" [form]="productForm"  [grid]="gridMd" (change)="onChange($event)" ></btlib-form-row> 

            
          </div>



          <div>
            <hr/>

            <h2>{{ 'common.provider' | actrans }}</h2>
            <btlib-form-row [controls]="[ controls.provider,  controls.providerPercent, controls.providerAmountDouble ]" [form]="productForm"  [grid]="gridMd" (change)="onChange($event)" ></btlib-form-row> 
          </div>


          <div>
            <hr/>

            <h2>{{ 'bus.product.cap' | actrans }}</h2>
            <btlib-form-row [controls]="[ controls.unlimited, controls.count ]" [form]="productForm"  [grid]="gridMd" (change)="onChange($event)" ></btlib-form-row> 

          </div>


          <div>
            <hr/>
            <h2>{{ 'bus.area' | actrans }} / {{ 'common.map' | actrans | str | lowercase}}</h2>
  
            <nz-modal [(nzVisible)]="mapVisible" [nzTitle]="'common.map' | actrans | str" nzWidth="90vw"  (nzOnCancel)="onMapSelectPositionCancel()" (nzOnOk)="mapVisible = false;">
              <ng-container *nzModalContent>
                <bt-google-maps #mapMain
                
                  [params]="{ 
                  selectPosition: true, 
                  coords: p.mapCoords
                }" (mapSelectPosition)="onMapSelectPosition($event)" ></bt-google-maps>
            
      
              </ng-container>
            </nz-modal>
  
            <div nz-row [nzGutter]="gridMd.gutter" nzAlign="middle">
              <div nz-col [nzXs]="gridMd.xs" [nzSm]="gridMd.sm" [nzMd]="gridMd.md" [nzLg]="gridMd.lg" [nzXl]="gridMd.xl" [nzXXl]="gridMd.xxl">
                <btlib-form-row [controls]="[ controls.area ]" [form]="productForm"  [grid]="gridXl" (change)="onChange($event)" ></btlib-form-row> 
              </div>
              <div nz-col [nzXs]="gridMd.xs" [nzSm]="gridMd.sm" [nzMd]="gridMd.md" [nzLg]="gridMd.lg" [nzXl]="gridMd.xl" [nzXXl]="gridMd.xxl">
                
            
                
                <nz-form-item>
                  <nz-form-label>{{ 'common.map' | actrans }} 
                    <span *ngIf="productForm.controls.latitude.value">
                      &nbsp; | {{ productForm.controls.latitude.value | str | slice:0:10 }}, {{ productForm.controls.longitude.value | str | slice:0:10 }}
                    </span>
                  </nz-form-label>
                  <nz-form-control>
                    <button nz-button (click)="openMap($event, 'product')" >
                      
                      {{ 'app.cbapi5.page.admin.product.placeOnMap' | actrans }}
                    </button>
         
                  </nz-form-control>
                </nz-form-item>
  
              </div>
            </div>


            <btlib-form-row [controls]="[ controls.address1, controls.zipCode, controls.city ]" [form]="productForm"  [grid]="gridMd" (change)="onChange($event)" ></btlib-form-row> 
  
      
            <div *ngIf="apps.bas.ds.config.appConfig.company.upCModSkattUtleieFraFormidlingsselskap">
              <btlib-form-row [controls]="[ controls.kommuneNr, controls.gardsNr, controls.bruksNr ]" [form]="productForm"  [grid]="gridMd" (change)="onChange($event)" ></btlib-form-row> 
  
            </div>
  
  
          </div>


          <div id="section-accommodation" *ngIf="isSectionVisible('accommodation')">
            <hr/>
            <h2>{{ 'common.accommodation' | actrans }}</h2>

            <btlib-form-row [controls]="[ controls.cap, controls.xcap ]" [form]="productForm"  [grid]="gridMd" (change)="onChange($event)" ></btlib-form-row> 


          </div>


          
          <div id="section-nameLeadBody" *ngIf="isSectionVisible('nameLeadBody')">
            <hr/>
            <h2>{{ 'app.cbapi5.page.admin.product.nameLeadBody' | actrans }}</h2>

            
            <btlib-form-row [controls]="[ controls.messages.childMap.g_name, controls.messages.childMap.g_ingress ]" [form]="productForm"  [grid]="gridLg" (change)="onChange($event)" ></btlib-form-row> 
            <btlib-form-row [controls]="[ controls.messages.childMap.g_description ]" [form]="productForm"  [grid]="gridXl" (change)="onChange($event)" ></btlib-form-row> 

          </div>



          <div id="section-guests" *ngIf="isSectionVisible('guests')" >
            <hr/>
            <h2>{{ 'app.lib.common.guests' | actrans }}</h2>
            <btlib-form-row [controls]="[ controls.guestProduct ]" [form]="productForm"  [grid]="gridMd" (change)="onChange($event)" ></btlib-form-row> 

            <div *ngIf="productForm.controls.guestProduct.value">
    
              
              <btlib-form-row [controls]="[ controls.minGuests, controls.maxGuests, controls.defaultGuests ]" [form]="productForm"  [grid]="gridSm" (change)="onChange($event)" ></btlib-form-row> 

          
              <div nz-row [nzGutter]="gridMd.gutter">
                <div  *ngFor="let gc of [0,1,2,3,4]" nz-col [nzXs]="24" [nzSm]="12" [nzMd]="8" [nzLg]="6" [nzXl]="4" [nzXXl]="4">
                  <div *ngIf="gc == 0 || controls.gcs.childMap['gc_' + (gc-1) + '_enabled'].value">

                  
                    <bt-dynamic-form-control
                    
                      [control]="controls.gcs.childMap['gc_' + gc + '_enabled']" 
                      [form]="controls.gcs.fg || productForm" 
                      (change)="onChange($event)" >
                    </bt-dynamic-form-control>

                    <div  *ngIf="controls.gcs.childMap['gc_' + gc + '_enabled'].value">
                      <div>
                        {{ 'common.name' | actrans }}: <strong>{{ p.gcs['gc_' + gc + '_mkDefault'] }}</strong>
                      </div>

                      <bt-message-field 
                      
                        [control]="controls.gcs.childMap['gc_' + gc + '_message']" 
                        [form]="controls.gcs.childMap['gc_' + gc + '_message'].fg || productForm" 
                        (change)="onChange($event)" >
                      </bt-message-field>
                      
                      <bt-dynamic-form-control 
                        [control]="controls.gcs.childMap['gc_' + gc + '_maxGc']" 
                        [form]="controls.gcs.fg || productForm" 
                        (change)="onChange($event)" >
                      </bt-dynamic-form-control>
                    </div>
                  </div>


                </div>
           
  
              </div>
        
            </div>
          </div>

         

    


          
          <div id="section-actEvent" *ngIf="isSectionVisible('actEvent')">
            <hr/>
            <h2>{{ 'app.cbapi5.page.admin.product.actEvent' | actrans }}</h2>

            <btlib-form-row [controls]="[ 
              controls.eventTicket, 
              controls.reportActivityInclude , 
              controls.venue , 
             
              controls.activityReportDays , 
              controls.activityReportTime 
              ]" [form]="productForm"  [grid]="gridMd" (change)="onChange($event)" ></btlib-form-row> 

            
          </div>

       


          <div id="section-advanced" *ngIf="isSectionVisible('advanced')">
            <hr/>

            <h2>{{ 'common.advanced' | actrans }}</h2>
            <btlib-form-row [controls]="[ 
              controls.minDays, 
              controls.defaultDays, 
              controls.minDaysToArrival, 
              controls.orderTimeThreshold 
            ]" [form]="productForm"  [grid]="gridMd" (change)="onChange($event)" ></btlib-form-row> 

            <btlib-form-row [controls]="[ 
              controls.showTime, 
              controls.showEndTime, 

              controls.singleDateProduct,

              controls.mapProduct, 
              controls.providerConfirmationRequired,
              controls.availabilityInfo,
              controls.ppgAvailabilityInfoProviderMail,
              controls.userAddressRequired,
              controls.showCampaignCodeField,
              controls.showInRelatedProductsList, 
              controls.orderable, 
              controls.channexEnabled,
              controls.templateProduct, 
              controls.parentCompany,
              controls.externalOrderSystemUrl
       
            ]" [form]="productForm"  [grid]="gridMd" (change)="onChange($event)" ></btlib-form-row> 
           
            <btlib-form-row [controls]="[ controls.guestCountIsQuantity ]" [form]="productForm"  [grid]="gridMd" (change)="onChange($event)" ></btlib-form-row> 

            <btlib-form-row [controls]="[ controls.jsonDataString ]" [form]="productForm"  [grid]="gridXl" (change)="onChange($event)" ></btlib-form-row> 


          </div>


          <div id="section-prices" *ngIf="isSectionVisible('prices')">
            <hr/>
            <h2>{{ 'bus.product.prices' | actrans }} </h2>

            <div nz-row [nzGutter]="[8, 16]" nzAlign="top" cdkDropList  (cdkDropListDropped)="cdkDropListDroppedItem($event, 'price')" >
              <div cdkDrag *ngFor="let itemId of p.prices; let idx = index" nz-col [nzXs]="12" [nzSm]="8" [nzMd]="8" [nzLg]="6" [nzXl]="6" [nzXXl]="4" >
            
                <nz-card [nzActions]=" [itemActionDelete, itemActionCopy, itemActionEdit]" > <!-- itemActionMove -->
                  
                  <div>{{ p.priceMap[itemId].name }}</div>
                  <div>{{ p.priceMap[itemId].mkType }} | {{ p.priceMap[itemId].mkGroup }} </div>
                  <div>{{ p.priceMap[itemId].startDateAsDate }} - {{ p.priceMap[itemId].endDateAsDate }}</div>
                </nz-card>
    
                <ng-template #itemActionDelete>
                  <i nz-icon nzType="delete"
                  nz-popconfirm
                  [nzPopconfirmTitle]="'app.lib.common.popconfirm.confirm' | actrans | str"  
                  [title]="'common.delete' | actrans"
                  (nzOnConfirm)="deleteItem(idx, 'price')" [nzTheme]="'twotone'" [nzTwotoneColor]="'#eb2f96'"></i>
                </ng-template>
                <ng-template #itemActionCopy>
                  <i nz-icon nzType="copy"
                  nz-popconfirm
                  [nzPopconfirmTitle]="'app.lib.common.popconfirm.confirm' | actrans | str"  
                  [title]="'common.copy' | actrans"
                  (nzOnConfirm)="newItem(undefined, 'price', '', undefined, itemId)"></i>
                </ng-template>
                <ng-template #itemActionEdit>
                  <i nz-icon nzType="edit" (click)="editItem(itemId, 'price')" ></i>
                </ng-template>
              </div>
          
    

              <div nz-col [nzXs]="12" [nzSm]="8" [nzMd]="8" [nzLg]="6" [nzXl]="6" [nzXXl]="4" style="margin:auto;">
        
                <div class="item-new-wrapper">
                  <div class="item-new-icon"><i nz-icon nzType="plus-circle"></i></div>
                  <button nz-button [disabled]="!p.id" nzType="dashed" nzSize="large" class="item-new" (click)="newItem($event, 'price')">
                    {{ 'common.add' | actrans }}
                  </button>
                </div>
  
              </div>
            </div>
            <!--  [nzComponentParams]="{ item: edit.price.obj }" ( -->
            <nz-modal [nzVisible]="!!edit.price.obj" nzTitle="{{ 'common.price' | actrans | str }}" nzWidth="95vw" [nzContent]="modalContentItem" (nzOnCancel)="edit.price.obj = null" (nzOnOk)="savePrice()">
              <ng-template #modalContentItem let-params>

  
                <form *ngIf="edit.price.obj" nz-form id="priceForm" [formGroup]="edit.price.form" class="item-form mt-d" nzLayout="vertical" [nzAutoTips]="apps.bas.fs.autoTips">

                  <btlib-form-row [controls]="[ 
                    edit.price.controls.time,
                    edit.price.controls.updated,
  
                  ]" [form]="edit.price.form"  [grid]="gridMd"  (change)="onChange($event)" ></btlib-form-row>  
                 <btlib-form-row [controls]="[ edit.price.controls.name, edit.price.controls.order, edit.price.controls.enabled  ]" [form]="edit.price.form"  [grid]="gridMd"  (change)="onChange($event)" ></btlib-form-row> 
                 <btlib-form-row [controls]="[ 
                   edit.price.controls.type, 
                   edit.price.controls.group, 
                   edit.price.controls.channexPriceConfigId 
                   ]" [form]="edit.price.form"  [grid]="gridMd"  (change)="onChange($event)" ></btlib-form-row>  
                 <btlib-form-row [controls]="[ edit.price.controls.orderPeriodAsString, edit.price.controls.campaignCode ]" [form]="edit.price.form"  [grid]="gridMd"  (change)="onChange($event)" ></btlib-form-row>  
                 <btlib-form-row [controls]="[ edit.price.controls.providerPercent, edit.price.controls.providerAmountDouble, edit.price.controls.commissionAmountDouble ]" [form]="edit.price.form"  [grid]="gridMd"  (change)="onChange($event)" ></btlib-form-row>  
       
                 <div *ngIf="edit.price.obj.typeEnum?.isSimple">
                  <nz-alert nzType="info" nzMessage="{{ 'web.common.price.simpleInfo' | actrans | str}}" nzShowIcon></nz-alert>
                  <btlib-form-row [controls]="[ edit.price.controls.rule, edit.price.controls.baseAmountDouble ]" [form]="edit.price.form"  [grid]="gridMd"  (change)="onChange($event)" ></btlib-form-row>  
                
                 </div>
                 <div *ngIf="edit.price.obj.typeEnum?.isDailyRates">
                  <nz-alert nzType="info" nzMessage="{{ 'web.common.price.dailyRatesInfo' | actrans | str}}" nzShowIcon></nz-alert>
                  <div class="m-d"></div>
                  
                  
                  <!-- ${nit:getUpw(company, 'C_PRODUCT_FEATURE_AMOUNT_GROUPS').booleanValue} &&  !templateProduct  -->
                  <btlib-form-row [controls]="[ edit.price.controls.parent ]" [form]="edit.price.form"  [grid]="gridMd" (change)="onChange($event)"  ></btlib-form-row>  

       
                  <div *ngIf="!edit.price.obj.parentObj" >
                    <btlib-form-row [controls]="[ edit.price.controls.useAmountGroups ]" [form]="edit.price.form"  [grid]="gridMd"  (change)="onChange($event)" ></btlib-form-row>  
                  
                  </div>

                  <div *ngIf="edit.price.form.controls.useAmountGroups.value">
                    
                    <h2>{{ 'bus.price.amountGroups' | actrans }}</h2>
                    <div>
                      <btlib-form-row [controls]="edit.price.controls.ags.children" [form]="edit.price.form.controls.ags"  [grid]="gridXs"  (change)="onChange($event)" ></btlib-form-row>  
                    </div>

                    <div>
                      <div class="text-center">
                        <button nz-button nzType="default" nzDanger nzSize="small" class="mr-1-off" (click)="addAmountGroup()">
                          {{ 'common.add' | actrans }}
                        </button>
                      </div>
                    </div>

                  </div>
             

                  <div *ngIf="edit.price.obj.parentObj" >

                    <h3>{{ 'web.common.product.price.templatePeriodsAndPrices' | actrans }}</h3>
                    <nz-table 
                      #nzTableDailyRatesParent
                      [nzData]="edit.price.obj.parentObj.dailyRatesItems" 
                      nzTableLayout="fixed" 
                      [nzShowPagination]="false" 
                      [nzPageSize]="100"
                      nzSize="small"
                      class="nz-table-xs"
                      >
                      <thead>
                        <tr>
                          <th nzWidth="30px"></th>
                          <th *ngIf="apps.bas.ui.r.gtesm" nzWidth="30px">#</th>
                          <th>{{ 'common.name' | actrans }}</th>

                          <th *ngIf="apps.bas.ui.r.gtesm"  nzWidth="180px">Periode</th>
                          <th *ngIf="apps.bas.ui.r.gtelg">{{ 'bus.priceItem.minMaxDays' | actrans }}</th>
                          <th *ngIf="apps.bas.ui.r.gtemd">{{ 'common.weekdays' | actrans }}</th>

                          <!-- <th *ngIf="apps.bas.ui.r.gtexl">{{ 'bus.bookingItem.guestCategory' | actrans }}</th> -->
                          <th *ngIf="apps.bas.ui.r.gtexl">{{ 'bus.priceItem.minMaxGuests' | actrans }}</th>
              
                          <th *ngIf="apps.bas.ui.r.gtelg">{{ 'bus.priceRule' | actrans }}</th>
                          <th nzAlign="right">{{ 'bus.price.amountGroup' | actrans }}</th>
                          <th>{{ 'common.override' | actrans }}</th>

                          <!-- <th *ngIf="apps.bas.ui.r.ltesm" nzAlign="right"></th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container  *ngFor="let data of nzTableDailyRatesParent.data; let idx = index">
                          <tr >
                            <td>
                              <i nz-icon class="cursor-pointer" [nzType]="edit.price.parentExpand == data.id ? 'minus-square' : 'plus-square'" (click)="setParentExpanded(data)" ></i>
                            </td>
                            <td *ngIf="apps.bas.ui.r.gtesm">{{ data.sortOrder }}</td>
                            <td>{{ data.name }}</td>
                            <td *ngIf="apps.bas.ui.r.gtesm">{{ data.datePeriodDisplay }} </td>
                            <td *ngIf="apps.bas.ui.r.gtelg">{{ data.daysRangeDisplay }}</td>
                            <td *ngIf="apps.bas.ui.r.gtemd">
                              <nz-tag *ngFor="let wd of data.weekdaysList" class="weekday-tag" [nzColor]="data.isWeekdaysAll || wd.enabled ? 'green' : 'red'">{{ wd.mkWeekday1 }}</nz-tag>
                            </td>
                    
                            <!-- <td *ngIf="apps.bas.ui.r.gtexl">{{ data.mkGuestCategory }}</td> -->
                            <td *ngIf="apps.bas.ui.r.gtexl">{{ data.totaltGuestsRangeDisplay }}</td>

                            <td *ngIf="apps.bas.ui.r.gtelg">{{ data.priceRuleLabel }}</td>

                            <td nzAlign="right" [style.color]="data.agColor" *ngIf="edit.price.obj.parentObj.useAmountGroups">
                              {{ data.mkAmountGroup }}: 
                              {{ (edit.price.obj.amountGroupsList && edit.price.obj.amountGroupsList.length > data.amountGroup ? edit.price.obj.amountGroupsList[data.amountGroup] : edit.price.obj.parentObj.amountGroupsList[data.amountGroup]).amountAsDouble | nf}}
                            </td>

                            <td nzAlign="right" *ngIf="!edit.price.obj.parentObj.useAmountGroups">
                              {{  data.amountAsDouble | nf }}
                              <!-- {{ (edit.price.obj.amountOverride && edit.price.obj.amountOverride[data.id] ? edit.price.obj.amountOverride[data.id] / 100.0 : data.amountAsDouble) | nf }} -->
                            </td>

                            <td>
                              <div *ngIf="edit.price.obj.parentObj.useAmountGroups && edit.price.controls.agos.childMap[data.id]">

                                <btlib-form-row [controls]="[ edit.price.controls.agos.childMap[data.id] ]" [form]="edit.price.form.controls.agos"  [grid]="gridXl" (change)="onChange($event)"  ></btlib-form-row>  
                              </div>
                              <div *ngIf="!edit.price.obj.parentObj.useAmountGroups && edit.price.controls.aos?.childMap[data.id]">

                                <btlib-form-row [controls]="[ edit.price.controls.aos.childMap[data.id] ]" [form]="edit.price.form.controls.aos"  [grid]="gridXl" (change)="onChange($event)"  ></btlib-form-row>  
                              </div>
                            </td>

                            <!-- <td *ngIf="apps.bas.ui.r.ltesm" nzAlign="right"></td> -->

                          </tr>
                          <tr  *ngIf="edit.price.parentExpand == data.id" class="tr-extended"> 
                            
                            <td [attr.colspan]="(
                                                        4
                              + (apps.bas.ui.r.ltesm ? 0 : 0)  
                              + (apps.bas.ui.r.gtesm ? 2 : 0) 
                              + (apps.bas.ui.r.gtemd ? 1 : 0) 
                              + (apps.bas.ui.r.gtelg ? 2 : 0) 
                              + (apps.bas.ui.r.gtexl ? 2 : 0)
                              )">
                            <div class="tr-extended-div">

                              <btlib-content-row [cols]="this.edit.price.parentExpandData" [grid]="apps.bas.ui.gridMd"></btlib-content-row>
      
                              <!--

                              -->
                            </div>
                            </td>

                          </tr>
                        </ng-container>
                      </tbody>
                    </nz-table>

                    <p class="text">
                      {{ 'web.common.product.price.templateOverrideInfo' | actrans }}
                    </p>

                  </div>

             

                  <div class="mt-d">
              
                    <nz-table 
                      #nzTableDailyRates
                      [nzData]="edit.price.obj.dailyRatesItems" 
                      nzTableLayout="fixed" 
                      [nzShowPagination]="false" 
                      [nzPageSize]="100"
                      nzSize="small"
                      class="nz-table-xs"
                      >
                      <thead>
                        <tr>
                          <th nzWidth="30px"></th>
                          <th *ngIf="apps.bas.ui.r.gtesm" nzWidth="30px">#</th>
                          <th>{{ 'common.name' | actrans }}</th>

                          <th *ngIf="apps.bas.ui.r.gtesm"  nzWidth="180px">Periode</th>
                          <th *ngIf="apps.bas.ui.r.gtelg">{{ 'bus.priceItem.minMaxDays' | actrans }}</th>
                          <th *ngIf="apps.bas.ui.r.gtemd">{{ 'common.weekdays' | actrans }}</th>

                          <!-- <th *ngIf="apps.bas.ui.r.gtexl">{{ 'bus.bookingItem.guestCategory' | actrans }}</th> -->
                          <th *ngIf="apps.bas.ui.r.gtexl">{{ 'bus.priceItem.minMaxGuests' | actrans }}</th>
              
                          <th *ngIf="apps.bas.ui.r.gtelg">{{ 'common.restrictions' | actrans }}</th>
                          <th *ngIf="apps.bas.ui.r.gtelg">{{ 'bus.priceRule' | actrans }}</th>
                          <th nzAlign="right">{{ 'common.amount' | actrans }}</th>

                          <th *ngIf="apps.bas.ui.r.ltesm" nzAlign="right"></th>
                          <th nzWidth="30px"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container  *ngFor="let data of nzTableDailyRates.data; let idx = index">
                          <tr *ngIf="!data.deleted">
                            <td>
                              <i nz-icon class="cursor-pointer" [nzType]="edit.dailyRatesItem.obj?.id == data.id ? 'minus-square' : 'plus-square'" (click)="editPriceItem(idx, 'dailyRatesItem', edit.price.obj, data.id)" ></i>
                            </td>
                            <td *ngIf="apps.bas.ui.r.gtesm">{{ data.sortOrder }}</td>
                            <td>{{ data.name }}</td>
                            <td *ngIf="apps.bas.ui.r.gtesm">{{ data.datePeriodDisplay }}</td>
                            <td *ngIf="apps.bas.ui.r.gtelg">{{ data.daysRangeDisplay }}</td>
                            <td *ngIf="apps.bas.ui.r.gtemd">
                              <nz-tag *ngFor="let wd of data.weekdaysList" class="weekday-tag" [nzColor]="data.isWeekdaysAll || wd.enabled ? 'green' : 'red'">{{ wd.mkWeekday1 }}</nz-tag>
                            </td>
                    
                            <!-- <td *ngIf="apps.bas.ui.r.gtexl">{{ data.mkGuestCategory }}</td> -->
                            <td *ngIf="apps.bas.ui.r.gtexl">{{ data.totaltGuestsRangeDisplay }}</td>

                            <td *ngIf="apps.bas.ui.r.gtelg">
                              <nz-tag 
                                [nzColor]="'green'"
                                *ngFor="let rest of data.restrictions"
                                class="restrictions-tag" [title]="rest.name"
                                >
                                {{ rest.abbr }}
                            </nz-tag>
                           
                            </td>
                            <td *ngIf="apps.bas.ui.r.gtelg">{{ data.priceRuleLabel }}</td>


            
                            <!-- <td *ngIf="!edit.price.form.controls.useAmountGroups.value" nzAlign="right">{{ data.amountAsDouble | nf }}</td>
                            <td *ngIf="edit.price.form.controls.useAmountGroups.value" nzAlign="right">{{ data.findamountAsDouble | nf }}</td> -->
                            <td nzAlign="right">
                              <span *ngIf="data.mkAmountGroup">{{ data.mkAmountGroup }}: </span>
                              {{ data.findAmountAsDouble | nf }}
                              <!-- | {{ data.deleted ? 'deleted' : 'not-d' }} -->
                            </td>

                            <td *ngIf="apps.bas.ui.r.ltesm" nzAlign="right"></td><!-- TODO -->
                            <td >
                              <i nz-icon class="cursor-pointer" [nzType]="'copy'" (click)="copyPriceItem(idx, 'dailyRatesItem', edit.price.obj, data)" ></i>
                            </td>

                          </tr>
                          <tr  *ngIf="edit.dailyRatesItem.obj?.id == data.id" class="tr-extended"> 
                           
                            <td [attr.colspan]="(
                                                       3 
                              + (apps.bas.ui.r.ltesm ? 1 : 0)  
                              + (apps.bas.ui.r.gtesm ? 2 : 0) 
                              + (apps.bas.ui.r.gtemd ? 1 : 0) 
                              + (apps.bas.ui.r.gtelg ? 3 : 0) 
                              + (apps.bas.ui.r.gtexl ? 2 : 0)
                              )">
                            <div *ngIf="edit.dailyRatesItem.obj" class="tr-extended-div">
                           
                              <form nz-form [formGroup]="edit.dailyRatesItem.form" class="item-form mt-d" nzLayout="vertical" [nzAutoTips]="apps.bas.fs.autoTips">

                                <btlib-form-row [controls]="[ edit.dailyRatesItem.controls.sortOrder, edit.dailyRatesItem.controls.name ]" [form]="edit.dailyRatesItem.form"  [grid]="gridMd"  (change)="onChange($event)" ></btlib-form-row>  
                                <btlib-form-row [controls]="[ edit.dailyRatesItem.controls.datePeriodAsRange, edit.dailyRatesItem.controls.daysAsRange ]" [form]="edit.dailyRatesItem.form"  [grid]="gridMd"  (change)="onChange($event)" ></btlib-form-row>  
                                <btlib-form-row [controls]="[ edit.dailyRatesItem.controls.weekdaysMap ]" [form]="edit.dailyRatesItem.form"  [grid]="gridXl" (change)="onChange($event)"  ></btlib-form-row>  
                                
                     
                                 <btlib-form-row [controls]="[ 
                                  edit.dailyRatesItem.controls.guestCategory, 
                                  edit.dailyRatesItem.controls.totalGuestsAsRange
                                  ]" [form]="edit.dailyRatesItem.form"  [grid]="gridSm" (change)="onChange($event)"  ></btlib-form-row>  
                                
                                <btlib-form-row [controls]="[ 
                                  edit.dailyRatesItem.controls.closedToArrival, 
                                  edit.dailyRatesItem.controls.closedToDeparture, 
                                  edit.dailyRatesItem.controls.exactPeriod
                                  ]" [form]="edit.dailyRatesItem.form"  [grid]="gridMd" (change)="onChange($event)"  ></btlib-form-row>  
                                
                     
                                <btlib-form-row [controls]="[ 
                                  edit.dailyRatesItem.controls.rule, 
                                  edit.dailyRatesItem.controls.amountAsDouble,
                                  edit.dailyRatesItem.controls.amountGroup
                                  ]" [form]="edit.dailyRatesItem.form"  [grid]="gridMd" (change)="onChange($event)"  ></btlib-form-row>  
                                

                                
                                <div nz-row [nzGutter]="0" nzAlign="middle">
                                  <div nz-col [nzXs]="12">
                                    <button 
                                      nz-popconfirm
                                      [nzPopconfirmTitle]="'app.lib.common.popconfirm.delete' | actrans | str"
                                      nz-button nzType="default" nzDanger nzSize="small" class="" (click)="$event.preventDefault()" (nzOnConfirm)="deletePriceItem(idx, 'dailyRatesItem', edit.price.obj, data)">
                                      {{ 'common.delete' | actrans }}
                                    </button>
                                  </div>
                                  <div nz-col [nzXs]="12">
                                    <div class="text-right">
                                      <button nz-button nzType="default" nzSize="small" (click)="saveItem('dailyRatesItem', '', edit.price.obj, $event)">
                                        {{ 'app.lib.common.save' | actrans }}
                                      </button>
                                    </div>
                              
                                  </div>
                                </div>
                            
                               

                                

                              </form>
                            </div>
                            </td>

                          </tr>
                        </ng-container>
                      </tbody>
                    </nz-table>

                    <div *ngIf="!edit.dailyRatesItem.obj" class="text-right">
                      <button nz-button nzType="default" nzSize="small" (click)="newItem($event, 'dailyRatesItem', '', edit.price.obj)">
                        {{ 'common.add' | actrans }}
                      </button>
                    </div>

                  </div>

                  

                 </div>
                 <div *ngIf="edit.price.obj.typeEnum?.isManual">
                  <nz-alert nzType="info" nzMessage="{{ 'web.common.price.manualInfo' | actrans | str}}" nzShowIcon></nz-alert>
                  <div class="m-d"></div>

                  <div>
                    <nz-table 
                      #nzTableManual
                      [nzData]="edit.price.obj.items" 
                      nzTableLayout="fixed" 
                      [nzShowPagination]="false" 
                      [nzPageSize]="100"
                      nzSize="small"
                      class="nz-table-xs"
                      >
                      <thead>
                        <tr>
                          <th nzWidth="30px"></th>
                          <th nzWidth="30px" class="text-center">#</th>
                          <th>{{ 'common.name' | actrans }}</th>

                          <th *ngIf="apps.bas.ui.r.gtesm"  nzWidth="180px">Periode</th>
                          <th *ngIf="apps.bas.ui.r.gtelg">{{ 'bus.priceItem.minMaxDays' | actrans }}</th>
                          <th *ngIf="apps.bas.ui.r.gtelg" nzWidth="115px">{{ 'common.weekdays' | actrans }}</th>

                          <th *ngIf="apps.bas.ui.r.gtexxl" nzWidth="80px">{{ 'common.time' | actrans }}</th>
                          <th *ngIf="apps.bas.ui.r.gtexxl" nzWidth="80px">{{ 'common.duration' | actrans }}</th>
                
                          <th *ngIf="apps.bas.ui.r.gtelg" nzWidth="100px">{{ 'bus.bookingItem.guestCategory' | actrans }}</th>
                          <th *ngIf="apps.bas.ui.r.gtexl">{{ 'bus.priceItem.minMaxGuests' | actrans }}</th>
              
                          <th *ngIf="apps.bas.ui.r.gtemd">{{ 'bus.priceRule' | actrans }}</th>
                          <th nzAlign="right">{{ 'common.amount' | actrans }}</th>

                          <!-- <th *ngIf="apps.bas.ui.r.ltesm" nzAlign="right"></th>TODO -->
                          <th nzWidth="30px"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container  *ngFor="let data of nzTableManual.data; let idx = index">
                          <tr *ngIf="!data.deleted" >
                            <td>
                              <i nz-icon class="cursor-pointer" [nzType]="edit.priceItem.obj?.id == data.id ? 'minus-square' : 'plus-square'" (click)="editPriceItem(idx, 'priceItem', edit.price.obj, data.id)" ></i>
                            </td>
                            <td class="text-center">{{ data.order }}</td>
                            <td>{{ data.name }}</td>
                            <td *ngIf="apps.bas.ui.r.gtesm">{{ data.datePeriodDisplay }}</td>
                            <td *ngIf="apps.bas.ui.r.gtelg">{{ data.daysRangeDisplay }}</td>
                            <td *ngIf="apps.bas.ui.r.gtelg">
                              <nz-tag *ngFor="let wd of data.weekdaysList" class="weekday-tag" [nzColor]="data.isWeekdaysAll || wd.enabled ? 'green' : 'red'">{{ wd.mkWeekday1 }}</nz-tag>
                            </td>
                            
                            <td *ngIf="apps.bas.ui.r.gtexxl">{{ data.timeRangeDisplay }}</td>
                            <td *ngIf="apps.bas.ui.r.gtexxl">{{ data.timeDurationRangeDisplay }}</td>

                            <td *ngIf="apps.bas.ui.r.gtelg">{{ data.mkGuestCategory }}</td>
                            <td *ngIf="apps.bas.ui.r.gtexl">{{ data.totaltGuestsRangeDisplay }}</td>

                            <td *ngIf="apps.bas.ui.r.gtemd">{{ data.rule }}</td>


                          
                            <td nzAlign="right">{{ data.amountDouble | nf }}</td>

                            <!-- <td *ngIf="apps.bas.ui.r.ltesm" nzAlign="right"></td>TODO -->

                            <td >
                              <i nz-icon class="cursor-pointer" [nzType]="'copy'" (click)="copyPriceItem(idx, 'priceItem', edit.price.obj, data)" ></i>
                            </td>

                          </tr>
                          <tr  *ngIf="edit.priceItem.obj?.id == data.id" class="tr-extended"> 
                           
                            <td [attr.colspan]="(
                                                       5 
                              + (apps.bas.ui.r.ltesm ? 0 : 0)  
                              + (apps.bas.ui.r.gtesm ? 1 : 0) 
                              + (apps.bas.ui.r.gtemd ? 1 : 0) 
                              + (apps.bas.ui.r.gtelg ? 3 : 0) 
                              + (apps.bas.ui.r.gtexl ? 1 : 0)
                              + (apps.bas.ui.r.gtexxl ? 2 : 0)
                              )">
                            <div *ngIf="edit.priceItem.obj" class="tr-extended-div">
                           
                              <form nz-form [formGroup]="edit.priceItem.form" class="item-form mt-d" nzLayout="vertical" [nzAutoTips]="apps.bas.fs.autoTips">

                                <btlib-form-row [controls]="[ edit.priceItem.controls.order, edit.priceItem.controls.name ]" [form]="edit.priceItem.form"  [grid]="gridMd" (change)="onChange($event)"  ></btlib-form-row>  
                                <btlib-form-row [controls]="[ edit.priceItem.controls.datePeriodAsRange, edit.priceItem.controls.daysAsRange ]" [form]="edit.priceItem.form"  [grid]="gridMd" (change)="onChange($event)"  ></btlib-form-row>  
                                <btlib-form-row [controls]="[ edit.priceItem.controls.weekdaysMap ]" [form]="edit.priceItem.form"  [grid]="gridXl" (change)="onChange($event)"  ></btlib-form-row>  
                                
                                <btlib-form-row [controls]="[ 
                                  edit.priceItem.controls.startTimeAsString, 
                                  edit.priceItem.controls.endTimeAsString, 
                                  edit.priceItem.controls.timeDurationAsRange
                                 ]" [form]="edit.priceItem.form"  [grid]="gridSm" (change)="onChange($event)"  ></btlib-form-row>  
                             
                                 <btlib-form-row [controls]="[ 
                                  edit.priceItem.controls.guestCategory, 
                                  edit.priceItem.controls.totalGuestsAsRange
                                  ]" [form]="edit.priceItem.form"  [grid]="gridSm" (change)="onChange($event)"  ></btlib-form-row>  
                                
                               <btlib-form-row [controls]="[ 
                                  edit.priceItem.controls.guestsGcAsRange0, 
                                  edit.priceItem.controls.guestsGcAsRange1,
                                  edit.priceItem.controls.guestsGcAsRange2,
                                  edit.priceItem.controls.guestsGcAsRange3,
                                  edit.priceItem.controls.guestsGcAsRange4,
                                  ]" [form]="edit.priceItem.form"  [grid]="gridSm" (change)="onChange($event)"  ></btlib-form-row>  
                            
                                  
                                <btlib-form-row [controls]="[ 
                                  edit.priceItem.controls.rule, 
                                  edit.priceItem.controls.amountDouble
                                  ]" [form]="edit.priceItem.form"  [grid]="gridSm" (change)="onChange($event)"  ></btlib-form-row>  
                                

                                <!-- <div class="text-right">
                              
                                  <button nz-button nzType="default" nzSize="small" (click)="saveItem('priceItem', '', edit.price.obj, $event)">
                                    {{ 'app.lib.common.save' | actrans }}
                                  </button>
                                </div> -->

                                 <!-- <button nz-button nzType="default" nzDanger nzSize="small" class="mr-1" (click)="edit.priceItem.obj = undefined;">
                                    {{ 'common.cancel' | actrans }}
                                  </button> -->
                                <div nz-row [nzGutter]="0" nzAlign="middle">
                                  <div nz-col [nzXs]="12">
                                    <button 
                                      nz-popconfirm
                                      [nzPopconfirmTitle]="'app.lib.common.popconfirm.delete' | actrans | str"
                                      nz-button nzType="default" nzDanger nzSize="small" class="" (click)="$event.preventDefault()" (nzOnConfirm)="deletePriceItem(idx, 'priceItem', edit.price.obj, data)">
                                      {{ 'common.delete' | actrans }}
                                    </button>
                                  </div>
                                  <div nz-col [nzXs]="12">
                                    <div class="text-right">
                                      <button nz-button nzType="default" nzSize="small" (click)="saveItem('priceItem', '', edit.price.obj, $event)">
                                        {{ 'app.lib.common.save' | actrans }}
                                      </button>
                                    </div>
                              
                                  </div>
                                </div>

                                

                              </form>
                            </div>
                            </td>

                          </tr>
                        </ng-container>
                      </tbody>
                    </nz-table>

                    <div *ngIf="!edit.priceItem.obj" class="text-right">
                      <button nz-button nzType="default" nzSize="small" (click)="newItem($event, 'priceItem', '', edit.price.obj)">
                        {{ 'common.add' | actrans }}
                      </button>
                    </div>

                  </div>


                 </div>
                
                
                <!-- <nz-alert nzType="info" nzMessage="{{ 'web.common.price.manualInfo' | actrans | str}}" nzShowIcon></nz-alert> -->
                 
                 <div *ngIf="edit.price.obj.typeEnum?.isRoute">
                  <div class="m-d"></div>
                  

                  <div>
                    <nz-table 
                      #nzTableRoute
                      [nzData]="edit.price.obj.routeItems" 
                      nzTableLayout="fixed" 
                      [nzShowPagination]="false" 
                      [nzPageSize]="100"
                      nzSize="small"
                      class="nz-table-xs"
                      >
                      <thead>
                        <tr>
                          <th nzWidth="30px"></th>
                          <th nzWidth="30px" class="text-center">#</th>
                          <th>{{ 'common.name' | actrans }}</th>

                          <th *ngIf="apps.bas.ui.r.gtesm"  nzWidth="180px">Periode</th>
                          <th *ngIf="apps.bas.ui.r.gtelg" nzWidth="115px">{{ 'common.weekdays' | actrans }}</th>

                          <th *ngIf="apps.bas.ui.r.gtelg" nzWidth="100px">{{ 'bus.bookingItem.guestCategory' | actrans }}</th>
                          <th *ngIf="apps.bas.ui.r.gtexl">{{ 'bus.priceItem.minMaxGuests' | actrans }}</th>
              
                          <th *ngIf="apps.bas.ui.r.gtemd">{{ 'bus.priceRouteItem.routeFrom' | actrans }}</th>
                          <th *ngIf="apps.bas.ui.r.gtemd">{{ 'bus.priceRouteItem.routeTo' | actrans }}</th>

                          <th *ngIf="apps.bas.ui.r.gtemd">{{ 'bus.priceRule' | actrans }}</th>
                          <th nzAlign="right">{{ 'common.amount' | actrans }}</th>

                          <th nzWidth="30px"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container  *ngFor="let data of nzTableRoute.data; let idx = index">
                          <tr *ngIf="!data.deleted" >
                            <td>
                              <i nz-icon class="cursor-pointer" [nzType]="edit.priceRouteItem.obj?.id == data.id ? 'minus-square' : 'plus-square'" (click)="editPriceItem(idx, 'priceRouteItem', edit.price.obj, data.id)" ></i>
                            </td>
                            <td class="text-center">{{ data.sortOrder }}</td>
                            <td>{{ data.name }}</td>
                            <td *ngIf="apps.bas.ui.r.gtesm">{{ data.datePeriodDisplay }}</td>
                            <td *ngIf="apps.bas.ui.r.gtelg">
                              <nz-tag *ngFor="let wd of data.weekdaysList" class="weekday-tag" [nzColor]="data.isWeekdaysAll || wd.enabled ? 'green' : 'red'">{{ wd.mkWeekday1 }}</nz-tag>
                            </td>
                            
                      
                            <td *ngIf="apps.bas.ui.r.gtelg">{{ data.mkGuestCategory }}</td>
                            <td *ngIf="apps.bas.ui.r.gtexl">{{ data.totaltGuestsRangeDisplay }}</td>

                            <td *ngIf="apps.bas.ui.r.gtemd">{{ data.routeFromLabel }}</td>
                            <td *ngIf="apps.bas.ui.r.gtemd">{{ data.routeToLabel }}</td>



                            <td *ngIf="apps.bas.ui.r.gtemd">{{ data.priceRuleLabel }}</td>


                          
                            <td nzAlign="right">{{ data.amountAsDouble | nf }}</td>

                       
                            <td >
                              <i nz-icon class="cursor-pointer" [nzType]="'copy'" (click)="copyPriceItem(idx, 'priceRouteItem', edit.price.obj, data)" ></i>
                            </td>

                          </tr>
                          <tr  *ngIf="edit.priceRouteItem.obj?.id == data.id" class="tr-extended"> 
                           
                            <td [attr.colspan]="(
                                                       5 
                              + (apps.bas.ui.r.ltesm ? 0 : 0)  
                              + (apps.bas.ui.r.gtesm ? 1 : 0) 
                              + (apps.bas.ui.r.gtemd ? 3 : 0) 
                              + (apps.bas.ui.r.gtelg ? 2 : 0) 
                              + (apps.bas.ui.r.gtexl ? 1 : 0)
                              + (apps.bas.ui.r.gtexxl ? 0 : 0)
                              )">
                            <div *ngIf="edit.priceRouteItem.obj" class="tr-extended-div">
                           
                              <form nz-form [formGroup]="edit.priceRouteItem.form" class="item-form mt-d" nzLayout="vertical" [nzAutoTips]="apps.bas.fs.autoTips">

                                <btlib-form-row [controls]="[ 
                                  edit.priceRouteItem.controls.sortOrder, 
                                  edit.priceRouteItem.controls.name
                                  ]" [form]="edit.priceRouteItem.form"  [grid]="gridMd" (change)="onChange($event)"  ></btlib-form-row>  
                                <btlib-form-row [controls]="[ edit.priceRouteItem.controls.datePeriodAsRange ]" [form]="edit.priceRouteItem.form"  [grid]="gridMd" (change)="onChange($event)"  ></btlib-form-row>  
                                <btlib-form-row [controls]="[ edit.priceRouteItem.controls.weekdaysMap ]" [form]="edit.priceRouteItem.form"  [grid]="gridXl" (change)="onChange($event)"  ></btlib-form-row>  
                                
               
                                 <btlib-form-row [controls]="[ 
                                  edit.priceRouteItem.controls.guestCategory, 
                                  edit.priceRouteItem.controls.totalGuestsAsRange
                                  ]" [form]="edit.priceRouteItem.form"  [grid]="gridSm" (change)="onChange($event)"  ></btlib-form-row>  
                                
             
                                  <btlib-form-row [controls]="[ 
                                  edit.priceRouteItem.controls.routeFrom, 
                                  edit.priceRouteItem.controls.routeTo
                                  ]" [form]="edit.priceRouteItem.form"  [grid]="gridMd" (change)="onChange($event)"  ></btlib-form-row>  
                                
             
                                  <!-- <btlib-form-row [controls]="[ 
                                  edit.priceRouteItem.controls.bothDirections
                                  ]" [form]="edit.priceRouteItem.form"  [grid]="gridMd" (change)="onChange($event)"  ></btlib-form-row>   -->
                                
                                  
                                  
                                <btlib-form-row [controls]="[ 
                                  edit.priceRouteItem.controls.bothDirections,
                                  edit.priceRouteItem.controls.rule, 
                                  edit.priceRouteItem.controls.amountAsDouble
                                  ]" [form]="edit.priceRouteItem.form"  [grid]="gridSm" (change)="onChange($event)"  ></btlib-form-row>  
                                

  
                                <div nz-row [nzGutter]="0" nzAlign="middle">
                                  <div nz-col [nzXs]="12">
                                    <button 
                                      nz-popconfirm
                                      [nzPopconfirmTitle]="'app.lib.common.popconfirm.delete' | actrans | str"
                                      nz-button nzType="default" nzDanger nzSize="small" class="" (click)="$event.preventDefault()" (nzOnConfirm)="deletePriceItem(idx, 'priceRouteItem', edit.price.obj, data)">
                                      {{ 'common.delete' | actrans }}
                                    </button>
                                  </div>
                                  <div nz-col [nzXs]="12">
                                    <div class="text-right">
                                      <button nz-button nzType="default" nzSize="small" (click)="saveItem('priceRouteItem', '', edit.price.obj, $event)">
                                        {{ 'app.lib.common.save' | actrans }}
                                      </button>
                                    </div>
                              
                                  </div>
                                </div>

                                

                              </form>
                            </div>
                            </td>

                          </tr>
                        </ng-container>
                      </tbody>
                    </nz-table>

                    <div *ngIf="!edit.priceRouteItem.obj" class="text-right">
                      <button nz-button nzType="default" nzSize="small" (click)="newItem($event, 'priceRouteItem', '', edit.price.obj)">
                        {{ 'common.add' | actrans }}
                      </button>
                    </div>

                  </div>


                 </div>

                 <div *ngIf="edit.price.obj.typeEnum?.isManualPeriod">
                  <nz-alert nzType="info" nzMessage="{{ 'web.common.price.manualPeriodInfo' | actrans | str}}" nzShowIcon></nz-alert>
                  <btlib-form-row [controls]="[ edit.price.controls.startDay ]" [form]="edit.price.form"  [grid]="gridMd" (change)="onChange($event)" class="mt-d" ></btlib-form-row>  

                  <div>
                    <nz-table 
                      #nzTableManualPeriod
                      [nzData]="edit.price.obj.manPeriodItems" 
                      nzTableLayout="fixed" 
                      [nzShowPagination]="false" 
                      [nzPageSize]="100"
                      nzSize="small"
                      class="nz-table-xs"
                      >
                      <thead>
                        <tr>
                          <th nzWidth="30px"></th>
                          <th nzWidth="30px">#</th>
                          <th>{{ 'common.name' | actrans }}</th>

                          <th *ngIf="apps.bas.ui.r.gtesm"  nzWidth="180px">{{ 'common.period' | actrans }}</th>
                          <th *ngIf="apps.bas.ui.r.gtelg">{{ 'common.days' | actrans }}</th>


                          <th *ngIf="apps.bas.ui.r.gtemd" nzAlign="right">{{ 'bus.priceManPeriodItem.period' | actrans }}</th>
                          <th *ngIf="apps.bas.ui.r.gtemd" nzAlign="right">{{ 'web.iframe.search.priceList.header.day' | actrans }}</th>
                          <th *ngIf="apps.bas.ui.r.gtelg">{{ 'common.weekdays' | actrans }} ({{ 'web.iframe.search.priceList.header.day' | actrans }})</th>
                          <th *ngIf="apps.bas.ui.r.gtemd" nzAlign="right">{{ 'web.iframe.search.priceList.header.week' | actrans }}</th>
                          <th *ngIf="apps.bas.ui.r.gtelg" nzAlign="right">{{ 'web.iframe.search.priceList.header.friToSun' | actrans }}</th>
                          <th *ngIf="apps.bas.ui.r.gtelg" nzAlign="right">{{ 'web.iframe.search.priceList.header.thuToSun' | actrans }}</th>
                          <th *ngIf="apps.bas.ui.r.gtelg" nzAlign="right">{{ 'web.iframe.search.priceList.header.monToFri' | actrans }}</th>
                          
                          
                          <th *ngIf="apps.bas.ui.r.ltesm" nzAlign="right">{{ '' | actrans }}</th><!--TODO-->

                          <th nzWidth="30px"></th>
                         
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container  *ngFor="let data of nzTableManualPeriod.data; let idx = index">
                          <tr *ngIf="!data.deleted" >
                            <td>
                              <i nz-icon class="cursor-pointer" [nzType]="edit.manPeriodItem.obj?.id == data.id ? 'minus-square' : 'plus-square'" (click)="editPriceItem(idx, 'manPeriodItem', edit.price.obj, data.id)" ></i>
                            </td>
                            <td>{{ data.order }}</td>
                            <td>{{ data.name }}</td>
                            <td *ngIf="apps.bas.ui.r.gtesm">{{ data.datePeriodDisplay }} </td>
                            <td *ngIf="apps.bas.ui.r.gtelg">{{ data.daysRangeDisplay }}</td>

                            <td *ngIf="apps.bas.ui.r.gtemd" nzAlign="right">{{ data.periodDouble | nf }}</td>
                            <td *ngIf="apps.bas.ui.r.gtemd" nzAlign="right">{{ data.dayDouble | nf }}</td>
                            <td *ngIf="apps.bas.ui.r.gtelg">
                              <nz-tag *ngFor="let wd of data.weekdaysList" class="weekday-tag" [nzColor]="data.isWeekdaysAll || wd.enabled ? 'green' : 'red'">{{ wd.mkWeekday1 }}</nz-tag>
                            </td>
                            <td *ngIf="apps.bas.ui.r.gtemd" nzAlign="right">{{ data.weekDouble | nf }}</td>
                            <td *ngIf="apps.bas.ui.r.gtelg" nzAlign="right">{{ data.friToSunDouble | nf }}</td>
                            <td *ngIf="apps.bas.ui.r.gtelg" nzAlign="right">{{ data.thuToSunDouble | nf }}</td>
                            <td *ngIf="apps.bas.ui.r.gtelg" nzAlign="right">{{ data.monToFriDouble | nf }}</td>

                            <!-- -->
                            <td *ngIf="apps.bas.ui.r.ltesm" nzAlign="right">{{ (data.periodDouble || data.dayDouble) | nf }}</td>
                            
                            <td >
                              <i nz-icon class="cursor-pointer" [nzType]="'copy'" (click)="copyPriceItem(idx, 'manPeriodItem', edit.price.obj, data)" ></i>
                            </td>

                          </tr>
                          <tr  *ngIf="edit.manPeriodItem.obj?.id == data.id" class="tr-extended"> 
                           
                            <td [attr.colspan]="
                                                       4 
                              + (apps.bas.ui.r.ltesm ? 1 : 0)  
                              + (apps.bas.ui.r.gtesm ? 1 : 0) 
                              + (apps.bas.ui.r.gtemd ? 3 : 0) 
                              + (apps.bas.ui.r.gtelg ? 5 : 0)
                              ">
                            <div *ngIf="edit.manPeriodItem.obj" class="tr-extended-div">
                           
                              <form nz-form [formGroup]="edit.manPeriodItem.form" class="item-form mt-d" nzLayout="vertical" [nzAutoTips]="apps.bas.fs.autoTips">

                                <btlib-form-row [controls]="[ edit.manPeriodItem.controls.order, edit.manPeriodItem.controls.name ]" [form]="edit.manPeriodItem.form"  [grid]="gridMd" (change)="onChange($event)"  ></btlib-form-row>  
                                <btlib-form-row [controls]="[ edit.manPeriodItem.controls.datePeriodAsRange, edit.manPeriodItem.controls.daysAsRange ]" [form]="edit.manPeriodItem.form"  [grid]="gridMd" (change)="onChange($event)"  ></btlib-form-row>  
                                <btlib-form-row [controls]="[ 
                                  edit.manPeriodItem.controls.periodDouble, 
                                  edit.manPeriodItem.controls.dayDouble, 
                                  edit.manPeriodItem.controls.weekDouble, 
                                  edit.manPeriodItem.controls.friToSunDouble, 
                                  edit.manPeriodItem.controls.thuToSunDouble, 
                                  edit.manPeriodItem.controls.monToFriDouble,
                                 ]" [form]="edit.manPeriodItem.form"  [grid]="gridSm" (change)="onChange($event)"  ></btlib-form-row>  
                             
                                <btlib-form-row [controls]="[ edit.manPeriodItem.controls.weekdaysMap ]" [form]="edit.manPeriodItem.form"  [grid]="gridXl" (change)="onChange($event)"  ></btlib-form-row>  
                        
                                <div nz-row [nzGutter]="0" nzAlign="middle">
                                  <div nz-col [nzXs]="12">
                                    <button 
                                      nz-popconfirm
                                      [nzPopconfirmTitle]="'app.lib.common.popconfirm.delete' | actrans | str"
                                      nz-button nzType="default" nzDanger nzSize="small" class="" (click)="deletePriceItem(idx, 'manPeriodItem', edit.price.obj, data)">
                                      {{ 'common.delete' | actrans }}
                                    </button>
                                  </div>
                                  <div nz-col [nzXs]="12">
                                    <div class="text-right">
                                      <button nz-button nzType="default" nzSize="small" (click)="saveItem('manPeriodItem', '', edit.price.obj, $event)">
                                        {{ 'app.lib.common.save' | actrans }}
                                      </button>
                                    </div>
                              
                                  </div>
                                </div>

                                

                              </form>
                            </div>
                            </td>

                          </tr>
                        </ng-container>
                      </tbody>
                    </nz-table>

                    <div *ngIf="!edit.manPeriodItem.obj" class="text-right">
                      <button nz-button nzType="default" nzSize="small" (click)="newItem($event, 'manPeriodItem', '', edit.price.obj)">
                        {{ 'common.add' | actrans }}
                      </button>
                    </div>
                  </div>

                 </div>
                 <div *ngIf="edit.price.obj.typeEnum?.isDiscount">
                  <nz-alert nzType="info" nzMessage="{{ 'web.common.price.discountInfo' | actrans | str}}" nzShowIcon></nz-alert>
                  <btlib-form-row [controls]="[ edit.price.controls.price, edit.price.controls.discountPercent, edit.price.controls.discountAmountDouble ]" [form]="edit.price.form"  [grid]="gridMd" (change)="onChange($event)"  ></btlib-form-row>  
                
                  <btlib-form-row [controls]="[ edit.price.controls.discountDaysAsRange, edit.price.controls.discountDatePeriodAsRange ]" [form]="edit.price.form"  [grid]="gridMd" (change)="onChange($event)"  ></btlib-form-row>  
                
                 </div>
                 <div *ngIf="edit.price.obj.typeEnum?.isCustom">
                  <nz-alert nzType="info" nzMessage="{{ 'web.common.price.customInfo' | actrans | str}}" nzShowIcon></nz-alert>
                  
                  <btlib-form-row [controls]="[ edit.price.controls.customDefaultDouble, edit.price.controls.customMinDouble,  edit.price.controls.customMaxDouble ]" [form]="edit.price.form"  [grid]="gridMd" (change)="onChange($event)"  ></btlib-form-row>  

                 </div>


                </form>
    

              </ng-template>
     
            </nz-modal>

            
          </div>



          
          <div id="section-images" *ngIf="isSectionVisible('images')">
            <hr/>
            <h2>{{ 'bus.product.images' | actrans }}</h2>
    
            <div nz-row [nzGutter]="[8, 16]" nzAlign="top" cdkDropList  (cdkDropListDropped)="cdkDropListDroppedItem($event, 'image')" >
              <div cdkDrag *ngFor="let itemId of p.images; let idx = index" nz-col [nzXs]="12" [nzSm]="8" [nzMd]="8" [nzLg]="6" [nzXl]="6" [nzXXl]="4" >
            
                <nz-card [nzActions]=" [itemActionDelete, itemActionMove, itemActionEdit]" >
                  <img nz-image [nzDisablePreview]="true" width="100%" class="cursor-pointer" [nzSrc]="p.imageMap[itemId].urlMediumSecure"  (click)="editItem(itemId, 'image')" />
                </nz-card>
    
                <ng-template #itemActionDelete>
                  <i nz-icon nzType="delete" 
                  nz-popconfirm
                  [nzPopconfirmTitle]="'app.lib.common.popconfirm.confirm' | actrans | str"  
                  [title]="'common.delete' | actrans"
                  (nzOnConfirm)="deleteItem(idx, 'image')" [nzTheme]="'twotone'" [nzTwotoneColor]="'#eb2f96'"></i>
                </ng-template>
                <ng-template #itemActionMove>
                  <i nz-icon nzType="up" (click)="moveItem(idx, true, 'image')" ></i>
                  <i nz-icon nzType="drag" cdkDragHandle></i>
                  <i nz-icon nzType="down" (click)="moveItem(idx, false, 'image')"></i>
                </ng-template>
                <ng-template #itemActionEdit>
                  <i nz-icon nzType="edit" (click)="editItem(itemId, 'image')" ></i>
                </ng-template>
              </div>

              <div nz-col [nzXs]="12" [nzSm]="8" [nzMd]="8" [nzLg]="6" [nzXl]="6" [nzXXl]="4" style="margin:auto; padding: 20">
        
                <nz-upload
                  nzType="drag"
                  [nzMultiple]="true"
                  [nzDisabled]="!p.id"
                  [nzAction]="this.apps.bas.ws.getBaseUrlTools() + '?action=uploadDropzone&type=productImage&product=' + p.id"
                  [nzHeaders]="uploadHeaders"
                  [(nzFileList)]="edit.image.fileList"
                  nzFileType="image/png,image/jpeg"
                  nzListType="text"
                  [nzShowUploadList]="{ showRemoveIcon: true }"
                  (nzChange)="handleUploadItem($event, 'image')"
                >
                  <p class="ant-upload-drag-icon">
                    <i nz-icon nzType="inbox"></i>
                  </p>
                  <p class="ant-upload-text">
                    {{ 'app.cbapi5.page.admin.product.clickOrDragImagesHere' | actrans }}
                    
                  </p>
                  <p class="ant-upload-hint">
                    {{ 'app.cbapi5.page.admin.product.uploadImagesHint' | actrans }}
                    
                  </p>
                </nz-upload>
  
              </div>
            </div>
            <!-- [nzComponentParams]="{ item: edit.image.obj }"  -->
            <nz-modal [nzVisible]="!!edit.image.obj" nzTitle="{{ 'common.image' | actrans | str }}" nzWidth="95vw" [nzContent]="modalContentItem" (nzOnCancel)="edit.image.obj = null" (nzOnOk)="saveItem('image')">
              <ng-template #modalContentItem let-params>

  
                <form nz-form [formGroup]="edit.image.form" class="item-form mt-d" nzLayout="vertical" [nzAutoTips]="apps.bas.fs.autoTips">

                  <div nz-row [nzGutter]="gridMd.gutter">
                    <div nz-col [nzXs]="24" [nzSm]="10" [nzMd]="8" >
                      <div *ngIf="edit.image.obj" class="text-center">
                        <img nz-image height="150px" [nzDisablePreview]="true" [nzSrc]="edit.image.obj.urlMediumSecure" alt="" style="max-width: 200px;" />
                      </div>
                    </div>
                    <div nz-col [nzXs]="24" [nzSm]="14" [nzMd]="16">
                      <btlib-form-row [controls]="[ 
                        edit.image.controls.updated,
                        edit.image.controls.fileSizeDisplay,
                        edit.image.controls.filename,
                        edit.image.controls.user
                        ]" [form]="edit.image.form"  [grid]="gridMd" (change)="onChange($event)"  ></btlib-form-row>  
                    </div>
      
                  </div>

                  <div class="m-2"></div>
                 
                  <btlib-form-row [controls]="[ edit.image.controls.name, edit.image.controls.type ]" [form]="edit.image.form"  [grid]="gridMd" (change)="onChange($event)"  ></btlib-form-row>  
                  <btlib-form-row [controls]="[ edit.image.controls.messages.childMap.desc ]" [form]="edit.image.form"  [grid]="gridXl" (change)="onChange($event)"  ></btlib-form-row>  
                  <btlib-form-row [controls]="[ edit.image.controls.tags, edit.image.controls.copyright ]" [form]="edit.image.form"  [grid]="gridMd" (change)="onChange($event)"  ></btlib-form-row>  
                 
                </form>
    

              </ng-template>
     
            </nz-modal>
    
          </div>

          


          <div id="section-times" *ngIf="isSectionVisible('times')">
            <hr/>
            <h2>{{ 'bus.product.times' | actrans }}</h2>
            
            <div nz-row [nzGutter]="[8, 16]" nzAlign="top" cdkDropList  (cdkDropListDropped)="cdkDropListDroppedItem($event, 'time')" >
              <div cdkDrag *ngFor="let itemId of p.timesParents; let idx = index" nz-col [nzXs]="12" [nzSm]="8" [nzMd]="8" [nzLg]="6" [nzXl]="6" [nzXXl]="4" >
            
                <nz-card [nzActions]=" [itemActionDelete, itemActionMove, itemActionEdit]" >
                  
                  <div>{{ p.timeMap[itemId].name }}</div>
                  <div>{{ p.timeMap[itemId].datePeriodDisplay }}</div>
                </nz-card>
    
                <ng-template #itemActionDelete>
                  <i nz-icon nzType="delete" 
                  nz-popconfirm
                  [nzPopconfirmTitle]="'app.lib.common.popconfirm.confirm' | actrans | str"  
                  [title]="'common.delete' | actrans"
                  (nzOnConfirm)="deleteItem(idx, 'time')" [nzTheme]="'twotone'" [nzTwotoneColor]="'#eb2f96'"></i>
                  
                  <i nz-icon nzType="copy"
                  nz-popconfirm
                  [nzPopconfirmTitle]="'app.lib.common.popconfirm.confirm' | actrans | str"  
                  [title]="'common.copy' | actrans"
                  (nzOnConfirm)="newItem(undefined, 'time', '', undefined, itemId)"></i>

                </ng-template>
                <ng-template #itemActionMove>
                  <i nz-icon nzType="up" (click)="moveItem(idx, true, 'time')" ></i>
                  <i nz-icon nzType="drag" cdkDragHandle></i>
                  <i nz-icon nzType="down" (click)="moveItem(idx, false, 'time')"></i>
                </ng-template>
                <ng-template #itemActionEdit>
  
                  <i nz-icon nzType="edit" (click)="editItem(itemId, 'time')" ></i>
                </ng-template>
              </div>

              <div nz-col [nzXs]="12" [nzSm]="8" [nzMd]="8" [nzLg]="6" [nzXl]="6" [nzXXl]="4" style="margin:auto;">
        
                <div class="item-new-wrapper">
                  <div class="item-new-icon"><i nz-icon nzType="plus-circle"></i></div>
                  <button nz-button [disabled]="!p.id" nzType="dashed" nzSize="large" class="item-new" (click)="newItem($event, 'time')">
                    {{ 'common.add' | actrans }}
                  </button>
                </div>
  
              </div>
            </div>
            <!-- [nzComponentParams]="{ item: edit.time.obj }"  -->
            <nz-modal [nzVisible]="!!edit.time.obj" nzTitle="{{ 'bus.productTime' | actrans | str }}" nzWidth="95vw" [nzContent]="modalContentItem" (nzOnCancel)="edit.time.obj = null" (nzOnOk)="saveItem('time')">
              <ng-template #modalContentItem let-params>

  
                <form nz-form [formGroup]="edit.time.form" class="item-form mt-d" nzLayout="vertical" [nzAutoTips]="apps.bas.fs.autoTips">

                  <btlib-form-row [controls]="[ 
                    edit.time.controls.time,
                    edit.time.controls.updated
                  ]" [form]="edit.time.form"  [grid]="gridMd" (change)="onChange($event)"  ></btlib-form-row>  
                 <btlib-form-row [controls]="[  edit.time.controls.timeEnabled ]" [form]="edit.time.form"  [grid]="gridMd" (change)="onChange($event)"  ></btlib-form-row> 
                 <btlib-form-row [controls]="[ edit.time.controls.name, edit.time.controls.datePeriodAsRange  ]" [form]="edit.time.form"  [grid]="gridMd"  (change)="onChange($event)" ></btlib-form-row>  
                 
                 <div *ngIf="!(edit.time.obj && apps.bas.envtest)">
                  <btlib-form-row [controls]="[ edit.time.controls.startTimeAsString, edit.time.controls.endTimeAsString  ]" [form]="edit.time.form"  [grid]="gridMd" (change)="onChange($event)"  ></btlib-form-row>  
                  <btlib-form-row [controls]="[ edit.time.controls.weekdaysMap ]" [form]="edit.time.form"  [grid]="gridXl" (change)="onChange($event)"  ></btlib-form-row> 
                  <btlib-form-row [controls]="[  edit.time.controls.enableWhenFull ]" [form]="edit.time.form"  [grid]="gridMd"  (change)="onChange($event)" ></btlib-form-row>  
                  
                 </div>
        
              
                 <btlib-form-row [controls]="[ edit.time.controls.cutoff,  edit.time.controls.overridePeriod ]" [form]="edit.time.form"  [grid]="gridMd"  (change)="onChange($event)" ></btlib-form-row>  
                 <btlib-form-row [controls]="[ edit.time.controls.routeItemFrom, edit.time.controls.routeItemTo, edit.time.controls.routeNumber ]" [form]="edit.time.form"  [grid]="gridMd"  (change)="onChange($event)" ></btlib-form-row>  
                

                 <div *ngIf="edit.time.obj && apps.bas.envtest">

                  <nz-table 
                  #nzTableTimeChildren
                  [nzData]="edit.time.obj.childrenAll || []" 
                  nzTableLayout="fixed" 
                  [nzShowPagination]="false" 
                  [nzPageSize]="100"
                  nzSize="small"
                  class="nz-table-xs"
                  >
                  <thead>
                    <tr>
                      <th nzWidth="50px">#</th>
                      <th nzWidth="60px" nzAlign="center">{{ 'common.enabled' | actrans }}</th>
                      <th nzWidth="100px">{{ 'bus.productTime.startTime' | actrans }}</th>
                      <th nzWidth="100px">{{ 'bus.productTime.endTime' | actrans }}</th>
                      <th nzWidth="240px">{{ 'common.weekdays' | actrans }}</th>
                      <th >{{ 'bus.productTime.enableWhenFullShort' | actrans }}</th>

                      <th nzWidth="30px"></th>
                      <th nzWidth="30px"></th>
                     
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container  *ngFor="let tc of nzTableTimeChildren.data; let idx = index">
                      <tr *ngFor="let fg of [ edit.time.controls.timeChildren.childMap['tc_' + tc.id] ]" >
                
                        <td>
                          <bt-dynamic-form-control *ngIf="idx != 0"  [control]="fg.childMap.order" [form]="fg.fg" [options]="tcOpts" (change)="onChange($event)" ></bt-dynamic-form-control>
                        </td>
                        <td nzAlign="center">
                          <bt-dynamic-form-control *ngIf="idx != 0" [control]="fg.childMap.timeEnabled" [form]="fg.fg" [options]="tcOpts" (change)="onChange($event)" ></bt-dynamic-form-control>
                        </td>
                        <td><bt-dynamic-form-control [control]="fg.childMap.startTimeAsString" [form]="fg.fg" [options]="tcOpts" (change)="onChange($event)" ></bt-dynamic-form-control></td>
                        <td><bt-dynamic-form-control [control]="fg.childMap.endTimeAsString" [form]="fg.fg" [options]="tcOpts" (change)="onChange($event)" ></bt-dynamic-form-control></td>
                        <td><bt-dynamic-form-control [control]="fg.childMap.weekdaysMap" [form]="fg.fg" [options]="tcOpts" (change)="onChange($event)" ></bt-dynamic-form-control></td>
                        <td><bt-dynamic-form-control [control]="fg.childMap.enableWhenFull" [form]="fg.fg" [options]="tcOpts" (change)="onChange($event)" ></bt-dynamic-form-control></td>
                  
            
                        <td nzAlign="center">
                          <i nz-icon class="cursor-pointer" [nzType]="'copy'" (click)="copyTimeChild(tc)" ></i>  
                     
                        </td>
                        <td nzAlign="center">
                          <i *ngIf="idx != 0" nz-icon class="cursor-pointer" [nzType]="'delete'" (click)="deleteTimeChild(tc)" ></i>
                       
                        </td>

                      </tr>
                 
                    </ng-container>
                  </tbody>
                </nz-table>

                <!--
                  <div *ngFor="let tc of edit.time.obj.childrenAll || []">
                    
                    <div *ngFor="let fg of [ edit.time.controls.timeChildren.childMap['tc_' + tc.id] ]">
                      <btlib-form-row [controls]="[ fg.childMap.order, fg.childMap.timeEnabled  ]" [form]="fg.fg"  [grid]="gridMd" (change)="onChange($event)"  ></btlib-form-row>  
                      <btlib-form-row [controls]="[ fg.childMap.startTimeAsString, fg.childMap.endTimeAsString  ]" [form]="fg.fg"  [grid]="gridMd" (change)="onChange($event)"  ></btlib-form-row>  
                      <btlib-form-row [controls]="[ fg.childMap.weekdaysMap, fg.childMap.enableWhenFull  ]" [form]="fg.fg"  [grid]="gridMd" (change)="onChange($event)"  ></btlib-form-row>  
            
                    </div>

                  </div>
                -->
                 </div>
                 
                </form>
    

              </ng-template>
     
            </nz-modal>
            
          </div>

          <div id="section-periods" *ngIf="isSectionVisible('periods')">
            <hr/>
            <h2>{{ 'bus.product.periods' | actrans }}</h2>

            <div nz-row [nzGutter]="[8, 16]" nzAlign="top" cdkDropList  (cdkDropListDropped)="cdkDropListDroppedItem($event, 'period')" >
              <div cdkDrag *ngFor="let itemId of p.periods; let idx = index" nz-col [nzXs]="12" [nzSm]="8" [nzMd]="8" [nzLg]="6" [nzXl]="6" [nzXXl]="4" >
            
                <nz-card [nzActions]=" [itemActionDelete, itemActionMove, itemActionEdit]" >
                  
                  <div>{{ p.periodMap[itemId].name }}</div>
                  <div>{{ p.periodMap[itemId].datePeriodDisplay }}</div>
                </nz-card>
    
                <ng-template #itemActionDelete>
                  <i nz-icon nzType="delete" 
                  nz-popconfirm
                  [nzPopconfirmTitle]="'app.lib.common.popconfirm.confirm' | actrans | str"  
                  [title]="'common.delete' | actrans"
                  (nzOnConfirm)="deleteItem(idx, 'period')" [nzTheme]="'twotone'" [nzTwotoneColor]="'#eb2f96'"></i>
                </ng-template>
                <ng-template #itemActionMove>
                  <i nz-icon nzType="up" (click)="moveItem(idx, true, 'period')" ></i>
                  <i nz-icon nzType="drag" cdkDragHandle></i>
                  <i nz-icon nzType="down" (click)="moveItem(idx, false, 'period')"></i>
                </ng-template>
                <ng-template #itemActionEdit>
                  <i nz-icon nzType="edit" (click)="editItem(itemId, 'period')" ></i>
                </ng-template>
              </div>

              <div nz-col [nzXs]="12" [nzSm]="8" [nzMd]="8" [nzLg]="6" [nzXl]="6" [nzXXl]="4" style="margin:auto;">
        
                <div class="item-new-wrapper">
                  <div class="item-new-icon"><i nz-icon nzType="plus-circle"></i></div>
                  <button nz-button [disabled]="!p.id" nzType="dashed" nzSize="large" class="item-new" (click)="newItem($event, 'period')">
                    {{ 'common.add' | actrans }}
                  </button>
                </div>
  
              </div>
            </div>


            
            <!-- [nzComponentParams]="{ item: edit.period.obj }"  -->
            <nz-modal [nzVisible]="!!edit.period.obj" nzTitle="{{ 'bus.productPeriod' | actrans | str }}" nzWidth="95vw" [nzContent]="modalContentItem" (nzOnCancel)="edit.period.obj = null" (nzOnOk)="saveItem('period')">
              <ng-template #modalContentItem let-params>

  
                <form nz-form [formGroup]="edit.period.form" class="item-form mt-d" nzLayout="vertical" [nzAutoTips]="apps.bas.fs.autoTips">

                  <btlib-form-row [controls]="[ 
                    edit.period.controls.time,
                    edit.period.controls.updated
                  ]" [form]="edit.period.form"  [grid]="gridMd" (change)="onChange($event)"  ></btlib-form-row>  
                 <btlib-form-row [controls]="[ edit.period.controls.name  ]" [form]="edit.period.form"  [grid]="gridMd" (change)="onChange($event)"  ></btlib-form-row>  
                 <btlib-form-row [controls]="[ edit.period.controls.datePeriodAsRange  ]" [form]="edit.period.form"  [grid]="gridMd" (change)="onChange($event)"  ></btlib-form-row>  

                </form>
    

              </ng-template>
     
            </nz-modal>
            
          </div>


          <div id="section-routeItems" *ngIf="isSectionVisible('routeItems')">
            <hr/>
            <h2>{{ 'bus.product.routeItems' | actrans }}</h2>

            <div nz-row [nzGutter]="[8, 16]" nzAlign="top" cdkDropList  (cdkDropListDropped)="cdkDropListDroppedItem($event, 'routeItem')" >
              <div cdkDrag *ngFor="let itemId of p.routeItems; let idx = index" nz-col [nzXs]="12" [nzSm]="8" [nzMd]="8" [nzLg]="6" [nzXl]="6" [nzXXl]="4" >
            
                <nz-card [nzActions]=" [itemActionDelete, itemActionMove, itemActionEdit]" >
                  
                  <div>{{ p.routeItemMap[itemId].name }}</div>
                  <!-- <div>{{ p.routeItemMap[itemId].routeFromLabel }}</div>
                  <div>{{ p.routeItemMap[itemId].routeToLabel }}</div> -->
                </nz-card>
    
                <ng-template #itemActionDelete>
                  <i nz-icon nzType="delete" 
                  nz-popconfirm
                  [nzPopconfirmTitle]="'app.lib.common.popconfirm.confirm' | actrans | str"  
                  [title]="'common.delete' | actrans"
                  (nzOnConfirm)="deleteItem(idx, 'routeItem')" [nzTheme]="'twotone'" [nzTwotoneColor]="'#eb2f96'"></i>
                </ng-template>
                <ng-template #itemActionMove>
                  <i nz-icon nzType="up" (click)="moveItem(idx, true, 'routeItem')" ></i>
                  <i nz-icon nzType="drag" cdkDragHandle></i>
                  <i nz-icon nzType="down" (click)="moveItem(idx, false, 'routeItem')"></i>
                </ng-template>
                <ng-template #itemActionEdit>
                  <i nz-icon nzType="edit" (click)="editItem(itemId, 'routeItem')" ></i>
                </ng-template>
              </div>

              <div nz-col [nzXs]="12" [nzSm]="8" [nzMd]="8" [nzLg]="6" [nzXl]="6" [nzXXl]="4" style="margin:auto;">
        
                <div class="item-new-wrapper">
                  <div class="item-new-icon"><i nz-icon nzType="plus-circle"></i></div>
                  <button nz-button [disabled]="!p.id" nzType="dashed" nzSize="large" class="item-new" (click)="newItem($event, 'routeItem')">
                    {{ 'common.add' | actrans }}
                  </button>
                </div>
  
              </div>
            </div>


            
            <!-- [nzComponentParams]="{ item: edit.period.obj }"  -->
            <nz-modal [nzVisible]="!!edit.routeItem.obj" nzTitle="{{ 'bus.product.routeItem' | actrans | str }}" nzWidth="95vw" [nzContent]="modalContentItem" (nzOnCancel)="edit.routeItem.obj = null" (nzOnOk)="saveItem('routeItem')">
              <ng-template #modalContentItem let-params>

  
                <form nz-form [formGroup]="edit.routeItem.form" class="item-form mt-d" nzLayout="vertical" [nzAutoTips]="apps.bas.fs.autoTips">

                  <btlib-form-row [controls]="[ 
                    edit.routeItem.controls.time,
                    edit.routeItem.controls.updated
                  ]" [form]="edit.routeItem.form"  [grid]="gridMd" (change)="onChange($event)"  ></btlib-form-row>  
                 <btlib-form-row [controls]="[ edit.routeItem.controls.name  ]" [form]="edit.routeItem.form"  [grid]="gridMd" (change)="onChange($event)"  ></btlib-form-row>  
                 <btlib-form-row [controls]="[ edit.routeItem.controls.enabled  ]" [form]="edit.routeItem.form"  [grid]="gridMd" (change)="onChange($event)"  ></btlib-form-row>  
                 <btlib-form-row [controls]="[ edit.routeItem.controls.terminal  ]" [form]="edit.routeItem.form"  [grid]="gridMd" (change)="onChange($event)"  ></btlib-form-row>  
                <!-- <btlib-form-row [controls]="[ edit.routeItem.controls.routeStart, edit.routeItem.controls.routeEnd  ]" [form]="edit.routeItem.form"  [grid]="gridMd" (change)="onChange($event)"  ></btlib-form-row>   -->

                 
                 <nz-form-item>
                  <nz-form-label>
                    {{ 'common.map' | actrans }} 
                    <span *ngIf="edit.routeItem.form.controls.latitude.value">
                      &nbsp; | {{ edit.routeItem.form.controls.latitude.value | str | slice:0:10 }}, {{ edit.routeItem.form.controls.longitude.value | str | slice:0:10 }}
                    </span>
                  </nz-form-label>
                  <nz-form-control>
                    <button nz-button (click)="openMap($event, 'routeItem')" >
                      
                      {{ 'app.cbapi5.page.admin.product.placeOnMap' | actrans }}
                    </button>
         
                  </nz-form-control>
                </nz-form-item>

                </form>
    

              </ng-template>
     
            </nz-modal>
            
          </div>

       

          <!-- [nzComponentParams]="{ item: edit.relation.obj }"  -->
          <nz-modal [nzVisible]="!!edit.relation.obj" nzTitle="{{ 'bus.productRelation' | actrans | str }}" nzWidth="95vw" [nzContent]="modalContentItem" (nzOnCancel)="edit.relation.obj = null" (nzOnOk)="saveItem('relation')">
            <ng-template #modalContentItem let-params>


              <form nz-form [formGroup]="edit.relation.form" class="item-form mt-d" nzLayout="vertical" [nzAutoTips]="apps.bas.fs.autoTips">

                <btlib-form-row [controls]="[ 
                  edit.relation.controls.time,
                  edit.relation.controls.updated,
                  edit.relation.controls.mkType
                ]" [form]="edit.relation.form"  [grid]="gridMd" (change)="onChange($event)"  ></btlib-form-row>  
               <btlib-form-row [controls]="[ edit.relation.controls.child, edit.relation.controls.price  ]" [form]="edit.relation.form"  [grid]="gridMd" (change)="onChange($event)"  ></btlib-form-row>  
               <btlib-form-row [controls]="[ edit.relation.controls.orderStartDate, edit.relation.controls.orderEndDate  ]" [form]="edit.relation.form"  [grid]="gridMd" (change)="onChange($event)"  ></btlib-form-row>  
               <btlib-form-row [controls]="[ edit.relation.controls.orderStartTimeAsString, edit.relation.controls.orderEndTimeAsString  ]" [form]="edit.relation.form"  [grid]="gridMd"  (change)="onChange($event)" ></btlib-form-row>  

               <btlib-form-row [controls]="[ 
                  edit.relation.controls.bookAlways,
                  edit.relation.controls.unlimited,
                  edit.relation.controls.web,
                  edit.relation.controls.copyGuestCounts
                ]" [form]="edit.relation.form"  [grid]="gridMd" (change)="onChange($event)"  ></btlib-form-row>  
            

              </form>
  

            </ng-template>
   
          </nz-modal>
          
          <div id="section-units" *ngIf="isSectionVisible('units')">
            <hr/>
            <h2>{{ 'web.common.product.units' | actrans }}</h2>
            
            <div nz-row [nzGutter]="[8, 16]" nzAlign="top" cdkDropList  (cdkDropListDropped)="cdkDropListDroppedItem($event, 'relation', 'Pool')" >
              <div cdkDrag *ngFor="let itemId of p.relationPools; let idx = index" nz-col [nzXs]="12" [nzSm]="8" [nzMd]="8" [nzLg]="6" [nzXl]="6" [nzXXl]="4" >
            
                <nz-card [nzActions]=" [itemActionDelete, itemActionMove, itemActionEdit]" >
                  
                  <div>{{ p.childMap[itemId].child }}</div>

                </nz-card>
    
                <ng-template #itemActionDelete>
                  <i nz-icon nzType="delete" 
                  nz-popconfirm
                  [nzPopconfirmTitle]="'app.lib.common.popconfirm.confirm' | actrans | str"  
                  [title]="'common.delete' | actrans"
                  (nzOnConfirm)="deleteItem(idx, 'relation', 'Pool')" [nzTheme]="'twotone'" [nzTwotoneColor]="'#eb2f96'"></i>
                </ng-template>
                <ng-template #itemActionMove>
                  <i nz-icon nzType="up" (click)="moveItem(idx, true, 'relation', 'Pool')" ></i>
                  <i nz-icon nzType="drag" cdkDragHandle></i>
                  <i nz-icon nzType="down" (click)="moveItem(idx, false, 'relation', 'Pool')"></i>
                </ng-template>
                <ng-template #itemActionEdit>
                  <i nz-icon nzType="edit" (click)="editItem(itemId, 'relation', 'Pool')" ></i>
                </ng-template>
              </div>

              <div nz-col [nzXs]="12" [nzSm]="8" [nzMd]="8" [nzLg]="6" [nzXl]="6" [nzXXl]="4" style="margin:auto;">
        
                <div class="item-new-wrapper">
                  <div class="item-new-icon"><i nz-icon nzType="plus-circle"></i></div>
                  <button nz-button [disabled]="!p.id" nzType="dashed" nzSize="large" class="item-new" (click)="newItem($event, 'relation', 'Pool')">
                    {{ 'common.add' | actrans }}
                  </button>
                </div>
  
              </div>
            </div>
           

          </div>

          <div id="section-accessories" *ngIf="isSectionVisible('accessories')">
            <hr/>
            <h2>{{ 'bus.product.accessories' | actrans }}</h2>
            
            <div nz-row [nzGutter]="[8, 16]" nzAlign="top" cdkDropList  (cdkDropListDropped)="cdkDropListDroppedItem($event, 'relation', 'Accessory')" >
              <div cdkDrag *ngFor="let itemId of p.relationAccessorys; let idx = index" nz-col [nzXs]="12" [nzSm]="8" [nzMd]="8" [nzLg]="6" [nzXl]="6" [nzXXl]="4" >
            
                <nz-card [nzActions]=" [itemActionDelete, itemActionMove, itemActionEdit]" >
                  
                  <div>{{ p.childMap[itemId].child }}</div>

                </nz-card>
    
                <ng-template #itemActionDelete>
                  <i nz-icon nzType="delete" 
                  nz-popconfirm
                  [nzPopconfirmTitle]="'app.lib.common.popconfirm.confirm' | actrans | str"  
                  [title]="'common.delete' | actrans"
                  (nzOnConfirm)="deleteItem(idx, 'relation', 'Accessory')" [nzTheme]="'twotone'" [nzTwotoneColor]="'#eb2f96'"></i>
                </ng-template>
                <ng-template #itemActionMove>
                  <i nz-icon nzType="up" (click)="moveItem(idx, true, 'relation', 'Accessory')" ></i>
                  <i nz-icon nzType="drag" cdkDragHandle></i>
                  <i nz-icon nzType="down" (click)="moveItem(idx, false, 'relation', 'Accessory')"></i>
                </ng-template>
                <ng-template #itemActionEdit>
                  <i nz-icon nzType="edit" (click)="editItem(itemId, 'relation', 'Accessory')" ></i>
                </ng-template>
              </div>

              <div nz-col [nzXs]="12" [nzSm]="8" [nzMd]="8" [nzLg]="6" [nzXl]="6" [nzXXl]="4" style="margin:auto;">
        
                <div class="item-new-wrapper">
                  <div class="item-new-icon"><i nz-icon nzType="plus-circle"></i></div>
                  <button nz-button [disabled]="!p.id" nzType="dashed" nzSize="large" class="item-new" (click)="newItem($event, 'relation', 'Accessory')">
                    {{ 'common.add' | actrans }}
                  </button>
                </div>
  
              </div>
            </div>
           

          </div>

          <div id="section-related" *ngIf="isSectionVisible('related')">
            <hr/>
            <h2>{{ 'bus.productRelation.type.relation' | actrans }}</h2>

            <div nz-row [nzGutter]="[8, 16]" nzAlign="top" cdkDropList  (cdkDropListDropped)="cdkDropListDroppedItem($event, 'relation', 'Relation')" >
              <div cdkDrag *ngFor="let itemId of p.relationRelations; let idx = index" nz-col [nzXs]="12" [nzSm]="8" [nzMd]="8" [nzLg]="6" [nzXl]="6" [nzXXl]="4" >
            
                <nz-card [nzActions]=" [itemActionDelete, itemActionMove, itemActionEdit]" >
                  
                  <div>{{ p.childMap[itemId].child }}</div>

                </nz-card>
    
                <ng-template #itemActionDelete>
                  <i nz-icon nzType="delete" 
                  nz-popconfirm
                  [nzPopconfirmTitle]="'app.lib.common.popconfirm.confirm' | actrans | str"  
                  [title]="'common.delete' | actrans"
                  (nzOnConfirm)="deleteItem(idx, 'relation', 'Relation')" [nzTheme]="'twotone'" [nzTwotoneColor]="'#eb2f96'"></i>
                </ng-template>
                <ng-template #itemActionMove>
                  <i nz-icon nzType="up" (click)="moveItem(idx, true, 'relation', 'Relation')" ></i>
                  <i nz-icon nzType="drag" cdkDragHandle></i>
                  <i nz-icon nzType="down" (click)="moveItem(idx, false, 'relation', 'Relation')"></i>
                </ng-template>
                <ng-template #itemActionEdit>
                  <i nz-icon nzType="edit" (click)="editItem(itemId, 'relation', 'Relation')" ></i>
                </ng-template>
              </div>

              <div nz-col [nzXs]="12" [nzSm]="8" [nzMd]="8" [nzLg]="6" [nzXl]="6" [nzXXl]="4" style="margin:auto;">
        
                <div class="item-new-wrapper">
                  <div class="item-new-icon"><i nz-icon nzType="plus-circle"></i></div>
                  <button nz-button [disabled]="!p.id" nzType="dashed" nzSize="large" class="item-new" (click)="newItem($event, 'relation', 'Relation')">
                    {{ 'common.add' | actrans }}
                  </button>
                </div>
  
              </div>
            </div>
            
          </div>
          <div id="section-packageItems" *ngIf="isSectionVisible('packageItems')">
            <hr/>
            <h2>{{ 'bus.productRelation.type.package' | actrans }}</h2>
            <div nz-row [nzGutter]="[8, 16]" nzAlign="top" cdkDropList  (cdkDropListDropped)="cdkDropListDroppedItem($event, 'relation', 'Package')" >
              <div cdkDrag *ngFor="let itemId of p.relationPackages; let idx = index" nz-col [nzXs]="12" [nzSm]="8" [nzMd]="8" [nzLg]="6" [nzXl]="6" [nzXXl]="4" >
            
                <nz-card [nzActions]=" [itemActionDelete, itemActionMove, itemActionEdit]" >
                  
                  <div>{{ p.childMap[itemId].child }}</div>

                </nz-card>
    
                <ng-template #itemActionDelete>
                  <i nz-icon nzType="delete" 
                  nz-popconfirm
                  [nzPopconfirmTitle]="'app.lib.common.popconfirm.confirm' | actrans | str"  
                  [title]="'common.delete' | actrans"
                  (nzOnConfirm)="deleteItem(idx, 'relation', 'Package')" [nzTheme]="'twotone'" [nzTwotoneColor]="'#eb2f96'"></i>
                </ng-template>
                <ng-template #itemActionMove>
                  <i nz-icon nzType="up" (click)="moveItem(idx, true, 'relation', 'Package')" ></i>
                  <i nz-icon nzType="drag" cdkDragHandle></i>
                  <i nz-icon nzType="down" (click)="moveItem(idx, false, 'relation', 'Package')"></i>
                </ng-template>
                <ng-template #itemActionEdit>
                  <i nz-icon nzType="edit" (click)="editItem(itemId, 'relation', 'Package')" ></i>
                </ng-template>
              </div>

              <div nz-col [nzXs]="12" [nzSm]="8" [nzMd]="8" [nzLg]="6" [nzXl]="6" [nzXXl]="4" style="margin:auto;">
        
                <div class="item-new-wrapper">
                  <div class="item-new-icon"><i nz-icon nzType="plus-circle"></i></div>
                  <button nz-button [disabled]="!p.id" nzType="dashed" nzSize="large" class="item-new" (click)="newItem($event, 'relation', 'Package')">
                    {{ 'common.add' | actrans }}
                  </button>
                </div>
  
              </div>
            </div>

          </div>

          <div id="section-groupItems" *ngIf="isSectionVisible('groupItems')">
            <hr/>
            <h2>{{ 'bus.productRelation.type.group' | actrans }}</h2>
            <div nz-row [nzGutter]="[8, 16]" nzAlign="top" cdkDropList  (cdkDropListDropped)="cdkDropListDroppedItem($event, 'relation', 'Group')" >
              <div cdkDrag *ngFor="let itemId of p.relationGroups; let idx = index" nz-col [nzXs]="12" [nzSm]="8" [nzMd]="8" [nzLg]="6" [nzXl]="6" [nzXXl]="4" >
            
                <nz-card [nzActions]=" [itemActionDelete, itemActionMove, itemActionEdit]" >
                  
                  <div>{{ p.childMap[itemId].child }}</div>
                </nz-card>
    
                <ng-template #itemActionDelete>
                  <i nz-icon nzType="delete" 
                  nz-popconfirm
                  [nzPopconfirmTitle]="'app.lib.common.popconfirm.confirm' | actrans | str"  
                  [title]="'common.delete' | actrans"
                  (nzOnConfirm)="deleteItem(idx, 'relation', 'Group')" [nzTheme]="'twotone'" [nzTwotoneColor]="'#eb2f96'"></i>
                </ng-template>
                <ng-template #itemActionMove>
                  <i nz-icon nzType="up" (click)="moveItem(idx, true, 'relation', 'Group')" ></i>
                  <i nz-icon nzType="drag" cdkDragHandle></i>
                  <i nz-icon nzType="down" (click)="moveItem(idx, false, 'relation', 'Group')"></i>
                </ng-template>
                <ng-template #itemActionEdit>
                  <i nz-icon nzType="edit" (click)="editItem(itemId, 'relation', 'Group')" ></i>
                </ng-template>
              </div>

              <div nz-col [nzXs]="12" [nzSm]="8" [nzMd]="8" [nzLg]="6" [nzXl]="6" [nzXXl]="4" style="margin:auto;">
        
                <div class="item-new-wrapper">
                  <div class="item-new-icon"><i nz-icon nzType="plus-circle"></i></div>
                  <button nz-button [disabled]="!p.id" nzType="dashed" nzSize="large" class="item-new" (click)="newItem($event, 'relation', 'Group')">
                    {{ 'common.add' | actrans }}
                  </button>
                </div>
  
              </div>
            </div>

          </div>

          <div id="section-properties" *ngIf="isSectionVisible('properties')">
            <hr/>
            <h2>{{ 'common.properties' | actrans }}</h2>

            <div nz-row [nzGutter]="[8, 16]" nzAlign="top" >
              <div  *ngFor="let itemId of p.properties; let idx = index" nz-col [nzXs]="12" [nzSm]="8" [nzMd]="8" [nzLg]="6" [nzXl]="6" [nzXXl]="4" >
            
                <nz-card [nzActions]=" [itemActionDelete, itemActionEdit]" >
                  
                  <div><strong>{{ p.propertyMap[itemId].type }}</strong></div>
                  <div>{{ p.propertyMap[itemId].label }}</div>
                </nz-card>
    
                <ng-template #itemActionDelete>
                  <i nz-icon nzType="delete" 
                  nz-popconfirm
                  [nzPopconfirmTitle]="'app.lib.common.popconfirm.confirm' | actrans | str"  
                  [title]="'common.delete' | actrans"
                  (nzOnConfirm)="deleteItem(idx, 'property')" [nzTheme]="'twotone'" [nzTwotoneColor]="'#eb2f96'"></i>
                </ng-template>
                <!-- <ng-template #itemActionMove>
                  <i nz-icon nzType="up" (click)="moveItem(idx, true, 'property')" ></i>
                  <i nz-icon nzType="drag" cdkDragHandle></i>
                  <i nz-icon nzType="down" (click)="moveItem(idx, false, 'property')"></i>
                </ng-template> -->
                <ng-template #itemActionEdit>
                  <i nz-icon nzType="edit" (click)="editItem(itemId, 'property')" ></i>
                </ng-template>
              </div>

              <div nz-col [nzXs]="12" [nzSm]="8" [nzMd]="8" [nzLg]="6" [nzXl]="6" [nzXXl]="4" style="margin:auto;">
        
                <div class="item-new-wrapper">
                  <div class="item-new-icon"><i nz-icon nzType="plus-circle"></i></div>
                  <form nz-form [formGroup]="add.property.form" class="item-form mt-d p-d" nzLayout="vertical" [nzAutoTips]="apps.bas.fs.autoTips">

                    <btlib-form-row [controls]="[ add.property.controls.type  ]" [form]="add.property.form"  [grid]="gridFull"  (change)="onChange($event)" ></btlib-form-row>  
                  </form>
                  <button nz-button [disabled]="!p.id" nzType="dashed" nzSize="large" class="item-new" (click)="newItem($event, 'property')">
                    {{ 'common.add' | actrans }}
                  </button>
                </div>
  
              </div>
            </div>
            <!-- [nzComponentParams]="{ item: edit.property.obj }"  -->
            <nz-modal [nzVisible]="!!edit.property.obj" nzTitle="{{ 'bus.property' | actrans | str }}" nzWidth="95vw" [nzContent]="modalContentItem" (nzOnCancel)="edit.property.obj = null" (nzOnOk)="saveItem('property')">
              <ng-template #modalContentItem let-params>

  
                <form nz-form [formGroup]="edit.property.form" class="item-form mt-d" nzLayout="vertical" [nzAutoTips]="apps.bas.fs.autoTips">

                  <h2>{{ edit.property.obj?.type }}</h2>
                  <!-- <btlib-form-row [controls]="[ edit.property.controls.type  ]" [form]="edit.property.form"  [grid]="gridMd"  (change)="onChange($event)" ></btlib-form-row>   -->
                  <btlib-form-row [controls]="[ edit.property.controls.value  ]" [form]="edit.property.form"  [grid]="gridMd" (change)="onChange($event)"  ></btlib-form-row>  
                  <btlib-form-row [controls]="[ edit.property.controls.booleanValue  ]" [form]="edit.property.form"  [grid]="gridMd" (change)="onChange($event)"  ></btlib-form-row>  
                  <btlib-form-row [controls]="[ edit.property.controls.doubleValue  ]" [form]="edit.property.form"  [grid]="gridMd" (change)="onChange($event)"  ></btlib-form-row>  
                  <btlib-form-row [controls]="[ edit.property.controls.integerValue  ]" [form]="edit.property.form"  [grid]="gridMd" (change)="onChange($event)"  ></btlib-form-row>  
                  <btlib-form-row [controls]="[ edit.property.controls.messages.childMap.messageValue ]" [form]="edit.property.form"  [grid]="gridXl" (change)="onChange($event)"  ></btlib-form-row>  
                    
                </form>
    

              </ng-template>
     
            </nz-modal>
            
          </div>

          
      
        

          
          <div id="section-files" *ngIf="isSectionVisible('files')">
            <hr/>
            <h2>{{ 'common.files' | actrans }}</h2>

            <btlib-form-row [controls]="[ controls.ppgIncludeGeneratedProductPdf ]" [form]="productForm"  [grid]="gridMd" (change)="onChange($event)" ></btlib-form-row> 

            
            <div nz-row [nzGutter]="[8, 16]" nzAlign="top" cdkDropList  (cdkDropListDropped)="cdkDropListDroppedItem($event, 'file')" >
              <div cdkDrag *ngFor="let itemId of p.files; let idx = index" nz-col [nzXs]="12" [nzSm]="8" [nzMd]="8" [nzLg]="6" [nzXl]="6" [nzXXl]="4" >
            
                <nz-card [nzActions]=" [itemActionDelete, itemActionMove, itemActionEdit]" >
                  <div>{{ p.fileMap[itemId].name }}</div>
                  <div>{{ p.fileMap[itemId].fileSizeDisplay }}</div>
                </nz-card>
    
                <ng-template #itemActionDelete>
                  <i nz-icon nzType="delete" 
                  nz-popconfirm
                  [nzPopconfirmTitle]="'app.lib.common.popconfirm.confirm' | actrans | str"  
                  [title]="'common.delete' | actrans"
                  (nzOnConfirm)="deleteItem(idx, 'file')" [nzTheme]="'twotone'" [nzTwotoneColor]="'#eb2f96'"></i>
                </ng-template>
                <ng-template #itemActionMove>
                  <i nz-icon nzType="up" (click)="moveItem(idx, true, 'file')" ></i>
                  <i nz-icon nzType="drag" cdkDragHandle></i>
                  <i nz-icon nzType="down" (click)="moveItem(idx, false, 'file')"></i>
                </ng-template>
                <ng-template #itemActionEdit>
                  <i nz-icon nzType="edit" (click)="editItem(itemId, 'file')" ></i>
                </ng-template>
              </div>

              <div nz-col [nzXs]="12" [nzSm]="8" [nzMd]="8" [nzLg]="6" [nzXl]="6" [nzXXl]="4" style="margin:auto;">
        
                <nz-upload
                  nzType="drag"
                  [nzMultiple]="true"
                  [nzDisabled]="!p.id"
                  [nzAction]="this.apps.bas.ws.getBaseUrlTools() + '?action=uploadDropzone&type=productFile&product=' + p.id"
                  [nzHeaders]="uploadHeaders"
                  [(nzFileList)]="edit.file.fileList"
                  nzFileType="application/pdf"
                  nzListType="text"
                  [nzShowUploadList]="{ showRemoveIcon: true }"
                  
                  (nzChange)="handleUploadItem($event, 'file')"
                >
                  <p class="ant-upload-drag-icon">
                    <i nz-icon nzType="inbox"></i>
                  </p>
                  <p class="ant-upload-text">
                    {{ 'app.cbapi5.page.admin.product.clickOrDragFilesHere' | actrans }}
             
                  </p>
                  <p class="ant-upload-hint">
                    {{ 'app.cbapi5.page.admin.product.uploadFilesHint' | actrans }}
                  </p>
                </nz-upload>
  
              </div>
            </div>
            <!-- [nzComponentParams]="{ item: edit.file.obj }"  -->
            <nz-modal [nzVisible]="!!edit.file.obj" nzTitle="{{ 'common.file' | actrans | str }}" nzWidth="95vw" [nzContent]="modalContentItem" (nzOnCancel)="edit.file.obj = null" (nzOnOk)="saveItem('file')">
              <ng-template #modalContentItem let-params>

  
                <form nz-form [formGroup]="edit.file.form" class="item-form mt-d" nzLayout="vertical" [nzAutoTips]="apps.bas.fs.autoTips">

                  <btlib-form-row [controls]="[ 
                    edit.file.controls.updated,
                    edit.file.controls.fileSizeDisplay,
                    edit.file.controls.filename,
                    edit.file.controls.user
                  ]" [form]="edit.file.form"  [grid]="gridMd"  (change)="onChange($event)" ></btlib-form-row>  
                  <btlib-form-row [controls]="[ edit.file.controls.name, edit.file.controls.language ]" [form]="edit.file.form"  [grid]="gridMd"  (change)="onChange($event)" ></btlib-form-row>  
                  <btlib-form-row [controls]="[ edit.file.controls.sendOnOrder, edit.file.controls.showOnWeb ]" [form]="edit.file.form"  [grid]="gridMd"  (change)="onChange($event)" ></btlib-form-row> 

                  <nz-form-item *ngIf="edit.file.obj">
                    <nz-form-label>{{ 'bus.image.filename' | actrans }}</nz-form-label>
                    <nz-form-control>
                      <a [href]="edit.file.obj.urlAccess" target="_blank">{{ edit.file.obj.filename }}</a> 
                      <!-- TODO: må legge inn tilgangsnøkkel i URL-en -->
                    </nz-form-control>
                  </nz-form-item>
     
                 
                </form>
    

              </ng-template>
     
            </nz-modal>
            

          </div>

            
          <div id="section-input" *ngIf="isSectionVisible('input')">
            <hr/>
            <h2>{{ 'bus.product.inputs' | actrans }}</h2>
            
            <div nz-row [nzGutter]="[8, 16]" nzAlign="top" cdkDropList  (cdkDropListDropped)="cdkDropListDroppedItem($event, 'input')" >
              <div cdkDrag *ngFor="let itemId of p.inputs; let idx = index" nz-col [nzXs]="12" [nzSm]="8" [nzMd]="8" [nzLg]="6" [nzXl]="6" [nzXXl]="4" >
            
                <nz-card [nzActions]=" [itemActionDelete, itemActionMove, itemActionEdit]" >
                  
                  <div>{{ p.inputMap[itemId].mkName }}</div>
                  <div>{{ p.inputMap[itemId].mkType }}</div>
                </nz-card>
    
                <ng-template #itemActionDelete>
                  <i nz-icon nzType="delete" 
                  nz-popconfirm
                  [nzPopconfirmTitle]="'app.lib.common.popconfirm.confirm' | actrans | str"  
                  [title]="'common.delete' | actrans"
                  (nzOnConfirm)="deleteItem(idx, 'input')" [nzTheme]="'twotone'" [nzTwotoneColor]="'#eb2f96'"></i>
                </ng-template>
                <ng-template #itemActionMove>
                  <i nz-icon nzType="up" (click)="moveItem(idx, true, 'input')" ></i>
                  <i nz-icon nzType="drag" cdkDragHandle></i>
                  <i nz-icon nzType="down" (click)="moveItem(idx, false, 'input')"></i>
                </ng-template>
                <ng-template #itemActionEdit>
                  <i nz-icon nzType="edit" (click)="editItem(itemId, 'input')" ></i>
                </ng-template>
              </div>

              <div nz-col [nzXs]="12" [nzSm]="8" [nzMd]="8" [nzLg]="6" [nzXl]="6" [nzXXl]="4" style="margin:auto;">
        
                <div class="item-new-wrapper">
                  <div class="item-new-icon"><i nz-icon nzType="plus-circle"></i></div>
                  <button nz-button [disabled]="!p.id" nzType="dashed" nzSize="large" class="item-new" (click)="newItem($event, 'input')">
                    {{ 'common.add' | actrans }}
                  </button>
                </div>
  
              </div>
            </div>
            <!-- [nzComponentParams]="{ item: edit.input.obj }"  -->
            <nz-modal [nzVisible]="!!edit.input.obj" nzTitle="{{ 'bus.productInput' | actrans | str }}" nzWidth="95vw" [nzContent]="modalContentItem" (nzOnCancel)="edit.input.obj = null" (nzOnOk)="saveItem('input')">
              <ng-template #modalContentItem let-params>

  
                <form nz-form [formGroup]="edit.input.form" class="item-form mt-d" nzLayout="vertical" [nzAutoTips]="apps.bas.fs.autoTips">

                  <btlib-form-row [controls]="[ 
                    edit.input.controls.updated,
  
                  ]" [form]="edit.input.form"  [grid]="gridMd" (change)="onChange($event)"  ></btlib-form-row>  
                 <btlib-form-row [controls]="[ edit.input.controls.enabled, edit.input.controls.required, edit.input.controls.guestInput, edit.input.controls.includeInProductName  ]" [form]="edit.input.form"  [grid]="gridMd"  (change)="onChange($event)" ></btlib-form-row> 
                 <btlib-form-row [controls]="[ edit.input.controls.name , edit.input.controls.type ]" [form]="edit.input.form"  [grid]="gridMd" (change)="onChange($event)"  ></btlib-form-row>  
                  
                  <!-- -->
                  <btlib-form-row [controls]="[ edit.input.controls.messages.childMap.name, edit.input.controls.messages.childMap.defaultValue ]" [form]="edit.input.form"  [grid]="gridLg" (change)="onChange($event)" ></btlib-form-row> 
                  <btlib-form-row [controls]="[ edit.input.controls.messages.childMap.desc ]" [form]="edit.input.form"  [grid]="gridLg" (change)="onChange($event)" ></btlib-form-row> 
                  <btlib-form-row [controls]="[ edit.input.controls.messages.childMap.options ]" [form]="edit.input.form"  [grid]="gridXl" (change)="onChange($event)" ></btlib-form-row> 
                  <btlib-form-row [controls]="[ edit.input.controls.configAsString ]" [form]="edit.input.form"  [grid]="gridXl" (change)="onChange($event)" ></btlib-form-row> 
            
                  
                </form>
    

              </ng-template>
     
            </nz-modal>

          </div>

            
          <!-- <div id="section-task" *ngIf="isSectionVisible('task')">
            <hr/>
            <h2>{{ 'common.tasks' | actrans }}</h2>
            
            <div nz-row [nzGutter]="[8, 16]" nzAlign="top" >
              <div cdkDrag *ngFor="let task of p.tasks; let idx = index" nz-col [nzXs]="12" [nzSm]="8" [nzMd]="8" [nzLg]="6" [nzXl]="6" [nzXXl]="4" >
            
                <nz-card [nzActions]=" [itemActionDelete, itemActionMove, itemActionEdit]" >
                  
                  <div>{{ task.title }}</div>
                  <div>{{ task.type.name }}</div>
                </nz-card>
    
                <ng-template #itemActionDelete>
                  <i nz-icon nzType="delete" 
                  nz-popconfirm
                  [nzPopconfirmTitle]="'app.lib.common.popconfirm.confirm' | actrans | str"  
                  [title]="'common.delete' | actrans"
                  (nzOnConfirm)="deleteItem(idx, 'task')" [nzTheme]="'twotone'" [nzTwotoneColor]="'#eb2f96'"></i>
                </ng-template>

                <ng-template #itemActionEdit>
                  <i nz-icon nzType="edit" (click)="editItem(idx, 'task')" ></i>
                </ng-template>
              </div>

              <div nz-col [nzXs]="12" [nzSm]="8" [nzMd]="8" [nzLg]="6" [nzXl]="6" [nzXXl]="4" style="margin:auto;">
        
                <div class="item-new-wrapper">
                  <div class="item-new-icon"><i nz-icon nzType="plus-circle"></i></div>
                  <button nz-button [disabled]="!p.id" nzType="dashed" nzSize="large" class="item-new" (click)="newItem($event, 'task')">
                    {{ 'common.add' | actrans }}
                  </button>
                </div>
  
              </div>
            </div>
            <nz-modal [nzVisible]="!!edit.task.obj" nzTitle="{{ 'common.task' | actrans | str }}" nzWidth="95vw" [nzContent]="modalContentItem" [nzComponentParams]="{ item: edit.input.obj }" (nzOnCancel)="edit.input.obj = null" (nzOnOk)="saveItem('input')">
              <ng-template #modalContentItem let-params>

                <form nz-form [formGroup]="edit.task.form" class="item-form mt-d" nzLayout="vertical" [nzAutoTips]="apps.bas.fs.autoTips">

                  <btlib-form-row [controls]="[ 
                    edit.task.controls.updated,
  
                  ]" [form]="edit.task.form"  [grid]="gridMd" (change)="onChange($event)"  ></btlib-form-row>  
                  <btlib-form-row [controls]="[ edit.task.controls.type ]" [form]="edit.task.form"  [grid]="gridMd" (change)="onChange($event)"  ></btlib-form-row>  
                  

            
                </form>

                <hr/>
            <h2>{{ 'bus.task.items' | actrans }}</h2>

            <div>
              <nz-table 
                #nzTableLog
                [nzData]="task.items || []" 
                nzTableLayout="fixed" 
                [nzShowPagination]="true" 
                nzSize="small"
                class="nz-table-xs"
                >
                <thead>
                  <tr>
                
                    <th>{{ 'common.title' | actrans }}</th>
                    <th nzWidth="30px"></th>
                    <th nzWidth="30px"></th>
              
                  </tr>
                </thead>
                <tbody>
                  <ng-container  *ngFor="let data of nzTableLog.data; let idx = index">
                    <tr >
            
                      <td >{{ data.title }}</td>
                      <td>
                        <i nz-icon class="cursor-pointer" nzType="delete" 
                        nz-popconfirm
                        [nzPopconfirmTitle]="'app.lib.common.popconfirm.confirm' | actrans | str"  
                        [title]="'common.delete' | actrans"
                        (nzOnConfirm)="deleteTaskItem(idx)" [nzTheme]="'twotone'" [nzTwotoneColor]="'#eb2f96'"></i>
                      </td>
                      <td>

                      </td>
                    </tr>
              
                  </ng-container>
                </tbody>
                <tfoot>
                  <tr >
                    <td colspan="3">
                      <nz-input-group nzAddOnAfterIcon="save">
                        <input type="text" nz-input [(ngModel)]="add.task.taskItemTitle" />
                      </nz-input-group>

                      <nz-input-group [nzAddOnAfter]="suffixIconButton">
                        <input type="text" nz-input placeholder="nytt element" />
                      </nz-input-group>
                      <ng-template #suffixIconButton>
                        <button nz-button nzType="primary" (click)="addNewTaskItem()"><span nz-icon nzType="search"></span></button>
                      </ng-template>
                    </td>

                   
                  </tr>
                </tfoot>
              </nz-table>



            </div>
    

              </ng-template>
     
            </nz-modal>

          </div> -->

          
          <div id="section-log" *ngIf="isSectionVisible('log')">
            <hr/>
            <h2>{{ 'common.log' | actrans }}</h2>

            <div>
              <nz-table 
                #nzTableLog
                [nzData]="p.logs || []" 
                nzTableLayout="fixed" 
                [nzShowPagination]="true" 
                nzSize="small"
                class="nz-table-xs"
                >
                <thead>
                  <tr>
                
                    <th *ngIf="apps.bas.ui.r.gtesm"  [nzWidth]="apps.bas.ui.r.gtesm ? '100px' : '80px'">{{ 'common.time' | actrans }}</th>
                    <th *ngIf="apps.bas.ui.r.gtelg" nzWidth="120px">{{ 'common.user' | actrans }}</th>
                    <th *ngIf="apps.bas.ui.r.gtelg" nzWidth="150px">{{ 'common.type' | actrans }}</th>
                    <th *ngIf="apps.bas.ui.r.gtelg" nzWidth="110px">{{ 'common.ipAddress' | actrans }}</th>
                    <th *ngIf="apps.bas.ui.r.gtesm">{{ 'bus.log.text' | actrans }}</th>
                    <th *ngIf="apps.bas.ui.r.ltexs">{{ 'common.log' | actrans }}</th>
                    <th nzWidth="30px"></th>
                    <th nzWidth="30px"></th>
              
                  </tr>
                </thead>
                <tbody>
                  <ng-container  *ngFor="let data of nzTableLog.data; let idx = index">
                    <tr >
                      <td *ngIf="apps.bas.ui.r.gtesm">{{ data.time }}</td>
                      <td *ngIf="apps.bas.ui.r.gtelg">{{ data.creator }}</td>
                      <td *ngIf="apps.bas.ui.r.gtelg">{{ data.mkType }}</td>
                      <td *ngIf="apps.bas.ui.r.gtelg">{{ data.ipAddress }}</td>
                      

                      <td *ngIf="apps.bas.ui.r.gtesm"> 
                        <div *ngIf="apps.bas.ui.r.ltemd">
                          <strong>{{ 'common.user' | actrans }}</strong> {{ data.creator }}<br/>
                          <strong>{{ 'common.type' | actrans }}</strong>: {{ data.mkType }}<br/>
                          <strong>{{ 'common.ipAddress' | actrans }}</strong>: {{ data.ipAddress }}
                          <hr/>
                        </div>
                        <div [innerHtml]="data.textOutput | trusthtml"></div>
                      </td>
                      
                     
                      <td *ngIf="apps.bas.ui.r.ltexs">
                        <div><strong>{{ 'common.time' | actrans }}</strong>: <span>{{ data.time }}</span></div>
                        <div *ngIf="apps.bas.ui.r.ltemd">
                          <strong>{{ 'common.user' | actrans }}</strong> {{ data.creator }}<br/>
                          <strong>{{ 'common.type' | actrans }}</strong>: {{ data.mkType }}<br/>
                          <strong>{{ 'common.ipAddress' | actrans }}</strong>: {{ data.ipAddress }}
            
                        </div>
                        <hr/>
                        <div [innerHtml]="data.textOutput | trusthtml"></div>
                      </td>
                      <td>
                        <i nz-icon class="cursor-pointer" nzType="delete" 
                        nz-popconfirm
                        [nzPopconfirmTitle]="'app.lib.common.popconfirm.confirm' | actrans | str"  
                        [title]="'common.delete' | actrans"
                        (nzOnConfirm)="deleteItem(idx, 'log')" [nzTheme]="'twotone'" [nzTwotoneColor]="'#eb2f96'"></i>
                      </td>
                      <td>
                        <i nz-icon class="cursor-pointer"nzType="edit" (click)="editItem(idx, 'log')" ></i>
                      </td>
                    </tr>
              
                  </ng-container>
                </tbody>
              </nz-table>

            </div>

            <!-- [nzComponentParams]="{ item: edit.log.obj }" -->
            <nz-modal [nzVisible]="!!edit.log.obj" nzTitle="{{ 'common.log' | actrans | str }}" nzWidth="95vw" [nzContent]="modalContentItem" (nzOnCancel)="edit.log.obj = null" (nzOnOk)="saveItem('log')">
              <ng-template #modalContentItem let-params>

  
                <form nz-form [formGroup]="edit.log.form" class="item-form mt-d" nzLayout="vertical" [nzAutoTips]="apps.bas.fs.autoTips">

                  <btlib-form-row [controls]="[ edit.log.controls.time ]" [form]="edit.log.form"  [grid]="gridMd" (change)="onChange($event)"  ></btlib-form-row>  
                  <btlib-form-row [controls]="[ edit.log.controls.text ]" [form]="edit.log.form"  [grid]="gridXl"  (change)="onChange($event)" ></btlib-form-row>  
                  
                </form>
    

              </ng-template>
     
            </nz-modal>

          </div>


              
          <hr/>
          <nz-form-item [formGroup]="productForm" >
            <nz-form-control>
              
              <textarea nz-input
                [placeholder]="'bus.log.text' | actrans | str"
                autoGrow
                [style.height.px]="60"
                id="logText"  
                formControlName="logText"
            
              ></textarea>

       
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-control>
              <button nz-button nzType="primary">
                {{ 'app.lib.common.save' | actrans }} 
                {{ 'common.product' | actrans | str | lowercase }}
              </button>
            </nz-form-control>
          </nz-form-item>

        </form>

        </div>

      </div>




    </div>
  </nz-spin>


</btlib-admin-page-wrapper>

