import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { MiscUtil } from 'src/app/lib/core/util/misc.util';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-common-empty-page',
  templateUrl: './common-empty-page.component.html',
  styleUrls: ['./common-empty-page.component.scss']
})
export class CommonEmptyPageComponent implements OnInit {

  constructor(public injector:Injector, public apps:AppService) {
    let route = injector.get(ActivatedRoute);
    this.apps.bas.us.checkActivatedRoute(route.snapshot);
  
  }

  ngOnInit(): void {
    
  }

}
