import { Component, Input, OnInit } from '@angular/core';
import { MiscUtil } from 'src/app/lib/core/util/misc.util';

import { AppService, APP_DATAID } from 'src/app/services/app.service';
import { DATAID } from 'src/app/lib/core/services/data.service';

@Component({
  selector: 'bt-page-wrapper',
  templateUrl: './page-wrapper.component.html',
  styleUrls: ['./page-wrapper.component.scss']
})
export class PageWrapperComponent implements OnInit {

  // @Input()
  // showMenu:boolean = true;

  operatingMessages:any = "";
  
  constructor(public apps:AppService) {
  
    this.apps.bas.ds.find(DATAID.APP_WEB_OPERATING_MESSAGES).subscribe((oms:any) => {
      // console.log("APP_WEB_OPERATING_MESSAGES: ", oms);
      this.operatingMessages = oms.message || "";
    })
    
  }

  ngOnInit(): void {

  }


}


