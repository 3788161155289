import { Injector } from '@angular/core';

import { Observable } from 'rxjs';
import { BooktechAppService } from './booktech-app.service';

// import { Platform } from '@ionic/angular';

import { MiscUtil } from '../util/misc.util';
import { BtEvent } from './event.service';


export enum HookType {
  HookNoop,
  TestHook,

  PushNotificationReceived,
  ShowUserProfileMenu,
  LoginSuccess,

  SavePasswordSuccess,

  WebApiRequest,

  WebGotoRequest,

}


export class Hook {
  type:HookType = HookType.HookNoop;
  priority?:number = 100;
  handler: ((params:any) => Promise<boolean>) = () => Promise.resolve(true);
}

export class HookService {

  // private platform: Platform;

  private hooks:{ [index: number]: { [index: number]: Hook } } = { };

  constructor(public injector:Injector, public bas:BooktechAppService) {
    if(this.bas.envtest) console.log('HookService');

    // this.platform = injector.get(Platform);
// 
    //this.hookTest();
    

    
  }

  on(type:HookType, handler: (params:any) => Promise<boolean>, priority:number = 100) {
    if (!this.hooks[type]) this.hooks[type] = { }; 
    if (this.hooks[type][priority]) {
      if(this.bas.envtest) console.log("HookService: WARN: Replacing existing hook with type '" + type + "' and priority " + priority);
    }
    if(this.bas.envtest) console.log("HookService: Adding HOOK, type: " + type + ", priority: " + priority);
    this.hooks[type][priority] = { 
      type: type,
      priority: priority,
      handler: handler
    }
  }

  async trigger(type:HookType, params:any):Promise<boolean> {
    return new Promise<boolean>(async (res, rej) => {
      if(this.bas.envtest) console.log("HookService.trigger: " + type + " -> ", params);
      if (!this.hooks[type]) return Promise.resolve(false);
      let map = this.hooks[type];
      let priorites = Object.keys(map).map(Number);
      priorites = priorites.sort((a, b) => a - b);

      let triggerRes = true;

      for (let p of priorites) {
        let hook = map[p];
        // if(this.bas.envtest) console.log("p: " + p + ", typeof: " + (typeof p)); 
    
        let hres = await hook.handler(params);
        if(this.bas.envtest) console.log("p: " + p + ", priorites.length: " +priorites.length+",  hres: " + hres);
        if (hres === true) {
          if(this.bas.envtest) console.log("hres is true, skipping rest of hooks");
          continue;
        } else {
          if(this.bas.envtest) console.log("hres is false, skipping rest of hooks");
          triggerRes = false;
          break;
        }
      }

      this.bas.es.trigger(BtEvent.APP_HOOK_COMPLETE, { type: type, params : params, result: triggerRes })
      return res(triggerRes);
    });
    
  }


  noop():Promise<boolean> {
    return new Promise<boolean>((res, rej) => {
      res(true);
    });
  }


  // TEST
  hookTest() {

    this.on(HookType.TestHook, (params) => {
      return new Promise<boolean>((res, rej) => {
        if(this.bas.envtest) console.log("TestHook.100");
        setTimeout(() => {
          if(this.bas.envtest) console.log("TestHook.100.resolve(true)");
          res(true);
        }, 2000)
      })
    });

    this.on(HookType.TestHook, (params) => {
      return new Promise<boolean>((res, rej) => {
        if(this.bas.envtest) console.log("TestHook.80");
        // setTimeout(() => {
          if(this.bas.envtest) console.log("TestHook.80.resolve(false)");
          res(false);
        // }, 1000)
      })
    }, 80);

    this.on(HookType.TestHook, (params) => {
      return new Promise<boolean>((res, rej) => {
        if(this.bas.envtest) console.log("TestHook.120");
        setTimeout(() => {
          if(this.bas.envtest) console.log("TestHook.120.resolve(true)");
          res(true);
        }, 2000)
      })
    }, 120);

    this.on(HookType.TestHook, (params) => {
      return new Promise<boolean>((res, rej) => {
        if(this.bas.envtest) console.log("TestHook.20");
        // setTimeout(() => {
          if(this.bas.envtest) console.log("TestHook.20.resolve(true)");
          res(true);
        // }, 4000)
      })
    }, 20);

    this.on(HookType.TestHook, (params) => {
      return new Promise<boolean>((res, rej) => {
        if(this.bas.envtest) console.log("TestHook.100.new");
        setTimeout(() => {
          if(this.bas.envtest) console.log("TestHook.100.new.resolve(true)");
          res(true);
        }, 4000)
      })
    }, 100);



    setTimeout(() => {
      this.trigger(HookType.TestHook, { test: "abc"}).then((res) => {
        if(this.bas.envtest) console.log("Trigger response: " + res);
      });
    }, 4000);
    
  }

 
}
