import { Component, Injector, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { MiscUtil } from 'src/app/lib/core/util/misc.util';
import { AppService } from 'src/app/services/app.service';
import { DynformControl } from '../../../../../lib/core/model/dymform-control';

import { NzUploadChangeParam, NzUploadFile } from 'ng-zorro-antd/upload';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-app-bileasy-lead-root-page',
  templateUrl: './app-bileasy-lead-root-page.component.html',
  styleUrls: ['./app-bileasy-lead-root-page.component.scss']
})
export class AppBileasyLeadRootPageComponent implements OnInit {

  step = 0;
  processing = false;
  qps:any = { };
  edit:any = {
    showEula: false,

    obj: {
      leadType: undefined,

    },
    fileList: []
  }

  constructor(public injector:Injector, public apps:AppService) {
    let route = injector.get(ActivatedRoute);
    this.apps.bas.us.checkActivatedRoute(route.snapshot);

    let fbo = this.apps.bas.ds.config.appConfig?.fbo;
    if (!fbo) {
      // TODO: refresh etter at configen er oppdatert.
      console.log("fbo == null, ac: ", this.apps.bas.ds.config);
      return;
    }
  
    this.newLead();

    let lt:any = undefined;
    this.qps = route.snapshot.queryParams;
    if (this.qps.leadType) {
      lt =  this.apps.bas.us.listToMap(this.apps.bas.ds.config.appConfig.fbo.leadTypes, "enumName")[this.qps.leadType];
      // console.log("lt: ", lt);
      
    }
    // if (!lt) lt = { id: 1 };
    if (lt) this.setLeadTypeId(undefined, lt.id);
  }

  ngOnInit(): void {
    
  }

  newLead() {
    this.editObject({
      uuid: MiscUtil.generatePassord(32),
      make: "",
      model: "",
      equity: 0
     });
  }

  onIndexChange(index: number): void {

    if (index > this.step) return;
    // if (this.step >= 3) return;  // TODO
    this.gotoStep(index, "onIndexChange");
  }

  getBaseNativeUrl() {
    // if (this.apps.bas.envprod) {
    //   return "https://cbapi5.booktech.app"
    //     + "/api/bruktbilklubben"
    //   ;
    // }
    return "https://" 
      + ( this.apps.bas.envtest ? "test." : "" )
      + "bilmatch.bruktbilklubben.no"
      + "/api/bruktbilklubben"
    ;
  }

  setLeadTypeId(event:any, lt:number) {

  
    if (event) {
      event.preventDefault(); 
    }

    let fbo = this.apps.bas.ds.config.appConfig.fbo;
    let leadType = this.apps.bas.us.listToMap(fbo.leadTypes)[lt];

    // if (leadType.enumName == "Simple") {
    //   this.apps.bas.ui.modalError("Under utvikling", "Denne funksjonen er under oppdatering, bruk gjerne bestemt modell, er du åpen for flere modeller, skriv dette i kommentarfeltet.");
    //   return;
    // }

    let isAndroid = /Android/i.test(window.navigator.userAgent);
   
    // if (this.apps.bas.envdev && !isAndroid) isAndroid = /Firefox/i.test(window.navigator.userAgent);
    if (event && isAndroid) {
      this.apps.bas.es.sendParentMessageRedir(
        this.getBaseNativeUrl() 
        + "/app/bileasy/lead?leadType=" 
        + leadType.enumName
        + "&standalone=true"
      );
      return true;
    }

    this.edit.form.controls.step0.controls.leadTypeId.setValue(lt);
    this.edit.leadType = leadType;

    if (leadType.enumName == "Simple") {
      // this.edit.form.controls.step0.controls.carTypeId.setValue(1);
      // this.edit.carType = this.apps.bas.us.listToMap(fbo.carTypes)[1]; // Elektrisk

    }

    return true;
  }


  nextStep(src:string = "none") {

    if (this.apps.bas.envtest) console.log("nextStep, step: " + this.step + ", src: " + src);
    let currStep = this.step;
    let nextStep = currStep + 1;

    // if (currStep == 0) {
    //   let selected = this.updateStep0selected();

    //   // if (selected < 0.5) return false;
    // }

    let formGroup = this.edit.form.controls["step" + currStep];
    let controls = this.edit.controls["step" + currStep].childMap;

    let valid = this.apps.bas.fs.validateForm(formGroup);
    let rv = this.apps.bas.fs.getRawValue(formGroup, controls, true);
    
    
    if (!valid) {
      
      this.apps.bas.es.sendParentMessageScroll();
      if (this.apps.bas.envtest) console.log("nextStep, valid: " + valid + ", fg: ", formGroup, ", constrols: ", controls, ", rv: ", rv);
      return false;
    }

    
  

    this.gotoStep(nextStep, "nextStep");
    return true;
  }
  prevStep(src:string = "none") {
    this.gotoStep(this.step - 1, "prevStep: " + src);
  }

  gotoStep(step:number, src:string) {
    if (this.apps.bas.envtest) console.log("gotoStep, step: " + step + ", src: " + src);
    this.step = step;
    this.apps.bas.es.sendParentMessageScroll();
  }


  uploadHeaders = (file: NzUploadFile): Object => {
    let headers = this.apps.bas.ws.getCommonHeaders();
    //MiscUtil.extend(headers, this.apps.bas.ws.getLoginHeaders());
    return headers;
  }
  handleUploadFile({ file, fileList }: NzUploadChangeParam): void {

    const status = file.status;
    const resp = file.response;
    if (status !== 'uploading') {
      if (this.apps.bas.envtest) console.log(file, fileList);

      if (status == "removed") {
        let itemId = resp.itemId;

        this.apps.bas.ws.json({ 
          checkChildApp: true,
          aType: "customAction", 
          action: "removeLeadFile", 
          itemId: itemId
        
        }).then((json) => {
          if (this.apps.bas.envtest) console.log("removeLeadImage, json: ", json);
          if (json.success) {
          
          }
        });

      }
    }
    if (status === 'done') {
     
      

      if (this.apps.bas.envtest) console.log(file.name + 'file uploaded successfully, resp: ', resp);

      if (resp.itemId) {
        //TODO
      } else {
        this.apps.bas.ui.error(this.apps.bas.ui.actrans("common.error.upload.general", [], false, "En feil oppstod da vi skulle behandle filen du lastet opp"));
      }

    } else if (status === 'error') {
      if (this.apps.bas.envtest) console.log(`${file.name} file upload failed.`);
      // TODO
      // this.apps.bas.ui.error(this.apps.bas.ui.actrans("common.error.imageUpload", [], false, "En feil oppstod da vi skulle behandle filen du lastet opp. Vanligvis er dette fordi du har lastet opp en JPEG fil i CMYK-format. Kun JPEG bilder i RGB-format kan lastes opp")); //TODO:text
      
    }
  }

  onKeydownEnter() {
    // console.log("onKeydownEnter");
  }

  updateStep0selected() {
    
    let selected = 0;
    let controls = this.edit.form.controls.step0.controls;
    for (const key in controls) {
      const control = controls[key];
      let isPrestine = control.pristine;
      
      // console.log("key: " + key + ", isPrestine: " + isPrestine);
      if (!isPrestine) {
        selected++;
      }
    }
    // console.log("selected: " + selected); 
    return selected;
  }

  formSubmit() {

    // console.log("formSubmit")
    let rv = this.apps.bas.fs.getRawValue(this.edit.form, this.edit.controls);

    if (rv === false) return;


    let lead:any = { };
    for (let step of Object.values(rv)) {
      MiscUtil.extend(lead, step);
    }
    if (this.apps.bas.envtest) console.log("formSubmit, rv: ", rv, ", lead: ", lead);

    if (this.edit.leadType?.enumName == 'Simple') {
      lead.priceMinInterval = undefined;
    } else {
      lead.priceAsRange = undefined;
    }


    this.processing = true;

    this.apps.bas.ws.json({ 
      checkChildApp: true,
      aType: "customAction", 
      action: "saveLead", 
      jsonData: JSON.stringify({ lead: lead })
    
    }).then((json) => {
      this.processing = false;

      if (this.apps.bas.envtest) console.log("saveLead, json: ", json);
      if (json.success) {

        this.nextStep();
        if (this.apps.bas.envprod) this.newLead();
        this.edit.form.controls.step0.controls.uuid.setValue(MiscUtil.generatePassord(32));

        this.apps.bas.es.sendParentMessageRedir("https://www.bruktbilklubben.no/ferdig")

      }
    }).catch(err => {
      this.processing = false;

    });

  }




  editObject(obj:any) {

    if (this.apps.bas.envtest) console.log("editObject, obj: ", obj);

    // if  (this.edit.form ) {
    //   let form = this.edit.form
    //   this.edit.form.reset();
    // }
    
    let ado = this.apps.bas.ds.config.appConfig.appDataObj;
    let fbo = this.apps.bas.ds.config.appConfig.fbo;

    let controls: DynformControl[] = [];
    let steps: { [index: string]: DynformControl[] } = { };

    for (let idx = 0; idx < 4; idx++) {
      let gcStep =  new DynformControl({ 
        key: 'step' + idx,
        controlType: 'formGroup',
        children: [ ]
      });

      controls.push(
        gcStep
      )

      steps[idx] = gcStep.children;
    }


    let valSimpleRequired =  
    (control: AbstractControl): { [s: string]: boolean } => {
      let fc = control as UntypedFormControl;
      
      if (fc.value !== undefined) return { };

      let isSimple = this.edit.leadType?.enumName == 'Simple';
      // console.log("valSimpleRequired, isSimple: " + (isSimple));


      if (isSimple) return  { error: true, required: true };
      return { };
    };

    let valElectricRequried = 
      (control: AbstractControl): { [s: string]: boolean } => {
        let fc = control as UntypedFormControl;
        
        if (fc.value !== undefined) return { };

        let isSimple = this.edit.leadType?.enumName == 'Simple';
        let isElectric = this.edit.carType?.enumName == 'Electric';
        // console.log("valElectricRequried, isSimple && isElectric: " + (isSimple && isElectric));
        
        if (isSimple && isElectric) return  { error: true, required: true };
        return { };
      }
    ;

    let valsElectric = [ valElectricRequried ];

    let valFossilRequried = 
      (control: AbstractControl): { [s: string]: boolean } => {
        let fc = control as UntypedFormControl;
        
        if (fc.value !== undefined) return { };

        let isSimple = this.edit.leadType?.enumName == 'Simple';
        let isFossil = this.edit.carType?.enumName == 'Fossil';
        // console.log("valFossilRequried, isSimple && isFossil: " + (isSimple && isFossil));
        
        if (isSimple && isFossil) return  { error: true, required: true };
        return { };
      }
    ;
    let valsFossil = [ valFossilRequried ];
    // let valsElectricFossil = [ valElectricRequried, valFossilRequried ];

    let valsCommercialRequried = 
      (control: AbstractControl): { [s: string]: boolean } => {
        let fc = control as UntypedFormControl;
        
        if (fc.value !== undefined) return { };

        let isSimple = this.edit.leadType?.enumName == 'Simple';
        let isCommercial = this.edit.carType?.enumName == 'Commercial';

        // console.log("valsCommercialRequried, isSimple && isCommercial: " + (isSimple && isCommercial));
        if (isSimple && isCommercial) return  { error: true, required: true };
        return { };
      }
    ;
    let valsCommercial = [ valsCommercialRequried  ];
    // let valsFossilCommercial = [ valFossilRequried, valsCommercialRequried  ];

    let valsAdvancedRequried = 
      (control: AbstractControl): { [s: string]: boolean } => {
        let fc = control as UntypedFormControl;
        
        if (fc.value !== undefined && fc.value !== "") return { };

        let isAdvanced = this.edit.leadType?.enumName == 'Advanced';
 
        // console.log("valsAdvancedRequried, isAdvanced: " + isAdvanced);
        if (isAdvanced) return  { error: true, required: true };
        return { };
      }
    ;
    let valsAdvanced = [ valsAdvancedRequried ];

    steps["0"].push(new DynformControl({ key: 'leadTypeId',     mk: 'Type' }));
    steps["0"].push(new DynformControl({ key: 'uuid' }));

    steps["0"].push(new DynformControl({ 
      key: 'carTypeId',     
      mk: 'Biltype',
      controlType: 'radio-button',
      radioButtonStyle: "solid", 
      // disabled: true,
      data: {  },
      options: () => fbo.carTypes,
      onChange: (event) => {
        // console.log("carType.onChange: ", event);
        this.edit.carType = this.apps.bas.us.listToMap(fbo.carTypes)[event.value];

        let isElectric = this.edit.carType.enumName == "Electric";
        let isCommercial = this.edit.carType.enumName == "Commercial";

        if (isElectric || isCommercial) {
          let towbar = this.edit.controls.step0.childMap.towbar;
          towbar.mkInfo = isElectric ? "Odd inge kommer tilbake med tekst her!" : "";  //TODO
          let trailerWeight = this.edit.controls.step0.childMap.trailerWeight;
          //console.log("trailerWeight: ", trailerWeight);
          trailerWeight.data.min = isElectric ? 0 : 750;
          trailerWeight.data.max = isElectric ? 2200 : 3500;
          
        }
        

      },
      optionsFieldValue: "id",
      optionsFieldLabel: "name",
      validators: [  valSimpleRequired ]

    }));

    // Elbil
    steps["0"].push(new DynformControl({ 
      key: 'minRange',     
      mk: 'Minimum rekkevidde i km (WLTP)',
      mkInfo: "WLTP står for «Worldwide Harmonized Light Vehicles Test Procedure» og regnes i dag som en av de mest pålitelige målemetodene for rekkevidde på elbiler, både ved blandet kjøring og ved bykjøring. WLTP-tallet som opplyses i spesifikasjonslistene, gjelder stort sett alltid for blandet kjøring.",
      controlType: 'slider',
      // required: true,
      data: {
        min: 10,
        max: 400,
        step: 10
      },
      validators: valsElectric
    }));



    steps["0"].push(new DynformControl({ 
      key: 'wheelDriveId',     
      mk: 'Hjuldrift',
      controlType: 'radio-button',
      radioButtonStyle: "solid", 
      data: {  },
      options: () => fbo.wheelDrives,
      optionsAllowEmpty: true,
      optionsEmptyLabel: "Alle",
      optionsFieldValue: "id",
      optionsFieldLabel: "name",
      // validators: valsElectric
     
    }));
   
    steps["0"].push(new DynformControl({ 
      key: 'maxAge',      
      mk: 'Maks alder',
      controlType: 'slider',
      // required: true,
      data: {
        min: 0,
        max: 20,
        step: 1,
        suffix: "år"
      },
      validators: [ valSimpleRequired ]
    }));
    
    steps["0"].push(new DynformControl({ 
      key: 'mileageMax',      
      mk: 'Maks km stand',
      controlType: 'slider',
      // required: true,
      data: {
        min: 0,
        max: 200000,
        step: 10000,
        // css: "fi-slider-handle-lg"
      },
      // validators: [ valSimpleRequired ]
    }));
    

    steps["0"].push(new DynformControl({ 
      key: 'carSizeIds',     
      mk: 'Størrelse',
      // controlType: 'radio-button',
      // radioButtonStyle: "solid", 
      
      placeholder: "Velg størrelse på kjøretøyet",
      controlType: 'tags',
      // selectMode: 'multiple',
      data: { 
        // backdrop: true
        // selectAll: true
      },
      options: () => fbo.carSizes,
      optionsFieldValue: "id",
      optionsFieldLabel: "name",
      onChange: (event) => {
        event.control.data.open = false;

      }
     
    }));

    // Fossil


    // Fossil / varebil / advanced

    steps["0"].push(new DynformControl({ 
      key: 'transmissionId',     
      mk: 'Girkasse',
      controlType: 'radio-button',
      radioButtonStyle: "solid", 
      data: {  },
      options: () => fbo.transmissions,
      optionsAllowEmpty: true,
      optionsEmptyLabel: "Alle",
      optionsFieldValue: "id",
      optionsFieldLabel: "name"
     
    }));

    let propulsionsAll = fbo.propulsions as any[];
    let propulsionsFossil = propulsionsAll.filter(item => !item.zeroEmissions);

    steps["0"].push(new DynformControl({ 
      key: 'propulsionIds',     
      mk: 'Drivstoff',
      placeholder: "Velg drivstoff",
      controlType: 'tags',
      // selectMode: 'multiple',
      data: {  },
      options: () => { 
        return this.edit.carType?.enumName === "Fossil" ? propulsionsFossil : propulsionsAll;
      },
      optionsFieldValue: "id",
      optionsFieldLabel: "name",
      onChange: (event) => {
        event.control.data.open = false;
      }
     
    }));

    steps["0"].push(new DynformControl({  // 
      key: 'chassisIds',     
      mk: 'Karosseri',
      placeholder: "Velg karosseri",
      controlType: 'tags',
      // selectMode: 'multiple',
      data: { },
      options: () => fbo.chassiss,
      optionsFieldValue: "id",
      optionsFieldLabel: "name",
      onChange: (event) => {
        event.control.data.open = false;
      }
     
    }));


    // Varebil



    steps["0"].push(new DynformControl({ 
      key: 'minPayload',      
      mk: 'Minumum nyttelast',
      controlType: 'slider',
      data: {
        min: 0,
        max: 2000,
        step: 100
      }
    }));
   

    // steps["0"].push(new DynformControl({ 
    //   key: 'carOptionIds',     
    //   mk: 'Andre valg', 
    //   placeholder: 'Andre ønsker',
    //   controlType: 'select',
    //   selectMode: 'multiple',
    //   data: { },
    //   options: () => fbo.carOptions,
    //   optionsFieldValue: "id",
    //   optionsFieldLabel: "name"
     
    // }));

    
    // steps["0"].push(new DynformControl({ 
    //   key: 'compartmentDoors',      
    //   mk: 'Antall dører varerom',
    //   controlType: 'slider',
    //   data: {
    //     min: 0,
    //     max: 4,
    //     step: 1
    //   }
    // }));


    // Avansert
 
    let makes = ado.makes || [ ];
    let models = ado.models || { };


    let makesOptions = makes.map((val:any) => { return  { value: val }});
    let modelsOptions:any = { };
    for (let make of makes) {
      // console.log("make: " + make + ", models: " + models[make]);
      modelsOptions[make] = (models[make] || []).map((val:any) => { return  { value: val }});
      modelsOptions[make].push({ value: "Andre" });
    }
  

    steps["0"].push(new DynformControl({ 
      key: 'make',     
      mk: 'Merke',
      placeholder: "Velg merke",
      controlType: 'select',

      options: () => makesOptions,
      optionsAllowEmpty: true,
      optionsFieldValue: "value",
      optionsFieldLabel: "value",
      validators: valsAdvanced

    }));
    steps["0"].push(new DynformControl({ 
      key: 'model',     
      mk: 'Modell',
      placeholder: "Velg modell",
      controlType: 'select',

      show: () => {
        return  this.edit.form.controls.step0.controls.make.value;
      },
      options: () => {
        let make = this.edit.form.controls.step0.controls.make.value;
        if (!make) return [];
        return modelsOptions[make] || [];
      },
      optionsAllowEmpty: true,
      optionsFieldValue: "value",
      optionsFieldLabel: "value",
      validators: valsAdvanced

    }));


    steps["0"].push(new DynformControl({ 
      key: 'yearAsRange',     
      mk: 'Årsmodell',
      // controlType: 'number-range',
      controlType: 'slider-range',
      required: false,
      data: {
        min: 2000,
        max: new Date().getFullYear(),
        numberFormatter: false
      }
    }));
    steps["0"].push(new DynformControl({ 
      key: 'mileageAsRange',     
      mk: 'Km stand',
      // controlType: 'number-range',
      controlType: 'slider-range',
      required: false,
      data: {
        min: 0,
        max: 200000,
        step: 10000,
      }
    }));


    // Alle

    
    steps["0"].push(new DynformControl({ 
      key: 'comment',     
      mk: 'Kommentar, ønsker og andre opplysninger', 
      placeholder: "Utstyr, farge, spesielle ønsker o.l",
      controlType: "textarea",
      validators: [  ]  // valFossilRequried
     }));

    steps["0"].push(new DynformControl({ 
      key: 'towbar',     
      mk: 'Hengerfeste?', 
      //mkInfo: 'Odd inge kommer tilbake med tekst her!',
      controlType: "checkbox", 
      required: false, 
    }));

    steps["0"].push(new DynformControl({ 
      key: 'priceAsRange',     
      mk: 'Ønsket prisområde (fra-til)',
      // controlType: 'number-range',
      controlType: 'slider-range',
      required: false,
      data: {
        min: 50000,
        max: 1000000,
        step: 50000,

        sliderInputMin: 50000,
        sliderInputMax: 1000000
      }
    }));

    let sliderSpan = this.apps.bas.ui.r.gtesm ? 16 : 12;
    // console.log("sliderSpan: " + sliderSpan + ", r: ", this.apps.bas.ui.r )
    steps["0"].push(new DynformControl({ 
      key: 'priceMinInterval',     
      mk: 'Ønsket prisområde (fra-til)',
      // controlType: 'number-range',
      controlType: 'slider',
      required: false,
      data: {
        min: 0,
        max: 1000000,
        step: 100000,
        sliderSpan: sliderSpan,
        // sliderInputMin: 100000,
        // sliderInputMax: 1000000

        numberFormatter:  (value: number): string => {
          if (value === undefined) return "";
          return this.apps.bas.ui.nf(value, 0) + " - " + this.apps.bas.ui.nf(value + 100000, 0);

        },
        numberParser:  (value: string): string => {
          if (value === undefined) return "";
          value = value.replace(" ", "");
          return parseFloat(value) + "";

        }
      }
    }));

    steps["0"].push(new DynformControl({ 
      key: 'trailerWeight',     
      mk: 'Tilhengervekt (kg)',
      controlType: 'slider',
      required: false,
      data: {
        min: 750,
        max: 3500,
        step: 50
      },
      show: (control) => {
        return this.edit.form.controls.step0.controls.towbar.value
      }
    }));
   
    

    // Innbyttebil      -- Ja eller Nei
    // steps["1"].push(new DynformControl({ key: 'tradein',     mk: 'Har du innbytte bil?', controlType: "checkbox", required: false, }));

    steps["1"].push(new DynformControl({ 
      key: 'tradein',     
      mk: 'Har du innbytte bil?',
      controlType: 'radio-button',
      radioButtonStyle: "solid", 
      required: true,
      data: { },
      options: () => fbo.yesNos,
      // optionsFieldValue: "id",
      optionsFieldLabel: "name",
      onChange : (event) => {
        if (!event.value) this.nextStep();
      }
     
    }));

    let tradeinValidators = [
      (control: AbstractControl): { [s: string]: boolean } => {
        let fc = control as UntypedFormControl;
        
        if (fc.value !== undefined) return { };

        let isTradein = this.edit.form.controls.step1.controls.tradein.value;
        if (isTradein) return  { error: true, required: true };
        return { };
      }
    ];

    steps["1"].push(new DynformControl({ 
      key: 'tradeinLicenseNumber',     
      mk: 'Regnr', 
      data: {
        min: 7,
        max: 7
      },
      validators: [
        (control: AbstractControl): { [s: string]: boolean } => {
          let fc = control as UntypedFormControl;
          
          if (fc.value !== undefined) return { };

          let isTradein = this.edit.form.controls.step1.controls.tradein.value;
          if (!isTradein) return  { };

          if (fc.value && fc.value.length != 7) return  { error: true, required: true };
  
          return  { };
          //return { };
        }
      ],
      // cssClass: "",
      onChange: (event) => {
        // console.log("tradeinLicenseNumber, change: ", event);
        // hente info fra AutoSys

        // if (event.value.length == 7) {
        

        this.apps.bas.ws.json({ 
          checkChildApp: true,
          aType: "customAction", 
          action: "autosysLookup", 
          kjennemerke: event.value
        
        }).then((json) => {
          // console.log("autosysLookup, json: ", json);
          if (json.success) {

            this.edit.form.controls.step1.controls.tradeinMakeAndModel.setValue(json.handelsbetegnelse);
            this.edit.form.controls.step1.controls.tradeinYear.setValue(json.year + "");
          }
        });
  
      // } else if (event.value.length != 0) {

      // }

      }
    }));
    // TODO: validate, eller hva med vanity plates? 
    
    steps["1"].push(new DynformControl({ 
      key: 'tradeinMakeAndModel',     
      mk: 'Merke og modell', 
      validators: tradeinValidators 
    }));


    steps["1"].push(new DynformControl({ 
      key: 'tradeinMileage',     
      mk: 'Kilometerstand',
      controlType: 'slider',
      // type: 'number',
      data: { 
        min: 0, 
        step: 1000,
        max: 500000
      },
      validators: tradeinValidators
    }));

    steps["1"].push(new DynformControl({ 
      key: 'tradeinYear',     
      mk: 'Registreringsår',
      controlType: 'input',
      type: 'number',
      data: { 
        min: 0, 
        numberFormatter: false 
      },
      validators: tradeinValidators
    }));



    // steps["1"].push(new DynformControl({ 
    //   key: 'tradeinImported',     
    //   mk: 'Bruktimportert',
    //   controlType: 'radio-button',
      // radioButtonStyle: "solid", 
    //   data: { },
    //   options: () => fbo.yesNos,
    //   optionsAllowEmpty: true,
    //   optionsEmptyLabel: "Vet ikke",
    //   // optionsFieldValue: "id",
    //   optionsFieldLabel: "name",
    //   validators: tradeinValidators
     
    // }));

    // steps["1"].push(new DynformControl({ 
    //   key: 'tradeinSeriousIssues',     
    //   mk: 'Har bilen problemer med motor, drivverk eller lignende?',
    //   controlType: 'radio-button',
      // radioButtonStyle: "solid", 
    //   // required: true,
    //   data: { },
    //   options: () => fbo.yesNos,
    //   // optionsFieldValue: "id",
    //   optionsFieldLabel: "name",
    //   validators: tradeinValidators
     
    // }));
    
    steps["1"].push(new DynformControl({ 
      key: 'tradeinPreviousCollisionDamage',     
      mk: 'Har bilen din tidligere skader fra kollisjon?',
      controlType: 'radio-button',
      radioButtonStyle: "solid", 
      // required: true,
      data: { },
      options: () => fbo.yesNos,
      // optionsFieldValue: "id",
      optionsFieldLabel: "name",
      validators: tradeinValidators
     
    }));

    steps["1"].push(new DynformControl({ 
      key: 'tradeinConditionReport',     
      mk: 'Har du nylig fått en takst eller tilstandsrapport på bilen?',
      controlType: 'radio-button',
      radioButtonStyle: "solid", 
      // required: true,
      data: { },
      options: () => fbo.yesNos,
      // optionsFieldValue: "id",
      optionsFieldLabel: "name",
      validators: tradeinValidators
     
    }));

    steps["1"].push(new DynformControl({ 
      key: 'tradeinOtherInfo',     
      mk: 'Annet vi burde vite? For eksempel ekstrautstyr, servicehistorikk, skader, problemer etc.', 
      controlType: "textarea", 
      required: false 
    }));


    steps["1"].push(new DynformControl({ 
      key: 'tradeinSecuredLoan',     
      mk: 'Har du lån med pant i bilen?',
      controlType: 'radio-button',
      radioButtonStyle: "solid", 
      required: false,
      data: { },
      options: () => fbo.yesNos,
      optionsFieldLabel: "name",
      validators: tradeinValidators
    }));
    steps["1"].push(new DynformControl({ 
      key: 'tradeinSecuredLoanAmount',     
      mk: 'Gjennstående beløp (ca)',
      controlType: 'input',
      type: 'number',
      data: { min: 0 },
      show: () => {
        return  this.edit.form.controls.step1.controls.tradeinSecuredLoan.value;
      },
      validators: [
        (control: AbstractControl): { [s: string]: boolean } => {
          let fc = control as UntypedFormControl;
          
          if (fc.value !== undefined) return { };

          let isTradein = this.edit.form.controls.step1.controls.tradein.value;
          if (!isTradein) return  { };
  
          let isSecuredLoan = this.edit.form.controls.step1.controls.tradeinSecuredLoan.value;
          if (isSecuredLoan) return  { error: true, required: true };
          return { };
        }
      ]
    }));


    // Finansiering
    // finansieringsvalg   egenkapital i tillegg til -ønsket mnd pris


    steps["2"].push(new DynformControl({ 
      key: 'financing',     
      mk: 'Ønsker du finansiering?',
      controlType: 'radio-button',
      radioButtonStyle: "solid", 
      required: true,
     
      options: () => fbo.yesNos,
      // optionsFieldValue: "id",
      optionsFieldLabel: "name",
      onChange : (event) => {
        if (!event.value) this.nextStep();
      }
     
    }));


    
    steps["2"].push(new DynformControl({ 
      key: 'monthlyAmount',     
      mk: 'Ønsket beløp per måned',
      // controlType: 'input',
      type: 'number',
      controlType: 'slider',
      data: {
        min: 1000,
        max: 10000,
        step: 200
      },
      // required: false,
      validators: [
        (control: AbstractControl): { [s: string]: boolean } => {
          let fc = control as UntypedFormControl;
      
          let financing = this.edit.form.controls.step2.controls.financing.value;

          // console.log("Telefon, val: " + fc.value + "; typeof: " + (typeof fc.value) + "; contactTelephone: " + contactTelephone + ", val != '': " + (fc.value != "" && fc.value != undefined));
          if (fc.value != "" && fc.value != undefined) return { };

          if (financing) return  { error: true, required: true };
          return { };
        }
      ]
    }));

    steps["2"].push(new DynformControl({ 
      key: 'equity',     
      mk: 'Egenkapital i tillegg til eventuell innbyttebil',
      // controlType: 'input',
      type: 'number',
      controlType: 'slider',
      data: {
        min: 0,
        max: 300000,
        step: 10000
      },
      //required: false, //TODO: egenkapital bør bare påkrevd  
      validators: [
        (control: AbstractControl): { [s: string]: boolean } => {
          let fc = control as UntypedFormControl;
      
          let financing = this.edit.form.controls.step2.controls.financing.value;

          // console.log("Telefon, val: " + fc.value + "; typeof: " + (typeof fc.value) + "; contactTelephone: " + contactTelephone + ", val != '': " + (fc.value != "" && fc.value != undefined));
          if (fc.value !== "" && fc.value !== undefined) return { };

          if (financing) return  { error: true, required: true };
          return { };
        }
      ]
    }));




    // Steg 4

    steps["3"].push( new DynformControl({ 
      key: 'user',
      controlType: 'formGroup',
      children: [
        new DynformControl({ 
          key: 'name',     
          mk: 'Fullt navn', 
          required: true 
        }),
        new DynformControl({ 
          key: 'username',     
          mk: 'E-post', 
          required: true,
          type: "email"
        }),
        new DynformControl({ 
          key: 'telephone',     
          mk: 'Telefon', 
          required: true ,
          validators: [
            (control: AbstractControl): { [s: string]: boolean } => {
              let fc = control as UntypedFormControl;
          
              let contactTelephone = this.edit.form.controls.step3.controls.contactTelephone.value;

              // console.log("Telefon, val: " + fc.value + "; typeof: " + (typeof fc.value) + "; contactTelephone: " + contactTelephone + ", val != '': " + (fc.value != "" && fc.value != undefined));
              if (fc.value != "" && fc.value != undefined) return { };

              if (contactTelephone) return  { error: true, required: true };
              return { };
            }
          ]
        }),
        new DynformControl({ 
          key: 'zipCode',     
          mk: 'Postnr', 
          required: true,
          onChange: (event) => {
            // console.log("zipCode, change: ", event);
 
            this.apps.bas.ws.json({ 
              // checkChildApp: true,
              // aType: "customAction", 
              action: "getZipCodeCity", 
              zipCode: event.value
            
            }).then((json) => {
              // console.log("getZipCodeCity, json: ", json);
              if (json.success) {
    
                this.edit.form.controls.step3.controls.user.controls.city.setValue(json.city);
               
              }
            });
    
    
          }
        }),
        new DynformControl({ 
          key: 'city',     
          mk: 'Poststed', 
          // controlType: 'label'
        }),
        new DynformControl({ 
          key: 'acceptInfoEmail',     
          mk: 'Ja, jeg ønsker å motta nyhetsbrev på epost', 
          controlType: "checkbox",
          value: true
        })

       ]
    }));

    
    


    let emailOrPhoneValidators = [
      (control: AbstractControl): { [s: string]: boolean } => {
        let fc = control as UntypedFormControl;
        
        let contactEmail = this.edit.form.controls.step3.controls.contactEmail.value;
        let contactTelephone = this.edit.form.controls.step3.controls.contactTelephone.value;
       
        // console.log("contactEmail: " + contactEmail + ", contactTelephone: " + contactTelephone + ", value: " + fc.value);
        

        // if (fc.value !== undefined) return { };

        if (contactEmail != true && contactTelephone != true) return  { error: true, required: true };
        return { };
      }
    ];

    let emailOrPhoneOnChange = (event:any) => {
      if (!this.edit.form.controls.step3.controls.contactTelephone.valid
        || !this.edit.form.controls.step3.controls.contactEmail.valid) {
        this.edit.form.controls.step3.controls.contactTelephone.updateValueAndValidity();
        this.edit.form.controls.step3.controls.contactEmail.updateValueAndValidity();
      }

      this.edit.form.controls.step3.controls.user.controls.telephone.updateValueAndValidity();

    }

    steps["3"].push(new DynformControl({ 
      key: 'contactEmail',     
      mk: 'E-post', 
      controlType: "checkbox", 
      required: false, 
      value: true,
      validators: emailOrPhoneValidators,
      onChange: emailOrPhoneOnChange
    }));
    steps["3"].push(new DynformControl({ 
      key: 'contactTelephone',     
      mk: 'Telefon', 
      controlType: "checkbox", 
      required: false, 
      value: true,
      validators: emailOrPhoneValidators,
      onChange: emailOrPhoneOnChange
     }));

     steps["3"].push(new DynformControl({ 
      key: 'contactTelephoneTimeId',     
      mk: 'Når passer det å bli kontaktet på telefon?',  // TODO: "Når som helst"
      controlType: 'radio-button',
      radioButtonStyle: "solid", 
      // required: true,
      value: 1,
      data: { },
      options: () => fbo.telephoneTimes,
      show: () => {
        return this.edit.form.controls.step3.controls.contactTelephone.value;
      },
      validators: [
        (control: AbstractControl): { [s: string]: boolean } => {
          let fc = control as UntypedFormControl;
          
          if (fc.value !== undefined) return { };
  
          let isTelephone = this.edit.form.controls.step3.controls.contactTelephone.value;
          if (!isTelephone) return { };
          return { error: true, required: true }
        }
      ],
      optionsFieldValue: "id",
      optionsFieldLabel: "name"
     
    }));

    steps["3"].push(new DynformControl({ 
      key: 'homeDelivery',     
      mk: 'Ønsker du hjemlevering?', 
      mkInfo: 'Ved velge hjemlevering kan du forvente å få tilbud inkludert levering til avtalt sted i ditt nærområde. De fleste bilforhandlere har avtale med transportselskaper eller egne sjåfører, dette er ofte rimeligere enn du tror.', 
      controlType: "checkbox", 
      required: false, 
      data: {
        css: "fi-bold"
      }
    }));
   


    // 



    steps["3"].push(new DynformControl({ 
      key: 'acceptEula',     
      mk: 'Jeg aksepterer vilkår og retningslinjer for personvern', 
      controlType: "checkbox", 
      required: true, 
    }));
    


    this.apps.bas.fs.updateFormObj(this.edit, controls, obj);

    // console.log("edit: ", this.edit);

  } 


}
