<btlib-admin-page-wrapper>

  <div class="m-d">
    <h1>{{ 'bus.discountCode' | actrans }}</h1>
    <div class="text-right">
      <button nz-button nzType="primary"  (click)="newObject()">
        <i nz-icon nzType="plus-circle"></i>
        {{ 'common.add' | actrans }}
      </button>
  
    </div>


    <div *ngIf="df.obj && false">
      <nz-collapse>
        <nz-collapse-panel  [nzHeader]="'Filter'" [nzActive]="apps.bas.ui.r.gtemd"  >
          
          <form nz-form [formGroup]="df.form" class="item-form mt-d" nzLayout="vertical" [nzAutoTips]="apps.bas.fs.autoTips" (ngSubmit)="dfSubmit()"  >
  
            <btlib-form-row [controls]="[ 
            df.controls.search
            
            ]" [form]="df.form"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row>
           
            <button nz-button nzType="default"> {{ 'common.show' | actrans }}  </button>
      
          </form>

        </nz-collapse-panel>
      </nz-collapse>


     
    </div>
   
  </div>

  <nz-table 

    #nzTableTasks
    [nzData]="fbo.stats.current || []" 
    nzTableLayout="fixed" 

    nzSize="small"
    class="nz-table-xs"

    [nzFrontPagination]="false"
    [nzLoading]="fbo.loading"

    [nzTotal]="fbo.stats.navigatorObj.size"
    [nzPageSize]="fbo.stats.navigatorObj.pageSize"
    [nzPageIndex]="fbo.stats.navigatorObj.page+1"
    (nzQueryParams)="onQueryParamsChange($event)"
    >
    <thead>
      <tr>
        <th nzWidth="40px"></th>
        <th>{{ 'common.code' | actrans }}</th>
        <th>{{ 'common.orderPeriod' | actrans }}</th>
        <th>{{ 'common.bookingPeriod' | actrans }}</th>
        <th nzAlign="right" nzWidth="70px">{{ 'common.amount' | actrans }}</th>
        <th nzAlign="right" nzWidth="70px">{{ 'common.percent' | actrans }}</th>
        <th nzAlign="right" nzWidth="80px" nzEllipsis>{{ 'bus.discountCode.limitCount' | actrans }}</th>
        <th nzAlign="right" nzWidth="80px" nzEllipsis>{{ 'bus.discountCode.limitAmount' | actrans }}</th>
        <!-- <th>{{ 'common.sum' | actrans }}: {{ 'common.count' | actrans }}</th> -->
        <th nzAlign="right" nzWidth="100px" >{{ 'common.sum' | actrans }}</th>
        <th nzWidth="40px"></th>
      </tr>
    </thead>
     
  <!-- //

  	private Integer ordersCount = 0;
	private Integer ordersAmount = 0;
	private Product product;
	private String productTag; -->

    <tbody>
      <tr *ngFor="let data of nzTableTasks.data">


        <td nzAlign="center">
          <button  nzSize="small" class="" [title]="'common.copy' | actrans" 
            (click)="$event.preventDefault()" 
            nz-popconfirm
            [nzPopconfirmTitle]="'app.lib.common.popconfirm.confirm' | actrans | str"
            nz-button nzType="default"
            (nzOnConfirm)="copyObject(data)"
          >
            <i nz-icon [nzType]="'copy'"></i>
          </button>
        </td>
  
        <td>{{ data.code }}</td>
        <td>{{ data.orderPeriodDisplay }}</td>
        <td>{{ data.bookingPeriodDisplay }}</td>
        <td nzAlign="right">{{ data.amountAsDouble | nf }}</td>
        <td nzAlign="right">{{ data.percentAsDouble | nf }}</td>
        <td nzAlign="right">{{ data.limitCount }}</td>
        <td nzAlign="right">{{ data.limitAmountAsDouble| nf }}</td>

        <td nzAlign="right">{{ data.ordersCount }} | {{ data.ordersAmountAsDouble | nf }}</td>

        <td nzAlign="center">
          <button nz-button nzType="default" nzSize="small" [title]="'common.edit' | actrans"  (click)="editObject(data)">
            <i nz-icon [nzType]="'edit'"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </nz-table>

  <nz-modal 
    [(nzVisible)]="edit.obj" 
    [nzTitle]="'common.edit' | actrans | str" 
    nzWidth="90vw" 
    (nzOnCancel)="onEditCancel()" 
    (nzOnOk)="onEditOk();" 
    [nzOkText]="'common.save' | actrans | str"
    [nzOkLoading]="edit.loading"
    >
    <ng-container *nzModalContent>
      

      <form nz-form [formGroup]="edit.form" class="item-form mt-d" nzLayout="vertical" [nzAutoTips]="apps.bas.fs.autoTips" (ngSubmit)="onEditOk()"  >
  
        <btlib-form-row [controls]="[ edit.controls.orderPeriodAsRange, edit.controls.bookingPeriodAsRange  ]" [form]="edit.form"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row> 
        <btlib-form-row [controls]="[ edit.controls.code, edit.controls.amountAsDouble, edit.controls.percentAsDouble ]" [form]="edit.form"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row>
        <btlib-form-row [controls]="[ edit.controls.product, edit.controls.limitCount, edit.controls.limitAmountAsDouble ]" [form]="edit.form"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row> 
        
        <!-- <btlib-form-row [controls]="[  ]" [form]="edit.form"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row>  -->
        
      </form>
      
      <!-- 

      controls.push(new DynformControl({ 
        key: 'productTag',     
        mk: 'web.common.productTag', 
      }));

      controls.push(new DynformControl({ 
        key: 'limitCount',     
        mk: 'bus.discountCode.limitCount', 
        controlType: "input-number" 
      }));
      controls.push(new DynformControl({ 
        key: 'limitAmountAsDouble',     
        mk: 'bus.discountCode.limitAmount', 
        controlType: "input-number" 
      })); -->



    </ng-container>
  </nz-modal>





</btlib-admin-page-wrapper>
