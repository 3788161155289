
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { NZ_DATE_CONFIG, NZ_I18N } from 'ng-zorro-antd/i18n';
import { nb_NO, en_US } from 'ng-zorro-antd/i18n';
import { NgZorroAntdModule } from './ng-zorro-antd.module';

import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import nb from '@angular/common/locales/nb';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MbscModule } from '@mobiscroll/angular-ivy';
import { DragDropModule } from '@angular/cdk/drag-drop'
import { PinchZoomModule } from '@meddv/ngx-pinch-zoom';

import { QuillModule } from 'ngx-quill'

import { AppService } from './services/app.service';

import { PendingChangesGuard } from './lib/core/guards/pending-changes.guard';

import { BtPipesModule } from './lib/core/pipes/bt-pipes.module';

import { IFrameResizerDirective } from './lib/core/directive/iframe-resizer.directive';

// import { FilterPipeModule } from 'ngx-filter-pipe';

//
import { TestComponent } from './component/test/test.component';
import { TestlibComponent } from './lib/core/component/testlib/testlib.component';
import { BtImageMapComponent } from './lib/core/component/ui/bt-image-map/bt-image-map.component';

import { LoginComponent } from './lib/core/component/user/login/login.component';
import { IframeComponent } from './lib/core/component/ui/iframe/iframe.component';
import { BtGoogleMapsComponent } from './lib/core/component/ui/bt-google-maps/bt-google-maps.component';
import { LoginCheckComponent } from './lib/core/component/user/login-check/login-check.component';

import { BtDynamicFormControlComponent } from './lib/core/component/form/bt-dynamic-form-control/bt-dynamic-form-control.component';
import { BtDynamicFormComponent } from './lib/core/component/form/bt-dynamic-form/bt-dynamic-form.component';
import { BtNzFormItemComponent } from './lib/core/component/form/bt-nz-form-item/bt-nz-form-item.component';
import { BtFormRowComponent } from './lib/core/component/form/bt-form-row/bt-form-row.component';
import { BtMessageFieldComponent } from './lib/core/component/form/bt-message-field/bt-message-field.component';


import { OrderProductComponent } from './lib/core/component/order/order-product/order-product.component';
import { OrderProductFormComponent } from './lib/core/component/order/order-product-form/order-product-form.component';
import { OrderProductCartComponent } from './lib/core/component/order/order-product-cart/order-product-cart.component';
import { OrderCartComponent } from './lib/core/component/order/order-cart/order-cart.component';
import { OrderCartPreviewComponent } from './lib/core/component/order/order-cart-preview/order-cart-preview.component';
import { EventCalendarComponent } from './lib/core/component/ui/event-calendar/event-calendar.component';

//
import { RootPageComponent } from './pages/root-page/root-page.component';

import { WidgetEventCalendarPageComponent } from './pages/widget/widget-event-calendar-page/widget-event-calendar-page.component';
import { WidgetOrderProductPageComponent } from './pages/widget/widget-order-product-page/widget-order-product-page.component';
import { WidgetTestPageComponent } from './pages/widget/widget-test-page/widget-test-page.component';
import { AdminRootPageComponent } from './pages/admin/admin-root-page/admin-root-page.component';
import { AdminProductsPageComponent } from './pages/admin/admin-products-page/admin-products-page.component';
import { AdminProductPageComponent } from './pages/admin/admin-product-page/admin-product-page.component';

import { MenuMainComponent } from './lib/core/component/ui/menu-main/menu-main.component';
import { AdminMenuComponent } from './lib/core/component/admin/admin-menu/admin-menu.component';
import { AdminPageWrapperComponent } from './lib/core/component/admin/admin-page-wrapper/admin-page-wrapper.component';
import { BtWeekdaysComponent } from './lib/core/component/form/bt-weekdays/bt-weekdays.component';
import { AdminOrderRootPageComponent } from './pages/admin/order/admin-order-root-page/admin-order-root-page.component';
import { AdminOrderOrderPageComponent } from './pages/admin/order/admin-order-order-page/admin-order-order-page.component';
import { AdminOrderOverviewPageComponent } from './pages/admin/order/admin-order-overview-page/admin-order-overview-page.component';
import { WidgetOrderProductsPageComponent } from './pages/widget/order/widget-order-products-page/widget-order-products-page.component';
import { AdminProductAreasPageComponent } from './pages/admin/product/admin-product-areas-page/admin-product-areas-page.component';
import { AppBileasyLeadRootPageComponent } from './pages/app/bileasy/lead/app-bileasy-lead-root-page/app-bileasy-lead-root-page.component';
import { AppBileasyAdminRootPageComponent } from './pages/app/bileasy/admin/app-bileasy-admin-root-page/app-bileasy-admin-root-page.component';
import { CommonEmptyPageComponent } from './pages/common/common-empty-page/common-empty-page.component';
import { WidgetOrderViewRootPageComponent } from './pages/widget/order/view/widget-order-view-root-page/widget-order-view-root-page.component';
import { AppTestTest1PageComponent } from './pages/app/test/app-test-test1-page/app-test-test1-page.component';
import { AppOpplevkrageroProductsPageComponent } from './pages/app/opplevkragero/app-opplevkragero-products-page/app-opplevkragero-products-page.component';
import { AdminProductProductTypesPageComponent } from './pages/admin/product/admin-product-product-types-page/admin-product-product-types-page.component';
import { AdminProductPropertyTypesPageComponent } from './pages/admin/product/admin-product-property-types-page/admin-product-property-types-page.component';
import { CommonUserUserPageComponent } from './pages/common/user/common-user-user-page/common-user-user-page.component';
import { AdminUserUsersPageComponent } from './pages/admin/user/admin-user-users-page/admin-user-users-page.component';
import { AdminUserUserPageComponent } from './pages/admin/user/admin-user-user-page/admin-user-user-page.component';
import { AdminUserFormComponent } from './lib/core/component/admin/admin-user-form/admin-user-form.component';
import { BtUserChangePasswordComponent } from './lib/core/component/user/bt-user-change-password/bt-user-change-password.component';
import { BtUserChangeUsernameComponent } from './lib/core/component/user/bt-user-change-username/bt-user-change-username.component';
import { BtUserDeleteUserComponent } from './lib/core/component/user/bt-user-delete-user/bt-user-delete-user.component';
import { AppSpacebookingOrderPageComponent } from './pages/app/spacebooking/app-spacebooking-order-page/app-spacebooking-order-page.component';
import { AppSpacebookingAdminPageComponent } from './pages/app/spacebooking/app-spacebooking-admin-page/app-spacebooking-admin-page.component';
import { AppBileasyProvidersPageComponent } from './pages/app/bileasy/app-bileasy-providers-page/app-bileasy-providers-page.component';
import { AdminLinksPageComponent } from './pages/admin/admin-links-page/admin-links-page.component';
import { AppRefugeeUserRoomForRentPageComponent } from './pages/app/refugee/user/app-refugee-user-room-for-rent-page/app-refugee-user-room-for-rent-page.component';
import { AdminReportsPageComponent } from './pages/admin/misc/admin-reports-page/admin-reports-page.component';
import { AdminStatsPageComponent } from './pages/admin/misc/admin-stats-page/admin-stats-page.component';
import { AppBileasyAdminProfilePageComponent } from './pages/app/bileasy/admin/app-bileasy-admin-profile-page/app-bileasy-admin-profile-page.component';
import { ProviderMiscGiftCardPageComponent } from './pages/provider/misc/provider-misc-gift-card-page/provider-misc-gift-card-page.component';
import { ProviderMiscElectricityBillingPageComponent } from './pages/provider/misc/provider-misc-electricity-billing-page/provider-misc-electricity-billing-page.component';
import { AgentMiscOrderOverviewPageComponent } from './pages/agent/misc/agent-misc-order-overview-page/agent-misc-order-overview-page.component';
import { PageWrapperComponent } from './lib/core/component/ui/page-wrapper/page-wrapper.component';
import { WidgetProductsRootPageComponent } from './pages/widget/products/widget-products-root-page/widget-products-root-page.component';
import { AdminTasksPageComponent } from './pages/admin/misc/admin-tasks-page/admin-tasks-page.component';
import { AppTasksTasksPageComponent } from './pages/app/tasks/app-tasks-tasks-page/app-tasks-tasks-page.component';
import { AppTasksAddPageComponent } from './pages/app/tasks/app-tasks-add-page/app-tasks-add-page.component';
import { AdminMiscSessionStatusPageComponent } from './pages/admin/misc/admin-misc-session-status-page/admin-misc-session-status-page.component';
import { AdminDiscountCodesPageComponent } from './pages/admin/misc/admin-discount-codes-page/admin-discount-codes-page.component';
import { SuperuserPropertiesPageComponent } from './pages/superuser/superuser-properties-page/superuser-properties-page.component';
import { AdminToolsPageComponent } from './pages/admin/misc/admin-tools-page/admin-tools-page.component';
import { MessageModalComponent } from './lib/core/component/admin/message-modal/message-modal.component';

import { OrdersListModalComponent } from './lib/core/component/admin/orders-list-modal/orders-list-modal.component';
import { OrderInfoModalComponent } from './lib/core/component/admin/order-info-modal/order-info-modal.component';
import { SmsModalComponent } from './lib/core/component/admin/sms-modal/sms-modal.component';
import { EmailModalComponent } from './lib/core/component/admin/email-modal/email-modal.component';
import { SendPushModalComponent } from './lib/core/component/admin/send-push-modal/send-push-modal.component';
import { OrderCancelModalComponent } from './lib/core/component/admin/order-cancel-modal/order-cancel-modal.component';
import { BtContentRowComponent } from './lib/core/component/ui/bt-content-row/bt-content-row.component';

registerLocaleData(en);
registerLocaleData(nb);

@NgModule({
  declarations: [
    AppComponent,
    //
    IFrameResizerDirective,
    //
    TestComponent,
    TestlibComponent,
    BtImageMapComponent,
    
    LoginComponent,
    LoginCheckComponent,
    IframeComponent,
    MenuMainComponent,
    AdminMenuComponent,
    BtDynamicFormControlComponent,
    BtDynamicFormComponent,
    BtNzFormItemComponent,
    BtFormRowComponent,
    BtMessageFieldComponent,
    BtGoogleMapsComponent,


    OrderProductComponent,
    OrderProductFormComponent,
    OrderProductCartComponent,
    OrderCartComponent,
    OrderCartPreviewComponent,
    EventCalendarComponent,

    //
    RootPageComponent,

    WidgetEventCalendarPageComponent,
    WidgetOrderProductPageComponent,
    WidgetTestPageComponent,

    AdminRootPageComponent,
    AdminProductsPageComponent,
    AdminProductPageComponent,
    AdminPageWrapperComponent,
    BtWeekdaysComponent,
    AdminOrderRootPageComponent,
    AdminOrderOrderPageComponent,
    AdminOrderOverviewPageComponent,
    OrderInfoModalComponent,
    BtContentRowComponent,
    WidgetOrderProductsPageComponent,
    AdminProductAreasPageComponent,
    AppBileasyLeadRootPageComponent,
    AppBileasyAdminRootPageComponent,
    CommonEmptyPageComponent,
    WidgetOrderViewRootPageComponent,
    AppTestTest1PageComponent,
    AppOpplevkrageroProductsPageComponent,
    AdminProductProductTypesPageComponent,
    AdminProductPropertyTypesPageComponent,
    CommonUserUserPageComponent,
    AdminUserUsersPageComponent,
    AdminUserUserPageComponent,
    BtUserChangePasswordComponent,
    BtUserChangeUsernameComponent,
    BtUserDeleteUserComponent,
    AppSpacebookingOrderPageComponent,
    AppSpacebookingAdminPageComponent,
    AppBileasyProvidersPageComponent,
    AdminLinksPageComponent,
    AppRefugeeUserRoomForRentPageComponent,
    AdminReportsPageComponent,
    AdminStatsPageComponent,
    OrdersListModalComponent,
    AppBileasyAdminProfilePageComponent,
    SmsModalComponent,
    ProviderMiscGiftCardPageComponent,
    ProviderMiscElectricityBillingPageComponent,
    AgentMiscOrderOverviewPageComponent,
    PageWrapperComponent,
    EmailModalComponent,
    SendPushModalComponent,
    AdminUserFormComponent,
    WidgetProductsRootPageComponent,
    AdminTasksPageComponent,
    AppTasksTasksPageComponent,
    AppTasksAddPageComponent,
    AdminMiscSessionStatusPageComponent,
    AdminDiscountCodesPageComponent,
    SuperuserPropertiesPageComponent,
    AdminToolsPageComponent,
    MessageModalComponent,
    OrderCancelModalComponent,
    
  ],
  imports: [ 
    MbscModule, 

    DragDropModule,
    PinchZoomModule,
    
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,

    NgZorroAntdModule,
    QuillModule.forRoot(),
    
    // FilterPipeModule,
    BtPipesModule, 
  ],
  providers: [
    { provide: NZ_I18N, useValue: nb_NO },
    {
      provide: NZ_DATE_CONFIG,
      useValue: {
        firstDayOfWeek: 1, // week starts on Monday (Sunday is 0)
      },
    },
    AppService,
    PendingChangesGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
