
import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { MiscUtil } from 'src/app/lib/core/util/misc.util';

import { AppService } from 'src/app/services/app.service';

import { DynformControl, DynformControlsMap } from 'src/app/lib/core/model/dymform-control';

import startOfDay from 'date-fns/startOfDay'
import startOfMonth from 'date-fns/startOfMonth'

@Component({
  selector: 'app-admin-reports-page',
  templateUrl: './admin-reports-page.component.html',
  styleUrls: ['./admin-reports-page.component.scss']
})
export class AdminReportsPageComponent implements OnInit {

  route: ActivatedRoute;

  fbo:any = {
    loading: true,
    stats: {
      current: [],
      navigatorObj: {
        size: 0,
        pageSize: 10,
        page: 0
      }
    }

  };

  customFilters:any = { }

  df:any = {
    form: undefined,
    controls: [],
    obj: undefined,
    params: { 
      // 
    },
    statsForm: { 
      statsType: "REPORT",
      period: "ALL",
      currentJil: "min",
      
      sort: "TYPE",
      sortMode: "ASC",
      sort_2: "FROM_DATE",
      sortMode_2: "DESC",
    },

    email: "",
    message: "",
    loading: false
  };

  newReportForm:any = {

    type: { }
  };


  
  selected:any[] = []

  constructor(public injector:Injector, public apps:AppService) {
    this.route = injector.get(ActivatedRoute);
    this.apps.bas.us.checkActivatedRoute(this.route.snapshot);

    if (this.apps.bas.envtest) console.log("AdminReportsPageComponent");


    // this.updateFbo();
    
  }

  async json(action:string, params:any = undefined, args:any = { }):Promise<any> {
    if (this.apps.bas.envtest) console.log("AdminReportsPageComponent.json, action: " + action + ", params: ", params);
    args = args || { };
    return this.apps.bas.ws.json(MiscUtil.extend({ 
      aType: "appAdmin", 
      action: action
    }, params)).then((json) => {

      if (this.apps.bas.envtest) console.log(action + ", json: ", json);
      if (json.success) {
        if (!args.skipSucessMessage) {
          this.apps.bas.ui.success(this.apps.bas.ui.actrans("common.success"));
      
        }
      }

      return json;
    });
  }


  onSelected(event:any, item:any) {
    if (this.apps.bas.envtest) console.log("onSelected, item: ", item,  ", event: ", event);
    item.selected = event;
    this.selected = this.fbo.stats.current?.filter((obj:any) => obj.selected);
  }

  async reportAction(action:string) {
    if (this.selected.length == 0) {
      this.apps.bas.ui.error(this.apps.bas.ui.actrans("web.common.error.noneSelected")); // 
      return;
    }
    let ids = this.selected.map((item) => item.id).join(",");
    
    this.df.loading = true;
    let json = await this.json(action, { 
      ids,
      "email": this.df.email,
      "message": this.df.message
    });

    
    this.df.loading = false;
    if (action == "deleteReports" && json?.success) {
      this.dfSubmit();
    }

  }

  onQueryParamsChange(queryParams: NzTableQueryParams): void {
    if (this.apps.bas.envtest) console.log("onQueryParamsChange: ", queryParams);
    const { pageSize, pageIndex, sort, filter } = queryParams;
    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || null;
    const sortOrder = (currentSort && currentSort.value) || null;

    let params:any = { };
    
    let page = pageIndex !== undefined && pageIndex > 0 ? pageIndex -1 : undefined;
    let nav = this.fbo.stats.navigatorObj;
    if ((page !== undefined && nav.page != page )) { 
      params.navAction = "page";
      params.navParam = page;
    } else if ((pageSize !== undefined && nav.pageSize != pageSize )) { 
      params.navAction = "pageSize";
      params.navParam = pageSize;
    }

    
    let sfi:any = { }
    if (sortField) {
      sfi.customSortsList = [
     
        { field: sortField, asc: sortOrder === "ascend" }
      ];

    }

    if (filter) {
      sfi.customFiltersMap = { };
      for (let item of filter) {
        if (item.value == null || item.value.length == 0) continue;
   
        sfi.customFiltersMap[item.key] = { value: item.value };

      }
    }


    this.updateFbo(params, sfi);
  }

  updateFbo(input:any = { }, statsFormInput:any = { }) {
    let params:any = {
      aType: "appAdmin",
      action: "getStatsFbo",
      page: "reports",
    };

    let rv = { };
    if (this.df.form) {
      rv = this.apps.bas.fs.getRawValue(this.df.form, this.df.controls);
      if (rv === false) return;
  
    }

    statsFormInput.customFiltersMap = statsFormInput.customFiltersMap || { };
    for (let key in this.customFilters) {
      let val = this.customFilters[key];
      if (val == undefined || val == "") continue; 
      statsFormInput.customFiltersMap[key] = { value: val, type: "search" };
    }

    MiscUtil.extend(rv,  statsFormInput);


    MiscUtil.extend(params, input);

    let statsForm = MiscUtil.extend({ }, this.df.statsForm);
    MiscUtil.extend(statsForm, rv);

    MiscUtil.extend(params, this.df.params);
    MiscUtil.extend(params, { statsForm: JSON.stringify(statsForm) } );

    if (this.apps.bas.envtest) console.log("updateFbo.params: ", params);

    this.fbo.loading = true;
    this.apps.bas.ws.json(params ).then((json:any) => {
      if (this.apps.bas.envtest) console.log("updateFbo.then, json: ", json);

      //TODO: må lagre dssse verdiene lokalt slik at man ikke må stille inn hver gang siden lastes.

      if (json.success) {
        this.updateForm(json.stats);

        for (let obj of json.stats?.current || []) {
          obj.downloadUrlAccess = this.apps.bas.ws.appendBttoken(obj.downloadUrl);
        }
        

        this.fbo = json;
        
      } else {

      }
      

     });
  }


  updateForm(stats:any) {

    let controls: DynformControl[] = [];

    let isAdmin = this.apps.bas.aas.isAdmin();


    // controls.push(new DynformControl({ key: 'statsType', value: "USER" }));
    
    
    // controls.push(new DynformControl({ key: 'search',               mk: 'common.search' }));
    // controls.push(new DynformControl({ key: 'currentJil', value: "max" }));
    controls.push(new DynformControl({ 
      key: 'username',               
      mk: 'common.username',
      show: () => {
        return isAdmin;
      } 
    }));
    
    
    // controls.push(new DynformControl({ 
    //   key: 'product',  
    //   // valuePath: "ruleId",
    //   mk: 'common.product',  
    //   controlType: 'select', 
    //   // required: true,
    //   options: () => {
    //     return stats.products || []; 
    //   },
    //   optionsAllowEmpty: true,
    //   optionsFieldValue: "id",
    //   optionsFieldLabel: "mkName"
    // }));

    // let roles = stats.roles;
    // if (this.apps.bas.envtest) console.log("roles: ", roles);

    controls.push(new DynformControl({ 
      key: 'reportType',  
      // valuePath: "ruleId",
      mk: 'common.type',  
      controlType: 'select', 
      // required: true,
      options: () => {
        return stats.reportTypes; 
      },
      optionsAllowEmpty: true,
    }));

    
    controls.push(new DynformControl({ 
      key: 'provider',  
      mk: 'common.provider',  
      controlType: 'select', 
      // required: true,
      options: () => {
        return stats.providers || [];
      },
      show: () => {
        return this.apps.bas.aas.isAdmin();
      },
      optionsAllowEmpty: true,
      optionsFieldValue: "id",
      optionsFieldLabel: "label"
    }));


    controls.push(new DynformControl({ 
      key: 'sort',  
      mk: 'common.sort',  
      controlType: 'select', 
      // required: true,
      options: () => {
        return stats.sorts || [];
      },
      optionsAllowEmpty: true,
      optionsFieldValue: "enumName",
      optionsFieldLabel: "mk"
    }));
    
    controls.push(new DynformControl({ 
      key: 'sortMode',  
      mk: 'common.sortMode',  
      controlType: 'select', 
      // required: true,
      options: () => {
        return stats.sortModes || [];
      },
      optionsAllowEmpty: true,
      optionsFieldValue: "enumName",
      optionsFieldLabel: "mk"
    }));

    //TODO: period-nedtreksboks: sist uke, osv

    /*
predef: 
statsType: USER
period: ONE_MONTH
fromAsString: 2022-01-21 00:00
toAsString: 2022-02-21 00:00
dateMode: TIME
groupBy: NONE
sort: TIME
sortMode: DESC
sort_2: NONE
sortMode_2: ASC
sort_3: NONE
sortMode_3: ASC
statuss: 0
statuss: 2
sm1: SHOW
provider: 
product: 
productTagsInclude: 
productTagsExclude: 
productSubType: 
viName: 
showSettled: ALL
showDoSettle: ALL
showSaved: YES
bookingTag: 
biTag: 
search: 
username: 
    */


    this.apps.bas.fs.updateFormObj(this.df, controls, stats);


  }

  dfSubmit() {
    this.updateFbo();
  }

  ngOnInit(): void {
    
  }

  async generateReport() {

    let rv = this.apps.bas.fs.getRawValue(this.newReportForm.form, this.newReportForm.controls);

    if (this.apps.bas.envtest) console.log("generateReport.rv: ", rv);

    if (rv === false) return;


    this.df.loading = true;
    let json = await this.json("generateReport", { 
      report: JSON.stringify(rv)
    });

    this.df.loading = false;

    this.newReportForm.obj = null;

    this.df.form.controls.reportType.setValue( rv.genReportType );
    this.updateFbo();

  }

  //TODO: flytte til UiService
  dateStartOf(date:Date = new Date(), field:string): Date {

    switch (field) {
      case "day":  return startOfDay(date);
      case "month":  return startOfMonth(date);
    }

    return date;
  }
    

  newReport() {

    let fbo = this.fbo;
    let stats = fbo.stats;
    let controls: DynformControl[] = [];

    let isAdmin = this.apps.bas.aas.isAdmin();

    this.newReportForm.type = { };

     
    controls.push(new DynformControl({ 
      key: 'username',               
      mk: 'common.username',
      show: () => {
        return this.newReportForm.type.user && isAdmin;
      } 
    }));
    
    controls.push(new DynformControl({ 
      key: 'genReportType',  
      // valuePath: "type",
      mk: 'common.type',  
      controlType: 'select', 
      required: true,
      options: () => {
        return stats.genReportTypes; 
      },
      value: isAdmin ? MiscUtil.listToMap(stats.genReportTypes, "enumName")["AGGREGATE"].id : undefined,
      onChange: (event:any) => {
        this.newReportForm.type = MiscUtil.listToMap(stats.genReportTypes)[event.value] || { };
        console.log("this.newReportForm.type: ", this.newReportForm.type);
      },
      optionsAllowEmpty: true,
      optionsFieldValue: "id",
      optionsFieldLabel: "mkName"
    }));


    let to = this.dateStartOf(new Date(), "month");
    let from = this.apps.bas.ui.dateAdd(to, { months: -1 });

    stats.datePeriodAsRange = MiscUtil.getDateAsString(from) + "_" + MiscUtil.getDateAsString(to);

    controls.push(new DynformControl({ 
      key: 'datePeriodAsRange',  
      mk: 'common.period', 
      required: true , 
      controlType: "date-range-picker", 
      data: { 
        readOnly: this.apps.bas.ui.r.xs
       } 
    })); 

    
    controls.push(new DynformControl({ 
      key: 'user',  
      valuePath: 'provider',
      mk: 'common.provider',  
      controlType: 'select', 
      // required: true,
      options: () => {
        return stats.providers || [];
      },
      show: () => {
        return this.newReportForm.type.user && isAdmin;
      },
      optionsAllowEmpty: true,
      optionsFieldValue: "id",
      optionsFieldLabel: "label"
    }));

    controls.push(new DynformControl({ 
      key: 'product',  
      // valuePath: "ruleId",
      mk: 'common.product',  
      controlType: 'select', 
      // required: true,
      options: () => {
        return stats.products || []; 
      },
      show: () => {
        return this.newReportForm.type.tags?.includes("Product");
      },
      optionsAllowEmpty: true,
      optionsFieldValue: "id",
      optionsFieldLabel: "mkName"
    }));

    // $cb.show($(".report-user-field"), rt.user);
    // $cb.show($(".report-product-field"),  rt.tags.contains("Product"));
    
    // if (!rt.user) {
    //   $("#r\\.user, username").val("");
    // }

    //TODO: period-nedtreksboks: sist uke, osv



    this.apps.bas.fs.updateFormObj(this.newReportForm, controls, stats);


  }

}

