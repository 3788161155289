import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { MiscUtil } from 'src/app/lib/core/util/misc.util';
import { AppService } from 'src/app/services/app.service';

import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { DynformControl } from 'src/app/lib/core/model/dymform-control';

import { BtContent } from 'src/app/lib/core/model/bt-content';
import { DATAID } from 'src/app/lib/core/services/data.service';
import { BtEvent } from 'src/app/lib/core/services/event.service';

@Component({
  selector: 'app-app-bileasy-admin-root-page',
  templateUrl: './app-bileasy-admin-root-page.component.html',
  styleUrls: ['./app-bileasy-admin-root-page.component.scss']
})
export class AppBileasyAdminRootPageComponent implements OnInit {

  columns:any = {
    "time": { 
      key: "time",
      name: 'Tidspunkt',
      valuePath: "time",
      width: '110px',
      sortOrder: null,
      sortFn: true,     // server sort
      // listOfFilter: null,
      // filterFn: null,
      responsive: "gtexs"
    },
    "providerContact": { 
      key: "providerContact",
      name: 'Kontakttidsp.',
      // valuePath: "providerContact",
      width: '110px',
      // sortOrder: null,
      // sortFn: true,    
      // listOfFilter: null,
      // filterFn: null,
      responsive: "gtesm"
    },

    "leadType": {
      key: "leadType",
      name: 'Type',
      valuePath: "mkLeadType",
      sortField: "leadTypeId",
      
      width: '120px',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [
        { text: 'Utfra behov', value: 1 },
        { text: 'Bestemt modell', value: 2 }
      ],
      filterFn: true,
      filterMultiple: false,
      responsive: "gtesm"
    },
    "carType": {
      key: "carType",
      name: 'Biltype',
      valuePath: "mkCarType",
      sortField: "carTypeId",

      width: '120px',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [
        { text: 'Elektrisk', value: 1 },
        { text: 'Fossil', value: 2 },
        { text: 'Varebil', value: 3 },
        // { text: 'Tom', value: '' }
      ],
      filterFn: true,
      filterMultiple: true,
      responsive: "gtesm"
    },
    "minRange": {
      key: "minRange",
      name: 'Rekkevidde',
      // valuePath: "mkCarType",
      
      width: '100px',
      sortOrder: null,
      sortFn: true,
      // listOfFilter: [
      //   { text: 'Elektrisk', value: 'Electric' },
      //   { text: 'Fossil', value: 'Fossil' },
      //   { text: 'Varebil', value: 'Commercial' },
      //   // { text: 'Tom', value: '' }
      // ],
      // filterFn: true,
      responsive: "gtesm"
    },
    "priceAsRange": {
      key: "priceAsRange",
      name: 'Pris',
      valuePath: "priceRangeDisplay",
      sortField: "priceMin",
      
      width: '120px',
      sortOrder: null,
      sortFn: true,
      // listOfFilter: [
      //   { text: 'Elektrisk', value: 'Electric' },
      //   { text: 'Fossil', value: 'Fossil' },
      //   { text: 'Varebil', value: 'Commercial' },
      //   // { text: 'Tom', value: '' }
      // ],
      // filterFn: true,
      responsive: "gtexs"
    },

    "make": {
      key: "make",
      name: 'Merke',

      sortOrder: null,
      sortFn: true,

      filterCustom: true,
      filterSearch: true,
      responsive: "gtemd"
    },
    "model": {
      key: "model",
      name: 'Modell',

      sortOrder: null,
      sortFn: true,

      filterCustom: true,
      filterSearch: true,
      responsive: "gtemd"
    },

    "comment": {
      key: "comment",
      name: 'Kommentar',

      responsive: "gtexl"
    },

    
    "maxAge": {
      key: "maxAge",
      name: 'Alder',

      width: '100px',
      sortOrder: null,
      sortFn: true,
      responsive: "gtemd"
    },
    
    "carSizes": {
      key: "carSizes",
      sortField: "carSizeIds",

      name: 'Størrelse',

      sortOrder: null,
      sortFn: true,
      responsive: "gtelg"
    },
    "chassiss": {
      key: "chassiss",
      sortField: "chassisIds",

      name: 'Karrosseri',

      sortOrder: null,
      sortFn: true,
      responsive: "gtemd"
    },
    "yearAsRange": {
      key: "yearAsRange",
      name: 'Årsmodell',
      valuePath: "yearRangeDisplay",
      sortField: "yearMin",
      
      width: '120px',
      sortOrder: null,
      sortFn: true,
      responsive: "gtelg"
    },
    
    "mileageAsRange": {
      key: "mileageAsRange",
      name: 'Km.stand',
      valuePath: "mileageRangeDisplay",
      sortField: "mileageMax",
      
      width: '120px',
      sortOrder: null,
      sortFn: true,
      responsive: "gtexl"
    },
    "propulsions": {
      key: "propulsions",
      sortField: "propulsionIds",

      name: 'Drivstoff',

      sortOrder: null,
      sortFn: true,
      responsive: "gtelg"
    },
    "wheelDrives": {
      key: "wheelDrives",
      sortField: "wheelDriveIds",

      name: 'Hjuldrift',

      sortOrder: null,
      sortFn: true,
      responsive: "gtexl"
    },
    "transmissions": {
      key: "transmissions",
      sortField: "transmissionIds",

      name: 'Gir',

      sortOrder: null,
      sortFn: true,
      responsive: "gtexl"
    },
    "userName": {
      key: "userName",
      valuePath: "userObj.name",
      sortField: "user.name",

      name: 'Kunde',

      sortOrder: null,
      sortFn: true,
      responsive: "gtelg"
    },
    "providerComment": {
      key: "providerComment",
 
      name: 'Min kommentar',

      // sortOrder: null,
      // sortFn: true,
      responsive: "gtexs"
    },
    
  };



  

  /*
                      1           2       3       4              5             6 
Elektrisk             Tidspunkt – Pris - Alder -  Rekkevidde -  Størrelse – Hjuldrift
Bensin/diesel/hybrid  Tidspunkt – Pris - Alder –  størrelse –   drivstoff – Gir
Varebil               Tidspunkt – Pris - Alder -  Karosseri -   Drivstoff-  Hjuldrift
Bestemt modell:       Tidspunkt – Merke  Modell – Årsmodell –   Pris –      Km stand

                                          maxAge  minRange,     size            wheelDrive
                                                  size,         propulsionIds   gear
                                                  chassis       propulsionIds   wheelDrive
                                                  year          price           milage
  */

  configs = [
    { 
      name: "Mine sendte tilbud", 

      columns: [ 
        this.columns.time, 
        this.columns.providerContact,
        this.columns.leadType, 
        this.columns.carType , 
        // this.columns.priceAsRange , 
        this.columns.userName  , 
        // this.columns.maxAge , 
        this.columns.providerComment 
      ], 
      params: { userLeads: true } 
    },
    { 
      name: "Elektrisk", 
      columns: [ 
        this.columns.time, 
        // this.columns.leadType , 
        // this.columns.carType , 
        this.columns.priceAsRange , 
        this.columns.maxAge , 
        this.columns.minRange , 
        this.columns.carSizes  , 
        this.columns.wheelDrives  , 
        this.columns.comment 
      ], 
      params: { leadType: "Simple", carType: "Electric" } 
    },
    { 
      name: "Fossil / hybrid", 

      columns: [ 
        this.columns.time, 
        // this.columns.leadType , 
        // this.columns.carType  , 
        this.columns.priceAsRange , 
        this.columns.maxAge , 
        this.columns.carSizes , 
        this.columns.propulsions , 
        this.columns.transmissions , 
        this.columns.comment 
      ], 
      params: { leadType: "Simple", carType: "Fossil" } 
    },
    { 
      name: "Varebil", 

      columns: [ 
        this.columns.time, 
        // this.columns.leadType , 
        // this.columns.carType ,
        this.columns.priceAsRange , 
        this.columns.maxAge , 
        this.columns.chassiss, 
        this.columns.propulsions, 
        this.columns.wheelDrives, 
        this.columns.comment 
      ], 
      params: { leadType: "Simple", carType: "Commercial" }
    },
    { 
      name: "Bestemt modell", 

      columns: [ 
        this.columns.time, 
        // this.columns.leadType , 
        // this.columns.carType  , 
        this.columns.make , 
        this.columns.model , 
        this.columns.yearAsRange , 
        this.columns.priceAsRange , 
        this.columns.mileageAsRange , 
        this.columns.comment 
      ], 

      params: { leadType: "Advanced"  } 
    },
    
  ];

  config:any = undefined ; // this.configs[0];
  configSelect:any = this.configs[0];

  processing = false;

  fbo:any = {
    loading: true,
    stats: {
      navigatorObj: {
        size: 0,
        pageSize: 30,
        page: 0
      }
    }

  };

  customFilters:any = {
  }

  df:any = {
    form: undefined,
    controls: [],
    obj: undefined,
    params: { }
  };

  lead:any = {
    ob: undefined,
    // showContact: false
  };

  
  prevTableQueryParams:NzTableQueryParams = { pageIndex: -1, pageSize: 20, sort: [], filter: []  };

  leadRows:{[index: string]:BtContent[]} = { 
    s1: []
  };

  constructor(public injector:Injector, public apps:AppService) {
    let route = injector.get(ActivatedRoute);
    this.apps.bas.us.checkActivatedRoute(route.snapshot);
  

    this.apps.bas.es.on(BtEvent.DEVICE_RESIZE, (event:any) => {
      this.onResize();
    });
    
    this.onResize();

  }

  ngOnInit(): void {
    
  }

  onResize() {
    if (this.apps.bas.envtest) console.log("DEVICE_RESIZE: ", event);
    if (this.config == undefined) this.configChange();
    else {
      this.config.columnsFiltered = this.filterColmns(this.config.columns);
    }
  }

  configChange() {
    this.fbo.stats.current = [];
    this.resetAllFilters();
    // console.log("config: ", this.configSelect);
    this.configSelect.columnsFiltered = this.filterColmns(this.configSelect.columns);
    //this.configSelect.rnd = new Date();
    // console.log("config: ", this.configSelect);
   
    let updateFbo = false;
    if (this.config && JSON.stringify(this.configSelect.columnsFiltered)==JSON.stringify( this.config.columnsFiltered)) {
      if (this.apps.bas.envtest) console.log("config.columnsFiltered is the same, forceing update");
      updateFbo = true;
    }
    this.config = this.configSelect;

    if (updateFbo) {
      
      this.onQueryParamsChange(this.prevTableQueryParams);
    }
   
  }

  filterColmns(columns:any[]) {
    let r:any = this.apps.bas.ui.r;
    console.log("filterColmns, r: ", r);

    return columns.filter((column =>  {
      
      let rf = column.responsive;
      let show = r[rf];
      console.log("key: " + column.key + ", show: " + show);

      return show;
    }));
  }

  getColumnValue(column:any, item:any) {
    let value = MiscUtil.getOwnNestedProperty(item, column.valuePath || column.key);
    if (Array.isArray(value)) {
      let arr = value;
      if (arr.length == 0) return "";
      let item0 = arr[0];
      if (typeof item0 === "object" && item0.name) arr = arr.map((item:any) => item.name);
      return arr.join(", ");
    }
    return value;
  }

  customFilterSearch(column:any) {
    if (this.apps.bas.envtest && column) console.log("customFilterSearch, " + column.key + ": " + this.customFilters[column.key]);


    this.onQueryParamsChange(this.prevTableQueryParams);

  }

  customFilterReset(column:any) {
     column.filterSearchVisible = false;

     this.customFilters[column.key] = "";
     this.customFilterSearch(column);
  }

  resetAllFilters() {
    this.customFilters = { };
    for (let column of Object.values(this.columns)) {

      (column as any).filterSearchVisible = false;
    }
  }

  closeLeadModal() {
    this.lead.obj = undefined;
    this.apps.bas.es.sendParentMessageScroll();
  }

  showLeadContact() {




    this.saveEvent({ leadEvent: "ShowContact" }).then((json) => {
      if (this.apps.bas.envtest) console.log("showLeadContact, json: ", json);
      if (json.hasAccess) {

        this.lead.obj.eventTypes.ShowContact = true;
      } else {
        console.log("showLeadContact, json.hasAccess == false: ", json);
        this.apps.bas.ui.modalError("Feil", "Du kan ikke vise flere kontaktinfoer, ta kontakt med kundeservice@bruktbilklubben.no");
        
      }
    });
  }

  confirmContact() {
    this.lead.obj.eventTypes.ConfirmContact = true;
    let leadId = this.lead.obj.id; 
    this.fbo.eventMap[leadId] = this.fbo.eventMap[leadId] || { };
    this.fbo.eventMap[leadId]["ConfirmContact"] = true;

    this.saveEvent({ leadEvent: "ConfirmContact" });

    this.lead.obj.icon = 'phone';
    this.lead.obj.iconColor = '#52c41a';
  }
  confirmSale() {
    this.lead.obj.eventTypes.ConfirmSale = true;

    this.saveEvent({ leadEvent: "ConfirmSale" });
 
    this.lead.obj.icon = 'dollar-circle';
    this.lead.obj.iconColor = '#f29e3e';

  }

  removeLead() {
    this.saveEvent({ leadEvent: "ProviderRemove" });
    let leadId = this.lead.obj.id;
    this.closeLeadModal();
    this.fbo.stats.current = this.fbo.stats.current.filter((lead:any) => lead.id != leadId);
  }

  saveComment() {
    
    this.saveEvent({ 
      id: this.lead.obj.commentEvent.id, 
      leadEvent: "Comment", 
      value: this.lead.obj.commentEvent.value 
    });

  }

  saveEvent(eventData:any) {
    MiscUtil.extend(eventData, { leadId: this.lead.obj.id  });

    return this.apps.bas.ws.json({ 
      checkChildApp: true,
      aType: "customAction", 
      action: "saveEvent", 
      jsonData: JSON.stringify(eventData)
    
    }).then((json) => {

      if (this.apps.bas.envtest) console.log("saveEvent, json: ", json);
      if (json.success) {
     
        
        if (json.event.isComment) {
          this.apps.bas.ui.success("Lagret!");
          this.lead.obj.commentEvent = json.event;
          this.lead.obj.providerComment = json.event.value;

        }
      }

      return json;
    });
  }

  getLeadEvents() {
    this.apps.bas.ws.json({ 
      checkChildApp: true,
      aType: "customAction", 
      action: "getLeadEvents", 
      lead: this.lead.obj.id 
    
    }).then((json) => {

      if (this.apps.bas.envtest) console.log("getEvents, json: ", json);
      if (json.success) {

        let events = json.events || [];
        let ets = json.eventTypes || { };
        let etas = json.eventTypesAll || { };

        this.lead.obj.events = events;
        this.lead.obj.eventTypes = ets;
        this.lead.obj.eventTypesAll = etas;
        this.lead.obj.commentEvent = json.commentEvent || { id: undefined, value: "" };
      
        this.lead.obj.subType = json.subType;
        this.lead.obj.threshold = json.threshold;
        this.lead.obj.count = json.count;
        this.lead.obj.hasAccess = json.hasAccess;
        
        
        // json.put("subType", subType);
        // json.put("threshold", threshold);
        // json.put("count", count);
        // json.put("hasAccess", hasAccess);
      }
    });
  }

  openLead(lead:any) {

    if (this.apps.bas.envtest) console.log("openLead: ", lead);
    
 

    /*


    */

    let cListOptions:any = { skipEmpty: true };

    if (lead.leadTypeObj.isSimple) {
      this.leadRows.electric = this.apps.bas.ui.toContentList(lead, {
        "minRange": "Minimum rekkevidde i km (WLTP)",
        // "minSeats": "Min. seter",
        "maxAge": "Max. alder",
        "mileageMax": "Max. km-stand",
        "carSizeIds": { label: "Størrelser", value: lead.carSizes.map((item:any) => item.name).join(", ") },
        "wheelDrive.name": "Hjuldrift",
        "mkTowbar": "Tilhengerfeste",
        "trailerWeight": "Tilhengervekt (kg)",
  
      }, cListOptions);
  

      this.leadRows.fossil = this.apps.bas.ui.toContentList(lead, {
  
        "maxAge": "Max. alder",
        "mileageMax": "Max. km-stand",
        "carSizeIds": { label: "Størrelser", value: lead.carSizes.map((item:any) => item.name).join(", ") },
        "propulsionIds": { label: "Fremdrift", value: lead.propulsions.map((item:any) => item.name).join(", ") },
        "wheelDriveIds": { label: "Hjuldrift", value: lead.wheelDrives.map((item:any) => item.name).join(", ") },
        "transmissionId": { label: "Gir", value: lead.transmissions.map((item:any) => item.name).join(", ") },
       
      }, cListOptions);

  
      this.leadRows.commercial = this.apps.bas.ui.toContentList(lead, {
        "chassisIds": { label: "Karroseri", value: lead.chassiss.map((item:any) => item.name).join(", ") },
        "propulsionIds": { label: "Fremdrift", value: lead.propulsions.map((item:any) => item.name).join(", ") },
        "transmissionId": { label: "Gir", value: lead.transmissions.map((item:any) => item.name).join(", ") },
        "wheelDriveIds": { label: "Hjuldrift", value: lead.wheelDrives.map((item:any) => item.name).join(", ") },
        
        "maxAge": "Max. alder",
        "mileageMax": "Max. km-stand",
        "minPayload": "Min. lastekapasitet",
        "mkTowbar": "Tilhengerfeste",
        "trailerWeight": "Tilhengervekt (kg)",
       
      }, cListOptions);


    }
   
    if (lead.leadTypeObj.isAdvanced) {

      this.leadRows.advanced = this.apps.bas.ui.toContentList(lead, {
        // "maxAge": "Max. alder",
        // "mileageMax": "Max. km-stand",
        "make": "Merke", // { label: "Merke og modeller", value: lead.makeAndModelsAsList.join(", ") },
        "model": "Modell", 
        "yearRangeDisplay": "Årsmodell",
        "mileageRangeDisplay": "Km.stand",
       
        // "chassisIds": { label: "Karroseri", value: lead.chassiss.map((item:any) => item.name).join(", ") },
        "transmissionId": { label: "Gir", value: lead.transmissions.map((item:any) => item.name).join(", ") },
        "wheelDriveIds": { label: "Hjuldrift", value: lead.wheelDrives.map((item:any) => item.name).join(", ") },
        "propulsionIds": { label: "Fremdrift", value: lead.propulsions.map((item:any) => item.name).join(", ") },
        // "taxClassIds": { label: "Avgiftsklasser", value: lead.taxClasss.map((item:any) => item.name).join(", ") },
        
      }, cListOptions);

    }

    this.leadRows.s1 = this.apps.bas.ui.toContentList(lead, {
      // "id": "common.id",
      // "leadType.name": "Type",
   
      // "amountDouble": { label: "common.amount", type: "double" },
      
      "priceRangeDisplay": "Ønsket pris (fra-til)",
      "time": "common.time", 
    });

    this.leadRows.s2 = this.apps.bas.ui.toContentList(lead, {

      "comment" : "Kommentar"
    }, cListOptions);



    let tradein = {
      "mkTradein": "Innbytte",
    }

    if (lead.tradein) {
      MiscUtil.extend(tradein, {

        "tradeinLicenseNumber": "Regnr",
        "tradeinMakeAndModel": "Merke og modell",
        "tradeinYear": "Registreringsår",
        "tradeinMileage": "Km.stand",
        "mkTradeinSecuredLoan": "Har du lån med pant i bilen?",
        "tradeinSecuredLoanAmount": "Gjennstående beløp (ca)",
        // "mkTradeinImported": "Bruktimportert",
        // "mkTradeinSeriousIssues": "Har bilen problemer med motor, drivverk eller lignende?",
        "mkTradeinPreviousCollisionDamage": "Har bilen din tidligere skader fra kollisjon?",
        "mkTradeinConditionReport": "Har du nylig fått en takst eller tilstandsrapport på bilen?",
        "tradeinOtherInfo": "Annet vi burde vite?",
        


      })
    }

    
    this.leadRows.tradein = this.apps.bas.ui.toContentList(lead, tradein, cListOptions);


    let financing = {
      "mkFinancing": "Ønsker du finansiering?",

    }

    if (lead.tradein) {
      MiscUtil.extend(financing, {

        "monthlyAmount": "Månedlig beløp",
        "equity": "Egenkapital i tillegg til eventuell innbyttebil",
        


      })
    }


    this.leadRows.financing = this.apps.bas.ui.toContentList(lead, financing, cListOptions);

    let contactInfo:any = {
      "userObj.name": "common.name",
      "userObj.zipCode": { label: "Poststed", value: lead.userObj.zipCode + " " + lead.userObj.city },
      "mkContactEmail": "Kontakt på epost",
      "mkHomeDelivery":  { label: "Ønsker du hjemlevering?", css: "text-bold" },
      // 
    }
    if (lead.contactEmail) contactInfo["userObj.username"] = "E-post";

    contactInfo["mkContactTelephone"] = "Kontakt på telefon";

    if (lead.contactTelephone) {
      MiscUtil.extend(contactInfo, {
        "userObj.telephone": "Telefon",
        "contactTelephoneTime.name": "Telefontid",
      });
    }


    this.leadRows.contactInfo = this.apps.bas.ui.toContentList(lead,contactInfo, cListOptions);


    //this.lead.obj.eventTypes.ShowContact = false;

    this.lead.obj = lead;
    if (!lead.icon) {
      lead.icon = 'eye';
      lead.iconColor = '#1e18ea'
    }

    this.saveEvent({ leadEvent: "ShowLead" }).then(() => {
      this.getLeadEvents();

    });

  }

  
  onQueryParamsChange(queryParams: NzTableQueryParams): void {
    //console.log("onQueryParamsChange: ", queryParams);
    this.prevTableQueryParams = queryParams;
    const { pageSize, pageIndex, sort, filter } = queryParams;
    
    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || null;
    const sortOrder = (currentSort && currentSort.value) || null;

    let params:any = { };
    
    let page = pageIndex !== undefined && pageIndex > 0 ? pageIndex -1 : undefined;
    let nav = this.fbo.stats.navigatorObj;
    if ((page !== undefined && nav.page != page )) { 
      params.navAction = "page";
      params.navParam = page;
    } else if ((pageSize !== undefined && nav.pageSize != pageSize )) { 
      params.navAction = "pageSize";
      params.navParam = pageSize;
    }

    let sfi:any = { }
    if (sortField) {
      let col = this.columns[sortField];
      sfi.customSortsList = [
     
        { field: col.sortField || sortField, asc: sortOrder === "ascend" }
      ];

    }

    if (filter) {
      sfi.customFiltersMap = { };
      for (let item of filter) {
        if (item.value == null || item.value.length == 0) continue;
        let col = this.columns[item.key];

        sfi.customFiltersMap[col.sortField || item.key] = { value: item.value };

      }
    }

    this.updateFbo(params, sfi);
  }

  updateFbo(input:any = { }, statsFormInput:any = { }) {

    if (!this.apps.bas.ds.login.success) {
      if (this.apps.bas.envtest) console.log("updateFbo, !this.apps.bas.ds.login.success, input: ", input);
      return;
    }

    let params:any = {
      checkChildApp: true,
      aType: "customAction", 
      action: "getLeads", 
    };

    let rv = { };
    if (this.df.form) {
      rv = this.apps.bas.fs.getRawValue(this.df.form, this.df.controls);
      if (rv === false) return;
  
    }
 
    statsFormInput.customFiltersMap = statsFormInput.customFiltersMap || { };
    for (let key in this.customFilters) {
      let val = this.customFilters[key];
      if (val == undefined || val == "") continue; 
      statsFormInput.customFiltersMap[key] = { value: val, type: "search" };
    }

    MiscUtil.extend(rv,  this.config.params);
    MiscUtil.extend(rv,  statsFormInput);


    MiscUtil.extend(params, input);

    MiscUtil.extend(params, this.df.params);
    MiscUtil.extend(params, { 
      statsForm: JSON.stringify(rv),
      navForm:  JSON.stringify({ pageSize: this.fbo.stats.navigatorObj.pageSize })
    } );

    // console.log("updateFbo.config: ", this.config);
    if (this.apps.bas.envtest) console.log("updateFbo.params: ", params);

    this.fbo.loading = true;
    this.apps.bas.ws.json(params ).then((json:any) => {
      if (this.apps.bas.envtest) console.log("updateFbo.then, json: ", json, ", curret size: " + (json.stats?.current || []).length);

      //TODO: må lagre dssse veriene lokalt slik at man ikke må stille inn hver gang siden lastes.

      if (json.success) {
        // this.updateForm(json.stats); //TODO

        let fbo = json;

        for (let lead of fbo.stats.current || []) {


          let icon:string|undefined = undefined;
          let iconColor:string|undefined = undefined;

          if(lead.archived) {
             icon = 'close-circle';
             iconColor = '#eb2f96'
          }

          let em = fbo.eventMap[lead.id];
          if (em) {
          
          

            // && fbo.eventMap[data.id]['ConfirmContact'] 
            //&& !fbo.eventMap[data.id]['ProviderRemove']
            // 'check-circle

            
            if (em['ConfirmSale']) {
              icon = 'dollar-circle';
              // iconColor = '#52c41a'
              iconColor = '#f29e3e'  // orange
            }
            else if (em['ConfirmContact']) {
              icon = 'phone';
              iconColor = '#52c41a' // grønn
            }
            else if (em['ShowLead']) {
              icon = 'eye';
              iconColor = '#1e18ea'
            }

    
          }

          lead.icon = icon;
          lead.iconColor = iconColor;

        }

        this.fbo = fbo;
        
      } else {

      }
      

     });
  }

  
  dfSubmit() {
    this.updateFbo();
  }

    
  updateForm(obj:any) {
    

    let controls: DynformControl[] = [];

    
    controls.push(new DynformControl({ key: 'search',               mk: 'common.search' }));
    controls.push(new DynformControl({ key: 'currentJil', value: "max" }));
    
    // controls.push(new DynformControl({ 
    //   key: 'product',  
    //   // valuePath: "ruleId",
    //   mk: 'common.product',  
    //   controlType: 'select', 
    //   // required: true,
    //   options: () => {
    //     return obj.products || []; 
    //   },
    //   optionsAllowEmpty: true,
    //   optionsFieldValue: "id",
    //   optionsFieldLabel: "mkName"
    // }));

    // controls.push(new DynformControl({ key: 'statusMap',     mk: 'common.status', controlType: "order-status", data: { statusList:  obj.statusList } }));


    this.apps.bas.fs.updateFormObj(this.df, controls, obj);


  }



}
