
import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MiscUtil } from 'src/app/lib/core/util/misc.util';

import { AppService } from 'src/app/services/app.service';

import { DynformControl } from 'src/app/lib/core/model/dymform-control';

@Component({
  selector: 'app-admin-misc-session-status-page',
  templateUrl: './admin-misc-session-status-page.component.html',
  styleUrls: ['./admin-misc-session-status-page.component.scss']
})
export class AdminMiscSessionStatusPageComponent {

  objects:any[] = [];
  fbo:any = { };
  status:any = { }


  edit:any = {
    obj: undefined,
   
  }
  
  // https://192.168.1.82:8200/api/kragerokystlag/admin/misc/session-status
  constructor(public injector:Injector, public apps:AppService) {
    let route = injector.get(ActivatedRoute);
    this.apps.bas.us.checkActivatedRoute(route.snapshot);

    if(this.apps.bas.envtest) console.log("AdminMiscSessionStatusPageComponent");

    this.getSessionStatus();
  }

  ngOnInit(): void {

  }

 
  getSessionStatus() {
    this.apps.bas.ws.json({ aType: "appAdmin", action: "getSessionStatus" }).then((json) => {
      if (json.success) {
        if(this.apps.bas.envtest) console.log("getSessionStatus: ", json);

        this.objects = json.objects || [];
        this.fbo = json.fbo || { };
        this.status = json || { };
      }
    });
  }

  newObject() {


    this.editObject(this.fbo.emptyObject || { 
      parentId: ""
    }); //TODO
  }

  editObject(obj:any) {

    if(this.apps.bas.envtest) console.log("editObject, obj: ", obj);

    let controls: DynformControl[] = [];

    controls.push(new DynformControl({ 
      key: 'url',     
      mk: 'common.url',
    }));
    



    this.apps.bas.fs.updateFormObj(this.edit, controls, obj);
  } 

  onEditCancel() {
    this.edit.obj = undefined;
  }
  onEditOk() {

  //   let rv = this.apps.bas.fs.getRawValue(this.edit.form, this.edit.controls);
  //   if (rv === false) return;
    

  //   if(this.apps.bas.envtest) console.log("onEditOk, rv: ", rv);

  //   let obj = this.edit.obj;
  //   let isNew = !obj.id;

  //   let params:any = {
  //     aType: "appAdmin", 
  //     action: "saveObject", 
  //     type: "link",
  //     id: isNew ? "new" : obj.id,
  //     isNew: isNew,
  //     object: JSON.stringify(rv)
  //   }

  //   this.apps.bas.ws.json(params).then((json) => {
  //     if (json.success) {
        
  //       if (isNew) this.objects = [json.object, ...this.objects ];
  //       else {
  //         let itemIndex = this.objects.findIndex(obj => obj.id == json.object.id);
  //         this.objects[itemIndex] =  json.object;
  //         this.objects = [...this.objects];
  //         // this.getObjects();
  //       }
  //     }
  //   });
    
  //   this.edit.obj = undefined;
  }



}
