<div nz-row [nzGutter]="grid.gutter" nzAlign="middle">
  <div 
    *ngFor="let col of cols || []" 
    [class.bt-row-hide]="col && !col.show()" 
    nz-col 
    [nzXs]="grid.xs" 
    [nzSm]="grid.sm" 
    [nzMd]="grid.md" 
    [nzLg]="grid.lg" 
    [nzXl]="grid.xl" 
    [nzXXl]="grid.xxl"
    >
    
    <nz-form-item>
      <nz-form-label *ngIf="col.label">
        <span class="text-bold">{{ col.label | actrans }}</span>

        <!-- <i 
          class="mkInfoIcon"
          *ngIf="col.mkInfo"
          nz-icon [nzType]="'info-circle'" 
          nz-tooltip 
          [nzTooltipTitle]="mkInfoTemplate" 
          >
          <ng-template #mkInfoTemplate>
            <span class="text-sm" [innerHtml]="col.mkInfo | actrans"></span>
          </ng-template>
        </i> -->
        
      </nz-form-label>
      <nz-form-control [class]="col.css || ''">
        <label *ngIf="col.value" [innerHtml]="col.value | trusthtml" >
       
        </label>
      </nz-form-control>
    </nz-form-item>

  </div>
</div>
