
import { Pipe, PipeTransform, Injector } from "@angular/core";
import { Inject } from '@angular/core';
import { SafeHtml } from "@angular/platform-browser";
import { AppService } from "src/app/services/app.service";
import { BooktechAppService } from "../services/booktech-app.service";


/*
*/
@Pipe({name: 'int'})
export class IntPipe implements PipeTransform {

  constructor(injector:Injector, public apps:AppService) {

    
  }


  transform(input: any): number {
    if (input === undefined) return 0;
    let int = parseInt(input);
    return int ? int : 0;
  }
}
