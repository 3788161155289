import { Component, Input, OnInit, Injector, EventEmitter, Output } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { MiscUtil } from 'src/app/lib/core/util/misc.util';
import { BtContent } from 'src/app/lib/core/model/bt-content';

@Component({
  selector: 'btlib-content-row',
  templateUrl: './bt-content-row.component.html',
  styleUrls: ['./bt-content-row.component.scss']
})
export class BtContentRowComponent implements OnInit {

  @Input() cols: BtContent[] = [];

  @Input() grid: any = { };
  g = {
    xs: null,
    sm: null,
    md: null,
    lg: null,
    xl: null,
    xxl: null,
    gutter: 16
  };

  constructor(public injector:Injector, public apps:AppService) {

  }

  ngOnInit(): void {
    MiscUtil.extend(this.g, this.grid);

  }

}
