<ng-container [ngSwitch]="true">
              
  <div *ngSwitchCase="apps.bas.ds.findCid() === 'bergensportsreiser'">

    <!-- TODO <bsr-menu class=""></bsr-menu> TODO: går det an å bruke en hook på en måte? Kanskje ha noen "faste" tomme filer i alle prosjekter, litt på samme måte som app.service. -->
  </div>
  <!-- <div *ngSwitchCase="">

  </div> -->
  <div *ngSwitchDefault>

    <div *ngIf="apps.bas.aas.isAdminProvider()" style="max-width: 1600px;" >
  
      <div class="m-d">
        <div nz-row [nzGutter]="8">
          <div nz-col  nz-col nzXs="12" nzSm="6"  nzXl="3" >
            <button nz-button nzType="default" nzBlock nz-dropdown  nzTrigger="click" [nzDropdownMenu]="
            apps.bas.settings.appId == 'cbadmin' 
              ? menuCbadmin
              : menuAdminCbapi5
              
              " id="btn-admin-menu" >
              <i nz-icon [nzType]="'menu'" ></i> <span>{{ 'common.admin' | actrans  }}</span>
            </button>
          </div>
          <!-- <div nz-col  nzFlex="auto"> -->
            <!-- nz-row [nzGutter]="[8, 8]" class="autocomplete-inputs inline-block-off" -->
            <!-- <div   *ngIf="showAutocomplete"> -->
              <div nz-col nzXs="12" nzSm="6" nzXl="7"   *ngIf="showAutocomplete" >

                <nz-input-group *ngIf="apps.bas.aas.isAdmin()" [nzPrefix]="prefixIconUser" class="w-auto">
                  <input
                    placeholder="Bruker..."
                    nz-input
                    [(ngModel)]="autocomplete.user.value"
                    (ngModelChange)="onAutocompleteChange($event, 'user')"
                    [nzAutocomplete]="autocompleteUser"
                  />
                </nz-input-group>
                <ng-template #prefixIconUser>
                  <span *ngIf="apps.bas.ui.r.gtesm" nz-icon nzType="user"></span>
                </ng-template>
                <nz-autocomplete #autocompleteUser>
                  <nz-auto-option class="global-search-item" *ngFor="let option of autocomplete.user.options" [nzValue]="option.label || option.value" (click)="onAutocompleteClick('user', option)">
                    {{ option.label || option.value }}
            
                  </nz-auto-option>
                </nz-autocomplete>
              </div>
        
              <!---->
              <div nz-col  nzXs="12" nzSm="6" nzXl="7"   *ngIf="showAutocomplete" >

                <nz-input-group [nzPrefix]="prefixIconProduct" class="w-auto">
                  <input
                    placeholder="Produkt..."
                    nz-input
                    [(ngModel)]="autocomplete.product.value"
                    (ngModelChange)="onAutocompleteChange($event, 'product')"
                    [nzAutocomplete]="autocompleteProduct"
                  />
                </nz-input-group>
                <ng-template #prefixIconProduct>
                  <span *ngIf="apps.bas.ui.r.gtesm" nz-icon nzType="shop"></span>
                </ng-template>
                <nz-autocomplete #autocompleteProduct>
                  <nz-auto-option class="global-search-item" *ngFor="let option of autocomplete.product.options" [nzValue]="option.label || option.value" (click)="onAutocompleteClick('product', option)">
                    {{ option.label || option.value }}
            
                  </nz-auto-option>
                </nz-autocomplete>
              </div>
              <!---->
        
              <div nz-col  nzXs="12" nzSm="6" nzXl="7"   *ngIf="showAutocomplete" >

                <nz-input-group *ngIf="apps.bas.aas.isAdmin()" [nzPrefix]="prefixIconBooking" class="w-auto">
                  <input
                    placeholder="Booking..."
                    nz-input
                    [(ngModel)]="autocomplete.booking.value"
                    (ngModelChange)="onAutocompleteChange($event, 'booking')"
                    [nzAutocomplete]="autocompleteBooking"
                  />
                </nz-input-group>
                <ng-template #prefixIconBooking>
                  <span *ngIf="apps.bas.ui.r.gtesm" nz-icon nzType="cloud"></span>
                </ng-template>
                <nz-autocomplete #autocompleteBooking>
                  <nz-auto-option class="global-search-item" *ngFor="let option of autocomplete.booking.options" [nzValue]="option.label || option.value" (click)="onAutocompleteClick('booking', option)">
                    {{ option.label || option.value }}
            
                  </nz-auto-option>
                </nz-autocomplete>
              </div>
        
              <!-- <span>{{ apps.bas.ui.r.gtesm }}</span> -->
        
            <!-- </div> -->

          <!-- </div> -->

        </div>
        <!-- (click)="menuDrawerVisible = !menuDrawerVisible;" -->

        <!-- <button *ngIf="apps.bas.aas.isSu()" nz-button nzType="default" nz-dropdown  nzTrigger="click" [nzDropdownMenu]="menuSu" id="btn-su-menu" >
          <i nz-icon [nzType]="'menu'" ></i> <span>{{ 'bus.user.role.superUser' | actrans  }}</span>
        </button> -->
    
      </div>
    
      <nz-dropdown-menu #menuCbadmin="nzDropdownMenu">
        <ul nz-menu>

          <li nz-menu-item [routerLink]="apps.bas.ui.getRouterPrefix() + '/order/dashboard'">{{ 'common.dashboard' | actrans  }}</li>
          <li nz-menu-item [routerLink]="apps.bas.ui.getRouterPrefix() + '/order/overview'">{{ 'web.common.overview' | actrans  }}</li>
          <li nz-menu-item [routerLink]="apps.bas.ui.getRouterPrefix() + '/order/orders'">{{ 'web.common.bookings' | actrans  }}</li>
          <li nz-menu-item [routerLink]="apps.bas.ui.getRouterPrefix() + '/product/products'">{{ 'common.products' | actrans  }}</li>
          <li nz-menu-item [routerLink]="apps.bas.ui.getRouterPrefix() + '/user/user'">{{ 'web.common.user' | actrans  }}</li>
          <li nz-menu-item [routerLink]="apps.bas.ui.getRouterPrefix() + '/misc/reports'"  >{{ 'web.common.reports' | actrans  }}</li>
          <li nz-menu-item [routerLink]="apps.bas.ui.getRouterPrefix() + '/misc/stats'"  >{{ 'web.common.stats' | actrans  }}</li>
          <li nz-menu-item [routerLink]="apps.bas.ui.getRouterPrefix() + '/misc/misc-stats'"  >{{ 'web.common.miscStats' | actrans  }}</li>

         
        </ul>
        <ul nz-menu *ngIf="apps.bas.aas.isAdmin()" >
          <!-- <li nz-menu-divider></li> -->
          <li *ngIf="apps.bas.aas.isBn() || user.upApAdminCompany" nz-menu-item [routerLink]="apps.bas.ui.getRouterPrefix() + '/user/user'" [queryParams]="{ id: company.id }" >{{ 'web.admin.editCompany' | actrans  }}</li>
          <li nz-menu-item [routerLink]="apps.bas.ui.getRouterPrefix() + '/product/areas'" >{{ 'web.common.areas' | actrans  }}</li>
          <li nz-menu-item [routerLink]="apps.bas.ui.getRouterPrefix() + '/user/users'" >{{ 'web.common.users' | actrans  }}</li>
          <li nz-menu-item [routerLink]="apps.bas.ui.getRouterPrefix() + '/product/product-types'" >{{ 'web.common.productTypes' | actrans  }}</li>
          <li nz-menu-item [routerLink]="apps.bas.ui.getRouterPrefix() + '/product/property-types'" >{{ 'web.common.propertyTypes' | actrans  }}</li>
          <li nz-menu-item [routerLink]="apps.bas.ui.getRouterPrefix() + '/misc/links'" >{{ 'web.common.links' | actrans:[]:false:'Links'  }}</li>
          
          <li *ngIf="company.upCmSms" nz-menu-item (click)="orderSendSms()" >{{ 'web.ajax.sms' | actrans  }}</li>
          <li nz-menu-item (click)="orderSendEmail()" >{{ 'web.ajax.email' | actrans  }}</li>
          <li *ngIf="company.upCmApp" nz-menu-item (click)="orderSendPushMessage()" >{{ 'web.admin.app.sendPushMessage' | actrans  }}</li>
          
    
        </ul>
    
        <ul nz-menu *ngIf="apps.bas.aas.isBn()" >
          <li nz-menu-divider></li>
          <li nz-menu-item [routerLink]="apps.bas.ui.getRouterPrefix() + '/misc/bank-integration'"  >{{ 'web.admin.bankIntegration' | actrans  }}</li> <!-- TODO: if ZRL-->
         
          <li nz-menu-item [routerLink]="apps.bas.ui.getRouterPrefix() + '/misc/tools'"  >{{ 'web.common.tools' | actrans  }}</li>
          <li nz-menu-item [routerLink]="apps.bas.ui.getRouterPrefix() + '/app/spacebooking/admin'"  >{{ 'Spacebooking Admin' | actrans  }}</li><!-- TODO: if Spacebooking-->
          <li nz-menu-item [routerLink]="apps.bas.ui.getRouterPrefix() + '/misc/app-config'"  >{{ 'web.admin.appConfig' | actrans  }}</li><!-- TODO: if app-->
          <li nz-menu-item [routerLink]="apps.bas.ui.getRouterPrefix() + '/misc/app-content'"  >{{ 'web.admin.website' | actrans  }}</li><!-- TODO: if app -->

          <li nz-menu-item [routerLink]="apps.bas.ui.getRouterPrefix() + '/misc/tasks'"  >{{ 'common.tasks' | actrans  }}</li>
        </ul>
    
        <ul nz-menu *ngIf="apps.bas.aas.isSu()" >
          <li nz-menu-item [routerLink]="apps.bas.ui.getRouterPrefix() + '/superuser/properties'"  >{{ 'web.su.properties' | actrans  }}</li>
        </ul>
    

        <ul nz-menu *ngIf="apps.bas.aas.isBn() || (apps.bas.aas.isAdminProvider() && company.upCbWebAdminClassicEnabled )" >
          <li nz-menu-item><a  [href]="apps.bas.ws.getBaseUrl() + '/admin/'" target="_blank" class="fgc-black">Cloud Booking backoffice klassisk</a></li>
        </ul>
        <ul nz-menu>
          <!-- <li nz-menu-divider></li> -->
          
          <li nz-menu-item (click)="apps.bas.api.logout()">{{ 'app.lib.common.logout' | actrans  }} </li>
          
        </ul>
    
    
      </nz-dropdown-menu>
    
    

      <!--  CBAPI5 -->
      <nz-dropdown-menu #menuAdminCbapi5="nzDropdownMenu">
        <ul nz-menu>
          <li nz-menu-item [routerLink]="apps.bas.ui.getRouterPrefix() + '/admin/'" >{{ 'common.admin' | actrans  }}</li>
          <li nz-menu-item [routerLink]="apps.bas.ui.getRouterPrefix() + '/admin/products'">{{ 'common.products' | actrans  }}</li>
          <li nz-menu-item [routerLink]="apps.bas.ui.getRouterPrefix() + '/admin/orders'">{{ 'web.common.bookings' | actrans  }}</li>
          <li nz-menu-item [routerLink]="apps.bas.ui.getRouterPrefix() + '/admin/order-overview'">{{ 'web.common.overview' | actrans  }}</li>
          <li nz-menu-item [routerLink]="apps.bas.ui.getRouterPrefix() + '/common/user/user'">{{ 'web.common.user' | actrans  }}</li>
          <li nz-menu-item [routerLink]="apps.bas.ui.getRouterPrefix() + '/admin/reports'">{{ 'web.common.reports' | actrans  }}</li>
          <li nz-menu-item [routerLink]="apps.bas.ui.getRouterPrefix() + '/admin/stats'">{{ 'web.common.stats' | actrans  }}</li>
         
        </ul>
        <ul nz-menu *ngIf="apps.bas.aas.isAdmin()" >
          <!-- <li nz-menu-divider></li> -->
          <li nz-menu-item [routerLink]="apps.bas.ui.getRouterPrefix() + '/admin/areas'" >{{ 'web.common.areas' | actrans  }}</li>
          <li nz-menu-item [routerLink]="apps.bas.ui.getRouterPrefix() + '/admin/user/users'" >{{ 'web.common.users' | actrans  }}</li>
          <li nz-menu-item [routerLink]="apps.bas.ui.getRouterPrefix() + '/admin/productTypes'" >{{ 'web.common.productTypes' | actrans  }}</li>
          <li nz-menu-item [routerLink]="apps.bas.ui.getRouterPrefix() + '/admin/propertyTypes'" >{{ 'web.common.propertyTypes' | actrans  }}</li>
          
          <li *ngIf="company.upCmSms" nz-menu-item (click)="orderSendSms()" >{{ 'web.ajax.sms' | actrans  }}</li>
          <li nz-menu-item (click)="orderSendEmail()" >{{ 'web.ajax.email' | actrans  }}</li>
          <li *ngIf="company.upCmApp" nz-menu-item (click)="orderSendPushMessage()" >{{ 'web.admin.app.sendPushMessage' | actrans  }}</li>
          
    
        </ul>
    
        <ul nz-menu *ngIf="apps.bas.aas.isBn()" >
          <!-- <li nz-menu-divider></li>  -->
          <li nz-menu-item [routerLink]="apps.bas.ui.getRouterPrefix() + '/admin/misc/tools'"  >{{ 'web.common.tools' | actrans  }}</li>
        </ul>
    
        <ul nz-menu *ngIf="apps.bas.aas.isSu()" >
          <!-- <li nz-menu-divider></li>  -->
          <li nz-menu-item [routerLink]="apps.bas.ui.getRouterPrefix() + '/superuser/properties'"  >{{ 'web.su.properties' | actrans  }}</li>
        </ul>
    
        <ul nz-menu>
          <!-- <li nz-menu-divider></li> -->
          
          <li nz-menu-item (click)="apps.bas.api.logout()">{{ 'app.lib.common.logout' | actrans  }} </li>
          
        </ul>
    
    
      </nz-dropdown-menu>
      
      <nz-dropdown-menu #menuSu="nzDropdownMenu">
        <ul nz-menu>
         
        </ul>
      </nz-dropdown-menu>
    
    </div>
    
    <div *ngIf="apps.bas.aas.isAdmin()" >
      <btlib-sms-modal *ngIf="company.upCmSms" [input]="smsInput"></btlib-sms-modal>
      <btlib-email-modal [input]="emailInput"></btlib-email-modal>
      <btlib-send-push-modal *ngIf="company.upCmApp" [input]="sendPushInput"></btlib-send-push-modal>
    
    </div>

  </div>


</ng-container>




<btlib-login-check [rolesRequired]="['SuperUser', 'Admin', 'Provider']" ></btlib-login-check>

