import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MiscUtil } from 'src/app/lib/core/util/misc.util';

import { AppService, APP_DATAID } from '../../../../../services/app.service';
import { BtContent } from '../../../model/bt-content';
import { DATAID } from '../../../services/data.service';

import { DynformControl } from 'src/app/lib/core/model/dymform-control';

@Component({
  selector: 'btlib-send-push-modal',
  templateUrl: './send-push-modal.component.html',
  styleUrls: ['./send-push-modal.component.scss']
})
export class SendPushModalComponent implements OnInit {

  // order:any = { }

  recipient:string = "";
  manual = false;

  fbo:any = {
    appConfigs: []
  };

  df:any = {
    form: undefined,
    controls: [],
    obj: undefined,

    processing: false
  }

  @Input() 
  set input(input: any) {
    if (!input) {
      this.recipient = "";
      this.getFbo();
      return;
    }

    let c = this.apps.bas.ds.config.company;
    if (!c.upCmApp) {
      console.log("!c.upCmApp: ", c)
      this.recipient = "";
      this.manual = false;
      return;
    }


    if (input.manual) {
      this.manual = input.manual;
      this.getFbo();
      return;
    }

    if (input.recipient) {
      this.recipient = input.recipient;
      this.getFbo();
      // console.log("this.recipients: ", this.recipients);
      return;
    }

    // console.log("input: ", input)

    if (input.orders) {
      let orders:any[] = input.orders;
      this.recipient = orders.map((order:any) => order.userEmailAdress).filter(mail => mail).find(Boolean); 
      this.getFbo();
      return;
    }
    if (input.bis) {
      let bis:any[] = input.bis;
      this.recipient = bis
        .map((order:any) => order.bookingObj?.userEmailAdress)
        .filter(mail => mail)
        .find(Boolean); 
      this.getFbo();
      return;
    }
  }

  @Output() change = new EventEmitter<any>();

  
  constructor(public apps:AppService) {
  
    // this.getFbo();
  }

  getFbo() {
    if (this.apps.bas.envtest) console.log("getFbo. ", this.fbo);

    this.apps.bas.ws.json({ 

      aType: "appAdmin", 
      action: "getSendPushMessageFbo"
    
    }).then((json) => {

      if (this.apps.bas.envtest) console.log("getFbo, json: ", json);
      if (json.success) {
        this.fbo = json.fbo;
        this.updateForm();
      }
    })

  }


  ngOnInit(): void {

  }

  closeModal() {
    this.recipient = "";
    this.manual = false;
  }

  updateForm() {

    let controls: DynformControl[] = [];

    let obj = this.fbo.an;
    // TODO: appConfigs?

    if (!this.fbo.appConfigs?.length) {
      if (this.apps.bas.envtest) console.log("!this.fbo.appConfigs?.length. ", this.fbo);

      return;
    }

    let acs = this.fbo.appConfigs;

    controls.push(new DynformControl({ 
      key: 'appId',   
      mk: 'bus.appConfig.appId', 
      controlType: 'select', 
      required: true,
      // show: () => acs.length > 1,
      options: () => {
        return acs;
      }, 
      optionsAllowEmpty: acs.length > 1 ,
      optionsFieldValue: "appId", 
      optionsFieldLabel: "appId",
      value: acs.length > 1 ? undefined : acs[0].appId
    }));


    if (this.manual) {
      controls.push(new DynformControl({ 
        key: 'recipient',   
        mk: 'Mottaker', 
        // controlType: "input", 
        // selectMode: "tags",
        value: this.recipient
      }));
    } else {
      controls.push(new DynformControl({ 
        key: 'recipient',   
        mk: 'Mottaker', 
        controlType: "label", 
        value: this.recipient
      }));
    }

    
    controls.push(new DynformControl({ key: 'title',   mk: 'common.title', required: true }));
    controls.push(new DynformControl({ key: 'message',   mk: 'common.message', controlType: 'textarea', required: true }));
    controls.push(new DynformControl({ key: 'url',   mk: 'common.url' }));
    controls.push(new DynformControl({ key: 'sendEmail',   mk: 'web.admin.appConfig.sendEmail', controlType: 'checkbox' }));
    controls.push(new DynformControl({ key: 'schedule',   mk: 'web.admin.appConfig.schedule', controlType: 'checkbox' }));

    controls.push(new DynformControl({ 
      key: 'sendTimeAsString',   
      mk: 'bus.appNotification.sendTime', 
      controlType: "datetime-picker",
      show: () => this.df.form.controls.schedule.value
    }));

    controls.push(new DynformControl({ 
      key: 'expireAsString',   
      mk: 'common.expires', 
      controlType: "datetime-picker",
      show: () => this.df.form.controls.schedule.value
    }));

    this.apps.bas.fs.updateFormObj(this.df, controls, obj);

    /*

            
             <div class="form-group">
               <div class="col-sm-offset-4-col-sm-8">
                 <div class="checkbox">
                   <label class="" for="pm_sendEmail">
                     <input type="checkbox" id="pm_sendEmail" name="sendEmail" class="jpField" />
                     <nit:message key="web.admin.appConfig.sendEmail" defaultText="Send også meldingen på epost til angitt mottaker" />
                   </label>
                 </div>
               </div>
            </div>
              
            <c:if test="${wpd.isBn}">
            
              <div class="form-group">
                <label class="control-label" for="topic"><nit:message key="common.topic"/> (bn):</label>
                <div class="controls"><input type="text" id="pm_topic" name="topic" class="form-control jpField" value="" /></div>
              </div>
              <div class="form-group">
                  <div class="col-sm-offset-4-col-sm-8">
                      <div class="checkbox">
                          <label class="" for="pm_dryRun">
                              <input type="checkbox" id="pm_dryRun" name="dryRun" class="jpField" />
                              <nit:message key="web.admin.appConfig.dryRun"/> (bn)
                          </label>
                      </div>
                  </div>
              </div>
            </c:if>
    
            

    */

  }

  dfSubmit() {


    let rv = this.apps.bas.fs.getRawValue(this.df.form, this.df.controls);

    if (rv === false) return;

    // rv.biid = this.df.obj.id;

    if (this.apps.bas.envtest) console.log("formSubmit, rv: ", rv);

    this.df.processing = true;

    // String type = WebUtil.getParam(request, "type", "manual");
		// 	String recipients = WebUtil.getParam(request, "recipients");
		// 	String subject = WebUtil.getParam(request, "subject");
		// 	String message = WebUtil.getParam(request, "message");
			
    // if (!this.apps.bas.envtest) return;

    let params = MiscUtil.extend({ 
      aType: "appConfig", 
      action: "sendPushMessage"
    }, rv);

    if (this.apps.bas.envtest) console.log("formSubmit, params: ", params);

    this.apps.bas.ws.json(params
    //   { 
    //   recipients: this.manual ? rv.recipients : this.recipients.join(","),
    //   subject: rv.subject,
    //   message: rv.message
    // }
    ).then((json) => {

    // this.apps.bas.ws.json({ 

    //   aType: "appConfig", 
    //   action: "sendPushMessage", 
    //   recipient: rv.recipient,
    //   title: rv.title,
    //   message: rv.message
    
    // }).then((json) => {
      this.df.processing = false;

      if (this.apps.bas.envtest) console.log("dfSubmit, json: ", json);
      if (json.success) {
        this.apps.bas.ui.success("Push meldingene er sendt");
        
        this.df.form.reset();
        this.df.obj = undefined;
 
        this.change.next({ type: "sendPushMessage", recipient: this.recipient });
        this.closeModal();
      }
    }).catch(err => {
      this.df.processing = false;

    });


  }

}
