<bt-page-wrapper>
<div class="m-d">

  <div *ngIf="qps.standalone">
    <a href="https://www.bruktbilklubben.no">
      <img
    
      src="https://www.bruktbilklubben.no/wp-content/uploads/2022/06/Bruktbilklubben_logo_BT_400x100.png"
      style="max-width: 100%"
   
      />
    </a>

  </div>


  <div *ngIf="edit.form">

    <form 
      nz-form 
      [formGroup]="edit.form" 
      (ngSubmit)="formSubmit()" 
      (keydown.enter)="$event.preventDefault(); onKeydownEnter();"
      class="item-form mt-d" 
      nzLayout="vertical" 
      [nzAutoTips]="apps.bas.fs.autoTips"
       >
  
      <nz-steps 
        [nzCurrent]="step" 
        [nzSize]="apps.bas.ui.r.gtelg ? 'default' : 'small'" 
        (nzIndexChange)="onIndexChange($event)"
        >
        <!-- <nz-step [nzTitle]="apps.bas.ui.r.gtemd ? 'Tilbudtype' : ''"></nz-step> -->
        <nz-step [nzTitle]="apps.bas.ui.r.gtemd ? 'Ønsker' : ''"></nz-step>
        <nz-step [nzTitle]="apps.bas.ui.r.gtemd ? 'Innbytte' : ''"></nz-step>
        <nz-step [nzTitle]="apps.bas.ui.r.gtemd ? 'Finansiering' : ''"></nz-step>
        <nz-step [nzTitle]="apps.bas.ui.r.gtemd ? 'Kontaktinfo' : ''"></nz-step>
        <!-- <nz-step [nzTitle]="apps.bas.ui.r.gtemd ? 'Ferdig' : ''"></nz-step> -->
      </nz-steps>
      <!-- [nzDirection]="apps.bas.ui.r.gtemd ? 'horizontal' : 'vertical'" -->

      <div class="m-4"></div>

      <div class="form-wrapper mx-d sm:m-0">

        <div *ngIf="step == 0">

          <div *ngIf="!this.edit.leadType">
            <!-- <div nz-row>
              <div nz-col [nzXs]="12">
              </div>
              <div nz-col [nzXs]="12" class="text-right"> <button nz-button nzType="primary" (click)="$event.preventDefault(); nextStep()">Gå videre</button></div>
            </div> -->

            <div class="lead-type-wrapper p-4 md:p-12" >

              <!-- <input type="text" value="test" /> -->
              
              <h1>Veien til din perfekte bruktbil</h1>
              <div nz-row [nzGutter]="32" nzAlign="top">
                <div nz-col [nzMd]="12">
                  <p class="type-text text-lg-off mt-d">Bruk 5 minutter på å fortelle oss alle dine ønsker og behov,
                    så starter Bruktbilklubben umiddelbart jakten på gode tilbud som passer perfekt til ønskene dine.</p>
      
                  <button *ngIf="apps.bas.ui.r.ltesm"
                    nz-button 
                    nzSize="default" 
                    class="btn-lt-s" 
                    [nzType]="'default'" 
                    (click)="setLeadTypeId($event, 1)"
                    >
                    Få tilbud som passer dine bilbehov
                    <!-- Få tilbud på en elbil som passer dine behov -->
                  </button>
                </div>
                <div nz-col [nzMd]="12">
                
                  <p class="type-text text-lg-off mt-d">
                    Eller - hvis du allerede vet hva slags bil du ønsker deg, og er på jakt etter en bestemt bilmodell, klikker du her.
                  </p>
                  <button *ngIf="apps.bas.ui.r.ltesm"
                    nz-button 
                    nzSize="default" 
                    class="btn-lt-a" 
                    [nzType]="'default'" 
                    (click)="setLeadTypeId($event, 2)"
                    >
                    Få tilbud på en bestemt bilmodell
                  </button>
                </div>
              </div>

              <div nz-row [nzGutter]="32" nzAlign="top"   *ngIf="apps.bas.ui.r.gtemd">
                <div nz-col [nzMd]="12">
         
                  <button
                    nz-button 
                    nzSize="default" 
                    class="btn-lt-s" 
                    [nzType]="'default'" 
                    (click)="setLeadTypeId($event, 1)"
                    >
                    Få tilbud som passer dine bilbehov
                    <!-- Få tilbud på en elbil som passer dine behov -->
                  </button>
                </div>
                <div nz-col [nzMd]="12">
            
                  <button  
                    nz-button 
                    nzSize="default" 
                    class="btn-lt-a" 
                    [nzType]="'default'" 
                    (click)="setLeadTypeId($event, 2)"
                    >
                    Få tilbud på en bestemt bilmodell
                </button>
                </div>
              </div>
            </div>

          </div>

          
          <!-- <p>LeadType: {{ edit.leadType?.enumName }}</p> -->
          <!-- <h2 *ngIf="apps.bas.ui.r.ltesm">Ønsker</h2> -->

          <div *ngIf="edit.leadType?.enumName == 'Simple'">
            <h2>Jeg ønsker tilbud på en bil som dekker mitt behov</h2>
            <!-- <h2>Jeg ønsker tilbud på en <strong>elbil</strong> som dekker mitt behov</h2> -->

            <btlib-form-row [controls]="[ edit.controls.step0.childMap.carTypeId ]" [form]="edit.form.controls.step0"  [grid]="apps.bas.ui.gridXl"  ></btlib-form-row> 

            <div *ngIf="edit.carType?.enumName == 'Electric'">
              <!-- <h3>Elektrisk</h3> -->
              <btlib-form-row [controls]="[ 
                edit.controls.step0.childMap.minRange, 
                edit.controls.step0.childMap.maxAge,
                edit.controls.step0.childMap.mileageMax
              ]" [form]="edit.form.controls.step0"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row> 
              
  
              <btlib-form-row [controls]="[ 
                edit.controls.step0.childMap.carSizeIds, 
                edit.controls.step0.childMap.wheelDriveId ]" [form]="edit.form.controls.step0"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row>            
    
              <!-- <btlib-form-row [controls]="[ edit.controls.step0.childMap.towbar ]" [form]="edit.form.controls.step0"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row> 
              <btlib-form-row [controls]="[ edit.controls.step0.childMap.trailerWeight ]" [form]="edit.form.controls.step0"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row>  -->

          
            </div>
            <div *ngIf="edit.carType?.enumName == 'Fossil'">
  
              <btlib-form-row [controls]="[ 
                  edit.controls.step0.childMap.maxAge,
                  edit.controls.step0.childMap.mileageMax
                ]" [form]="edit.form.controls.step0"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row> 
            
              <btlib-form-row [controls]="[ 
                edit.controls.step0.childMap.carSizeIds, 
                edit.controls.step0.childMap.propulsionIds
                ]" [form]="edit.form.controls.step0"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row>            
              
              <btlib-form-row [controls]="[ 
                edit.controls.step0.childMap.wheelDriveId, 
                edit.controls.step0.childMap.transmissionId 
                ]" [form]="edit.form.controls.step0"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row> 

            </div>

            <div *ngIf="edit.carType?.enumName == 'Commercial'">
         
              <btlib-form-row [controls]="[ 
                edit.controls.step0.childMap.chassisIds,  
                edit.controls.step0.childMap.propulsionIds ]" [form]="edit.form.controls.step0"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row> 
              <btlib-form-row [controls]="[
                edit.controls.step0.childMap.transmissionId,  
                edit.controls.step0.childMap.wheelDriveId ]" [form]="edit.form.controls.step0"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row> 

            
              <btlib-form-row [controls]="[ 
                edit.controls.step0.childMap.maxAge,
                edit.controls.step0.childMap.mileageMax
              ]" [form]="edit.form.controls.step0"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row> 
        
              <btlib-form-row [controls]="[ 
              edit.controls.step0.childMap.minPayload ]" [form]="edit.form.controls.step0"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row> 
     
              
              <btlib-form-row [controls]="[ edit.controls.step0.childMap.towbar ]" [form]="edit.form.controls.step0"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row> 
              <btlib-form-row [controls]="[ edit.controls.step0.childMap.trailerWeight ]" [form]="edit.form.controls.step0"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row> 


            </div>


          </div>
          <div *ngIf="edit.leadType?.enumName == 'Advanced'">
            <h2>Jeg ønsker tilbud på en bestemt modell</h2>

            <btlib-form-row [controls]="[ 
              edit.controls.step0.childMap.make,
              edit.controls.step0.childMap.model ]" [form]="edit.form.controls.step0"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row> 
            
            <btlib-form-row [controls]="[ 
              edit.controls.step0.childMap.yearAsRange, 
              edit.controls.step0.childMap.mileageAsRange ]" [form]="edit.form.controls.step0"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row> 
            <btlib-form-row [controls]="[ 
              edit.controls.step0.childMap.transmissionId, 
              edit.controls.step0.childMap.wheelDriveId  ]" [form]="edit.form.controls.step0"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row> 
            <btlib-form-row [controls]="[ 
              edit.controls.step0.childMap.propulsionIds ]" [form]="edit.form.controls.step0"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row>            
        
            <!--
              , edit.controls.step0.childMap.minSeats
            -->
            
            <btlib-form-row [controls]="[ 
            edit.controls.step0.childMap.priceMinInterval ]" [form]="edit.form.controls.step0"   [grid]="apps.bas.ui.r.gtelg ? apps.bas.ui.gridMd : apps.bas.ui.gridXl"  ></btlib-form-row>            
      
          </div>

          <div *ngIf="edit.leadType?.enumName == 'Advanced' || edit.carType">
            <div *ngIf="edit.leadType?.enumName == 'Simple'">
              <btlib-form-row [controls]="[ edit.controls.step0.childMap.priceAsRange ]" [form]="edit.form.controls.step0"  [grid]="apps.bas.ui.r.gtelg ? apps.bas.ui.gridMd : apps.bas.ui.gridXl"  ></btlib-form-row> 

            </div>
          
          
      
            <!-- <btlib-form-row [controls]="[  ]" [form]="edit.form.controls.step0"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row>  -->
            <btlib-form-row [controls]="[ edit.controls.step0.childMap.comment ]" [form]="edit.form.controls.step0"  [grid]="apps.bas.ui.gridXl"  ></btlib-form-row> 
            

            <div  *ngIf="( apps.bas.us.mapToList(edit.form.controls.step0.controls) | filterBy: { pristine: false }).length < 3">
              <nz-alert class="my-d" nzType="error" nzMessage="Vi anbefaler at du fyller ut så mye som mulig i skjemaet for å få så konkrete og gode tilbud som mulig." nzShowIcon></nz-alert>
            </div>

            <div nz-row>
              <div nz-col [nzXs]="12">
              </div>
              <div nz-col [nzXs]="12" class="text-right"> <button nz-button nzType="primary" (click)="$event.preventDefault(); nextStep()">Gå videre</button></div>
            </div>
          </div>

        </div>

      
        <div  *ngIf="step == 1">
          <h2 *ngIf="apps.bas.ui.r.ltesm">Innbytte</h2>

          <div>
            <!-- <h2>Innbytte</h2> -->
            <btlib-form-row [controls]="[ edit.controls.step1.childMap.tradein ]" [form]="edit.form.controls.step1"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row> 
          
            <div *ngIf="edit.form.controls.step1.controls.tradein.value">

              <btlib-form-row [controls]="[ 
                edit.controls.step1.childMap.tradeinLicenseNumber, 
                edit.controls.step1.childMap.tradeinMakeAndModel,
                edit.controls.step1.childMap.tradeinYear,
                edit.controls.step1.childMap.tradeinMileage,
                ]" [form]="edit.form.controls.step1"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row> 
        
              <btlib-form-row [controls]="[ 
                
                edit.controls.step1.childMap.tradeinSecuredLoan,
                edit.controls.step1.childMap.tradeinSecuredLoanAmount,
              
              ]" [form]="edit.form.controls.step1"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row> 

              <btlib-form-row [controls]="[ 
            
                edit.controls.step1.childMap.tradeinPreviousCollisionDamage,
                edit.controls.step1.childMap.tradeinConditionReport ,
                
              
              ]" [form]="edit.form.controls.step1"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row> 
        
              
              <btlib-form-row [controls]="[ edit.controls.step1.childMap.tradeinOtherInfo ]" [form]="edit.form.controls.step1"  [grid]="apps.bas.ui.gridXl"  ></btlib-form-row> 
          
              <!--
                    edit.controls.step1.childMap.tradeinImported ,
                edit.controls.step1.childMap.tradeinSeriousIssues ,
              -->

              <div nz-row [nzGutter]="apps.bas.ui.gridMd.gutter" nzAlign="top">
                <div nz-col [nzXs]="24" [nzSm]="16" [nzMd]="12" [nzLg]="10" [nzXl]="8" [nzXXl]="8">
                  <h3>Last opp bilder av bilen</h3>
                  <p>For eksempel eksteriør, interiør etc.</p>
                  <p>Du kan også laste opp PDF-filer av takst og lignende. </p>
                  <nz-upload
                    nzType="drag"
                    [nzMultiple]="true"
                    [nzAction]="this.apps.bas.ws.getBaseUrlTools() + '?action=uploadDropzone&type=bilEasyLeadFile&uuid=' + edit.obj.uuid"
                    [nzHeaders]="uploadHeaders"
                    [(nzFileList)]="edit.fileList"
                    nzFileType="image/png,image/jpeg,application/pdf"
                    nzListType="text"
                    [nzShowUploadList]="{ showRemoveIcon: true }"
                    (nzChange)="handleUploadFile($event)"
    
                    >
                    <p class="ant-upload-drag-icon">
                      <i nz-icon nzType="inbox"></i>
                    </p>
                    <p class="ant-upload-text">
                      {{ 'app.cbapi5.page.admin.product.clickOrDragImagesHere' | actrans }}
                      
                    </p>
                    <p class="ant-upload-hint">
                      {{ 'app.cbapi5.page.admin.product.uploadImagesHint' | actrans }}
                      
                    </p>
                  </nz-upload>
              
                </div>
              </div>

            </div>

          </div>

          <div class="m-d"></div>
          <div nz-row>
            <div nz-col [nzXs]="12"><button nz-button nzType="default" (click)="$event.preventDefault(); prevStep('Innbytte');">Tilbake</button></div>
            <div nz-col [nzXs]="12" class="text-right"> <button nz-button nzType="primary" (click)="$event.preventDefault(); nextStep()">Gå videre</button></div>
          </div>

        </div>
        <div  *ngIf="step == 2">
          <h2 *ngIf="apps.bas.ui.r.ltesm">Finansiering</h2>

          <div>
            <!-- <h2>Finansiering</h2> -->
            <btlib-form-row [controls]="[ edit.controls.step2.childMap.financing ]" [form]="edit.form.controls.step2"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row> 
          
            <div *ngIf="edit.form.controls.step2.controls.financing.value">


              <btlib-form-row [controls]="[ 
                edit.controls.step2.childMap.monthlyAmount,  
                edit.controls.step2.childMap.equity
                ]" [form]="edit.form.controls.step2"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row> 
        
            </div>

          </div>

          <div nz-row>
            <div nz-col [nzXs]="12"><button nz-button nzType="default" (click)="$event.preventDefault(); prevStep();">Tilbake</button></div>
            <div nz-col [nzXs]="12" class="text-right"> <button nz-button nzType="primary" (click)="$event.preventDefault(); nextStep()">Gå videre</button></div>
          </div>
        
        </div>
        <div  *ngIf="step == 3">
          <h2 *ngIf="apps.bas.ui.r.ltesm">Kontaktinformasjon</h2>

          <div>
            <!-- <h2>Kontaktinformasjon</h2> -->

            <!-- <p class="text-bold">Hvordan ønsker du å bli kontaktet?</p>
            <btlib-form-row [controls]="[ 
              edit.controls.step3.childMap.contactTelephone,
              edit.controls.step3.childMap.contactEmail,
              
              ]" [form]="edit.form.controls.step3"  [grid]="apps.bas.ui.gridXs"  ></btlib-form-row> 
          
            <btlib-form-row [controls]="[ edit.controls.step3.childMap.contactTelephoneTimeId  ]" [form]="edit.form.controls.step3"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row>  -->
          

            <btlib-form-row [controls]="[ edit.controls.step3.childMap.user.childMap.name, ]" [form]="edit.form.controls.step3.controls.user"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row> 
            <btlib-form-row [controls]="[ 
              
              edit.controls.step3.childMap.user.childMap.username,
              edit.controls.step3.childMap.user.childMap.telephone,
              edit.controls.step3.childMap.user.childMap.zipCode,
              edit.controls.step3.childMap.user.childMap.city,
              
              ]" [form]="edit.form.controls.step3.controls.user"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row> 
          
            <btlib-form-row [controls]="[ 
              edit.controls.step3.childMap.homeDelivery, 
              edit.controls.step3.childMap.acceptEula  ]" [form]="edit.form.controls.step3"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row> 
          
            
            <!-- <btlib-form-row [controls]="[ edit.controls.step3.childMap.user.childMap.acceptInfoEmail  ]" [form]="edit.form.controls.step3.controls.user"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row>  -->
        
            <nz-alert class="my-d" nzType="info" nzMessage="Ved å benytte bruktbilklubbens tjeneste vil du automatisk bli medlem i bruktbilklubben med 1. års gratis medlemskap. Ingen bindingstid." nzShowIcon></nz-alert>
           
            
          </div>

          <div nz-row>
            <div nz-col [nzXs]="12">
              <button nz-button nzType="default" (click)="$event.preventDefault(); prevStep();">Tilbake</button>
              <button nz-button nzType="default" (click)="$event.preventDefault(); edit.showEula = true; apps.bas.es.sendParentMessageScroll();">Vis avtalevilkår</button> 
          
            </div>
            <div nz-col [nzXs]="12" class="text-right"> <button nz-button nzType="primary" [nzLoading]="processing" >Send inn skjema</button> </div>
          </div>
     
        </div>
        <div  *ngIf="step == 4">

          <div class="text-center">

            <h1>Ferdig</h1>
            <p>Takk for at du bruker Bruktbilklubben.no. Vi har sendt deg en bekreftelse på epost, sjekk søppelpost-mapen din om du ikke finner den i innboksen.</p>
            <p><i nz-icon [nzType]="'check-circle'" [nzTheme]="'twotone'" [nzTwotoneColor]="'#52c41a'"  style="font-size: 80px;"></i></p>
          

            <p class="m-d"><button nz-button (click)="$event.preventDefault(); apps.bas.es.sendParentMessageRedir('/')">Gå til forsiden</button></p>
          </div>
        </div>

      </div>

    </form>
  </div>

  <div style="height: 50px;"></div>

  
  <nz-modal 
    [(nzVisible)]="edit.showEula" 
    [nzTitle]="'Avtalevilkår' | actrans | str" 
  
    (nzOnOk)="edit.showEula = false; "
    (nzOnCancel)="edit.showEula = false; "
    (nzAfterClose)="apps.bas.es.sendParentMessageScroll();"
    nzWidth="80vw" 
    >
    <ng-container *nzModalContent>
      
      <p [innerHtml]="'web.eula.eula' | actrans">

      </p>

    </ng-container>
  </nz-modal>

</div>
</bt-page-wrapper>
<!--

ddf


-->
