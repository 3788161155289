import { Component, Injector, OnInit } from '@angular/core';


import { AppService } from 'src/app/services/app.service';
import { DATAID } from 'src/app/lib/core/services/data.service';
import { MiscUtil } from 'src/app/lib/core/util/misc.util';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-root-page',
  templateUrl: './root-page.component.html',
  styleUrls: ['./root-page.component.scss']
})
export class RootPageComponent implements OnInit {
  route: ActivatedRoute;

  loadingVisible = true;
  loginVisible = false;

  rootDrawerVisible = false;

  mainContent:string = "loading";

  constructor(public injector:Injector, public apps:AppService) {
    if(this.apps.bas.envtest) console.log(MiscUtil.getLogText("RootPageComponent.constructor"));

    this.route = injector.get(ActivatedRoute);
    this.apps.bas.us.checkActivatedRoute(this.route.snapshot);

    if(this.apps.bas.envtest) console.log("RootPageComponent, route.snapshot: ", this.route.snapshot);

    apps.bas.ds.find(DATAID.UI_MAIN_CONTENT, { }).subscribe(res => {
      if (res.id !== undefined)  this.mainContent = res.id ;
    });

    apps.bas.es.ready().then(() => {
      if(this.apps.bas.envtest) console.log(MiscUtil.getLogText("RootPageComponent.ready, login: "), this.apps.bas.ds.login);


      if (this.apps.bas.ds.login.success) {
        this.mainContent = "apptest";
      } else {
        this.mainContent = "apptest"; // todo: welcome

        this.apps.bas.ds.get(DATAID.APP_LOGIN).subscribe(res => {
          if(this.apps.bas.envtest) console.log("RootPageComponent.APP_LOGIN.subscribe: ", res);
    
          if (res.success) {
            if ( this.apps.bas.aas.isAdminProvider() ) {
              this.apps.bas.ui.router.navigate([ apps.bas.ui.getRouterPrefix() + '/admin/' ]);
            }
          }
        })
    
        


      }
    })



  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    if(this.apps.bas.envtest) console.log(MiscUtil.getLogText("RootPageComponent.ngAfterViewInit"));
  }

  drawerOpen(): void {
    this.rootDrawerVisible = true;
  }

  drawerClose(): void {
    this.rootDrawerVisible = false;
  }

  menuClick(item:string) {
    this.apps.bas.ds.set(DATAID.UI_MAIN_CONTENT, { id: item });
    this.drawerClose();
  }

  showLogin() {
    this.loadingVisible = false;
    this.loginVisible = true;
  }

  onLogin(event:any) {
    if (event.success) {
      
    }

  }


}
