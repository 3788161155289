<btlib-admin-page-wrapper>

  <div class="m-d">
    <h1>{{ 'Session status' | actrans }}</h1>
   

   
  </div>

  <div *ngIf="status.sqc">
    <nz-descriptions
        nzBordered
        [nzColumn]="1"
        style="max-width: 500px;"
      >
      <nz-descriptions-item nzTitle="sessionCountCurrent"><div class="text-right">{{ status.sessionCountCurrent }}</div></nz-descriptions-item>
      <nz-descriptions-item nzTitle="sessionCountActive"><div class="text-right">{{ status.sessionCountActive }}</div></nz-descriptions-item>
      <nz-descriptions-item nzTitle="sqc.pendingSize"><div class="text-right">{{ status.sqc.pendingSize }}</div></nz-descriptions-item>
      <nz-descriptions-item nzTitle="sqc.activeSize"><div class="text-right">{{ status.sqc.activeSize }}</div></nz-descriptions-item>
      <nz-descriptions-item nzTitle="sqc.findActiveCount"><div class="text-right">{{ status.sqc.findActiveCount }}</div></nz-descriptions-item>
      <nz-descriptions-item nzTitle="sqc.activeSum"><div class="text-right">{{ status.sqc.activeSum }}</div></nz-descriptions-item>
      <nz-descriptions-item nzTitle="sqc.cap"><div class="text-right">{{ status.sqc.cap }}</div></nz-descriptions-item>
      <nz-descriptions-item nzTitle="sqc.available"><div class="text-right">{{ status.sqc.cap - status.sqc.activeSum }}</div></nz-descriptions-item>
      <nz-descriptions-item nzTitle="sqc.currentIndex"><div class="text-right">{{ status.sqc.ci }}</div></nz-descriptions-item>
          
    </nz-descriptions>

  </div>


  <nz-table 
    *ngIf="(objects || []).length"
    #objectsTable 
    nzTableLayout="fixed" 
    [nzData]="objects || []"
    [nzPageSize]="20"
    nzSize="small"
    class="nz-table-xs"
    >
    <thead>
      <tr>
        <th>{{ 'common.shortUrl' | actrans }}</th>
        <th>{{ 'common.target' | actrans }} {{ 'common.url' | actrans }}</th>
        <th>{{ 'common.clicks' | actrans }}</th>
        <th nzWidth="50px"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of objectsTable.data">

        <td>{{ data.shortUrl }}</td>
        <td>{{ data.url }}</td>
        <td>{{ data.count }}</td>
    
        <td nzAlign="center">
          <button nz-button nzType="default" nzSize="small" [title]="'common.edit' | actrans"  (click)="editObject(data)">
            <i nz-icon [nzType]="'edit'"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </nz-table>


  <div class="text-center m-d">
    <button nz-button nzType="primary"  (click)="getSessionStatus()">
      <i nz-icon nzType="sync"></i>
      {{ 'common.update' | actrans }}
    </button>

  </div>




</btlib-admin-page-wrapper>
