<div *ngIf="view == 'grid'" class="m-d">

  <div nz-row [nzGutter]="apps.bas.acs.productsGrid.gutter" nzAlign="top" class="product-grid">
    <div 
      nz-col 
      [nzXs]="apps.bas.acs.productsGrid.xs" 
      [nzSm]="apps.bas.acs.productsGrid.sm" 
      [nzMd]="apps.bas.acs.productsGrid.md" 
      [nzLg]="apps.bas.acs.productsGrid.lg" 
      [nzXl]="apps.bas.acs.productsGrid.xl" 
      [nzXXl]="apps.bas.acs.productsGrid.xxl"
      
      *ngFor="let p of ctx.products"
      >

      <div class="cell custom-style">
        <a 
          [href]="p.urlFull" target="_blank"
          class="cell-img cursor-pointer" 
          [style.background-image]="'url(' + (p.firstImageUrlMedium || ctx.missingImgUrl) + ')'"
          (click)="apps.bas.acs.productsOnClick($event, ctx, p)"
          >

        </a>
        <h2 class="header" nz-typography nzEllipsis >{{ p.mkName }}</h2>
        <p class="lead">{{ p.mkIngress }}</p>

        <div *ngIf="ctx.queryParams.showType" nz-row>
          <div nz-col [nzXs]="8"> {{ 'common.type' | actrans }} </div>
          <div nz-col [nzXs]="16" class="text-right text-bold text-ellipsis"> {{ p.type }} </div>
        </div>
        <div *ngIf="ctx.queryParams.showInfoProvider && p.findProvidersAsString" nz-row>
          <div nz-col [nzXs]="8"> {{ (p.findTypeActOrEvent ? 'common.organizer' : 'common.provider') | actrans }} </div>
          <div nz-col [nzXs]="16" class="text-right text-bold text-ellipsis"> {{ p.findProvidersAsString }} </div>
        </div>

        <div class="m-d"></div>

        <div nz-row [nzGutter]="apps.bas.ui.gridMd.gutter" nzAlign="top">
          <div  nz-col   [nzXs]="apps.bas.ui.gridMd.xs">
        
            <a [href]="p.urlFull" target="_blank" nz-button nzBlock nzType="primary" (click)="apps.bas.acs.productsOnClick($event, ctx, p)">{{ 'app.cbapi5.common.moreInfoAndOrder' | actrans:[]:false:'Mer informasjon og bestilling' }}</a>
          </div>
        </div>
      </div>

    </div>

  </div> 
</div>
<div *ngIf="view == 'odd-even'" class="m-d-off product-odd-even">

  <div 
    *ngFor="let p of ctx.products; let i = index; let even = even; "

    >

    <div nz-row [nzGutter]="0" nzAlign="middle" class="product-row"
        [class.product-odd]="!even"
        [class.product-even]="even"
      >
      <div 
        nz-col 
        [nzXs]="24" 
        [nzSm]="12"
        [nzOrder]="even || apps.bas.ui.r.ltexs ? 1 : 2"
        >
        
        <a 
          [href]="p.urlFull" target="_blank"
          class="cell-img cursor-pointer" 
          [style.background-image]="'url(' + (p.firstImageUrlLarge || ctx.missingImgUrl) + ')'"
          (click)="apps.bas.acs.productsOnClick($event, ctx, p)"
          >

        </a>
      </div>
      <div 
        nz-col 
        [nzXs]="24" 
        [nzSm]="12"
        [nzOrder]="even || apps.bas.ui.r.ltexs ? 2 : 1"
        >

        <div [class]="'cv-' + contentView">

          <div *ngIf="contentView == 'default'" class="cell custom-style">
      

            <h2 class="header" nz-typography nzEllipsis >{{ p.mkName }}</h2>
            <p class="lead">{{ p.mkIngress }}</p>
    
            <div *ngIf="ctx.queryParams.showType" nz-row>
              <div nz-col [nzXs]="8"> {{ 'common.type' | actrans }} </div>
              <div nz-col [nzXs]="16" class="text-right text-bold text-ellipsis"> {{ p.type }} </div>
            </div>
            <div *ngIf="ctx.queryParams.showInfoProvider && p.findProvidersAsString" nz-row>
              <div nz-col [nzXs]="8"> {{ (p.findTypeActOrEvent ? 'common.organizer' : 'common.provider') | actrans }} </div>
              <div nz-col [nzXs]="16" class="text-right text-bold text-ellipsis"> {{ p.findProvidersAsString }} </div>
            </div>
    
            <div class="m-d"></div>
    
            <div nz-row [nzGutter]="apps.bas.ui.gridMd.gutter" nzAlign="top">
              <div  nz-col   [nzXs]="apps.bas.ui.gridMd.xs">
            
                <a [href]="p.urlFull" target="_blank" nz-button nzBlock nzType="primary" (click)="apps.bas.acs.productsOnClick($event, ctx, p)">{{ 'app.cbapi5.common.moreInfoAndOrder' | actrans:[]:false:'Mer informasjon og bestilling' }}</a>
              </div>
            </div>
          </div>
          <div *ngIf="contentView == 'prohemsedal'" class="cell custom-style">
            
            <p class="preheader">{{ p.productProperties.preheader | messagetrans }} </p>
            <h2 class="header">{{ p.mkName }}</h2>
            
            <a nz-button nzType="primary" class="my-d mb-5" *ngIf="p.productProperties.virtual_viewing_link" [href]="p.productProperties.virtual_viewing_link" target="_blank" >
              Ta en virituel visning av {{ p.mkName }} her
            </a>
            <p class="text" [innerHtml]="(p.productProperties.website_text | messagetrans:[]:true) || p.mkDesc "></p>

            <div>
              <a [href]="p.urlFull" target="_blank" nz-button nzType="primary" (click)="apps.bas.acs.productsOnClick($event, ctx, p)">{{ 'app.cbapi5.common.moreInfoAndOrder' | actrans:[]:false:'Mer informasjon og bestilling' }}</a>
            </div>
          </div>

        </div>
      </div>

    </div>

  </div>

  
</div>

