

<div style="height: 50px;"></div>
<!-- <button nz-button nzType="default" (click)="goBack()">Gå tilbake</button> -->


  <nz-steps [nzCurrent]="step" nzSize="small">
    <!-- -->
    <nz-step nzTitle="Se gjennom"  nzIcon="shopping-cart"></nz-step>
    <nz-step nzTitle="Logg inn"  nzIcon="user"></nz-step>
    <nz-step nzTitle="Betal"  nzIcon="dollar"></nz-step>
    <nz-step nzTitle="Ferdig"  nzIcon="check-circle"></nz-step>
  </nz-steps>


  <nz-list class="">
    <div *ngFor="let pw of cart.items ">

    
    <nz-list-item>
  
        <nz-list-item-meta
          nzDescription="LINJE 1"
        >
          <nz-list-item-meta-title>
            Title: {{ pw.productObj.mkName }}
          </nz-list-item-meta-title>
          <!-- <nz-list-item-meta-description>
            Desc: {{ pw.productObj.mkIngress }}
          </nz-list-item-meta-description> -->
        </nz-list-item-meta>
        {{ pw.totalAmountDouble | nf }} <!-- amountDouble:  -->
        <!-- <ul nz-list-item-actions>
          <nz-list-item-action><a (click)="edit(item)">edit</a></nz-list-item-action>
          <nz-list-item-action><a (click)="edit(item)">more</a></nz-list-item-action>
        </ul> -->
    
    
    </nz-list-item>
  </div>
  </nz-list>

  <div *ngIf="step == 0">
    <button nz-button nzType="primary" (click)="step = 1">Gå videre</button>


    <button nz-button nzType="default" (click)="placeOrder()">TEST: PO()</button>

  </div>
<div *ngIf="step == 1">


  <form nz-form  >
    <nz-form-item>
      <nz-form-label [nzSm]="apps.bas.ui.formSize.lbl.sm" [nzXs]="apps.bas.ui.formSize.lbl.xs">Navn</nz-form-label>
      <nz-form-control [nzSm]="apps.bas.ui.formSize.ctrl.sm" [nzXs]="apps.bas.ui.formSize.ctrl.xs">
        <input nz-input type="text" placeholder="Navn" [(ngModel)]="user.u_name" name="name" />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="apps.bas.ui.formSize.lbl.sm" [nzXs]="apps.bas.ui.formSize.lbl.xs">E-post</nz-form-label>
      <nz-form-control [nzSm]="apps.bas.ui.formSize.ctrl.sm" [nzXs]="apps.bas.ui.formSize.ctrl.xs">
        <input nz-input type="email" placeholder="E-post" [(ngModel)]="user.u_username" name="username" />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="apps.bas.ui.formSize.lbl.sm" [nzXs]="apps.bas.ui.formSize.lbl.xs">Telefon</nz-form-label>
      <nz-form-control [nzSm]="apps.bas.ui.formSize.ctrl.sm" [nzXs]="apps.bas.ui.formSize.ctrl.xs">
        <input nz-input type="tel" placeholder="Telefon" [(ngModel)]="user.u_telephone" name="telephone" />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-control [nzOffset]="apps.bas.ui.formSize.lbl.sm" [nzSm]="apps.bas.ui.formSize.ctrl.sm" >
        <button nz-button nzType="primary" (click)="placeOrder()">Bekreft bestilling og gå til betaling</button>

      </nz-form-control>
    </nz-form-item>
  </form>



</div>

<div *ngIf="step == 2">
  <btlib-iframe [src]="paymentUrl" [autoResize]="true"></btlib-iframe>
</div>

<div *ngIf="step == 3">
  <div style="margin-top: 50px; padding: 20px;">
    <p>Betalingen var velykket. Vi har sendt deg en ordrebekreftelse på epost og sms.</p>
  
    <p><strong>Velkommen skal du være</strong></p>
  </div>

</div>


