import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { MiscUtil } from 'src/app/lib/core/util/misc.util';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-app-bileasy-providers-page',
  templateUrl: './app-bileasy-providers-page.component.html',
  styleUrls: ['./app-bileasy-providers-page.component.scss']
})
export class AppBileasyProvidersPageComponent implements OnInit {

  fbo:any = { };

  constructor(public injector:Injector, public apps:AppService) {
    let route = injector.get(ActivatedRoute);
    this.apps.bas.us.checkActivatedRoute(route.snapshot);
  

  }

  ngOnInit(): void {
    this.apps.bas.ws.json({ 
      checkChildApp: true,
      aType: "customAction", 
      action: "getProviders"
    
    }).then((json) => {

      if (this.apps.bas.envtest) console.log("getProviders, json: ", json);
      if (json.success) {
        this.fbo = json;
      }

    });

  }

  websiteClick(provider:any) {
    console.log("websiteClick: ", provider);
    //TOOD
  }



}
