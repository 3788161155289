
<button nz-button nzType="default" id="btn-menu-main" (click)="menuDrawerVisible = !menuDrawerVisible;">
  <i nz-icon [nzType]="'menu'" ></i>
</button>



<nz-drawer
[nzClosable]="true"
[nzVisible]="menuDrawerVisible"
nzPlacement="left"
[nzTitle]="'common.admin' | actrans | str"
(nzOnClose)="drawerClose()"
>

<ng-container *nzDrawerContent>
  

  <nav>
    <a [routerLink]="apps.bas.ui.getRouterPrefix() + '/admin/'" >{{ 'common.admin' | actrans }}</a>
    <a [routerLink]="apps.bas.ui.getRouterPrefix() + '/admin/products'">{{ 'common.products' | actrans }}</a>
  </nav>

  <!-- <div>
    <button nz-button nzType="default" (click)="menuClick('login')">login</button>
  </div>
  <div>
    <button nz-button nzType="default" (click)="menuClick('apptest')">apptest</button>
  </div>
  <div>
    <button nz-button nzType="default" (click)="menuClick('libtest')">libtest</button>
  </div> -->

</ng-container>
</nz-drawer>