import { AfterViewInit, Component, HostListener, Injector, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DynformControl, DynformControlsMap } from 'src/app/lib/core/model/dymform-control';
import { DATAID } from 'src/app/lib/core/services/data.service';
import { MiscUtil } from 'src/app/lib/core/util/misc.util';
import { upperCase } from 'src/app/lib/core/util/const';

import { AppService } from 'src/app/services/app.service';

import { NzUploadChangeParam, NzUploadFile, NzUploadXHRArgs } from 'ng-zorro-antd/upload';
import { Observable, Subscription } from 'rxjs';
import { BtGoogleMapsComponent } from 'src/app/lib/core/component/ui/bt-google-maps/bt-google-maps.component';
import { ComponentCanDeactivate } from 'src/app/lib/core/guards/pending-changes.guard';


@Component({
  selector: 'app-admin-user-form',
  templateUrl: './admin-user-form.component.html',
  styleUrls: ['./admin-user-form.component.scss']
})
export class AdminUserFormComponent implements OnInit, AfterViewInit, ComponentCanDeactivate {

  router: Router;
  route: ActivatedRoute;

  fbo:any;



  edit:any = {
    form: undefined,
    obj: undefined,
    changes: { },

    contact:  { controls: {},  obj: null, form: null, id: -1, idx: -1, newItemField: "contactNew" },
    log:      { controls: {},  obj: null, form: null, id: -1, idx: -1, newItemField: "logNew" },
  }


  isSpinning = true;
  dirty = false;
  lang:string = "no";

  userChangePassword:any = undefined;
  userChangeUsername:any = undefined;
  userDeleteUser:any = undefined;

  Sections = {
    "basic"         : { id: "basic",        title: "app.lib.common.basic",        icon: "setting"}, // + tagsEtc
    "companyInfo"   : { id: "companyInfo",  title: "web.common.user.companyInfo", icon: "dollar"}, 
    // "company"       : { id: "company",      title: "common.company",              icon: "setting"}, // + coa, cbapi
    "contacts"      : { id: "contacts",     title: "bus.user.contacts",           icon: "user"}, 
    "provider"      : { id: "provider",     title: "common.provider",             icon: "appstore"}, 
    // "agent"         : { id: "agent",        title: "common.agent",                icon: "setting"}, 
    
    "ups"           : { id: "ups",          title: "bus.user.ups",                icon: "table"}, 
    "log"           : { id: "log",          title: "common.log",                  icon: "file" },
   
  }


  helpVisible = false;
  mapVisible = false;

  private mapMain: BtGoogleMapsComponent|undefined;

  @ViewChild('mapMain', { static: false }) set mapMainComponent(content: BtGoogleMapsComponent) {
      if (this.apps.bas.envtest) console.log("mapMainComponent, content: " + content);

      if(content) { // initially setter gets called with undefined
          this.mapMain = content;
      }
  }

  @Output() userChange = new EventEmitter<any>();


  private _inputId:any;
  @Input() 
  get inputId() { return this._inputId; }
  set inputId(inputId:any) {
    console.log("inputId.set: " + inputId);
    if (inputId) this.getUser(inputId);
    this._inputId = inputId;
  }

  constructor(public injector:Injector, public apps:AppService) {
    this.router = injector.get(Router);
    this.route = injector.get(ActivatedRoute);
    this.apps.bas.us.checkActivatedRoute(this.route.snapshot);

    if (this.apps.bas.envtest) console.log("CommonUserUserPageComponent, login.success: "+this.apps.bas.ds.login.success+", route: ", this.route.snapshot, ", inputId: " + this.inputId);


  }

  ngOnInit(): void {
    if (this.apps.bas.envtest) console.log("CommonUserUserPageComponent.ngOnInit");


    if (this.apps.bas.ds.login.success) {
      
      // this.getUser(this.route.snapshot.queryParams.id);
      
      
      if (this.inputId) {
        this.getUser(this.inputId);

      } else {
        this.route.queryParams.subscribe(qps => {
          console.log("route.queryParams.subscribe: ", qps);
          if (qps["id"]) this.getUser(qps["id"]);
  
        });
      }
  
    } 
    else {

      //TODO: denne blir kalt flere ganger. 
      this.apps.bas.ds.get(DATAID.APP_LOGIN).subscribe((res) => {
        if (this.apps.bas.envtest) console.log("CommonUserUserPageComponent.LOGIN.updated, res: ", res)
        // window.location.reload();
        if (res.success) {
          this.getUser(this.inputId || this.route.snapshot.queryParams["id"]);
      
          
        }
     
      });
    }
  }

  ngAfterViewInit(): void {
    if (this.apps.bas.envtest) console.log("CommonUserUserPageComponent.ngAfterViewInit");
  }

  //

  
  async newItem(event:Event, item:string) {

    let params = this.edit[item].params || { };
    
    if (this.apps.bas.envtest) console.log("newItem: " + item + ", params: ", params);
    
    
    event.preventDefault();
    
    if (this.apps.bas.envtest) console.log("newItem: " + item + ", params: ", params);

    let obj = MiscUtil.clone( this.edit.obj[this.edit[item].newItemField] );

    if (obj.id == "") obj.id = this.edit[item].id--;
    if (this.apps.bas.envtest) console.log("newItem.then: ", obj);


    this.edit.obj[item + "s"] = this.edit.obj[item + "s"] || [];

    if (item == "log") this.edit.obj[item + "s"] = [obj, ...this.edit.obj[item + "s"] ];
    else this.edit.obj[item + "s"] = [...this.edit.obj[item + "s"], obj ];


    this.editItem(obj, item);

  }

  editItem(objOrId:number|any, item:string) {


    let isNum =  typeof objOrId !== "object";
    let obj = isNum ? this.edit.obj[item + "s"][objOrId] : objOrId;
  
    if (isNum) obj.idx = objOrId;

    let controls = this.generateControls(item, obj);
 
    this.apps.bas.fs.updateFormObj(this.edit[item], controls, obj);
  }

  saveItem(item:string, event:any = undefined) {


    let formValue = this.apps.bas.fs.getRawValue(this.edit[item].form, this.edit[item].controls);
    if (formValue === false) return false;
    if (this.apps.bas.envtest) console.log("saveItem, item: "+item+": ", formValue);

    let arr:any[] = this.edit.changes[ item + "s"] = this.edit.changes[ item + "s"] || [];

    let currentIdx = arr.findIndex((elem:any) => elem.id === formValue.id);

    if (currentIdx < 0) arr.push(formValue);
    else arr[currentIdx] = formValue;

    this.dirty = true;

    let obj =  this.edit[item].obj;
    this.edit[item].obj = null;

    let objClone = MiscUtil.clone(obj);

    MiscUtil.extend(obj, formValue);

    if (item == "contact") {
     obj.mkType = MiscUtil.listToMap(this.fbo.contactTypes)[formValue.type].mkName;

    } else  if (item == "log") {
      obj.time = objClone.time;
      obj.textOutput = obj.text;

      
    }


    if ( event) { 
      
      event.preventDefault();
    } 

    return true;
  }

    
  deleteItem(idx:number, item:string) {
   

    let toDelete = this.edit.obj[item + "s"][idx];
    this.edit.obj[item + "s"] = this.edit.obj[item + "s"].filter((id:number, i:number) => i != idx);
    if (toDelete.id < 0) {
      
    } else {
      this.edit.changes[item + "Delete"] =  this.edit.changes[item + "Delete"] || [];
      this.edit.changes[item + "Delete"].push(toDelete.id);
    }
    
    this.dirty = true;

    if (this.apps.bas.envtest) console.log("deleteItem, changes: ", this.edit.changes)
  }


  //

  uploadHeaders = (file: NzUploadFile): Object => {
    let headers = this.apps.bas.ws.getCommonHeaders();
    MiscUtil.extend(headers, this.apps.bas.ws.getLoginHeaders());
    return headers;
  }

  handleUploadImage({ file, fileList }: NzUploadChangeParam): void {

    const status = file.status;
    const resp = file.response;
    if (status !== 'uploading') {
      if (this.apps.bas.envtest) console.log(file, fileList);

      if (status == "removed") {
        //TODO
      }
    }
    if (status === 'done') {
     
      if (this.apps.bas.envtest) console.log(file.name + 'file uploaded successfully, resp: ', resp);

      if (resp.itemId) {
        //TODO
      } else {
        this.apps.bas.ui.error(this.apps.bas.ui.actrans("common.error.upload.general", [], false, "En feil oppstod da vi skulle behandle filen du lastet opp"));
      }

    } else if (status === 'error') {
      if (this.apps.bas.envtest) console.log(`${file.name} file upload failed.`);
      // TODO
      // this.apps.bas.ui.error(this.apps.bas.ui.actrans("common.error.imageUpload", [], false, "En feil oppstod da vi skulle behandle filen du lastet opp. Vanligvis er dette fordi du har lastet opp en JPEG fil i CMYK-format. Kun JPEG bilder i RGB-format kan lastes opp")); //TODO:text
      
    }
  }



  //



  generateControls(type:string, obj:any) {
    if (type == "contact") return this.generateControlsContact(obj);
    else if (type == "log") return this.generateControlsLog(obj);

    return [];
  }

  generateControlsContact(obj:any) {
    let controls: DynformControl[] = [];

    controls.push(new DynformControl({ key: 'id' }));
    controls.push(new DynformControl({ key: 'time',     mk: 'common.time',     controlType: 'label', }));
    controls.push(new DynformControl({ key: 'name',     mk: 'common.name' }));
    controls.push(new DynformControl({ key: 'telephone',     mk: 'common.telephone' }));
    controls.push(new DynformControl({ key: 'email',     mk: 'common.email' }));
    controls.push(new DynformControl({ key: 'comment',     mk: 'common.comment', controlType: "textarea" }));

    controls.push(new DynformControl({ 
      key: 'type',     
      mk: 'bus.contact.type', 
      controlType: 'select', 
      required: true,
      // show: () => user.isUser,bus.user.role
      options: () => {
        return this.fbo.contactTypes;
      }, 
      optionsAllowEmpty: true,
      optionsFieldValue: "id", 
      optionsFieldLabel: "mkName"
    }));

    /*
		<div class="form-group">
										<label class="col-sm-4 control-label" for="contact.type"><nit:message key="bus.contact.type"/>:</label>
										<div class="col-sm-8">
											<nit:select cssClass="form-control " path="fbo.contact.type" items="${fbo.contactTypes}" enabled="${empty fbo.contact.id}" valueProperty="id" labelProperty="mk" />
											<%--
											<c:choose>
											<c:when test="${empty fbo.contact.id}">
												<nit:select cssClass="form-control " path="fbo.contact.type" items="${fbo.contactTypes}" valueProperty="id" labelProperty="mk" />
											</c:when>
											<c:otherwise>
												<input type="hidden" name="contact.type" value="${fbo.contact.type}" />
<
												<p class="form-control-static"><nit:message key="${fbo.contact.typeEnum.mk}"/></p>
											</c:otherwise>
											</c:choose>
											--%>
										</div>
									</div> 
    */


    return controls;
  }

  generateControlsLog(obj:any) {
    let controls: DynformControl[] = [];

    controls.push(new DynformControl({ key: 'id' }));
    controls.push(new DynformControl({ key: 'time',          mk: 'common.time',     controlType: 'label', }));
    controls.push(new DynformControl({ key: 'text',     mk: 'bus.log.text', controlType: "richtext" }));

    return controls;
  }




  generateControlsUser(user:any) {
    let controls: DynformControl[] = [];

    let langs = this.apps.bas.ds.config.appConfig?.enabledLanguages;
    // console.log("langs:")

    let isNew = !user.id;
    let isAdmin = this.apps.bas.aas.isAdmin();
    let isBn = this.apps.bas.aas.isBn();
  

    controls.push(new DynformControl({ key: 'id',     mk: 'common.id' }));

    controls.push(new DynformControl({ key: 'username',     mk: 'common.username', controlType: isNew ? "input" : "label", required: true }));
    controls.push(new DynformControl({ key: 'password',     mk: 'common.password', controlType: "label", value: "•••••••••••" }));

    controls.push(new DynformControl({ key: 'email',     mk: 'common.email', show: () => !user.isUsernameEmail }));
    
    if (isNew) {
      controls.push(new DynformControl({ 
        key: 'role',     
        mk: 'bus.user.role', 
        controlType: 'select', 
        required: true,
        // show: () => user.isUser,bus.user.role
        options: () => {
          return this.fbo.newUserRoles;
        }, 
        // optionsAllowEmpty: true,
        optionsFieldValue: "id", 
        optionsFieldLabel: "mkName",
        onChange: () => {
          this.edit.obj.userRole = this.apps.bas.us.listToMap(this.fbo.newUserRoles)[this.edit.form.controls.role.value];
        }
      }));
    } else {
      controls.push(new DynformControl({ key: 'role', valuePath: "mkRole",     mk: 'bus.user.role', controlType: "label" }));
   
    }
 
    controls.push(new DynformControl({ 
      key: 'customerType',     
      mk: user.userRole.isUser ? 'bus.user.customerType' : 'common.type', 
      controlType: 'select', 
      required: true,
      // show: () => user.isUser,
      options: () => {
        return this.fbo.customerTypes;
      }, 
      // optionsAllowEmpty: true,
      optionsFieldValue: "id", 
      optionsFieldLabel: "mkName",
      onChange: () => {
        this.edit.ct = this.apps.bas.us.listToMap(this.fbo.customerTypes)[this.edit.form.controls.customerType.value];
      }
    }));
    this.edit.ct = this.apps.bas.us.listToMap(this.fbo.customerTypes)[user.customerType];

    controls.push(new DynformControl({ 
      key: 'socialSecurityNumber',     
      mk: 'bus.user.socialSecurityNumber',
      show: () => {
        return this.edit.ct?.isPrivate;
      }
    }));
    controls.push(new DynformControl({ 
      key: 'orgNumber',     
      mk: 'bus.user.orgNumber',
      show: () => {
        return !this.edit.ct?.isPrivate;
      }
    }));

    controls.push(new DynformControl({ key: 'name',     mk: 'common.name' }));
    controls.push(new DynformControl({ key: 'address1',     mk: 'common.addressLine1' }));
    controls.push(new DynformControl({ key: 'address2',     mk: 'common.addressLine2' }));
    controls.push(new DynformControl({ key: 'address3',     mk: 'common.addressLine3' }));
    controls.push(new DynformControl({ key: 'zipCode',     mk: 'common.zipCode' }));
    controls.push(new DynformControl({ key: 'city',     mk: 'common.city' }));
    controls.push(new DynformControl({ key: 'telephone',     mk: 'common.telephone' }));
    
    controls.push(new DynformControl({ 
      key: 'country', 
      valuePath: "countryCode",
      mk: 'common.country', 
      controlType: 'select', 
      required: true,
      options: () => {
        return this.fbo.countries;
      }, 
      // optionsAllowEmpty: true,
      optionsFieldValue: "code", 
      optionsFieldLabel: "mkName",
  
    }));
    
    controls.push(new DynformControl({ 
      key: 'language', 
      valuePath: "languageCode",
      mk: 'common.language', 
      controlType: 'select', 
      required: true,
      options: () => {
        return this.apps.bas.ds.config.appConfig?.enabledLanguages || [];
      }, 
      // optionsAllowEmpty: true,
      optionsFieldValue: "code", 
      optionsFieldLabel: "mkName",
  
    }));
    
    controls.push(new DynformControl({ 
      key: 'timeZone', 
      // valuePath: "countryCode",
      mk: 'common.timeZone', 
      controlType: 'select', 
      required: true,
      options: () => {
        return this.fbo.timeZones;
      }, 
      // optionsAllowEmpty: true,
      optionsFieldValue: "id", 
      optionsFieldLabel: "label",
 
    }));

    controls.push(new DynformControl({ key: 'invoiceNote',     mk: 'bus.user.invoiceNote' }));
    
    controls.push(new DynformControl({ key: 'ehfEnabled',     mk: 'bus.user.ehfEnabled' }));
    controls.push(new DynformControl({ key: 'ehfOrderReference',     mk: 'bus.user.ehfOrderReference' }));
    controls.push(new DynformControl({ key: 'ehfContractDocumentReference',     mk: 'bus.user.ehfContractDocumentReference' }));
    controls.push(new DynformControl({ key: 'ehfContactId',     mk: 'bus.user.ehfContactId' }));
    
    
    controls.push(new DynformControl({ key: 'acceptInfoEmail',     mk: 'bus.user.acceptInfoEmail' }));
    controls.push(new DynformControl({ key: 'acceptInfoSms',     mk: 'bus.user.acceptInfoSms' }));
    
    controls.push(new DynformControl({ key: 'notes',     mk: 'common.notes', controlType: "textarea" }));

    this.fbo.availableTagsAsOptions = this.fbo.userTags == "" ? [] : this.fbo.userTags.split(",").map((x:string) => { return { value: x } }); 
    controls.push(new DynformControl({ 
      key: 'tagsAsString',  
      mk: 'common.tags',  
      // mkInfo: 'bus.product.vatInfo.helpText',
      controlType: 'select', 
      selectMode: "tags", 
      options:  () => {
        return this.fbo.availableTagsAsOptions;
      }, 
      optionsFieldValue: "value", 
      optionsFieldLabel: "value"
    }));

    
    
    // Provider
    controls.push(new DynformControl({ key: 'displayName',     mk: 'common.displayName', show: () => this.edit.obj.userRole.isProvider }));
    controls.push(new DynformControl({ key: 'webAddress',     mk: 'common.webAddress' }));
    controls.push(new DynformControl({ key: 'bankAccount',     mk: 'bus.user.bankAccount' }));

    // Provider Tab:

    let company = this.apps.bas.ds.config.company;
    let daysList = this.fbo.daysList.map((val:number) => { return { value: val, label: val } });;

    // if (user.isProvider) {
      let upControls:DynformControl[] = [];

      upControls.push(new DynformControl({ 
        key: 'settleDay', 
        mk: 'bus.user.settleDay', 
        controlType: 'select', 
        options: () => {
          return daysList;
        }, 
        optionsAllowEmpty: true,
      }));
      upControls.push(new DynformControl({ 
        key: 'settleWeekday', 
        mk: 'bus.user.settleWeekday', 
        controlType: 'select', 
        options: () => {
          return this.fbo.weekdays;
        }, 
        optionsAllowEmpty: true,
      }));

      
      upControls.push(new DynformControl({ 
        key: 'payoutDelay', 
        mk: 'bus.user.payoutDelay', 
        controlType: 'select', 
        options: () => {
          return daysList;
        }, 
        optionsAllowEmpty: true,
      }));

      upControls.push(new DynformControl({ key: 'providerPercent',     mk: 'bus.common.providerPercent' }));
      // 	<c:if test="${nit:getUpw(wpd.company, 'C_MOD_SKATT_UTLEIE_FRA_FORMIDLINGSSELSKAP').booleanValue}">
      upControls.push(new DynformControl({ key: 'yearlyFixedFeeAsDouble',     mk: 'bus.user.up.yearlyFixedFee', show: () => company.upCModSkattUtleieFraFormidlingsselskap }));

      for (let ctrl of upControls) {
        ctrl.valuePath =  'upObj.' + ctrl.key;  // ctrl.valuePath ||
      }

      controls.push(new DynformControl({ 
        key: 'upObj',
        controlType: 'formGroup',
        children: upControls
      }));

      
    // }
    

    let upsMapControls:DynformControl[] = [];

    for (let upw of user.upsList) {
      let isBoolean = upw.upObj.isBoolean;

      if (isBoolean) {
        upsMapControls.push(new DynformControl({ 
          key: upw.upObj.id + "",    
          //valuePath: 'upsMap.' + upw.upObj.id,
          mk: '', //upw.upObj.mkName,
          value: upw.value || "",
          controlType: 'select', 
          options: () => {
            return this.fbo.yesNo;
          }, 
          optionsAllowEmpty: true,
          optionsFieldValue: "bool", 
          optionsFieldLabel: "mkName",
          // data: {
          //   up: upw.up
          // }
        }));
      } else {
        upsMapControls.push(new DynformControl({ 
          key: upw.upObj.id + "",    
          //valuePath: 'upsMap.' + upw.upObj.id,
          mk: '', //upw.upObj.mkName,
          controlType: "input",
          value: upw.value || "",
          // data: {
          //   up: upw.up
          // }
        }));
      }


    }

    controls.push(new DynformControl({ 
      key: 'upsMap',
      controlType: 'formGroup',
      children: upsMapControls
    }));


    //
    
    // if (user.isCompany) {
        
    //   let ucControls:DynformControl[] = [];

    //   let parentCompanies =  this.fbo.companies.filter((c:any) => c.id != user.id);

            
    //   ucControls.push(new DynformControl({ 
    //     key: 'ga4IdProd',     
    //     mk: 'bus.user.uc.gaid', 
    //   }));

    //   ucControls.push(new DynformControl({ 
    //     key: 'parent', 
    //     valuePath: 'parentId',
    //     mk: 'common.parent', 
    //     controlType: 'select', 
    //     options: () => {
    //       return parentCompanies;
    //     }, 
    //     show: () => isBn,
    //     optionsAllowEmpty: true,
    //     optionsFieldValue: "id", 
    //     optionsFieldLabel: "label",
    //   }));

    //   ucControls.push(new DynformControl({ 
    //     key: 'productTypePrimary', 
    //     valuePath: 'productTypePrimaryId',
    //     mk: 'bus.user.productTypePrimary', 
    //     controlType: 'select', 
    //     options: () => {
    //       return this.fbo.productTypes;
    //     }, 
    //     optionsAllowEmpty: true,
    //     optionsFieldValue: "id", 
    //     optionsFieldLabel: "mkName",
    //   }));
    //   ucControls.push(new DynformControl({ 
    //     key: 'productTypesSecondaryIdsAsString', 
    //     mk: 'bus.user.productTypesSecondary', 
    //     controlType: 'select', 
    //     selectMode: "multiple",
    //     options: () => {
    //       return this.fbo.productTypes;
    //     }, 
    //     optionsAllowEmpty: true,
    //     optionsFieldValue: "id", 
    //     optionsFieldLabel: "mkName",
    //   }));
    //   ucControls.push(new DynformControl({ 
    //     key: 'languagesAsString', 
    //     mk: 'bus.user.languages', 
    //     controlType: 'select', 
    //     selectMode: "multiple",
    //     options: () => {
    //       return this.fbo.languagesAll;
    //     }, 
    //     optionsAllowEmpty: true,
    //     optionsFieldValue: "code", 
    //     optionsFieldLabel: "mkName",
    //   }));


      
    //   for (let ctrl of ucControls) {
    //     ctrl.valuePath =  'ucObj.' + ctrl.key; 
    //   }
      
    //   controls.push(new DynformControl({ 
    //     key: 'ucObj',
    //     controlType: 'formGroup',
    //     children: ucControls
    //   }));
    // }
          
        


 

    return controls;

   
  }

  onChange(event:any) {
    if (!event.control) return;
    this.dirty = true;

  }

  onUserChange(event:any, type:string) {

    if (this.apps.bas.envtest) console.log("onUserChange, type: "+type+", ev", event);

    if (type == "delete") {

      this.userChange.next({ type: "delete", user: this.edit.obj, isNew: false });
    }

  }

  @HostListener('document:keydown.control.s',['$event']) 
  ctrlS(event: KeyboardEvent) {
    // console.log("ctrlS: ", event);

    this.submitForm();
    if (event) event.preventDefault();
    return false;
  }

  getVisibleSections() {
    let list:any = [];
    for (let s of Object.values(this.Sections)) {
      if (this.isSectionVisible(s.id)) list.push(s);
    }
    return list;
  }

  isSectionVisible(section:string, button:boolean = false) {

    let u = this.edit.obj;
    let ctrls = this.edit.form.controls;

    let isAdmin = this.apps.bas.aas.isAdmin();
  
    // let roleId = this.edit.obj.role;
    // let role = this.apps.bas.us.listToMap(this.fbo.userRoles)[roleId];
    let role = u.userRole;

    // console.log("roleId: " + roleId + ", role: ", role);

    switch (section) {
      case "provider": 
        if (!role.isProvider) return false; break;
      case "company": 
        if (!role.isCompany) return false; break;
      case "agent": 
        if (!role.isAgent) return false; break;
      case "companyInfo": 

        if (!(role.isCompany || role.isProvider || role.isAgent)) {
          if (!(role.isUser && !this.edit.ct.isPrivate)) {

            return false; 
          }
        }
        break;
     
      case "ups": 
        if (role.isUser) return false; break

      default: break;
    }

 /*

 <c:if test="${fbo.user.isCompany or fbo.user.isProvider or fbo.user.isAgent}">

  Sections = {
    "basic"         : { id: "basic",        title: "app.lib.common.basic",        icon: "setting"}, // + tagsEtc
    "companyInfo"   : { id: "companyInfo",  title: "web.common.user.companyInfo", icon: "setting"}, 
    "company"       : { id: "company",      title: "common.company",              icon: "setting"}, // + coa, cbapi
    "contacts"      : { id: "contacts",     title: "bus.user.contacts",           icon: "setting"}, 
    "provider"      : { id: "provider",     title: "common.provider",             icon: "setting"}, 
    "#agent"        : { id: "#agent",       title: "common.#agent",               icon: "setting"}, 
    "log"           : { id: "log",          title: "common.log",                  icon: "file" },
    "ups"           : { id: "ups",          title: "bus.user.ups",                icon: "setting"}, 
   
  }
 */


    return true;
  }

  getUser(id:any) {

    let isAdmin = this.apps.bas.aas.isAdmin();
    if (!isAdmin) {
      
      // vise feilmelding og sende til forsiden
      return;
    }


    if (this.apps.bas.envtest) console.log("getUser, id: " + id + ", queryParams: ", this.route.snapshot.queryParams);

    if (!id) return;

    let options:any = { 
      jil: "all",
      jil2: "all",
      params: { }
    };

    if (this.apps.bas.aas.isCbSu()) options.params["superuser"] = true;


    // if (id == "new" && this.route.snapshot.queryParams.copy) options.copy = this.route.snapshot.queryParams.copy;

    
    this.apps.bas.aas.getUser(id, options).then((json) => {
      if (this.apps.bas.envtest) console.log("getUser.then: ", json);

  
      let fbo = json.fbo;

      this.fbo = fbo;

      let user = json.user;

      this.setUser(user);
      this.isSpinning = false;



    });
  } 

  setUser(user:any) {

    user.logText = "";

    let controls = this.generateControlsUser(user);


    this.apps.bas.fs.updateFormObj(this.edit, controls, user);

    this.updateUserLogs(user);
    
    this.dirty = false;
  }

  updateUserLogs(user:any) {
    if (!user.id) return; 

    this.apps.bas.ws.json( { 
      // actionType: "user", 
      action: "getUserLogs",
      user: user.id
    }).then(json => {
      if (this.apps.bas.envtest) console.log("updateUserLogs, logs: ", json);
      user.logs = json.logs || [];
    });


  }


  onMapSelectPosition(latLng:google.maps.LatLng) {
    // console.log("onMapSelectPosition, latLng: ", latLng);
    // console.log("controls: ", latLng);
    
    this.edit.form.controls.latitude.setValue(latLng.lat());
    this.edit.form.controls.longitude.setValue(latLng.lng());
    
  }
  onMapSelectPositionCancel() {
    // console.log("onMapSelectPositionCancel, restoring value"
    //   + ", lat: " + this.productForm.controls.latitude.value + " -> " + this.p.latitude
    //   + ", lng: " + this.productForm.controls.longitude.value + " -> " + this.p.longitude
    // );
    this.edit.form.controls.latitude.setValue(this.edit.obj.latitude);
    this.edit.form.controls.longitude.setValue(this.edit.obj.longitude);
    this.mapVisible = false;
  }



  submitForm(): void {

  
    let rv = this.apps.bas.fs.getRawValue(this.edit.form, this.edit.controls);
    if (rv === false) return;

    rv.changes = this.edit.changes;

    this.isSpinning = true;

    let isNew = "new" == this.route.snapshot.queryParams["id"];
    if (!isNew) delete rv.role;
    
    if (this.apps.bas.envtest) console.log("CommonUserUserPageComponent.submitForm, isNew: " + isNew+ ", rv: ", rv)

    this.apps.bas.aas.saveUser(this.edit.obj.id || "new", rv).then(res => {
      this.isSpinning = false;
      this.edit.changes = { };

      if (this.apps.bas.envtest) console.log("CommonUserUserPageComponent.submitForm.then: ", res);

      if (!res.success) {

        return;
      }

      this.apps.bas.ui.success("Brukeren er lagret"); //TODO:text

      this.userChange.next({ type: "save", user: res.user, isNew: isNew })

      this.setUser(res.user);

      if (!this.inputId && isNew) {

        this.router.navigate([
            this.apps.bas.ui.getRouterPrefix() + '/admin/user/user'
          ], { queryParams: { id: res.user.id }}
        );

      }
     

    }).catch(err =>  {
      this.isSpinning = false;

      if (this.apps.bas.envtest) console.log("CommonUserUserPageComponent.submitForm.err: ", err);
    })


  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    // insert logic to check if there are pending changes here;
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away

    return !this.dirty;
  }


}


