
<div class="m-d">

  <div nz-row [nzGutter]="apps.bas.acs.productsGrid.gutter" nzAlign="top" class="product-grid">
    <div 
      nz-col 
      [nzXs]="apps.bas.acs.productsGrid.xs" 
      [nzSm]="apps.bas.acs.productsGrid.sm" 
      [nzMd]="apps.bas.acs.productsGrid.md" 
      [nzLg]="apps.bas.acs.productsGrid.lg" 
      [nzXl]="apps.bas.acs.productsGrid.xl" 
      [nzXXl]="apps.bas.acs.productsGrid.xxl"
      
      *ngFor="let p of ctx.products"
      >

      <div class="cell custom-style">
        <a 
          [href]="p.urlFull" target="_blank"
          class="cell-img cursor-pointer" 
          [style.background-image]="'url(' + (p.firstImageUrlMedium || ctx.missingImgUrl) + ')'"
          (click)="apps.bas.acs.productsOnClick($event, ctx, p)"
          >

        </a>
        <h2 class="header" nz-typography nzEllipsis >{{ p.mkName }}</h2>
        <p class="lead">{{ p.mkIngress }}</p>

        <!-- <nz-descriptions nzBordered  [nzColumn]="1" nzSize="small" class="descriptions-xs"  >
          <nz-descriptions-item *ngIf="ctx.queryParams.showType"  [nzTitle]="'common.type' | actrans | str"><div class="text-right text-bold">{{ p.type }}</div></nz-descriptions-item>
          <nz-descriptions-item *ngIf="ctx.queryParams.showInfoProvider && p.findProvidersAsString"  [nzTitle]="(p.findTypeActOrEvent ? 'common.organizer' : 'common.provider') | actrans | str"><div class="text-right text-bold ">{{ p.findProvidersAsString }}</div></nz-descriptions-item>
        </nz-descriptions> -->


        <div *ngIf="ctx.queryParams.showType" nz-row>
          <div nz-col [nzXs]="8"> {{ 'common.type' | actrans }} </div>
          <div nz-col [nzXs]="16" class="text-right text-bold text-ellipsis"> {{ p.type }} </div>
        </div>
        <div *ngIf="ctx.queryParams.showInfoProvider && p.findProvidersAsString" nz-row>
          <div nz-col [nzXs]="8"> {{ (p.findTypeActOrEvent ? 'common.organizer' : 'common.provider') | actrans }} </div>
          <div nz-col [nzXs]="16" class="text-right text-bold text-ellipsis"> {{ p.findProvidersAsString }} </div>
        </div>

        <div class="m-d"></div>

        <div nz-row [nzGutter]="apps.bas.ui.gridMd.gutter" nzAlign="top">
          <div  nz-col   [nzXs]="apps.bas.ui.gridMd.xs">
        
            <a [href]="p.urlFull" target="_blank" nz-button nzBlock nzType="primary" (click)="apps.bas.acs.productsOnClick($event, ctx, p)">{{ 'app.cbapi5.common.moreInfoAndOrder' | actrans:[]:false:'Mer informasjon og bestilling' }}</a>
          </div>
        </div>
      </div>



      <!--
        	<div class="thumbnail">
				<a href="${pUrl}" class="center link-redirect product-badges" data-product="${p.id}" style="" <c:if test="${param.newWindow}">target="_blank"</c:if> >
				  <div class="img-wrapper" style="height: 175px; background: #fff url(${not empty p.firstImage ? p.firstImage.urlMediumSecure : missingImgUrl}) center center/cover ;"></div>
<%--                                 <img class="img-responsive" style="" src="${not empty p.firstImage ? p.firstImage.urlMediumSecure : missingImgUrl}" alt="<nit:message key="${not empty p.firstImage ? p.firstImage.name : 'common.imageMissing'}" ct="false" />" /> --%>
				  
                           <%@include file="/jsp/product-badges.jsp" %>
                         </a>
				<div class="caption">
				
					<h4 class="product-grid-header bold" title="<nit:message key="${p.mkName}" ct="false" />"><nit:message key="${p.mkName}" /></h4>
					
                             <c:if test="${showLead and not empty p.mkIngress}">
						<p class="lead-off product-grid-ingress">
							<nit:message key="${p.mkIngress}"/>
						</p>
					</c:if>
					
					
					<c:if test="${isTest or wpd.isBn}"> 
 						<p>${p.tagsAsString}</p>
					</c:if>
					
					 
                             <c:if test="${showInfo}">
 								<div class="key-value-form form-horizontal">
   									
                                   <c:if test="${showInfoType}">
                                       <div class="form-group pg-info-type">
   										<label class="${fgLabelClass} control-label"><nit:message key="common.type"/>:</label>
   										<div class="${fgControlClass}"><p class="form-control-static ellipsis"><nit:message key="${p.displayType.mkName}"/></p></div>
   									</div>
                                   </c:if>
                                   
     								<c:if test="${showInfoArea}">
     									<c:if test="${not empty p.findArea}">
     										<div class="form-group pg-info-area">
     											<label class="${fgLabelClass} control-label"><nit:message key="bus.area"/>:</label>
     											<div class="${fgControlClass}"><p class="form-control-static ellipsis"><nit:message key="${p.findArea.mkName}"/></p></div>
     										</div>
     									</c:if>
     								</c:if>
                                   
                    <c:if test="${empty p.externalOrderSystemUrl and showInfoLodgingBeds}">
  	 									<c:if test="${p.typeLodging}">
  	 										
  	 										<div class="form-group pg-info-beds">
  	 											<label class="${fgLabelClass} control-label"><nit:message key="common.logding.beds"/>:</label>
  	 											<div class="${fgControlClass}"><p class="form-control-static"><span class="label label-success">${p.findCap}</span></p></div>
  	 										</div>
  	 
  	 									</c:if>
           					</c:if>
                   					
                    <c:if test="${p.findTypeEvent}">
                         
                         <c:if test="${showInfoWhen and not empty p.findEventStart}">
                           <div class="form-group">
                               <label class="${fgLabelClass} control-label"><nit:message key="common.when"/>:</label>
                               <div class="${fgControlClass}"><p class="form-control-static">
                                 <span style="text-transform: capitalize"><fmt:formatDate value="${p.findEventStart}" timeZone="${company.timeZone}" pattern="EEEE"/></span>
                                 <fmt:formatDate value="${p.findEventStart}" timeZone="${company.timeZone}" type="date" dateStyle="long"  timeStyle="short" />
                               </p></div>
                           </div>
                         </c:if>
                         <c:if test="${showInfoWhere and not empty p.venue}">
                           <div class="form-group">
                             <label class="${fgLabelClass} control-label"><nit:message key="common.where"/>:</label>
                             <div class="${fgControlClass}"><p class="form-control-static cb-font-weigh-normal">${p.venue}</p></div>
                           </div>
                         </c:if>
                   </c:if>
                                 
                  <c:if test="${showInfoProvider}">
   									<c:if test="${p.findTypeActOrEvent}">
   										<c:if test="${not empty p.findProvidersAsString and p.company.upCbShowProvider}">
   											<div class="form-group pg-info-provider">
   												<c:if test="${p.findTypeActOrEvent or true}"><label class="${fgLabelClass} control-label"><nit:message key="${p.findTypeActOrEvent ? 'common.organizer' : 'common.provider'}"/>:</label></c:if> 
   												<div class="${fgControlClass}"><p class="form-control-static ellipsis" title="<nit:message key="${p.findProvidersAsString}" ct="false"/>"><nit:message key="${p.findProvidersAsString}"/></p></div>
   											</div>
   										</c:if>
   									</c:if>
   									
                  </c:if>
 								</div>
                 
					</c:if>
             
					<a href="${pUrl}" class="btn btn-primary btn-sm btn-block mt10 link-redirect" data-product="${p.id}" <c:if test="${param.newWindow}">target="_blank"</c:if> ><nit:message key="web.iframe.search.readMoreOrder" ct="false" /></a>
				</div>
			</div>
			


      -->
    
    </div>

  </div> 
</div>

