<btlib-admin-page-wrapper>

  <div class="mx-d">

    
   
    <div  class="pull-right">
      <button nz-button nzType="primary" (click)="newReport()">
        <i nz-icon nzType="plus-circle"></i>
        {{ 'common.new' | actrans }}
      </button>
    </div>
    <nz-modal [(nzVisible)]="newReportForm.obj" [nzTitle]="'common.new' | actrans | str" nzWidth="90vw"  (nzOnCancel)="newReportForm.obj = null" (nzOnOk)="generateReport();" [nzCancelLoading]="df.loading"  [nzOkLoading]="df.loading" >
      <ng-container *nzModalContent>
        
  
        <form nz-form [formGroup]="newReportForm.form" class="item-form mt-d" nzLayout="vertical" [nzAutoTips]="apps.bas.fs.autoTips" (ngSubmit)="generateReport()"  >
    
          <btlib-form-row [controls]="[ 
            newReportForm.controls.genReportType, 
            newReportForm.controls.datePeriodAsRange  
            ]" [form]="newReportForm.form"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row> 
          <btlib-form-row [controls]="[ 
            newReportForm.controls.username, 
            newReportForm.controls.user 
            ]" [form]="newReportForm.form"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row> 
          
          <btlib-form-row [controls]="[ newReportForm.controls.product  ]" [form]="newReportForm.form"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row> 
        
          
        </form>
        
  
      </ng-container>
    </nz-modal>


    <h2>{{ 'web.common.reports' | actrans }}</h2>

    <div *ngIf="df.obj">
      <nz-collapse>
        <nz-collapse-panel  [nzHeader]="'Filter'" [nzActive]="apps.bas.ui.r.gtemd"  >
          
          <form nz-form [formGroup]="df.form" class="item-form mt-d" nzLayout="vertical" [nzAutoTips]="apps.bas.fs.autoTips" (ngSubmit)="dfSubmit()"  >
  
            <btlib-form-row [controls]="[ 
            df.controls.reportType ,
            df.controls.provider ,
            df.controls.username
            
            ]" [form]="df.form"  [grid]="apps.bas.ui.gridSm"  ></btlib-form-row>
           
            
            <btlib-form-row [controls]="[ 
            df.controls.sort ,
            df.controls.sortMode
            
            ]" [form]="df.form"  [grid]="apps.bas.ui.gridSm"  ></btlib-form-row>
           
            <button nz-button nzType="default"> {{ 'common.show' | actrans }}  </button>
      
          </form>

        </nz-collapse-panel>
      </nz-collapse>


     
    </div>
  

    <div class="mt-d">

      <nz-table 
        #nzTableOrders
        [nzData]="fbo.stats.current || []" 
        nzTableLayout="fixed" 

        nzSize="small"
        class="nz-table-xs"

        [nzFrontPagination]="false"
        [nzLoading]="fbo.loading"

        [nzTotal]="fbo.stats.navigatorObj.size"
        [nzPageSize]="fbo.stats.navigatorObj.pageSize"
        [nzPageIndex]="fbo.stats.navigatorObj.page+1"
        (nzQueryParams)="onQueryParamsChange($event)"

        >
        <thead>
          <tr>
            <!-- <th nzWidth="30px"></th> -->
      

            <th>{{ 'common.type' | actrans }}</th>
            <th *ngIf="apps.bas.ui.r.gtemd">{{ 'common.user' | actrans }}</th>
            <th [nzWidth]="'120px'" *ngIf="apps.bas.ui.r.gtelg">{{ 'common.updated' | actrans }}</th>
            <th [nzWidth]="'120px'" *ngIf="apps.bas.ui.r.gtelg">{{ 'common.sent' | actrans }}</th>
            <th [nzWidth]="'180px'" *ngIf="apps.bas.ui.r.gtesm">{{ 'common.period' | actrans }}</th>
            <!-- <th [nzWidth]="'100px'" *ngIf="apps.bas.ui.r.gtemd">{{ 'common.updated' | actrans }}</th> -->
            <th nzWidth="30px"> </th>
            <th nzWidth="30px"></th>

          </tr>
        </thead>
        <tbody>
          <ng-container  *ngFor="let data of nzTableOrders.data; let idx = index">
            <tr  >

 
              <td>{{ data.mkReportType }}</td>
              <td *ngIf="apps.bas.ui.r.gtemd">
                {{ data.userLabel }}
              </td>
              <td *ngIf="apps.bas.ui.r.gtelg">
                {{ data.updated }}
              </td>
              <td *ngIf="apps.bas.ui.r.gtelg">
                {{ data.updated }}
              </td>
         
              <td>{{ data.periodLabel }}</td>

              <td>
                <label 
                nz-checkbox 
                [nzValue]="true" 
                [ngModelOptions]="{standalone: true}"
                [ngModel]="selected[data.id]"
                (ngModelChange)="onSelected($event, data)"
                ></label>
              </td>
              <td>
                <!-- <a nz-button [href]="data.downloadUrlAccess" nzType="text" nzSize="small" target="_blank">
                  <i nz-icon  [nzType]="'download'" ></i>
                </a> -->
                <a nz-icon  [nzType]="'download'" [href]="data.downloadUrlAccess" target="_blank"></a>
              </td>

            </tr>
        
          </ng-container>
        </tbody>
 
      </nz-table>

      <div class="text-right my-d">
        <button nz-button nzType="default" [nzLoading]="df.loading" (click)="reportAction('regenerateReports')">{{ 'web.common.reports.regenerateReports' | actrans }}</button>
        <button nz-button nzType="default" [nzLoading]="df.loading" nzDanger (click)="reportAction('deleteReports')">{{ 'web.common.reports.delete' | actrans }}</button>
    
      </div>
    
      <!-- <div nz-row nzAlign="bottom">
        <div nz-col nzMd="24">
          
        [nzLayout]="'inline'"
      -->

      <h3>
        {{ 'web.common.reports.sendReports' | actrans }}

        <span 
          class="ml-1"
          nz-icon 
          nzType="info-circle" 
          nzTheme="outline" 
          nz-popover 
          [nzPopoverPlacement]="'bottom'"
          [nzPopoverContent]="'web.common.reports.sendReportsDesc' | actrans | str">
        </span> 
      </h3>
      <form nz-form  (ngSubmit)="reportAction('sendReports')">
         <nz-form-item>
          <nz-form-control>
            <input nz-input [(ngModel)]="df.email" [placeholder]="'common.email' | actrans"  [ngModelOptions]="{standalone: true}"/>
          </nz-form-control>

          </nz-form-item>
          <nz-form-item>
            <nz-form-control>
              <input nz-input [(ngModel)]="df.message" [placeholder]="'common.message' | actrans"  [ngModelOptions]="{standalone: true}"/>
            </nz-form-control>
            </nz-form-item>
          
            <nz-form-item>
              <nz-form-control>
            <button nz-button nzType="default" nzSize="default" [nzLoading]="df.loading"  (click)="reportAction('sendReports')">{{ 'web.common.reports.sendReports' | actrans }}</button>
          </nz-form-control>
        </nz-form-item>
      </form>
      <!-- </div>
 

    </div> -->

      <div style="height: 50px;"></div>

    </div>
  </div>



  

</btlib-admin-page-wrapper>

