import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

/*
*/
@Pipe({name: 'trusthtml'})
export class TrustHtmlPipe implements PipeTransform {
  
  constructor(public sanitizer: DomSanitizer) {

  }

  transform(value: any): any {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
}
