<div *ngIf="src">
  <iframe
  class="iframe"
  [src]="safeSource"
  frameborder="0" 
  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"

  *ngIf="!autoResize"
  id="iframeCompNoReisze"
></iframe>
<iframe

  class="iframe"
  [src]="safeSource"
  frameborder="0" 
  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"

  *ngIf="autoResize"
  id="iframeCompReisze"
  appIFrameResizer
  scrolling="no"
></iframe>

</div>







