<btlib-admin-page-wrapper>

  <div class="m-d">
    <h1>{{ 'common.products' | actrans }}</h1>
    <div class="text-right">
      <button nz-button nzType="primary"  [routerLink]="apps.bas.ui.getRouterPrefix() + '/admin/product/new'">
        <i nz-icon nzType="plus-circle"></i>
        {{ 'common.add' | actrans }}
      </button>
  
    </div>

    <div *ngIf="df.obj" class="mt-d">
      <nz-collapse>
        <nz-collapse-panel  [nzHeader]="'Filter'" [nzActive]="apps.bas.ui.r.gtemd"  >
          
          <form nz-form [formGroup]="df.form" class="item-form mt-d" nzLayout="vertical" [nzAutoTips]="apps.bas.fs.autoTips" (ngSubmit)="dfSubmit()"  >
  
            <btlib-form-row [controls]="[ df.controls.productType,  df.controls.productSubType ]" [form]="df.form"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row> 
            <btlib-form-row [controls]="[ df.controls.provider, df.controls.showEnabledProducts ]" [form]="df.form"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row> 
            <btlib-form-row [controls]="[ df.controls.search, df.controls.showArchived ]" [form]="df.form"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row>
            <btlib-form-row [controls]="[ df.controls.sort, df.controls.sortMode ]" [form]="df.form"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row> 
            <btlib-form-row [controls]="[ df.controls.showUnits ]" [form]="df.form"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row> 
    
            <button nz-button nzType="primary"> {{ 'common.show' | actrans }}  </button>
      
          </form>

        </nz-collapse-panel>
      </nz-collapse>


     
    </div>
   
  </div>

  <!--
        #productsTable 
    nzTableLayout="fixed" 
    [nzData]="(apps.bas.ds.get('APP_ADMIN_PRODUCTS') | async).products || []"
    [nzPageSize]="50"
    

  -->
  <nz-table 

    #nzTableProducts
    [nzData]="fbo.statsObj.current || []" 
    nzTableLayout="fixed" 

    nzSize="small"
    class="nz-table-xs"

    [nzFrontPagination]="false"
    [nzLoading]="fbo.loading"

    [nzTotal]="fbo.statsObj.navigatorObj.size"
    [nzPageSize]="fbo.statsObj.navigatorObj.pageSize"
    [nzPageIndex]="fbo.statsObj.navigatorObj.page+1"
    (nzQueryParams)="onQueryParamsChange($event)"

    >
    <thead>
      <tr>

        <th>{{ 'common.name' | actrans }}</th>
        <th *ngIf="apps.bas.ui.r.gtemd">{{ 'common.type' | actrans }}</th>
        <th *ngIf="apps.bas.ui.r.gtemd">{{ 'bus.product.subType' | actrans }}</th>
        <th *ngIf="apps.bas.ui.r.gtemd">{{ 'common.provider' | actrans }}</th>
        <th nzWidth="50px"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of nzTableProducts.data">
        <td nzEllipsis>
          <a [routerLink]="apps.bas.ui.getRouterPrefix() + '/admin/product/' + data.id">
            {{ data.name }}
          </a>
        </td>
        <td *ngIf="apps.bas.ui.r.gtemd">{{ data.type }}</td>
        <td *ngIf="apps.bas.ui.r.gtemd">{{ data.subType }}</td>
        <td *ngIf="apps.bas.ui.r.gtemd">{{ data.findProvider }}</td>

        <td>
          <i nz-icon class="cursor-pointer mr-1" [nzType]="'copy'"
          nz-popconfirm
          [nzPopconfirmTitle]="'app.lib.common.popconfirm.confirm' | actrans | str"
          (nzOnConfirm)="copyProduct(data.id)"
          [title]="'common.copy' | actrans"
    
          ></i>

          <!-- <i nz-icon class="cursor-pointer" [nzType]="'copy'" (click)="copyProduct(data.id)"></i> -->

      
        </td>
      </tr>
    </tbody>
  </nz-table>

  <!-- <div class="m-d">
    <div *ngIf="!fbo.loading && (fbo.statsObj.current || []).length == 0">
      <p class="text-lg ion-text-center">{{ ('app.cbapi5.common.stats.empty' | actrans) || 'Ingen funnet' }}</p>
    </div>
  </div> -->



</btlib-admin-page-wrapper>
