
<bt-page-wrapper>
<h2 class="text-center">Torvplass</h2>

<div class="p-d">

  
  <div nz-row [nzGutter]="apps.bas.ui.gridMd.gutter" nzAlign="top">
    <div nz-col [nzXs]="apps.bas.ui.gridMd.xs" [nzSm]="24" [nzMd]="16" [nzLg]="16" [nzXl]="20" [nzXXl]="20">
    
      <bt-image-map 
      [bg]="imageMap.bg" 
      [items]="imageMap.items" 
      (onClick)="imageMapClick($event)" 
      [canEdit]="true"></bt-image-map>

    </div>
    <div nz-col [nzXs]="apps.bas.ui.gridMd.xs" [nzSm]="24" [nzMd]="8" [nzLg]="8" [nzXl]="4" [nzXXl]="4">
           
      <!-- <p>Logg</p> -->
      <div *ngFor="let l of log;">{{ l }}</div>

    </div>
  </div>

</div>
</bt-page-wrapper>



<!-- 
template: row
<div nz-row [nzGutter]="apps.bas.ui.gridMd.gutter" nzAlign="top">
  <div nz-col [nzXs]="apps.bas.ui.gridMd.xs" [nzSm]="apps.bas.ui.gridMd.sm" [nzMd]="apps.bas.ui.gridMd.md" [nzLg]="apps.bas.ui.gridMd.lg" [nzXl]="apps.bas.ui.gridMd.xl" [nzXXl]="apps.bas.ui.gridMd.xxl">
  
  </div>
  <div nz-col [nzXs]="apps.bas.ui.gridMd.xs" [nzSm]="apps.bas.ui.gridMd.sm" [nzMd]="apps.bas.ui.gridMd.md" [nzLg]="apps.bas.ui.gridMd.lg" [nzXl]="apps.bas.ui.gridMd.xl" [nzXXl]="apps.bas.ui.gridMd.xxl">
    
  </div>
</div> 
-->




