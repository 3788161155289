import { Component, OnInit } from '@angular/core';

import { AppService } from '../../../../services/app.service';
import { DATAID } from '../../services/data.service';

@Component({
  selector: 'btlib-test',
  templateUrl: './testlib.component.html',
  styleUrls: ['./testlib.component.scss']
})
export class TestlibComponent implements OnInit {

  constructor(public apps:AppService) {

  }

  ngOnInit(): void {
    
  }

}
