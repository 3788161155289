import { Component, Injector, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DynformControl } from 'src/app/lib/core/model/dymform-control';
import { BtEvent } from 'src/app/lib/core/services/event.service';

import { MiscUtil } from 'src/app/lib/core/util/misc.util';
import { AppService } from 'src/app/services/app.service';

// import { ImageMapCoordinate } from 'src/app/lib/core/comonent/ui/bt-image-map/bt-image-map.component';

@Component({
  selector: 'app-app-spacebooking-order-page',
  templateUrl: './app-spacebooking-order-page.component.html',
  styleUrls: ['./app-spacebooking-order-page.component.scss']
})
export class AppSpacebookingOrderPageComponent implements OnInit {

  route:ActivatedRoute;

  itemBg = {
    colorSelected: "rgba(0, 0, 255, 0.4)",
    color: "rgba(0, 163, 28, 0.4)",
  };
  

  imageMap:any = {
    bg: {

      // img: '/assets/test/img/torvet_kart_2022_rotert.jpg',
      height: '100%',
      // maxHeight: ''
    },

    items: [ ]

  };

  // log:string[] = [];
  // selected = "";
  step = 0;

  processing = false;

  fbo:any = { };
  edit:any = {
    obj: undefined,

    periods: [],
    items: [],
    itemsError: false,
    periodsError: false,

    controlOptions: {
      showRequiredStar: true
    }
    
  }
  booking:any = null;
  
  constructor(public injector:Injector, public apps:AppService) {
    this.route = injector.get(ActivatedRoute);
    this.apps.bas.us.checkActivatedRoute(this.route.snapshot);
  
    if (this.apps.bas.envtest) console.log("AppSpacebookingOrderPageComponent.constructor")

    this.getFbo();

    this.apps.bas.es.on(BtEvent.MESSAGE, (ev:any) => this.onMessage(ev));
  }

  ngOnInit(): void {
    
  }

  onMessage(btenv:any) {
    // let ev = this.apps.bas.es.onMessage(event);
    if(this.apps.bas.envtest) console.log("onMessage: ", btenv);
    if (btenv.action == "cbPaymentComplete") {
      this.booking.paymentUrlApi = "";
      //TODO: vise bekreftelse
      if(this.apps.bas.envtest) console.log("ID: " + btenv.params.id);
      // this.paymentSuccess = true;
      
      this.editObject({});
      this.nextStep();
    }
  }
  

  addPeriod() {
    let period = this.edit.form.controls.datePeriodAsRange.value;
    if(this.apps.bas.envtest) console.log("addPeriod, period: ", period);

    if (!period) return;



    let start = period[0];
    let end = period.length > 1 ? period[1] : undefined;

    let startDate = this.apps.bas.ui.dateFormat(start as Date);
    let endDate = (end ? this.apps.bas.ui.dateFormat(end as Date) : "");
    
    let val = startDate + "_" + endDate

    if(this.apps.bas.envtest) console.log("addPeriod, val: " + val);

    let periodObj = 
    {
      value: val,
      label: startDate + (startDate != endDate ? " - " + endDate : "") // val.split("_").join(" - ")
    }

    if (this.edit.periods.indexOf(periodObj) >= 0) {
      //TODO
      return;
    }
    
    this.edit.periods.push(periodObj);

    this.updatePeriods();
  }

  updatePeriods() {

    let periods = this.edit.periods.map((item:any) => item.value).join(",")

    this.edit.form.controls.periods.setValue(periods);

    this.edit.periodsError = false;

    this.fbo.periodAmounts = false;
    this.edit.form.controls.spaces.setValue("");

    console.log("updatePeriods: " + periods + ", periods.val: " +  this.edit.form.controls.periods.value);
  }

  removePeriod(period:any) {
    let periods:any[] = this.edit.periods;
    periods.splice(periods.indexOf(period), 1);
    this.edit.periods = [...periods];
    this.edit.form.controls.periods.setValue(periods.map((item:any) => item.value).join(","));
  }


  getFbo() {

    this.apps.bas.ws.json({ 
      aType: "spaceBooking", 
      action: "getFbo", 
      id: this.route.snapshot.queryParams.id
      // jsonData: JSON.stringify({ lead: lead })
    
    }).then((json) => {

      if (this.apps.bas.envtest) console.log("getFbo, json: ", json);
      if (json.success) {
        this.fbo = json.fbo;

        this.imageMap.bg.img = this.fbo.parent.firstImage?.urlOrg;

        this.editObject({
          // datePeriodAsRange: "",
          priceId: "",
          user: {
            // customUserRolesAsString: ""
          }
           
         });



         setTimeout(() => {
          //
          this.apps.bas.es.sendParentMessageResize();

          if (this.fbo.fixedPeriod) {
            console.log("this.fbo.fixedPeriod: ", this.fbo.fixedPeriod);
            this.edit.periods.push(this.fbo.fixedPeriod);
  
            this.updatePeriods();
           }
         }, 100);
      }
    });

  }

  getSpaces(gotoNext = false) {
    let rv =  this.apps.bas.fs.getRawValue(this.edit.form, this.edit.controls, true, false);

    if (gotoNext) this.processing = true;

    this.apps.bas.ws.json({ 
      aType: "spaceBooking", 
      action: "getSpaces", 
      id: this.route.snapshot.queryParams.id, 
      jsonData: JSON.stringify(rv)
    
    }).then((json) => {

      if (this.apps.bas.envtest) console.log("getSpaces, json: ", json);
      if (json.success) {
        MiscUtil.extend(this.fbo, json);
        if (!json.periodAmounts) this.fbo.periodAmounts = [];
        this.fbo.accessories = json.accessories || [];
        // console.log("getSpaces, fbo: ", this.fbo);

        if (!this.imageMap.items.length || gotoNext) {
          for (let item of  this.fbo.items || []) {
            if (this.fbo.showProviderOrders && item.isProvider) item.bg = { colorDisabled: "rgba(50, 50, 50, 0.5)" };
            else item.bg = this.itemBg;
          }
          this.imageMap.items = this.fbo.items || [];
  
        }
      
        if (gotoNext) this.gotoStep(this.step + 1);
        
        
      }
    }).finally(() => {
      this.processing = false;

    })
  }


  
  formSubmit() {

    // console.log("formSubmit")
    let rv = this.apps.bas.fs.getRawValue(this.edit.form, this.edit.controls);

    if (rv === false) return;


   
    
    if (this.apps.bas.envtest) console.log("formSubmit, rv: ", rv);

    this.processing = true;

    this.apps.bas.ws.json({ 
      aType: "spaceBooking", 
      action: "placeOrder", 
      id: this.route.snapshot.queryParams.id, 
      jsonData: JSON.stringify(rv)
    
    }).then((json) => {
      this.processing = false;

      if (this.apps.bas.envtest) console.log("placeOrder, json: ", json);
      if (json.success) {
        this.booking = json.booking;
        this.booking.invoice = json.invoice;

        this.gotoStep(this.step + 1);
        
      }
    }).catch(err => {
      this.processing = false;

    });

  }


  imageMapClick(event:any) {
    if (this.apps.bas.envtest) console.log("imageMapClick: ", event);

    // this.apps.bas.ui.success("Du trykket på: " + event.name + "; valgt: " + event.selected);
    
    this.edit.items =  this.imageMap.items.filter((item:any) => item.selected);
    // this.selected = this.edit.items.map((item:any) => item.name).join(", ");
    // this.log = [...this.log, "Du trykket på: " + event.name ]

    this.edit.itemsError = false;

    this.edit.form.controls.spaces.setValue(this.edit.items.map((item:any) => item.id).join(","));

    this.getSpaces();

  }

  nextStep() {
    let currStep = this.step;
    let nextStep = currStep + 1;

    let controls = this.edit.form.controls;

    let rv =  this.apps.bas.fs.getRawValue(this.edit.form, this.edit.controls, true, false);

    let valid = true;
    if (currStep == 0) {
      valid = this.apps.bas.fs.validateFormControls({
        "customerType": controls.customerType
      });

      if (valid) {
        if (this.edit.periods.length == 0) {

          if (this.edit.form.controls.datePeriodAsRange.value) {
            this.addPeriod();
           
          } 

          if (this.edit.periods.length == 0) {
            this.edit.periodsError = true;
            valid = false;
          }
         
        }
      }

      if(valid) {
        if (this.apps.bas.envtest) console.log("before getSpaces: ", rv);

        this.getSpaces(true);
        return;
      }

    } else if (currStep == 1) {
       if (this.edit.items.length == 0) {
          this.edit.itemsError = true;
          valid = false;
        }
    } else if (currStep == 2) {
      
      // valid = this.apps.bas.fs.validateForm(controls.user);
      // valid = this.apps.bas.fs.validateForm(controls.inputs) && valid;

      // if (valid) {

        this.formSubmit();
        return;

      // }

    } else if (currStep == 3) {

      this.editObject({});

    }

    // let formGroup = this.edit.form.controls["step" + currStep];
    // let controls = this.edit.controls["step" + currStep].childMap;

    // let valid = this.apps.bas.fs.validateForm(formGroup);
    // let rv = this.apps.bas.fs.getRawValue(this.edit.form, this.edit.controls, true);
    
    
    if (!valid) {
      if (this.apps.bas.envtest) console.log("nextStep ("+currStep + " -> " +nextStep+") , valid: " + valid + ", rv: ", this.apps.bas.fs.getRawValue(this.edit.form, this.edit.controls, true, false));
      return false;
    }

    

    this.gotoStep(nextStep);
    return true;
  }
  prevStep() {
    this.gotoStep(this.step - 1);
  }

  gotoStep(step:number) {
    this.step = step;
    this.scrollToTop();
    
  }

  onIndexChange(index:any) {
    if (this.apps.bas.envtest) console.log("onIndexChange: ", index);
    if (index > this.step) return;
    // if (this.step >= 3) return;  // TODO
    this.gotoStep(index);
  }

  scrollToTop() {
    this.apps.bas.es.sendParentMessageScroll();
    this.apps.bas.es.sendParentMessageResize();
    
  }

  editObject(obj:any) {

    if(this.apps.bas.envtest) console.log("editObject, obj: ", obj);

    let controls: DynformControl[] = [];

    controls.push(new DynformControl({ 
      key: 'spaces',
      value: ""
    }));
    controls.push(new DynformControl({ 
      key: 'periods',
      value: "",
      required: true , 
    }));
    
    controls.push(new DynformControl({ 
      key: 'comment',
      mk: 'Spørsmål eller kommentarer',  
      controlType: "textarea"
    }));

    let fbo = this.fbo;

    let firstDate = MiscUtil.truncate();
    let lastDate = MiscUtil.setDateAsInt(fbo.maxOrderDate) || this.apps.bas.ui.dateAdd(firstDate, { days: 70 });
    let dateExceptions = fbo.dateExceptions || [];

    // controls.push(new DynformControl({ 
    //   key: 'priceId',  
    //   // valuePath: "user.customUserRolesAsString",
    //   mk: 'Kundetype',  
    //   // controlType: 'select', 
    //   controlType: 'radio-button',
      // radioButtonStyle: "solid", 
    //   required: true,
    //   options: () => {
    //     return this.fbo.prices || []; 
    //   },
    //   // optionsAllowEmpty: true,
    //   optionsFieldValue: "id",
    //   optionsFieldLabel: "name"
    // }));

    controls.push(new DynformControl({ 
      key: 'customerType',  
      // valuePath: "user.customUserRolesAsString",
      mk: 'Kundetype',  
      // controlType: 'select', 
      controlType: 'radio',
      radioButtonStyle: "outline",
      required: true,
      options: () => {
        return this.fbo.customerTypes || []; 
      },
      onChange: (event:any) => {
        let val = event.value;
        // this.edit.customerType = this.apps.bas.us.listToMap(this.fbo.customerTypes)[event.value];
        if (this.edit.customerTypeInputId) {
          this.edit.form.controls.inputs.controls[this.edit.customerTypeInputId].setValue(val);
        }
      },
      // optionsAllowEmpty: true,
      optionsFieldValue: "name",
      optionsFieldLabel: "name"
    }));

    controls.push(new DynformControl({ 
      key: 'datePeriodAsRange',  
      mk: 'common.period', 
      // required: true , 
      controlType: "date-range-picker", 
      data: { 
        readOnly: this.apps.bas.ui.r.xs,
        // inline: true,
        // firstDate: MiscUtil.truncate()
        disabledDate: (date:Date) => {
          if (date.getTime() < firstDate.getTime()) return true;
          if (date.getTime() > lastDate.getTime()) return true;

          if (date.getDay() == 0) return true; // Søndag

          let intDate = MiscUtil.getDateAsInt(date);
          let isException = dateExceptions.indexOf(intDate) >= 0;

          if (isException) return true;

          return false;
        }
       } ,
      //  validators: [
      //    (control: AbstractControl): { [s: string]: boolean } => {
           
      //      if (this.edit.periods.length == 0) return  { error: true, required: true };
      //      return { };
      //    }
      //  ]
    })); 

    let pm = new DynformControl({ 
      key: 'paymentMethod',  
      // valuePath: "user.customUserRolesAsString",
      mk: 'Velg betalingsmåte',  
      // controlType: 'select', 
      controlType: 'radio-button',
      radioButtonStyle: "solid", 
      // required: true,
      options: () => {
        return this.fbo.paymentMethods || []; 
      },
      show: () => {
        return this.fbo.sumAmountAsDouble > 0;
      },
      // optionsAllowEmpty: true,
      optionsFieldValue: "id",
      optionsFieldLabel: "mkName",
      onChange: (event) => {
        this.edit.pm = this.apps.bas.us.listToMap(this.fbo.paymentMethods)[event.value];
      },
      validators: [
        (control: AbstractControl): { [s: string]: boolean } => {
          let fc = control as UntypedFormControl;
          
          if (fc.value !== undefined) return { };

          let isFrivillighet = this.edit.form.controls.customerType.value == "Frivillighet";
          if (isFrivillighet) return  { };

          return  { error: true, required: true };
         
        }
      ]
    });

    if (this.fbo.paymentMethods.length == 1) {
      this.edit.pm = this.fbo.paymentMethods[0];
      pm.value = this.edit.pm.id;
    }


    
    let upCmEhf = this.apps.bas.ds.config.company.upCmEhf;


    controls.push(pm);

    controls.push(new DynformControl({ 
      key: "user",
      controlType: "formGroup",
      children: [
        

        new DynformControl({ 
          key: 'username',     
          mk: 'E-post', 
          required: true,
          type: "email"
        }),
        new DynformControl({ 
          key: 'telephone',     
          mk: 'Telefon', 
          required: true 
        }),

        new DynformControl({ 
          key: 'contactPerson',     
          mk: 'Kontaktperson', 
          required: true 
        }),
        new DynformControl({ 
          key: 'orgNumber',     
          mk: 'Organisasjonsnummer', 
          // required: true 
        }),
        
        new DynformControl({ 
          key: 'name',     
          mk: 'Firmanavn', 
          // required: true 
        }),
        new DynformControl({ 
          key: 'acceptEula',     
          mk: 'Aksepter avtalevilkår', 
          controlType: "checkbox",
          required: true 
        }),
        new DynformControl({ 
          key: 'ehfEnabled',     
          mk: 'Send faktura som EHF', 
          controlType: "checkbox",
          required: false,
          show: () => upCmEhf && this.edit.pm?.enumName == "INVOICE",
        }),
        new DynformControl({ 
          key: 'ehfOrderReference',     
          mk: 'EHF ordrereferanse', 
          // controlType: "checkbox",
          required: false,
          show: () => {
            return this.edit.pm?.enumName == "INVOICE" && this.edit.form.controls.user.controls.ehfEnabled.value;
          }
        }),

      ]
    }));

    
    

    let inputs = new DynformControl({ 
      key: "inputs",
      controlType: "formGroup",
      children: []
    });

    // if(this.apps.bas.envtest) console.log("editObject,  this.fbo.parent.inputs: ",  this.fbo.parent.inputs);

    for (let input of this.fbo.parent.inputs) {
      if (!input.enabled) continue;

      if (input.mkName == "Kundetype") {
        this.edit.customerTypeInputId = input.id + "";
      }
      
      let fc = new DynformControl({ 
        key: input.id + "",
        mk: input.mkName, 
        required: input.required,
        show: () => { return input.mkName != "Kundetype" }
      });


      if (input.typeEnum.isCheckbox) fc.controlType = "checkbox";
      if (input.typeEnum.isTextArea) fc.controlType = "textarea";
      else if (input.typeEnum.isSelect) {

        input.optionsAsListMap = input.optionsAsList.map((item:string) => { return { value: item, label: item }});

        fc.controlType = "select";
        fc.options = () => {
          return input.optionsAsListMap;
        }
        fc.optionsAllowEmpty = true;
        fc.value = "";
        
      }

      if (this.apps.bas.envtest) console.log("input: ", input, ", fc: ", fc)


      inputs.children.push(fc);


      /*


      */
    }

    controls.push(inputs);

    


    this.apps.bas.fs.updateFormObj(this.edit, controls, obj);

    this.edit.periods = [];
    this.edit.items = [];
    
  } 

  testForm() {

    let ctls = this.edit.form.controls;
    let uc = ctls.user.controls;
    let ic = ctls.inputs.controls;

    ctls.comment.setValue("Dette er en TEST-kommentar");

    ctls.paymentMethod.setValue(1);

    uc.username.setValue("demo@booktech.no");
    uc.name.setValue("Booktech Demo");
    uc.contactPerson.setValue("Ole Olsen");
    // uc.zipCode.setValue("0340");
    // uc.city.setValue("Oslo");
    uc.telephone.setValue("99887766");
    uc.acceptEula.setValue(true);

    ic["472"]?.setValue("Diverse varer");     // Hva skal plassen benyttes til?  *
    ic["473"]?.setValue("Nei"); // Skal du selge mat fra godkjent salgsvogn?  *
    ic["474"]?.setValue("Nei");     // Trenger du strøm?  *
    ic["475"]?.setValue("Nei");     // Skal du bruke høyttaler under arrangementet?  *
    ic["476"]?.setValue("10:00-15:00"); // Hvilken tid på døgnet blir det aktivitet på plassen?  *


  }


}

