<btlib-admin-page-wrapper>
 
 
  <nz-spin [nzSpinning]="isSpinning">

    <div class="m-d" *ngIf="edit.obj">

  
    <div nz-row  [nzGutter]="8" style="flex-flow: row;">
      <div nz-col [nzFlex]="'35px'">


        <nz-affix [nzOffsetTop]="10">
          

          <div id="linkTop"></div>
          <nz-anchor [nzAffix]="false">

            <button nz-button nzType="primary" nzShape="circle" class="section-button-back" [nzSize]="apps.bas.ui.r.gtesm ? 'default' : 'small'" [routerLink]="apps.bas.ui.getRouterPrefix() + '/admin/orders'" [title]="'web.common.bookings' | actrans">
              <i nz-icon nzType="left"></i>
            </button>

            <nz-link [nzHref]="'#linkTop'" [nzTitle]="saveLinkTemplate" class="section-link" > 
              <ng-template #saveLinkTemplate>
                <button nz-button nzType="primary" nzShape="circle" class="section-button-save" [nzSize]="apps.bas.ui.r.gtesm ? 'default' : 'small'" (click)="submitForm()" [title]="'common.save' | actrans">
                  <i nz-icon nzType="save"></i>
                </button>
              </ng-template>

            </nz-link>
            
            <nz-link [nzHref]="'#linkTop'" [nzTitle]="helpLinkTemplate" class="section-link" > 
              <ng-template #helpLinkTemplate>
                <button nz-button nzType="primary" nzShape="circle" class="section-button" [nzSize]="apps.bas.ui.r.gtesm ? 'default' : 'small'" (click)="helpVisible = true;"  [title]="'common.help' | actrans" ><i nz-icon nzType="question-circle"></i></button>
              </ng-template>

            </nz-link>

            <nz-link *ngFor="let s of apps.bas.us.values(Sections)" [nzHref]="'#section-' + s.id" [nzTitle]="linkTemplate" class="section-link" > 
              <ng-template #linkTemplate>
                <button  *ngIf="isSectionVisible(s.id, true)" nz-button nzType="primary" nzShape="circle" class="section-button" [nzSize]="apps.bas.ui.r.gtesm ? 'default' : 'small'" [title]="s.title | actrans" >
                  <i nz-icon [nzType]="s.icon"></i> 
                </button>

              </ng-template>

            </nz-link>

 
          </nz-anchor>



        </nz-affix>

        <nz-modal [(nzVisible)]="helpVisible" nzTitle="Hjelp" (nzOnCancel)="helpVisible = false;" (nzOnOk)="helpVisible = false;">
          <ng-container *nzModalContent>
            <h2>{{ 'app.cbapi5.page.admin.product.sections' | actrans }}</h2>
            <div nz-row *ngFor="let s of getVisibleSections()">
              <div nz-col  [nzFlex]="'35px'" >
                <i nz-icon [nzType]="s.icon"></i> 
              </div>
              <div nz-col >
                {{ s.title | actrans }}

              </div>
            </div>
  
          </ng-container>
        </nz-modal>

       
      </div>

      <div nz-col  nzFlex="auto" style="width: calc(100% - 40px);">

        <nz-alert nzType="warning" nzMessage="Denne funksjonen er under utvikling." nzShowIcon></nz-alert>
    

        <form nz-form [formGroup]="edit.form" class="user-form" (ngSubmit)="submitForm()" nzLayout="vertical" [nzAutoTips]="apps.bas.fs.autoTips">
 
        <h1>{{ edit.obj.bid }} </h1>

        <hr/>
 
        <div id="section-items" *ngIf="isSectionVisible('items')">

          <div>
            <button nz-button nzType="default" (click)="$event.preventDefault(); addRow()">{{ 'web.common.booking.newRow' | actrans }}</button>
            <button nz-button nzType="default" (click)="$event.preventDefault(); setDefinitive()">{{ 'web.common.booking.setDefinitive' | actrans }}</button>
            
            
            <!-- <a href="#" class="btn btn-success display-inline-block pull-right" id="newRow"><nit:message key="web.common.booking.newRow" ct="false" /></a><nit:message key="web.common.booking.newRow" sm="false" />
            <a href="#" class="btn btn-warning display-inline-block pull-right hidden" id="btn-set-definitive" title="" ><nit:message key="web.common.booking.setDefinitive" ct="false" /></a><nit:message key="web.common.booking.setDefinitive" sm="false" /> -->
                
          </div>
          <h2>{{ 'bus.booking.items' | actrans }}</h2>

          <nz-table 
            #nzTableOrderItems
            [nzData]="edit.obj.items || []" 
            nzTableLayout="fixed" 
            [nzShowPagination]="false" 
            [nzPageSize]="100"
            nzSize="small"
            class="nz-table-xs"
            >
            <thead>
              <tr>
                <th nzWidth="30px"></th>
           
     
                <th [nzWidth]="apps.bas.ui.r.gtemd ? '100px' : '40px'">
                  <span *ngIf="apps.bas.ui.r.gtemd">{{ 'common.status' | actrans }}</span>
                </th>
                <th>{{ 'common.product' | actrans }}</th>

                <th *ngIf="apps.bas.ui.r.gtelg">{{ 'common.updated' | actrans }}</th>
                <th *ngIf="apps.bas.ui.r.gtemd">{{ 'common.period' | actrans }}</th>

                <th *ngIf="apps.bas.ui.r.gtelg">{{ 'bus.booking.guests' | actrans }}</th>
                <th *ngIf="apps.bas.ui.r.gtelg">{{ 'common.count' | actrans }}</th>
    
                <th nzAlign="right">{{ 'common.amount' | actrans }}</th>
                <th nzWidth="30px"></th>
                <th nzWidth="30px"></th>
           
              </tr>
            </thead>
            <tbody>
              <ng-container  *ngFor="let data of nzTableOrderItems.data; let idx = index">
                <tr>
                  <td>
                    <i nz-icon class="cursor-pointer" [nzType]="data.expanded ? 'minus-square' : 'plus-square'" (click)="data.expanded = !data.expanded" ></i>
                  </td>
                  <td [class]="data.statusCssClass">
                    <span *ngIf="apps.bas.ui.r.gtemd">{{ data.mkStatus }}</span> 
                  </td>
                  <td>{{ data.product }}</td>
     
              
                  <td *ngIf="apps.bas.ui.r.gtelg">{{ data.updated }}</td>
                  
                  <td *ngIf="apps.bas.ui.r.gtemd">{{ data.periodString }}</td>
           
                  <td *ngIf="apps.bas.ui.r.gtelg">{{ data.guests }}</td>
                  <td *ngIf="apps.bas.ui.r.gtelg">{{ data.count }}</td>

                  <td nzAlign="right">{{ data.amountDouble | nf }}</td>

                  <td nzAlign="center">
                    <label 
                      nz-checkbox 
                      [nzValue]="true" 
                      [ngModelOptions]="{standalone: true}"
                      [ngModel]="selected[data.id]"
                      (ngModelChange)="onSelected($event, data)"
                      ></label>
         
                  </td>

                  <td nzAlign="center">
                    <i nz-icon class="cursor-pointer" nzType="edit" (click)="editItem(idx, 'item')" ></i>
           
                  </td>

             
                </tr>
                <tr  *ngIf="data.expanded" class="tr-expanded"> 
                 
                  <td [attr.colspan]="(
                                             4 
                    + (apps.bas.ui.r.ltesm ? 0 : 0)  
                    + (apps.bas.ui.r.gtesm ? 0 : 0) 
                    + (apps.bas.ui.r.gtemd ? 1 : 0) 
                    + (apps.bas.ui.r.gtelg ? 3 : 0) 
                    + (apps.bas.ui.r.gtexl ? 0 : 0)
                    )">
                  <div class="tr-extended-div">
                 
                   
                    <!-- 
                    TODO: vise mer info her? 

                    <btlib-content-row [cols]="orderRows['item' + data.id]" [grid]="apps.bas.ui.gridMd"></btlib-content-row>
      
                    <div *ngIf="orderRows['item' + data.id + '_inputValues']">
                      <h3>{{ 'bus.productInput' | actrans }}</h3>
                      <btlib-content-row [cols]="orderRows['item' + data.id + '_inputValues']" [grid]="apps.bas.ui.gridMd"></btlib-content-row>
      
                    </div> -->

                  </div>
                  </td>

                </tr>
              </ng-container>
            </tbody>
            <tfoot *ngIf="!(edit.obj.status?.isAvailability || edit.obj.status?.isProvider)">
              <tr>
                <th colspan="3">
                  {{ 'common.sum' | actrans }}
                </th>
                <th [attr.colspan]="(
                    1
                    + (apps.bas.ui.r.ltesm ? 0 : 0)  
                    + (apps.bas.ui.r.gtesm ? 0 : 0) 
                    + (apps.bas.ui.r.gtemd ? 1 : 0) 
                    + (apps.bas.ui.r.gtelg ? 3 : 0) 
                    + (apps.bas.ui.r.gtexl ? 0 : 0)
                  )"
                  
                  nzAlign="right"
                >
                {{ edit.obj.amountDouble | nf }}
                </th>
           
              </tr>
              <tr>
                <th colspan="3">
                  {{ 'bus.invoice.paidAmount' | actrans }}
                </th>
                <th [attr.colspan]="(
                    1
                    + (apps.bas.ui.r.ltesm ? 0 : 0)  
                    + (apps.bas.ui.r.gtesm ? 0 : 0) 
                    + (apps.bas.ui.r.gtemd ? 1 : 0) 
                    + (apps.bas.ui.r.gtelg ? 3 : 0) 
                    + (apps.bas.ui.r.gtexl ? 0 : 0)
                  )"
                  
                  nzAlign="right"
                >
                {{ edit.obj.paidAmountDouble | nf }}
                </th>
           
              </tr>
              <tr>
                <th colspan="3">
                  {{ 'pdf.invoice.remainingAmount' | actrans }}
                </th>
                <th [attr.colspan]="(
                    1
                    + (apps.bas.ui.r.ltesm ? 0 : 0)  
                    + (apps.bas.ui.r.gtesm ? 0 : 0) 
                    + (apps.bas.ui.r.gtemd ? 1 : 0) 
                    + (apps.bas.ui.r.gtelg ? 3 : 0) 
                    + (apps.bas.ui.r.gtexl ? 0 : 0)
                  )"
                  
                  nzAlign="right"
                >
                {{ edit.obj.remainingAmountDouble | nf }}
                </th>
           
              </tr>

            </tfoot>
          </nz-table>

          <div>
            <button nz-button nzType="default" (click)="$event.preventDefault(); cancelAll()">{{ 'web.common.booking.cancelAll' | actrans }}</button>
            <button nz-button nzType="default" (click)="$event.preventDefault(); cloneOrder()">{{ 'web.common.booking.cloneOrderButton' | actrans }}</button> <!-- web.common.booking.cloneOrderButtonHelp  -->
            <button nz-button nzType="default" (click)="$event.preventDefault(); cloneSelectedRows(true)">{{ 'web.common.booking.cloneSelectedRowsToThis' | actrans }}</button>
          </div>
          <div>
            <button nz-button nzType="default" (click)="$event.preventDefault(); creditSelectedRows(false)">{{ 'web.common.booking.creditSelectedRows' | actrans }}</button>
            <button nz-button nzType="default" (click)="$event.preventDefault(); creditSelectedRows(true)">{{ 'web.common.booking.creditSelectedRowsToThis' | actrans }}</button>
            
  
          </div>
          
          <!-- [nzComponentParams]="{ item: edit.item.obj }"  -->
          <nz-modal [nzVisible]="!!edit.item.obj" nzTitle="{{ 'bus.bookingItem' | actrans | str }}" nzWidth="95vw" [nzContent]="modalContentItem" (nzOnCancel)="edit.item.obj = null" (nzOnOk)="saveItem('item')">
            <ng-template #modalContentItem let-params>


              <form nz-form [formGroup]="edit.item.form" class="item-form mt-d" nzLayout="vertical" [nzAutoTips]="apps.bas.fs.autoTips">

                <btlib-form-row [controls]="[  
                  edit.item.controls.id, 
                  edit.item.controls.updated 
                ]" [form]="edit.item.form"  [grid]="apps.bas.ui.gridMd" (change)="onChange($event)"  ></btlib-form-row>  
               
                <btlib-form-row [controls]="[ 
                  edit.item.controls.parent,
                  edit.item.controls.status 
                  
                ]" [form]="edit.item.form"  [grid]="apps.bas.ui.gridMd"  (change)="onChange($event)" ></btlib-form-row>  
                

                <btlib-form-row [controls]="[ 
                  edit.item.controls.product,
                  edit.item.controls.periodAsRange ,
                  edit.item.controls.quantity 
                  
                ]" [form]="edit.item.form"  [grid]="apps.bas.ui.gridMd"  (change)="onChange($event)" ></btlib-form-row>  
                
                <btlib-form-row [controls]="
                  edit.item.controls.gcs.children
                " [form]="edit.item.form.controls.gcs"  [grid]="apps.bas.ui.gridXs"  (change)="onChange($event)" ></btlib-form-row>  
                
                
                <btlib-form-row [controls]="[ 
                  edit.item.controls.price,
                  edit.item.controls.manAmountDouble ,
                  edit.item.controls.retailAmountDouble ,
                  edit.item.controls.providerAmountDouble 
                  
                ]" [form]="edit.item.form"  [grid]="apps.bas.ui.gridMd"  (change)="onChange($event)" ></btlib-form-row>  
                
                  
                <btlib-form-row [controls]="[ 
                  edit.item.controls.invoiceText 
                  
                ]" [form]="edit.item.form"  [grid]="apps.bas.ui.gridXl"  (change)="onChange($event)" ></btlib-form-row>  
              
                <btlib-form-row [controls]="[ 
                  edit.item.controls.additionalInfo 
                  
                ]" [form]="edit.item.form"  [grid]="apps.bas.ui.gridLg"  (change)="onChange($event)" ></btlib-form-row>  
               
                <btlib-form-row [controls]="[ 
                  edit.item.controls.completed,
                  edit.item.controls.campaignCode ,
                  edit.item.controls.giftCardCode ,
                  edit.item.controls.settled  ,
                  edit.item.controls.payoutConfirmedCompany  ,
                  edit.item.controls.payoutConfirmedProvider  ,
                  edit.item.controls.credits  ,
                  edit.item.controls.eosId 
                  
                ]" [form]="edit.item.form"  [grid]="apps.bas.ui.gridSm"  (change)="onChange($event)" ></btlib-form-row>  
              

                <div>
                  <h2>{{ 'bus.product.inputs' | actrans }}</h2>
                  <btlib-form-row [controls]="edit.item.controls.inputValues.children" [form]="edit.item.form.controls.inputValues"  [grid]="apps.bas.ui.gridMd"  (change)="onChange($event)" ></btlib-form-row>  
              
                </div>
                
              
                <btlib-form-row [controls]="[ 
                  edit.item.controls.forceAdd,
                ]" [form]="edit.item.form"  [grid]="apps.bas.ui.gridXl"  (change)="onChange($event)" ></btlib-form-row>  
              
              </form>
  

              <!--

    // TODO: bi_input_values

    // TODO: Tags

                		<div class="form-group">
								<label class="col-sm-4 control-label" for=""><nit:message key="common.tags"/>:</label>
								<div class="col-sm-8"><p class="form-control-static" id="biTagsDiv"></p></div>
							</div>
							<c:if test="${isBn}">
								<div class="form-group">
									<label class="col-sm-4 control-label" for=""><nit:message key="common.addTag"/> <span class="label label-info label-bn">BN</span>:</label>
									<div class="col-sm-8">
										<nit:select id="newBiTag" name="newBiTag" cssClass="form-control " items="${fbo.biTags}" allowEmpty="true" valueProperty="id" labelProperty="mk" />
										<a href="#" id="addBiTag" class="btn btn-default"><nit:message key="common.addTag" ct="false" /></a>
									</div>
								</div>
							</c:if>

              -->
            </ng-template>

            <div *nzModalFooter>
              
              <!-- <button nz-button nzType="default" (click)="showAvailableProducts()">{{ 'web.common.booking.showAvailableProducts' | actrans }}</button> --> 
              <!-- TODO:low -->

              <button nz-button nzType="default" (click)="edit.item.obj = null">{{ 'common.close' | actrans }}</button>
              <button nz-button nzType="primary" (click)="saveItem('item')" [nzLoading]="isSpinning">{{ 'common.save' | actrans }}</button> <!-- 	<c:if test="${user.isAdminSu and user.upApAdminOrderWrite}"> -->
            </div>
          </nz-modal>

        </div>

       
        <div id="section-general" *ngIf="isSectionVisible('general')">
          <h2>{{ 'common.general' | actrans }}</h2>
          
          <div>
            <btlib-form-row  [controls]="[ 
              edit.controls.bid, 
              edit.controls.time, 
              edit.controls.updated, 
              edit.controls.createdBy, 
              edit.controls.parent, 
              edit.controls.poEmail, 
              edit.controls.source, 
              edit.controls.referer, 
              edit.controls.lastSentProviderMail, 
            ]" [form]="edit.form"  [grid]="apps.bas.ui.gridSm" (change)="onChange($event)" ></btlib-form-row> 
            
            <div *ngIf="true">
               
              <button nz-button nzType="default" (click)="$event.preventDefault(); sendProviderMail()">{{ 'web.common.booking.sendProviderMail' | actrans }}</button>
              <button
              nz-popconfirm
              [nzPopconfirmTitle]="'app.lib.common.popconfirm.confirm' | actrans | str"
              (nzOnConfirm)="sendArrivalInfo()"
               nz-button nzType="default">{{ 'web.common.order.sendArrivalInfo' | actrans }}</button>
           
            </div>

            <div *ngIf="c.upCbHotel"><!-- <c:if test="${company.upCbHotel}"> -->
              
              <btlib-form-row  [controls]="[ 
              edit.controls.mkIsCheckedIn
              ]" [form]="edit.form"  [grid]="apps.bas.ui.gridSm" (change)="onChange($event)" ></btlib-form-row> 
            
              <button *ngIf="!edit.obj.isCheckedIn" nz-button nzType="default" (click)="$event.preventDefault(); hotelCheckIn(true)">{{ 'common.checkIn' | actrans }}</button>
              <button *ngIf="edit.obj.isCheckedIn" nz-button nzType="default" (click)="$event.preventDefault(); hotelCheckIn(false)">{{ 'common.checkOut' | actrans }}</button>
           
            </div>

            <btlib-form-row  [controls]="[ 
      
              edit.controls.orderReference,
              edit.controls.externalId,
              edit.controls.purpose,
            ]" [form]="edit.form"  [grid]="apps.bas.ui.gridSm" (change)="onChange($event)" ></btlib-form-row> 
            
            <btlib-form-row  [controls]="[ 
        
              edit.controls.comment,
              edit.controls.notes
            ]" [form]="edit.form"  [grid]="apps.bas.ui.gridLg" (change)="onChange($event)" ></btlib-form-row> 
          
            <btlib-form-row  [controls]="[ 
        
            edit.controls.jsonDataString
          ]" [form]="edit.form"  [grid]="apps.bas.ui.gridLg" (change)="onChange($event)" ></btlib-form-row> 
        
          </div>


          <div>

            <!--

								<div class="form-group">
									<label class="col-sm-4 control-label" for="bookingTagsDiv"><nit:message key="common.tags"/>:</label>
									<div class="col-sm-8"><p class="form-control-static" id="bookingTagsDiv"></p></div>
								</div>
								<c:if test="${user.isAdminSu and user.upApAdminOrderWrite}">
									<div class="form-group">
										<label class="col-sm-4 control-label" for=""><nit:message key="common.addTag"/>:</label>
										<div class="col-sm-8">
											<nit:select id="newBookingTag" name="newBookingTag" cssClass="form-control " items="${fbo.bookingTags}" allowEmpty="true" valueProperty="id" labelProperty="mk" />
											<a href="#" id="addBookingTag" class="btn btn-default"><nit:message key="common.addTag" ct="false" /></a>
										</div>
									</div>
								</c:if>
								
								<div id="orderTagsCustom"> 
								
									<div class="form-group">
										<label class="col-sm-4 control-label" for="newCustomTag"><nit:message key="common.tags"/>:</label>
										<div class="col-sm-5">
											<input type="text" id="newCustomTag" class="newTag form-control" style="" value=""  />
										</div>
										<div class="col-sm-3">
											<a href="#" id="" class="addTag btn btn-default"><i class="glyphicon glyphicon-tag"></i> <nit:message key="common.addTag" ct="false" /></a>
										</div>
									</div>
									<div class="form-group">
										<div class="col-sm-offset-4 col-sm-8">
											<div id="tagsCustomDiv" class="tagsDiv"></div>
										</div>
									</div>
									
								</div>

            -->
          </div>

        </div>

        <div id="section-customer" *ngIf="isSectionVisible('customer')">
          <h2>{{ 'common.customer' | actrans }}</h2>
          
          <div>
            <btlib-form-row  [controls]="[ 
              edit.controls.user, 
          
            ]" [form]="edit.form"  [grid]="apps.bas.ui.gridSm" (change)="onChange($event)" ></btlib-form-row> 
            
          </div>

          <div>

            <button nz-button nzType="default" (click)="$event.preventDefault(); userSearch()">{{ 'web.common.booking.userSearch' | actrans }}</button>
            <button nz-button nzType="default" (click)="$event.preventDefault(); newUser()">{{ 'web.common.booking.newUser' | actrans }}</button>
            <button nz-button nzType="default" (click)="$event.preventDefault(); editUser()">{{ 'web.common.booking.editUser' | actrans }}</button>
            <button nz-button nzType="default" (click)="$event.preventDefault(); orderSendSms()">{{ 'web.ajax.sms' | actrans }}</button>
            <button nz-button nzType="default" (click)="$event.preventDefault(); orderSendEmail()">{{ 'web.ajax.email' | actrans }}</button>
            <button nz-button nzType="default" (click)="$event.preventDefault(); orderSendPushMessage()">{{ 'web.admin.app.sendPushMessage' | actrans }}</button> <!-- <c:if test="${company.upCmApp}"> -->
           
          </div>
        </div>
         
        <div id="section-invoice" *ngIf="isSectionVisible('invoice')">
          <h2>{{ 'bus.invoice' | actrans }}</h2>
          
          <div>
            <btlib-form-row  [controls]="[ 
              edit.controls.mkGenerateInvoice, 
            ]" [form]="edit.form"  [grid]="apps.bas.ui.gridSm" (change)="onChange($event)" ></btlib-form-row> 
            
          </div>
          <button *ngIf="edit.obj.generateInvoiceBool" nz-button nzType="default" (click)="$event.preventDefault(); doNotGenerateInvoice()">{{ 'web.common.booking.doNotGenerateInvoice' | actrans }}</button>
           
          <div>
            <btlib-form-row  [controls]="[ 
              edit.controls.paymentMethod , 
              edit.controls.invoiceDateAsString , 
              edit.controls.advancePaymentAmountAsDouble , 
              edit.controls.dueDateAdvancePaymentAsString , 
              edit.controls.dueDateFinalPaymentAsString , 
              edit.controls.settleDateAsString 
            ]" [form]="edit.form"  [grid]="apps.bas.ui.gridSm" (change)="onChange($event)" ></btlib-form-row> 
            
          </div>
          <div>
            <btlib-form-row  [controls]="[ 
              edit.controls.userInvoiceNote, 
              edit.controls.invoiceNote 
            ]" [form]="edit.form"  [grid]="apps.bas.ui.gridMd" (change)="onChange($event)" ></btlib-form-row> 
            
          </div>
          <div>
            <btlib-form-row  [controls]="[ 
              edit.controls.doSendInvoice, 
              edit.controls.doGenerateInvoice , 
              edit.controls.doGenerateInvoiceNotice , 
              edit.controls.doSettle , 
              edit.controls.doSendInvoice , 
              edit.controls.doCancelUnpaid , 
              edit.controls.generateInitialInvoice , 
              edit.controls.generateInvoiceForce , 
              edit.controls.includeEula 
            ]" [form]="edit.form"  [grid]="apps.bas.ui.gridSm" (change)="onChange($event)" ></btlib-form-row> 
            
          </div>

          <div> <!-- 	<c:if test="${user.isAdminSu and user.upApAdminOrderWrite}"> -->
            <button nz-button nzType="default" (click)="$event.preventDefault(); generateInvoice()">{{ 'bus.booking.doGenerateInvoice' | actrans }}</button>
            <button nz-button nzType="default" (click)="$event.preventDefault(); sendInvoice()">{{ 'web.common.booking.sendInvoice' | actrans }}</button>
            <button nz-button nzType="default" (click)="$event.preventDefault(); sendEhfInvoice()">{{ 'web.common.booking.sendEhfInvoice' | actrans }}</button>
            <button nz-button nzType="default" (click)="$event.preventDefault(); sendPaymentConfirmation()">{{ 'web.common.order.sendPaymentConfirmation' | actrans }}</button>
            <button nz-button nzType="default" (click)="$event.preventDefault(); sendOrderConfirmation()">{{ 'web.common.order.sendOrderConfirmation' | actrans }}</button>
            
          </div>
          <div>
            <button nz-button nzType="default" (click)="$event.preventDefault(); addPayment()">{{ 'web.common.booking.addPayment' | actrans }}</button>
            <button nz-button nzType="default" (click)="$event.preventDefault(); addPaymentManual()">{{ 'web.common.booking.addPayment' | actrans }} (bn)</button> <!-- <c:if test="${wpd.isBn}"> -->
            <button nz-button nzType="default" (click)="$event.preventDefault(); useGiftCard()">{{ 'web.order.order.useGiftCard' | actrans }}</button> <!-- <c:if test="${company.findHasGiftCard}"> -->
            <button nz-button nzType="default" (click)="$event.preventDefault(); refundUser()">{{ 'common.refund' | actrans }}</button>
            <button nz-button nzType="default" (click)="$event.preventDefault(); transferPayment()">{{ 'web.common.booking.transferPayment' | actrans }}</button>
            
          </div>
    

          <div>
            <h2>{{ 'bus.booking.invoices' | actrans }}</h2>
        
            <nz-table 
              #nzTableInvoices
              [nzData]="edit.obj.invoices || []" 
              nzTableLayout="fixed" 
              [nzShowPagination]="false" 
              [nzPageSize]="100"
              nzSize="small"
              class="nz-table-xs"
              >
              <thead>
                <tr>
                  <th nzWidth="30px"></th>

                  <th *ngIf="apps.bas.ui.r.gtesm">{{ 'common.number' | actrans }}</th>

                  <th *ngIf="apps.bas.ui.r.gtelg">{{ 'bus.invoice.kid' | actrans }}</th>
                  <th *ngIf="apps.bas.ui.r.gtemd">{{ 'common.status' | actrans }}</th>
                  <th *ngIf="apps.bas.ui.r.gtemd">{{ 'common.type' | actrans }}</th>

                  <th>{{ 'bus.invoice.date' | actrans }}</th>
                  <th *ngIf="apps.bas.ui.r.gtelg">{{ 'bus.invoice.dueDate' | actrans }}</th>
        
                  <th nzAlign="right">{{ 'common.amount' | actrans }}</th>
      
            
                </tr>
              </thead>
              <tbody>
                <ng-container  *ngFor="let data of nzTableInvoices.data; let idx = index">
                  <tr>
                    <td>
                      <i nz-icon class="cursor-pointer" [nzType]="data.expanded ? 'minus-square' : 'plus-square'" (click)="data.expanded = !data.expanded" ></i>
                    </td>
                    <td *ngIf="apps.bas.ui.r.gtesm">{{ data.number }}</td>
                    <td *ngIf="apps.bas.ui.r.gtelg">{{ data.kid }}</td>
                    
                    <td *ngIf="apps.bas.ui.r.gtemd">{{ data.mkStatus }}</td>
                    <td *ngIf="apps.bas.ui.r.gtemd">{{ data.mkType }}</td>
            
                    <td>{{ data.dateAsDate }}</td>
                    <td *ngIf="apps.bas.ui.r.gtelg">{{ data.dueDateAsDate }}</td>

                    <td nzAlign="right">{{ data.amountDouble | nf }}</td>             
                  </tr>
                  <tr  *ngIf="data.expanded" class="tr-expanded"> 
                  
                    <td [attr.colspan]="(
                                              3
                      + (apps.bas.ui.r.gtesm ? 1 : 0) 
                      + (apps.bas.ui.r.gtemd ? 2 : 0) 
                      + (apps.bas.ui.r.gtelg ? 2 : 0) 
                      + (apps.bas.ui.r.gtexl ? 0 : 0)
                      )">
                    <div class="tr-extended-div">
                  
                    
                      <!-- 
                      TODO: vise mer info her? 

                      <btlib-content-row [cols]="orderRows['item' + data.id]" [grid]="apps.bas.ui.gridMd"></btlib-content-row>
        
                      <div *ngIf="orderRows['item' + data.id + '_inputValues']">
                        <h3>{{ 'bus.productInput' | actrans }}</h3>
                        <btlib-content-row [cols]="orderRows['item' + data.id + '_inputValues']" [grid]="apps.bas.ui.gridMd"></btlib-content-row>
        
                      </div> -->

                    </div>
                    </td>

                  </tr>
                </ng-container>
              </tbody>
            </nz-table>
          </div>

          <div>
            <h2>{{ 'bus.invoice.payments' | actrans }}</h2>
        
            <nz-table 
              #nzTablePayments
              [nzData]="edit.obj.payments || []" 
              nzTableLayout="fixed" 
              [nzShowPagination]="false" 
              [nzPageSize]="100"
              nzSize="small"
              class="nz-table-xs"
              >
              <thead>
                <tr>
                  <th nzWidth="30px"></th>

                  <th *ngIf="apps.bas.ui.r.gtesm">{{ 'common.time' | actrans }}</th>
                  <th *ngIf="true">{{ 'common.date' | actrans }}</th>
                  <th *ngIf="apps.bas.ui.r.gtemd">{{ 'common.type' | actrans }}</th>
                  <th *ngIf="true">{{ 'common.success' | actrans }}</th>
                  <th *ngIf="apps.bas.ui.r.gtemd">{{ 'common.user' | actrans }}</th>

                  <th *ngIf="apps.bas.ui.r.gtelg">{{ 'bus.payment.transId' | actrans }}</th>
                  <th *ngIf="apps.bas.ui.r.gtelg">{{ 'common.comment' | actrans }}</th>
        
                  <th nzAlign="right">{{ 'common.amount' | actrans }}</th>
 
                </tr>
              </thead>
              <tbody>
                <ng-container  *ngFor="let data of nzTablePayments.data; let idx = index">
                  <tr>
                    <td>
                      <i nz-icon class="cursor-pointer" [nzType]="data.expanded ? 'minus-square' : 'plus-square'" (click)="data.expanded = !data.expanded" ></i>
                    </td>
                    <td *ngIf="apps.bas.ui.r.gtesm">{{ data.time }}</td>
                    <td *ngIf="true">{{ data.dateAsDate }}</td>
                    <td *ngIf="apps.bas.ui.r.gtemd">{{ data.mkType }}</td>
            
                    <td>{{ data.mkSuccess }}</td> <!--TODO : label-success/danger -->
                    <td *ngIf="apps.bas.ui.r.gtemd">{{ data.user }}</td>
            
                    <td *ngIf="apps.bas.ui.r.gtelg">{{ data.findTransId }}</td>    
                    <td *ngIf="apps.bas.ui.r.gtelg">{{ data.comment }}</td>

                    <td nzAlign="right">{{ data.amountDouble | nf }}</td>             
                  </tr>
                  <tr  *ngIf="data.expanded" class="tr-expanded"> 
                  
                    <td [attr.colspan]="(
                                              3
                      + (apps.bas.ui.r.gtesm ? 1 : 0) 
                      + (apps.bas.ui.r.gtemd ? 2 : 0) 
                      + (apps.bas.ui.r.gtelg ? 2 : 0) 
                      + (apps.bas.ui.r.gtexl ? 0 : 0)
                      )">
                    <div class="tr-extended-div">
                  
                    
                      <!-- 
                      TODO: vise mer info her? 

                      <btlib-content-row [cols]="orderRows['item' + data.id]" [grid]="apps.bas.ui.gridMd"></btlib-content-row>
        
                      <div *ngIf="orderRows['item' + data.id + '_inputValues']">
                        <h3>{{ 'bus.productInput' | actrans }}</h3>
                        <btlib-content-row [cols]="orderRows['item' + data.id + '_inputValues']" [grid]="apps.bas.ui.gridMd"></btlib-content-row>
        
                      </div> -->

                    </div>
                    </td>

                  </tr>
                </ng-container>
              </tbody>
            </nz-table>
          </div>

         


        </div>

        <div id="section-economy" *ngIf="isSectionVisible('economy')">
          <h2>{{ 'common.economy' | actrans }}</h2>
          
          <button nz-button nzType="default" (click)="$event.preventDefault(); economyRefresh()">{{ 'common.update' | actrans }}</button>
           
          <div *ngIf="edit.obj.economyData ">
            <h2>{{ 'bus.accountingTransaction' | actrans }}</h2>
        
            <nz-table 
              #nzTableAtis
              [nzData]="edit.obj.economyData?.atis || []" 
              nzTableLayout="fixed" 
              [nzShowPagination]="false" 
              [nzPageSize]="100"
              nzSize="small"
              class="nz-table-xs"
              >
              <thead>
                <tr>
                  <th nzWidth="30px"></th>

                  <th *ngIf="apps.bas.ui.r.gtesm">{{ 'bus.accountingItem.voucherNo' | actrans }}</th>
                  <th *ngIf="apps.bas.ui.r.gtemd">{{ 'common.time' | actrans }}</th>
                  <th *ngIf="true">{{ 'common.type' | actrans }}</th>
                  <th *ngIf="apps.bas.ui.r.gtemd">{{ 'common.account' | actrans }}</th>
                  <th *ngIf="apps.bas.ui.r.gtemd">{{ 'bus.accountingItem.ledger' | actrans }}</th>
                  <th *ngIf="apps.bas.ui.r.gtemd">{{ 'common.date' | actrans }}</th>
                  <th nzAlign="right">{{ 'common.amount' | actrans }}</th>
                  <th *ngIf="apps.bas.ui.r.gtelg"></th>
                </tr>
              </thead>
              <tbody>
                <ng-container  *ngFor="let data of nzTableAtis.data; let idx = index">
                  <tr>
                    <td>
                      <i nz-icon class="cursor-pointer" [nzType]="data.expanded ? 'minus-square' : 'plus-square'" (click)="data.expanded = !data.expanded" ></i>
                    </td>
                    
                    <td *ngIf="apps.bas.ui.r.gtesm">
                      {{ data.lineId }}
                      <div *ngIf="apps.bas.ui.r.gtemd">
                        {{ data.transactionId }} / {{ data.id }}
                      </div>
                    </td>
                    <td *ngIf="apps.bas.ui.r.gtemd">
                      {{ data.time }}
                      <div *ngIf="apps.bas.ui.r.gtemd">
                        {{ data.mkTag }}
                      </div>
                    </td>
                    <td *ngIf="true">{{ data.mkType }}</td>
                    <td *ngIf="apps.bas.ui.r.gtemd">{{ data.mkAccount }}</td> 
                    
                    <td *ngIf="apps.bas.ui.r.gtemd">{{ data.ledgerLabel }}</td>
                    <td *ngIf="apps.bas.ui.r.gtemd">{{ data.transactionObj.dateAsDate }}</td>
            
                    <td nzAlign="right">
                      {{ data.amountAsDouble | nf }}
                      <div>
                        {{ data.balanceAsDouble | nf }}
                        
                      </div>
                    </td>  
                    <td *ngIf="apps.bas.ui.r.gtelg">
                      
                    </td>           
                  </tr>
                  <tr  *ngIf="data.expanded" class="tr-expanded"> 
                  
                    <td [attr.colspan]="(
                                              3
                      + (apps.bas.ui.r.gtesm ? 1 : 0) 
                      + (apps.bas.ui.r.gtemd ? 4 : 0) 
                      + (apps.bas.ui.r.gtelg ? 1 : 0) 
                      + (apps.bas.ui.r.gtexl ? 0 : 0)
                      )">
                    <div class="tr-extended-div">
                  
                    
                      <!-- 
                      TODO: vise mer info her? 

                      <btlib-content-row [cols]="orderRows['item' + data.id]" [grid]="apps.bas.ui.gridMd"></btlib-content-row>
        
                      <div *ngIf="orderRows['item' + data.id + '_inputValues']">
                        <h3>{{ 'bus.productInput' | actrans }}</h3>
                        <btlib-content-row [cols]="orderRows['item' + data.id + '_inputValues']" [grid]="apps.bas.ui.gridMd"></btlib-content-row>
        
                      </div> -->

                    </div>
                    </td>

                  </tr>
                </ng-container>
              </tbody>
            </nz-table>
        

            <!-- 			<c:if test="${not empty company.ucObj.netsAvtaleIdDr or company.ucObj.netsAvtaleUseGlobal}"> -->
            <h2>{{ 'bus.drFile.transactions' | actrans }}</h2>
            <nz-table 
              #nzTableDrts
              [nzData]="edit.obj.economyData?.drts || []" 
              nzTableLayout="fixed" 
              [nzShowPagination]="false" 
              [nzPageSize]="100"
              nzSize="small"
              class="nz-table-xs"
              >
              <thead>
                <tr>
                  <th nzWidth="30px"></th>

                  <th *ngIf="apps.bas.ui.r.gtemd">{{ 'common.time' | actrans }}</th>
                  <th *ngIf="apps.bas.ui.r.gtemd">{{ 'common.receipt' | actrans }}</th>
                  <th *ngIf="true">{{ 'common.type' | actrans }}</th>
                  <th *ngIf="apps.bas.ui.r.gtemd">{{ 'bus.drTrans.utbetalingsDato' | actrans }}</th>
                  <th *ngIf="apps.bas.ui.r.gtemd">{{ 'bus.drTrans.kreditKonto' | actrans }}</th>
                  <th nzAlign="right">{{ 'common.amount' | actrans }}</th>
                  <th *ngIf="apps.bas.ui.r.gtelg">{{ 'bus.bookingItem' | actrans }}</th>
                </tr>
        
              </thead>
              <tbody>
                <ng-container  *ngFor="let data of nzTableDrts.data; let idx = index">
                  <tr>
                    <td>
                      <i nz-icon class="cursor-pointer" [nzType]="data.expanded ? 'minus-square' : 'plus-square'" (click)="data.expanded = !data.expanded" ></i>
                    </td>
             
                    <td *ngIf="apps.bas.ui.r.gtemd">
                      {{ data.drFileObj.time }}
                    </td>
                    <td *ngIf="apps.bas.ui.r.gtemd">{{ data.drFileObj.mkReceipt }}</td>
                    <td *ngIf="true">{{ data.mkType }}</td> 
                    
                    <td *ngIf="apps.bas.ui.r.gtemd">
                      {{ data.utbetalingsDato }}
                      <div>
                        {{ data.id }}: {{ data.egenRef }}
                      </div>
                    </td>
                    <td *ngIf="apps.bas.ui.r.gtemd">{{ data.kreditKontoLabel }}</td>
            
                    <td nzAlign="right">
                      {{ data.belopDouble | nf }}
                      <!-- <div> TODO: bisAmount
                        {{ data.belopDouble | nf }}
                        
                      </div> -->
                    </td>  
                    <td *ngIf="apps.bas.ui.r.gtelg">
                      <!-- TODO: bis -->
                    </td>           
                  </tr>
                  <tr  *ngIf="data.expanded" class="tr-expanded"> 
                  
                    <td [attr.colspan]="(
                                              3
                      + (apps.bas.ui.r.gtesm ? 0 : 0) 
                      + (apps.bas.ui.r.gtemd ? 4 : 0) 
                      + (apps.bas.ui.r.gtelg ? 1 : 0) 
                      + (apps.bas.ui.r.gtexl ? 0 : 0)
                      )">
                    <div class="tr-extended-div">
                  
                    
                      <!-- 
                      TODO: vise mer info her? 

                      <btlib-content-row [cols]="orderRows['item' + data.id]" [grid]="apps.bas.ui.gridMd"></btlib-content-row>
        
                      <div *ngIf="orderRows['item' + data.id + '_inputValues']">
                        <h3>{{ 'bus.productInput' | actrans }}</h3>
                        <btlib-content-row [cols]="orderRows['item' + data.id + '_inputValues']" [grid]="apps.bas.ui.gridMd"></btlib-content-row>
        
                      </div> -->

                    </div>
                    </td>

                  </tr>
                </ng-container>
              </tbody>
            </nz-table>
            
          </div>
     
        </div>

       

        <div id="section-log" *ngIf="isSectionVisible('log')">
          <hr/>


          <h2>{{ 'common.log' | actrans }}</h2>
          <!--  -->
          <div>
            <nz-table 
                #nzTableLog
                [nzData]="edit.obj.logs || []" 
                nzTableLayout="fixed" 
                [nzShowPagination]="true"
                [nzPageSize]="5" 
                nzSize="small"
                class="nz-table-xs"
                >
                <thead>
                  <tr>
                
                    <th *ngIf="apps.bas.ui.r.gtesm"  [nzWidth]="apps.bas.ui.r.gtesm ? '100px' : '80px'">{{ 'common.time' | actrans }}</th>
                    <th *ngIf="apps.bas.ui.r.gtemd" nzWidth="120px">{{ 'common.user' | actrans }}</th>
                    <th *ngIf="apps.bas.ui.r.gtelg" nzWidth="150px">{{ 'common.type' | actrans }}</th>
                    <th *ngIf="apps.bas.ui.r.gtelg" nzWidth="110px">{{ 'common.ipAddress' | actrans }}</th>
                    <th>{{ 'bus.log.text' | actrans }}</th>
                    <th nzWidth="30px"></th>
                    <th nzWidth="30px"></th>
              
                  </tr>
                </thead>
                <tbody>
                  <ng-container  *ngFor="let data of nzTableLog.data; let idx = index">
                    <tr >
                      <td *ngIf="apps.bas.ui.r.gtesm">{{ data.time }}</td>
                      <td *ngIf="apps.bas.ui.r.gtemd">{{ data.creator }}</td>
                      <td *ngIf="apps.bas.ui.r.gtelg">{{ data.mkType }}</td>
                      <td *ngIf="apps.bas.ui.r.gtelg">{{ data.ipAddress }}</td>
                      
                      <td> <div [innerHtml]="data.textOutput | trusthtml"></div> </td>
                      <td>
                        <i nz-icon class="cursor-pointer" nzType="delete" (click)="deleteItem(idx, 'log')" [nzTheme]="'twotone'" [nzTwotoneColor]="'#eb2f96'"></i>
                      </td>
                      <td>
                        <i nz-icon class="cursor-pointer" nzType="edit" (click)="editItem(idx, 'log')" ></i>
                      </td>
                    </tr>
              
                  </ng-container>
                </tbody>
              </nz-table>

              <!-- [nzComponentParams]="{ item: edit.log.obj }" -->
              <nz-modal [nzVisible]="!!edit.log.obj" nzTitle="{{ 'common.log' | actrans | str }}" nzWidth="95vw" [nzContent]="modalContentLog" (nzOnCancel)="edit.log.obj = null" (nzOnOk)="saveItem('log')">
                <ng-template #modalContentLog let-params>
  
    
                  <form nz-form [formGroup]="edit.log.form" class="item-form mt-d" nzLayout="vertical" [nzAutoTips]="apps.bas.fs.autoTips">
  
                    <btlib-form-row [controls]="[ edit.log.controls.time ]" [form]="edit.log.form"  [grid]="apps.bas.ui.gridMd" (change)="onChange($event)"  ></btlib-form-row>  
                    <btlib-form-row [controls]="[ edit.log.controls.text ]" [form]="edit.log.form"  [grid]="apps.bas.ui.gridXl"  (change)="onChange($event)" ></btlib-form-row>  
                    
                  </form>
      
  
                </ng-template>
       
              </nz-modal>

          </div> 

        </div>

 


        <hr/>
        <nz-form-item>
          <nz-form-control class="form-buttons">
            <button nz-button nzType="primary">
              {{ 'app.lib.common.save' | actrans }} 
            </button>

            <span *ngIf="edit.obj.id">
                
 
              <!-- <button nz-button nzType="default" nzDanger class="pull-right"  (click)="$event.preventDefault(); userDeleteUser = edit.obj;">{{ 'web.common.user.deleteUser' | actrans }}</button> -->

            </span>
          </nz-form-control>
        </nz-form-item>

      </form>



      </div>

    </div>


    <!-- [nzComponentParams]="{ item: edit.payment.obj }"  -->
    <nz-modal [nzVisible]="!!edit.payment.obj" nzTitle="{{ 'common.payment' | actrans | str }}" nzWidth="95vw" [nzContent]="modalContentPayment" (nzOnCancel)="edit.payment.obj = null" (nzOnOk)="savePayment()">
      <ng-template #modalContentPayment let-params>
        <form nz-form [formGroup]="edit.payment.form" class="item-form mt-d" nzLayout="vertical" [nzAutoTips]="apps.bas.fs.autoTips">

          <btlib-form-row [controls]="[ edit.payment.controls.time ]" [form]="edit.payment.form"  [grid]="apps.bas.ui.gridMd" (change)="onChange($event)"  ></btlib-form-row>  
          <btlib-form-row [controls]="[ edit.payment.controls.comment ]" [form]="edit.payment.form"  [grid]="apps.bas.ui.gridXl"  (change)="onChange($event)" ></btlib-form-row>  
          
        </form>
      </ng-template>
    </nz-modal>
    
    <!-- [nzComponentParams]="{ item: edit.transferPayment.obj }"  -->
    <nz-modal [nzVisible]="!!edit.transferPayment.obj" nzTitle="{{ 'common.payment' | actrans | str }}" nzWidth="95vw" [nzContent]="modalContentTransferPayment" (nzOnCancel)="edit.transferPayment.obj = null" (nzOnOk)="saveTransferPayment()">
      <ng-template #modalContentTransferPayment let-params>
        <form nz-form [formGroup]="edit.transferPayment.form" class="item-form mt-d" nzLayout="vertical" [nzAutoTips]="apps.bas.fs.autoTips">

          <btlib-form-row [controls]="[ edit.transferPayment.controls.time ]" [form]="edit.transferPayment.form"  [grid]="apps.bas.ui.gridMd" (change)="onChange($event)"  ></btlib-form-row>  
          <btlib-form-row [controls]="[ edit.transferPayment.controls.comment ]" [form]="edit.transferPayment.form"  [grid]="apps.bas.ui.gridXl"  (change)="onChange($event)" ></btlib-form-row>  
          
        </form>
      </ng-template>
    </nz-modal>
    
    <!-- [nzComponentParams]="{ item: edit.giftCard.obj }"  -->
    <nz-modal [nzVisible]="!!edit.giftCard.obj" nzTitle="{{ 'common.giftCard' | actrans | str }}" nzWidth="95vw" [nzContent]="modalContentGiftCard" (nzOnCancel)="edit.giftCard.obj = null" (nzOnOk)="saveGiftCard()">
      <ng-template #modalContentGiftCard let-params>
        <form nz-form [formGroup]="edit.giftCard.form" class="item-form mt-d" nzLayout="vertical" [nzAutoTips]="apps.bas.fs.autoTips">

          <btlib-form-row [controls]="[ edit.giftCard.controls.time ]" [form]="edit.giftCard.form"  [grid]="apps.bas.ui.gridMd" (change)="onChange($event)"  ></btlib-form-row>  
          <btlib-form-row [controls]="[ edit.giftCard.controls.comment ]" [form]="edit.giftCard.form"  [grid]="apps.bas.ui.gridXl"  (change)="onChange($event)" ></btlib-form-row>  
          
        </form>
      </ng-template>
    </nz-modal>
    
    <!-- [nzComponentParams]="{ item: edit.userSearch.obj }" -->
    <nz-modal [nzVisible]="!!edit.userSearch.obj" nzTitle="{{ 'web.common.booking.userSearch' | actrans | str }}" nzWidth="95vw" [nzContent]="modalContentUserSerach"  [nzFooter]="null"  (nzOnCancel)="edit.userSearch.obj = null">
      <ng-template #modalContentUserSerach let-params>

        <form  *ngIf="edit.userSearch.obj"  nz-form  class="item-form mt-d" nzLayout="vertical" [nzAutoTips]="apps.bas.fs.autoTips" (ngSubmit)="onUserSearch()" >

          <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
            <input [(ngModel)]="edit.userSearch.search" [ngModelOptions]="{standalone: true}" type="text" nz-input [placeholder]="'common.search' | actrans" />
          </nz-input-group>
          <ng-template #suffixIconButton>
            <button nz-button nzType="primary" nzSearch ><span nz-icon nzType="search"></span></button>
          </ng-template>
        </form>

        <!-- <form nz-form [formGroup]="edit.userSearch.form" class="item-form mt-d" nzLayout="vertical" [nzAutoTips]="apps.bas.fs.autoTips" (ngSubmit)="userSearchSubmit()"  >

          <btlib-form-row [controls]="[ edit.userSearch.controls.search ]" [form]="edit.payment.form"  [grid]="apps.bas.ui.gridMd" (change)="onChange($event)"  ></btlib-form-row>  
          
        </form>
              [nzShowPagination]="true"
            [nzPageSize]="5" 
      -->

        <div *ngIf="edit.userSearch.users">
          <nz-table 
            #nzTableUserSearch
            [nzData]="edit.userSearch.users || []" 
            nzTableLayout="fixed" 
            nzSize="small"
            class="nz-table-xs"
            >
            <thead>
              <tr>
                <th>{{ 'common.username' | actrans }}</th>
                <th *ngIf="apps.bas.ui.r.gtemd" >{{ 'common.name' | actrans }}</th>
                <th nzWidth="30px"></th>
                <th nzWidth="30px"></th>
              </tr>
            </thead>
            <tbody>
              <ng-container  *ngFor="let data of nzTableUserSearch.data; let idx = index">
         
                <tr >
                  <td *ngIf="apps.bas.ui.r.gtesm">{{ data.username }}</td>
                  <td *ngIf="apps.bas.ui.r.gtemd">
                    {{ data.name }} <br/>
                    {{ data.addressFull }}
                    <div *ngIf="data.tagsAsString">
                      <strong>{{ 'common.tags' | actrans }}</strong>: {{ data.tagsAsString.replace(',', ', ') }}
                    </div>
                  </td>
                  <td>
                    <i nz-icon class="cursor-pointer" nzType="edit" (click)="editUser(data.id); edit.userSearch.obj = null;" ></i>
                  </td>
                  <td>
                    <i nz-icon class="cursor-pointer" nzType="arrow-right" (click)="selectUser(data)"></i>
                  </td>
                 
                </tr>
          
              </ng-container>
            </tbody>
          </nz-table>
        </div>

      </ng-template>
    </nz-modal>

    

    <!-- [nzComponentParams]="{ item: edit.user.obj }" -->
    <nz-modal 
      [nzVisible]="!!edit.user.obj" 
      nzTitle="{{ 'common.user' | actrans | str }}" 
      nzWidth="95vw" 
      [nzContent]="modalContentUser" 
      
      (nzOnCancel)="edit.user.obj = null" 
      (nzOnOk)="edit.user.obj = null">
      <ng-template #modalContentUser let-params>

        <div *ngIf="edit.user.obj">
          <app-admin-user-user-page 
            [inputId]="edit.user.obj.id"
            (userChange)="onUserChange($event)"
            ></app-admin-user-user-page>

        </div>
     

      </ng-template>
      <div *nzModalFooter>

        <button nz-button nzType="default" (click)="edit.user.obj = null">{{ 'common.close' | actrans }}</button>
      
      </div>

    </nz-modal>


  </div>
</nz-spin>

<btlib-sms-modal [input]="smsInput"></btlib-sms-modal>
<btlib-email-modal [input]="emailInput"></btlib-email-modal>
<btlib-send-push-modal [input]="sendPushInput"></btlib-send-push-modal>



</btlib-admin-page-wrapper>

