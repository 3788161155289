
<div *ngIf="pw.id">
  
<div>
    <btlib-order-product-form [pw]="pw" (change)="onChange($event)"></btlib-order-product-form>
</div>

<div>
    <btlib-order-product-cart [pw]="pw"></btlib-order-product-cart>
</div>

<div>
  <button nz-button nzType="primary" (click)="addToCart()">Legg i handlevogn</button>
</div>
</div>

