import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { Injectable, Inject, Injector } from '@angular/core';

import { BooktechAppService } from '../../../services/booktech-app.service';

import { DynformControl } from '../../../model/dymform-control';
import { UntypedFormGroup } from '@angular/forms';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'bt-dynamic-form',
  templateUrl: './bt-dynamic-form.component.html',
  styleUrls: ['./bt-dynamic-form.component.scss'],
})
export class BtDynamicFormComponent implements OnInit {


  @Input() controls: DynformControl[] | null = [ ];
  form!: UntypedFormGroup;
  @Input() options:any = { };

  @Output() submitted = new EventEmitter<any>();

  constructor(public injector:Injector, public apps:AppService) {
   
  }

  ngOnInit() {
    this.form = this.apps.bas.fs.toFormGroup(this.controls as DynformControl[]);
  }

  getControlsList() {
    return Object.values(this.controls as DynformControl[]).sort((a, b) => a.order - b.order);
  }

  onSubmitted() {

    let payLoad = this.form.getRawValue();
    // console.log("onSubmitted, payLoad, type: " + (typeof payLoad) + ", obj: ", payLoad);
    this.submitted.emit(payLoad);
  }

  

}
