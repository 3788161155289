import { Pipe, PipeTransform, Injector } from "@angular/core";
import { Inject } from '@angular/core';
import { SafeHtml } from "@angular/platform-browser";
import { AppService } from "src/app/services/app.service";
import { BooktechAppService } from "../services/booktech-app.service";


/*
*/
@Pipe({name: 'stringify'})
export class StringifyPipe implements PipeTransform {

  constructor(injector:Injector, public apps:AppService) {

    
  }


  transform(input: any, opts:any = { }): string {
    let str = (input ? JSON.stringify(input) : "") + "";

    if (opts.pretty) {
      str = this.apps.bas.us.pretty(str);
    }
    return str;
  }
}
