<bt-page-wrapper>
<div *ngIf="apps.bas.ds.login.success">
  <div *ngIf="apps.bas.aas.isAdminProvider()">
    <btlib-admin-menu></btlib-admin-menu>
  </div>
  <div *ngIf="!apps.bas.aas.isAdminProvider()">
    <div class="m-d">
      <button nz-button nzType="default" nz-dropdown  nzTrigger="click" [nzDropdownMenu]="menuNotAdmin" id="btn-not-admin-menu">
        <i nz-icon [nzType]="'menu'" ></i> <span>{{ 'web.main.menu' | actrans  }}</span>
      </button>
    </div>
  
  
    <nz-dropdown-menu #menuNotAdmin="nzDropdownMenu">

      <ul nz-menu>
        <!-- <li nz-menu-divider></li> -->
        
        <li nz-menu-item (click)="apps.bas.api.logout()">{{ 'app.lib.common.logout' | actrans  }} </li>
        
      </ul>
  
  
    </nz-dropdown-menu>

  </div>
</div>
<div  class="mx-d mt-d" *ngIf="!apps.bas.ds.login.success">
 
  <button *ngIf="!loginVisible" nz-button nzType="default" (click)="showLogin()">
    <i nz-icon nzType="login"></i>
    {{ 'app.lib.common.login' | actrans }}
  </button>

  <div *ngIf="loginVisible" >
    <btlib-login (onLogin)="onLogin($event)"></btlib-login>

  </div>

</div>

    



 <div  *ngIf="loadingVisible" class="loading-wrapper text-center">
  <div  style="margin-top: 35vh;">
    <!-- <nz-spin id="loading-spinner" nzSimple [nzSize]="'large'"></nz-spin> -->
  </div>
  <h1>Cloud Booking API</h1>
</div>

</bt-page-wrapper>

<!-- <button nz-button nzType="default" id="btn-menu-main" (click)="rootDrawerVisible = !rootDrawerVisible;">
  <i nz-icon [nzType]="'menu'" ></i>
</button>
<nz-layout>
  <nz-content id="content-main" style="height: 100%">
    <div [ngSwitch]="mainContent">

      <app-test *ngSwitchCase="'apptest'"></app-test>
      <btlib-test *ngSwitchCase="'libtest'"></btlib-test>
      <btlib-login *ngSwitchCase="'login'"></btlib-login>

      <div *ngSwitchCase="'loading'">
        <div class="loading-wrapper text-center">
          <div  style="margin-top: 40vh;">
            <nz-spin id="loading-spinner" nzSimple [nzSize]="'large'"></nz-spin>
          </div>
        </div>
        
        
      </div>
    </div>
    
    
  </nz-content>
 
</nz-layout>


<nz-drawer
[nzClosable]="true"
[nzVisible]="rootDrawerVisible"
nzPlacement="left"
nzTitle="Hovedmeny"
(nzOnClose)="drawerClose()"
>
<ng-container *nzDrawerContent>
  
  <p>Bla bla bla</p>
  <div>
    <button nz-button nzType="default" (click)="menuClick('login')">login</button>
  </div>
  <div>
    <button nz-button nzType="default" (click)="menuClick('apptest')">apptest</button>
  </div>
  <div>
    <button nz-button nzType="default" (click)="menuClick('libtest')">libtest</button>
  </div>

</ng-container>
</nz-drawer> -->



  <!-- <nz-sider *ngIf="leftSidebarContent != ''" nzTheme="light" [nzWidth]="leftSidebarWidth" [(nzCollapsed)]="isCollapsed" [nzTrigger]="null" [nzCollapsedWidth]="0">
    <nz-layout style="height: 100%;">

      <nz-content>

        <div [ngSwitch]="leftSidebarContent">
          <app-login *ngSwitchCase="'login'"></app-login>
          <div *ngSwitchCase="'test'">

            <h1>TEST LEFT sidebar</h1>
 
            <div>
              <button nz-button nzType="default" (click)="apps.ds.set('UI_MAIN_CONTENT', { id: 'login'})">login</button>
            </div>
            <div>
              <button nz-button nzType="default" (click)="apps.bas.api.refreshHostConfig()">refreshHostConfig </button>
              <button nz-button nzType="default" (click)="apps.bas.api.refreshAppConfig()">refreshAppConfig </button>
            </div>


          </div>
        </div>

        <hr/>
        <div>
          <button nz-button nzType="default" (click)="apps.ds.set('UI_LEFT_SIDEBAR_CONTENT', { id: 'login'})">ls:login</button>
        </div>
        <div>
          <button nz-button nzType="default" (click)="apps.ds.set('UI_LEFT_SIDEBAR_CONTENT', { id: 'test'})">ls:test</button>
        </div>
        <div>
          <button nz-button nzType="default" (click)="apps.ds.set('UI_LEFT_SIDEBAR_CONTENT', { id: ''})">ls:hide</button>
        </div>

      </nz-content>
    
    </nz-layout>

  </nz-sider> -->

