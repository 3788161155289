
import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { MiscUtil } from 'src/app/lib/core/util/misc.util';

import { AppService } from 'src/app/services/app.service';

import { DynformControl, DynformControlsMap } from 'src/app/lib/core/model/dymform-control';


@Component({
  selector: 'app-admin-stats-page',
  templateUrl: './admin-stats-page.component.html',
  styleUrls: ['./admin-stats-page.component.scss']
})
export class AdminStatsPageComponent implements OnInit {

  route: ActivatedRoute;

  fbo:any = {
    loading: true,
    stats: {
      current: [],
      groupByNone: true,
      groupBy2None: true,
      navigatorObj: {
        size: 0,
        pageSize: 10,
        page: 0
      }
    }

  };

  customFilters:any = { }

  df:any = {
    form: undefined,
    controls: [],
    obj: undefined,
    params: { 
      // 
    },
    statsForm: { 
      // statsType: "REPORT", //TODO
      currentJil: "min"
    }
  };
  
  orderInfo:any = { };

  constructor(public injector:Injector, public apps:AppService) {
    this.route = injector.get(ActivatedRoute);
    this.apps.bas.us.checkActivatedRoute(this.route.snapshot);

    if (this.apps.bas.envtest) console.log("AdminStatsPageComponent");


    // this.updateFbo();
    
  }

  // getDownloadUrl(report:any) {
  //   console.log("du: " + report.downloadUrl + ", report: ", report);
  //   return report.downloadUrl;
  //   // this.apps.bas.ui.router.navigate([ 
  //   //     this.apps.bas.ui.getRouterPrefix() 
  //   //     + '/admin/user/user'
  //   //   ], { queryParams: { id: user.id }}
  //   // );
  //   // [routerLink]="[apps.bas.ui.getRouterPrefix(), '/common/user/user', '?id=' + data.id ]
  // }

  onQueryParamsChange(queryParams: NzTableQueryParams): void {
    if (this.apps.bas.envtest) console.log("onQueryParamsChange: ", queryParams);
    const { pageSize, pageIndex, sort, filter } = queryParams;
    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || null;
    const sortOrder = (currentSort && currentSort.value) || null;

    let params:any = { };
    
    let page = pageIndex !== undefined && pageIndex > 0 ? pageIndex -1 : undefined;
    // let nav = this.fbo.stats.navigatorObj;
    // if ((page !== undefined && nav.page != page )) { 
    //   params.navAction = "page";
    //   params.navParam = page;
    // } else if ((pageSize !== undefined && (nav.pageSize != pageSize || !this.fbo.success ))) { 
    //   params.navAction = "pageSize";
    //   params.navParam = pageSize;
    // }

    
    let sfi:any = { }
        
    if (page !== undefined) sfi.navPage = page;
    if (pageSize !== undefined)  sfi.navPageSize = pageSize;


    if (sortField) {
      sfi.customSortsList = [
     
        { field: sortField, asc: sortOrder === "ascend" }
      ];

    }

    if (filter) {
      sfi.customFiltersMap = { };
      for (let item of filter) {
        if (item.value == null || item.value.length == 0) continue;
   
        sfi.customFiltersMap[item.key] = { value: item.value };

      }
    }


    this.updateFbo(params, sfi);
  }

  
  dfSubmit() {
    this.updateFbo({ }, { 
      navPageSize: this.fbo.stats.navigatorObj.pageSize,
      navPage: 0, //this.fbo.stats.navigatorObj.page
    });
  }

  updateFbo(input:any = { }, statsFormInput:any = { }) {
    let params:any = {
      aType: "appAdmin",
      action: "getStatsFbo"
    };

    let rv = { };
    if (this.df.form) {
      rv = this.apps.bas.fs.getRawValue(this.df.form, this.df.controls);
      if (rv === false) return;
  
    }

    statsFormInput.customFiltersMap = statsFormInput.customFiltersMap || { };
    for (let key in this.customFilters) {
      let val = this.customFilters[key];
      if (val == undefined || val == "") continue; 
      statsFormInput.customFiltersMap[key] = { value: val, type: "search" };
    }

    MiscUtil.extend(rv,  statsFormInput);


    MiscUtil.extend(params, input);

    let statsForm = MiscUtil.extend({ }, this.df.statsForm);
    MiscUtil.extend(statsForm, rv);

    MiscUtil.extend(params, this.df.params);
    MiscUtil.extend(params, { statsForm: JSON.stringify(statsForm) } );

    if (this.apps.bas.envtest) console.log("updateFbo.params: ", params);

    this.fbo.loading = true;
    this.apps.bas.ws.json(params ).then((json:any) => {
      if (this.apps.bas.envtest) console.log("updateFbo.then, json: ", json);

      //TODO: må lagre dssse veriene lokalt slik at man ikke må stille inn hver gang siden lastes.

      if (json.success) {
        this.updateForm(json.stats);

        if (json.stats.current) {
          if (!json.stats.groupByNone) {
            //let current:any[]= [];
            let headerList = json.stats.header.values;
            for (let row of (json.stats.current || [])) {

              row.vals = [];
              for (let colIdx in row.values) {
                let header = headerList[colIdx];
                let value = row.values[colIdx];

                if (header.tagExcel) continue; 

                row.vals.push({
                  hw: header,
                  value: value
                });

              }
            }

            let footer = json.stats.footer;
            footer.vals = [];
            for (let colIdx in footer.values) {
              let header = headerList[colIdx];
              let value = footer.values[colIdx];

              if (header.tagExcel) continue; 

              footer.vals.push({
                hw: header,
                value: value
              });

            }

          }
  
        }

        this.fbo = json;
        
      } else {

      }
      

     });
  }

  
  updateForm(stats:any) {

    let controls: DynformControl[] = [];

    // controls.push(new DynformControl({ key: 'statsType', value: "USER" }));
    


    let isAdmin = this.apps.bas.aas.isAdmin();

    let enabledStatsTypes:any = {
      "BOOKING": true,
      // "LOG": true ,
      // "REPORT": true ,
    }
    
    let statsTypes = stats.statsTypes.filter((st:any) => {
      return enabledStatsTypes[st.enumName]; 
    });

    controls.push(new DynformControl({ 
      key: 'statsType',  
      // valuePath: "statsTypeId",
      mk: 'common.type',  
      controlType: 'select', 
      // required: true,
      options: () => {
        return statsTypes; 
      },
      optionsAllowEmpty: false,
      optionsFieldValue: "enumName",
      optionsFieldLabel: "mkName",
      
    }));


    controls.push(new DynformControl({ 
      key: 'period',  
      valuePath: "periodObj.enumName",
      mk: 'common.period',  
      controlType: 'select', 
      // required: true,
      options: () => {
        return stats.periods; 
      },
      // onChange: (event) => {
      //   this.df.period = event.value;
      //   console.log("period.onChange, period: " + this.df.period + ", event: ", event)
      // },
      optionsAllowEmpty: false,
      optionsFieldValue: "enumName",
      optionsFieldLabel: "label",
      
    }));

    controls.push(new DynformControl({ 
      key: 'datePeriodAsRange',  
      mk: 'common.custom', 
      // required: true , 
      controlType: "date-range-picker", 
      show: () => {
        // console.log("datePeriodAsRange.show: " + (this.df.form.controls.period.value));
        return this.df.form.controls.period.value == "CUSTOM";
      },
      data: { 
        readOnly: this.apps.bas.ui.r.xs
       } 
    })); 

    
    let isDev = this.apps.bas.envdev;
    
    controls.push(new DynformControl({ 
      key: 'groupBy',  
      // valuePath: "periodObj.enumName",
      mk: 'web.common.stats.groupVertically',  
      controlType: 'select', 
      // required: true,
      options: () => {
        return stats.groupBys; 
      },
      // show: () => { return isDev;   },
      optionsAllowEmpty: false,
      optionsFieldValue: "enumName",
      optionsFieldLabel: "mkName",
      
    }));


    //

    controls.push(new DynformControl({ 
      key: 'reportType',  
      mk: 'common.type',  
      controlType: 'select', 
      // required: true,
      options: () => {
        return stats.reportTypes; 
      },
      optionsAllowEmpty: true,
    }));

        
    controls.push(new DynformControl({ 
      key: 'provider',  
      mk: 'common.provider',  
      controlType: 'select', 
      // required: true,
      options: () => {
        return stats.providers || [];
      },
      show: () => {
        return isAdmin;
      },
      optionsAllowEmpty: true,
      optionsFieldValue: "id",
      optionsFieldLabel: "label"
    }));

        
    controls.push(new DynformControl({ key: 'search',               mk: 'common.search' }));
    // controls.push(new DynformControl({ key: 'currentJil', value: "max" }));
    
    
    
    controls.push(new DynformControl({ 
      key: 'product',  
      // valuePath: "ruleId",
      mk: 'common.product',  
      controlType: 'select', 
      // required: true,
      options: () => {
        return stats.products || []; 
      },
      optionsAllowEmpty: true,
      optionsFieldValue: "id",
      optionsFieldLabel: "mkName"
    }));

    /*

  <label class="col-sm-4 control-label" for="predef"><nit:message key="web.common.stats.predef"/>:</label>
  <div class="col-sm-8"><nit:select path="fbo.predef" style="" items="${fbo.predefs}" cssClass="form-control " allowEmpty="true" labelProperty="mk" valueProperty="name" /></div>
          

predef: 
statsType: USER
period: ONE_MONTH
fromAsString: 2022-01-21 00:00
toAsString: 2022-02-21 00:00
dateMode: TIME
groupBy: NONE
sort: TIME
sortMode: DESC
sort_2: NONE
sortMode_2: ASC
sort_3: NONE
sortMode_3: ASC
statuss: 0
statuss: 2
sm1: SHOW
provider: 
product: 
productTagsInclude: 
productTagsExclude: 
productSubType: 
viName: 
showSettled: ALL
showDoSettle: ALL
showSaved: YES
bookingTag: 
biTag: 
search: 
username: 
    */


    this.apps.bas.fs.updateFormObj(this.df, controls, stats);


  }


  ngOnInit(): void {
    
  }

}


