import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { MiscUtil } from 'src/app/lib/core/util/misc.util';
import { AppService } from 'src/app/services/app.service';

// import { ImageMapCoordinate } from 'src/app/lib/core/comonent/ui/bt-image-map/bt-image-map.component';

@Component({
  selector: 'app-app-test-test1-page',
  templateUrl: './app-test-test1-page.component.html',
  styleUrls: ['./app-test-test1-page.component.scss']
})
export class AppTestTest1PageComponent implements OnInit {

  itemBg = {
    color: "rgba(255, 0, 0, 0.4)"
  };

  imageMap = {
    // image: "https://image.shutterstock.com/image-vector/solar-system-sun-planets-vector-260nw-751091653.jpg",
    // image: "",
    
    bg: {
      // color: "#ddd",
      // height: "300px"
      img: '/assets/test/img/torvet_kart_2022_rotert.jpg',
      height: '100%',
      // maxHeight: ''
    },

    items: [
      // { name: 'Plass 1', top: 17.5, left: 80,   height: 8,  width: 4.5, bg: this.itemBg },
      // { name: 'Plass 2', top: 17.5, left: 75,   height: 8,  width: 4.5, bg: this.itemBg },
      // { name: 'Plass 13', top: 40.5, left: 80.5,   height: 8,  width: 4.5, rotate: 27, bg: this.itemBg },
    ]

  };

  log:string[] = [];
  
  constructor(public injector:Injector, public apps:AppService) {
    let route = injector.get(ActivatedRoute);
    this.apps.bas.us.checkActivatedRoute(route.snapshot);
  
  }

  ngOnInit(): void {
    
  }

  imageMapClick(event:any) {
    if (this.apps.bas.envtest) console.log("imageMapClick: ", event);

    this.log = [...this.log, "Du trykket på: " + event.name ]
  }

}
