import { Component, Injector, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MiscUtil } from 'src/app/lib/core/util/misc.util';

import { AppService } from 'src/app/services/app.service';

import { DynformControl } from 'src/app/lib/core/model/dymform-control';

@Component({
  selector: 'bt-user-change-password',
  templateUrl: './bt-user-change-password.component.html',
  styleUrls: ['./bt-user-change-password.component.scss']
})
export class BtUserChangePasswordComponent implements OnInit {

  @Input() 
  get user(): any { return this.edit.obj; }
  set user(user: any) {
    if (user == undefined) return;
    // console.log("user.set: ", user);
    this.editObject(user);
  }

  @Output() userChange = new EventEmitter<any>();


  edit:any = {
    visible: false,
    obj: undefined,
   
  }
  
  constructor(public injector:Injector, public apps:AppService) {

    if(this.apps.bas.envtest) console.log("BtUserChangePasswordComponent");

  }

  ngOnInit(): void {

  }

  editObject(obj:any) {

    if(this.apps.bas.envtest) console.log("editObject, obj: ", obj);

    let isAdmin = this.apps.bas.aas.isAdmin();

    let controls: DynformControl[] = [];

    controls.push(new DynformControl({ key: 'id' }));
    controls.push(new DynformControl({ 
      key: 'passwordOld', 
      mk: 'web.common.user.passwordOld', 
      type: "password",
      required: !isAdmin,
      show: () => !isAdmin
    }));
    controls.push(new DynformControl({ 
      key: 'passwordNew', 
      mk: 'web.common.user.passwordNew', 
      type: "password" ,
      required: true
    }));
    controls.push(new DynformControl({ 
      key: 'passwordConfirm', 
      mk: 'web.common.user.passwordConfirm', 
      type: "password",
      required: true
    }));




    this.apps.bas.fs.updateFormObj(this.edit, controls, obj);
    // this.edit.visible = true;
  } 

  onChange(event:any) {

  }

  onCancel() {
    // this.edit.visible = false;
    this.edit.obj = undefined;
    this.userChange.next(this.edit.obj);
  }
  onOk() {

    let rv = this.apps.bas.fs.getRawValue(this.edit.form, this.edit.controls);
    if (rv === false) return;

    if (rv.passwordNew != rv.passwordConfirm) {
      // TODO: https://stackoverflow.com/a/67232471
      
      this.apps.bas.ui.error(this.apps.bas.ui.actrans("web.main.js.jp.register.passwordsDoNotMatch"));
      return;
    }

    
    if (this.apps.bas.aas.isAdmin()) delete rv.passwordOld;

    let obj = this.edit.obj;

    let params:any = {
      aType: "appAdmin", 
      action: "changePassword"
    };
    MiscUtil.extend(params, rv);


    if(this.apps.bas.envtest) console.log("onOk, params: ", params);


    this.apps.bas.ws.json(params).then((json) => {
      
      if (json.success) {
        this.apps.bas.ui.success(json.statusDesc); 

        this.userChange.next(this.edit.obj);
        this.edit.obj = undefined;
      }
    });
    
    // this.edit.visible = false;
  }



}
