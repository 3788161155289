import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bt-nz-form-item',
  templateUrl: './bt-nz-form-item.component.html',
  styleUrls: ['./bt-nz-form-item.component.scss']
})
export class BtNzFormItemComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
