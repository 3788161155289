import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
// import { MbscPopupOptions } from '@mobiscroll/angular-ivy';
// import { MbscPopupOptions, MbscPopup } from '@mobiscroll/angular4';
import { MiscUtil } from 'src/app/lib/core/util/misc.util';

import { AppService, APP_DATAID } from '../../../../../services/app.service';
import { DATAID } from '../../../services/data.service';

@Component({
  selector: 'btlib-order-product-form',
  templateUrl: './order-product-form.component.html',
  styleUrls: ['./order-product-form.component.scss']
})
export class OrderProductFormComponent implements OnInit {

  @Input() pw:any;
  @Output() change = new EventEmitter<any>();

  // @ViewChild('guestsButton', { static: false })
  // guestsButtonRef: ElementRef|undefined;

  // //  display="anchored"  [buttons]="[]" anchor="#guestsButton" 
  // guestsPopupOptions: MbscPopupOptions = {
  //   display: "anchored",
  //   buttons: [],
  // }

  dateInvalid:Date[] = [];
  
  range:any;
  selectingRange:any[] = [];

  size = {
    lbl: {
      xs: 12,
      sm: 6
    },
    ctrl: {
      xs: 12,
      sm: 10
    }

  }

  // dateOnOpen(event:any) {
  //   if(this.apps.bas.envtest) console.log("dateOnOpen, ", event, ", invalid: ", this.dateInvalid);
  //   this.dateUpdateInvalid(event);
  // }
  dateOnPageLoading(event:any) {
    // console.log("dateOnPageLoading, ", event, ", invalid: ", this.dateInvalid);
    this.dateUpdateInvalid(event);
  }

  dateOnChange(event:any) {
    if(this.apps.bas.envtest) console.log("dateOnChange, startDateAsString: " + this.pw.startDateAsString
      + ", startDateAsJsDate: " + this.pw.startDateAsJsDate + ", event: ", event);
    this.pw.startDateAsString = this.apps.bas.ui.dateToString(this.pw.startDateAsJsDate);
    this.change.emit({ field: "startDateAsString"});
  }

  dateUpdateInvalid(event:any) {
    let inst = event.inst;
    let fd = event.firstDay;
    let ld = event.lastDay;


    let invalid = [];
    
    let pw = this.pw;

    let dates = this.apps.bas.ui.getAllDatesBwtweenDates(fd, ld);

    for (let current of dates) {
      let dateInt = MiscUtil.getDateAsInt(current);
      
      if (pw.selectableDatesNext && pw.selectableDatesNext.length) {
        if (MiscUtil.binarySearch(pw.selectableDatesNext, dateInt) < 0) {
          //console.log("current: " + current + " -> dateInt: " + dateInt + ", binarySearch: " + MiscUtil.binarySearch(pw.selectableDatesNext, dateInt) + ", pw: ", pw);

          invalid.push(current);
        }
      } else if (((pw.selectableDates && pw.selectableDates.length) || pw.productObj.timeProduct)) {
        if (MiscUtil.binarySearch(pw.selectableDates, dateInt) < 0)  invalid.push(current);
      } else if (pw.productObj.periodProduct && pw.occupiedDates) {
        if (MiscUtil.binarySearch(pw.occupiedDates, dateInt) >= 0)  invalid.push(current);
      }
    }

    //console.log("Setting dateInvalid: " + this.dateInvalid.length + " -> " + invalid.length);
    this.dateInvalid = invalid;


  }

  constructor(public apps:AppService) {
    
  }

  ngOnInit(): void {
   
  }


  //
  incrementGcValue(gcIdx:number) { this.setGcValue(gcIdx, this.pw.guestCounts.map[gcIdx] + 1); }
  decrementGcValue(gcIdx:number) { this.setGcValue(gcIdx, this.pw.guestCounts.map[gcIdx] - 1); }
  setGcValue(gcIdx:number, value:number) {
    if (value < 0) return;

    let maxGc = this.pw.productObj.maxGcMap[gcIdx];
    if (maxGc === "") maxGc = 100;
    maxGc = Math.min(maxGc, this.pw.maxGuests);


    if (maxGc < value) return;

    this.pw.guestCounts.map[gcIdx] = value;
    MiscUtil.updateGuestCountsFromMap(this.pw.guestCounts);

    //TODO: sjekke total antall mot maxGuests?


  }

  guestsPopupVisibleChange(event:any) {
    if(this.apps.bas.envtest) console.log("guestsPopupVisibleChange, ev: ", event);
    if (event === false) {
      this.change.emit({ field: "guestCounts", value: this.pw.guestCounts });
    
    }
  }

  getGcs() {
    if (!this.pw) return "";
    let gcs = this.pw.guestCounts.guestCountsAsString.split(",").join(" + ");
    return gcs;
  }


  //
  // dateDisabledDate = (current: Date): boolean => {
  //   let dateInt = MiscUtil.getDateAsInt(current);
  //   let pw = this.pw;

  //   if (pw.selectableDatesNext && pw.selectableDatesNext.length) {
  //     if (MiscUtil.binarySearch(pw.selectableDatesNext, dateInt) < 0) return true;
  //   } else if (((pw.selectableDates && pw.selectableDates.length) || pw.productObj.timeProduct)) {
  //     if (MiscUtil.binarySearch(pw.selectableDates, dateInt) < 0) return true;
  //   } else if (pw.productObj.periodProduct && pw.occupiedDates) {
  //     if (MiscUtil.binarySearch(pw.occupiedDates, dateInt) >= 0) return true;
  //   }

  //   return false;
  // }

  // startDateModelchange(event:any) {
  //   if(this.apps.bas.envtest) console.log("startDateModelchange, ev: ", event + "; pw: ", this.pw);
  //   this.pw.startDateAsString = this.apps.bas.ui.dateToString(this.pw.startDateAsJsDate);
  //   this.change.emit({ field: "startDateAsString"});

  // }


  // 
  rangeModelchange(event:any) {
    if(this.apps.bas.envtest) console.log("rangeModelchange, ev: ", event);
    this.selectingRange = [];
  }

  rangeCalendarChange(event:any) {
    //console.log("calChange, ev: ", event);

    let dates: Date[] = event;
    if(this.apps.bas.envtest) console.log("rangeCalendarChange, dates: ", dates);

  
    if (dates.length == 2) {
      this.selectingRange = [];
    } else {
      this.selectingRange = dates;
    }


  }

  rangleDisabledDate = (current: Date): boolean => {
    if (this.selectingRange.length == 2) {
      return false;
    }

    if (current.getTime() < new Date().getTime()) {
      return true;
    }

    //console.log("day: " + current.getDay() + "; date: " + current.getDate());

    if (this.selectingRange.length == 1) {
      var firstDate = this.selectingRange[0];
      if (firstDate.getTime() == current.getTime()) return false;
      if (current.getDate() >= 18 && current.getDate() <= 19) return true;

    } else {
      if (current.getDate() >= 17 && current.getDate() <= 18) return true;

    }

   
    return false;
  }

}
