import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MiscUtil } from 'src/app/lib/core/util/misc.util';

import { AppService, APP_DATAID } from '../../../../../services/app.service';
import { BtContent } from '../../../model/bt-content';
import { DATAID } from '../../../services/data.service';

@Component({
  selector: 'btlib-order-info-modal',
  templateUrl: './order-info-modal.component.html',
  styleUrls: ['./order-info-modal.component.scss']
})
export class OrderInfoModalComponent implements OnInit {

  // order:any = { }
  private _order:any = { };

  @Output() change = new EventEmitter<any>();


  @Input() 
  get order(): any { return this._order; }
  set order(order: any) {
    
    if (!order.id || order.time) {
      
      this.updateOrderRows(order);
      this._order = order;
      return;
    }
    // { actionType: "appService", action: "getOrders", userOrders: true }
    this.apps.bas.ws.json( {
      aType: "appService",
      action: "getOrder",
      id: order.id,
      biid: order.biid || "",
      jil: "all"
     } ).then((json:any) => {
      if(this.apps.bas.envtest) console.log("getOrder.then, json: ", json);

      this.updateOrderRows( json.order);
      this._order = json.order || { };
     

     });


  }


  orderRows:{[index: string]:BtContent[]} = { 
    s1: []
  };
  
  
  smsInput:any = { };
  emailInput:any = { };

  edit:any = {
  
  }

  constructor(public apps:AppService) {
  
    
  }

  ngOnInit(): void {

  }

  sendSms() {
    this.smsInput = {
      orders: [ this._order ]
    };
  }
  sendEmail() {
    this.emailInput = {
      orders: [ this._order ]
    };
  }


  closeOrderModal() {
    this.order = { };
  }


  deleteOrder() {
    let o = this.order;
    if (!(o && o.status && o.status.isAvailability)) {
      return;
    }

    this.apps.bas.ws.json( {
      aType: "appAdmin",
      action: "deleteOrder",
      id: o.id,
     } ).then((json:any) => {
      if(this.apps.bas.envtest) console.log("deleteOrder.then, json: ", json);
      this.order = { };

      this.change.next({ type: "delete", order: o });

     });

  }

  saveNotes() {
    this.apps.bas.ws.json( {
        aType: "appAdmin",
        action: "saveOrderData",
        id: this.order.id,
        order: JSON.stringify({
            notes: this.order.notes
        })
       } ).then((json:any) => {
        if(this.apps.bas.envtest) console.log("saveNotes.then, json: ", json);
        //TODO: 
        this.apps.bas.ui.success("Lagret!");

        this.change.next({ type: "saveOrderData", order: this.order });
  
       });
  }


  updateOrderRows(o:any) {

    if (!o || !o.time) {
      this.orderRows = { };
      return; 
    }



    // let orderSum = 0;
    
    for (let item of o.items || []) {
      // orderSum += item.amountDouble; 

      let itemObj = {
        "product": "common.product",
        "periodString": "common.period", 
        "updated": "common.updated",
        "mkStatus": { label: "common.status", css: 'bt-status ' + item.statusCssClass },
        "guests": "bus.booking.guests",
        "count": "common.count",
        "amountDouble": { label: "common.amount", type: "double" },
        // "": "common.",

      };

      this.orderRows["item" + item.id] = this.apps.bas.ui.toContentList(item, itemObj);
      
      if (item.inputValuesDisplay) {
        this.orderRows["item" + item.id + "_inputValues"] = this.apps.bas.ui.toContentListObject(item.inputValuesDisplay);
      

        
      }

      // o.orderSum = orderSum;

      // o.orderSum = orderSum;
      this.orderRows['s1'] = this.apps.bas.ui.toContentList(o, {
        // "bid": "common.bid",
        "time": "common.created", 
        "updated": "common.updated",
        "createdBy": "bus.booking.createdBy",
        // "user": "common.customer",
        "amountDouble": { label: "common.amount", type: "double" },
      });


    //   let user = o.userObj;
        this.orderRows['customer'] = this.apps.bas.ui.toContentList(o.userObj, {
        "label": "common.name",
        "emailAddress": { label: "common.email", type: "email" }, 
        "telephone": { label: "common.telephone", type: "telephone" }, 
        // "createdBy": "bus.booking.createdBy",
        // "user": "common.customer",
        // "amountDouble": { label: "common.amount", type: "double" },
      });
      

    //   this.orderRows.s1.push(new BtContent({
    //       label: "common.customer",
    //       value: user.label 
    //         + '<br/>' + user.emailAddress 
    //         + '<br/>' + user.telephone 
    //   }))

      this.orderRows['s2'] = this.apps.bas.ui.toContentList(o, {
        "comment": "common.comment",
    //     "notes": "common.notes", 
      });

      /*
            "providerAmount": 750000,
            "booking": "ADF773",
            "hasTagPCRExpired": false,
            "endDate": 20220101,
            "endAsString": "2022-01-01",
            "isAvailability": false,
            "isReservation": false,
            "startTimeAsString": "16:00",
            "manAmountDouble": 8000,
            "jsonDataString": "",
            "retailAmountDouble": "",
            "payoutPendingCompany": "",
            "companyVat": 5357,
            "inputValuesMap": {},
            "price": "",
            "additionalInfo": "",
            "isDefinitive": false,
            "isLocalProduct": true,
            "id": 123556,
            "order": 0,
            "startAsIso8601": "2021-11-01T16:00:00.000+01:00",
            "vatShortName": "L",
            "productId": 2142,
            "isWaitingList": false,
            "settled": "",
            "count": 1,
            "index": 0,
            "eventTickets": [],
            "completed": "",
            "calcProviderAmountDouble": 7500,
            "startDateAsString": "2021-11-01",
            "cartItemInputValues": "",
            "periodString": "01.11.21 - 01.01.22",
            "vatInfoItemCompanyObj": {
                "id": 14
            },
            "updated": "24.09.21 14:21",
            "startDate": 20211101,
            "status": 4,
            "isLongTerm": true,
            "cartItemInputValuesAll": "",
            "calcProviderAmount": 750000,
            "eosId": "",
            "payoutPendingProvider": "",
        
            "hasTagPCRConfirmed": false,
            "endDateAsString": "2022-01-01",
            "canChange": true,
            "manAmount": 800000,
            "gc1": 0,
            "gc0": 1,
            "credits": "",
            "gc3": 0,
            "vatDouble": 857.14,
            "gc2": 0,
            "statusName": "LongTerm",
            "gc4": 0,
            "inputValuesDisplay": {},
            "inputValuesList": [],
            "end": "01.01.22",
            "startTime": 960,
            "mkTags": "",
            "hasTagPCRAny": false,
            "endFullAsString": "2022-01-01 12:00",
            "campaignCode": "-",
            "inputValues": {},
            "startFullAsString": "2021-11-01 16:00",
            "isProvider": false,
            "vatInfoItemCompanyId": 14,
            "creditsId": "",
            "product": "Pøbbens leilighet",
            "isCancelled": false,
            "quantity": 1,
            "invoiceText": "",
            "eosData": "",
            "start": "01.11.21",
            "startAsString": "2021-11-01",
            "label": "Pøbben - Leilighet: 01.11.21 16:00-01.01.22 12:00",
            "bookingId": 83773,
            "endTimeAsString": "12:00",
            "providerAmountDouble": 7500,
            "depth": 0,
            "amountDouble": 8000,
            "hasTagPCR": false,
            "guests": "1",
            "vatInfoItemCompany": 14,
            "hasTagPCRDenied": false,
            "endAsIso8601": "2022-01-01T12:00:00.000+01:00",
            "endTime": 720,
            "bid": "ADF773",
            "priceId": "",
            "commissionAmountDouble": 500,
            "statusCssClass": "status-long-term",
            "vatInfoItemCompanyLabel": "id: 14, vi.id: 3, sd: 20211001, ed: 20600101, vat: 12",
            "mkStatus": "Langtidsleie",
            "payoutConfirmedProvider": "",
            "payoutConfirmedCompany": ""

      */

    }

      /*
{
   "id": 83773,
    "hasTagProviderInvoiceOrder": false,
    "lastSentProviderMail": "",
    "referer": "",
    "parent": "",
    "notes": "Booking created from other booking: ADE504 Booking created from other booking: ADF772",
    "canCreditCc": false,
    "saved": true,
    "posDevice": "",
    "doGenerateInvoice": true,
    "refererUserLabel": "",
    "doSettle": true,
    "source": "",
    "isPmCreditCard": false,
    "tagsCustomAsString": "",
    "jsonDataString": "",
    "dueDateFinalPayment": "",
    "paidAmountDouble": 0,
    "ref": "ADF772",
    "dueDateAdvancePaymentAsString": "",
    "isPayoutAny": false,
    "isSomeSettled": false,
    "dueDateFpAsDate": "01.01.22",
    "purpose": 0,
    "generateInvoice": "Ja",
    "hasApInvoice": false,
    "userInvoiceNote": "",
    "posDeviceLabel": "",
    "settleDate": "",
    "advancePaymentAmount": "",
    "userEmailAdress": "terje@vikteam.dk",
    "doCancelUnpaid": false,
    "remainingAmount": 0,
    "doSendProviderMail": true,
    "isAllSettled": false,
    "dueDateApAsDate": "01.01.22",
    "company": "Hus- & Hjerterom",
    "settleDateAsString": "",
    "currency": "kr",
    "refererUserObj": "",
    "generateInvoiceBool": true,
    "advancePaymentAmountAsDouble": "",
    "viewParams": "",
    "mkTags": "",
    "hasEosProducts": false,
    "dueDateAdvancePayment": "",
    "label": "ADF773 (83773)",
    "invoiceDate": "",
    "userId": 43489,
    "parentId": "",
    "remainingAmountDouble": 0,
    "isPayoutAll": false,
    "mkPaymentMethod": "Faktura",
    "createdBy": "Anne Marie Kollhus",
    "mkPurpose": "Fritid",
    "amountDouble": 0,
    "comment": "",
    "isPmInvoice": true,
    "time": "24.09.21 14:20",
    "refId": 83772,
    "bid": "ADF773",
    "user": "Vikteam, Danmark",
    "invoiceDateAsString": "",
    "mkStatus": "Langtidsleie",
    "currencyCode": "NOK",
    "poEmail": ""

    "createdById": 29193,
    "posDeviceId": "",
    "posDeviceObj": "",
    "dueDateFinalPaymentAsString": "",
    "refererUser": "",
    "invoiceNote": "",
    "posTransNumber": "",
 
    "paymentUrl": "https://test.cloud-booking.net/husoghjerterom/tools.htm?action=payment&id=83773&lang=no",
    "companyId": 28187,
    "includeEula": true,
    "isCreditOrder": false,
    "paymentMethod": 1,
    "orderReference": "",
    "doSendInvoice": true,
    "updated": "24.09.21 14:21",
    "doGenerateInvoiceNotice": true,
    "paidAmount": 0,
    "refererUserId": "",


    
    "userObj": {
        "zipCode": "",
        "country": "Norge",
        "roleEnum": "User",
        "role": 0,
        "city": "",
        "language": "Norsk",
        "confirmed": true,
        "enabled": true,
        "acceptInfoEmail": false,
        "emailAddress": "terje@vikteam.dk",
        "customerType": 1,
        "displayUsername": "terje@vikteam.dk",
        "countryCode": "NO",
        "company": "Hus- & Hjerterom",
        "id": 43489,
        "email": "",
        "mkCustomerType": "Bedrift",
        "address3": "",
        "address2": "",
        "address1": "Terje Pettersen",
        "active": false,
        "timeZone": "Europe/Oslo",
        "telephone": "+4522563900",
        "label": "Vikteam, Danmark",
        "isNew": false,
        "languageCode": "no",
        "name": "Vikteam, Danmark",
        "time": "24.08.21 15:27",
        "isUser": true,
        "updated": "24.08.21 15:27",
        "mkRole": "Kunde",
        "username": "terje@vikteam.dk"
    },

    "logs": [
        {
            "creator": "Anne Marie Kollhus",
            "booking": "ADF773",
            "product": "",
            "productId": "",
            "creatorId": 29193,
            "ipAddress": "79.161.105.74",
            "type": 7,
            "userId": "",
            "bookingId": 83773,
            "textOutput": "Saving booking: id: 83773, bid: ADF773 (husoghjerterom): <br/>",
            "mkType": "Booking",
            "html": true,
            "id": 548877,
            "time": "24.09.21 14:22",
            "text": "Saving booking: id: 83773, bid: ADF773 (husoghjerterom): <br/>",
            "creatorObj": {
                "zipCode": "",
                "country": "Norge",
                "roleEnum": "Admin",
                "uiLabel": "",
                "hasApiBaseUrl": false,
                "notes": "",
                "isAdminSu": true,
                "language": "Norsk",
                "hasStripeCustomerId": false,
                "acceptInfoEmail": false,
                "emailAddress": "huldra@outlook.com",
                "customerType": 1,
                "isAgent": false,
                "ui": "",
                "id": 29193,
                "isSu": false,
                "invoiceNote": "",
                "webAddress": "",
                "isBn": false,
                "active": false,
                "timeZone": "Europe/Oslo",
                "telephone": "",
                "isAdmin": true,
                "isUsernameEmail": false,
                "languageCode": "no",
                "isTranslator": false,
                "customUserRoles": [],
                "name": "Anne Marie Kollhus",
                "isUser": false,
                "stripeCustomerId": "",
                "isPersonalSharedUser": false,
                "updated": "29.01.21 09:15",
                "mkRole": "Admin",
                "customUserType": "",
                "addressFull": "",
                "role": 1,
                "city": "",
                "displayName": "",
                "acceptInfoSms": false,
                "confirmed": true,
                "enabled": true,
                "displayUsername": "annemarie",
                "countryCode": "NO",
                "tagsAsString": "",
                "customUserRolesAsString": "",
                "company": "Hus- & Hjerterom",
                "email": "huldra@outlook.com",
                "mkCustomerType": "Bedrift",
                "isProvider": false,
                "apiBaseUrl": "/husoghjerterom/index.htm",
                "uiObj": "",
                "address3": "",
                "address2": "",
                "address1": "",
                "label": "Anne Marie Kollhus",
                "isNew": false,
                "isCompany": false,
                "sharedUser": false,
                "uiId": "",
                "time": "29.01.21 09:09",
                "username": "annemarie"
            },
            "user": ""
        },
      
    ],

    "items": [
        {
            "providerAmount": 750000,
            "booking": "ADF773",
            "hasTagPCRExpired": false,
            "endDate": 20220101,
            "endAsString": "2022-01-01",
            "isAvailability": false,
            "isReservation": false,
            "startTimeAsString": "16:00",
            "manAmountDouble": 8000,
            "jsonDataString": "",
            "retailAmountDouble": "",
            "payoutPendingCompany": "",
            "companyVat": 5357,
            "inputValuesMap": {},
            "price": "",
            "additionalInfo": "",
            "isDefinitive": false,
            "isLocalProduct": true,
            "id": 123556,
            "order": 0,
            "startAsIso8601": "2021-11-01T16:00:00.000+01:00",
            "vatShortName": "L",
            "productId": 2142,
            "isWaitingList": false,
            "settled": "",
            "count": 1,
            "index": 0,
            "eventTickets": [],
            "completed": "",
            "calcProviderAmountDouble": 7500,
            "startDateAsString": "2021-11-01",
            "cartItemInputValues": "",
            "periodString": "01.11.21 - 01.01.22",
            "vatInfoItemCompanyObj": {
                "id": 14
            },
            "updated": "24.09.21 14:21",
            "startDate": 20211101,
            "status": 4,
            "isLongTerm": true,
            "cartItemInputValuesAll": "",
            "calcProviderAmount": 750000,
            "eosId": "",
            "payoutPendingProvider": "",
        
            "hasTagPCRConfirmed": false,
            "endDateAsString": "2022-01-01",
            "canChange": true,
            "manAmount": 800000,
            "gc1": 0,
            "gc0": 1,
            "credits": "",
            "gc3": 0,
            "vatDouble": 857.14,
            "gc2": 0,
            "statusName": "LongTerm",
            "gc4": 0,
            "inputValuesDisplay": {},
            "inputValuesList": [],
            "end": "01.01.22",
            "startTime": 960,
            "mkTags": "",
            "hasTagPCRAny": false,
            "endFullAsString": "2022-01-01 12:00",
            "campaignCode": "-",
            "inputValues": {},
            "startFullAsString": "2021-11-01 16:00",
            "isProvider": false,
            "vatInfoItemCompanyId": 14,
            "creditsId": "",
            "product": "Pøbbens leilighet",
            "isCancelled": false,
            "quantity": 1,
            "invoiceText": "",
            "eosData": "",
            "start": "01.11.21",
            "startAsString": "2021-11-01",
            "label": "Pøbben - Leilighet: 01.11.21 16:00-01.01.22 12:00",
            "bookingId": 83773,
            "endTimeAsString": "12:00",
            "providerAmountDouble": 7500,
            "depth": 0,
            "amountDouble": 8000,
            "hasTagPCR": false,
            "guests": "1",
            "vatInfoItemCompany": 14,
            "hasTagPCRDenied": false,
            "endAsIso8601": "2022-01-01T12:00:00.000+01:00",
            "endTime": 720,
            "bid": "ADF773",
            "priceId": "",
            "commissionAmountDouble": 500,
            "statusCssClass": "status-long-term",
            "vatInfoItemCompanyLabel": "id: 14, vi.id: 3, sd: 20211001, ed: 20600101, vat: 12",
            "mkStatus": "Langtidsleie",
            "payoutConfirmedProvider": "",
            "payoutConfirmedCompany": ""



            -----------------------------



            "productObj": {
                "parent": "",
                "requireAdditionalInfo": false,
                "apInclude": true,
                "userAddressRequired": false,
                "tcNotNone": true,
                "requiredCleaning": {
                    "parent": "Pøbbens leilighet",
                    "isPool": false,
                    "endDateAsDate": "01.01.60",
                    "orderEndDate": -1,
                    "endDate": 20600101,
                    "orderStartTimeAsString": "",
                    "bookDeparture": false,
                    "type": 0,
                    "mkOrderStartDateAbbr": "Ankomstdato",
                    "startDateAsDate": "01.01.10",
                    "mkOrderEndDate": "Samme dag som avreisedato",
                    "endDateAsString": "2060-01-01",
                    "orderEndTimeAsString": "",
                    "bookStaying": true,
                    "orderStartDate": 0,
                    "web": true,
                    "price": "Pøbben",
                    "childName": "Inkludert sluttvask",
                    "orderEndTime": "",
                    "id": 2778,
                    "order": 0,
                    "bookAlways": true,
                    "isAccessory": true,
                    "unlimited": false,
                    "copyGuestCounts": true,
                    "mkOrderEndDateAbbr": "Avreisedato",
                    "isPackage": false,
                    "childId": 2154,
                    "startDateAsString": "2010-01-01",
                    "isRelation": false,
                    "parentId": 2142,
                    "mkOrderStartDate": "Samme dag som ankomstdato",
                    "checkCircularDependency": true,
                    "typeAsString": "Accessory",
                    "bookArrival": true,
                    "mkType": "Tilbehør",
                    "priceObj": {
                        "simpleAmountDouble": 450,
                        "hasOrderPeriod": false,
                        "parent": "",
                        "endDateAsDate": "01.01.60",
                        "parentLabel": "",
                        "endDate": 20600101,
                        "endAsString": "",
                        "isManualPeriod": false,
                        "isDiscount": false,
                        "parentObj": "",
                        "type": 0,
                        "orderPeriod": {
                            "containsNow": true,
                            "endDateAsString": "2060-01-01",
                            "t": "01.01.60 00:00",
                            "endDate": 20600101,
                            "f": "01.01.10 00:00",
                            "label": "01.01.10 00:00 - 01.01.60 00:00",
                            "endAsLong": 2840137200000,
                            "startDate": 20100101,
                            "startAsLong": 1262300400000
                        },
                        "orderEndAsString": "",
                        "ruleObj": {
                            "mkName": "Fast pris",
                            "id": 0,
                            "mkDesc": "Prisen vil alltid være lik uavhengig av antall produkter, antall netter/dager og antall gjester. "
                        },
                        "price": "",
                        "useAmountGroups": false,
                        "isSimple": true,
                        "id": 3013,
                        "ruleId": 0,
                        "group": 0,
                        "order": 0,
                        "amountGroupOverride": {},
                        "productId": 2154,
                        "newItem": {
                            "providerAmount": "",
                            "maxTime": "",
                            "weekdaysShortAsString": "Alle",
                            "endDateAsDate": "01.01.60",
                            "endDate": 20600101,
                            "totalGuestsRangeDisplay": "",
                            "timeDurationRangeDisplay": "",
                            "guestCategory": "",
                            "datePeriodAsRange": "2022-01-01_2060-01-01",
                            "weekdaysList": [
                                {
                                    "mkWeekday": "Mandag",
                                    "mkWeekday1": "M",
                                    "weekday": 2,
                                    "enabled": false,
                                    "mkWeekday3": "Man"
                                },
                                {
                                    "mkWeekday": "Tirsdag",
                                    "mkWeekday1": "T",
                                    "weekday": 3,
                                    "enabled": false,
                                    "mkWeekday3": "Tir"
                                },
                                {
                                    "mkWeekday": "Onsdag",
                                    "mkWeekday1": "O",
                                    "weekday": 4,
                                    "enabled": false,
                                    "mkWeekday3": "Ons"
                                },
                                {
                                    "mkWeekday": "Torsdag",
                                    "mkWeekday1": "T",
                                    "weekday": 5,
                                    "enabled": false,
                                    "mkWeekday3": "Tor"
                                },
                                {
                                    "mkWeekday": "Fredag",
                                    "mkWeekday1": "F",
                                    "weekday": 6,
                                    "enabled": false,
                                    "mkWeekday3": "Fre"
                                },
                                {
                                    "mkWeekday": "Lørdag",
                                    "mkWeekday1": "L",
                                    "weekday": 7,
                                    "enabled": false,
                                    "mkWeekday3": "Lør"
                                },
                                {
                                    "mkWeekday": "Søndag",
                                    "mkWeekday1": "S",
                                    "weekday": 1,
                                    "enabled": false,
                                    "mkWeekday3": "Søn"
                                }
                            ],
                            "startTimeAsString": "",
                            "maxDays": "",
                            "daysRangeDisplay": "",
                            "guestsGcAsRange4": "_",
                            "id": "",
                            "maxGcs": {},
                            "order": 1,
                            "minGcs": {},
                            "timeDurationAsRange": "_",
                            "timeRangeDisplay": "",
                            "guestsGcRangeDisplay2": "",
                            "guestsGcRangeDisplay3": "",
                            "startDay": "",
                            "guestsGcRangeDisplay0": "",
                            "guestsGcRangeDisplay1": "",
                            "guestsGcAsRange1": "_",
                            "guestsGcAsRange0": "_",
                            "guestsGcAsRange3": "_",
                            "guestsGcRangeDisplay4": "",
                            "minDays": "",
                            "weekdaysMap": {
                                "1": true,
                                "2": true,
                                "3": true,
                                "4": true,
                                "5": true,
                                "6": true,
                                "7": true
                            },
                            "guestsGcAsRange2": "_",
                            "startDateAsString": "2022-01-01",
                            "mkStartDay": "Alle",
                            "maxGuests": "",
                            "name": "",
                            "datePeriodDisplay": "2022-01-01 - 2060-01-01",
                            "minGuests": "",
                            "weekdaysAsString": "Alle",
                            "startDate": 20220101,
                            "mkGuestCategory": "",
                            "hasWeekdays": false,
                            "providerPercentDouble": "",
                            "rule": "",
                            "startDateAsDate": "01.01.22",
                            "endDateAsString": "2060-01-01",
                            "minTime": "",
                            "daysAsRange": "_",
                            "weekday5": false,
                            "weekday4": false,
                            "weekday7": false,
                            "isWeekdaysAll": false,
                            "weekday6": false,
                            "weekday1": false,
                            "weekday3": false,
                            "weekday2": false,
                            "providerPercent": "",
                            "endTimeAsString": "",
                            "providerAmountDouble": "",
                            "amountDouble": "",
                            "totalGuestsAsRange": "_",
                            "priceId": 3013
                        },
                        "startDay": "",
                        "isManual": false,
                        "newManPeriodItem": {
                            "weekdaysShortAsString": "Alle",
                            "endDateAsDate": "",
                            "week": "",
                            "extraDay": "",
                            "endDate": "",
                            "friToSun": "",
                            "monToFri": "",
                            "datePeriodAsRange": "_",
                            "weekdaysList": [
                                {
                                    "mkWeekday": "Mandag",
                                    "mkWeekday1": "M",
                                    "weekday": 2,
                                    "enabled": false,
                                    "mkWeekday3": "Man"
                                },
                                {
                                    "mkWeekday": "Tirsdag",
                                    "mkWeekday1": "T",
                                    "weekday": 3,
                                    "enabled": false,
                                    "mkWeekday3": "Tir"
                                },
                                {
                                    "mkWeekday": "Onsdag",
                                    "mkWeekday1": "O",
                                    "weekday": 4,
                                    "enabled": false,
                                    "mkWeekday3": "Ons"
                                },
                                {
                                    "mkWeekday": "Torsdag",
                                    "mkWeekday1": "T",
                                    "weekday": 5,
                                    "enabled": false,
                                    "mkWeekday3": "Tor"
                                },
                                {
                                    "mkWeekday": "Fredag",
                                    "mkWeekday1": "F",
                                    "weekday": 6,
                                    "enabled": false,
                                    "mkWeekday3": "Fre"
                                },
                                {
                                    "mkWeekday": "Lørdag",
                                    "mkWeekday1": "L",
                                    "weekday": 7,
                                    "enabled": false,
                                    "mkWeekday3": "Lør"
                                },
                                {
                                    "mkWeekday": "Søndag",
                                    "mkWeekday1": "S",
                                    "weekday": 1,
                                    "enabled": false,
                                    "mkWeekday3": "Søn"
                                }
                            ],
                            "startDateAsDate": "",
                            "endDateAsString": "",
                            "maxDays": "",
                            "periodDouble": "",
                            "daysRangeDisplay": "",
                            "daysAsRange": "_",
                            "thuToSun": "",
                            "monToFriDouble": "",
                            "id": "",
                            "day": "",
                            "extraDayDouble": "",
                            "order": 1,
                            "weekday5": false,
                            "weekday4": false,
                            "period": "",
                            "weekday7": false,
                            "isWeekdaysAll": true,
                            "weekday6": false,
                            "weekday1": false,
                            "weekday3": false,
                            "weekdays": "",
                            "weekday2": false,
                            "weekdaysMap": {
                                "1": true,
                                "2": true,
                                "3": true,
                                "4": true,
                                "5": true,
                                "6": true,
                                "7": true
                            },
                            "minDays": "",
                            "startDateAsString": "",
                            "thuToSunDouble": "",
                            "weekDouble": "",
                            "dayDouble": "",
                            "name": "",
                            "datePeriodDisplay": " - ",
                            "weekdaysAsString": "Alle",
                            "priceId": 3013,
                            "startDate": "",
                            "friToSunDouble": ""
                        },
                        "isAdmin": false,
                        "startDateAsString": "2010-01-01",
                        "mkStartDay": "Alle",
                        "isWeb": true,
                        "name": "Pøbben",
                        "discountAmountDouble": "",
                        "mkGroup": "Web",
                        "isUpgrade": false,
                        "orderEnd": "",
                        "updated": "27.05.21 16:52",
                        "startDate": 20100101,
                        "isLongTerm": false,
                        "rule": "Fast pris",
                        "amountGroups": {},
                        "enabled": true,
                        "startDateAsDate": "01.01.10",
                        "endDateAsString": "2060-01-01",
                        "customMaxDouble": "",
                        "customDefaultDouble": "",
                        "orderStartAsString": "",
                        "orderStart": "",
                        "end": "",
                        "campaignCode": "",
                        "isProfile": false,
                        "product": "Inkludert sluttvask",
                        "discountPercent": "",
                        "isOffer": false,
                        "start": "",
                        "startAsString": "",
                        "customMinDouble": "",
                        "isPackage": false,
                        "newDailyRatesItem": {
                            "weekdaysShortAsString": "Alle",
                            "endDateAsDate": "31.01.22",
                            "endDate": 20220131,
                            "mkGuestCategory": "",
                            "amountGroup": "",
                            "hasWeekdays": false,
                            "totalGuestsRangeDisplay": "",
                            "guestCategory": "",
                            "rule": "",
                            "datePeriodAsRange": "2022-01-01_2022-01-31",
                            "weekdaysList": [
                                {
                                    "mkWeekday": "Mandag",
                                    "mkWeekday1": "M",
                                    "weekday": 2,
                                    "enabled": false,
                                    "mkWeekday3": "Man"
                                },
                                {
                                    "mkWeekday": "Tirsdag",
                                    "mkWeekday1": "T",
                                    "weekday": 3,
                                    "enabled": false,
                                    "mkWeekday3": "Tir"
                                },
                                {
                                    "mkWeekday": "Onsdag",
                                    "mkWeekday1": "O",
                                    "weekday": 4,
                                    "enabled": false,
                                    "mkWeekday3": "Ons"
                                },
                                {
                                    "mkWeekday": "Torsdag",
                                    "mkWeekday1": "T",
                                    "weekday": 5,
                                    "enabled": false,
                                    "mkWeekday3": "Tor"
                                },
                                {
                                    "mkWeekday": "Fredag",
                                    "mkWeekday1": "F",
                                    "weekday": 6,
                                    "enabled": false,
                                    "mkWeekday3": "Fre"
                                },
                                {
                                    "mkWeekday": "Lørdag",
                                    "mkWeekday1": "L",
                                    "weekday": 7,
                                    "enabled": false,
                                    "mkWeekday3": "Lør"
                                },
                                {
                                    "mkWeekday": "Søndag",
                                    "mkWeekday1": "S",
                                    "weekday": 1,
                                    "enabled": false,
                                    "mkWeekday3": "Søn"
                                }
                            ],
                            "startDateAsDate": "01.01.22",
                            "endDateAsString": "2022-01-31",
                            "maxDays": "",
                            "daysRangeDisplay": "",
                            "price": 3013,
                            "daysAsRange": "_",
                            "id": "",
                            "priceRuleLabel": "",
                            "priceLabel": "prod: Inkludert sluttvask (husoghjerterom), name: Pøbben, type: 0, group: 0, period: 20100101-20600101, baseAmount: 45000; cc: ",
                            "weekday5": false,
                            "weekday4": false,
                            "amount": "",
                            "weekday7": false,
                            "isWeekdaysAll": true,
                            "weekday6": false,
                            "weekday1": false,
                            "weekdays": "",
                            "weekday3": false,
                            "weekday2": false,
                            "minDays": "",
                            "weekdaysMap": {
                                "1": true,
                                "2": true,
                                "3": true,
                                "4": true,
                                "5": true,
                                "6": true,
                                "7": true
                            },
                            "startDateAsString": "2022-01-01",
                            "priceRuleId": "",
                            "priceRule": "",
                            "maxGuests": "",
                            "sortOrder": 1,
                            "totalGuestsAsRange": "_",
                            "name": "",
                            "datePeriodDisplay": "2022-01-01 - 2022-01-31",
                            "minGuests": "",
                            "weekdaysAsString": "Alle",
                            "mkAmountGroup": "",
                            "time": "01.01.22 14:16",
                            "priceId": 3013,
                            "updated": "01.01.22 14:16",
                            "startDate": 20220101,
                            "amountAsDouble": ""
                        },
                        "label": "Pøbben",
                        "providerPercent": "",
                        "baseAmountDouble": 450,
                        "parentId": "",
                        "orderPeriodAsString": "_",
                        "providerAmountDouble": "",
                        "typeEnum": {
                            "mkName": "Enkel",
                            "isDailyRates": false,
                            "isManual": false,
                            "isCustom": false,
                            "isSimple": true,
                            "id": 0,
                            "isManualPeriod": false,
                            "isDiscount": false,
                            "mk": "bus.price.type.simple"
                        },
                        "mkType": "Enkel",
                        "isDailyRates": false,
                        "time": "27.05.21 16:52",
                        "commissionAmountDouble": "",
                        "priceId": ""
                    },
                    "time": "27.05.21 17:09",
                    "priceId": 3013,
                    "isGroup": false,
                    "updated": "27.05.21 17:09",
                    "startDate": 20100101,
                    "child": "Sluttvask",
                    "orderStartTime": ""
                },
                "showTimePicker": false,
                "groupTagsAsString": "",
                "showInRelatedProductsList": true,
                "externalOrderSystemUrl": "",
                "guestCountIsQuantity": "",
                "activityReportTime": "",
                "productConfigId": 1,
                "findProviderPercent": 80,
                "pool": "",
                "subTypeObj": {
                    "parent": "Overnatting",
                    "mkName": "Hytter og feriehus",
                    "isGiftCard": false,
                    "timeConfigs": [
                        {
                            "mkName": "bus.product.timeConfig.name.None",
                            "isNone": true,
                            "name": "Ingen",
                            "isDate": false,
                            "id": 1,
                            "mkDesc": "bus.product.timeConfig.desc.None",
                            "isPeriod": false,
                            "isTime": false,
                            "desc": "Produktet bestilles ikke på dato eller tid. Dette gjelder f.eks. fysiske produkter."
                        },
                        {
                            "mkName": "bus.product.timeConfig.name.Time",
                            "isNone": false,
                            "name": "Tid",
                            "isDate": false,
                            "id": 2,
                            "mkDesc": "bus.product.timeConfig.desc.Time",
                            "isPeriod": false,
                            "isTime": true,
                            "desc": "Produktet bestilles på eksakte tider, brukes ofte på aktiviteter og arrangementer"
                        },
                        {
                            "mkName": "bus.product.timeConfig.name.Date",
                            "isNone": false,
                            "name": "Dato",
                            "isDate": true,
                            "id": 3,
                            "mkDesc": "bus.product.timeConfig.desc.Date",
                            "isPeriod": false,
                            "isTime": false,
                            "desc": "Produktet bestilles over én eller flere dag/netter. Brukes på overnattingsprodukter og aktiviteter som går over flere dager, f.eks. Sykkelutleie osv. "
                        },
                        {
                            "mkName": "bus.product.timeConfig.name.Period",
                            "isNone": false,
                            "name": "Periode",
                            "isDate": false,
                            "id": 4,
                            "mkDesc": "bus.product.timeConfig.desc.Period",
                            "isPeriod": true,
                            "isTime": false,
                            "desc": "Produktet bestilles på én eller flere spesifikke perioder. Brukes blant annet på aktiviteter som går over flere dager, f.eks et kurs som går over en helg. "
                        }
                    ],
                    "label": "Hytter og feriehus",
                    "ptid": "gl_cottages_and_holiday_houses",
                    "message": "Hytter og feriehus",
                    "parentId": 1,
                    "isLocal": true,
                    "companyId": "",
                    "isCleaning": false,
                    "name": "Hytter og feriehus",
                    "isGlobal": true,
                    "isActOrEvent": false,
                    "company": "",
                    "isActivity": false,
                    "id": 24,
                    "time": "23.02.14 14:43",
                    "isOther": false,
                    "guestCountIsQuantity": true,
                    "isLodging": false,
                    "isEvent": false
                },
                "eosProduct": "",
                "findXcap": 1,
                "findTypeEvent": false,
                "packageProduct": false,
                "timeConfigId": 3,
                "templateProduct": false,
                "tcPeriod": false,
                "mkIngress": "Liten leilighet over den lokale puben",
                "tcTime": false,
                "archived": false,
                "pos": true,
                "providerObj": {
                    "zipCode": "3540",
                    "country": "Norge",
                    "roleEnum": "Provider",
                    "role": 2,
                    "city": "NESBYEN",
                    "language": "Norsk",
                    "confirmed": true,
                    "enabled": true,
                    "acceptInfoEmail": false,
                    "emailAddress": "ah@nhage.no",
                    "customerType": 1,
                    "displayUsername": "ah@nhage.no",
                    "countryCode": "NO",
                    "company": "Hus- & Hjerterom",
                    "id": 43328,
                    "email": "",
                    "mkCustomerType": "Bedrift",
                    "address3": "",
                    "address2": "",
                    "address1": "Sandevja 10",
                    "active": false,
                    "timeZone": "Europe/Oslo",
                    "telephone": "90998229",
                    "label": "Adnan Helja",
                    "isNew": false,
                    "languageCode": "no",
                    "name": "Adnan Helja",
                    "time": "20.08.21 10:37",
                    "isUser": false,
                    "updated": "24.08.21 15:40",
                    "mkRole": "Leverandør",
                    "username": "ah@nhage.no"
                },
                "ppgIncludeGeneratedProductPdf": true,
                "isCleaningIncluded": true,
                "firstImageUrl": "https://test.cloud-booking.net/pf/img/product/husoghjerterom/10870/10870-1024x768.png",
                "unlimited": false,
                "guestProduct": true,
                "apForTypeId": "",
                "showChildItems": true,
                "mkgCartItemInfo": "",
                "isPricesEmpty": false,
                "eventDateEnd": "",
                "typeObj": {
                    "parent": "",
                    "mkName": "Overnatting",
                    "isGiftCard": false,
                    "timeConfigs": [
                        {
                            "mkName": "bus.product.timeConfig.name.Date",
                            "isNone": false,
                            "name": "Dato",
                            "isDate": true,
                            "id": 3,
                            "mkDesc": "bus.product.timeConfig.desc.Date",
                            "isPeriod": false,
                            "isTime": false,
                            "desc": "Produktet bestilles over én eller flere dag/netter. Brukes på overnattingsprodukter og aktiviteter som går over flere dager, f.eks. Sykkelutleie osv. "
                        },
                        {
                            "mkName": "bus.product.timeConfig.name.Period",
                            "isNone": false,
                            "name": "Periode",
                            "isDate": false,
                            "id": 4,
                            "mkDesc": "bus.product.timeConfig.desc.Period",
                            "isPeriod": true,
                            "isTime": false,
                            "desc": "Produktet bestilles på én eller flere spesifikke perioder. Brukes blant annet på aktiviteter som går over flere dager, f.eks et kurs som går over en helg. "
                        }
                    ],
                    "label": "Overnatting",
                    "ptid": "g_lodging_lodging",
                    "message": "Overnatting",
                    "parentId": "",
                    "isLocal": true,
                    "companyId": "",
                    "isCleaning": false,
                    "name": "Overnatting",
                    "isGlobal": true,
                    "isActOrEvent": false,
                    "company": "",
                    "isActivity": false,
                    "id": 1,
                    "time": "23.06.12 15:40",
                    "isOther": false,
                    "guestCountIsQuantity": false,
                    "isLodging": true,
                    "isEvent": false
                },
                "hasCustomAdditionalInfoText": false,
                "findCap": 3,
                "findVatInfoCompany": "Norge lav 12%",
                "findTypeActivity": false,
                "findVatInfo": "Norge lav 12%",
                "featured": false,
                "isEos": false,
                "findTripAdvisorWidget": "",
                "isLinenIncluded": true,
                "availabilityInfo": true,
                "firstImageUrlMedium": "https://test.cloud-booking.net/pf/img/product/husoghjerterom/10870/10870-400x300.png",
                "capacityProduct": false,
                "isBreakfastIncluded": false,
                "providerConfirmationRequired": false,
                "eventTimeStart": "",
                "daysDropdownMin": "",
                "findMinCalendarDays": 1,
                "findArea": "",
                "confirmationRequired": false,
                "maxGcs": "-, -, -, -, -",
                "barcode": "",
                "activityReportDays": "",
                "periodsProduct": false,
                "requireAvailableRule": false,
                "apFromDate": "",
                "eventDateStart": "",
                "findKeyOutlet": "",
                "defaultGuests": "",
                "findDefaultGuests": 1,
                "showTime": false,
                "count": 1,
                "mkGcs": {
                    "0": "Voksen",
                    "1": "Barn"
                },
                "subTypeId": 24,
                "pcUnit": false,
                "typeActivity": false,
                "name": "Pøbben - Leilighet",
                "apMandatory": false,
                "findId": "2142",
                "updated": "20.08.21 10:39",
                "cid": "husoghjerterom",
                "desc": "",
                "distAlpineSkiCentre": "14 km",
                "findCount": 1,
                "venue": "",
                "findShowTime": false,
                "shortUrl": "bturl.no/p2142",
                "distCenter": "0 m",
                "findDefaultDays": 2,
                "vatInfoCompanyObj": "",
                "publishBookNorway": false,
                "provider": "Adnan Helja",
                "providerId": 43328,
                "company": "Hus- & Hjerterom",
                "findTypeLodging": true,
                "mainCap": 2,
                "overview": true,
                "timeProduct": false,
                "findMinDays": 1,
                "label": "Pøbben - Leilighet",
                "findIsPriceCustom": false,
                "reportActivityInclude": true,
                "guestsIsCount": false,
                "time": "09.05.21 20:12",
                "typeCleaning": false,
                "eventTimeEnd": "",
                "vatInfoCompanyId": "",
                "orderable": true,
                "hasTimes": false,
                "reportSsbRa0366Include": true,
                "type": "Overnatting",
                "apBookStaying": false,
                "pmVippsEnabled": false,
                "gardsNr": "",
                "findProvider": "Adnan Helja",
                "mkRequiredAdditionalInfoValues": "",
                "findCustomPriceMinDouble": "",
                "id": 2142,
                "findMaxDaysToArrival": 365,
                "eosTypeId": "",
                "isCompanyProduct": true,
                "reportHytteformidlingInclude": true,
                "maxGcMap": {
                    "0": "",
                    "1": "",
                    "2": "",
                    "3": "",
                    "4": ""
                },
                "periodProduct": true,
                "fastCheckout": false,
                "showCampaignCodeField": false,
                "mkRequiredAdditionalInfoText": "Tilleggsinformasjon",
                "isCompanyChildProduct": false,
                "displayType": "Hytter og feriehus",
                "eventTicket": false,
                "maxGuests": "",
                "eventDateStartAsString": "",
                "tcDate": true,
                "poolId": "",
                "subType": "Hytter og feriehus",
                "minCount": "",
                "typeId": 1,
                "mkName": "Pøbbens leilighet",
                "vatInfoCompanyLabel": "",
                "city": "",
                "apToDate": "",
                "apForType": "",
                "apBookArrival": false,
                "hasEosProduct": false,
                "mkDesc": "<p>Velkommen til Gøtt!</p> \n<p>Dette er en leilighet over den lokale puben, Gøtt Pøbb. Leiligheten har en liten kjøkkenkrok med stort kjøleskap, et oppholdsrom med stue og spisebord. Bad med dusjkabinett og vaskemaskin. Soverommet har to enkeltsenger.</p> \n<p>Huset midt i Nesbyen sentrum. <a href=\"https://www.facebook.com/hallingspranget/\">Fjellinken</a> skal gå fra andre siden av veien, og Trailhead plukker for Shuttling rett utenfor. Det mindre enn 2 minutter å gå til <a href=\"http://Visitnesbyen.no\">turistkontor</a>, kjøpesenter, kafé, matbutikk og sykkelhub. Ca. 600 meter til <a href=\"https://buskerudmuseet.com/hallingdal-museum/\">Hallingdal Folkemuseum</a>. </p> \n<ul> \n <li>Muligheter til å låse inn og vaske sykkel (utekran)</li> \n <li>Du kan ha med dyr, men de skal være i bur hvis dere selv ikke er hjemme. + 200,-<br></li> \n <li>Perfekt for deg som er her for å oppleve noen lokale arrangementer i sentrum eller skal sykle og oppleve naturen i et par dager!</li> \n <li>Hvis du skal bruke kjøkken må du enten ta oppvasken selv, eller betale ekstra for dette +150,-</li> \n</ul>",
                "enabled": true,
                "isStartBeforeEnd": false,
                "web": true,
                "eosType": "",
                "tagsAsString": "",
                "maxGc4": "",
                "maxGc2": "",
                "findMinDaysToArrival": 1,
                "maxGc3": "",
                "maxGc0": "",
                "findLongitude": 9.104264096631534,
                "maxGc1": "",
                "chain": "",
                "findProvidersAsString": "Adnan Helja",
                "isAdditionalProduct": false,
                "hasMap": true,
                "url": "/order/product/Overnatting-1/Hytter%20og%20feriehus-24/Adnan%20Helja-43328/-/-/-/P%C3%B8bbens%20leilighet-2142/P%C3%B8bbens%20leilighet.htm",
                "showAdditionalInfoField": false,
                "ppgAvailabilityInfoProviderMail": false,
                "ioProviderPercent": 80,
                "findMinGuests": 1,
                "zipCode": "",
                "isAnimalsAllowed": true,
                "findCustomPriceMaxDouble": "",
                "hasExternalOrderSystemUrl": false,
                "vatRate": 12,
                "pid": "annashus",
                "apBookDeparture": false,
                "mkFeatured": "Nei",
                "daysDropdownMax": "",
                "productConfig": {
                    "mkName": "bus.product.productConfig.name.Normal",
                    "isNormal": true,
                    "isUnit": false,
                    "name": "Normal",
                    "isCollection": false,
                    "isCapacity": false,
                    "isPackage": false,
                    "id": 1,
                    "mkDesc": "bus.product.productConfig.desc.Normal",
                    "isGroup": false,
                    "desc": "Et vanlig produkt"
                },
                "pcGroup": false,
                "mapProduct": false,
                "frontpage": true,
                "relationProduct": false,
                "findOrderTimeThreshold": "",
                "stock": false,
                "findTypeGiftCard": false,
                "findLatitude": 60.57119311643177,
                "vatShortName": "L",
                "kommuneNr": "",
                "dateProduct": false,
                "findProviderAmountDouble": "",
                "pcCapacity": false,
                "mkMeetingPoint": "",
                "apUnlimited": false,
                "parentCompany": true,
                "companyId": 28187,
                "unit": false,
                "minGuests": "",
                "firstImage": {
                    "mkName": "Pøbbens leilighet",
                    "imageDimCalcXlargeAsString": "2000x1334",
                    "copyright": "Pøbben leilighet",
                    "description": "",
                    "urlThumbnailSecure": "https://test.cloud-booking.net/pf/img/product/husoghjerterom/10870/10870-200x150.png",
                    "mkDesc": "",
                    "urlLargeSecure": "https://test.cloud-booking.net/pf/img/product/husoghjerterom/10870/10870-1024x768.png",
                    "imageDimCalcOrgAsString": "5472x3648",
                    "type": 0,
                    "descMessage_en": "",
                    "imageDimCalcThumbnailAsString": "200x134",
                    "urlXlargeSecure": "https://test.cloud-booking.net/pf/img/product/husoghjerterom/10870/10870-2000x2000.png",
                    "urlLarge": "https://test.cloud-booking.net/pf/img/product/husoghjerterom/10870/10870-1024x768.png",
                    "urlMedium": "https://test.cloud-booking.net/pf/img/product/husoghjerterom/10870/10870-400x300.png",
                    "urlMediumSecure": "https://test.cloud-booking.net/pf/img/product/husoghjerterom/10870/10870-400x300.png",
                    "sizeWarning": false,
                    "imageDimCalcLargeAsString": "1024x683",
                    "id": 10870,
                    "hasImageOrg": true,
                    "order": 0,
                    "fileSizeKb": "11 673",
                    "ext": "png",
                    "product": "Pøbben - Leilighet",
                    "productId": 2142,
                    "urlXlarge": "https://test.cloud-booking.net/pf/img/product/husoghjerterom/10870/10870-2000x2000.png",
                    "imageDimCalcMediumAsString": "400x267",
                    "urlOrgSecure": "https://test.cloud-booking.net/pf/img/product/husoghjerterom/10870/10870-org.png",
                    "hasOrgImage": true,
                    "urlOrg": "https://test.cloud-booking.net/pf/img/product/husoghjerterom/10870/10870-org.png",
                    "orgSize": "5472x3648",
                    "tags": "",
                    "fileSizeDisplay": "11673 kB",
                    "findCopyright": "Pøbben leilighet",
                    "filename": "1.png",
                    "mkType": "Generelt",
                    "descMessage_no": "",
                    "fileSize": 11953625,
                    "name": "",
                    "urlThumbnail": "https://test.cloud-booking.net/pf/img/product/husoghjerterom/10870/10870-200x150.png",
                    "messages": {
                        "desc": {
                            "texts": {
                                "no": "",
                                "en": ""
                            },
                            "id": 27900,
                            "key": "bus.image._mks.10870.desc"
                        }
                    },
                    "time": "06.06.21 15:38",
                    "updated": "06.06.21 15:38",
                    "user": "Anna Kollhus"
                },
                "orderView": "",
                "bruksNr": "",
                "findMaxGuests": "",
                "findPosPrice": 0,
                "eosId": "",
                "groupProduct": false,
                "varCap": false,
                "tcNone": false,
                "typeLodging": true,
                "findSubType": "Hytter og feriehus",
                "daysDropdownEnabled": false,
                "eventDateEndAsString": "",
                "hasCap": true,
                "isSmokingAllowed": false,
                "showEndTime": true,
                "timeConfig": {
                    "mkName": "bus.product.timeConfig.name.Date",
                    "isNone": false,
                    "name": "Dato",
                    "isDate": true,
                    "id": 3,
                    "mkDesc": "bus.product.timeConfig.desc.Date",
                    "isPeriod": false,
                    "isTime": false,
                    "desc": "Produktet bestilles over én eller flere dag/netter. Brukes på overnattingsprodukter og aktiviteter som går over flere dager, f.eks. Sykkelutleie osv. "
                },
                "typeOther": false,
                "address1": "",
                "isSkiInSkiOut": false,
                "findTypeActOrEvent": false,
                "vatInfoCompany": "",
                "pcPackage": false,
                "findCustomPriceDefaultDouble": ""
            },
   
        }
    ],
 
}

      */

  }



}
