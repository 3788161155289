import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { AppService, APP_DATAID } from '../../../../../services/app.service';


export class ImageMapItem {
  coords:({top: number,
    left: number,
    width: number,
    height: number,
    rotation?: number
  }) = { 
    top: 0, 
    left: 0, 
    width: 0, 
    height: 0,
    rotation: 0
  };
  
  id?:any
  name?: string
  bg?:any = { }
  data?:any = { }
  selected?:boolean = false;
  disabled?:boolean = false;

  constructor(init?: Partial<ImageMapItem>) {
    Object.assign(this, init);
  }

}

@Component({
  selector: 'bt-image-map',
  templateUrl: './bt-image-map.component.html',
  styleUrls: ['./bt-image-map.component.scss']
})
export class BtImageMapComponent implements OnInit {

  @Input()
  bg: any = {
    
  }

  @Input()
  items: ImageMapItem[] = []

  @Input()
  canEdit: boolean = false;

  @Output('onClick')
  onClick: EventEmitter<ImageMapItem> = new EventEmitter();

  selectedItem:ImageMapItem|null = null;

  constructor(public apps:AppService) {

  }

  ngOnInit() {

    if (this.apps.bas.envtest && this.apps.bas.nossr) {

      window.addEventListener("keyup", (event:KeyboardEvent) => {
        // console.log("keyup: ", event);
        // For at ikke siden skal skrolle, først skroll til riktig sted: 
        // body { overflow: hidden;  }

        if (this.selectedItem == null) return;

        let coords = this.selectedItem.coords;

        if (event.key == "ArrowLeft") {
          coords.left -= (event.shiftKey ? 1 : 0.1);
        } else if (event.key == "ArrowRight") {
          coords.left +=  (event.shiftKey ? 1 : 0.1);
        } else if (event.key == "ArrowUp") {
          coords.top -=  (event.shiftKey ? 1 : 0.1);
        } else if (event.key == "ArrowDown") {
          coords.top +=  (event.shiftKey ? 1 : 0.1);
        } else 
        
        if (event.key == "a") {
          coords.width -=  (event.shiftKey ? 1 : 0.1);
        } else if (event.key == "d") {
          coords.width +=  (event.shiftKey ? 1 : 0.1);
        } else if (event.key == "w") {
          coords.height -=  (event.shiftKey ? 1 : 0.1);
        } else if (event.key == "s") {
          coords.height +=  (event.shiftKey ? 1 : 0.1);
        } else 
        
        if (event.key == "r") { // rotering
          if (coords.rotation == undefined) coords.rotation = 0.0;
          coords.rotation -= 1;
        } else if (event.key == "t") {
          if (coords.rotation == undefined) coords.rotation = 0.0;
          coords.rotation += 1;
        } else {
          return;
        }
        event.preventDefault();
        event.stopPropagation();
      });
    }
  }

  getContainerStyle():object {

    let bg = this.bg;
    if (bg.img) {

      return {
        // backgroundRepeat: 'no-repeat',
        // backgroundImage: 'url("'+bg.img+'")',
        // backgroundSize: 'contain',
        height: bg.height || 'auto'
      }
     
    }

    return {
      backgroundColor: bg.color || 'transparent',
      height: bg.height || 'auto'
    }
  }

  getItemStyle(item: ImageMapItem): object {
    let color = item.selected ? item.bg.colorSelected || item.bg.color : item.bg.color ;
    if (item.disabled) color = item.bg.colorDisabled || "rgba(255, 0, 0, 0.4)";

    let style = {
      top: `${item.coords.top}%`,
      left: `${item.coords.left}%`,
      height: `${item.coords.height}%`,
      width: `${item.coords.width}%`,
      backgroundColor: color || 'transparent',
      transform: `rotate(${item.coords.rotation}deg)`,
    };

    return style;
  }

  onItemClick(item:any) {
    if (item.disabled) {
      if (!this.apps.bas.envtest) return;
    }
    item.selected = !item.selected;
    this.onClick.emit(item);

    this.selectedItem = item;
  }

  // onAreaContext(e: MouseEvent, coordinate: ImageMapItem) {
  //   if(this.canEdit)
  //   {
  //     if(coordinate) {
  //       console.log('editing')
  //     }
  //     else {
  //       console.log('creating')
  //     }
  //     e.stopPropagation();
  //     return false;
  //   }
  // }

  // onAreaCreate(x: number, y: number): ImageMapItem {
  //   const coordinate = new ImageMapItem({x, y, width: 100, height: 100});
  //   return coordinate
  // }

  // onAreaEdit(coordinate: ImageMapItem): ImageMapItem {
  //   return coordinate;
  // }

}

