<btlib-admin-page-wrapper>

  <div class="m-d">
    <h1>{{ 'web.common.tools' | actrans }}</h1>
   
    <form nz-form [nzLayout]="'vertical'" class="m-d" style="max-width: 800px;" >
    
      <div nz-row [nzGutter]="8"  nzAlign="middle">
        <div nz-col  [nzXs]="24" [nzSm]="18" >
          <nz-form-item>
            <nz-form-label >{{ 'web.admin.tools.commonTask' | actrans }}</nz-form-label>
            <nz-form-control  [nzValidateStatus]="errors.runCommonTask ? 'error' : 'validating'">
              <nz-select [(ngModel)]="run.commonTask"  [ngModelOptions]="{standalone: true}">
                <nz-option  nzValue="" nzLabel="-------"></nz-option>
                <nz-option 
                *ngFor="let ct of fbo.commonTasks" 
                  
                  [nzValue]="ct.taskName" 
                  [nzLabel]="ct.label" 
                ></nz-option>
          
              </nz-select>
        
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col  [nzXs]="24" [nzSm]="6" >
          <nz-form-item>
            <nz-form-label >{{ 'web.admin.tools.testNumber' | actrans }}</nz-form-label>
            <nz-form-control  [nzValidateStatus]="errors.runTest ? 'error' : 'validating'">
              <nz-input-number 
               [(ngModel)]="run.testNo"  
               [ngModelOptions]="{standalone: true}"
               [nzMin]="1"
               ></nz-input-number>

        
            </nz-form-control>
          </nz-form-item>
        </div>

      </div>

      <div nz-row [nzGutter]="8"  nzAlign="middle">
        <div 
          *ngFor="let arg of run.args"
          nz-col  [nzXs]="12">
          <nz-form-item>
            <nz-form-label >Arg {{ arg.idx }}</nz-form-label>
            <nz-form-control>
              <input nz-input  [(ngModel)]="arg.value"  [ngModelOptions]="{standalone: true}"   />

        
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div nz-row [nzGutter]="8"  nzAlign="middle">
        <div nz-col  [nzXs]="24" [nzSm]="12" >
          <nz-form-item>
            <nz-form-control>
       
              <button nz-button nzBlock nzType="default"  (click)="runTask('runCommonTask')">
                {{ 'web.admin.tools.runCommonTask' | actrans }}
              </button>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col  [nzXs]="24" [nzSm]="12" >
          <nz-form-item>
            <nz-form-control>
              <button nz-button nzBlock nzType="default"  (click)="runTask('runTest')">
                {{ 'web.admin.tools.runTest' | actrans }}
              </button>
        
            </nz-form-control>
          </nz-form-item>
        </div>

      </div>

    </form>

  </div>


  <!--TODO: translate -->
  <!--T
  
  		<c:if test="${wpd.isNit}">
			<h4 class="mt20"><nit:message key="web.admin.tools.runUpdateWarning" ct="false" /></h4>
			<div class="row" style="">
				<div class="col-sm-4 col-md-3">
					<div id="update_timeDatepicker" class="input-group date ">

  <input type="text" id="update_time" name="update_time" class="form-control" placeholder="<nit:message key="web.admin.tools.updateTime" ct="false" />" value="${updateTime}" autocomplete="off" />
  <span class="input-group-addon"><i class="glyphicon glyphicon-calendar" data-time-icon="glyphicon glyphicon-time" data-date-icon="glyphicon glyphicon-calendar"></i></span>
</div>
</div>
<div class="col-sm-2 col-md-1">
<input type="text" id="update_period" name="update_period" class="form-control" placeholder="<nit:message key="web.admin.tools.updatePeriod" ct="false" />" value="${nit:getProperty('WEB_UPDATE_PERIOD','')}" />
</div>
<div class="col-sm-6 col-md-8">
<a href="#" class="btn btn-default" id="setUpdateWarning"><nit:message key="web.admin.tools.runUpdateWarning" ct="false" /></a>
</div>

</div>
</c:if>
  -->

<div *ngIf="apps.bas.aas.isSu()" class="m-d">
  <h2>{{ 'web.admin.tools.runUpdateWarning' | actrans }}</h2>
  <div nz-row [nzGutter]="8"  nzAlign="middle">
    <div nz-col  [nzXs]="24" [nzSm]="12" >
      <nz-form-item>
        <nz-form-control>
          <nz-date-picker 
          [(ngModel)]="fbo.updateTimeDate" 
          [ngModelOptions]="{standalone: true}" 
          nzShowTime
          [nzFormat]="'yyyy-MM-dd HH:mm'"
    
        ></nz-date-picker>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col  [nzXs]="24" [nzSm]="6" >
      <nz-form-item>
        <nz-form-control>
          <nz-input-number  
            [(ngModel)]="fbo.updatePeriod" 
            [nzPlaceHolder]="'web.admin.tools.updatePeriod' | actrans | str" 
            [ngModelOptions]="{standalone: true}" >
          </nz-input-number>
       
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col  [nzXs]="24" [nzSm]="6" >
      <nz-form-item>
        <nz-form-control>
          <button nz-button nzBlock nzType="default"  (click)="saveUpdateTime()">
            {{ 'common.save' | actrans }}
          </button>
        </nz-form-control>
      </nz-form-item>
    </div>
  
  </div>

</div>

<div class="m-d">
  <h2>{{ 'web.main.tr.translateText' | actrans }}</h2>

  <div nz-row [nzGutter]="8"  nzAlign="middle">
    <div nz-col  [nzXs]="24" [nzSm]="18" >
      <nz-form-item>
        <nz-form-control>
          <input nz-input  [(ngModel)]="messageKey" [placeholder]="'web.main.tr.messageKey' | actrans | str"  [ngModelOptions]="{standalone: true}"   />
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col  [nzXs]="24" [nzSm]="6" >
      <nz-form-item>
        <nz-form-control>
          <button nz-button nzBlock nzType="default"  (click)="translateText()">
            {{ 'web.main.tr.translateText' | actrans }}
          </button>
    
        </nz-form-control>
      </nz-form-item>
    </div>
  
  </div>
</div>


  

  <div class="m-d" *ngIf="fbo.heapUsageMax">
    <nz-descriptions
        nzBordered
        [nzColumn]="1"
        [nzTitle]="'common.status' | actrans | str"
        style="max-width: 500px;"
      >
      <nz-descriptions-item [nzTitle]="'common.version' | actrans | str"><div class="text-right">{{ fbo.servletContextName }}</div></nz-descriptions-item>
      <nz-descriptions-item [nzTitle]="'common.environment' | actrans | str"><div class="text-right">{{ apps.bas.env ? 'Produksjon' : 'Test' }}</div></nz-descriptions-item>
      <nz-descriptions-item [nzTitle]="'web.admin.tools.started' | actrans | str"><div class="text-right">{{ fbo.startTime }}</div></nz-descriptions-item>
      <nz-descriptions-item [nzTitle]="'web.admin.tools.uptime' | actrans | str"><div class="text-right">{{ fbo.uptime }}</div></nz-descriptions-item>
      <nz-descriptions-item [nzTitle]="'web.admin.tools.mem.init' | actrans | str"><div class="text-right">{{ (fbo.heapUsageInit | nf) }} MB</div></nz-descriptions-item>
      <nz-descriptions-item [nzTitle]="'web.admin.tools.mem.max' | actrans | str"><div class="text-right">{{ (fbo.heapUsageMax | nf) }} MB</div></nz-descriptions-item>
      <nz-descriptions-item [nzTitle]="'web.admin.tools.mem.committed' | actrans | str"><div class="text-right">{{ (fbo.heapUsageCommitted | nf) }} MB</div></nz-descriptions-item>
      <nz-descriptions-item [nzTitle]="'web.admin.tools.mem.used' | actrans | str"><div class="text-right">{{ (fbo.heapUsageUsed | nf) }} MB</div></nz-descriptions-item>

    </nz-descriptions>

  </div>


  
  <btlib-message-modal [input]="messageInput"></btlib-message-modal>



  <!-- <div class="text-center m-d">
    <button nz-button nzType="primary"  (click)="getFbo()">
      <i nz-icon nzType="sync"></i>
      {{ 'common.update' | actrans }}
    </button>

  </div> -->




</btlib-admin-page-wrapper>

