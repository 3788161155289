
<nz-modal 
[nzVisible]="key != ''" 
[nzTitle]="'common.translate' | actrans | str" 
(nzOnCancel)="closeModal()" 
(nzOnOk)="closeModal()"
>
  <!--   nzWidth="90vw"  -->
  <ng-container *nzModalContent>
    
    <div *ngIf="key != ''">
      <form nz-form [formGroup]="df.form" class="item-form mt-d" nzLayout="vertical" [nzAutoTips]="apps.bas.fs.autoTips" (ngSubmit)="dfSubmit()"  >
        <btlib-form-row [controls]="[ 
            
          df.controls.key,
          df.controls.message,
        ]" [form]="df.form"  [grid]="apps.bas.ui.gridFull"  ></btlib-form-row>
      
      </form>

      <nz-alert
        *ngIf="key.includes('common') || key.includes('._mks.') || key.startsWith('bus.')"
        nzType="warning" 
        [nzMessage]="alertTpl"></nz-alert>
    
        <ng-template #alertTpl>
          <div [innerHtml]="'web.main.tr.commonWarning' | actrans"></div>
        </ng-template>

    </div>
  </ng-container>
  <div *nzModalFooter>
    <button nz-button nzType="primary" (click)="dfSubmit()">{{ 'common.save' | actrans }}</button>
  </div>
</nz-modal>

