<div class="bt-page-wrapper">
  <div *ngIf="!pw0" style="height: 400px;">
    <div class="loading-wrapper text-center">
      <div  style="margin-top: 40vh;">
        <nz-spin id="loading-spinner" nzSimple [nzSize]="'large'"></nz-spin>
      </div>
    </div>
    <div></div>
    
  </div>
  
  
  <div style="height: 20px;"></div>
  <div class="padding: 10px;">
  
  <div nz-row>
    <div nz-col nzSpan="12">
      <button nz-button nzType="link" (click)="goHome();">
        <i nz-icon nzType="home"></i>
      </button> 
    </div>
    <div nz-col nzSpan="12" class="text-right" style="padding-right: 20px;">
      <btlib-order-cart-preview [cart]="apps.bas.ds.getValue('ORDER_CART')"></btlib-order-cart-preview>
      
    </div>
  </div>
  
  <div *ngIf="pw0 && !checkout">
  
  
  
    <btlib-order-product [pw]="pw0"></btlib-order-product>
  
    <!-- -->
  
    
  
    <!-- <div style="height: 20px;"></div>
    <button nz-button nzType="default" (click)="test1();">Test-knapp</button>  -->
  
  </div>
  
  <div *ngIf="checkout">
    <btlib-order-cart></btlib-order-cart>
  </div>
  </div>
  
  <div style="height: 100px;"></div>
  
  </div>
  
  