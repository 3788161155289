import { Injector, SecurityContext } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { MiscUtil } from '../util/misc.util';
import { BooktechAppService } from './booktech-app.service';
import { DATAID } from './data.service';

export const AntdTheme = {
  dark: 'dark',
  default: 'default',
  base: 'base',
  langedrag: 'langedrag',
  bileasy: 'bileasy',
  tonsberglivet: 'tonsberglivet',
  cbsite: "cbsite"
}



const CustomStyleVars:any = {
  "color-primary": "color",
  "color-secondary": "color"
};

const pSBC=(p:any,c0:any,c1?:any,l?:any)=>{
	let r,g,b,P,f,t,h,m=Math.round,a:any=typeof(c1)=="string";
	if(typeof(p)!="number"||p<-1||p>1||typeof(c0)!="string"||(c0[0]!='r'&&c0[0]!='#')||(c1&&!a))return null;
	h=c0.length>9,h=a?c1.length>9?true:c1=="c"?!h:false:h,f=pSBC.pSBCr(c0),P=p<0,t=c1&&c1!="c"?pSBC.pSBCr(c1):P?{r:0,g:0,b:0,a:-1}:{r:255,g:255,b:255,a:-1},p=P?p*-1:p,P=1-p;
	if(!f||!t)return null;
	if(l)r=m(P*f.r+p*t.r),g=m(P*f.g+p*t.g),b=m(P*f.b+p*t.b);
	else r=m((P*f.r**2+p*t.r**2)**0.5),g=m((P*f.g**2+p*t.g**2)**0.5),b=m((P*f.b**2+p*t.b**2)**0.5);
	a=f.a,t=t.a,f=a>=0||t>=0,a=f?a<0?t:t<0?a:a*P+t*p:0;
	if(h)return"rgb"+(f?"a(":"(")+r+","+g+","+b+(f?","+m(a*1000)/1000:"")+")";
	else return"#"+(4294967296+r*16777216+g*65536+b*256+(f?m(a*255):0)).toString(16).slice(1,f?undefined:-2)
}

pSBC.pSBCr=(d:any)=>{
	const i=parseInt;
	let n=d.length,x:any={};
	if(n>9){
		const [r, g, b, a] = (d = d.split(','));
	        n = d.length;
		if(n<3||n>4)return null;
		x.r=i(r[3]=="a"?r.slice(5):r.slice(4)),x.g=i(g),x.b=i(b),x.a=a?parseFloat(a):-1
	}else{
		if(n==8||n==6||n<4)return null;
		if(n<6)d="#"+d[1]+d[1]+d[2]+d[2]+d[3]+d[3]+(n>4?d[4]+d[4]:"");
		d=i(d.slice(1),16);
		if(n==9||n==5)x.r=d>>24&255,x.g=d>>16&255,x.b=d>>8&255,x.a=Math.round((d&255)/0.255)/1000;
		else x.r=d>>16,x.g=d>>8&255,x.b=d&255,x.a=-1
	}return x
};

export class StyleService {
  antdTheme = AntdTheme.default;
  antdThemeIdx = 2;

  DOC:Document;

  constructor(public injector:Injector, public bas: BooktechAppService ) {
    if(this.bas.envtest) console.log(MiscUtil.getLogText("StyleService.constructor, findCid: " + this.bas.ds.findCid('style-service.constructor')));
    // this.loadTheme(AntdTheme.dark, true).then((done) => {
    //   if(this.apps.bas.envtest) console.log(MiscUtil.getLogText("StyleService.done"));
    // });
   
    this.DOC = injector.get(DOCUMENT);

    this.updateCssVars({ });


    // this.bas.ds.find(DATAID.APP_CONFIG).subscribe((res) => {
    //   if(this.apps.bas.envtest) console.log(MiscUtil.getLogText("StyleService.APP_CONFIG.subscribe, cid: ", res));
    // });

    let cid = this.bas.ds.findCid('style-service.constructor');
    let theme = AntdTheme.default;


    if (this.bas.settings.appId == "cbwidget") {
      for ( let key of Object.keys(AntdTheme) ) {
        if (key == cid) {
          theme = key;
          break;
        }
      }
    }



    if (theme != AntdTheme.default) {
      if(this.bas.envtest) console.log(MiscUtil.getLogText("StyleService.calling loadTheme: " + theme));
      this.loadTheme(theme);
    }

    this.bas.es.ready().then(() => {
      let appInitWrapper = this.DOC.getElementById("app-init-wrapper");
      if (appInitWrapper) {
        appInitWrapper.style.display = "none";
        //this.bas.es.sendParentMessageResize();
      }
    });
  }

  getQueryParamStyle(style:any, ar:ActivatedRoute, defaults:any = { }) {

    for (let key of Object.keys(style)) {
      style[key] = ar.snapshot.queryParams[key]  || style[key];
      if (style[key] === undefined && defaults[key]) style[key] = defaults[key];
    }

    
    return style;
  }

  // setCustomStyle(style:any) {
  //   for (let key of Object.keys(style)) {
  //     let val = style[key];
  //     if (val == undefined) continue;
  //     this.addRootClass("has-style-" + key);
  //   }
  // }


  updateCustomStyle(ars:ActivatedRouteSnapshot) {
    if (this.bas.envtest) console.log("updateCustomStyle: ", ars.queryParams);
    let hasCustomStyle = false;
    for (let key in ars.queryParams) {
  
      // style-custom-color-primary
      if (!key.startsWith("style-custom")) continue;

      let val = ars.queryParams[key];
      //console.log("key: " + key + "; val: " + val);

      
      let type = CustomStyleVars[key.substring("style-custom-".length)];
      if (!type) continue; 

      if (type == "color") {
        // console.log("valid Color: " + CSS.supports("color", val));
        if (!CSS.supports("color", val)) {
          continue;
        }
      }

      //const result = this.bas.ui.sanitizer.sanitize(SecurityContext.STYLE, val);
      

      let varName = key.replace("style-custom", "app-custom");

      this.updateCssVar(varName, val);
      hasCustomStyle = true;
    }

    if (hasCustomStyle) this.addRootClass("has-custom-style");
  }

  updateCssVar(name:string, value:string) {
    let vars:any = { };
    vars[name] = value; 
    this.updateCssVars(vars);
  }
  updateCssVars(vars:any) {
    //TODO: hente fra appConfig eller bruke default
    for (let key of Object.keys(vars)) {
      let val:string = vars[key];
      if (val === undefined) continue;
      
      this.DOC.documentElement.style.setProperty("--" + key, val);


      if (val.startsWith("#") || val.startsWith("rgb") ) {
        let colorDarken = this.adjustColor(-0.2, val);
        let colorLighten = this.adjustColor(0.1, val);
        
        this.DOC.documentElement.style.setProperty("--" + key + "-darken", colorDarken);
        this.DOC.documentElement.style.setProperty("--" + key + "-lighten", colorLighten);

      }

    }

    // this.DOC.documentElement.style.setProperty("--bgc", "blue");
  }

  updateFavicon(url:string) {
    const icon:HTMLLinkElement|null = this.DOC.querySelector("link[rel=icon]");
    if (this.bas.envtest) console.log("updateFavicon: icon: ", icon);
    // Hva hvis det er flere tagger, både ico og png? 

    if (icon) {
      icon.type = "image/png";
      icon.href = url;
    } else {
      const link = this.DOC.createElement('link');
      link.rel = 'icon';
      link.type = "image/png";
      link.href = url;
     
      this.DOC.head.append(link);
    }
    
   
    //  <link rel="icon" type="image/png" href="https://www.cloud-booking.net/pf/img/company/runguviaferrata/favicon.png">
   
  }

  addRootClass(name:string) {
    this.DOC.documentElement.classList.add(name)
    
  }
  removeRootClass(name:string) {
    this.DOC.documentElement.classList.remove(name)
  }


  private removePreviousTheme(): void {
    let prevIdx = this.antdThemeIdx - 2;
    const prevThemeStyle = this.DOC.getElementById("antd-theme-style-" + prevIdx);
    if (this.bas.envtest) console.log("removePreviousTheme: prevIdx: " + prevIdx + ", prevThemeStyle: ", prevThemeStyle);
    
    if (prevThemeStyle) {
      let themeName = prevThemeStyle.getAttribute("data-theme");
      if(this.bas.envtest) console.log("themeName: " + themeName);
      if (themeName) this.DOC.documentElement.classList.remove(themeName);
      this.DOC.head.removeChild(prevThemeStyle);
    }
  }

  private loadCss(href: string, id: string): Promise<Event> {
    let idx = this.antdThemeIdx++;
    if(this.bas.envtest) console.log("loadCss: " + idx + ", id: " + id + ", href: " + href + ", antdThemeIdx: " + this.antdThemeIdx);
    return new Promise((resolve, reject) => {
      const style = this.DOC.createElement('link');
      style.rel = 'stylesheet';
      style.href = href;
      style.id = "antd-theme-style-" + idx;
      style.setAttribute("data-theme", id);
      style.onload = resolve;
      style.onerror = reject;
      this.DOC.head.append(style);
    });
  }

  public loadTheme(theme:string = AntdTheme.default, firstLoad = false): Promise<Event> {
    // const theme = this.antdTheme;
    
    this.antdTheme = theme;
    if (firstLoad) {
      this.DOC.documentElement.classList.add(theme);
    }
    return new Promise<Event>((resolve, reject) => {
      this.loadCss(`${theme}.css`, theme).then(
        (e) => {
          if (!firstLoad) {
            this.DOC.documentElement.classList.add(theme);
          }
          this.removePreviousTheme();
          resolve(e);
        },
        (e) => reject(e)
      );
    });
  }


  public adjustColor(percent:number, color1:string, color2?:string|boolean, linear?:boolean) {
    return pSBC(percent, color1, color2, linear);
  }

  public getColorOld(col:string, amt:number) {
  
    var usePound = false;
  
    if (col[0] == "#") {
        col = col.slice(1);
        usePound = true;
    }
 
    var num = parseInt(col,16);
 
    var r = (num >> 16) + amt;
 
    if (r > 255) r = 255;
    else if  (r < 0) r = 0;
 
    var b = ((num >> 8) & 0x00FF) + amt;
 
    if (b > 255) b = 255;
    else if  (b < 0) b = 0;
 
    var g = (num & 0x0000FF) + amt;
 
    if (g > 255) g = 255;
    else if (g < 0) g = 0;
 
    return (usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16);
  
}


}
