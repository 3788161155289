import { Component, Injector, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { MiscUtil } from 'src/app/lib/core/util/misc.util';
import { AppService } from 'src/app/services/app.service';
import { DynformControl } from 'src/app/lib/core/model/dymform-control';

import { NzUploadChangeParam, NzUploadFile } from 'ng-zorro-antd/upload';

@Component({
  selector: 'app-app-refugee-user-room-for-rent-page',
  templateUrl: './app-refugee-user-room-for-rent-page.component.html',
  styleUrls: ['./app-refugee-user-room-for-rent-page.component.scss']
})
export class AppRefugeeUserRoomForRentPageComponent implements OnInit {

  processing = false;
  fbo:any = { };
  response:any = { 
    statusCode: false 
  };
  edit:any = {
    obj: {

    },
    controlOptions: {
      showRequiredStar: true
    },
    fileList: []
  }

  constructor(public injector:Injector, public apps:AppService) {
    let route = injector.get(ActivatedRoute);
    this.apps.bas.us.checkActivatedRoute(route.snapshot);

    this.getFbo();

  }

  ngOnInit(): void {
    
  }


  
  getFbo() {

    this.apps.bas.ws.json({ 
      aType: "refugee", 
      action: "getFbo", 
      // id: this.route.snapshot.queryParams.id
      // jsonData: JSON.stringify({ lead: lead })
    
    }).then((json) => {

      if (this.apps.bas.envtest) console.log("getFbo, json: ", json);
      if (json.success) {
        this.fbo = json.fbo;

        this.editObject({

         });

        //  setTimeout(() => {
        //   //console.log("sendParentMessageResize");
        //   this.apps.bas.es.sendParentMessageResize();
        //  }, 100);
      }
    });

  }

    
  formSubmit() {

    // console.log("formSubmit")
    let rv = this.apps.bas.fs.getRawValue(this.edit.form, this.edit.controls);

    if (rv === false) return;


   
    
    if (this.apps.bas.envtest) console.log("formSubmit, rv: ", rv);

    this.processing = true;

    this.apps.bas.ws.json({ 
      aType: "refugee", 
      action: "addRoomForRent", 
      // id: this.route.snapshot.queryParams.id, 
      jsonData: JSON.stringify(rv)
    
    }).then((json) => {
      this.processing = false;

      if (this.apps.bas.envtest) console.log("addRoomForRent, json: ", json);
      if (json.success) {
        this.response = json;
      }
    }).catch(err => {
      this.processing = false;

    });

  }
  
  editObject(obj:any) {

    if(this.apps.bas.envtest) console.log("editObject, obj: ", obj);

    let controls: DynformControl[] = [];

    controls.push(new DynformControl({ 
      key: "product",
      controlType: "formGroup",
      children: [
        new DynformControl({ 
          key: 'address1',
          
          mk: 'Adresse', 
          required: true , 
          value: ""
        }),
        new DynformControl({ 
          key: 'zipCode',
          
          mk: 'Postnr.', 
          required: true , 
          value: "",
          grid: this.apps.bas.ui.gridMd_1_3,
          onChange: (event) => {
            this.apps.bas.ws.json({  action: "getZipCodeCity",  zipCode: event.value  }).then((json) => {
              if (json.success)  this.edit.form.controls.product.controls.city.setValue(json.city);
            });
          }
        }),
        new DynformControl({ 
          key: 'city',
          
          mk: 'Poststed', 
          required: true , 
          value: "",
          grid: this.apps.bas.ui.gridMd_2_3,
        }),
        new DynformControl({ 
          key: 'desc',
          mk: 'Spørsmål eller kommentarer',  
          controlType: "textarea"
        })
      ]
    }));

    let fbo = this.fbo;


    controls.push(new DynformControl({ 
      key: "user",
      controlType: "formGroup",
      children: [
        

        new DynformControl({ 
          key: 'username',     
          mk: 'E-post', 
          required: true,
          type: "email"
        }),
        new DynformControl({ 
          key: 'telephone',     
          mk: 'Telefon', 
          required: true 
        }),

        new DynformControl({ 
          key: 'orgNumber',     
          mk: 'Organisasjonsnummer', 
          // required: true 
        }),
        
        new DynformControl({ 
          key: 'name',     
          mk: 'Fullt navn', 
          required: true 
        }),
        new DynformControl({ 
          key: 'acceptEula',     
          mk: 'Aksepter avtalevilkår', 
          controlType: "checkbox",
          required: true 
        }),

      ]
    }));


    this.apps.bas.fs.updateFormObj(this.edit, controls, obj);


  } 

}