
<div class="m-d">
  <h1>{{ 'common.new' | actrans }} {{ 'common.task' | actrans }}</h1>

  <div *ngIf="df.obj">

    <form nz-form [formGroup]="df.form" class="item-form mt-d" nzLayout="vertical" [nzAutoTips]="apps.bas.fs.autoTips" (ngSubmit)="dfSubmit()"  >
      <div class="my-d" *ngIf="apps.bas.appLogin.success">
        Du er innlogget som <strong>{{ apps.bas.appLogin.user.username }}</strong>
      </div>

      <btlib-form-row [controls]="[  df.controls.product ]" [form]="df.form"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row>

 

      <div *ngIf="!apps.bas.appLogin.success">

        <btlib-form-row [controls]="[  df.controls.userUsername, df.controls.userName ]" [form]="df.form"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row>
     
        <p>Vi lagrer navnet og epost-adressen din for å kunne holde deg oppdatert på status på oppgaven du legger inn.</p>
      </div>


      <btlib-form-row [controls]="[  df.controls.title ]" [form]="df.form"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row>
      <btlib-form-row [controls]="[  df.controls.body ]" [form]="df.form"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row>
      

      <div nz-row [nzGutter]="apps.bas.ui.gridMd.gutter" nzAlign="top">
        <div nz-col [nzXs]="24" [nzSm]="16" [nzMd]="12" [nzLg]="10" [nzXl]="8" [nzXXl]="8">
          <h3>Last opp bilder og dokumenter</h3>
          <p>Du kan laste opp bilde-filer og PDF-filer. </p>
          <nz-upload
            nzType="drag"
            [nzMultiple]="true"
            [nzAction]="this.apps.bas.ws.getBaseUrlTools() + '?action=uploadDropzone&type=taskFile&uuid=' + df.obj.uuid"
            [nzHeaders]="uploadHeaders"
            [(nzFileList)]="df.fileList"
            nzFileType="image/png,image/jpeg,application/pdf"
            nzListType="text"
            [nzShowUploadList]="{ showRemoveIcon: true }"
            (nzChange)="handleUploadFile($event)"

            >
            <p class="ant-upload-drag-icon">
              <i nz-icon nzType="inbox"></i>
            </p>
            <p class="ant-upload-text">
              {{ 'app.cbapi5.page.admin.product.clickOrDragFilesHere' | actrans }}
              
            </p>
            <p class="ant-upload-hint">
              {{ 'app.cbapi5.page.admin.product.uploadFilesHint' | actrans }}
              
            </p>
          </nz-upload>
      
        </div>
      </div>
      
      <div class="mt-d">
        <button nz-button nzType="default"> {{ 'common.save' | actrans }}  </button>
      </div>

    </form>

    
  </div>
  
</div>




