<bt-page-wrapper>
  <div></div>
</bt-page-wrapper>


<!-- 
template: row
<div nz-row [nzGutter]="apps.bas.ui.gridMd.gutter" nzAlign="top">
  <div nz-col [nzXs]="apps.bas.ui.gridMd.xs" [nzSm]="apps.bas.ui.gridMd.sm" [nzMd]="apps.bas.ui.gridMd.md" [nzLg]="apps.bas.ui.gridMd.lg" [nzXl]="apps.bas.ui.gridMd.xl" [nzXXl]="apps.bas.ui.gridMd.xxl">
  
  </div>
  <div nz-col [nzXs]="apps.bas.ui.gridMd.xs" [nzSm]="apps.bas.ui.gridMd.sm" [nzMd]="apps.bas.ui.gridMd.md" [nzLg]="apps.bas.ui.gridMd.lg" [nzXl]="apps.bas.ui.gridMd.xl" [nzXXl]="apps.bas.ui.gridMd.xxl">
    
  </div>
</div> 
-->
