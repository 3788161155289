import { HostListener, Injector } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, Title } from '@angular/platform-browser';
import { BooktechAppService } from './booktech-app.service';

import format from 'date-fns/format'
import formatISO from 'date-fns/formatISO'
import parse from 'date-fns/parse'
import parseISO from 'date-fns/parseISO'
import addDays from 'date-fns/addDays'
import isSunday from 'date-fns/isSunday'
import add from 'date-fns/add'
import set from 'date-fns/set'
import intervalToDuration from 'date-fns/intervalToDuration'
import differenceInDays from 'date-fns/differenceInDays'

import { MbscPopup, MbscPopupOptions } from '@mobiscroll/angular-ivy';
import { MiscUtil } from '../util/misc.util';
import { DATAID } from './data.service';
// import { MbscFormOptions } from '@mobiscroll/angular4';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzModalService } from 'ng-zorro-antd/modal';

import { Subject } from 'rxjs';
// import { debounceTime } from 'rxjs/operators';
import {debounceTime, switchMap, takeUntil, skip } from 'rxjs/operators';

import { BtEvent } from './event.service';
import { Router } from '@angular/router';
import { BtContent } from '../model/bt-content';

const PADDING = "000000";
const SEPARATORS = {
  "no": { "DECIMAL": ",", "THOUSANDS": ""},
  "en": { "DECIMAL": ".", "THOUSANDS": ""},
};

const BREAKPOINTS = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600
};

export const autocomplete = (time:number, selector:any) => (source$:any) =>
  source$.pipe(
    debounceTime(time),
    switchMap((...args: any[]) => 
      selector(...args)
        .pipe(
            takeUntil(
                source$
                    .pipe(
                        skip(1)
                    )
            )
        )
    )
  )

export class UIService {

  public readonly DECIMAL_SEPARATOR: string = ",";
  public readonly THOUSANDS_SEPARATOR: string = " ";
  sanitizer:DomSanitizer;

  formSize = {
    lbl: {
      xs: 12,
      sm: 6
    },
    ctrl: {
      xs: 12,
      sm: 10
    }

  }

  resizeSubject = new Subject<any>();
  r = {
    w: -1, //window ? window.innerWidth : -1,
    h: -1, //window ? window.innerHeight : -1,

    xs: true,
    sm: false,
    md: false,
    lg: false,
    xl: false,
    xxl: false,
    
    gtexs: true,
    gtesm: false,
    gtemd: false,
    gtelg: false,
    gtexl: false,
    gtexxl: false,
    // ltxs: true,
    ltsm: true,
    ltmd: true,
    ltlg: true,
    ltxl: true,
    ltxxl: true,
    ltexs: true,
    ltesm: true,
    ltemd: true,
    ltelg: true,
    ltexl: true,
    ltexxl: true,

    size: "xs",
    init: false,
  }

  
  //            xs: 0,  sm: 576,  md: 768,  lg: 992,  xl: 1200,  xxl: 1600
  gridFull  = { xs: 24, gutter: [8, 16] };
  gridXl    = { xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 12, gutter: [8, 16] };
  gridLg    = { xs: 24, sm: 24, md: 12, lg: 12, xl: 12,  xxl: 12,  gutter: [8, 16] };
  gridMd    = { xs: 24, sm: 12, md: 12, lg: 12, xl: 8,  xxl: 6,  gutter: [8, 16] };
  gridMd_1_3 = { xs: 24, sm: 8, md: 8, lg: 6, xl: 4,  xxl: 4,  gutter: [8, 16] };
  gridMd_2_3 = { xs: 24, sm: 16, md: 16, lg: 12, xl: 8,  xxl: 6,  gutter: [8, 16] };
  
  gridSm    = { xs: 24, sm: 12, md: 8,  lg: 8,  xl: 6,  xxl: 4,  gutter: [8, 16] };
  gridXs    = { xs: 12, sm: 8,  md: 8,  lg: 6,  xl: 4,  xxl: 4,  gutter: [8, 16] };
  gridXxs    = { xs: 12, sm: 6,  md: 6,  lg: 4,  xl: 4,  xxl: 2,  gutter: [8, 16] };
  //           xs: 0, sm: 576, md: 768, lg: 992, xl: 1200, xxl: 1600

  msPopupSettings: MbscPopupOptions = {
      display: 'anchored',
      buttons: []
  };

  // msFormOptions: MbscFormOptions = { // 
  //   labelStyle: "stacked",
  //   inputStyle: "outline"
  // }

  // label-style="floating" input-style="outline"

  messageService: NzMessageService;
  notificationService: NzNotificationService;
  modalService: NzModalService;

  router:Router;
  title:Title;

  

  constructor(public injector:Injector, public bas: BooktechAppService ) {
    if(this.bas.envtest) console.log("UIService.constructor");
    this.sanitizer = injector.get(DomSanitizer);
    this.messageService = injector.get(NzMessageService);
    this.notificationService = injector.get(NzNotificationService);
    this.modalService = injector.get(NzModalService);
    this.router = injector.get(Router);
    this.title = injector.get(Title);

    if (bas.nossr) {
      this.r.w = window.innerWidth ;
      this.r.h = window.innerHeight ;
    }

    //this.router.navigate([ "/" ], { });

    this.resizeSubject.pipe(
      debounceTime(50)
    ).subscribe((resizeEvent) => {
      this.doResize(resizeEvent);
    });

    this.doResize();
  }


  onResize(event?:any) {
    // console.log(MiscUtil.getLogText("onResize", true)); 
    this.resizeSubject.next(event);
  }

  doResize(event?:any) {
    // this.innerWidth = window.innerWidth;
    // console.log(MiscUtil.getLogText("doResize", true)); 
    let h =  this.bas.nossr ? window.innerHeight : -1;
    let w = this.bas.nossr ? window.innerWidth : -1;

    var current = this.r;
    var r = MiscUtil.clone(current);

    let b = BREAKPOINTS;

    r.w = w;
    r.h = h;
    r.xs = (w < b.sm);
    r.sm = (w >= b.sm && w < b.md);
    r.md = (w >= b.md && w < b.lg);
    r.lg = (w >= b.lg && w < b.xl);
    r.xl = (w >= b.xl && w < b.xxl);
    r.xxl = (w >= b.xxl);

    // grater then or equal to
    r.gtexs = r.xxl || r.xl || r.lg || r.md || r.sm || r.xs; 
    r.gtesm = r.xxl || r.xl || r.lg || r.md || r.sm; 
    r.gtemd = r.xxl || r.xl || r.lg || r.md; 
    r.gtelg = r.xxl || r.xl || r.lg; 
    r.gtexl = r.xxl || r.xl; 
    r.gtexxl = r.xxl; 

    // less then
    r.ltsm = r.xs; 
    r.ltmd = r.xs || r.sm; 
    r.ltlg = r.xs || r.sm || r.md; 
    r.ltxl = r.xs || r.sm || r.md || r.lg; 
    r.ltxxl = r.xs || r.sm || r.md || r.lg || r.xl; 
    // r.ltxxl = r.xs || r.sm || r.md || r.lg || r.xl || r.xxl; ; 

    // less then or equal to
    r.ltexs = r.xs; 
    r.ltesm = r.xs || r.sm; 
    r.ltemd = r.xs || r.sm || r.md; 
    r.ltelg = r.xs || r.sm || r.md || r.lg; 
    r.ltexl = r.xs || r.sm || r.md || r.lg || r.xl; 
    r.ltexxl = r.xs || r.sm || r.md || r.lg || r.xl || r.xxl; ; 
    
    if (r.xs) r.size = "xs";
    if (r.sm) r.size = "sm";
    if (r.md) r.size = "md";
    if (r.lg) r.size = "lg";
    if (r.xl) r.size = "xl";
    if (r.xxl) r.size = "xxl";


    this.r = r;

    if (current.size != r.size || r.init == false) {

      this.bas.es.trigger(BtEvent.DEVICE_RESIZE, {  prev: current, responsive: r })
    }
 
    this.bas.es.trigger(BtEvent.RESIZE, { prev: current, responsive: r })

    r.init = true;
  }


  info(message:string, durationIsSeconds:number = 4) {
    return this.messageService.info(message, { nzDuration: durationIsSeconds * 1000 });
  }
  success(message:string, durationIsSeconds:number = 4) {
    return this.messageService.success(message, { nzDuration: durationIsSeconds * 1000 });
  }
  warning(message:string, durationIsSeconds:number = 4) {
    return this.messageService.warning(message, { nzDuration: durationIsSeconds * 1000 });
  }
  error(message:string, durationIsSeconds:number = 4) {
    return this.messageService.error(message, { nzDuration: durationIsSeconds * 1000 });
  }

  modal(type:string, title:string, content:string) {
    if (type == "error") return this.modalError(title, content);
    if (type == "info") return this.modalInfo(title, content);

    return this.modalInfo(title, content);
  }


  modalInfo(title:string, content:string) {
    return this.modalService.info({
      nzTitle: title,
      nzContent: content
    });
  }

  modalError(title:string = "", content:string) {
    if (!title) title = this.actrans('common.error');

    return this.modalService.error({
      nzTitle: title,
      nzContent: content
    });
  }

  notification(type:string, title:string, desc:string, duration:number = 4) {
    return this.notificationService.create(
      type,
      title,
      desc,
      { 
        nzDuration: duration * 1000
      }
    );
  }

  nzNumberParser = (value: string):string => {  return this.nfparse(value) + ""; }
  nzNumberParser1 = (value: string):string => {  return this.nfparse(value, 1) + ""; }
  nzNumberFormatter = (value: number):string =>  { return this.nf(value);}
  nzNumberFormatter1 = (value: number):string => { return this.nf(value, 1);}

  nf(value: number | string, fractionSize: number = 2): string {
    if (value == undefined) return ""; // undef
    if ((typeof value) === "number" && isNaN(value as number)) return "NaN";
    if (value === "") return "";
    if (typeof value == "string") value = this.nfparse(value);

    let [ integer, fraction = "" ] = (value).toString().split(".");

    if (!this.DECIMAL_SEPARATOR) {
      if(this.bas.envtest) console.log("this.DECIMAL_SEPARATOR == null, this: " + this);
    }
   
    fraction = fractionSize > 0
      ? this.DECIMAL_SEPARATOR + (fraction + PADDING).substring(0, fractionSize)
      : "";

    integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, this.THOUSANDS_SEPARATOR);

    return integer + fraction;
  }

  nfparse(value: string, fractionSize: number = 2): number {
    let val = (value || "") + "";
    let [ integer, fraction = "" ] = val.split(this.DECIMAL_SEPARATOR);

    integer = integer.replace(new RegExp(this.THOUSANDS_SEPARATOR, "g"), "");

    fraction = parseInt(fraction, 10) > 0 && fractionSize > 0
      ? "." + (fraction + PADDING).substring(0, fractionSize)
      : "";

    return parseFloat(integer + fraction);
  }

  actrans(key:string, input:any|any[] = [ ], safe:boolean = false, fallback?:string): any { //(string | SafeHtml)
    let ac = this.bas.ds.config.appConfig;
    let keystring = key; // this.bas.envprod ? "" : "" + key; // this.bas.envprod ? "" : "" + key;

    let opts = Array.isArray(input) ? {
      params: input,
      safe: safe,
      fallback: fallback
    } : input;

    let params = opts.params || [];
    safe = opts.safe;
    fallback = opts.fallback;

    let lang =  this.bas.ds.findLang() || this.bas.ds.lang.code || "no";
    if (!fallback) fallback = keystring;

    // console.log("key: " + key 
    //   + ", lang: " + lang
    //   + ", ac.m: " + (ac ? ac.messagesTrust : "null")
    //   + "; ac: ", ac);
    
    if (!ac) return fallback;

    var messages = safe ? ac.messagesTrust : ac.messages;
    if (!messages) return fallback;

    // console.log("messages: ", messages); 

    var texts = messages[key];

    // console.log("texts: ", texts); 

 
    if (!texts) return fallback;
    var trans = texts[lang];
    if (!trans) {
      var altLang = lang == "no" ? "en" : "no";
      trans = texts[altLang];
    }
    // console.log("trans: '" + trans + "'");
    
    if (params.length) {
      for (let idx = 0; idx < params.length; idx++) {
        let value = params[idx];
        let text:string = safe ? trans.changingThisBreaksApplicationSecurity : trans;
        text = text.replace(new RegExp("\\{"+idx+"\\}", "g"), value);
        trans = safe ?  this.sanitizer.bypassSecurityTrustHtml(text) : text;
      }
    }

    if (opts.lowercase) {
      let text:string = safe ? trans.changingThisBreaksApplicationSecurity : trans;
      text = text.toLowerCase();
      trans = safe ?  this.sanitizer.bypassSecurityTrustHtml(text) : text;

    }


    return trans;
  }
  messagetrans(message:any, params:any[] = [ ], safe:boolean = false, fallback?:string): any {
    
    let lang = this.bas.ds.lang.code || "no";
    if (!fallback) fallback = "";

    if (!message || !message.texts) return fallback;
    if (safe) message = this.bas.ds.getMessageTrust(message);
    var texts = message.texts;

    if (!texts) return fallback;
    var trans = texts[lang];
    if (!trans) {
      var altLang = lang == "no" ? "en" : "no";
      trans = texts[altLang];
    }
    // console.log("trans: '" + trans + "'");
    
    if (params.length) {
      for (let idx = 0; idx < params.length; idx++) {
        let value = params[idx];
        let text:string = safe ? trans.changingThisBreaksApplicationSecurity : trans;
        text = text.replace(new RegExp("\\{"+idx+"\\}", "g"), value);
        trans = safe ?  this.sanitizer.bypassSecurityTrustHtml(text) : text;
      }
    }

    return trans;
  }

  getRouterPrefixCommands():any[] {
    return this.getRouterPrefix().split("/");
  }
  getRouterPrefix(lang?:string, cid?:string):string {
    if (lang === undefined) lang =  this.bas.ds.findLang();
    if (this.bas.settings.appId == "cbsite" ) {
      let isCustomDomain = this.isCustomHostname(); 
      if (!cid) cid = this.bas.ds.findCid('ui.service.getRouterPrefix.cbsite');
      // if(this.bas.envtest) console.log("getRouterPrefix, isCustomDomain: " + isCustomDomain + ", cid: " + cid + ", hn: " + (this.bas.nossr ? window.location.hostname : "cloudbooking.io"));
      return  (!isCustomDomain && cid ? "/booking/" + cid : "") + "/" + lang;
    }


    //let cid = this.bas.ds.getValue(DATAID.APP_URL_PARAMS).cid;

    let pn = this.bas.nossr ? window.location.pathname : "";
    return pn.startsWith("/api/") ? "/api/" + (this.bas.ds.findCid('ui.service.getRouterPrefix') || "_") : "";
  }
  isCustomHostname() {
    let isCustomDomain = false;
    if (this.bas.settings.appId == "cbsite" ) {
      let hn = this.bas.nossr ? window.location.hostname : "cloudbooking.no";
      isCustomDomain = (this.bas.envprod )  // && hn.indexOf("cloudbooking.no") < 0 | Prod er alltid "custom"
        || (this.bas.envdev && hn.indexOf("192.168.1.81") < 0) 
        || (this.bas.envtest && !this.bas.envdev && hn.indexOf("cloudbooking.io") < 0) 
      
      ;
    }
    return isCustomDomain;
  }




  trustUrl(url:string):SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  dateToIsoString(date:Date):string {
    return formatISO(date); //, 'yyyy-MM-dd[T]HH:mm:ssZZ')
  }
  isoStringToDate(dateString:string):Date {
    return parseISO(dateString); 
  }

  dateToString(date:Date):string {
    if (date == undefined) return "";
    return format(date, 'yyyy-MM-dd')
  }
  stringToDate(date:string):Date|undefined {
    return parse(date, 'yyyy-MM-dd', new Date());
  }

  dateRangeToString(dates:Date[]):string {
    if (dates == undefined || dates.length < 1) return "_";
    let from = dates[0];
    let to =  dates[1];

    return this.dateToString(from) + "_" +  this.dateToString(to);
  }
  dateTimeRangeToString(dates:Date[]):string {
    if (dates == undefined || dates.length < 1) return "_";
    let from = dates[0];
    let to =  dates[1];

    return format(from, 'yyyy-MM-dd HH:mm') + "_" +  format(to, 'yyyy-MM-dd HH:mm');
  }


  dateFormat(date:Date, dateFormat:string = 'yyyy-MM-dd') {
    if (date == undefined) return undefined;
    try {
      return format(date, dateFormat);
    }catch (err) {
      console.log("dateFormat, err: ", err);
      return "";
    }

  }
  timeFormat(date:Date, timeFormat:string = 'HH:mm') {
    if (date == undefined) return undefined;
    return format(date, timeFormat);
  }
  dateTimeFormat(date:Date, timeFormat:string = 'yyyy-MM-dd HH:mm') {
    if (date == undefined) return undefined;
    return format(date, timeFormat);
  }

  dateParse(dateString:string, dateFormat?:string, refDate:Date = new Date()) {
    if (dateFormat == undefined) {
      if (dateString.indexOf(" ") && dateString.length == 16) dateFormat = 'yyyy-MM-dd HH:mm';
      else dateFormat = 'yyyy-MM-dd';
    }
    return parse(dateString, dateFormat, refDate);
  }
  dateTimeParse(dateString:string, dateFormat:string = 'yyyy-MM-dd HH:mm', refDate:Date = new Date()) {
    return parse(dateString, dateFormat, refDate);
  }
  timeParse(timeString:string, dateFormat:string = 'HH:mm', refDate:Date = new Date()) {
    return parse(timeString, dateFormat, refDate);
  }

  dateFormatIso(date:Date):string {
    return formatISO(date); //, 'yyyy-MM-dd[T]HH:mm:ssZZ')
  }
  dateParseIso(dateString:string):Date {
    return parseISO(dateString); 
  }

  getCurrentYear() {
    return new Date().getFullYear();
  }


  dateAdd(date:Date = new Date(), duration:Duration) {
    return add(date, duration);
  }
  dateSet(date:Date = new Date(), values:Duration) {
    return set(date, values);
  }
  //  set(new Date(2014, 8, 20), { year: 2015, month: 9, date: 20 })

  differenceInDays(from:Date = new Date(), to:Date = new Date()) {
    return differenceInDays(from, to);
  }
  intervalToDuration(interval:Interval) {
    return intervalToDuration(interval);
  }

  
  fixNewline(text:any) {
    return text && typeof text === "string" ? text.replace(/\n/g, "<br/>") : text;
  }

  setTitle(title:string) {
    this.title.setTitle(title);
  }
  getTitle() {
    return this.title.getTitle();
  }

  getAllDatesBwtweenDates(from:Date, to:Date): Date[] {
    let dates:Date[] = [];

    let current = from;

    while (current.getTime() < to.getTime()) {
      dates.push(current);
      current = addDays(current, 1);
    }
    //console.log("from: " + from + ", to: " + to + ", current: " + current);

    return dates;
  }


  toContentList(source:any, paths:any, options:any = {}):BtContent[] {
    let rows:BtContent[] = [];
    options = options || { };

    for (let path of Object.keys(paths)) {
      let value:any = undefined;
      if (MiscUtil.hasOwnNestedProperty(source, path)) {
        value = MiscUtil.getOwnNestedProperty(source, path);
      }
      let obj = paths[path];
      if (obj.value !== undefined) value = obj.value; 

      if (value === undefined) {
        if(this.bas.envtest) console.log("value === undefined, path: " + path);
        continue;
      }

      if (typeof obj === "string") obj = { label: obj };

      let type = obj.type || "string";
      if (type == "double") value = this.bas.ui.nf(value);
      else if (type == "email" && value) value = '<a href="mailto:'+value+'">'+value+'</a>';
      else if (type == "telephone" && value) {
     
        let label = value + "";
        let val = label.replace(/ /g, "");

        // if(this.bas.envtest) console.log("value: " +value + ", val: " + val + ", label: " + label);
        if (val.length == 8 || val.startsWith("+")) {
          if (val.length == 8) {
            val = "+47" + val;
          }

          value = '<a href="tel:'+val+'">'+label+'</a>';
        }
      }

      if (options.skipEmpty && (value === "" || value === 0)) continue;

      obj.value = value; 

      rows.push(new BtContent(obj));
    }

    return rows;
  }

  toContentListObject(object:any, options:any = {}):BtContent[] {

    let rows:BtContent[] = [];
    options = options || { };

    for (let key of Object.keys(object)) {
      let obj = object[key];
      
      let value:any = undefined;

      if (typeof obj === "string") {
        value = obj;
        obj = { label: key };

      }

      let type = obj.type || "string";
      if (type == "double") value = this.bas.ui.nf(value);
      else if (type == "email" && value) value = '<a href="mailto:'+value+'">'+value+'</a>';
      else if (type == "telephone" && value) {
        //TODO: ta bort mellomrom og legge på +47 forran om nødvendig. Hva med kommaseparert?
        let label = value + "";
        let val = label.replace(/ /g, "");

        if(this.bas.envtest) console.log("value: " +value + ", val: " + val + ", label: " + label);
        if (val.length == 8 || val.startsWith("+")) {
          if (val.length == 8) {
            val = "+47" + val;
          }

          value = '<a href="tel:'+val+'">'+label+'</a>';
        }

        
      }

      if (options.skipEmpty && (value === "" || value === 0)) continue;

      obj.value = value; 

      rows.push(new BtContent(obj));
    }
    return rows;
  }

  getTelephoneHref(value:any) {
    if (!value || typeof value != "string") return "";

    let val = value.replace(/ /g, "");

    // if(this.bas.envtest) console.log("value: " +value + ", val: " + val + ", label: " + label);
    if (val.length == 8 || val.startsWith("+")) {
      if (val.length == 8) {
        val = "+47" + val;
      }

      value = val;
    }

    return "tel:" + val;

  }


}
