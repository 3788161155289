<div *ngIf="cart">
 


  <a
    nz-popover
      nzPopoverTitle="Handlevogn"
      nzPopoverTrigger="click"
      [(nzPopoverVisible)]="popoverVisible"
      [nzPopoverContent]="popoverTemplate"
  >
  <!--    
      (nzPopoverVisibleChange)="change($event)"
   
    -->
    <nz-badge [nzCount]="cart.items?.length">
      <i nz-icon nzType="shopping-cart" nzTheme="outline"></i>
      Handlevogn
    </nz-badge>
  </a>
  <ng-template #popoverTemplate>
    <div *ngIf="!cart.items?.length">
      Handlevognen er tom
    </div>

    <div *ngIf="cart.items?.length">
      <div> <button nz-button nzType="default" nzDanger nzSize="small" (click)="emptyCart()">Tøm handlevogn</button>
      </div>
      <div> <button nz-button nzType="primary" nzSize="small" (click)="gotoCheckout()">Gå til kassen</button>
      </div>
    
    </div>

  </ng-template>


    
</div>
