<bt-page-wrapper>
<div class="m-d">

  <!-- <nz-radio-group [(ngModel)]="ctx.type" nzButtonStyle="solid">
    <label 
      *ngFor="let pt of ctx.productTypes" 
      nz-radio-button 
      [nzValue]="pt.id"
      class=""
      >
      {{ pt.mkName }}
    </label>
  </nz-radio-group> -->


  <div nz-row class="types-wrapper" [nzGutter]="4">
    <div 
      nz-col 
      [nzXs]="8" 
      [nzLg]="4"
      *ngFor="let pt of ctx.productTypes" 
      >
      <div
        style="border-radius: 5px;"
        class="type-button text-center p-1 cursor-pointer"
        [class.active]="pt.id == ctx.type"
        (click)="ctx.type = pt.id"
        >

        {{ pt.mkName }}
      </div>

      <!-- <button 
      nz-button
      nzBlock
    >
      {{ pt.mkName }}
    </button> -->

    </div>
  </div>

  <div class="m-d"></div>

  <div nz-row [nzGutter]="apps.bas.acs.productsGrid.gutter" nzAlign="top" class="product-grid">
    <div 
      nz-col 
      [nzXs]="apps.bas.acs.productsGrid.xs" 
      [nzSm]="apps.bas.acs.productsGrid.sm" 
      [nzMd]="apps.bas.acs.productsGrid.md" 
      [nzLg]="apps.bas.acs.productsGrid.lg" 
      [nzXl]="apps.bas.acs.productsGrid.xl" 
      [nzXXl]="apps.bas.acs.productsGrid.xxl"
      
      *ngFor="let p of (ctx.productsMap[ctx.type] || [])"
      >

      <div class="cell">
        <a 
          [href]="p.urlFull" target="_blank"
          class="cell-img cursor-pointer" 
          [style.background-image]="'url(' + p.firstImageUrlMedium + ')'"
          (click)="apps.bas.acs.productsOnClick($event, ctx, p)"
          >

        </a>
        <h2 class="header" nz-typography nzEllipsis >{{ p.mkName }}</h2>
        <p class="lead">{{ p.mkIngress }}</p>


        <div nz-row [nzGutter]="apps.bas.ui.gridMd.gutter" nzAlign="top">
          <div 
            nz-col 
            [nzXs]="apps.bas.ui.gridMd.xs">
        
            <a [href]="p.urlFull" target="_blank" nz-button nzBlock nzType="primary" (click)="apps.bas.acs.productsOnClick($event, ctx, p)">{{ 'Mer informasjon og bestilling'}}</a>
          </div>
        </div>
      </div>

    </div>

  </div> 
</div>
</bt-page-wrapper>
