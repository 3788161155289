import { Pipe, PipeTransform, Injector } from "@angular/core";
import { Inject } from '@angular/core';
import { SafeHtml } from "@angular/platform-browser";
import { AppService } from "src/app/services/app.service";
import { BooktechAppService } from "../services/booktech-app.service";


/*
*/
@Pipe({name: 'messagetrans'})
export class MessageTransPipe implements PipeTransform {

  constructor(injector:Injector, public apps:AppService) {

    
  }


  transform(key: any, params:any[] = [ ], safe: boolean = false, fallback?:string):  (string | SafeHtml) {
    //Log.l("value.type: " + typeof value + ", value: ", value);
    
    return this.apps.bas.ui.messagetrans(key, params, safe, fallback);
  }
}
