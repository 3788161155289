import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MiscUtil } from '../../../../lib/core/util/misc.util';

import { AppService } from '../../../../services/app.service';

@Component({
  selector: 'app-widget-products-root-page',
  templateUrl: './widget-products-root-page.component.html',
  styleUrls: ['./widget-products-root-page.component.scss']
})
export class WidgetProductsRootPageComponent implements OnInit {

  route:ActivatedRoute;
  ctx:any = {
    queryParams: { },
    products: []
  };

  view:string = "grid"
  contentView:string = "default"
  		
  constructor(public injector:Injector, public apps:AppService) {
    this.route = injector.get(ActivatedRoute);
    this.apps.bas.us.checkActivatedRoute(this.route.snapshot);

    if (this.apps.bas.envtest) console.log("WidgetProductsRootPageComponent");

    this.ctx.queryParams = this.route.snapshot.queryParams;
    this.view = this.ctx.queryParams.view || this.view;
    this.contentView = this.ctx.queryParams.contentView || this.contentView;

    this.ctx.missingImgUrl = this.apps.bas.ws.getBaseUrlStatic() + '/img/misc/missing_image_400x300.png';
	
    let eps:any = { };
    if (this.contentView == "prohemsedal") {
      eps.jdp = JSON.stringify({
        includeProductProperties: {
          website_text: true,
          preheader: true,
          virtual_viewing_link: true
        }
      });
    }
    this.apps.bas.acs.productsGet(this.ctx, eps);
  }

  ngOnInit(): void {
    
  } 

}
