import { Component, Input, OnInit } from '@angular/core';

import { AppService } from '../../../../../services/app.service';
import { DATAID } from '../../../services/data.service';

@Component({
  selector: 'btlib-order-product-cart',
  templateUrl: './order-product-cart.component.html',
  styleUrls: ['./order-product-cart.component.scss']
})
export class OrderProductCartComponent implements OnInit {

  @Input() pw:any;
  
  constructor(public apps:AppService) {

  }

  ngOnInit(): void {

  }

}
