

<div *ngIf="pw">


    <!--
      <mbsc-form  [options]="apps.bas.ui.msFormOptions" ></mbsc-form> 
     mbsc-form label-styel="floating" input-style="outline"
    -->

    <!-- <mbsc-form  [options]="apps.bas.ui.msFormOptions" >
      
    [formGroup]="validateForm" (ngSubmit)="submitForm()"

    vertical

    background-color: var(--bgc, red);
    -->


      <form nz-form [nzLayout]="'vertical'" class="" >
        <div nz-row  [nzGutter]="16">
          <div nz-col nzSpan="8" class="gutter-row" style="">
          
        <nz-form-item>
          <nz-form-label  class="" nzFor="">Velg dato</nz-form-label>
          <nz-form-control nzErrorTip="Velg dato!">
           
            <!-- <nz-input-group nzPrefixIcon="calendar">
              <input  nz-input placeholder="Dato"
               mbsc-datepicker
              [mbscOptions]="{ controls: ['calendar'],select:'range' }" />
            </nz-input-group> -->

            <nz-input-group nzPrefixIcon="calendar">
              <input  nz-input placeholder="Dato"
              [(ngModel)]="pw.startDateAsJsDate"
              name="startDateAsJsDate"
               mbsc-datepicker
               [controls]="['calendar']"
               select="date"
               [invalid]="dateInvalid"
           
               
               (onPageLoading)="dateOnPageLoading($event)"
               (onChange)="dateOnChange($event)"
               />
            </nz-input-group>
            <!--
              (onOpen)="dateOnOpen($event)"
              , touchUi: true
            -->

          </nz-form-control>
        </nz-form-item>
      </div>

      <div nz-col nzSpan="8" class="gutter-row"  *ngIf="pw.productObj.timeProduct">
        <nz-form-item>
          <nz-form-label nzRequired nzFor="time">Tidspunkt</nz-form-label>
          <nz-form-control nzErrorTip="Velg tid">
            <nz-select *ngIf="pw.timeItemsAvailable?.length > 1" [(ngModel)]="pw.timePeriodAsString" name="timePeriodAsString"  nzPlaceHolder="Velg tidspunkt" >
              <nz-option *ngFor="let ti of pw.timeItemsAvailable" [nzValue]="ti.timePeriod" [nzLabel]="ti.label"></nz-option>
            </nz-select>
            <label *ngIf="pw.timeItemsAvailable?.length <= 1" >
              <span>
                {{ pw.timePeriodAsString }}
              </span>
            </label>
    
          </nz-form-control>
        </nz-form-item>
      </div>

      <div nz-col nzSpan="8" class="gutter-row">
        <nz-form-item>
          <nz-form-label class="" nzFor="">Gjester</nz-form-label>
          <nz-form-control>
            <button nz-button nzBlock nz-dropdown nzTrigger="hover"  [nzDropdownMenu]="guestsPopup" (nzVisibleChange)="guestsPopupVisibleChange($event)" class="text-left">
              <!-- <span>Gjester:</span>  -->
              <span class="text-lg-off"> {{ getGcs() }}</span>
              <!-- <i nz-icon nzType="down"></i> -->
            </button>
            <nz-dropdown-menu #guestsPopup="nzDropdownMenu">
  
              <div nz-menu>
                <div class="p-3">
  
                  <div *ngIf="pw.productObj.guestProduct" >
                    <div *ngFor="let gcMk of pw.guestCategories; index as gcIdx">
                      <div class="grid grid-cols-2 gap-4" *ngIf="pw.productObj.maxGcMap[gcIdx] !== 0">
                        <div> <nz-form-label  [nzFor]="'gc' + gcIdx">{{ gcMk }}</nz-form-label></div>
                        <div>
                          <nz-space [nzSize]="'small'">
                            <button *nzSpaceItem nz-button nzType="primary" nzShape="circle" (click)="decrementGcValue(gcIdx)" ><i nz-icon nzType="minus-circle"></i></button>
                            <input *nzSpaceItem nz-input [(ngModel)]="pw.guestCounts.map[gcIdx]" [name]="'gc' + gcIdx" class="text-center" style="width: 50px;" />
                            <button *nzSpaceItem nz-button nzType="primary" nzShape="circle" (click)="incrementGcValue(gcIdx)" ><i nz-icon nzType="plus-circle"></i></button>
                          </nz-space>

                        </div>
                      </div>
                      <!-- <nz-form-item *ngIf="pw.productObj.maxGcMap[gcIdx] !== 0">
                        <nz-form-label  [nzFor]="'gc' + gcIdx">{{ gcMk }}</nz-form-label>
                        <nz-form-control nzErrorTip="">
                          <nz-space [nzSize]="'small'">
                            <button *nzSpaceItem nz-button nzType="primary" nzShape="circle" (click)="decrementGcValue(gcIdx)" ><i nz-icon nzType="minus-circle"></i></button>
                            <input *nzSpaceItem nz-input [(ngModel)]="pw.guestCounts.map[gcIdx]" [name]="'gc' + gcIdx" class="text-center" style="width: 50px;" />
                            <button *nzSpaceItem nz-button nzType="primary" nzShape="circle" (click)="incrementGcValue(gcIdx)" ><i nz-icon nzType="plus-circle"></i></button>
                          </nz-space>
                        </nz-form-control>
                      </nz-form-item> -->
                    </div>
                  </div>
  
                  <!-- <button class="py-2 px-3 m-2 bg-blue-500 hover:bg-nav-hover-blue rounded-md text-white">Click Me!</button> -->

                </div>
              </div>
  
            </nz-dropdown-menu>
          </nz-form-control>
        </nz-form-item>
      </div>

      </div>
      </form>


    </div>


    <!-- <form nz-form class="mt-20" >
  
    


      <nz-form-item>
        <nz-form-label [nzSm]="size.lbl.sm" [nzXs]="size.lbl.xs" nzRequired nzFor="startDate">Dato</nz-form-label>
        <nz-form-control [nzSm]="size.ctrl.sm" [nzXs]="size.ctrl.xs"  nzErrorTip="Velg dato">
          <nz-date-picker
            nzFormat="yyyy-MM-dd"
            [nzDisabledDate]="dateDisabledDate"
            nzAllowClear="false"
            [(ngModel)]="pw.startDateAsJsDate"
            (ngModelChange)="startDateModelchange($event)"
            name="startDateAsJsDate"
            class="w100-off"
          ></nz-date-picker>
   
        
        </nz-form-control>
      </nz-form-item>
  
      <nz-form-item *ngIf="pw.productObj.timeProduct">
        <nz-form-label [nzSm]="size.lbl.sm" [nzXs]="size.lbl.xs" nzRequired nzFor="time">Tidspunkt</nz-form-label>
        <nz-form-control [nzSm]="size.ctrl.sm" [nzXs]="size.ctrl.xs" nzErrorTip="Velg tid">
          <nz-select *ngIf="pw.timeItemsAvailable?.length > 1" [(ngModel)]="pw.timePeriodAsString" name="timePeriodAsString" style="width: auto;" nzPlaceHolder="Velg tidspunkt" >
            <nz-option *ngFor="let ti of pw.timeItemsAvailable" [nzValue]="ti.timePeriod" [nzLabel]="ti.label"></nz-option>

          </nz-select>
          <label *ngIf="pw.timeItemsAvailable?.length <= 1" >
            <span>
              {{ pw.timePeriodAsString }}
            </span>
          </label>
  
        </nz-form-control>
      </nz-form-item>
  
        
      <div *ngIf="pw.productObj.guestProduct">
        <div *ngFor="let gcMk of pw.guestCategories; index as gcIdx">
          <nz-form-item *ngIf="pw.productObj.maxGcMap[gcIdx] !== 0">
            <nz-form-label [nzSm]="size.lbl.sm" [nzXs]="size.lbl.xs"  [nzFor]="'gc' + gcIdx">{{ gcMk }}</nz-form-label>
            <nz-form-control [nzSm]="size.ctrl.sm" [nzXs]="size.ctrl.xs" nzErrorTip="">
              <nz-space [nzSize]="'small'">
                <button *nzSpaceItem nz-button nzType="primary" nzShape="circle" (click)="decrementGcValue(gcIdx)" ><i nz-icon nzType="minus-circle"></i></button>
                <input *nzSpaceItem nz-input [(ngModel)]="pw.guestCounts.map[gcIdx]" [name]="'gc' + gcIdx" class="text-center" style="width: 50px;" />
                <button *nzSpaceItem nz-button nzType="primary" nzShape="circle" (click)="incrementGcValue(gcIdx)" ><i nz-icon nzType="plus-circle"></i></button>
              </nz-space>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>


    </form> -->
  
  
  



    <!-- <nz-form-item>
        <nz-form-label [nzSm]="size.lbl.sm" [nzXs]="size.lbl.xs" nzRequired nzFor="startDate">Periode</nz-form-label>
        <nz-form-control [nzSm]="size.ctrl.sm"  [nzXs]="size.ctrl.xs" nzErrorTip="Velg dato">
        
          <nz-range-picker
            [nzDisabledDate]="rangeDisabledDate"
            nzFormat="yyyy-MM-dd"
            [(ngModel)]="range"
            name="range"
            (nzOnCalendarChange)="rangeCalendarChange($event)"
            (ngModelChange)="rangeModelchange($event)"
          ></nz-range-picker>
  
        </nz-form-control>
      </nz-form-item> -->
  
  
      <!--
  
  if (p.periodsProduct && $cb.obj.pw.productPeriods) {
  
  else if (p.dateOrTimeProduct) {
  
    if (settings.preselectDate) $(".startDateAsStringDatepicker", ctx).datepicker("update", $cb.obj.pw.startDateAsString);
                          else {
                              if ($("#startDateAsString", ctx).val() == "") {
                                  hasStartDate = false;
                              }
                          }
                          
                          if (p.timeProduct) {
  
                              var select = $("#timeItem", ctx);
                              select.empty();
                              
                              var fgTimeItem = $(".fg-timeItem", ctx);
                              var fgTimeItemStatic = $(".fg-timeItem-static", ctx);
                              
                              $cb.hide(fgTimeItem);
                              $cb.hide(fgTimeItemStatic);
                              
                              var tis = $cb.obj.pw.typeWeb ? $cb.obj.pw.timeItemsAvailable : $cb.obj.pw.timeItems;
                              if (tis) {
                
                  $.each(tis, function(idx, ti) {
                                      select.append('<option value="'+ti.timePeriod+'">'+ti.label+'</option>');
                                  });
                                  if ($cb.obj.pw.timePeriodAsString != "") select.val($cb.obj.pw.timePeriodAsString);
                                  
                                  $("#timeItemStatic", fgTimeItemStatic).html($cb.obj.pw.timePeriodDisplay);
                                  
                  if (tis.length != 1 || (tis.length == 1 && $cb.obj.pw.timePeriodAsString != tis[0].timePeriod)) $cb.show(fgTimeItem);
                                  else $cb.show(fgTimeItemStatic);
                                  
                              }
                }
              }
  
     else if (p.periodProduct) {
  
     }
  
  
      if (p.guestProduct) {
  
      -->


