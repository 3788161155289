import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MiscUtil } from 'src/app/lib/core/util/misc.util';

import { AppService, APP_DATAID } from '../../../../../services/app.service';
import { BtContent } from '../../../model/bt-content';
import { DATAID } from '../../../services/data.service';

@Component({
  selector: 'btlib-order-cancel-modal',
  templateUrl: './order-cancel-modal.component.html',
  styleUrls: ['./order-cancel-modal.component.scss']
})
export class OrderCancelModalComponent  implements OnInit {

  // order:any = { }

  bis:any[] = [ ];
  sumAmount = 0;

  reason:string = "";
  df:any = {
    loading: false,
    status: undefined
  };


  @Input() 
  set input(input: any) {
    if (!input) {
      this.bis = [];
      return;
    }
    

    let c = this.apps.bas.ds.config.company;

    if (input.bis) {
      this.bis = input.bis;
      this.sumAmount = this.bis.map(bi => bi.amountDouble).reduce((a, b) => a + b, 0);
      return;
    }
  }

  @Output() change = new EventEmitter<any>();

  
  constructor(public apps:AppService) {
  
    
  }

  ngOnInit(): void {

  }

  closeModal() {
    this.bis = [ ];
    this.sumAmount = 0;
    this.reason = "";
  }


  cancelAndRefund() {
    let bis = this.bis;

    console.log("reason: " + this.reason);
    // console.log("r.length: " + r.length);
    console.log("bis: ", bis);


    if (this.reason == "") {
      this.df.status = "error";
      return;
    }

    this.df.loading = true;
    this.df.status = undefined;

    this.apps.bas.ws.json( {
      aType: "appAdmin",
      action: "cancelAndRefund",
      biids: bis.map((bi) => bi.id).join(","),
      reason: this.reason,
      testRun: false
     } , { checkSuccess: false }).then((json:any) => {

      this.df.loading = false;
      if(this.apps.bas.envtest) console.log("cancelAndRefund.then, json: ", json);

      if (json.errors.length == 0) {
        this.closeModal();
   
        this.change.next({ type: "cancelAndRefund", bis: bis });
  
      } else {
        this.apps.bas.ui.modalError(
          this.apps.bas.ui.actrans("common.error"), "" + json.errors.map((obj:any) => obj.bi.bid + ": " + obj.bi.id + ": " + obj.message).join("<br/>") );
      }
 
      // if (json.statusList?.length) {

      //   this.apps.bas.ui.modalInfo("Info", "" + json.statusList.filter((obj:any) => obj.message).join("") ).afterClose.subscribe(() => {
      //     if(this.apps.bas.envtest) console.log("cancelAndRefund.then, onClose");
      //     this.closeModal();
   
      //     this.change.next({ type: "cancelAndRefund", bis: bis });
      //   });
         
      // }

    

     });

  }

}
