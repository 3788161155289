import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PendingChangesGuard } from './lib/core/guards/pending-changes.guard';

// Root
import { RootPageComponent } from './pages/root-page/root-page.component';


// Widget
import { WidgetOrderProductsPageComponent } from './pages/widget/order/widget-order-products-page/widget-order-products-page.component';
import { WidgetOrderViewRootPageComponent } from './pages/widget/order/view/widget-order-view-root-page/widget-order-view-root-page.component';
import { WidgetEventCalendarPageComponent } from './pages/widget/widget-event-calendar-page/widget-event-calendar-page.component';
import { WidgetOrderProductPageComponent } from './pages/widget/widget-order-product-page/widget-order-product-page.component';
import { WidgetTestPageComponent } from './pages/widget/widget-test-page/widget-test-page.component';


// Admin
import { AdminRootPageComponent } from './pages/admin/admin-root-page/admin-root-page.component';
import { AdminLinksPageComponent } from './pages/admin/admin-links-page/admin-links-page.component';

// Admin order
import { AdminOrderRootPageComponent } from './pages/admin/order/admin-order-root-page/admin-order-root-page.component';
import { AdminOrderOrderPageComponent } from './pages/admin/order/admin-order-order-page/admin-order-order-page.component';
import { AdminOrderOverviewPageComponent } from './pages/admin/order/admin-order-overview-page/admin-order-overview-page.component';

// Admin product
import { AdminProductsPageComponent } from './pages/admin/admin-products-page/admin-products-page.component';
import { AdminProductPageComponent } from './pages/admin/admin-product-page/admin-product-page.component';

import { AdminProductProductTypesPageComponent } from './pages/admin/product/admin-product-product-types-page/admin-product-product-types-page.component';
import { AdminProductPropertyTypesPageComponent } from './pages/admin/product/admin-product-property-types-page/admin-product-property-types-page.component';
import { AdminProductAreasPageComponent } from './pages/admin/product/admin-product-areas-page/admin-product-areas-page.component';

// Admin misc
import { AdminReportsPageComponent } from './pages/admin/misc/admin-reports-page/admin-reports-page.component';
import { AdminStatsPageComponent } from './pages/admin/misc/admin-stats-page/admin-stats-page.component';

import { AdminTasksPageComponent } from './pages/admin/misc/admin-tasks-page/admin-tasks-page.component';
import { AdminMiscSessionStatusPageComponent } from './pages/admin/misc/admin-misc-session-status-page/admin-misc-session-status-page.component';
import { AdminDiscountCodesPageComponent } from './pages/admin/misc/admin-discount-codes-page/admin-discount-codes-page.component';
import { AdminToolsPageComponent } from './pages/admin/misc/admin-tools-page/admin-tools-page.component';

// SU
import { SuperuserPropertiesPageComponent } from './pages/superuser/superuser-properties-page/superuser-properties-page.component';



// Common
import { CommonEmptyPageComponent } from './pages/common/common-empty-page/common-empty-page.component';
import { CommonUserUserPageComponent } from './pages/common/user/common-user-user-page/common-user-user-page.component';


// App
import { AppBileasyLeadRootPageComponent } from './pages/app/bileasy/lead/app-bileasy-lead-root-page/app-bileasy-lead-root-page.component';
import { AppBileasyAdminRootPageComponent } from './pages/app/bileasy/admin/app-bileasy-admin-root-page/app-bileasy-admin-root-page.component';
import { AppBileasyAdminProfilePageComponent } from './pages/app/bileasy/admin/app-bileasy-admin-profile-page/app-bileasy-admin-profile-page.component';
import { AppBileasyProvidersPageComponent } from './pages/app/bileasy/app-bileasy-providers-page/app-bileasy-providers-page.component';

import { AppOpplevkrageroProductsPageComponent } from './pages/app/opplevkragero/app-opplevkragero-products-page/app-opplevkragero-products-page.component';

import { AppTestTest1PageComponent } from './pages/app/test/app-test-test1-page/app-test-test1-page.component';
import { AdminUserUsersPageComponent } from './pages/admin/user/admin-user-users-page/admin-user-users-page.component';
import { AdminUserUserPageComponent } from './pages/admin/user/admin-user-user-page/admin-user-user-page.component';
import { AppSpacebookingOrderPageComponent } from './pages/app/spacebooking/app-spacebooking-order-page/app-spacebooking-order-page.component';
import { AppSpacebookingAdminPageComponent } from './pages/app/spacebooking/app-spacebooking-admin-page/app-spacebooking-admin-page.component';

import { AppRefugeeUserRoomForRentPageComponent } from './pages/app/refugee/user/app-refugee-user-room-for-rent-page/app-refugee-user-room-for-rent-page.component';
import { ProviderMiscGiftCardPageComponent } from './pages/provider/misc/provider-misc-gift-card-page/provider-misc-gift-card-page.component';
import { ProviderMiscElectricityBillingPageComponent } from './pages/provider/misc/provider-misc-electricity-billing-page/provider-misc-electricity-billing-page.component';
import { AgentMiscOrderOverviewPageComponent } from './pages/agent/misc/agent-misc-order-overview-page/agent-misc-order-overview-page.component';
import { WidgetProductsRootPageComponent } from './pages/widget/products/widget-products-root-page/widget-products-root-page.component';

import { AppTasksTasksPageComponent } from './pages/app/tasks/app-tasks-tasks-page/app-tasks-tasks-page.component';
import { AppTasksAddPageComponent } from './pages/app/tasks/app-tasks-add-page/app-tasks-add-page.component';


//

const routesCommon: Routes = [

  {
    path: '',
    component: RootPageComponent
  },


  {
    path: 'widget',
    children: [
      {
        path: 'test',
        component: WidgetTestPageComponent
      },
      {
        path: 'order-product',
        component: WidgetOrderProductPageComponent
      },
      {
        path: 'event-calendar',
        component: WidgetEventCalendarPageComponent
      },

      {
        path: 'order',
        children: [
          {
            path: 'view',
            children: [
              {
                path: '',
                component: WidgetOrderViewRootPageComponent
              }
            ]
          },

          {
            path: 'products',
            component: WidgetOrderProductsPageComponent
          },
          { path: '**', redirectTo: 'products', pathMatch: 'full' }
        ]
      },

      {
        path: 'products',
        children: [
          {
            path: '',
            component: WidgetProductsRootPageComponent
          }
        ]
      },

      { path: '**', redirectTo: 'test', pathMatch: 'full' }
    ]
  },
  {
    path: 'admin',
    children: [
      {
        path: '',
        component: AdminRootPageComponent
      },
      {
        path: 'products',
        component: AdminProductsPageComponent
      },
      {
        path: 'product/:id',
        component: AdminProductPageComponent,
        canDeactivate: [ PendingChangesGuard ] 
      },
      {
        path: 'orders',
        component: AdminOrderRootPageComponent
      },
      {
        path: 'order/:id',
        component: AdminOrderOrderPageComponent
      },
      {
        path: 'order-overview',
        component: AdminOrderOverviewPageComponent
      },
      {
        path: 'areas',
        component: AdminProductAreasPageComponent
      },
      {
        path: 'productTypes',
        component: AdminProductProductTypesPageComponent
      },
      {
        path: 'propertyTypes',
        component: AdminProductPropertyTypesPageComponent
      },
      {
        path: 'links',
        component: AdminLinksPageComponent
      },
      {
        path: 'stats',
        component: AdminStatsPageComponent
      },
      {
        path: 'reports',
        component: AdminReportsPageComponent
      },
      {
        path: 'misc',
        children: [
          {
            path: 'tasks',
            component: AdminTasksPageComponent
          },    
          {
            path: 'discount-codes',
            component: AdminDiscountCodesPageComponent
          },
          {
            path: 'session-status',
            component: AdminMiscSessionStatusPageComponent
          },
          {
            path: 'tools',
            component: AdminToolsPageComponent
          },

        ]
      },
      {
        path: 'user',
        children: [
          {
            path: 'users',
            component: AdminUserUsersPageComponent
          },
          {
            path: 'user',
            component: AdminUserUserPageComponent,
            canDeactivate: [ PendingChangesGuard ] 
          },
        ]
      }

     

    ]
  },

    
  {
    path: 'agent',
    children: [
      {
        path: 'misc',
        children: [
          {
            path: 'order-overview',
            component: AgentMiscOrderOverviewPageComponent, 
          },
 
        ]
      }
      
    ]
      
  },
  
  {
    path: 'provider',
    children: [
      {
        path: 'misc',
        children: [
          {
            path: 'gift-card',
            component: ProviderMiscGiftCardPageComponent, 
          },
          {
            path: 'electricity-billing',
            component: ProviderMiscElectricityBillingPageComponent, 
          },
        ]
      }
      
    ]
    
  },
  
  {
    path: 'superuser',
    children: [

      {
        path: 'properties',
        component: SuperuserPropertiesPageComponent, 
      },
  
    
      
    ]
      
  },
  
  
  {
    path: 'common',
    children: [
      {
        path: 'empty',
        component: CommonEmptyPageComponent
      },

      {
        path: 'user',
        children: [
          {
            path: 'user',
            component: CommonUserUserPageComponent,
            canDeactivate: [ PendingChangesGuard ] 
          },
        ]
      }
      
    ]
      
  },


  {
    path: 'app',
    children: [
      // BilEasy start
      {
        path: 'bileasy',
        children: [
          {
            path: 'lead',
            children: [
              {
                path: '',
                component: AppBileasyLeadRootPageComponent
              },
            ]
          },
          {
            path: 'admin',
            children: [
              {
                path: '',
                component: AppBileasyAdminRootPageComponent
              },
              {
                path: 'profile',
                component: AppBileasyAdminProfilePageComponent
              },
            ]
          },
          {
            path: 'providers',
            component: AppBileasyProvidersPageComponent
          },
          { path: '**', redirectTo: 'lead', pathMatch: 'full' }
        ]
      },
      // BilEasy end

       // Opplevkragero start
       {
        path: 'opplevkragero',
        children: [
          {
            path: 'products',
            component: AppOpplevkrageroProductsPageComponent        
          },
        ]
      },
      // Opplevkragero end

      // Spacebooking start
      {
        path: 'spacebooking',
        children: [
          {
            path: 'order',
            component: AppSpacebookingOrderPageComponent
          },
          {
            path: 'admin',
            component: AppSpacebookingAdminPageComponent
          },
        ]
      },
      
      // Spacebooking end

      
      // Refugee start
      {
        path: 'refugee',
        children: [
          // {
          //   path: 'admin',
          //   children: [
          //     {
          //       path: 'xxxxx',
          //       component: AppSpacebookingOrderPageComponent
          //     },
          //   ]
          // },
          {
            path: 'user',
            children: [
              {
                path: 'room-for-rent',
                component: AppRefugeeUserRoomForRentPageComponent
              },
            ]
          },
        ]
      },
      
      // Refugee end

       // Tasks start
       {
        path: 'tasks',
        children: [
          {
            path: 'tasks',
            component: AppTasksTasksPageComponent        
          },
          {
            path: 'add',
            component: AppTasksAddPageComponent        
          },
        ]
      },
      // Tasks end


       // Test start
       {
        path: 'test',
        children: [
          {
            path: 'test1',
            component: AppTestTest1PageComponent        
          },
        ]
      },
      // Test end
    ]
  }

];


const routes: Routes = [
  {
    path: '',
    component: RootPageComponent
  },
  ...routesCommon,

  { // Nei, vi kan heller lage <dest>.cbapi5.booktech.no
    // /api/nesbyen/admin/order/ADD514
    path: 'api/:cid', // booking, destination, company, api ?? 
    children: routesCommon
  },

  { path: '**', redirectTo: '/', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
