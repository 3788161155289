import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { MiscUtil } from 'src/app/lib/core/util/misc.util';

import { AppService } from 'src/app/services/app.service';

import { FormGroup } from '@angular/forms';
import { DynformControl, DynformControlsMap } from 'src/app/lib/core/model/dymform-control';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-admin-tasks-page',
  templateUrl: './admin-tasks-page.component.html',
  styleUrls: ['./admin-tasks-page.component.scss']
})
export class AdminTasksPageComponent implements OnInit {

  dirty = false;

  fbo:any = {
    loading: true,
    stats: {
      current: [],
      navigatorObj: {
        size: 0,
        pageSize: 10,
        page: 0
      }
    }

  };

  edit:any = {
    obj: undefined,
    loading: false,

    item: {
      id: -1,
      obj: undefined,
    },
    user: {
      id: -1,
      obj: undefined,
    }
  }


  customFilters:any = { }
  df:any = {
    form: undefined,
    controls: [],
    obj: undefined,
    params: { 
      // 
    },
    statsForm: { 
      statsType: "TASK",
      dateMode: "START",
      currentJil: "min"
    },

    loading: false
  };
  
  // https://192.168.1.82:8200/api/nesbyen/admin/misc/tasks
  constructor(public injector:Injector, public apps:AppService) {
    let route = injector.get(ActivatedRoute);
    this.apps.bas.us.checkActivatedRoute(route.snapshot);

    if(this.apps.bas.envtest) console.log("AdminTasksPageComponent");

    // this.apps.bas.aas.getProducts();
    // this.getObjects();
  }

  ngOnInit(): void {

  }

  // getObjects() {
  //   this.apps.bas.ws.json({ aType: "appAdmin", action: "getObjects", type: "task" }).then((json) => {
  //     if (json.success) {
  //       if(this.apps.bas.envtest) console.log("getObjects: ", json);

  //       this.objects = json.objects || [];
  //       this.fbo = json.fbo || { };
  //     }
  //   });
  // }


  onQueryParamsChange(queryParams: NzTableQueryParams): void {
    if (this.apps.bas.envtest) console.log("onQueryParamsChange: ", queryParams);
    const { pageSize, pageIndex, sort, filter } = queryParams;
    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || null;
    const sortOrder = (currentSort && currentSort.value) || null;

    let params:any = { };
    
    let page = pageIndex !== undefined && pageIndex > 0 ? pageIndex -1 : undefined;
    let nav = this.fbo.stats.navigatorObj;
    if ((page !== undefined && nav.page != page )) { 
      params.navAction = "page";
      params.navParam = page;
    } else if ((pageSize !== undefined && nav.pageSize != pageSize )) { 
      params.navAction = "pageSize";
      params.navParam = pageSize;
    }

    
    let sfi:any = { }
    if (sortField) {
      sfi.customSortsList = [
     
        { field: sortField, asc: sortOrder === "ascend" }
      ];

    }

    if (filter) {
      sfi.customFiltersMap = { };
      for (let item of filter) {
        if (item.value == null || item.value.length == 0) continue;
   
        sfi.customFiltersMap[item.key] = { value: item.value };

      }
    }


    this.updateFbo(params, sfi);
  }

  updateFbo(input:any = { }, statsFormInput:any = { }) {
    let params:any = {
      aType: "appAdmin",
      action: "getStatsFbo",
      page: "tasks",
    };

    let rv = { };
    if (this.df.form) {
      rv = this.apps.bas.fs.getRawValue(this.df.form, this.df.controls);
      if (rv === false) return;
  
    }

    statsFormInput.customFiltersMap = statsFormInput.customFiltersMap || { };
    for (let key in this.customFilters) {
      let val = this.customFilters[key];
      if (val == undefined || val == "") continue; 
      statsFormInput.customFiltersMap[key] = { value: val, type: "search" };
    }

    MiscUtil.extend(rv,  statsFormInput);


    MiscUtil.extend(params, input);

    let statsForm = MiscUtil.extend({ }, this.df.statsForm);
    MiscUtil.extend(statsForm, rv);

    MiscUtil.extend(params, this.df.params);
    MiscUtil.extend(params, { statsForm: JSON.stringify(statsForm) } );

    if (this.apps.bas.envtest) console.log("updateFbo.params: ", params);

    this.fbo.loading = true;
    this.apps.bas.ws.json(params ).then((json:any) => {
      if (this.apps.bas.envtest) console.log("updateFbo.then, json: ", json);

      //TODO: må lagre dssse verdiene lokalt slik at man ikke må stille inn hver gang siden lastes.

      if (json.success) {
        this.updateForm(json.stats);

        this.fbo = json;
        
      } else {

      }
      

     });
  }

  
  updateForm(stats:any) {

    let controls: DynformControl[] = [];

    // controls.push(new DynformControl({ key: 'statsType', value: "USER" }));
    
    
    controls.push(new DynformControl({ key: 'search',               mk: 'common.search' }));
    // controls.push(new DynformControl({ key: 'currentJil', value: "max" }));
    
    
    
    controls.push(new DynformControl({ 
      key: 'product',  
      // valuePath: "ruleId",
      mk: 'common.product',  
      controlType: 'select', 
      // required: true,
      options: () => {
        return stats.products || []; 
      },
      optionsAllowEmpty: true,
      optionsFieldValue: "id",
      optionsFieldLabel: "mkName"
    }));

    // let roles = stats.roles;
    // if (this.apps.bas.envtest) console.log("roles: ", roles);

    controls.push(new DynformControl({ 
      key: 'taskType',  
      valuePath: "taskTypeLabel",
      mk: 'common.type',  
      controlType: 'select', 
      // required: true,
      options: () => {
        return stats.taskTypes; 
      },
      optionsAllowEmpty: true,
      optionsFieldValue: "enumName",
      optionsFieldLabel: "enumName"
    }));

    
    controls.push(new DynformControl({ 
      key: 'taskStatus',  
      valuePath: "taskStatusLabel",
      mk: 'common.status',  
      controlType: 'select', 
      // required: true,
      options: () => {
        return stats.taskStatuss || []; 
      },
      optionsAllowEmpty: true,
      optionsFieldValue: "enumName",
      optionsFieldLabel: "enumName"
    }));



    //TODO: period-nedtreksboks: sist uke, osv

    /*
predef: 
statsType: USER
period: ONE_MONTH
fromAsString: 2022-01-21 00:00
toAsString: 2022-02-21 00:00
dateMode: TIME
groupBy: NONE
sort: TIME
sortMode: DESC
sort_2: NONE
sortMode_2: ASC
sort_3: NONE
sortMode_3: ASC
statuss: 0
statuss: 2
sm1: SHOW
provider: 
product: 
productTagsInclude: 
productTagsExclude: 
productSubType: 
viName: 
showSettled: ALL
showDoSettle: ALL
showSaved: YES
bookingTag: 
biTag: 
search: 
username: 
    */


    this.apps.bas.fs.updateFormObj(this.df, controls, stats);


  }

  dfSubmit() {
    this.updateFbo();
  }


  newObject() {


    this.editObject(this.fbo.newTask || {  enabled: true, items: [] });  //TODO: bruke mal fra server 
  }

  copyObject(src:any) {
    let obj = MiscUtil.clone(src);
    obj.id = ""; //this.edit[type].id--;
    obj.items.map((item:any) => item.id = this.edit.item.id--)
    obj.users.map((user:any) => user.id = this.edit.user.id--)

    this.editObject(obj); 
  }


  editObject(obj:any) {

    if(this.apps.bas.envtest) console.log("editObject, obj: ", obj);

    let controls: DynformControl[] = [];

    controls.push(new DynformControl({ key: 'enabled',     mk: 'common.enabled', controlType: "checkbox" }));
    controls.push(new DynformControl({ key: 'booking', valuePath: "bookingObj.bid",     mk: 'common.booking', controlType: "label" }));
    controls.push(new DynformControl({ key: 'bi', valuePath: "biObj.label",     mk: 'bus.bookingItem', controlType: "label" }));
    
    controls.push(new DynformControl({ 
      key: 'title',     
      mk: 'common.name',
      required: true
    }));


    controls.push(new DynformControl({ 
      key: 'body', 
      controlType: 'textarea', 
      mk: 'common.description'
    }));


    controls.push(new DynformControl({ 
      key: 'type',  
      valuePath: "typeLabel",
      mk: 'common.type',  
      controlType: 'select', 
      required: true,
      options: () => {
        return this.fbo.stats.taskTypes || []; 
      },
      optionsAllowEmpty: true,
      optionsFieldValue: "enumName",
      optionsFieldLabel: "enumName"
    }));

    controls.push(new DynformControl({ 
      key: 'status',  
      valuePath: "statusLabel",
      mk: 'common.status',  
      controlType: 'select', 
      required: true,
      options: () => {
        return this.fbo.stats.taskStatuss || []; 
      },
      optionsAllowEmpty: true,
      optionsFieldValue: "enumName",
      optionsFieldLabel: "enumName"
    }));



    controls.push(new DynformControl({ 
      key: 'product',  
      // valuePath: "statusId",
      mk: 'common.product',  
      controlType: 'select', 
      // required: true,
      options: () => {
        return this.fbo.stats.products || []; // this.apps.bas.ds.getValue('APP_ADMIN_PRODUCTS').products || []; 
      },
      optionsAllowEmpty: true,
      optionsFieldValue: "id",
      optionsFieldLabel: "mkName"
    }));


    if (obj.items?.length) {
      let idx = 0;

      obj.items.map((i:any) => {
        i.sortOrder = idx++
       
      });
    }


    /*

    */

    this.apps.bas.fs.updateFormObj(this.edit, controls, obj);
  } 

  onEditCancel() {
    this.edit.obj = undefined;
  }
  async onEditOk() {
    if (this.edit.item.obj) {
      this.saveItem(this.edit.item.obj);
    }

    let rv = this.apps.bas.fs.getRawValue(this.edit.form, this.edit.controls);
    if (rv === false) return;
    

   
    let obj = this.edit.obj;
    let isNew = !obj.id;

    let items:any[] = [];
    obj.items.map((i:any) => {
      items.push(MiscUtil.copyKeys(i, [
        "id",
        "sortOrder",
        // "optionsAsString",
        "optionsListAsString",
        "inputType",
        "title",
        "status",
        "required"
      ]))
    });
  

    rv.items = items; 
    //MiscUtil.extend(obj, rv)

    if(this.apps.bas.envtest) console.log("onEditOk, obj: ", obj);
    if(this.apps.bas.envtest) console.log("onEditOk, rv: ", rv);

    this.edit.loading = true;

    let params:any = {
      aType: "appAdmin", 
      action: "saveObject", 
      type: "task",
      id: isNew ? "new" : obj.id,
      isNew: isNew,
      object: JSON.stringify(rv)
    }

    await this.apps.bas.ws.json(params,  { showLoading: true }).then((json) => {
      if (json.success) {
        this.updateFbo();

        // if (isNew) this.objects = [...this.objects, json.object];
        // else {
        //   let itemIndex = this.objects.findIndex(obj => obj.id == json.object.id);
        //   this.objects[itemIndex] =  json.object;
        //   this.objects = [...this.objects];
        // }
      }
    });
    
    this.edit.loading = false;
    
    this.edit.obj = undefined;
  }


  onChange(event:any) {
    if (!event.control) return;
    this.dirty = true;

  }

  newItem() {
    let item = MiscUtil.clone(this.edit.obj.newItem);
    if (item.id == "") item.id = this.edit.item.id--;
    
    
    this.edit.obj.items = this.edit.obj.items || [];

    item.sortOrder = this.edit.obj.items.length == 0 ? 0 : Math.max(...this.edit.obj.items.map((item:any) => item.sortOrder)) + 1;

    if(this.apps.bas.envtest) console.log("newItem, sortOrder: "+item.sortOrder+", obj: ", this.edit.obj);

    // item.sortOrder = item.sortOrder === -Infinity ? 0 : item.sortOrder + 1;
  

    this.edit.obj.items = [...this.edit.obj.items, item];
    
    if(this.apps.bas.envtest) console.log("newItem, obj: ", this.edit.obj);
   
    this.editItem(item);
  }

  deleteItem(item:any, idx:number) {
    if(this.apps.bas.envtest) console.log("deleteItem, idx: "+idx+", item: ", item);


    this.edit.obj.items = this.edit.obj.items.filter((itm:any, i:number) => i != idx);
    
    this.edit.item.obj = null;
  }
  saveItem(item:any) {
   
    let formValue = this.apps.bas.fs.getRawValue(this.edit.item.form, this.edit.item.controls);
    if(this.apps.bas.envtest) console.log("saveItem, item: ", formValue);

    if (formValue === false) return false;


    MiscUtil.extend(item, formValue);
    item.statusObj = this.apps.bas.us.listToMap(this.fbo.stats.taskStatuss, "enumName")[formValue.status];
    item.inputTypeObj = this.apps.bas.us.listToMap(this.fbo.stats.taskInputTypes, "enumName")[formValue.inputType];
    
    item.isFinished = formValue.status == "Finished"
    item.isFailed = formValue.status == "Failed"

    let items:any[] = this.edit.obj.items;
    let currentIdx = items.findIndex((elem:any) => elem.id === item.id);

    if (currentIdx < 0) items.push(item);
    else items[currentIdx] = item;

    // if(this.apps.bas.envtest) console.log("saveItem, items: ", items);

    this.edit.obj.items = items;

    // if(this.apps.bas.envtest) console.log("saveItem, item: ", item);

    if(this.apps.bas.envtest) console.log("saveItem, obj: ", this.edit.obj);

    this.edit.item.obj = null;

    return true;
  }

  editItem(item:any) {
    if (item.id == this.edit.item.obj?.id) {
      this.edit.item.obj = null;
      return;
    }

    if(this.apps.bas.envtest) console.log("editItem, item: ", item);

    let controls: DynformControl[] = [];

    controls.push(new DynformControl({ 
      key: 'title',     
      mk: 'common.title',
      required: true
    }));

    controls.push(new DynformControl({ 
      key: 'required',     
      mk: 'common.required',
      controlType: 'checkbox'
      // required: true
    }));

    controls.push(new DynformControl({ 
      key: 'optionsListAsString',     
      mk: 'common.options',
      controlType: 'select', 
      selectMode: 'tags',
      options: () => {
        return item.options?.options || []; 
      },
      show: (control:DynformControl) => {
        return this.edit.item.form.controls.inputType.value == "Select";
      },
      optionsFieldValue: "value", 
      optionsFieldLabel: "value"
    })); 


    controls.push(new DynformControl({ 
      key: 'inputType',  
      valuePath: "inputTypeLabel",
      mk: 'bus.productInput.type',  
      controlType: 'select', 
      required: true,
      options: () => {
        return this.fbo.stats.taskInputTypes || []; 
      },
      optionsAllowEmpty: true,
      optionsFieldValue: "enumName",
      optionsFieldLabel: "mkName"
    }));

    controls.push(new DynformControl({ 
      key: 'status',  
      valuePath: "statusLabel",
      mk: 'common.status',  
      controlType: 'select', 
      required: true,
      options: () => {
        return this.fbo.stats.taskStatuss || []; 
      },
      optionsAllowEmpty: true,
      optionsFieldValue: "enumName",
      optionsFieldLabel: "enumName"
    }));



    this.apps.bas.fs.updateFormObj(this.edit.item, controls, item);
  }

  cdkDropListDroppedItem(event: CdkDragDrop<string[]>) {
    if (this.apps.bas.envtest) console.log("cdkDropListDropped, event: ", event);
    this.edit.item.obj = null;

    let array = this.edit.obj.items;
    let prev = array[event.previousIndex];
    let curr = array[event.currentIndex];
    
    let prevOrder = prev.sortOrder;

    if (this.apps.bas.envtest) console.log("cdkDropListDropped, prev ("+prevOrder+"->"+curr.sortOrder+"): " + prev.title);
    if (this.apps.bas.envtest) console.log("cdkDropListDropped, curr ("+curr.sortOrder+"->"+prevOrder+"): " + curr.title);


    // prev.sortOrder = curr.sortOrder;
    // curr.sortOrder = prevOrder; 

    if (this.apps.bas.envtest) console.log("cdkDropListDropped, array: " + array.map((i:any) => i.title).join(", "));
    

    moveItemInArray(array, event.previousIndex, event.currentIndex);
    
    let idx = 0; 
    array.map((i:any) => i.sortOrder = idx++);


    if (this.apps.bas.envtest) console.log("cdkDropListDropped, array: " + array.map((i:any) => i.title).join(", "));
    


    this.edit.obj.items = [...array];
  }

}
