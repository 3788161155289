import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AppService } from 'src/app/services/app.service';
import { MiscUtil } from 'src/app/lib/core/util/misc.util';

@Component({
  selector: 'app-admin-root-page',
  templateUrl: './admin-root-page.component.html',
  styleUrls: ['./admin-root-page.component.scss']
})
export class AdminRootPageComponent implements OnInit {

  route: ActivatedRoute;

  constructor(public injector:Injector, public apps:AppService) {
    this.route = injector.get(ActivatedRoute);
    this.apps.bas.us.checkActivatedRoute(this.route.snapshot);

    if(this.apps.bas.envtest) console.log(MiscUtil.getLogText("AdminRootPageComponent, route.snapshot: ")); 

    this.apps.bas.aas.getOrderStats().then((stats) => {
        

    });

   }

  ngOnInit(): void {
  }

}
