

<btlib-admin-page-wrapper [showMenu]="false">

  <div class="mx-d">
   
    
    <div *ngIf="df.obj">

      <nz-collapse>
        <nz-collapse-panel [nzHeader]="'Filter'"  [nzActive]="apps.bas.ui.r.gtemd">

          <form nz-form [formGroup]="df.form" class="item-form mt-d" nzLayout="vertical" [nzAutoTips]="apps.bas.fs.autoTips" (ngSubmit)="dfSubmit()"  >
  
            <btlib-form-row [controls]="[ df.controls.productType,  df.controls.productSubType ]" [form]="df.form"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row> 
            <btlib-form-row [controls]="[ df.controls.provider, df.controls.area ]" [form]="df.form"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row> 
            <btlib-form-row [controls]="[ df.controls.productQuery, df.controls.calSize ]" [form]="df.form"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row> 
            <btlib-form-row [controls]="[ 
              df.controls.showPoolUnits, 
              df.controls.showEmptyResources, 
              df.controls.showAccumulated  , 
              df.controls.calEventList
            ]" [form]="df.form"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row> 
    
            <button nz-button nzType="primary"> {{ 'common.show' | actrans }}  </button>
    
          </form>

        </nz-collapse-panel>
      </nz-collapse>

    </div>


  </div>
  <div [class]="'cal-container'" [class.event-count-1]="myResources.length == 1" [attr.data-cal-size]="calSizeCalc">
    <mbsc-eventcalendar 
      #myEventcalendar
      [data]="myEvents" 
      [resources]="myResources"
      [options]="eventSettings" 
      [view]="view"
      (onPageLoading)="onPageLoading($event)"

      ></mbsc-eventcalendar>
    
  </div>

  <btlib-order-info-modal [order]="orderInfo" (change)="onOrderInfoChange($event)" ></btlib-order-info-modal>
  <btlib-orders-list-modal [input]="orderListInput" (change)="onOrderListChange($event)" ></btlib-orders-list-modal>

  <!--  -->
  <nz-modal 
    [(nzVisible)]="newEvent.obj" 
    [nzTitle]="'common.new' | actrans | str" 
    [nzWidth]="apps.bas.ui.r.gtemd ? '90vw' : '98vw'"  
    [nzFooter]="null"
    (nzOnCancel)="closeNewEventModal()" 
    (nzOnOk)="closeNewEventModal()"
    >
    <ng-container *nzModalContent>
    
      <form nz-form [formGroup]="newEvent.form" (ngSubmit)="newEventSubmit()"  class="item-form mt-d" nzLayout="vertical" [nzAutoTips]="apps.bas.fs.autoTips" >
  


        <h2>{{ 'web.common.overview.availabilityOrder' | actrans }}: {{ newEvent.pw?.product }}</h2>
        <div nz-row [nzGutter]="apps.bas.ui.gridMd.gutter" nzAlign="top">

     

          <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="12" [nzLg]="12" [nzXl]="8" [nzXXl]="6">
            <bt-dynamic-form-control [control]="newEvent.controls.period" [form]="newEvent.form" ></bt-dynamic-form-control>

          </div>
          <div nz-col [nzXs]="16" [nzSm]="12" [nzMd]="6" [nzLg]="6" [nzXl]="4" [nzXXl]="4">
            <bt-dynamic-form-control [control]="newEvent.controls.availability" [form]="newEvent.form" ></bt-dynamic-form-control>
          </div>
          <div nz-col [nzXs]="8" [nzSm]="12" [nzMd]="6" [nzLg]="6" [nzXl]="4" [nzXXl]="3">
            <nz-form-item>
              <nz-form-label >
                <div>
                {{ 'common.vacant' | actrans }}: 
                <span>{{ newEvent.pw?.available }}</span>
                <span *ngIf="newEvent.form.controls.availability.value">
                  + 
                  {{ newEvent.form.controls.availability.value }}
                  = 
                  <strong>{{ ((newEvent.pw?.available || 0) + (newEvent.form.controls.availability.value | int) ) }}</strong>
                </span>
              </div>
              </nz-form-label>
              <nz-form-control>
                <nz-button-group class="w-100">
                    <button nz-button  nzType="primary" nzSize="default" style="flex-grow: 2;"> {{ 'common.save' | actrans }}  </button>
           
                    <button nz-button nzType="default" nzSize="default" (click)="$event.preventDefault(); newEvent.adv = !newEvent.adv; " > <i nz-icon nzType="setting" class="" ></i> </button>
                </nz-button-group>
                
                
              </nz-form-control>
            </nz-form-item>
           
          </div>

        </div>
        <div *ngIf="newEvent.adv">
          <btlib-form-row [controls]="[ newEvent.controls.notes ]" [form]="newEvent.form"  [grid]="apps.bas.ui.gridXl"  ></btlib-form-row> 
        </div>


        <!-- -->


       
      </form>

    </ng-container>
  </nz-modal>

</btlib-admin-page-wrapper>
