<btlib-admin-page-wrapper [showMenu]="false">

  <div class="mx-d">
    
    <div *ngIf="apps.bas.ds.login.success" class="text-right">
      Du er innlogget som {{ apps.bas.ds.login.user.username }}
      <button nz-button nzSize="small" class="ml-d" (click)="apps.bas.api.logout()">{{ 'app.lib.common.logout' | actrans  }} </button>
    
    </div>

    <h2>{{ 'Løs inn gavekort' | actrans }}</h2>

    <div *ngIf="df.obj">
    
      <form nz-form [formGroup]="df.form" class="item-form mt-d" nzLayout="vertical" [nzAutoTips]="apps.bas.fs.autoTips" (ngSubmit)="dfSubmit()"  >

        <div nz-row [nzGutter]="apps.bas.ui.gridMd.gutter" nzAlign="top">
          <div nz-col [nzXs]="24" [nzSm]="18" [nzMd]="18" [nzLg]="12" [nzXl]="8" [nzXXl]="6">
            <bt-dynamic-form-control [control]="df.controls.giftCardCode" [form]="df.form" ></bt-dynamic-form-control>
            
          </div>
          <div nz-col [nzXs]="24" [nzSm]="6" [nzMd]="6">
            <div class="bt-form-label-placeholder"></div>
            <button nz-button nzType="default" (click)="getBalance(); $event.preventDefault();"> {{ 'Sjekk saldo' | actrans }}  </button>
            <span *ngIf="df.balance !== undefined">
              Saldo: {{ df.balance | nf }}
            </span>
          </div>

        </div>

        <btlib-form-row [controls]="[ 
          df.controls.amountAsDouble ,
          df.controls.comment
        
        ]" [form]="df.form"  [grid]="apps.bas.ui.gridXl"  ></btlib-form-row>
        
        <button nz-button nzType="primary" [nzLoading]="processing"> {{ 'Løs inn gavekort' | actrans }}  </button>
  
        <div style="height: 50px;"></div>
      </form>
     
    </div>
  

    
  </div>

</btlib-admin-page-wrapper>

