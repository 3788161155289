
<pinch-zoom disableZoomControl="disable" [limitPan]="true" backgroundColor="transparent">
<div class="image-map-container" [ngStyle]="getContainerStyle()">
	<img [src]="bg.img" />
  <div class="image-map-item-container">
    <div 
      class="map-item" 
      [class.disabled]="item.disabled"
      [ngStyle]="getItemStyle(item)"
      [title]="item.name" 
      *ngFor="let item of items" 
      (click)="onItemClick(item)" 
 
      ></div>
  
  </div>
</div>
</pinch-zoom>

<!-- <div *ngIf="apps.bas.envdev && selectedItem">

  <nz-descriptions class="mt-d"
    nzTitle="ITEM"
    nzBordered
    [nzColumn]="1"
    nzSize="small"
  >

    <nz-descriptions-item nzTitle="left"><div class="text-right text-nowrap">{{ selectedItem.coords.left | nf }}</div></nz-descriptions-item>
    <nz-descriptions-item nzTitle="top"><div class="text-right text-nowrap">{{ selectedItem.coords.top | nf }}</div></nz-descriptions-item>
    <nz-descriptions-item nzTitle="width"><div class="text-right text-nowrap">{{ selectedItem.coords.width | nf }}</div></nz-descriptions-item>
    <nz-descriptions-item nzTitle="height"><div class="text-right text-nowrap">{{ selectedItem.coords.height | nf }}</div></nz-descriptions-item>
    <nz-descriptions-item nzTitle="rotation"><div class="text-right text-nowrap">{{ (selectedItem.coords.rotation || 0) | nf }}</div></nz-descriptions-item>

  </nz-descriptions>
</div> -->
