import { Injector } from '@angular/core';
import { MiscUtil } from '../util/misc.util';
import { BooktechAppService } from './booktech-app.service';


export class AppCommonService {


  constructor(public injector:Injector, public bas: BooktechAppService ) {
    if (this.bas.envtest) console.log("AppCommonService.constructor");

   
  }


  productGet(id:number|string, extraParams:any = { }):Promise<any> {
    let params = {
      aType: "order", 
      action: "getProduct", 
      id: id
    }
    MiscUtil.extend(params, extraParams || { });

    return this.bas.ws.json(params).then((json) => {
      // if (this.bas.envtest) console.log("getProduct, json: ", json);
      if (json.success) {
        let p = json.product;
        this.updateProductObj(p);
        // return p;
      }
      return json;

    });
  }

  // products start
  productsGrid = { 
    xs: 24, 
    sm: 12, 
    md: 8, 
    lg: 8, 
    xl: 6,  
    xxl: 4,  
    gutter: [8, 16] 
  };
  
  updateProductObj(p:any, opts?:any) {
    opts = opts || { };
    let qps = opts.qps || { };
    let lang = opts.lang || this.bas.ds.findLang();
    if (p.url) p.urlFull = this.bas.ws.getBaseUrl(p.cid) + p.url + "?source=" +(qps.source || "cbapi5");


    let updateRoute = true;
    if (opts.checkCbsiteEnable) {
      let fc = this.bas.ds.config.appConfig?.companies?.[p.cid];
      // if (this.bas.envdev) console.log("updateProductObj, fc: " + fc && fc.upCbCbsiteEnabled );
      if (fc && fc.upCbCbsiteEnabled === false) updateRoute = false;
      if (this.bas.envdev) updateRoute = true; // TODO
    }

    if ( updateRoute) {
     
      let typeName = p.findType  || p.typeObj?.mkName;
      let pName = p.mkName || p.name;

      let pInfo:any = "" + p.findSubType; // SubType og area   :  Property 'replaceAll' does not exist on type 'string'. Do you need to change your target library? Try changing the 'lib' compiler option to 'es2021' or later.
      if (p.findArea) pInfo += (pInfo ? " " : "") + p.findArea;
      if (!pInfo) pInfo = p.findType || p.typeObj.mkName;

      // TODO: erstte alle "ugylgdige" tegn, hjerte-tegn osv 
      if (typeName.indexOf("/")) typeName = typeName.replaceAll("/", " ");
      if (pInfo.indexOf("/")) pInfo = pInfo.replaceAll("/", " ");
      if (pName.indexOf("/")) pName = pName.replaceAll("/", " ");


      // TODO: hvis link fra cloudbooking.io, så skal vi linke til 
      if (this.bas.ui.isCustomHostname()) {
        p.cbsiteRoute = [ 
          "/" + lang
        ];
      } else {
        p.cbsiteRoute = [ 
          "/booking",
          p.cid,
          lang
        ];
      }
      p.cbsiteRoute = [
        ...p.cbsiteRoute, 
        "p",
        typeName,
        pInfo,
        pName + "-" + p.id
      ]

      p.cbsiteHref = p.cbsiteRoute.join("/");

      
    }
  
    let imageList = [];
    for (let iid of p.images || []) {
      imageList.push(p.imageMap[iid]);
    }
    p.imageList = imageList;
    p.imageMap = undefined;
  

    if (p.pcCollection) {
      p.hideOrderForm = true;
    
      for (let cp of p.collectionRelations || [] ) this.updateProductObj(cp, opts);
    }

    if (p.daysDropdownMax) {
      
      p.findMinRange = p.daysDropdownMin;
      p.findMaxRange = p.daysDropdownMax;
      if (p.isStartBeforeEnd === false) {
        p.findMinRange++;
        p.findMaxRange++;
      }
    }

    let pc = p.productConfigObj;
    if (pc) {
      if (pc.isCbWidget) {
        switch (p.cbWidgetPath) {
          case "/order/form/kjerringtorget": 
            p.hideOrderForm = true;
            p.customProductView = "order-form-kjerringtorget";
            break;
        }
      } else if (pc.isRoute) {
        p.hideOrderForm = true;
        p.customProductView = "order-form-route";
        // p.customCartAddView = "order-form-route";
        
      }
    }



  }

  productsGet(ctx:any, extraParams:any = { }):Promise<any> {
    let params = {
      aType: "order", 
      action: "getProducts", 
    }

    if (this.bas.envtest) console.log("productsGet.ctx: ", ctx);

    let qps = ctx.queryParams || { };

    MiscUtil.extend(params, qps);
    MiscUtil.extend(params, extraParams || { });

    let lang = this.bas.ds.findLang();
    // let cid = this.bas.ds.findCid();


    return this.bas.ws.json(params).then((json) => {
      // if (this.bas.envtest) console.log("getProducts, json: ", json);
      if (json.success) {
        ctx.productTypes = json.productTypes || { };
        if (ctx.type === undefined) ctx.type = json.type || (ctx.productTypes ? ctx.productTypes[0].id : undefined);
        for (let p of  json.products || []) {
         this.updateProductObj(p, { lang,  qps });
        }
        ctx.products = json.products || [];

        

        for (let ptid of Object.keys(json.productsMap || { })) {
          for (let p of  json.productsMap[ptid] || []) {
            this.updateProductObj(p, { lang,  qps });
            // p.urlFull = this.bas.ws.getBaseUrl(p.cid) + p.url + "?source=" +(qps.source || "cbapi5");

          }
        }


        ctx.productsMap = json.productsMap || { };

      
        
      }

      return json;
    });
  }

  productsOnClick(event:any, ctx:any, p:any) {
    if (ctx.queryParams?.newWindow) {
      return;
    }


    event.preventDefault();
    if (this.bas.envtest) console.log("productClick: ", p);

    this.bas.es.sendParentMessageRedir(p.urlFull);
  }

  // products end

  


}
