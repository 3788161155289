<!-- <p>order-product-cart works!</p> -->


<nz-list class="">
  <nz-list-item>

      <nz-list-item-meta
        nzDescription="LINJE 1"
      >
        <nz-list-item-meta-title>
          Title: {{ pw.productObj.mkName }}
        </nz-list-item-meta-title>
        <!-- <nz-list-item-meta-description>
          Desc: {{ pw.productObj.mkIngress }}
        </nz-list-item-meta-description> -->
      </nz-list-item-meta>
      {{ pw.totalAmountDouble | nf }} <!-- amountDouble:  -->
      <!-- <ul nz-list-item-actions>
        <nz-list-item-action><a (click)="edit(item)">edit</a></nz-list-item-action>
        <nz-list-item-action><a (click)="edit(item)">more</a></nz-list-item-action>
      </ul> -->
  
  
  </nz-list-item>

</nz-list>

