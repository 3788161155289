import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MiscUtil } from 'src/app/lib/core/util/misc.util';

import { AppService, APP_DATAID } from '../../../../../services/app.service';
import { BtContent } from '../../../model/bt-content';
import { DATAID } from '../../../services/data.service';

import { DynformControl } from 'src/app/lib/core/model/dymform-control';

@Component({
  selector: 'btlib-email-modal',
  templateUrl: './email-modal.component.html',
  styleUrls: ['./email-modal.component.scss']
})
export class EmailModalComponent implements OnInit {

  // order:any = { }

  _input:any;
  recipients:string[] = [ ];
  manual = false;

  df:any = {
    form: undefined,
    controls: [],
    obj: undefined,

    processing: false
  }

  @Input() 
  set input(input: any) {
    this._input = input
    if (!input) {
      this.recipients = [];
      this.updateForm();
      return;
    }

    // let c = this.apps.bas.ds.config.company;
    // if (!c.upCmSms) {
    //   console.log("!c.upCmSms: ", c)
    //   this.recipients = [];
    //   return;
    // }


    if (input.manual) {
      this.manual = input.manual;
      this.updateForm();
      return;
    }


    if (input.recipients) {
      this.recipients = input.recipients.filter((mail:string) => mail);
      this.updateForm();
      // console.log("this.recipients: ", this.recipients);
      return;
    }

    // console.log("input: ", input)

    if (input.orders) {
      let orders:any[] = input.orders;
      this.recipients = orders.map((order:any) => order.userEmailAdress).filter(mail => mail); 
      this.updateForm();
      return;
    }
    if (input.bis) {
      let bis:any[] = input.bis;
      this.recipients = bis
        .map((order:any) => order.bookingObj?.userEmailAdress)
        .filter(mail => mail); 
      this.updateForm();
      return;
    }

    if (input.bids) {
      
      this.df.processing = true;
      this.apps.bas.ws.json( {
        aType: "appAdmin",
        action: "getEmailRecipients",
        bids: input.bids.join(","),
    
       } ).then((json:any) => {
        if(this.apps.bas.envtest) console.log("getEmailRecipients.then, json: ", json);
     
        
        this.df.processing = false;
        if (json.success && json.recipients) {
          this.recipients = json.recipients;
          this.updateForm();
        }
  
       }).catch((err) => {
        
        this.df.processing = false;
       });
  
    }
  }

  @Output() change = new EventEmitter<any>();

  
  constructor(public apps:AppService) {
  
    this.updateForm();
  }

  ngOnInit(): void {

  }

  closeModal() {
    this.recipients = [ ];
    this.manual = false;
  }

  updateForm(obj:any = { }) {

    let controls: DynformControl[] = [];

    

    if (this.manual) {
      controls.push(new DynformControl({ 
        key: 'recipients',   
        mk: 'Mottakere', 
        controlType: "select", 
        selectMode: "tags",
        value: this.recipients
      }));
    } else {

      controls.push(new DynformControl({ 
        key: 'recipients',   
        mk: 'Mottakere', 
        // controlType: "label", 
        disabled: true,
        value: this.recipients.join(", ")
      }));

      let input  = this._input;
      if (input?.orders?.length) {
        controls.push(new DynformControl({ 
          key: 'bids',   
          mk: 'bids', 
          // controlType: "label", 
          disabled: true,
          value: JSON.stringify(input.orders.map((order:any) => { return { bid: order.id, email: order.userEmailAdress }; }))
        }));
  
     ; 
      }

    }

    // controls.push(new DynformControl({ key: 'recipients',   mk: 'Mottakere', controlType: "label", value: this.recipients.join(", ") }));
    controls.push(new DynformControl({ key: 'subject',   mk: 'Emne', required: true }));
    controls.push(new DynformControl({ 
      key: 'message',   
      mk: 'common.message', 
      controlType: 'textarea', 
      required: true,
      data: {
        height:  150
      }
    }));

    this.apps.bas.fs.updateFormObj(this.df, controls, obj);


  }

  dfSubmit() {


    let rv = this.apps.bas.fs.getRawValue(this.df.form, this.df.controls);

    if (rv === false) return;

    // rv.biid = this.df.obj.id;

    if (this.apps.bas.envtest) console.log("formSubmit, rv: ", rv);

    this.df.processing = true;

    // String type = WebUtil.getParam(request, "type", "manual");
		// 	String recipients = WebUtil.getParam(request, "recipients");
		// 	String subject = WebUtil.getParam(request, "subject");
		// 	String message = WebUtil.getParam(request, "message");
    
    let params = MiscUtil.extend({ 
      aType: "admin", 
      action: "sendEmail"
    }, rv);



    if (this.apps.bas.envtest) console.log("formSubmit, params: ", params);

    this.apps.bas.ws.json(params).then((json) => {
      this.df.processing = false;

      if (this.apps.bas.envtest) console.log("dfSubmit, json: ", json);
      if (json.success) {
        this.apps.bas.ui.success("Epostene er sendt");
        
        this.df.form.reset();
        this.df.obj = undefined;
 
        this.change.next({ type: "sendEmail", recipients: this.recipients });
        this.closeModal();
      }
    }).catch(err => {
      this.df.processing = false;

    });


  }

}
