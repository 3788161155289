import { Injectable, Injector } from '@angular/core';
import { BooktechAppService } from '../lib/core/services/booktech-app.service';
import { MiscUtil } from '../lib/core/util/misc.util';

import { environment } from 'src/environments/environment';
import { APP_CONFIG_INIT } from 'src/environments/config.init';

export const APP_DATAID = {
  "ORDER_CART": "ORDER_CART",

  "ORDER_OVERVIEW_DATA": "ORDER_OVERVIEW_DATA"
  
}

const INIT_FROM_STORAGE:string[] = [

  // APP_DATAID.ORDER_CART,

];
const INIT_FROM_STORAGE_SESSION = [

  APP_DATAID.ORDER_CART,

];

@Injectable({
  providedIn: 'root'
})
export class AppService {
  
  bas:BooktechAppService;
  // route: ActivatedRoute;


  data:any = {
    messageTemplates: []
  }

  constructor(
    public injector:Injector
  ) {
   
    // this.route = injector.get(ActivatedRoute);
    this.bas = new BooktechAppService(injector, {
      apps: this,
      environment: environment,
      appConfig: APP_CONFIG_INIT,
      data: {
        // appInfo: {
        //   version: APP_VERSION_NAME,
        //   build: APP_VERSION_CODE
        // }
       } 
    });
    
    if(this.bas.envtest) console.log(MiscUtil.getLogText("AppService.constructor"));



    INIT_FROM_STORAGE.forEach((did) => {
      this.bas.ds.getFromStorage(did).then((res) => {
        if(this.bas.envtest) console.log(MiscUtil.getLogText('AppService.INIT_FROM_STORAGE: ' + did  + ' initialized, res: '), res);
      });
    })
    INIT_FROM_STORAGE_SESSION.forEach((did) => {
      this.bas.ds.getFromStorage(did, true).then((res) => {
        if(this.bas.envtest) console.log(MiscUtil.getLogText('AppService.INIT_FROM_STORAGE: ' + did  + ' initialized, res: '), res);
      });
    })


  }

}
