import { Injector } from '@angular/core';

import { Observable } from 'rxjs';
import { BooktechAppService } from './booktech-app.service';

export class ScriptService {

  private scripts: any = {};

  constructor(public injector:Injector, public bas:BooktechAppService) {
    // MiscUtil.log('ScriptService');

  }


  load(scripts: any[]) {
    var promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  loadScript(scriptObj: { name: string, src: string, id?:string }):Promise<any> {
      return new Promise((resolve, reject) => {
          //resolve if already loaded
          if (!this.scripts[scriptObj.name]) {
            this.scripts[scriptObj.name] = scriptObj;
          }
          if (this.scripts[scriptObj.name].loaded) {
              resolve({script: scriptObj.name, loaded: true, status: 'Already Loaded'});
          }
          else {
              //load script
              let script = this.bas.DOC.createElement('script');
              script.type = 'text/javascript';
              script.src = scriptObj.src;
              script.async = true;
              if (scriptObj.id) script.id = scriptObj.id;
     
              script.onload = () => {
                  this.scripts[scriptObj.name].loaded = true;
                  resolve({script: scriptObj.name, loaded: true, status: 'Loaded'});
              };
         
              script.onerror = (error: any) => resolve({script: scriptObj.name, loaded: false, status: 'Load Error'});
              this.bas.DOC.getElementsByTagName('head')[0].appendChild(script);
          }
      });
  }

  appendToHead(element:any) {

    this.bas.DOC.getElementsByTagName('head')[0].appendChild(element);

  }
 
}
