import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MiscUtil } from 'src/app/lib/core/util/misc.util';

import { AppService, APP_DATAID } from 'src/app/services/app.service';
import { BtContent } from 'src/app/lib/core/model/bt-content';
import { DATAID } from 'src/app/lib/core/services/data.service';

@Component({
  selector: 'btlib-orders-list-modal',
  templateUrl: './orders-list-modal.component.html',
  styleUrls: ['./orders-list-modal.component.scss']
})
export class OrdersListModalComponent implements OnInit {

  // order:any = { }

  orders:any[] = [ ];
  

  @Input() 
  set input(input: any) {
    if (!input) {
      this.orders = [];
      return;
    }
    if (input.orders) {
      this.orders = input.orders;
      return;
    }

    console.log("input: ", input)

    if (input.productId && input.period) {
      this.apps.bas.ws.json( {
        aType: "appAdmin",
        action: "getOrders",
        product: input.productId,
        period: input.period,
        jil: "all"
      } ).then((json:any) => {
        if(this.apps.bas.envtest) console.log("getOrders.then, json: ", json);

        this.updateContent( json.orders);
        this.orders = json.orders || [ ];
      

      });
      return;
    }

    if (input.biids) {
      this.apps.bas.ws.json( {
        aType: "booking",
        action: "getBis",
        biids: input.biids.join(","),
        jil: "all"
      } ).then((json:any) => {
        if(this.apps.bas.envtest) console.log("getBis.then, json: ", json);

        this.updateContent( json.bis);
        this.orders = json.bis || [ ];
      

      });
      return;
    }
  }

  @Output() change = new EventEmitter<any>();

  selected:any[] = []
  selectedAll = false;
  // isSomeSelected = false;

  smsInput:any = { };
  emailInput:any = { };
  cancelAndRefundInput:any = { };

  contentData:{ [index: string]:{ [index: string]:BtContent[] } } = { 
    s1: { s2:  [] }
  };

  sums:any = {
    show: false
   }
  
  constructor(public apps:AppService) {
  
    
  }

  ngOnInit(): void {

  }

  onSelected(event:any, bi:any) {
    console.log("onSelected, bi: ", bi,  ", event: ", event);

    if (bi === 'all') {

      this.selected = event ? [...this.orders ] : [];
      this.orders.filter((order:any) => order.selected = event);
      return;
    }

    bi.selected = event;
    this.selected = this.orders.filter((order:any) => order.selected);

    this.selectedAll = this.selected.length == this.orders.length;
  }

  onModalClose() {
    this.closeOrdersModal();
  }

  sendSms() {
    this.smsInput = {
      bis: this.selected
    };
  }
  sendEmail() {
    this.emailInput = {
      bis: this.selected
    };
  }

  cancelAndRefund() {
    this.cancelAndRefundInput = {
      bis: this.selected
    };
  }

  closeOrdersModal() {
    this.orders = [ ];
  }


  deleteOrder(o:any) {
    // let o = this.order;
    if (!(o && o.statusEnum?.isAvailability)) {
      return;
    }

    this.apps.bas.ws.json( {
      aType: "appAdmin",
      action: "deleteOrder",
      id: o.bookingId,
     } ).then((json:any) => {
      if(this.apps.bas.envtest) console.log("deleteOrder.then, json: ", json);
      // this.order = { };

      if (json.success) {
        this.orders = this.orders.filter(order => order.id != o.id);
      }
      this.change.next({ type: "delete", order: o });

     });

  }

  updateContent(orders:any[]) {

    if (!orders || !orders.length) {
      this.contentData = { };
      this.sums.show = false;
      return; 
    }



    let ordersSum = 0;
    let ordersCount = 0;
    for (let item of orders || []) {
      // orderSum += item.amountDouble; 

      let contentId = "item" + item.id;
      let contentObj:{ [index: string]:BtContent[] } = { };
      let itemObj = {
        "product": "common.product",
        "periodString": "common.period", 
        "updated": "common.updated",
        "mkStatus": { label: "common.status", css: 'bt-status ' + item.statusCssClass },
        "guests": "bus.booking.guests",
        "count": "common.count",
        "amountDouble": { label: "common.amount", type: "double" },
        // "": "common.",

      };


      contentObj["basic"] = this.apps.bas.ui.toContentList(item, itemObj);
      contentObj["order"] = this.apps.bas.ui.toContentList(item.bookingObj, {
        "user": "common.user",
        "userEmailAdress":  { label: "common.email", type: "email" },
        "userTelephone":  { label: "common.telephone", type: "telephone" },
        "comment": "common.comment",
        "notes": "common.notes",
      });
      
      if (item.inputValuesDisplay) {
        contentObj["inputValues"] = this.apps.bas.ui.toContentListObject(item.inputValuesDisplay);
      }



      ordersSum += item.amountDouble;
      ordersCount += item.count;

    //   this.contentData.s1 = this.apps.bas.ui.toContentList(o, {
    //     "bid": "common.bid",
    //     "time": "common.created", 
    //     "updated": "common.updated",
    //     "createdBy": "bus.booking.createdBy",
    //     // "user": "common.customer",
    //     "amountDouble": { label: "common.amount", type: "double" },
    //   });


    // //   let user = o.userObj;
    //   this.contentData.customer = this.apps.bas.ui.toContentList(o.userObj, {
    //     "label": "common.name",
    //     "emailAddress": { label: "common.email", type: "email" }, 
    //     "telephone": { label: "common.telephone", type: "telephone" }, 
    //     // "createdBy": "bus.booking.createdBy",
    //     // "user": "common.customer",
    //     // "amountDouble": { label: "common.amount", type: "double" },
    //   });
      

    //   this.orderRows.s1.push(new BtContent({
    //       label: "common.customer",
    //       value: user.label 
    //         + '<br/>' + user.emailAddress 
    //         + '<br/>' + user.telephone 
    //   }))

  
      // this.orderRows.s2 = this.apps.bas.ui.toContentList(o, {
      //   "comment": "common.comment",
      //   "notes": "common.notes", 
      // });

     
      this.contentData[contentId] = contentObj;

    }

    // this.contentData["sums"] = {
    //   "sums":  this.apps.bas.ui.toContentList({ }, {
    //     "amount":  { label: "common.amount", type: "double", value: ordersSum },
    //    })
    // };

    this.sums = {
      show: true,
      count: ordersCount,
      amount: ordersSum
    }


  }

}
