<btlib-admin-page-wrapper>

  <div class="m-d">
    <h1>{{ 'common.tasks' | actrans }}</h1>
    <!-- <div class="text-right">
      <button nz-button nzType="primary"  (click)="newObject()">
        <i nz-icon nzType="plus-circle"></i>
        {{ 'common.add' | actrans }}
      </button>
  
    </div> -->


    <div *ngIf="df.obj">
      <nz-collapse>
        <nz-collapse-panel  [nzHeader]="'Filter'" [nzActive]="apps.bas.ui.r.gtemd"  >
          
          <form nz-form [formGroup]="df.form" class="item-form mt-d" nzLayout="vertical" [nzAutoTips]="apps.bas.fs.autoTips" (ngSubmit)="dfSubmit()"  >
  
            <btlib-form-row [controls]="[ 
            df.controls.taskType ,
            df.controls.taskStatus,
            df.controls.search
            
            ]" [form]="df.form"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row>
           
            <button nz-button nzType="default"> {{ 'common.show' | actrans }}  </button>
      
          </form>

        </nz-collapse-panel>
      </nz-collapse>


     
    </div>
   
  </div>


  <div nz-row [nzGutter]="[8, 16]" nzAlign="top" >
    <div cdkDrag *ngFor="let data of fbo.stats?.current || []; let idx = index" nz-col [nzXs]="24" [nzSm]="12" [nzMd]="8" [nzLg]="6" [nzXl]="6" [nzXXl]="4" >
  
      <nz-card [nzActions]=" [itemActionDelete, itemActionMove, itemActionEdit]" >
        
        <div>{{ data.title }}</div>
        <div>{{ data.typeLabel }}</div>
        <div>{{ data.statusLabel }}</div>
      </nz-card>

      <ng-template #itemActionDelete>
        <!-- <i nz-icon nzType="delete" 
        nz-popconfirm
        [nzPopconfirmTitle]="'app.lib.common.popconfirm.confirm' | actrans | str"  
        [title]="'common.delete' | actrans"
        (nzOnConfirm)="deleteItem(idx, 'input')" [nzTheme]="'twotone'" [nzTwotoneColor]="'#eb2f96'"></i> -->
      </ng-template>
      <ng-template #itemActionMove>
        <!-- <i nz-icon nzType="up" (click)="moveItem(idx, true, 'input')" ></i>
        <i nz-icon nzType="drag" cdkDragHandle></i>
        <i nz-icon nzType="down" (click)="moveItem(idx, false, 'input')"></i> -->
      </ng-template>
      <ng-template #itemActionEdit>
        <i nz-icon nzType="edit" (click)="editObject(data)" ></i>
      </ng-template>
    </div>

    <div nz-col [nzXs]="24" [nzSm]="12" [nzMd]="8" [nzLg]="6" [nzXl]="6" [nzXXl]="4" style="margin:auto;">

      <div class="item-new-wrapper">
        <div class="item-new-icon"><i nz-icon nzType="plus-circle"></i></div>
        <button nz-button  nzType="dashed" nzSize="large" class="item-new" (click)="newObject()">
          {{ 'common.add' | actrans }}
        </button>
      </div>

    </div>
  </div>

  <!-- <nz-table 
    #objectsTable 
    nzTableLayout="fixed" 
    [nzData]="fbo.stats.current || []"
    [nzPageSize]="20"
    nzSize="small"
    class="nz-table-xs"

    >
    <thead>
      <tr>
        <th>{{ 'common.title' | actrans }}</th>
        <th>{{ 'common.status' | actrans }}</th>
        <th>{{ 'common.type' | actrans }}</th>
        <th nzWidth="50px"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of objectsTable.data">

        <td nzEllipsis>{{ data.title }}</td>
        <td>{{ data.statusLabel }}</td>
        <td>{{ data.typeLabel }}</td>

        <td nzAlign="center">
          <button nz-button nzType="default" nzSize="small" [title]="'common.edit' | actrans"  (click)="editObject(data)">
            <i nz-icon [nzType]="'edit'"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </nz-table> -->

  <nz-modal [(nzVisible)]="edit.obj" [nzTitle]="'common.edit' | actrans | str" nzWidth="90vw"  (nzOnCancel)="onEditCancel()" (nzOnOk)="onEditOk();">
    <ng-container *nzModalContent>
      

      <form nz-form [formGroup]="edit.form" class="item-form mt-d" nzLayout="vertical" [nzAutoTips]="apps.bas.fs.autoTips" (ngSubmit)="onEditOk()"  >
  
        <btlib-form-row [controls]="[ edit.controls.type, edit.controls.status ]" [form]="edit.form"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row>
        <!-- <btlib-form-row [controls]="[ edit.controls.messages.childMap.name ]" [form]="edit.form"  [grid]="apps.bas.ui.gridXl" ></btlib-form-row>  -->
        <btlib-form-row [controls]="[ edit.controls.title ]" [form]="edit.form"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row> 
        
        
        <btlib-form-row [controls]="[ edit.controls.product ]" [form]="edit.form"  [grid]="apps.bas.ui.gridMd"  ></btlib-form-row> 
      
      </form>
      

    </ng-container>
  </nz-modal>

</btlib-admin-page-wrapper>
