



<nz-layout class="content-main page-wrapper max-width-1200">
  <nz-content>

    <div *ngIf=" !this.data.cbclassic 
      && (showMenu 
        || !apps.bas.ds.login.success 
        || !(apps.bas.aas.isAdminProvider() || apps.bas.aas.isAgent()))">
      <btlib-admin-menu></btlib-admin-menu>
      <!--  -->
    </div>
 
    

    <div class="" *ngIf="apps.bas.aas.isAdminProvider() || apps.bas.aas.isAgent() ">
      <bt-page-wrapper>

        <ng-content></ng-content>
      </bt-page-wrapper>
    </div>

    
  </nz-content>
 
</nz-layout>





