
<nz-modal 
  [nzVisible]="manual || !!recipients.length" 
  [nzTitle]="'web.ajax.sms' | actrans | str" 

  (nzOnCancel)="closeModal()" 
  (nzOnOk)="closeModal()"
>
<!--   nzWidth="90vw"  -->
  <ng-container *nzModalContent>
    
    <div>
      <div>
        <div class="text-bold">{{ 'common.recipients' | actrans }}</div>  
        <div *ngIf="!manual">
          {{  recipients.join(", ") }}
        </div>
        <div *ngIf="manual">
          <nz-select nzMode="tags" [nzPlaceHolder]="'common.recipients' | actrans | str" [(ngModel)]="recipients">
            <!-- <nz-option *ngFor="let option of listOfOption" [nzLabel]="option.label" [nzValue]="option.value"></nz-option> -->
          </nz-select>
        </div>
      </div>
      <div class="mt-d">
        <div class="text-bold">{{ 'common.message' | actrans }}</div> 
        <nz-textarea-count [class.bt-error]="df.status == 'error'" [nzMaxCharacterCount]="360">

          <textarea nz-input
            [placeholder]="'common.message' | actrans"
            [required]="true" 
            autoGrow
          
            [style.height.px]="75"
            [(ngModel)]="text"
        
          ></textarea>
          
        </nz-textarea-count>

        <div *ngIf="apps.data.messageTemplates">
          <div class="mt-d">
            <nz-tag 
              *ngFor="let tpl of apps.data.messageTemplates || []" 
              [nzColor]="'cyan'" 
              (click)="text = text + tpl.text"
              class="cursor-pointer"
              >
              {{ tpl.name || tpl.text }}
            </nz-tag>
          </div>
       
        </div>
      </div>
    </div>
  </ng-container>
  <div *nzModalFooter>
    <button nz-button nzType="primary" (click)="sendSms()" [disabled]="!recipients.length"   [nzLoading]="df.processing">{{ 'common.submit' | actrans }}</button>
  </div>
</nz-modal>