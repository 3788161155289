import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { MiscUtil } from 'src/app/lib/core/util/misc.util';

import { AppService } from 'src/app/services/app.service';

import { DynformControl, DynformControlsMap } from 'src/app/lib/core/model/dymform-control';

import { NzUploadChangeParam, NzUploadFile } from 'ng-zorro-antd/upload';

@Component({
  selector: 'app-app-tasks-add-page',
  templateUrl: './app-tasks-add-page.component.html',
  styleUrls: ['./app-tasks-add-page.component.scss']
})
export class AppTasksAddPageComponent implements OnInit {


  fbo:any = { };
  df:any = {
    form: undefined,
    controls: [],
    obj: undefined,

    fileList: [],
    params: { 
      // 
    },
    loading: false
  };
  
  // https://192.168.1.82:8200/api/nesbyen/app/tasks/add
  constructor(public injector:Injector, public apps:AppService) {
    let route = injector.get(ActivatedRoute);
    this.apps.bas.us.checkActivatedRoute(route.snapshot);

    if(this.apps.bas.envtest) console.log("AppTasksAddPageComponent, al: ", this.apps.bas.appLogin);

    let qps = route.snapshot.queryParams;
    for (let pn in qps) {
      if ([ "bttoken", "source"].indexOf(pn) >= 0) continue;
      this.df.params[pn] = qps[pn];
    }
    // MiscUtil.extend(this.df.params, route.snapshot.queryParams);
    // this.df.params
    if(this.apps.bas.envtest) console.log("AppTasksAddPageComponent, params: ", this.df.params);

    this.updateFbo();

  }

  ngOnInit(): void {

  }

  updateFbo() {
    let params:any = {
      // checkChildApp: true,
      appId: "tasks",
      aType: "customAction", 
      action: "getAddFbo", 
    };

    MiscUtil.extend(params, this.df.params);

    this.fbo.loading = true;
    this.apps.bas.ws.json(params ).then((json:any) => {
      if (this.apps.bas.envtest) console.log("updateFbo.then, json: ", json);

      if (json.success) {
        this.fbo = json.fbo;
        this.updateForm();
      }

     });
  }

  
  updateForm() {
    let fbo = this.fbo;
    fbo.uuid = MiscUtil.generatePassord(32);
    this.df.fileList = [];

    let controls: DynformControl[] = [];

    controls.push(new DynformControl({ key: 'uuid' }));

    controls.push(new DynformControl({ 
      key: 'title', 
      mk: 'common.title' ,
      required: true
    }));


    controls.push(new DynformControl({ 
      key: 'userUsername', 
      mk: 'common.email' ,
      type: "email", // TODO: blir denne validert? 
      required: !this.apps.bas.appLogin.success,

    }));

    controls.push(new DynformControl({ 
      key: 'userName', 
      mk: 'common.name' ,
      // type: "email", // TODO: blir denne validert? 
      required: !this.apps.bas.appLogin.success,

    }));

    controls.push(new DynformControl({ 
      key: 'body', 
      mk: 'common.description' ,
      controlType: "textarea", 
      required: true
    }));



    controls.push(new DynformControl({ 
      key: 'product',  
      // valuePath: "statusId",
      mk: 'common.product',  
      controlType: 'select', 
      required: true,
      disabled: fbo.products.length == 1 && fbo.product,
      options: () => {
        return fbo.products || [];
      },
      optionsAllowEmpty: true,
      optionsFieldValue: "id",
      optionsFieldLabel: "mkName"
    }));


    this.apps.bas.fs.updateFormObj(this.df, controls, fbo);
  }


  dfSubmit() {

    let rv = this.apps.bas.fs.getRawValue(this.df.form, this.df.controls);
    if (rv === false) return;
    

    if(this.apps.bas.envtest) console.log("onEditOk, rv: ", rv);

    let obj = this.df.obj;

    let params:any = {
      appId: "tasks",
      aType: "customAction", 
      action: "addTask", 
      jsonData: JSON.stringify(rv)
    }

    this.apps.bas.ws.json(params).then((json) => {
      if (this.apps.bas.envtest) console.log("dfSubmit.then, json: ", json);

      if (json.success) {

        this.apps.bas.ui.success("Oppgaven ble lagret. Oppgave ID: " + json.task.id)

        this.updateForm();

      }
    });

  }



  uploadHeaders = (file: NzUploadFile): Object => {
    let headers = this.apps.bas.ws.getCommonHeaders();
    //MiscUtil.extend(headers, this.apps.bas.ws.getLoginHeaders());
    return headers;
  }
  handleUploadFile({ file, fileList }: NzUploadChangeParam): void {

    const status = file.status;
    const resp = file.response;
    if (status !== 'uploading') {
      if (this.apps.bas.envtest) console.log(file, fileList);

      if (status == "removed") {
        let itemId = resp.itemId;

        this.apps.bas.ws.json({ 
          appId: "tasks",
          aType: "customAction", 
          action: "removeTaskFile", 
          itemId: itemId
        
        }).then((json) => {
          if (this.apps.bas.envtest) console.log("removeTaskFile, json: ", json);
          if (json.success) {
          
          }
        });

      }
    }
    if (status === 'done') {
     
      

      if (this.apps.bas.envtest) console.log(file.name + 'file uploaded successfully, resp: ', resp);

      if (resp.itemId) {
        //TODO
      } else {
        this.apps.bas.ui.error(this.apps.bas.ui.actrans("common.error.upload.general", [], false, "En feil oppstod da vi skulle behandle filen du lastet opp"));
      }

    } else if (status === 'error') {
      if (this.apps.bas.envtest) console.log(`${file.name} file upload failed.`);
      // TODO
      // this.apps.bas.ui.error(this.apps.bas.ui.actrans("common.error.imageUpload", [], false, "En feil oppstod da vi skulle behandle filen du lastet opp. Vanligvis er dette fordi du har lastet opp en JPEG fil i CMYK-format. Kun JPEG bilder i RGB-format kan lastes opp")); //TODO:text
      
    }
  }


}
