import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MiscUtil } from 'src/app/lib/core/util/misc.util';

import { AppService, APP_DATAID } from '../../../../../services/app.service';
import { BtContent } from '../../../model/bt-content';
import { DATAID } from '../../../services/data.service';

import { DynformControl } from 'src/app/lib/core/model/dymform-control';

@Component({
  selector: 'btlib-message-modal',
  templateUrl: './message-modal.component.html',
  styleUrls: ['./message-modal.component.scss']
})
export class MessageModalComponent {

  key = "";

  df:any = {
    form: undefined,
    controls: [],
    obj: undefined,

    processing: false
  }

  @Input() 
  set input(input: any) {
    console.log("input.set: ", input);
    if (!input) {
      this.key = "";
      this.updateForm();
      return;
    }

    if (input.key) {
      this.key = input.key;
      this.getMessage();
      return;
    }

  }

  @Output() change = new EventEmitter<any>();

  
  constructor(public apps:AppService) {
    console.log("MessageModalComponent.constructor");
    this.updateForm();
  }

  ngOnInit(): void {

  }

  closeModal() {
    this.key = "";
  }

  getMessage() {
   
    this.apps.bas.ws.json({
      aType: "misc", 
      action: "getMessage", 
      key: this.key
    } ).then((json) => {
      console.log("getMessage, json: ", json);
      this.updateForm({ message: json.message });
    });
  }

  updateForm(obj:any = { }) {

    let controls: DynformControl[] = [];


    controls.push( new DynformControl({ 
      key: 'key',
      mk: 'common.key', 
      controlType: 'label',
      value: this.key
     }));

    controls.push( new DynformControl({ 
      key: 'message',
      mk: '', 
      controlType: 'message',
      data: { controlType: "textarea" }, //TODO: noen har HTML
    }));

    this.apps.bas.fs.updateFormObj(this.df, controls, obj);


  }

  dfSubmit() {


    let rv = this.apps.bas.fs.getRawValue(this.df.form, this.df.controls);

    if (rv === false) return;

    // rv.biid = this.df.obj.id;

    if (this.apps.bas.envtest) console.log("formSubmit, rv: ", rv);

    this.df.processing = true;

    let params = { 
      aType: "misc", 
      action: "updateMessage",
      key: this.key
    };

    MiscUtil.extend(params, rv.message);

    if (this.apps.bas.envtest) console.log("formSubmit, params: ", params);

    this.apps.bas.ws.json(params).then((json) => {
     
      if (this.apps.bas.envtest) console.log("dfSubmit, json: ", json);
      if (json.success) {
        this.apps.bas.ui.success("Teksten er lagret");
        
        this.df.form.reset();
        this.df.obj = undefined;
 
        this.change.next({ type: "saveMessage", key: this.key });
        this.closeModal();
      }
    }).catch(err => {
      this.df.processing = false;

    });


  }

}
