
<nz-modal 
  [nzVisible]="!!bis.length" 
  [nzTitle]="'web.common.overview.cancelAndRefundOrders' | actrans | str" 

  nzWidth="80vw"
  (nzOnCancel)="closeModal()" 
  (nzOnOk)="closeModal()"
>
<!--     -->
  <ng-container *nzModalContent>
    
    <div>
      <div>
        <!-- <div class="text-bold">{{ 'common.orders' | actrans }}</div>   -->
        <div>
          <!-- {{  bis.join(", ") }} -->

          <nz-table 
          #nzTableBis
          [nzData]="bis || []" 
          nzTableLayout="fixed" 
          [nzShowPagination]="false" 
          [nzPageSize]="100"
          nzSize="small"
          class="nz-table-xs"
          >
          <thead>
            <tr>
 
              <th nzWidth="120px">{{ 'common.id' | actrans }}</th>
              <th [nzWidth]="apps.bas.ui.r.gtemd ? '100px' : '40px'">
                <span *ngIf="apps.bas.ui.r.gtemd">{{ 'common.status' | actrans }}</span>
              </th>
              <th>{{ 'common.product' | actrans }}</th>
              <th *ngIf="apps.bas.ui.r.gtemd">{{ 'common.period' | actrans }}</th>
              <th nzAlign="right">{{ 'common.amount' | actrans }}</th>

            </tr>
          </thead>
          <tbody>
            <ng-container  *ngFor="let data of nzTableBis.data; let idx = index">
              <tr>
           
                <td>{{ data.bid }}: {{ data.id }}</td>
                <td [class]="data.statusCssClass">
                  <span *ngIf="apps.bas.ui.r.gtemd">{{ data.mkStatus }}</span> 
                </td>
                <td>{{ data.product }}</td>
   
                <td *ngIf="apps.bas.ui.r.gtemd">{{ data.periodString }}</td>

                <td nzAlign="right">{{ data.amountDouble | nf }}</td>

                <!-- <td *ngIf="apps.bas.ui.r.ltesm"></td> -->

              </tr>
    
            </ng-container>
          </tbody>
          <tfoot>
            <tr>
              <th colspan="2">
                {{ 'common.sum' | actrans }}
              </th>
              <th [attr.colspan]="(
                  2
                  + (apps.bas.ui.r.ltesm ? 0 : 0)  
                  + (apps.bas.ui.r.gtesm ? 0 : 0) 
                  + (apps.bas.ui.r.gtemd ? 1 : 0) 
                  + (apps.bas.ui.r.gtelg ? 0 : 0) 
                  + (apps.bas.ui.r.gtexl ? 0 : 0)
                )"
                
                nzAlign="right"
              >
              {{ sumAmount | nf }}
              </th>
         
            </tr>
           

          </tfoot>
        </nz-table>


        </div>
        
      </div>
      <div class="mt-d">
        <div class="text-bold">{{ 'common.reason' | actrans }}</div> 
        <nz-textarea-count [class.bt-error]="df.status == 'error'" [nzMaxCharacterCount]="360">

          <textarea nz-input
            [placeholder]="'common.reason' | actrans"
            [required]="true" 
            autoGrow
          
            [style.height.px]="60"
            [(ngModel)]="reason"
        
          ></textarea>
          
        </nz-textarea-count>
      </div>
    </div>
  </ng-container>
  <div *nzModalFooter>
    <button 
      nz-button 
      nzType="primary" 
      nzDanger 
      (click)="cancelAndRefund()" 
      [disabled]="!bis.length"
      [nzLoading]="df.loading"
      >{{ 'web.common.overview.cancelAndRefundOrders' | actrans }}</button>
  </div>
</nz-modal>
