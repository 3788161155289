import { Component, Input, OnInit, Injector, EventEmitter, Output } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { MiscUtil } from 'src/app/lib/core/util/misc.util';
import { DynformControl } from 'src/app/lib/core/model/dymform-control';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'bt-message-field',
  templateUrl: './bt-message-field.component.html',
  styleUrls: ['./bt-message-field.component.scss']
})
export class BtMessageFieldComponent implements OnInit {

  @Input() form: UntypedFormGroup|null = null;
  @Input() control!: DynformControl;

  @Output() change = new EventEmitter<any>();
  
  constructor(public injector:Injector, public apps:AppService) {
    
  }

  fg():UntypedFormGroup {
    return (this.control.fg || this.form) as UntypedFormGroup;
  }

  ngOnInit(): void {
    // if(this.apps.bas.envtest) console.log("BtMessageFieldComponent.ngOnInit, control: ", this.control);
  }

  onChange(event:any) {
    this.change.emit(event);
    
  }


}
