<btlib-admin-page-wrapper [showMenu]="false">

  <div class="mx-d">
    
    <div *ngIf="apps.bas.ds.login.success" class="text-right">
      Du er innlogget som {{ apps.bas.ds.login.user.username }}
      <button nz-button nzSize="small" class="ml-d" (click)="apps.bas.api.logout()">{{ 'app.lib.common.logout' | actrans  }} </button>
    
    </div>

    <h2>{{ 'Strømavregning' | actrans }}</h2>

    <div>
      <div *ngIf="df.orders?.length">

        <nz-table 

        #nzTableOrders
        [nzData]="df.orders || []" 
        nzTableLayout="fixed" 
    
        nzSize="small"
        class="nz-table-xs"
    
        >
        <thead>
          <tr>
    
            <th>{{ 'common.bid' | actrans }}</th>
            <th *ngIf="apps.bas.ui.r.gtesm">{{ 'common.product' | actrans }}</th>
            <th *ngIf="apps.bas.ui.r.gtesm">{{ 'common.period' | actrans }}</th>
            <th *ngIf="apps.bas.ui.r.gtemd">{{ 'common.customer' | actrans }}</th>
            <th *ngIf="apps.bas.ui.r.gtemd">{{ 'common.status' | actrans }}</th>
            <!-- <th *ngIf="apps.bas.ui.r.gtemd">{{ 'common.provider' | actrans }}</th> -->
            <th nzWidth="50px"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of nzTableOrders.data">
            <td>
              {{ data.bid }}
            </td>
            <td *ngIf="apps.bas.ui.r.gtesm">{{ data.product }}</td>
            <td *ngIf="apps.bas.ui.r.gtesm">{{ data.parentPeriodString }}</td>
            <td *ngIf="apps.bas.ui.r.gtemd">{{ data.bookingUser.name }}</td>
            <td *ngIf="apps.bas.ui.r.gtemd">{{ data.mkStatus }}</td>
            <td>
              <i nz-icon class="cursor-pointer" [nzType]="'right'" (click)="selectOrder(data)"></i>
            </td>
          </tr>
        </tbody>
      </nz-table>

      </div>
      <div *ngIf="!df.orders?.length">Ingen ventende strømavregninger funnet.</div>
      
    </div>

    <div *ngIf="df.obj">
    
      <form nz-form [formGroup]="df.form" class="item-form mt-d" nzLayout="vertical" [nzAutoTips]="apps.bas.fs.autoTips" (ngSubmit)="dfSubmit()"  >

        <!-- <btlib-form-row [controls]="[ 
          df.controls.kwh ,
          df.controls.unitPrice ,
          df.controls.calcTotal
        ]" [form]="df.form"  (change)="onChange($event)" [grid]="apps.bas.ui.gridSm"  ></btlib-form-row> -->

        <btlib-form-row [controls]="[ 
        
          df.controls.totalAmount,
          df.controls.comment
        ]" [form]="df.form"  [grid]="apps.bas.ui.gridXl"  ></btlib-form-row>
      

        <div nz-row [nzGutter]="apps.bas.ui.gridMd.gutter" nzAlign="top">
          <div nz-col [nzXs]="24" [nzSm]="16" [nzMd]="12" [nzLg]="10" [nzXl]="8" [nzXXl]="8">
            <h3>Last opp dokumentasjon</h3>
            <p>Gjesten vil få utlevert dokumentasjonen på forespørsel. </p>
            <p>Filtyper: JPEG, PNG eller PDF</p>
            <nz-upload
              nzType="drag"
              [nzMultiple]="true"
              [nzAction]="this.apps.bas.ws.getBaseUrlTools() + '?action=uploadDropzone&type=providerElectricityBillingDocs&biid=' + df.obj.id"
              [nzHeaders]="uploadHeaders"
              [(nzFileList)]="df.fileList"
              nzFileType="image/png,image/jpeg,application/pdf"
              nzListType="text"
              [nzShowUploadList]="{ showRemoveIcon: true }"
              (nzChange)="handleUploadFile($event)"

              >
              <p class="ant-upload-drag-icon">
                <i nz-icon nzType="inbox"></i>
              </p>
              <p class="ant-upload-text">
                {{ 'app.cbapi5.page.admin.product.clickOrDragFilesHere' | actrans }}
                
              </p>
              <p class="ant-upload-hint">
                {{ 'app.cbapi5.page.admin.product.uploadFilesHint' | actrans }}
                
              </p>
            </nz-upload>
        
          </div>
        </div>

        <div class="mt-d">
          <button nz-button nzType="primary" [nzLoading]="processing" [disabled]="df.fileList.length == 0 && apps.bas.envprod"> {{ 'Lagre' | actrans }}  </button>
  
        </div>
      
        <div style="height: 50px;"></div>
      </form>
     
    </div>
  

    
  </div>

</btlib-admin-page-wrapper>

