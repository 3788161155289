import { Pipe, PipeTransform, Injector } from "@angular/core";
import { Inject } from '@angular/core';
import { AppService } from "src/app/services/app.service";
import { BooktechAppService } from "../services/booktech-app.service";


@Pipe({ name: "nfi" })
export class NfiPipe implements PipeTransform {

  
  constructor(injector:Injector, public apps:AppService) {

    
  }


  transform(value: number | string, opts:any = { }): string {
    opts = opts || { };

    let val = typeof value === "string" ? this.apps.bas.ui.nfparse(value) : value;
    if (val !== undefined) val /= 100.0;

    return this.apps.bas.ui.nf(val, opts.fractionSize);

  }

  // parse(value: string, fractionSize: number = 2): number {
  //   return this.apps.bas.ui.nfparse(value, fractionSize);

  // }

}

