
import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-app-opplevkragero-products-page',
  templateUrl: './app-opplevkragero-products-page.component.html',
  styleUrls: ['./app-opplevkragero-products-page.component.scss']
})
export class AppOpplevkrageroProductsPageComponent implements OnInit {

  route:ActivatedRoute;
  ctx:any = {
    queryParams: { },
    products: [],
    productsMap: { }
  };
  
  constructor(public injector:Injector, public apps:AppService) {
    this.route = injector.get(ActivatedRoute);
    this.apps.bas.us.checkActivatedRoute(this.route.snapshot);

    if (this.apps.bas.envtest) console.log("AppOpplevkrageroProductsPageComponent");

    this.ctx.queryParams = this.route.snapshot.queryParams;
    this.ctx.type = this.ctx.queryParams.tabType || undefined;
    
  }

  ngOnInit(): void {
    this.apps.bas.acs.productsGet(this.ctx);
  } 

}

